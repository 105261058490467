import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get, isEmpty } from 'lodash';
import { FormAssessmentItemExtraData } from 'o2x-store/src/models/FormAssessmentItem';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  minRating?: number;
  maxRating?: number;
  iconName?: string;
  extraData?: FormAssessmentItemExtraData | null;
};

const RatingInput: React.FC<Props> = (props) => {
  const store = useStore();
  //const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const {
    minRating = 1,
    iconName = 'star',
    onChange,
    initial,
    question,
    extraData,
  } = props;

  let maxRating = props.maxRating || 5;
  if (get(extraData || {}, 'maxRating')) {
    maxRating = Number(get(extraData || {}, 'maxRating'));
  }

  const ratingLegend = get(extraData || {}, 'ratingLegend') || {};

  const stars = Array.from(Array(maxRating), (_, i) => i + 1);
  const [rating, setRating] = useState(initial as Number);
  const windowWidth = Dimensions.get('window').width;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });
  const maxIconWidth = isMobile ? windowWidth - 180 : 400;
  const minIconSize = isMobile ? 36 : 20;

  const onRate = useCallback(
    (rate) => {
      setRating(rate);
      onChange(question.key, rate);
    },
    [setRating],
  );

  return (
    <View>
      <View style={styles.container}>
        <Layout style={styles.content}>
          {!!question.label && (
            <Text style={styles.header} category="c1">
              {question.label}
            </Text>
          )}
          <View style={styles.ratingContainer}>
            {stars.map((rate: number) => (
              <TouchableOpacity
                style={styles.ratingButton}
                key={`${rate}`}
                onPress={() => onRate(rate)}
              >
                <Icon
                  fill={rating >= rate ? theme['white'] : theme['black']}
                  stroke={theme['gray']}
                  strokeWidth={0.5}
                  width={Math.min(minIconSize, maxIconWidth / maxRating)}
                  height={Math.min(minIconSize, maxIconWidth / maxRating)}
                  name={iconName}
                />
              </TouchableOpacity>
            ))}
          </View>
          {!isEmpty(ratingLegend) && (
            <View style={styles.ratingLegend}>
              {Object.keys(ratingLegend).map((key) => (
                <View key={key} style={styles.ratingLegendItem}>
                  <View style={styles.ratingLegendLabel}>
                    <Text style={styles.ratingLegendText}>{key}</Text>
                    <Icon
                      fill={theme['white']}
                      stroke={theme['gray']}
                      strokeWidth={0.5}
                      width={Math.min(36, (windowWidth - 180) / maxRating)}
                      height={Math.min(36, (windowWidth - 180) / maxRating)}
                      name={iconName}
                    />
                  </View>
                  <Text style={styles.ratingLegendText}>{`= ${get(
                    ratingLegend,
                    key,
                    '',
                  )}`}</Text>
                </View>
              ))}
            </View>
          )}
        </Layout>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    maxWidth: 268,
    borderRadius: 4,
  },
  header: {
    marginBottom: 12,
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingButton: {
    padding: 4,
  },
  ratingLegend: {
    marginTop: 40,
  },
  ratingLegendItem: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  ratingLegendText: {
    fontSize: 16,
    marginRight: 4,
  },
  ratingLegendLabel: {
    width: 60,
    flexDirection: 'row',
  },
});

export default RatingInput;
