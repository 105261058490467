import { SnapshotOutOf } from 'mobx-keystone';
import Team from 'o2x-store/src/models/Team';
import { EMOJI_NAMES } from 'o2x-store/src/utils/emoji';
import snakeCaseKeys from 'snakecase-keys';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchTeams = async (token: string) => {
  return callApiWithToken(config.urls.teams, token, 'GET');
};

export const fetchPendingTeams = async (token: string) => {
  return callApiWithToken(`${config.urls.teams}pending/`, token, 'GET');
};

export const fetchTeamActivity = async (
  token: string,
  teamId: number,
  page?: number,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/actions/?page=${page ? page : 1}`,
    token,
    'GET',
  );
};

export const fetchTeamFormAssessments = async (
  token: string,
  teamId: number,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/form_assessments/`,
    token,
    'GET',
  );
};

export const fetchTeamPhysicalAssessments = async (
  token: string,
  teamId: number,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/physical_assessments/`,
    token,
    'GET',
  );
};

export const fetchMembers = async (token: string, teamId: number) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/members/`,
    token,
    'GET',
  );
};

export const fetchPendingMembers = async (token: string, teamId: number) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/pending_members/`,
    token,
    'GET',
  );
};

export const createTeam = async (
  token: string,
  data: Partial<SnapshotOutOf<Team>>,
) => {
  return callApiWithToken(
    config.urls.teams,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
};

export const updateTeam = async (
  token: string,
  data: Partial<SnapshotOutOf<Team>>,
  id: string,
) => {
  return callApiWithToken(
    `${config.urls.teams}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
};

export const deleteTeam = async (token: string, id: string) => {
  return callApiWithToken(`${config.urls.teams}${id}/`, token, 'DELETE');
};

export const validateUser = async (token: string, identifier: string) => {
  return callApiWithToken(
    `${config.urls.teams}validate_user/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ identifier })),
  );
};

export const addReaction = async (
  token: string,
  reaction: EMOJI_NAMES,
  id: string,
  teamId: string,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/actions/${id}/add_reaction/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ reaction })),
  );
};

export const getReactions = async (
  token: string,
  id: string,
  teamId: string,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/actions/${id}/reactions/`,
    token,
    'GET',
  );
};

export const sendChallenge = async (
  token: string,
  teamId: number,
  sweatProgramId?: number | undefined,
  thriveProgramId?: number | undefined,
  sweatWorkoutId?: number | undefined,
  physicalAssessmentId?: number | undefined,
  formAssessmentId?: number | undefined,
) => {
  const data = {};
  if (sweatProgramId) {
    data['sweatProgram'] = sweatProgramId;
  }
  if (thriveProgramId) {
    data['thriveProgram'] = thriveProgramId;
  }
  if (sweatWorkoutId) {
    data['sweatWorkout'] = sweatWorkoutId;
  }
  if (physicalAssessmentId) {
    data['physicalAssessment'] = physicalAssessmentId;
  }
  if (formAssessmentId) {
    data['formAssessment'] = formAssessmentId;
  }

  return callApiWithToken(
    `${config.urls.teams}${teamId}/send_challenge/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
};

export const undoChallenge = async (
  token: string,
  teamId: number,
  actionId: number,
) => {
  return callApiWithToken(
    `${config.urls.teams}${teamId}/undo_challenge/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ actionId: actionId })),
  );
};
