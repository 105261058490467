import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { GroupConversation, MessageModel } from '../../models/Message';
import { callApiWithToken } from './base';

export const checkUnread = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.contact}check_unread_messages/?sender=${id}`,
    token,
    'GET',
  );

export const fetchMyMessages = async (token: string, url?: string) =>
  callApiWithToken(
    url || `${config.urls.contact}my_messages_staging/`,
    token,
    'GET',
  );

export const fetchConversation = async (
  token: string,
  data?: MessageModel,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.conversationStaging}?user1=${data?.sender}&user2=${data?.receiver}&limit=20`,
    token,
    'GET',
  );

export const createMessages = async (token: string, data: MessageModel) => {
  const message = new FormData();
  Object.keys(data).forEach((key) => {
    if (key == 'files') {
      data.files.forEach((file) => message.append('file_data', file));
    } else if (data[key]) {
      message.append(key, data[key]);
    }
  });
  return callApiWithToken(
    config.urls.contact,
    token,
    'POST',
    {},
    message,
    true,
  );
};

export const createGroupConversation = async (
  token: string,
  data: Partial<GroupConversation>,
) =>
  callApiWithToken(
    config.urls.groupConversation,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const sendEmailMessage = async (token: string, data: MessageModel) =>
  callApiWithToken(
    config.urls.sendEmailMessage,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const markReadMessage = async (token: string, data: MessageModel) =>
  callApiWithToken(
    `${config.urls.contact}${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteConversation = async (
  token: string,
  data: { user: number; sender: number; receiver: number },
) =>
  callApiWithToken(
    `${config.urls.deleteMessage}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );
