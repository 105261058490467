import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { get, isFinite, toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { QUESTION_TYPES } from 'o2x-store/src/utils/questions';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Alert, Animated, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import config from 'src/config';
import NavigationBar from '../../components/NavigationBar';
import BottomNav from '../../components/Question/BottomNav';
import Input from '../../components/Question/Input';
import ProgressBar from '../../components/Question/ProgressBar';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentSectionDetail'>;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const FormAssessmentSectionDetail: React.FC<Props> = (props) => {
  const { assessmentId, section } = props.route.params;
  const { assessment } = useStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [sectionItems, setSectionItems] = useState<number[]>([]);
  const [scoreMapping, setScoreMapping] = useState<{ [key: number]: string }>(
    {},
  );
  const [error, setError] = useState('');
  const titleOpacity = useRef(new Animated.Value(0));

  const formAssessment = assessment.formAssessments.get(`${assessmentId}`);

  const formAssessmentSubmission = assessment.formAssessmentSubmissions.get(
    `${formAssessment?.currentSubmission}`,
  );

  const formAssessmentItem = useMemo(
    () => assessment.formAssessmentItems.get(`${sectionItems[index]}`),
    [sectionItems, index],
  );
  const numBars = useMemo(() => sectionItems.length || 0, [sectionItems]);

  const onBack = useCallback(() => {
    console.log('[DEBUG] on back');
    if (index === 0) {
      navigation.goBack();
    } else {
      setIndex((index) => index - 1);
    }
  }, [index]);

  const onNext = useCallback(async () => {
    if (!formAssessmentItem || !formAssessmentSubmission) {
      return;
    }

    const score = getScoreValue(index);
    if (
      formAssessmentItem.isRequired &&
      ((!score && score !== '0') || score.length === 0)
    ) {
      Alert.alert('Error', 'This field is required');
      setError('Required');
      return;
    }

    const result = await formAssessmentItem.answer(
      formAssessmentSubmission?.id,
      score,
    );
    // OFFLINE ERROR when scoring is set to custom readiness
    console.log('[DEBUG] result:', result);

    if (result.ok) {
      if (index + 1 === numBars) {
        if (formAssessment) {
          analytics.logAssessmentComplete(formAssessment);
        }
        navigation.navigate('AssessmentSectionMenu', {
          assessmentId,
          type: ASSESSMENT_TYPE.FORM,
        });
      } else {
        setLoading(true);
        setIndex((index) => index + 1);
      }
    }
  }, [
    index,
    scoreMapping,
    assessmentId,
    formAssessment,
    formAssessmentItem,
    formAssessmentSubmission,
  ]);

  const getScoreValue = useCallback(
    (index: number) => {
      const score = scoreMapping[index];
      if (
        score &&
        formAssessmentItem &&
        formAssessmentItem.questionType === QUESTION_TYPES.TIME
      ) {
        const time = toNumber(score);
        if (isFinite(time)) {
          return `${time}`; // Assumed seconds.
        } else {
          return `${moment.duration(score).as('seconds')}`;
        }
      }
      return score;
    },
    [scoreMapping, formAssessmentItem],
  );

  const onScoringUpdate = useCallback(
    (key: string, score: string | number) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: `${score}`,
      }));
    },
    [index],
  );

  useEffect(() => {
    async function run() {
      if (sectionItems.length === 0) {
        await assessment.fetchFormAssessmentItems(assessmentId);
        const tempItems = assessment.filterFormAssessmentItemSection(
          assessmentId,
          section,
        );

        setSectionItems(tempItems);
      }
    }
    run();
  }, [sectionItems, index]);

  // loading previous answers
  useEffect(() => {
    if (sectionItems && formAssessmentSubmission) {
      setLoading(true);
      const item = formAssessmentSubmission?.items.find(
        (item) => item.itemId === sectionItems[index],
      );

      let answer = '';
      if (typeof item !== 'undefined') {
        answer = item?.value;
      }

      if (answer !== '') {
        // conversion from seconds to hh:mm:ss
        if (formAssessmentItem?.questionType === QUESTION_TYPES.TIME) {
          answer = new Date(Number(answer) * 1000).toISOString().substr(11, 8);
        }
        setScoreMapping((value) => ({
          ...value,
          [index]: `${answer}`,
        }));
      }
    }
    setLoading(false);
  }, [index, formAssessmentSubmission, formAssessmentItem, sectionItems]);

  useFocusEffect(
    useCallback(() => {
      async function fetch() {
        const tempItems = assessment.filterFormAssessmentItemSection(
          assessmentId,
          section,
        );
        if (tempItems.length === 0) {
          await assessment.fetchFormAssessmentItems(assessmentId);
        }
        setSectionItems(
          assessment.filterFormAssessmentItemSection(assessmentId, section),
        );

        assessment.fetchFormAssessmentSubmission(
          formAssessment?.currentSubmission || -1,
        );

        if (formAssessment && !formAssessment.currentSubmission) {
          formAssessment.start();
        }
      }
      fetch();
    }, []),
  );

  const getStatus = useCallback((): string => {
    switch (formAssessmentItem?.category) {
      case 'Eat':
        return 'basic-eat';
      case 'Sweat':
        return 'basic-sweat';
      case 'Thrive':
        return 'basic-thrive';
      default:
        return 'basic-plain';
    }
  }, [formAssessmentItem]);

  if (!formAssessment || !formAssessmentItem) {
    return null;
  }

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar
          title={formAssessment.name}
          titleOpacity={titleOpacity.current.interpolate({
            inputRange: [0, config.titleDisplayOffsetOnScroll],
            outputRange: [0, 1],
          })}
        />
      </Layout>
      <AnimatedScrollView
        style={styles.content}
        contentContainerStyle={styles.contentContainer}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: titleOpacity.current } } }],
          { useNativeDriver: true },
        )}
      >
        {!!formAssessmentItem.category && (
          <Text style={styles.category}>{formAssessmentItem.category}</Text>
        )}
        <Text style={styles.title}>
          {formAssessmentItem.question}
          {formAssessmentItem.isRequired ? ' *' : ''}
        </Text>
        {!loading && (
          <Input
            status={getStatus()}
            key={`input-${index}`}
            style={styles.input}
            question={{
              question: formAssessmentItem.question,
              type: formAssessmentItem.questionType,
              key: `${index}`,
              options: formAssessmentItem.options.map((o) => [
                o.value,
                o.label,
              ]),
            }}
            onChange={onScoringUpdate}
            initial={get(scoreMapping, index) || ''}
            extraData={formAssessmentItem.extraData}
          />
        )}
      </AnimatedScrollView>
      <Layout>
        <ProgressBar numBars={numBars} currentBar={index} />
        <BottomNav onBack={onBack} onNext={onNext} />
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 16,
  },
  scoring: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 16,
    marginBottom: 4,
  },
  input: {
    marginTop: 12,
  },
  category: {
    textTransform: 'uppercase',
    marginHorizontal: 24,
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 32,
  },
});

export default observer(FormAssessmentSectionDetail);
