import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import * as FileSystem from 'expo-file-system';
import { LinearGradient } from 'expo-linear-gradient';
import * as Sharing from 'expo-sharing';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import UserSweatWorkoutProgress from 'o2x-store/src/models/UserSweatWorkoutProgress';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  ScrollView,
  Share,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import ViewShot, { captureRef } from 'react-native-view-shot';
import { useMediaQuery } from 'react-responsive';
import SaveIcon from '../../assets/images/action-save.svg';
import ShareIcon from '../../assets/images/action-share.svg';
import Clock from '../../assets/images/clock-white.svg';
import Edit from '../../assets/images/highlight.svg';
import PlanRepeat from '../../assets/images/plan-repeat.svg';
import SweatWorkoutCompleteIcon from '../../assets/images/sweat-workout-complete-icon.svg';
import UsersIcon from '../../assets/images/users.svg';
import TopNav from '../../components/Question/TopNav';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatWorkoutComplete'>;
  navigation: StackNavigationProp<AppStackParamList, 'SweatWorkoutComplete'>;
};

const SweatWorkoutComplete: React.FC<Props> = (props) => {
  const {
    route: {
      params: { programId, workoutId },
    },
    navigation,
  } = props;
  const { sweat, user } = useStore();
  const { sweatStart, workoutFilter } = useNativeStore();
  const program = sweat.sweatPrograms.get(`${programId}`);
  const workout = sweat.sweatWorkouts.get(`${workoutId}`)!;
  const nextWorkout = sweat.sweatWorkouts.get(
    `${program?.userProgress?.currentWorkout}`,
  );
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState<UserSweatWorkoutProgress | null>(null);
  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const viewShotRef = useRef();

  workoutFilter.reset();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const summary = await user.fetchRecentSweatWorkoutProgress();
        setSummary(summary);
        setLoading(false);
      })();
    }, [workoutId, program]),
  );

  const startNextWorkout = useCallback(async () => {
    if (!program || program.loading || !nextWorkout || nextWorkout?.loading) {
      return;
    }

    const result = await program.startWorkout();
    const next = sweat.sweatWorkouts.get(`${result?.extra?.workout}`);
    if (!next) {
      console.warn('[DEBUG] failed to start workout', result);
      return;
    }
    if (!next.isExercisesReady) {
      await next.fetch();
    }

    sweatStart.startWorkout(next, sweat);
    navigation.navigate('SweatStart', {
      id: programId,
      jumpWorkoutId: nextWorkout.id,
    });
  }, [nextWorkout, sweatStart, sweat]);

  const repeatWorkout = useCallback(async () => {
    if (!workout || workout?.loading) {
      return;
    }

    await workout.fetch();

    await workout.startWorkout();
    sweatStart.startWorkout(workout, sweat);
    sweatStart.setRepeat(true);

    if (sweatStart.isWorkoutGenerator) {
      sweatStart.setIsWorkoutGenerator(false);
      navigation.navigate('Workout', {
        workoutId: workout.id,
      });
    } else if (program) {
      navigation.navigate('SweatStart', {
        id: programId,
        jumpWorkoutId: workout.id,
      });
    } else {
      navigation.navigate('SweatContinue', {
        workoutId: workout.id,
      });
    }
  }, [workout, sweatStart, sweat, program]);

  const repeatProgram = useCallback(async () => {
    if (!program || program?.loading) {
      return;
    }

    await program?.startProgram();

    const newWorkoutId = get(program, 'userProgress.currentWorkout', null);
    const newWorkout =
      newWorkoutId && sweat.sweatWorkouts.get(`${newWorkoutId}`);

    await program?.startWorkout();
    sweatStart.startWorkout(newWorkout, sweat);

    navigation.navigate('SweatStart', {
      id: program.id,
      jumpWorkoutId: newWorkoutId,
    });
  }, [program, sweat]);

  const explorePrograms = useCallback(async () => {
    await user.fetchSweatProfile();
    if (user.sweatProfile) {
      navigation.navigate('FindSweatPlanSummary');
    } else {
      navigation.navigate('FindSweatPlanQuestion');
    }
  }, [user.sweatProfile]);

  const onSave = useCallback(() => {
    if (workout && workout.saveList.length === 0) {
      navigation.navigate('SaveToList', {
        listType: LIST_TYPE.SWEAT_WORKOUT,
        itemId: workout.id,
      });
    }
  }, [workout]);

  const onClose = useCallback(() => {
    sweatStart.setIsWorkoutGenerator(false);
    navigation.popToTop();
  }, []);

  const onShare = useCallback(async () => {
    if (Platform.OS === 'web') {
      alert('Share is not supported in this browser');
      return;
    }

    if (program) {
      const shareData = {
        message: program.viewUrl,
        url: program.viewUrl,
        title: `I just finished an amazing workout on ${program.name}!`,
      };
      try {
        const result = await Share.share(shareData);
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      let screenshotUri = '';
      await captureRef(viewShotRef, {
        format: 'jpg',
        quality: 0.8,
        result: 'base64',
      }).then(
        async (res) => {
          let filename = 'share.jpg';
          let filepath = `${FileSystem.documentDirectory}/${filename}`;
          await FileSystem.writeAsStringAsync(filepath, res, {
            encoding: 'base64',
          });
          await Sharing.shareAsync(filepath, { mimeType: 'image/jpg' });
          Sharing.shareAsync(screenshotUri);
        },
        (error) => {
          alert(error.message);
        },
      );
    }
  }, [program]);

  const onChallenge = useCallback(() => {
    const name = program ? program.name : workout ? workout.name : '';
    navigation.navigate('TeamChallenge', {
      name,
      sweatProgramId: programId,
      sweatWorkoutId: workoutId,
    });
  }, [navigation, program, workout, programId, workoutId]);

  const repeatGenerator = useCallback(() => {
    navigation.navigate('WorkoutGeneratorFilter');
  }, []);

  const [imageBanner, setImageBanner] = useState<string>('');

  const onEdit = useCallback(() => {
    if (summary) {
      navigation.navigate('SweatWorkoutSummary', {
        screen: 'SweatWorkoutSummaryEdit',
        params: {
          summaryId: summary.id,
          editingMode: true,
        },
      });
    }
  }, [summary]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (program?.imageBanner) {
          setImageBanner(program?.imageBanner);
        } else {
          const result = await sweat.fetchRandomSweatImageFile();
          if (result.entities) {
            if (result.entities.contentBanner) {
              setImageBanner(result.entities.contentBanner);
            }
          }
        }
      })();
    }, [program, setImageBanner]),
  );

  const duration = useMemo(() => {
    const data = moment.utc(summary?.time * 1000);
    return data.format('HH:mm:ss');
  }, [summary?.time]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <Image
              style={styles.cover as StyleProp<ImageStyle>}
              source={
                imageBanner
                  ? { uri: imageBanner }
                  : require('../../assets/images/workout-generator-thumbnail.png')
              }
            />
            <LinearGradient
              colors={['transparent', '#10283E']}
              style={styles.gradient}
            />
            <Layout
              style={[styles.navigationContainer, styles.transparentBackground]}
            >
              <TopNav
                showBack={false}
                showClose={true}
                onClose={onClose}
                style={styles.transparentBackground}
              />
            </Layout>

            <ScrollView
              style={styles.scroll}
              contentContainerStyle={styles.contentScroll}
            >
              <ViewShot ref={viewShotRef} style={styles.screenshotContainer}>
                <SweatWorkoutCompleteIcon
                  style={styles.icon}
                  width={74}
                  height={74}
                />
                <Text style={styles.label} category="h1">
                  {program?.userProgress?.isComplete
                    ? 'Program\nComplete!'
                    : 'Workout\nComplete!'}
                </Text>
              </ViewShot>

              <View style={styles.titleContainer}>
                <Text style={styles.programName} category="h2">
                  {program ? program.name : workout.displayName}
                </Text>
                {program && (
                  <Text style={styles.dayDisplay} category="c1">
                    {workout.dayDisplay}
                  </Text>
                )}
              </View>

              <View style={styles.summaryContent}>
                <View style={styles.summaryContainer}>
                  <Text style={styles.summaryText}>Summary</Text>

                  <TouchableOpacity style={styles.editButton} onPress={onEdit}>
                    <Text style={styles.editText}>Edit</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.divider} />
                <View style={styles.summaryCategoryContainer}>
                  <Clock fill={theme['white']} height={14} width={14} />

                  <Text style={styles.summaryCategoryText}>Duration</Text>
                  {!loading && (
                    <Text style={styles.summaryValueText}>{duration}</Text>
                  )}
                </View>
                <View style={styles.divider} />
                <View style={styles.summaryCategoryContainer}>
                  <Edit fill={theme['white']} height={14} width={14} />
                  <Text style={styles.summaryCategoryText}>Notes</Text>
                  {!loading && (
                    <View style={{ flex: 1 }}>
                      <Text style={styles.summaryValueText} numberOfLines={1}>
                        {summary?.notes}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={styles.divider} />
              </View>

              <View style={styles.actions}>
                <TouchableOpacity style={styles.actionItem} onPress={onSave}>
                  <SaveIcon
                    style={styles.actionIcon}
                    stroke="#C4C4C4"
                    color="#C4C4C4"
                  />
                  <Text style={styles.actionText}>Save</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.actionItem}
                  onPress={onChallenge}
                >
                  <UsersIcon
                    style={styles.actionIcon}
                    stroke="#C4C4C4"
                    color="#C4C4C4"
                  />
                  <Text style={styles.actionText}>Challenge</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.actionItem} onPress={onShare}>
                  <ShareIcon
                    style={styles.actionIcon}
                    stroke="#C4C4C4"
                    color="#C4C4C4"
                  />
                  <Text style={styles.actionText}>Share</Text>
                </TouchableOpacity>
              </View>

              {!program && (
                <TouchableOpacity
                  style={styles.repeatContainer}
                  onPress={repeatWorkout}
                >
                  <PlanRepeat />
                  <Text style={styles.repeatText}> Repeat Workout</Text>
                </TouchableOpacity>
              )}

              {!program && (
                <TouchableOpacity
                  style={styles.startButton}
                  onPress={repeatGenerator}
                >
                  <Text style={styles.startLabel}>Start a New Workout</Text>
                  <Text style={styles.startDayLabel} category="c1">
                    Workout Generator
                  </Text>
                </TouchableOpacity>
              )}

              {program &&
                (nextWorkout ? (
                  <>
                    <TouchableOpacity
                      style={styles.repeatContainer}
                      onPress={repeatWorkout}
                    >
                      <PlanRepeat />
                      <Text style={styles.repeatText}> Repeat Workout</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.startButton}
                      onPress={startNextWorkout}
                    >
                      {program.loading || nextWorkout.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <Text style={styles.startLabel}>
                            Start Next Workout
                          </Text>
                          {nextWorkout && (
                            <Text style={styles.startDayLabel} category="c1">
                              {nextWorkout.dayDisplay}
                            </Text>
                          )}
                        </>
                      )}
                    </TouchableOpacity>
                  </>
                ) : (
                  <>
                    <TouchableOpacity
                      style={styles.repeatContainer}
                      onPress={repeatProgram}
                    >
                      <PlanRepeat />
                      <Text style={styles.repeatText}> Repeat Program</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.startButton}
                      onPress={explorePrograms}
                    >
                      <Text style={styles.startLabel}>Start Next Programs</Text>
                      <Text style={styles.startDayLabel} category="c1">
                        Explore Programs
                      </Text>
                    </TouchableOpacity>
                  </>
                ))}
            </ScrollView>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  contentScroll: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 24,
    paddingTop: 0,
  },
  screenshotContainer: {
    width: '100%',
    paddingBottom: 30,
    justifyContent: 'center',
  },
  cover: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  gradient: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  dayDisplay: {
    color: 'cyan',
    textTransform: 'uppercase',
    marginBottom: 60,
  },
  icon: {
    marginBottom: 24,
    alignSelf: 'center',
  },
  label: {
    textAlign: 'center',
  },
  programName: {
    marginBottom: 8,
    textAlign: 'center',
  },
  scroll: {
    flex: 1,
  },
  startButton: {
    marginTop: 48,
    width: 245,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    paddingHorizontal: 37,
    paddingVertical: 10,
  },
  startDayLabel: {
    color: 'cyan',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  startLabel: {
    marginBottom: 6,
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  titleContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  actions: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  actionIcon: {
    marginBottom: 7,
  },
  actionItem: {
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  actionText: {
    color: 'light-gray',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  repeatText: {
    color: 'light-gray',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
  repeatContainer: {
    flexDirection: 'row',
    paddingTop: 30,
    alignItems: 'center',
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  challengeButton: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 20,
    alignItems: 'center',
  },
  challengeText: {
    color: 'light-gray',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 17,
    textTransform: 'uppercase',
  },
  summaryText: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    color: 'cyan',
  },
  divider: {
    height: 2,
    backgroundColor: 'black',
    width: '100%',
  },
  editButton: {
    width: 50,
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
    paddingVertical: 6,
    alignItems: 'center',
  },
  editText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    textTransform: 'uppercase',
  },
  summaryContent: {
    width: '100%',
    paddingBottom: 20,
  },
  summaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingVertical: 4,
  },
  summaryCategoryContainer: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 8,
    alignItems: 'center',
  },

  summaryCategoryText: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    color: 'light-gray',
    paddingHorizontal: 12,
  },
  summaryValueText: {
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
  },
});

export default observer(SweatWorkoutComplete);
