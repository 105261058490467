import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TextInput, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Filter from '../../assets/images/filter.svg';
import { useNativeStore } from '../../stores';

type Props = {
  onPress: () => void;
  selected: TASK_TYPE;
};

const FilterInput: React.FC<Props> = (props) => {
  const { selected } = props;
  const { exploreFilter } = useNativeStore();
  const { isFiltering } = exploreFilter;
  const { onPress } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const color = theme['white'];
  const [search, setSearch] = useState(exploreFilter.search);
  const onSearch = useCallback(() => {
    exploreFilter.setSearch(search);
  }, [exploreFilter, search, selected]);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  return (
    <Layout
      style={
        isDeviceMaxWidth600 ? styles.containerMaxWidth600 : styles.container
      }
    >
      <Icon style={styles.icon} fill={color} name="search" />
      <TextInput
        style={styles.searchInput}
        placeholder="Search"
        placeholderTextColor={theme['white']}
        returnKeyType="search"
        value={search}
        onChangeText={setSearch}
        onSubmitEditing={onSearch}
      />
      <TouchableOpacity onPress={onPress}>
        <Filter width={24} height={24} stroke={theme['gray']} />
        {isFiltering && (
          <Layout style={styles.badge}>
            <Text style={styles.badgeLabel}>
              {
                Object.values(exploreFilter.filters).filter((filter) => filter)
                  .length
              }
            </Text>
          </Layout>
        )}
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderColor: 'darker-blue',
    borderRadius: 6,
    borderWidth: 2,
    width: 12,
    height: 12,
  },
  badgeLabel: {
    fontSize: 8,
    lineHeight: 11,
    textAlign: 'center',
    color: 'darker-blue',
  },
  container: {
    flexDirection: 'row',
    marginVertical: 8,
    marginLeft: 24,
    padding: 10,
    backgroundColor: 'black',
  },
  containerMaxWidth600: {
    flexDirection: 'row',
    marginVertical: 8,
    padding: 10,
    backgroundColor: 'black',
  },
  icon: {
    height: 24,
    width: 24,
  },
  text: {
    color: 'gray',
    flex: 1,
    marginHorizontal: 12,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    marginHorizontal: 8,
    paddingHorizontal: 4,
  },
});

export default observer(FilterInput);
