import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  FlatList,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import {
  FTESweatProgram,
  FTESweatWorkout,
} from '../../../../o2x-store/src/models/FTELibrary';
import User from '../../../../o2x-store/src/models/User';
import ProgramWeek from './ProgramWeek';

type Props = {
  program: FTESweatProgram | undefined;
  user: User | undefined;
  onClose: () => void;
  onPressDay: (day: FTESweatWorkout) => void;
};

const ProgramDetail: React.FC<Props> = ({
  program,
  user,
  onClose,
  onPressDay,
}) => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [weeks, setWeeks] = useState<Array<FTESweatWorkout[]>>([]);
  const styles = useStyleSheet(themedStyles);
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (program) {
          setLoading(true);
          const prog = await store.fteLibrary.fetchSweatProgramById(program.id);
          setWeeks(
            Array.from(
              Array(prog.extra?.results[0].lengthInWeeks),
              () => new Array<FTESweatWorkout>(),
            ),
          );
          await (() => {
            for (let x = 0; x < prog.extra?.results[0].lengthInWeeks!; x++) {
              setWeeks((prev) => {
                for (let i = 1; i <= 7; i++) {
                  const workoutObj: Partial<FTESweatWorkout> = {
                    week: x,
                    day: i,
                  };
                  prev[x].push(
                    prog.extra?.results[0].workouts.find(
                      (program) => program.week === x + 1 && program.day === i,
                    )
                      ? prog.extra?.results[0].workouts.find(
                          (program) =>
                            program.week === x + 1 && program.day === i,
                        )
                      : workoutObj,
                  );
                }
                return prev;
              });
            }
          })();
          setLoading(false);
        }
      })();
    }, [program]),
  );

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Layout style={styles.container}>
      <Layout style={!!isMobile ? styles.mobileBg : styles.bgContainer}>
        <Layout style={styles.header}>
          <Image
            style={styles.logo as StyleProp<ImageStyle>}
            source={
              user?.profileImage
                ? { uri: user.profileImage }
                : require('../../../assets/images/user_placeholder.png')
            }
          />
          <Text style={styles.headerText}>
            {user?.fullName ? user.fullName : user?.email}
          </Text>

          <TouchableOpacity
            onPress={onClose}
            style={[styles.close, { marginLeft: 'auto' }]}
          >
            <Icon name="close-outline" fill="white" style={styles.icon} />
          </TouchableOpacity>
        </Layout>
        <Layout style={styles.programHeader}>
          <Text style={styles.headerText}>{program ? program.name : ''}</Text>
        </Layout>
        <ScrollView
          style={
            !!isMobile
              ? [styles.contentContainer, { width: '90%' }]
              : styles.contentContainer
          }
        >
          {loading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <FlatList
              data={weeks}
              renderItem={({ item: week, index }) =>
                week.length > 0 ? (
                  <Layout key={index} style={styles.weekContainer}>
                    <Text style={styles.weekText}> Week {index + 1}</Text>
                    <ProgramWeek
                      week={week}
                      key={index}
                      onPressDay={onPressDay}
                    />
                  </Layout>
                ) : null
              }
            />
          )}
        </ScrollView>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '90%',
    height: '95%',
    marginLeft: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileBg: {
    position: 'absolute',
    width: '87%',
    height: '90%',
    left: '26px',
    right: '26px',
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  header: {
    width: '95%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  programHeader: {
    width: '95%',
    height: '50px',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: 0,
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  weekText: {
    fontSize: 20,
  },
  weekContainer: {
    padding: '10px',
  },
  logo: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 10,
  },
  close: {
    alignSelf: 'center',
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
  contentContainer: {
    width: '95%',
    marginLeft: '26px',
    marginBottom: '40px',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
});

export default observer(ProgramDetail);
