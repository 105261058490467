import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import moment from 'moment';
import { RootStore } from '.';
import config from '../config';
import { ProgramPreview } from '../models/Program';
import ThriveExercise from '../models/ThriveExercise';
import ThriveMediaFile, {
  THRIVE_MEDIA_FILE_TYPE,
} from '../models/ThriveMediaFile';
import ThriveProgram from '../models/ThriveProgram';
import ThriveProgramDay from '../models/ThriveProgramDay';
import ThriveProgramExercise from '../models/ThriveProgramExercise';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import { THRIVE_ACTIVITY_TYPE } from '../utils/thrive';

@model('o2x-store/ThriveStore')
export default class ThriveStore extends Model({
  thriveMediaFiles: prop_mapObject(() => new Map<string, ThriveMediaFile>()),
  thriveExercises: prop_mapObject(() => new Map<string, ThriveExercise>()),
  thrivePrograms: prop_mapObject(() => new Map<string, ThriveProgram>()),
  thriveProgramDays: prop_mapObject(() => new Map<string, ThriveProgramDay>()),
  thriveProgramExercises: prop_mapObject(
    () => new Map<string, ThriveProgramExercise>(),
  ),
  activeThriveProgramIds: prop<Array<number>>(() => new Array<number>()),
}) {
  _thriveProgramsLoading = '';
  _thriveExercisesLoading = '';

  @observable
  loading = false;

  @modelAction
  createOrUpdateThriveMediaFile(
    data: ModelInstanceCreationData<ThriveMediaFile>,
    type: THRIVE_MEDIA_FILE_TYPE,
  ) {
    const id = `${type}-${data.id}`;
    if (this.thriveMediaFiles.has(id)) {
      this.thriveMediaFiles.get(id)!.update(data, type);
    } else {
      const thriveMediaFile = new ThriveMediaFile(data);
      this.thriveMediaFiles.set(id, thriveMediaFile);
      thriveMediaFile.update(data, type);
    }
  }

  @modelAction
  createOrUpdateThriveExercise(
    data: ModelInstanceCreationData<ThriveExercise>,
  ) {
    const id = `${data.id}`;
    if (this.thriveExercises.has(id)) {
      this.thriveExercises.get(id)!.update(data);
    } else {
      const thriveExercise = new ThriveExercise(data);
      this.thriveExercises.set(id, thriveExercise);
      thriveExercise.update(data);
    }
  }

  @modelAction
  createOrUpdateThriveProgram(data: ModelInstanceCreationData<ThriveProgram>) {
    const id = `${data.id}`;
    if (this.thrivePrograms.has(id)) {
      this.thrivePrograms.get(id)!.update(data);
    } else {
      const thriveProgram = new ThriveProgram(data);
      this.thrivePrograms.set(id, thriveProgram);
      thriveProgram.update(data);
    }
  }

  @modelAction
  createOrUpdateThriveProgramDay(
    data: ModelInstanceCreationData<ThriveProgramDay>,
  ) {
    const id = `${data.id}`;
    if (this.thriveProgramDays.has(id)) {
      this.thriveProgramDays.get(id)!.update(data);
    } else {
      const thriveProgramDay = new ThriveProgramDay(data);
      this.thriveProgramDays.set(id, thriveProgramDay);
      thriveProgramDay.update(data);
    }
  }

  @modelAction
  createOrUpdateThriveProgramExercise(
    data: ModelInstanceCreationData<ThriveProgramExercise>,
  ) {
    const id = `${data.id}`;
    if (this.thriveProgramExercises.has(id)) {
      this.thriveProgramExercises.get(id)!.update(data);
    } else {
      const thriveProgramExercise = new ThriveProgramExercise(data);
      this.thriveProgramExercises.set(id, thriveProgramExercise);
      thriveProgramExercise.update(data);
    }
  }

  @modelFlow
  fetchThriveMediaFiles = _async(function* (this: ThriveStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    for (const type of [
      THRIVE_MEDIA_FILE_TYPE.AUDIO,
      THRIVE_MEDIA_FILE_TYPE.IMAGE,
      THRIVE_MEDIA_FILE_TYPE.VIDEO,
    ]) {
      let results: [ModelInstanceCreationData<ThriveMediaFile>];
      try {
        ({
          response: {
            entities: { results },
          },
        } = yield* _await(
          api.fetchThriveMediaFiles(rootStore.auth.token, type),
        ));
      } catch (error) {
        console.warn('[DEBUG] error fetching thrive media files', error, type);
        return getError(error);
      }
      results.forEach((data) => this.createOrUpdateThriveMediaFile(data, type));
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThrivePrograms = _async(function* (
    this: ThriveStore,
    recommended?: boolean,
    nextUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._thriveProgramsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    if (!nextUrl) this.thrivePrograms.clear();

    let url =
      nextUrl ||
      `${config.urls.thrivePrograms}?limit=10${recommended ? '&r=1' : ''}`;

    let results: [ModelInstanceCreationData<ThriveProgram>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(api.fetchThrivePrograms(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error);
    }
    if (runId !== this._thriveProgramsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }
    results.forEach((data) => this.createOrUpdateThriveProgram(data));

    this._thriveProgramsLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  fetchActiveThrivePrograms = _async(function* (this: ThriveStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<ThriveProgram>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchActiveThrivePrograms(
          rootStore.auth.token,
          rootStore.auth.user?.id,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching active thrive programs', error);
      return getError(error);
    }

    this.activeThriveProgramIds = new Array<number>();
    results.forEach((data) => {
      this.createOrUpdateThriveProgram(data);
      this.activeThriveProgramIds.push(data.id);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThriveProgramsByIds = _async(function* (
    this: ThriveStore,
    ids: Array<string>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<ThriveProgram>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchThriveProgramsByIds(rootStore.auth.token, ids.toString()),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateThriveProgram(data));
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  findThrivePrograms = _async(function* (this: ThriveStore, data: Object) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.findThrivePrograms(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error finding thrive programs', error);
      return getError(error);
    }

    entities.results.forEach((data: ModelInstanceCreationData<ThriveProgram>) =>
      this.createOrUpdateThriveProgram(data),
    );

    this.loading = false;
    return entities.results;
  });

  @modelFlow
  searchThrivePrograms = _async(function* (
    this: ThriveStore,
    filterString: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._thriveProgramsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    this.thrivePrograms.clear();

    let url = `${config.urls.thrivePrograms}?limit=10&${filterString}`;
    // while (url) {
    let results: [ModelInstanceCreationData<ThriveProgram>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(
        api.searchThrivePrograms(rootStore.auth.token, filterString, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error searching thrive programs', error);
      return getError(error);
    }
    if (runId !== this._thriveProgramsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }
    results.forEach((data) => this.createOrUpdateThriveProgram(data));
    // }

    this._thriveProgramsLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  batchStartThrivePrograms = _async(function* (
    this: ThriveStore,
    data: Object,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      ({ response } = yield* _await(
        api.batchStartThrivePrograms(rootStore.auth.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error batch starting thrive programs', error);
      return getError(error);
    }

    this.loading = false;
    return;
  });

  @modelFlow
  fetchThriveProgramPreview = _async(function* (this: ThriveStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ProgramPreview;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchThriveProgramPreview(rootStore.auth.token, id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive program preview', error);
      return getError(error);
    }

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchThriveExercises = _async(function* (this: ThriveStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    this.thriveExercises.clear();

    let results: [ModelInstanceCreationData<ThriveExercise>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchThriveExercises(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercises', error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateThriveExercise(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThriveExerciseByIds = _async(function* (
    this: ThriveStore,
    ids: Array<string>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<ThriveExercise>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchThriveExercisesByIds(rootStore.auth.token, ids.toString()),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercise', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateThriveExercise(data));
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  searchThriveExercises = _async(function* (
    this: ThriveStore,
    filterString: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._thriveExercisesLoading = runId;
    this.loading = true;
    this.thriveExercises.clear();

    let url = `${config.urls.thriveExercises}?limit=10&${filterString}`;
    while (url) {
      let results: [ModelInstanceCreationData<ThriveExercise>];
      try {
        ({
          response: {
            entities: { results, next: url },
          },
        } = yield* _await(
          api.searchThriveExercises(rootStore.auth.token, filterString, url),
        ));
      } catch (error) {
        console.warn('[DEBUG] error searching thrive exercise', error);
        return getError(error);
      }
      if (runId !== this._thriveExercisesLoading) {
        return getSuccess();
      }
      results.forEach((data) => this.createOrUpdateThriveExercise(data));
    }

    this._thriveExercisesLoading = '';
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  logThriveActivity = _async(function* (
    this: ThriveStore,
    activity: THRIVE_ACTIVITY_TYPE,
    start: Date,
    end: Date,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const startStr = moment(start).toISOString();
    const endStr = moment(end).toISOString();

    let response;
    try {
      ({ response } = yield* _await(
        api.logThriveActivity(rootStore.auth.token, activity, startStr, endStr),
      ));
    } catch (error) {
      console.warn('[DEBUG] error logging thrive activity', activity, error);
      return getError(error);
    }

    this.loading = false;
    return response.entities;
  });

  @modelFlow
  fetchAllExercisesUsingProgramId = _async(function* (
    this: ThriveStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) return null;

    try {
      const preview = yield* _await(this.fetchThriveProgramPreview(id));
      if ('errors' in preview) return null;

      const program = this.thrivePrograms.get(id.toString());
      if (!program) return null;

      let result = yield* _await(program.fetchProgramDays());
      if (!result.ok) return null;

      const dayIds: string[] = [];
      (preview.preview as any).forEach((week: any) => {
        week.data.forEach((day: any) => {
          if (!dayIds.includes(day.id)) dayIds.push(day.id);
        });
      });

      const thriveExercises: any[] = [];
      yield* _await(
        Promise.all(
          dayIds.map(async (id) => {
            const programDay = this.thriveProgramDays.get(`${id}`);
            if (!programDay) return;
            await programDay.fetch();
            const exercises = programDay.exercises.map((e) => `${e}`);
            await this.fetchThriveExerciseByIds(exercises);
            programDay.thriveStartSections.forEach((section) => {
              const exercise = this.thriveExercises.get(`${section.exercise}`);
              thriveExercises.push({
                id: section.id,
                programDay: section.programDay,
                instructions: section.instructions,
                cta: section.cta,
                time: section.time,
                timer: section.timer,
                timerLengthInSeconds: section.timerLengthInSeconds,
                video: exercise?.video,
                description: exercise?.description,
              });
            });
          }),
        ),
      );
      return { preview, thriveExercises };
    } catch (error) {
      console.warn('[DEBUG] error fetching all step exercises', error);
      return null;
    }
  });

  @modelFlow
  clearThrivePrograms = _async(function* (this: ThriveStore) {
    this.thrivePrograms.clear();
  });
}
