import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { DashboardFile, DashboardModel } from '../../models/Dashboard';
import { callApiWithToken } from './base';

export const fetchMyDashboard = async (token: string, url: string) =>
  callApiWithToken(url, token, 'GET');

export const createDashboardContent = async (
  token: string,
  data: DashboardModel,
  files?: any,
) => {
  const body = new FormData();
  body.append('title', data.title);
  body.append('body', data.body);
  body.append('user', data.user);
  files.forEach((file) => body.append('file_data', file));
  return callApiWithToken(config.urls.dashboard, token, 'POST', {}, body, true);
};

export const updateDashboardContent = async (
  token: string,
  data: Partial<DashboardModel>,
  files?: any,
  attachedFiles?: any,
) => {
  const body = new FormData();
  body.append('title', data.title);
  body.append('body', data.body);
  files.forEach((file) => body.append('file_data', file));
  attachedFiles.forEach((file) => body.append('attached_files', file));

  return callApiWithToken(
    `${config.urls.dashboard}${data.id}/`,
    token,
    'PATCH',
    {},
    body,
    true,
  );
};

export const deleteDashboardContent = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.dashboard}${id}/`, token, 'DELETE');

export const uploadDashboardFile = async (
  token: string,
  data: Partial<DashboardFile>,
) =>
  callApiWithToken(
    config.urls.dashboardFiles,
    token,
    'POST',
    { 'Content-Disposition': `attachment; filename=${data.fileData.name}` },
    snakeCaseKeys(data),
    true,
  );

export const getDashboardFile = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.dashboardFiles}${id}/`, token, 'GET');
