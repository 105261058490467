import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserDebriefProfile } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import DailyDebriefDial from 'src/components/DailyDebriefDial';
import TopNav from 'src/components/Question/TopNav';
import TaskIcon from 'src/components/TaskIcon';
import Topics from 'src/components/User/Topics';
import ThriveAverage from '../../assets/images/thrive-average.svg';
import ThriveExcellent2 from '../../assets/images/thrive-excellent-2.svg';
import ThriveExcellent from '../../assets/images/thrive-excellent.svg';
import ThrivePoor from '../../assets/images/thrive-poor.svg';
import BottomNav from '../../components/Question/BottomNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'DailyDebriefQuestion'>;
};

const dailyDebriefContent: { [key: string]: any } = {
  content: {
    stress:
      'Stress is not always a bad thing, but it can show up in many different ways and in different areas of our lives. Stressful situations are those require us to work through discomfort or pressure towards an outcome.',
    sleep:
      'Sleep is critical for mental and physical health and performance. Rate your sleep quality and quantity and think about things you can do to optimize your sleep habits, mindset, and environment to get better quality rest every night.',
    resilience:
      'Resilience is not the absence of stress, but rather your ability to handle stress, overcome challenges, and turn obstacles into opportunities. It is our ability to withstand stress and grow from it.',
  },
  excellent2: ThriveExcellent2,
  excellent: ThriveExcellent,
  average: ThriveAverage,
  poor: ThrivePoor,
  color: '#FF7A00',
};

const fields = [
  'overallThrive',
  'hoursSleep',
  'qualitySleep',
  'stressLevel',
  'balanceInLife',
  'goalsAchieved',
  'skillsToManageStress',
];

const DailyDebriefThrive: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const store = useStore();

  const [value, setValue] = useState(0);

  const { monitorFields = [] } = store.user.debriefProfile ?? {};
  const {
    debriefData = {},
    habits = fields.filter((f) => monitorFields.includes(f)),
  } = props?.route.params ?? {};

  const task = TASK_TYPE.THRIVE;

  const onNext = useCallback(async () => {
    const monitorFields = [
      ...debriefData.eatHabits,
      ...debriefData.sweatHabits,
      ...habits,
    ];
    const data = {
      ...getSnapshot(new UserDebriefProfile({})),
      ...debriefData,
      overallThrive: Math.floor(value / 10),
      thriveHabits: habits,
      monitorFields,
    };
    if (habits.length === 0) {
      if (store.user.debriefProfile) {
        const dailyDebrief = {
          ...getSnapshot(store.user.debriefProfile),
          ...data,
        };
        const debriefProfile = {
          ...dailyDebrief,
          id: store.user.debriefProfile.id,
        };
        await store.user.updateDailyDebrief(dailyDebrief);
        await store.user.updateDebriefProfile(debriefProfile);
      } else {
        await store.user.updateDebriefProfile(data);
      }
      navigation.navigate('Today');
    } else {
      navigation.navigate('QuestionThrive', {
        debriefData: data,
        habits,
      });
    }
  }, [habits, debriefData]);

  const addHabit = useCallback(() => {
    navigation.navigate('AddHabitThrive', { habits });
  }, [habits]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Layout style={styles.navigationContainer}>
              <TopNav
                showBack
                showClose
                onClose={() => navigation.navigate('Today')}
                onBack={goBack}
              />
            </Layout>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <View style={styles.header}>
                <View
                  style={[
                    styles.titleContainer,
                    {
                      paddingTop: insets.top,
                    },
                  ]}
                >
                  <TaskIcon
                    style={styles.icon}
                    type={task}
                    width={35}
                    height={35}
                  />
                  <Text
                    category="h1"
                    style={[
                      styles.title,
                      {
                        color: getTaskColor(task),
                      },
                    ]}
                  >
                    {task}
                  </Text>
                </View>
                <Topics task={task} />
              </View>
              <View style={styles.flex}>
                <View style={styles.center}>
                  <DailyDebriefDial
                    value={value}
                    setValue={setValue}
                    debrief={dailyDebriefContent}
                    offsetY={insets.top + 120}
                    isRating={true}
                  />
                </View>
                <View style={styles.flex} />
                <Text category="s1" style={styles.note}>
                  {`Custom tailor your ${task.toUpperCase()} log by selecting the button below.`}
                </Text>
                <TouchableOpacity style={styles.addHabit} onPress={addHabit}>
                  <Icon
                    height={15}
                    width={15}
                    fill="#c4c4c4"
                    name="plus-outline"
                  />
                  <Text category="label" style={styles.add}>
                    ADD HABITS
                  </Text>
                </TouchableOpacity>
                <View style={styles.bottom} />
              </View>
            </View>
            <View style={{ paddingBottom: insets.bottom }}>
              <BottomNav
                onBack={goBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  add: {
    color: '#c4c4c4',
  },
  addHabit: {
    flexDirection: 'row',
    marginVertical: 20,
    height: 40,
    width: 150,
    borderRadius: 2,
    alignSelf: 'center',
    backgroundColor: '#203950',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    paddingHorizontal: '25%',
    overflowY: 'scroll',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    overflowY: 'scroll',
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    overflowY: 'scroll',
  },
  modalOverlay2: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal1: {
    width: '70%',
  },
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 90,
  },
  title: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: '500',
    alignSelf: 'center',
  },
  icon: {
    alignSelf: 'center',
    marginRight: 6,
  },
  note: {
    alignSelf: 'center',
    color: '#c4c4c4',
    textAlign: 'center',
    maxWidth: 224,
  },
  header: {
    height: 130,
  },
  bottom: {
    height: 10,
  },
});

export default observer(DailyDebriefThrive);
