import {
  Datepicker,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import {
  find,
  findIndex,
  get,
  indexOf,
  isUndefined,
  keys,
  omitBy,
  set,
} from 'lodash';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import UserStore from 'o2x-store/src/stores/UserStore';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dimensions,
  FlatList,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LabeledInput from 'src/components/LabeledInput';
import HeightInput from 'src/components/Question/HeightInput';
import { getErrors } from 'src/utils/errors';
import Next from '../../assets/images/next.svg';
import Previous from '../../assets/images/previous.svg';
import Logo from '../../assets/images/survey-logo.svg';

enum FIELD_TYPE {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
  HEIGHT = 'height',
}

type EditableField = {
  key: string;
  label: string;
  value: string | number | Date;
  type: FIELD_TYPE;
  options?: Array<FieldOption> | undefined;
};

type UserData = {
  user: User;
  values: Partial<User>;
};

type FieldOption = {
  label: string;
  value: string;
};

const EDITABLE_FIELDS = ['birthDate', 'gender', 'height', 'weight'];

type Props = {
  navigation: Navigator;
  closeModal: () => void;
  setDone: () => void;
  currentUser: User | null;
  userStore: UserStore | undefined;
};

const { width } = Dimensions.get('window');

const PreassessmentSurvey: React.FC<Props> = ({
  navigation,
  closeModal,
  setDone,
  currentUser,
  userStore,
}) => {
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState<UserData>();
  const [datePickerData, setDatePickerData] = useState({
    show: false,
    value: format(new Date(), 'yyyy-MM-dd'),
    field: '',
  });
  const [pickerData, setPickerData] = useState({
    show: false,
    options: [],
    value: '',
    field: '',
  });

  const profileFields: Array<EditableField> = [
    {
      key: 'birthDate',
      label: 'Date of Birth',
      value: userData?.values.birthDate || '',
      type: FIELD_TYPE.DATE,
    },
    {
      key: 'gender',
      label: 'Gender',
      value: userData?.values.gender || '',
      type: FIELD_TYPE.SELECT,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    },
    {
      key: 'height',
      label: 'Height',
      value: userData?.values.height || '',
      type: FIELD_TYPE.HEIGHT,
    },
    {
      key: 'weight',
      label: 'Weight (lbs)',
      value: userData?.values.weight || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f) => {
      register({ name: f }, { required: true });
    });
  }, [EDITABLE_FIELDS, register]);

  useEffect(() => {
    const storedUser = currentUser;
    if (storedUser) {
      const storedUserValues: Partial<User> = {};
      EDITABLE_FIELDS.forEach((f: string) =>
        set(storedUserValues, f, get(storedUser, f)),
      );
      setUserData({
        user: storedUser,
        values: storedUserValues,
      });
    }
  }, [currentUser, EDITABLE_FIELDS]);

  const onNext = useCallback(async () => {
    setDone();
  }, [setDone]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (data) {
        const result = await userStore?.updateMe(omitBy(data, isUndefined));
        setLoading(false);
        if (result?.ok) {
          keys(data).forEach((k: string) => {
            setValue(k, data[k]);
          });
          onNext();
        } else {
          setErrorMessage(getErrors(result?.errors));
        }
      }
    },
    [userData],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const getIndex = (arr: Array<FieldOption>, value: number | string) => {
    const idx = findIndex(arr, (o) => o.value === value);
    // if (idx < 0) return 0;
    return idx;
  };

  const getSelectValue = (
    options: Array<FieldOption>,
    value: string | number,
  ) => {
    const valueOption = find(options, (o) => o.value === value);
    if (valueOption) {
      return valueOption.label;
    }
    return '';
  };

  const renderSelectField = useCallback(
    (item: EditableField) => (
      <View style={styles.inputContainer}>
        {get(errors, item.key) && (
          <Text style={styles.error}>This field is required</Text>
        )}
        <Text style={styles.inputLabel}>{item.label} *</Text>
        <Select
          placeholder="Select an option"
          value={getSelectValue(item.options, get(userData?.values, item.key))}
          selectedIndex={
            getIndex(item.options, get(userData?.values, item.key)) > -1
              ? new IndexPath(
                  getIndex(item.options, get(userData?.values, item.key)),
                )
              : null
          }
          onSelect={(index) => {
            const indexStr = index.toString();
            setUserData({
              user: userData?.user,
              values: {
                ...userData?.values,
                [item.key]: item.options[Number(indexStr) - 1].value,
              },
            });
            setValue(item.key, item.options[Number(indexStr) - 1].value);
          }}
        >
          {item.options?.map((o) => (
            <SelectItem title={o.label} key={o.value} />
          ))}
        </Select>
      </View>
    ),
    [userData, errors],
  );

  const renderDatePickerField = useCallback(
    (item: EditableField) => (
      <View style={styles.inputContainer}>
        {get(errors, item.key) && (
          <Text style={styles.error}>This field is required</Text>
        )}
        <Text style={styles.inputLabel}>{item.label} *</Text>
        <Datepicker
          date={
            get(userData?.values, item.key)
              ? parseISO(get(userData?.values, item.key))
              : new Date()
          }
          onSelect={(value: Date) => {
            const newValue = value ? format(value, 'yyyy-MM-dd') : null;
            setUserData({
              ...userData,
              values: { ...userData?.values, [item.key]: newValue },
            });
            setDatePickerData({
              show: false,
              field: '',
              value: format(new Date(), 'yyyy-MM-dd'),
            });
            setValue(item.key, newValue);
          }}
          max={new Date()}
          min={new Date(1900, 0, 1)}
        />
      </View>
    ),
    [userData, errors],
  );

  const renderHeightField = useCallback(
    (item: EditableField) => {
      return (
        <View>
          {get(errors, item.key) && (
            <Text style={styles.error}>This field is required</Text>
          )}
          <Text style={styles.inputLabel} category="c2">
            {item.label} *
          </Text>
          <HeightInput
            question={{
              question: item.label,
              type: item.type,
              key: item.key,
              options: [],
            }}
            style={styles.inputContainer}
            inputStyle={styles.inputFieldHeight}
            textStyle={styles.inputFieldText}
            underlineColorAndroid="transparent"
            keyboardType="decimal-pad"
            multiline
            onChange={(k, v) => setValue(item.key, v)}
            divider={false}
            initial={item.value}
            required={false}
          />
        </View>
      );
    },
    [errors],
  );

  const renderTextField = useCallback(
    (item: EditableField) => {
      return (
        <>
          {get(errors, item.key) && (
            <Text style={styles.error}>This field is required</Text>
          )}
          <LabeledInput
            style={styles.inputContainer}
            label={`${item.label} *`}
            labelStyle={styles.inputLabel}
            inputStyle={styles.inputField}
            textStyle={styles.inputFieldText}
            onChangeText={(text) => {
              setUserData({
                ...userData,
                values: {
                  ...userData?.values,
                  [item.key]: get(userData?.user, item.key),
                },
              });
              setValue(item.key, text);
            }}
            defaultValue={item.value || ''}
            underlineColorAndroid="transparent"
            keyboardType={
              indexOf(['height', 'weight'], item.key) !== -1
                ? 'decimal-pad'
                : 'default'
            }
            multiline
          />
        </>
      );
    },
    [userData, errors],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.itemContainer}>
        <View
          style={isMobile ? styles.itemEditableMobile : styles.itemEditable}
        >
          {item.type === FIELD_TYPE.DATE && renderDatePickerField(item)}
          {item.type === FIELD_TYPE.TEXT && renderTextField(item)}
          {item.type === FIELD_TYPE.SELECT && renderSelectField(item)}
          {item.type === FIELD_TYPE.HEIGHT && renderHeightField(item)}
          <View style={styles.divider} />
        </View>
      </View>
    ),
    [
      onSubmitWrapped,
      userData,
      setDatePickerData,
      datePickerData,
      profileFields,
      errors,
    ],
  );

  const renderHeader = () => {
    return (
      <>
        <Logo />
        <Text style={[styles.category]}>Pre-assessment</Text>
      </>
    );
  };

  // checks if the initial values have been set.
  useEffect(() => {
    if (!getValues()['height']) {
      Array.from(profileFields.values()).map((item) => {
        setValue(item.key, item.value);
      });
    }
  }, [profileFields]);

  return (
    <>
      <Layout style={isMobile ? styles.surveyModalMobile : styles.surveyModal}>
        <Layout
          style={
            isMobile
              ? styles.modalSurveyContainerMobile
              : styles.modalSurveyContainer
          }
        >
          <ScrollView style={styles.scroll}>
            <Layout style={styles.hpaSurveyHeader}>{renderHeader()}</Layout>
            <Layout
              style={
                isMobile ? styles.hpaModalContentMobile : styles.hpaModalContent
              }
            >
              <Layout
                style={isMobile ? styles.modalBodyMobile : styles.modalBody}
              >
                <TouchableOpacity
                  style={
                    isMobile ? styles.modalButtonMobile : styles.modalButton
                  }
                  onPress={closeModal}
                >
                  <Previous />
                </TouchableOpacity>
                <Layout
                  style={
                    isMobile
                      ? styles.hpaSurveyContainerMobile
                      : styles.hpaSurveyContainer
                  }
                >
                  {!!errorMessage && (
                    <Text style={styles.error} category="label">
                      {errorMessage}
                    </Text>
                  )}
                  <FlatList
                    data={Array.from(profileFields.values())}
                    renderItem={renderItem}
                    keyExtractor={(item) => `${item.key}`}
                  />
                </Layout>
                <TouchableOpacity
                  style={
                    isMobile ? styles.modalButtonMobile : styles.modalButton
                  }
                  onPress={onSubmitWrapped}
                >
                  <Next />
                </TouchableOpacity>
              </Layout>
            </Layout>
          </ScrollView>
        </Layout>
      </Layout>
    </>
  );
};

const themedStyles = StyleService.create({
  hpaSurveyHeader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    paddingTop: 5,
  },
  surveyModal: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  surveyModalMobile: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  modalSurveyContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalSurveyContainerMobile: {
    width: '100%',
    height: '87%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  scroll: {
    width: '100%',
  },
  category: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
    marginLeft: 23,
    marginTop: 6,
  },
  hpaSurveyContainer: {
    width: 370,
    height: 300,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  hpaSurveyContainerMobile: {
    width: '80%',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  hpaModalContent: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 28,
    marginBottom: 60,
  },
  hpaModalContentMobile: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 20,
  },
  question: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    marginBottom: 12,
    width: '90%',
  },
  input: {
    width: '100%',
  },
  scoreText: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  asessmentDoneModal: {
    width: '100%',
    height: 466,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  asessmentDoneModalMobile: {
    width: '100%',
    height: '94%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalBody: {
    width: '100%',
    height: 300,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  modalBodyMobile: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
  },
  survey: {
    paddingLeft: 35,
    width: 320,
    backgroundColor: 'transparent',
  },
  modalButton: {
    width: 10,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalButtonMobile: {
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  surveyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  progressBar: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  progressBarMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    marginTop: 10,
  },
  doneText: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    marginBottom: 50,
  },
  doneBadge: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#4E7B89',
  },
  actions: {
    marginTop: 30,
    marginVertical: 20,
  },
  actionIcon: {
    alignSelf: 'center',
    marginBottom: 7,
  },
  actionText: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
  finishButton: {
    width: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finishButtonMobile: {
    width: '100%',
    maxWidth: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finish: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 30,
    marginTop: 0,
    height: 1,
  },
  inputContainer: {
    flex: 1,
  },
  inputLabel: {
    color: 'light-gray',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  inputField: {
    width: '100%',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldHeight: {
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldText: {
    fontSize: 18,
    color: 'light-gray',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemEditable: {
    flexDirection: 'column',
    flex: 1,
  },
  itemEditableMobile: {
    flexDirection: 'column',
    flex: 1,
    width: width * 0.8,
    alignSelf: 'center',
  },
});

export default observer(PreassessmentSurvey);
