import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserDailyThriveHabit } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import DailyLogInput from 'src/components/Question/DailyLogInput';
import BottomNav from '../../components/Question/BottomNav';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route?: any | null;
};

const ThriveHabitQuestion: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();

  const [index, setIndex] = useState(0);
  const [thriveHabit, setThriveHabit] = useState<UserDailyThriveHabit | null>(
    new UserDailyThriveHabit({}),
  );
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const question = store.question.dailyThriveHabitQuestions.has(`${index}`)
    ? store.question.dailyThriveHabitQuestions.get(`${index}`)
    : null;
  const numBars = store.question.dailyThriveHabitQuestions.size;

  const validateAnswer = useCallback(
    (question) => {
      // Check if has answer because field is required by default
      const isRequired =
        question && question.required !== undefined ? question.required : true;
      return (
        (isRequired &&
          question &&
          thriveHabit &&
          get(thriveHabit, question.key) !== null &&
          get(thriveHabit, question.key) !== '' &&
          get(thriveHabit, question.key) !== -1) ||
        !isRequired
      );
    },
    [thriveHabit],
  );

  useFocusEffect(
    useCallback(() => {
      store.question.fetchDailyThriveHabitQuestions();
    }, []),
  );

  const onBack = useCallback(() => {
    const newIndex = index - 1;
    const id = String(newIndex);
    if (store.question.dailyThriveHabitQuestions.has(id)) {
      setIndex(newIndex);
    } else {
      navigation.goBack();
    }
  }, [index, setIndex, question, store]);

  const onNext = useCallback(async () => {
    const newIndex = index + 1;
    const id = String(newIndex);
    if (store.question.dailyThriveHabitQuestions.has(id)) {
      if (validateAnswer(question)) {
        setIndex(newIndex);
      }
    } else {
      //handle final question
      const lastQuestion = store.question.dailyThriveHabitQuestions.get(
        `${index}`,
      );
      if (!validateAnswer(lastQuestion)) {
        return;
      }

      if (thriveHabit) {
        await store.user.updateDailyThriveHabit(getSnapshot(thriveHabit));
      }
      navigation.navigate('ThriveHabitSummary');
    }
  }, [index, setIndex, question, store]);

  const onChange = useCallback(
    (key: string, value: any) => {
      thriveHabit?.set(key, value);
    },
    [setThriveHabit, thriveHabit],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose={true} onClose={goBack} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : styles.contentContainer
              }
            >
              <Text style={styles.title}>Record your THRIVE habits</Text>
              <Text style={styles.description}>{question?.question}</Text>
              {question && (
                <View style={styles.questionContainer}>
                  <DailyLogInput
                    key={question.key}
                    question={question}
                    onChange={onChange}
                    initial={
                      question && thriveHabit
                        ? get(thriveHabit, question.key)
                        : ''
                    }
                    status="basic-thrive"
                    fill={theme['orange']}
                  />
                </View>
              )}
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={index} />
              <BottomNav
                onBack={onBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '25%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    marginTop: -20,
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 24,
    marginHorizontal: 20,
  },
  description: {
    fontSize: 16,
    color: 'white',
    lineHeight: 22,
    marginBottom: 12,
    marginHorizontal: 20,
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  questionContainer: {
    flex: 1,
  },
});

export default observer(ThriveHabitQuestion);
