import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Team, { TeamMember } from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { ReactNode, useMemo } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

export type Props = {
  team: Team;
  onPress: () => void;
  showCaret?: boolean;
  accessoryRight?: ReactNode;
};

const VISIBLE_TEAM_MEMBERS = 6;

const TeamItem: React.FC<Props> = (props) => {
  const { team, onPress, showCaret = false, accessoryRight } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const user = store.auth.user;

  const visibleTeamMembers = useMemo(() => {
    return team.members.slice(0, VISIBLE_TEAM_MEMBERS);
  }, [team.members]);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableOpacity
      style={isMobile ? styles.containerMobile : styles.container}
      onPress={onPress}
    >
      <Layout style={styles.contentContainer} level="2">
        <Image
          style={
            isMobile
              ? (styles.imageMobile as StyleProp<ImageStyle>)
              : (styles.image as StyleProp<ImageStyle>)
          }
          source={
            team?.logoThumbnail
              ? { uri: team?.logoThumbnail }
              : require('../../assets/images/icon.png')
          }
        />
        <View style={{ flex: 1, paddingHorizontal: 10, flexWrap: 'wrap' }}>
          <Text style={isMobile ? styles.titleMobile : styles.title}>
            {team.name}
          </Text>
          <Text style={isMobile ? styles.subtitleMobile : styles.subtitle}>
            {`Team Members (${team?.members.length})`}
          </Text>
          <View style={styles.members}>
            {visibleTeamMembers.map((member: TeamMember) => (
              <Image
                key={`member-${member.id}`}
                style={styles.memberImage as StyleProp<ImageStyle>}
                source={
                  team?.logoThumbnail
                    ? { uri: team?.logoThumbnail }
                    : require('../../assets/images/user_placeholder.png')
                }
              />
            ))}
            {team.members.length > VISIBLE_TEAM_MEMBERS && (
              <View style={styles.memberExtra}>
                <Icon
                  style={styles.memberImage}
                  name="plus-outline"
                  fill="#FFFFFF"
                />
              </View>
            )}
          </View>
        </View>
        {showCaret && (
          <View style={{ alignSelf: 'center' }}>
            <Icon style={styles.navIcon} fill="white" name="chevron-right" />
          </View>
        )}
        {accessoryRight}
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
    marginBottom: 26,
    marginHorizontal: 36,
    minHeight: 141,
  },
  containerMobile: {
    flexDirection: 'row',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
    marginBottom: 12,
    marginHorizontal: 15,
    minHeight: 120,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  content: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    flex: 1,
  },
  navIcon: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    marginBottom: 21,
  },
  titleMobile: {
    fontSize: 18,
    marginBottom: 12,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 17,
    color: 'light-gray',
    marginBottom: 8,
  },
  subtitleMobile: {
    fontSize: 12,
    color: 'light-gray',
    marginBottom: 8,
  },
  image: {
    height: 141,
    width: 141,
  },
  imageMobile: {
    height: 120,
    width: 120,
  },
  members: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  memberImage: {
    height: 24,
    width: 24,
    borderRadius: 24,
    marginRight: 6,
  },
  memberExtra: {
    height: 24,
    width: 24,
    borderRadius: 24,
    backgroundColor: 'dark-blue',
  },
});

export default TeamItem;
