import config from '../../config';
import { TASK_TYPE } from '../../utils/tasks';
import { callApiWithToken } from './base';

export const fetchTasks = async (
  token: string,
  task_type: TASK_TYPE | 'all',
  clearCache: boolean = false,
) =>
  callApiWithToken(
    config.urls.tasks + `?task_type=${task_type}`,
    token,
    'GET',
    {},
    undefined,
    clearCache,
  );
