import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { MessageModel } from 'o2x-store/src/models/Message';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Conversation from '../../../components/Contact/Conversation';
import { getErrors } from '../../../utils/errors';
import MemberListModal from './MemberListModal';
import UserMessage from './UserMessage';
import { useFocusEffect } from '@react-navigation/native';

type Props = {};
const { width, height } = Dimensions.get('window');
const Contact: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);
  const scrollViewRef = useRef<ScrollView>(null);
  const store = useStore();
  const authUserId = store.auth.user?.id;

  const [showConversation, setShowConversation] = useState(false);
  const [groupUsers, setGroupUsers] = useState<number[]>([]);
  const [selectedUserMessage, setSelectedUserMessage] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [isSendMessageModalVisible, setIsSendMessageModalVisible] =
    useState(false);
  const animation = useRef(new Animated.Value(0)).current;
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [toBeDeletedUserMessage, setToBeDeletedUserMessage] =
    useState<number>();

  const loadMessages = async () => {
    setShowConversation(false);
    setLoading(true);
    await store.contactPortal.fetchMessages();
    setLoading(false);
    setSelectedUserMessage(undefined);
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        await loadMessages();
      })();
      return () => {};
    }, []),
  );

  const hideModal = useCallback(() => {
    setIsSendMessageModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setIsSendMessageModalVisible(true);
  }, []);

  const onSelect = useCallback(
    async (data: MessageModel) => {
      setLoadingConversation(true);
      if (
        data.isUnread &&
        (data.receiver === store.auth.user?.id || data.group)
      ) {
        await store.contactPortal.markReadMessage(data);
      }

      store.contactPortal.setCurrentUserMessage(
        JSON.parse(JSON.stringify(data)),
      );
      await store.contactPortal.fetchConversation(false, true);

      setGroupUsers(data.groupUsers);
      setShowConversation(true);
      setLoadingConversation(false);
      scrollViewRef.current?.scrollToEnd({ animated: false });
    },
    [scrollViewRef],
  );

  const onDelete = useCallback((userMessageIndex: number) => {
    setIsDeleteConfirmationModalVisible(true);
    setToBeDeletedUserMessage(userMessageIndex);
    Animated.timing(animation, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, []);
  const onCancelDelete = useCallback(() => {
    setIsDeleteConfirmationModalVisible(false);
    setToBeDeletedUserMessage(undefined);
    animation.setValue(0);
  }, []);

  const onDeleteConversation = useCallback(async () => {
    if (toBeDeletedUserMessage === undefined) return;
    await store.contactPortal.deleteConversation({
      user: authUserId as number,
      sender: store.contactPortal.usersMessages[toBeDeletedUserMessage].sender,
      receiver:
        store.contactPortal.usersMessages[toBeDeletedUserMessage].receiver,
      group: store.contactPortal.usersMessages[toBeDeletedUserMessage].group,
    });
    animation.setValue(0);
    await loadMessages();
  }, [toBeDeletedUserMessage]);

  const onSubmit = useCallback(
    async (data) => {
      setIsSendMessageModalVisible(false);
      const result = await store.contactPortal.createMessage(data);
      if (result.ok) {
        await store.contactPortal.fetchConversation();
        await store.fteEvent.sendNotification({
          recipientUsers: data.receiver
            ? data.receiver.toString()
            : data.groupUsers
            ? data.groupUsers.toString().replace(/,\s*$/, '')
            : groupUsers.toString().replace(/,\s*$/, ''),
          title: 'You have a new message from your On Site Specialist',
          body: 'New Message',
          extra: {
            type: 'New Message',
            message: data,
            fte: {
              id: store.auth.user?.id,
              email: store.auth.user?.email,
              fullName: store.auth.user?.fullName,
              firstName: store.auth.user?.firstName,
              lastName: store.auth.user?.lastName,
              o2xId: store.auth.user?.o2XId,
              organizations: store.auth.user?.organization,
              profileImage: store.auth.user?.profileImage,
            },
          },
        });
        scrollViewRef.current?.scrollToEnd({ animated: true });
      } else {
        console.log(getErrors(result.errors));
      }
    },
    [groupUsers, store],
  );

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const loadingMessages = loading;

  return (
    <Layout style={styles.container}>
      {isMobile ? (
        <ScrollView contentContainerStyle={styles.mobileBg}>
          {isSendMessageModalVisible && (
            <MemberListModal
              visible={isSendMessageModalVisible}
              onBackdropPress={hideModal}
              onSubmit={async (data) => {
                await onSubmit(data);
                await loadMessages();
              }}
            />
          )}

          {showConversation ? (
            <Layout style={styles.mobileContentContainer}>
              {loadingConversation ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                <Conversation
                  setSelected={setSelectedUserMessage}
                  onSubmit={onSubmit}
                  setShowConversation={setShowConversation}
                  scrollViewRef={scrollViewRef}
                />
              )}
            </Layout>
          ) : (
            <Layout style={styles.mobileListContainer}>
              <Text style={styles.messageHeader}>Messages</Text>
              <ScrollView style={styles.messageList}>
                {loadingMessages ? (
                  <Layout style={styles.loading}>
                    <Spinner />
                  </Layout>
                ) : (
                  store.contactPortal.usersMessages?.map(
                    (userMessage, index) => {
                      return (
                        <UserMessage
                          key={index}
                          message={userMessage}
                          selected={selectedUserMessage === index}
                          onSelect={async () => {
                            setSelectedUserMessage(index);
                            await onSelect(userMessage);
                          }}
                          onDelete={() => onDelete(index)}
                          authUserId={authUserId}
                        />
                      );
                    },
                  )
                )}
              </ScrollView>
              <TouchableOpacity style={styles.submitButton} onPress={showModal}>
                <Text style={styles.buttonText}>Send a Message</Text>
              </TouchableOpacity>
            </Layout>
          )}
        </ScrollView>
      ) : (
        <Layout style={styles.bgContainer}>
          {isSendMessageModalVisible && (
            <MemberListModal
              visible={isSendMessageModalVisible}
              onBackdropPress={hideModal}
              onSubmit={async (data) => {
                await onSubmit(data);
                await loadMessages();
              }}
            />
          )}
          <Layout style={styles.listContainer}>
            <Text style={styles.messageHeader}>Messages</Text>
            <ScrollView style={styles.messageList}>
              {loadingMessages ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                store.contactPortal.usersMessages?.map((userMessage, index) => {
                  return (
                    <UserMessage
                      key={index}
                      message={userMessage}
                      selected={selectedUserMessage === index}
                      onSelect={async () => {
                        setSelectedUserMessage(index);
                        await onSelect(userMessage);
                      }}
                      onDelete={() => onDelete(index)}
                      authUserId={authUserId}
                    />
                  );
                })
              )}
            </ScrollView>
            <TouchableOpacity style={styles.submitButton} onPress={showModal}>
              <Text style={styles.buttonText}>Send a Message</Text>
            </TouchableOpacity>
          </Layout>
          <Layout style={styles.contentContainer}>
            {loadingConversation ? (
              <Layout style={styles.loading}>
                <Spinner />
              </Layout>
            ) : (
              showConversation && (
                <Conversation
                  setSelected={setSelectedUserMessage}
                  onSubmit={onSubmit}
                  setShowConversation={setShowConversation}
                  scrollViewRef={scrollViewRef}
                />
              )
            )}
          </Layout>
        </Layout>
      )}
      <View
        style={isMobile ? styles.mobileCenter : styles.center}
        pointerEvents={isDeleteConfirmationModalVisible ? undefined : 'none'}
      >
        {isDeleteConfirmationModalVisible && (
          <TouchableOpacity
            activeOpacity={1}
            style={isMobile ? styles.mobileCenter : styles.center}
            onPress={onCancelDelete}
          />
        )}
        <Animated.View
          style={[
            styles.popUpContainer,
            {
              opacity,
              transform: [{ scale }, { translateY }],
            },
          ]}
        >
          <Icon
            style={styles.savedIcon}
            name={'question-mark-circle-outline'}
            fill={'orange'}
          />
          <Text style={styles.saveText}>
            {'Are you sure you want to \n delete this conversation'}
          </Text>
          <View style={styles.buttons}>
            <TouchableOpacity style={styles.button} onPress={onCancelDelete}>
              <Text style={styles.deleteText}>NO</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button}
              onPress={onDeleteConversation}
            >
              <Text style={styles.deleteText}>YES</Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    position: 'absolute',
    width: '90%',
    height: '97%',
    left: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'row',
  },
  mobileBg: {
    position: 'absolute',
    width: '90%',
    height: '90%',
    left: '26px',
    right: '26px',
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  listContainer: {
    width: '40%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
    // paddingButtom: 24,
  },
  mobileListContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  messageHeader: {
    color: 'white',
    fontSize: 18,
    marginTop: '10px',
    marginLeft: '7.5%',
  },
  contentContainer: {
    width: '60%',
    height: '100%',
    backgroundColor: '#203950',
    flexDirection: 'column',
  },
  mobileContentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  messageList: {
    width: '85%',
    backgroundColor: '#203950',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: '7.5%',
    marginRight: '7.5%',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  profile: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: 15,
    marginLeft: 20,
  },
  conversationContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    height: '100%',
  },
  conversation: {
    width: '90%',
    maxHeight: '50%',
    marginLeft: '5%',
    marginRight: '5%',
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  send: {
    width: 20,
    height: 20,
    padding: 5,
    backgroundColor: 'olive',
  },
  messageInput: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '2%',
    maxHeight: '35%',
    backgroundColor: 'transparent',
  },
  messageInputMobile: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '2%',
    maxHeight: '30%',
    backgroundColor: 'transparent',
  },
  input: {
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  loadMore: {
    margin: 'auto',
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '85%',
    marginLeft: '7.5%',
    marginBottom: '5%',
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  close: {
    marginLeft: 'auto',
    marginRight: 10,
    alignSelf: 'center',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: width,
    height: height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
});

export default observer(Contact);
