import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { min } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback, useState } from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import ReadMore from 'react-native-read-more-text';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import NavigationBar from 'src/components/NavigationBar';
import ProgramDetailActions from 'src/components/ProgramDetailActions';
import UserDetail from 'src/components/UserDetail';
import { useSaveList } from 'src/hooks/list';
import EquipmentIcon from '../../assets/images/equipment-icon-orange.svg';
import { AppStackParamList } from '../AppContainer';
import LevelIcon from '../ProgramDetail/LevelIcon';
type Props = {
  route: RouteProp<AppStackParamList, 'ThriveYogaDetail'>;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const ThriveYogaDetail: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const { thrive } = store;
  const exercise = thrive.thriveExercises.get(`${id}`);

  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    LIST_TYPE.THRIVE_EXERCISE,
  );
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [playing, setPlaying] = useState(false);

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const initialVideoWidth = isMobile
    ? Dimensions.get('window').width * 0.8 // 80% of screen width
    : isDeviceMaxWidth600
    ? 220
    : isDeviceMaxWidth768
    ? 250
    : min([Dimensions.get('window').width * 0.5, 750]); // Maximum Width of Modal

  const opts = {
    width: initialVideoWidth,
    height: (initialVideoWidth / 4) * 3,
    playerVars: {
      modestbranding: 1,
      rel: 0,
    },
  };

  const renderTruncatedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          Read More
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const renderRevealedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          Show Less
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const onPlay = useCallback(() => {
    setPlaying(true);
    setStartTime((prevStartTime) =>
      prevStartTime ? prevStartTime : moment().toDate(),
    );
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      const logActivity = () => {
        if (startTime !== null) {
          thrive.logThriveActivity(
            THRIVE_ACTIVITY_TYPE.YOGA,
            startTime,
            moment().toDate(),
          );
        }
      };
      return logActivity;
    }, [startTime]),
  );

  const toggleBookmark = useCallback(async () => {
    if (exercise.saveList.length === 0) {
      // Save
      navigation.navigate('SaveToList', {
        listType: LIST_TYPE.THRIVE_EXERCISE,
        itemId: exercise.id,
      });
    } else {
      // Remove

      removeFromList(savedList, String(exercise.id));
    }
  }, [savedList, exercise.saveList]);

  const goBack = useCallback(() => {
    if (playing) {
      const doEnd = confirm('Are you sure you want to go back?');
      if (doEnd) {
        navigation.goBack();
      }
    } else {
      navigation.goBack();
    }
  }, [playing]);

  if (!exercise) {
    return null;
  }
  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }
              >
                {!!exercise.videoId && (
                  <View style={styles.videoContainer}>
                    <YouTube
                      videoId={exercise.videoId}
                      opts={opts}
                      onPlay={onPlay}
                      onPause={onPause}
                    />
                  </View>
                )}
                <Layout
                  style={[
                    isDeviceMaxWidth600
                      ? styles.contentMaxWidth600
                      : styles.content,
                    !exercise.videoId && { marginTop: 20 },
                  ]}
                >
                  <Text category="h2">{exercise.name}</Text>
                  <Text style={styles.subtitle} category="c2">
                    {exercise.duration}
                  </Text>
                  <ProgramDetailActions
                    style={styles.actions}
                    onStart={null}
                    onPreview={null}
                    onSave={toggleBookmark}
                    onShare={null}
                    onQuit={null}
                    saved={exercise.saveList.length > 0}
                  />
                  {!!exercise.equipment && (
                    <View style={styles.extraSection}>
                      <View style={styles.extraSectionLabel}>
                        <EquipmentIcon width={20} color={theme['orange']} />
                        <Text style={styles.extraSectionLabelText}>
                          EQUIPMENT
                        </Text>
                      </View>
                      <Text style={styles.extraSectionText}>
                        {exercise.equipment}
                      </Text>
                    </View>
                  )}
                  {!!exercise.difficulty && (
                    <View style={[styles.extraSection]}>
                      <View style={styles.extraSectionLabel}>
                        <LevelIcon color={theme['orange']} />
                        <Text style={styles.extraSectionLabelText}>LEVEL</Text>
                      </View>
                      <Text style={styles.extraSectionText}>
                        {Humanize.titleCase(exercise.difficulty)}
                      </Text>
                    </View>
                  )}
                  {!!exercise.author && (
                    <UserDetail
                      style={styles.author}
                      author={exercise.author}
                    />
                  )}
                  {!!exercise.description && (
                    <View style={styles.description}>
                      <ReadMore
                        numberOfLines={5}
                        renderTruncatedFooter={renderTruncatedFooter}
                        renderRevealedFooter={renderRevealedFooter}
                      >
                        <Text category="p2">{exercise.description}</Text>
                      </ReadMore>
                    </View>
                  )}
                </Layout>
              </Layout>
            </ScrollView>
            <NavigationBar
              style={[styles.navigation, { top: insets.top }]}
              showBack={false}
              showClose={true}
              onClose={goBack}
              withBackground={false}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  actions: {
    marginTop: 20,
    marginBottom: 30,
  },
  author: {
    marginTop: 29,
    marginBottom: 6,
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: '15%',
  },
  contentMaxWidth600: {
    paddingVertical: 10,
    paddingHorizontal: '5%',
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
    paddingHorizontal: 24,
  },
  rating: {
    marginVertical: 5,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
    color: 'orange',
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    color: 'orange',
  },
  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    margiVertical: 20,
  },
  description: {
    marginTop: 10,
  },
  videoContainer: {
    alignSelf: 'center',
  },
});

export default observer(ThriveYogaDetail);
