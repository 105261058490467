import { Text } from '@ui-kitten/components';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React from 'react';
import { Dimensions, View } from 'react-native';

type Props = {
  data: any;
};

const Slide: React.FC<Props> = (props) => {
  const windowWidth = Dimensions.get('window').width;
  const { data } = props;
  const { sweat } = useStore();
  return (
    <View
      style={{
        alignItems: 'flex-start',
        width: '80%',
        margin: 10,
      }}
    >
      <Text style={{ fontSize: 18, width: '100%' }}>
        {data?.title ? data.title : 'No results'}
      </Text>
      <Text style={{ fontSize: 12 }}>{data?.subtitle}</Text>
      {data?.steps.map((step: SweatGlobalStep) => {
        return step.exercises?.map((exercise: SweatGlobalStepExercise) => {
          const exerciseInfo = sweat.getSweatExercise(exercise.exercise);
          return (
            <Text key={step.id + '-' + exercise.id} style={{ fontSize: 12 }}>
              {exerciseInfo?.name}
            </Text>
          );
        });
      })}
    </View>
  );
};

export default Slide;
