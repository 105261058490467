import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import LabeledInput from '../../components/LabeledInput';
import { NetPromoterModalParamList } from '../AppContainer/modals/NetPromoterStackModal';

type Props = {
  route: RouteProp<NetPromoterModalParamList, 'NetPromoterModal'>;
};

const NetPromoterModal: React.FC<Props> = (props) => {
  const { eventId } = props.route.params;
  const store = useStore();
  const user = store.auth.user;
  const event = store.event.events.get(`${eventId}`);
  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState('');
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const stars = Array.from(Array(11), (_, i) => i);

  const onChangeRating = useCallback((newRating) => {
    setRating((rating) => {
      if (rating === newRating) {
        return 0;
      } else {
        return newRating;
      }
    });
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onRate = useCallback(async () => {
    const res = await store.event.updateCheckin(`${eventId}`, {
      id: event?.checkin ? event?.checkin.id : undefined,
      promoterScore: rating,
      promoterFeedback: feedback,
    });
    if (res.ok) {
      navigation.goBack();
    }
  }, [rating, feedback]);

  return (
    <View style={styles.container}>
      <Layout style={styles.modal}>
        <View style={styles.content}>
          <Text
            style={styles.header}
            category="c1"
          >{`How likely would you recommend the O2X app to your friends?`}</Text>
          <View style={styles.ratingContainer}>
            {stars.map((rate) => (
              <TouchableOpacity
                style={[
                  styles.ratingButton,
                  rating && rate <= rating && styles.ratingButtonSelected,
                ]}
                key={`${rate}`}
                onPress={() => onChangeRating(rate)}
              >
                <Text
                  style={[
                    styles.rateText,
                    rating && rate <= rating && styles.rateTextSelected,
                  ]}
                >
                  {rate}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <View style={styles.legend}>
          <Text style={styles.legendText}>Not at all likely</Text>
          <View style={styles.legendSpacing} />
          <Text style={styles.legendText}>Extremely likely</Text>
        </View>
        <View style={styles.feedback}>
          <LabeledInput
            autoCapitalize="none"
            returnKeyType="done"
            onChangeText={(text) => setFeedback(text)}
            label="Comments"
            divider
            multiline
          />
        </View>
        <View style={styles.actions}>
          <TouchableOpacity style={styles.action} onPress={goBack}>
            <Text style={styles.actionText} category="c1">
              Cancel
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.action, styles.rateAction]}
            onPress={onRate}
          >
            <Text style={styles.actionText} category="c1">
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </View>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  action: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    marginTop: 12,
  },
  actionText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000080',
  },
  content: {
    padding: 24,
  },
  header: {
    marginBottom: 30,
  },
  modal: {
    maxWidth: 268,
    borderRadius: 4,
    overflow: 'hidden',
  },
  rateAction: {
    backgroundColor: 'olive',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -10,
  },
  ratingButton: {
    padding: 4,
    borderWidth: 1,
    borderColor: 'gray',
    backgroundColor: 'black',
  },
  ratingButtonSelected: {
    borderColor: 'white',
    backgroundColor: 'white',
  },
  rateText: {
    color: 'gray',
  },
  rateTextSelected: {
    color: 'gray',
  },
  legend: {
    flexDirection: 'row',
    marginBottom: 30,
    marginTop: -10,
  },
  legendSpacing: {
    flex: 1,
  },
  legendText: {
    maxWidth: '20%',
    fontSize: 12,
    textAlign: 'center',
    marginHorizontal: 15,
  },
  feedback: {
    paddingHorizontal: 24,
  },
});

export default NetPromoterModal;
