import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchSweatEquipments = async (url: string, token: string) =>
  callApiWithToken(url || config.urls.sweatEquipments, token);

export const fetchSweatProfessions = async (token: string) =>
  callApiWithToken(config.urls.sweatProfessions, token);

export const fetchSweatCategories = async (token: string) =>
  callApiWithToken(config.urls.sweatCategories, token);

export const fetchSweatPrograms = async (token: string, url?: string) =>
  callApiWithToken(
    url || config.urls.sweatPrograms,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchSweatWorkouts = async (token: string, url?: string) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchActiveSweatPrograms = async (
  token: string,
  userId: number | undefined,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}?active=True&user=${userId ? userId : ''}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchSweatProgramsByIds = async (
  token: string,
  ids: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.sweatPrograms}?id__in=${ids}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const searchSweatPrograms = async (
  token: string,
  qs: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.sweatPrograms}?${qs}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchSweatExercise = async (token: string, exerciseId: number) =>
  callApiWithToken(`${config.urls.sweatExercises}${exerciseId}/`, token);

export const fetchSweatExercises = async (token: string, url?: string) =>
  callApiWithToken(url || config.urls.sweatExercises, token, 'GET');

export const startSweatProgram = async (
  token: string,
  id: number,
  params?: string,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${id}/start/${params ? params : ''}`,
    token,
    'POST',
  );

export const batchStartSweatPrograms = async (token: string, data: Object) =>
  callApiWithToken(
    config.urls.sweatPrograms + 'batch_start/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const markCompleteProgram = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${id}/mark_complete/`,
    token,
    'POST',
  );

export const findSweatPrograms = async (token: string) =>
  callApiWithToken(`${config.urls.sweatPrograms}?r=1&limit=5`, token, 'GET');

export const fetchSweatProgramRatings = async (
  token: string,
  program: number,
) => callApiWithToken(`${config.urls.sweatPrograms}${program}/ratings/`, token);

export const postSweatProgramRate = async (
  token: string,
  program: number,
  rating: number,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${program}/rate/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ rating }),
  );

export const fetchSweatProgramWorkouts = async (
  token: string,
  program: number,
) =>
  callApiWithToken(`${config.urls.sweatPrograms}${program}/workouts/`, token);

export const fetchSweatProgramWorkout = async (
  token: string,
  program: number,
  workout: number,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${program}/workouts/${workout}/`,
    token,
  );

export const startSweatProgramWorkout = async (
  token: string,
  program: number,
  workout: number,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${program}/workouts/${workout}/start/`,
    token,
    'POST',
  );

export const markCompleteSweatProgramWorkout = async (
  token: string,
  program: number,
  workout: number,
  data: {
    totalTime: number;
    stepExerciseTimeBreakdown: { [key: number]: number };
    globalStepExerciseTimeBreakdown: { [key: number]: number };
  },
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${program}/workouts/${workout}/mark_complete/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchActiveSweatGlobalSteps = async (token: string) =>
  callApiWithToken(`${config.urls.sweatGlobalSteps}?is_active=true`, token);

export const fetchFTESweatGlobalSteps = async (
  token: string,
  fte: number,
  type: string,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.sweatGlobalSteps}?step_type=${type}&fte=${fte}&limit=10`,
    token,
  );

export const createSweatGlobalSteps = async (
  token: string,
  data: SweatGlobalStep,
) =>
  callApiWithToken(
    `${config.urls.sweatGlobalSteps}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateSweatGlobalStep = async (
  token: string,
  id: number,
  data: SweatGlobalStep,
) =>
  callApiWithToken(
    `${config.urls.sweatGlobalSteps}${id}/`,
    token,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deleteSweatGlobalStep = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.sweatGlobalSteps}${id}`, token, 'DELETE');

export const fetchSweatWorkout = async (token: string, workoutId: number) =>
  callApiWithToken(`${config.urls.sweatWorkouts}${workoutId}/`, token);

export const fetchSweatWorkoutByIds = async (token: string, ids: string) =>
  callApiWithToken(`${config.urls.sweatWorkouts}?id__in=${ids}`, token);

export const fetchActiveSweatWorkouts = async (
  token: string,
  userId: number | undefined,
) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}?active=True&user=${userId ? userId : ''}`,
    token,
  );

export const generateSweatWorkout = async (token: string, params: Object) =>
  callApiWithToken(
    config.urls.sweatWorkouts + 'generate/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const generateSweatWorkouts = async (
  token: string,
  params: Object,
  url?: string,
) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts + 'generate_workouts_trial/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const filterSweatWorkoutsExactMatch = async (
  token: string,
  params: Object,
  url?: string,
) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts + 'workouts_filter_exact_match/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const filterSweatWorkoutsSomeMatch = async (
  token: string,
  params: Object,
  url?: string,
) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts + 'workouts_filter_some_match/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const filterSweatWorkoutsPopular = async (
  token: string,
  params: Object,
  url?: string,
) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts + 'workouts_filter_popular/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const generateSweatFilterExclusionWorkouts = async (
  token: string,
  params: Object,
  url?: string,
) =>
  callApiWithToken(
    url || config.urls.sweatWorkouts + 'generate_filter_exclusion_workouts/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const generateSweatWorkoutCount = async (
  token: string,
  params: Object,
) =>
  callApiWithToken(
    config.urls.sweatWorkouts + 'generate_workout_count_trial/',
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(params)),
  );

export const startSweatWorkout = async (token: string, workout: number) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}${workout}/start/`,
    token,
    'POST',
  );

export const markCompleteSweatWorkout = async (
  token: string,
  workout: number,
  data: {
    totalTime: number;
    stepExerciseTimeBreakdown: { [key: number]: number };
    globalStepExerciseTimeBreakdown: { [key: number]: number };
  },
) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}${workout}/mark_complete/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchSweatProgramPreview = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.sweatPrograms}${id}/preview/`, token, 'GET');

export const fetchRandomSweatImageFile = async (token: string) =>
  callApiWithToken(`${config.urls.sweatImageFiles}random/`, token);

export const fetchSweatWorkoutDetail = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}${id}/workout_detail/`,
    token,
    'GET',
  );
