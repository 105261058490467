import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  SnapshotOutOf,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import Team, {
  TeamActivity,
  TeamAssessment,
  UserReaction,
} from '../models/Team';
import * as api from '../services/api';
import { EMOJI_NAMES } from '../utils/emoji';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/TeamStore')
export default class TeamStore extends Model({
  teams: prop_mapObject(() => new Map<string, Team>()),
  pending_teams: prop_mapObject(() => new Map<string, Team>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchTeams = _async(function* (this: TeamStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Team>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchTeams(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching Teams', error);
      return getError(error);
    }

    this.teams.clear(); // Need to clear to handle delete

    results.forEach((teamData) => {
      const id = `${teamData.id}`;

      const updatedTeamData = {
        ...teamData,
        teamActivities: teamData.teamActivities?.map(
          (activity) => new TeamActivity(activity),
        ),
        physicalAssessments: teamData.physicalAssessments?.map(
          (assessmentData) => new TeamAssessment(assessmentData),
        ),
        formAssessments: teamData.formAssessments?.map(
          (assessmentData) => new TeamAssessment(assessmentData),
        ),
      };
      const team = new Team(updatedTeamData);
      this.teams.set(id, team);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPendingTeams = _async(function* (this: TeamStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Team>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPendingTeams(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching Teams', error);
      return getError(error);
    }

    this.pending_teams.clear(); // Need to clear to handle delete

    results.forEach((teamData) => {
      const id = `${teamData.id}`;
      const updatedTeamData = {
        ...teamData,
        teamActivities: teamData.teamActivities?.map(
          (activity) => new TeamActivity(activity),
        ),
        physicalAssessments: teamData.physicalAssessments?.map(
          (assessmentData) => new TeamAssessment(assessmentData),
        ),
        formAssessments: teamData.formAssessments?.map(
          (assessmentData) => new TeamAssessment(assessmentData),
        ),
        pending: true,
      };
      const team = new Team(updatedTeamData);
      this.pending_teams.set(id, team);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchTeamActivity = _async(function* (
    this: TeamStore,
    teamId: number,
    page?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<TeamActivity>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchTeamActivity(rootStore.auth.token, teamId, page),
      ));
      const team = this.teams.get(`${teamId}`);
      if (team) {
        const teamActivities = new Array<TeamActivity>();
        results.forEach((teamActivityData) => {
          teamActivities.push(new TeamActivity(teamActivityData));
        });
        team.teamActivities = teamActivities;
        this.teams.set(`${teamId}`, team);
      }
    } catch (error) {
      console.warn('[DEBUG] error fetching Team activities', error);
      this.loading = false;
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchTeamAssessment = _async(function* (this: TeamStore, teamId: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTeamPhysicalAssessments(rootStore.auth.token, teamId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching Team physical assessments', error);
      return getError(error);
    }

    const team = this.teams.get(`${teamId}`);

    if (team) {
      const teamAssessment = new Array<TeamAssessment>();
      entities.forEach(
        (teamAsessmentData: ModelInstanceCreationData<TeamAssessment>) => {
          teamAssessment.push(new TeamAssessment(teamAsessmentData));
        },
      );
      team.physicalAssessments = teamAssessment;
      this.teams.set(`${teamId}`, team);
    }

    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTeamFormAssessments(rootStore.auth.token, teamId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching Team form assessments', error);
      return getError(error);
    }

    if (team) {
      const teamAssessment = new Array<TeamAssessment>();
      entities.forEach(
        (teamAsessmentData: ModelInstanceCreationData<TeamAssessment>) => {
          teamAssessment.push(new TeamAssessment(teamAsessmentData));
        },
      );
      team.formAssessments = teamAssessment;
      this.teams.set(`${teamId}`, team);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createTeam = _async(function* (
    this: TeamStore,
    data: Partial<SnapshotOutOf<Team>>,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<Team>;
    try {
      const {
        response: { entities },
      } = yield* _await(api.createTeam(rootStore.auth.token, data));
      // Will fetch teams on reload, no need to append here
    } catch (error) {
      console.warn('[DEBUG] create team error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateTeam = _async(function* (
    this: TeamStore,
    data: Partial<SnapshotOutOf<Team>>,
    id: string,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.updateTeam(rootStore.auth.token, data, id));
      this.teams.set(`${entities.id}`, new Team(entities));
    } catch (error) {
      console.warn('[DEBUG] update team error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteTeam = _async(function* (this: TeamStore, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const { response } = yield* _await(
        api.deleteTeam(rootStore.auth.token, id),
      );
      // Will fetch on reload, no need to remove here
      // if (!('error' in response)) {
      //   this.teams.delete(`${id}`);
      // }
    } catch (error) {
      console.warn('[DEBUG] delete team error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  validateUser = _async(function* (this: TeamStore, identifier: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(
        api.validateUser(rootStore.auth.token, identifier),
      );
    } catch (error) {
      console.warn('[DEBUG] update validate user error', error);
      return getError(error);
    }

    this.loading = false;
    return response;
  });

  @modelFlow
  fetchMembers = _async(function* (this: TeamStore, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(api.fetchMembers(rootStore.auth.token, id));
    } catch (error) {
      console.warn('[DEBUG] fetch members error', error);
      return getError(error);
    }

    this.loading = false;
    return response;
  });

  @modelFlow
  fetchPendingMembers = _async(function* (this: TeamStore, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(
        api.fetchPendingMembers(rootStore.auth.token, id),
      );
    } catch (error) {
      console.warn('[DEBUG] fetch members error', error);
      return getError(error);
    }

    this.loading = false;
    return response;
  });

  @modelFlow
  addReaction = _async(function* (
    this: TeamStore,
    reaction: EMOJI_NAMES,
    id: string,
    teamId: string,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<TeamActivity>;
    try {
      const {
        response: { entities },
      } = yield* _await(
        api.addReaction(rootStore.auth.token, reaction, id, teamId),
      );
      const team = this.teams.get(`${teamId}`);
      if (team) {
        team.teamActivities = team.teamActivities.map(
          (activity: TeamActivity) =>
            activity.id === entities.id ? new TeamActivity(entities) : activity,
        );
        this.teams.set(`${teamId}`, team);
      }
    } catch (error) {
      console.warn('[DEBUG] add reaction error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  getReactions = _async(function* (
    this: TeamStore,
    id: string,
    teamId: string,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: Array<UserReaction>;

    try {
      const {
        response: { entities },
      } = yield* _await(api.getReactions(rootStore.auth.token, id, teamId));
      this.loading = false;
      return entities;
    } catch (error) {
      console.warn('[DEBUG] get reactions error', error);
      this.loading = false;
      return getError(error);
    }
  });

  @modelFlow
  sendChallenge = _async(function* (
    this: TeamStore,
    teamId: number,
    sweatProgramId?: number | undefined,
    thriveProgramId?: number | undefined,
    sweatWorkoutId?: number | undefined,
    physicalAssessmentId?: number | undefined,
    formAssessmentId?: number | undefined,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(
        api.sendChallenge(
          rootStore.auth.token,
          teamId,
          sweatProgramId,
          thriveProgramId,
          sweatWorkoutId,
          physicalAssessmentId,
          formAssessmentId,
        ),
      );
    } catch (error) {
      console.warn('[DEBUG] send challenge error', error);
      return getError(error);
    }

    this.loading = false;
    return response.response.entities;
  });

  @modelFlow
  undoChallenge = _async(function* (
    this: TeamStore,
    teamId: number,
    actionId: number,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(
        api.undoChallenge(rootStore.auth.token, teamId, actionId),
      );
    } catch (error) {
      console.warn('[DEBUG] undo challenge error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
