import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get, indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UserDailyEatHabit } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from '../../components/Question/BottomNav';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';
import ScoreBadge from '../../components/ScoreBadge';

type Props = {
  route?: any | null;
};

const EatHabitSummary: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const [eatHabit, setEatHabit] = useState<UserDailyEatHabit | null>(null);
  const [numBars, setNumBars] = useState<number>(0);
  const monitorFields = store.user.eatProfile?.monitorFields || [];

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    const eatHabit = store.user.dailyEatHabit;
    if (eatHabit) {
      setEatHabit(eatHabit);
    }
    const keys = [...store.question.dailyEatHabitQuestions.keys()];
    setNumBars(keys.length);
  }, [eatHabit, store.question.dailyEatHabitQuestions]);

  const onNext = useCallback(() => {
    navigation.popToTop();
  }, []);

  const goBack = useCallback(() => {
    navigation.popToTop();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose={true} onClose={goBack} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <Text style={styles.title}>Thank you!</Text>
              <Text style={styles.description}>View your summary below.</Text>
              <ScrollView>
                {eatHabit && (
                  <View style={styles.summaryContainer}>
                    {indexOf(monitorFields, 'waterOz') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.waterOz}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'waterOz')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          ounces of water a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'fruitsPerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.fruitsPerDay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'fruitsPerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          servings of fruits a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'caffeinePerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.caffeinePerDay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'caffeinePerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          caffeinated drinks a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'treatsPerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.treatsPerDay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'treatsPerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          indulgent food items a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'vegetablesPerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.vegetablesPerDay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'vegetablesPerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          servings of vegetables a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'alcoholPerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.alcoholPerDay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'alcoholPerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          alcoholic drinks a day
                        </Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'caloriesPerDay') > -1 && (
                      <View style={styles.fieldValue}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.caloriesPerDay || 'N/A'}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'caloriesPerDay')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>calories a day</Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'energyLevel') > -1 && (
                      <View style={[styles.fieldValue, styles.fieldValueFull]}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.energyLevelDisplay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'energyLevel')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>energy level a day</Text>
                      </View>
                    )}
                    {indexOf(monitorFields, 'weightGoal') > -1 && (
                      <View style={[styles.fieldValue, styles.fieldValueFull]}>
                        <View style={styles.fieldText}>
                          <Text style={styles.fieldNumber}>
                            {eatHabit.weightGoalDisplay}
                          </Text>
                          <ScoreBadge
                            type={get(eatHabit.score, 'weightGoal')}
                            style={styles.badge}
                            size={16}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>goal a day</Text>
                      </View>
                    )}
                  </View>
                )}
              </ScrollView>
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={numBars} />
              <BottomNav
                showBack={false}
                onNext={onNext}
                nextLabel="Finish"
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '25%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 25,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 25,
  },
  summaryContainer: {
    marginVertical: 12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fieldLabel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  fieldValue: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 12,
    marginBottom: 12,
    width: '40%',
    marginRight: '10%',
  },
  fieldValueFull: {
    width: '180%',
  },
  fieldNumber: {
    fontSize: 36,
    color: 'white',
  },
  fieldUnit: {
    fontSize: 10,
    lineHeight: 12,
    color: 'blue-secondary',
    textTransform: 'uppercase',
  },
  fieldText: {
    flexDirection: 'row',
  },
  badge: {
    marginLeft: 12,
  },
});

export default observer(EatHabitSummary);
