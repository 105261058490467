import { useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SavedList from 'o2x-store/src/models/SavedList';
import ThriveExercise from 'o2x-store/src/models/ThriveExercise';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback } from 'react';
import { ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native';
import ImageLoad from 'react-native-image-placeholder';
import { useSaveList } from 'src/hooks/list';

export type Props = {
  item: ThriveExercise;
  savedList: Map<string, SavedList> | undefined;
};

const ThriveYogaItem: React.FC<Props> = (props) => {
  const { item, savedList } = props;
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [_, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    LIST_TYPE.THRIVE_EXERCISE,
  );

  const onOpen = useCallback(() => {
    navigation.navigate('ThriveYogaDetail', {
      id: item.id,
    });
  }, [item]);

  const toggleBookmark = useCallback(async () => {
    if (item.saveList.length === 0) {
      // Save
      navigation.navigate('SaveToList', {
        listType: LIST_TYPE.THRIVE_EXERCISE,
        itemId: item.id,
      });
    } else {
      // Remove

      removeFromList(savedList, String(item.id));
    }
  }, [savedList, item.saveList]);

  return (
    <Layout style={styles.container}>
      <TouchableOpacity style={styles.yoga} onPress={onOpen}>
        <TouchableOpacity
          style={styles.bookmarkContainer}
          onPress={toggleBookmark}
        >
          <Icon
            style={styles.bookmark}
            fill={theme['white']}
            name={item.saveList.length === 0 ? 'bookmark-outline' : 'bookmark'}
          />
        </TouchableOpacity>
        <Layout style={styles.imageWrapper}>
          {!!item.imageThumbnail || !!item.videoId ? (
            <ImageLoad
              style={styles.image as StyleProp<ImageStyle>}
              source={{
                uri: !!item.imageThumbnail
                  ? item.imageThumbnail
                  : `https://img.youtube.com/vi/${item.videoId}/0.jpg`,
              }}
              loadingStyle={{ size: 'small', color: '#091C2D' }}
              placeholderSource={require('../../assets/images/thrive-icon.svg')}
            />
          ) : null}
        </Layout>
        <Layout style={styles.content} level="2">
          <View style={styles.titleContainer}>
            <Text numberOfLines={2} style={styles.programText}>
              {item.name}
            </Text>
          </View>

          <Layout style={styles.footerContainer}>
            <View style={styles.subtitleContainer}>
              <Text style={styles.subtitle}>{item.duration}</Text>
            </View>

            <Text style={styles.difficulty}>
              {item.difficulty || 'All Levels'}
            </Text>
          </Layout>
        </Layout>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bookmark: {
    width: 25,
    height: 25,
  },
  bookmarkContainer: {
    backgroundColor: 'transparent',
    zIndex: 1,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  bookmarkFilled: {
    background: 'white',
    color: 'white',
  },
  container: {
    backgroundColor: 'transparent',
    width: 242,
  },
  content: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
    marginRight: 4,
    marginVertical: 8,
  },
  yoga: {
    width: 232,
    height: 220,
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'orange',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  imageWrapper: {
    width: 228,
    height: 140,
    backgroundColor: 'transparent',
  },
  indicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  programText: {
    fontSize: 14,
    lineHeight: 17,
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  ratingBackground: {
    position: 'absolute',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    paddingVertical: 3,
    color: 'orange',
  },
  titleContainer: {
    flex: 1,
  },
  footerContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    paddingRight: 5,
  },

  subtitleContainer: {
    flex: 1,
  },
  difficulty: {
    textTransform: 'uppercase',
    fontSize: 9,
    lineHeight: 11,
    color: 'white',
  },
});

export default observer(ThriveYogaItem);
