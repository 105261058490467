import { useFocusEffect, useNavigation } from '@react-navigation/core';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import { MessageModel } from 'o2x-store/src/models/Message';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TopNav from 'src/components/Question/TopNav';

type Props = {
  route: any;
};

const { height } = Dimensions.get('window');

const MessageListFte: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const scrollViewRef = useRef<ScrollView>(null);

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessageModel[]>([]);

  const store = useStore();

  const { selectedFte } = props.route.params;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        let url = `${config.urls.contact}my_messages_staging/?sender=${selectedFte.id}`;
        const fetchData = await store.contact.fetchMessages(url);
        setMessages(fetchData.extra as MessageModel[]);
        setLoading(false);
      })();
    }, []),
  );

  const onPressMessage = useCallback(
    (message) => {
      navigation.navigate('ContactFte', {
        selectedFte,
        message,
      });
    },
    [selectedFte],
  );

  const emptyMessages = useCallback(
    () => (
      <View style={{ marginVertical: 80, flex: 1 }}>
        <Text style={{ alignSelf: 'center', color: '#8F9BB3', opacity: 0.7 }}>
          NO MESSAGES
        </Text>
        <TouchableOpacity
          style={{
            marginTop: 20,
            borderColor: 'white',
            borderWidth: 1,
            height: 50,
            alignSelf: 'center',
            borderStyle: 'dashed',
            opacity: 0.4,
          }}
          onPress={() => onPressMessage({})}
        >
          <Text
            style={{
              alignSelf: 'center',
              color: '#8F9BB3',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginHorizontal: 20,
            }}
          >
            Contact your OSS
          </Text>
        </TouchableOpacity>
      </View>
    ),
    [],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={styles.messageItem}
        onPress={() => onPressMessage(item)}
      >
        <Image
          style={styles.profile as StyleProp<ImageStyle>}
          source={
            item.group
              ? require('../../assets/images/user_placeholder.png')
              : store.auth.user?.id === item.sender
              ? item.receiverDetails?.profileImage
                ? { uri: item.receiverDetails?.profileImage }
                : require('../../assets/images/user_placeholder.png')
              : item.senderDetails?.profileImage
              ? { uri: item.senderDetails?.profileImage }
              : require('../../assets/images/user_placeholder.png')
          }
        />
        <View style={styles.titleContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {!!item.group
              ? item.groupDetails
              : store.auth.user?.id === item.sender
              ? item.receiverDetails.fullName
                ? item.receiverDetails.fullName
                : item.receiverDetails.email
              : item.senderDetails.fullName
              ? item.senderDetails.fullName
              : item.senderDetails.email}
          </Text>
          <Text style={styles.date}>
            {format(parseISO(item.created), 'yyyy-MM-dd H:mma')}
          </Text>
        </View>
        {item.isUnread && item.sender != store.auth.user?.id && (
          <View style={styles.notification} />
        )}
      </TouchableOpacity>
    ),
    [],
  );
  const keyExtractor = useCallback((item) => item.id.toString(), []);

  return (
    <Layout
      style={[
        styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={styles.header}>
        <Text>Messages</Text>
        <TouchableOpacity
          onPress={() => onPressMessage({})}
          style={{ marginLeft: 'auto' }}
        >
          <Icon
            name="edit-outline"
            fill="white"
            style={[
              {
                height: 30,
                width: 30,
              },
            ]}
          />
        </TouchableOpacity>
      </View>
      {loading ? (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      ) : (
        <FlatList
          data={messages}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          //   ListHeaderComponent={renderHeader}
          ListEmptyComponent={emptyMessages}
        />
      )}

      <View style={[styles.navigationContainer]}>
        <TopNav showClose={false} style={styles.topNav} />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    paddingLeft: 60,
    paddingRight: 20,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  receiverMessage: {
    marginRight: 'auto',
    marginBottom: '2%',
    color: 'white',
    padding: 10,
    maxWidth: '80%',
    backgroundColor: 'olive',
    fontSize: 14,
    borderRadius: 4,
  },
  senderMessage: {
    marginLeft: 'auto',
    marginBottom: '2%',
    color: 'white',
    padding: 10,
    maxWidth: '80%',
    backgroundColor: '#091C2D',
    fontSize: 14,
    borderRadius: 4,
  },
  loading: {
    margin: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  conversationContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  messageContainer: {
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  receiverDate: {
    marginRight: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  senderDate: {
    marginLeft: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  send: {
    width: 24,
    height: 24,
  },
  input: {
    backgroundColor: '#0B1F35',
    color: 'white',
    maxHeight: 70,
    borderColor: 'transparent',
  },
  sendButton: {
    height: 40,
    width: 40,
    backgroundColor: 'olive',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  messageItem: {
    marginHorizontal: 20,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
    borderLeftWidth: 5,
    borderColor: '#4E7B89',
    flexDirection: 'row',
  },
  titleContainer: {
    // flex: 1,
    // marginVertical: 8,
    width: '100%',
    marginHorizontal: 18,
  },
  bodyContainer: {
    marginVertical: 12,
    marginHorizontal: 10,
  },
  title: {
    // flex: 1,
    marginTop: 'auto',
    // marginBottom: 'auto',
    maxWidth: '70%',
    fontSize: 20,
    fontWeight: '700',
    color: 'white',
  },
  profile: {
    height: 60,
    width: 60,
    borderRadius: 30,
    marginVertical: 15,
    marginLeft: 10,
  },
  date: {
    lineHeight: 11,
    letterSpacing: 0.05,
    marginTop: 8,
    marginBottom: 'auto',
    color: '#C4C4C4',
    fontSize: 12,
  },
  notification: {
    position: 'absolute',
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: 'green',
    marginTop: 12,
    marginLeft: 8,
  },
});

export default observer(MessageListFte);
