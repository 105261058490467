import { filter, uniq } from 'lodash';
import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import Url from 'url-parse';
import { LIST_TYPE } from '../utils/list';
import { getTaskColor, TASK_TYPE } from '../utils/tasks';
import { Author } from './Program';
@model('o2x-store/ThriveExercise')
export default class ThriveExercise extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  author: prop<Author>(),
  description: prop<string>(''),
  imageThumbnail: prop<string>(''),
  imageBanner: prop<string>(''),
  video: prop<string | null>(null),
  videoLink: prop<string>(''),
  equipment: prop<string>(''),
  difficulty: prop<string>(''),
  duration: prop<string>(''),
  category: prop<number | null>(null),
  isYoga: prop<boolean>(false),
  saveList: prop<Array<number>>(() => new Array<number>()),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<ThriveExercise>) {
    Object.assign(this, data);
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @computed
  get title(): string {
    return this.name;
  }

  @computed
  get subtitle(): string {
    return this.duration;
  }

  @computed
  get type(): TASK_TYPE {
    return TASK_TYPE.THRIVE;
  }

  @computed
  get listType(): LIST_TYPE {
    return LIST_TYPE.THRIVE_EXERCISE;
  }

  @computed
  get color(): string {
    return getTaskColor(TASK_TYPE.THRIVE);
  }

  @computed
  get videoId(): string | undefined {
    // Assumes that the videoLink is youtube.
    const url = new Url(this.videoLink, true);
    return url.query.v;
  }
}
