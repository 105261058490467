import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { FTESweatWorkout } from 'o2x-store/src/models/FTELibrary';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import CreateStep from 'src/components/FTE/CreateStep';

type Props = {
  style?: StyleProp<ViewStyle>;
  onClose: () => void;
  workout?: SweatWorkout | FTESweatWorkout;
  libraryId?: number | undefined;
  workoutLibrary?: Number[];
  prepareStep?: SweatGlobalStep;
  recoverStep?: SweatGlobalStep;
};

const CreateGlobalStep: React.FC<Props> = (props) => {
  const { onClose, style, prepareStep, recoverStep } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [prepareName, setPrepareName] = useState<string>(prepareStep?.name);
  const [recoverName, setRecoverName] = useState<string>(recoverStep?.name);
  const [prepExerModalVisible, setPrepExerModalVisible] = useState(false);
  const [recExerModalVisible, setRecExerModalVisible] = useState(false);
  const [prepParamModalVisible, setPrepParamModalVisible] = useState(false);
  const [recParamModalVisible, setRecParamModalVisible] = useState(false);
  const [prepareExerciseList, setPrepareExerciseList] = useState<
    SweatGlobalStepExercise[]
  >(prepareStep?.exercises);
  const [recoverExerciseList, setRecoverExerciseList] = useState<
    SweatGlobalStepExercise[]
  >(recoverStep?.exercises);

  const [loadingSave, setLoadingSave] = useState(false);
  const [resultError, setResultError] = useState('');
  const [hasError, setHasError] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const prepareData: Partial<SweatGlobalStep> = {
    name: prepareName,
    fteOwner: store.auth.user?.id,
    exercises: prepareExerciseList,
    isActive: false,
    stepType: 'prepare',
  };
  const recoverData: Partial<SweatGlobalStep> = {
    name: recoverName,
    fteOwner: store.auth.user?.id,
    exercises: recoverExerciseList,
    isActive: false,
    stepType: 'recover',
  };

  const onSubmit = useCallback(async (data) => {
    setLoadingSave(true);
    data.exercises.forEach((exercise) => {
      if (isNaN(exercise.exercise)) exercise.exercise = exercise.exercise.id;
    });
    const result = await store.sweat.createSweatGlobalStep(data);
    if (!result.ok) {
      Object.values(result.errors).map((error) =>
        setResultError(JSON.stringify(error)),
      );
    }
    setLoadingSave(false);
    setHasError(!result.ok);
    Animated.timing(animation, {
      toValue: 3,
      duration: 2000,
      useNativeDriver: false,
    }).start(() => {
      animation.setValue(0);
      onClose();
    });
  }, []);

  const onSubmitEdit = useCallback(async (id, data) => {
    setLoadingSave(true);
    data.exercises.forEach((exercise) => {
      if (isNaN(exercise.exercise)) exercise.exercise = exercise.exercise.id;
    });
    const result = await store.sweat.updateSweatGlobalStep(id, data);
    if (!result.ok) {
      Object.values(result.errors).map((error) =>
        setResultError(JSON.stringify(error)),
      );
    }
    setLoadingSave(false);
    setHasError(!result.ok);
    Animated.timing(animation, {
      toValue: 3,
      duration: 2000,
      useNativeDriver: false,
    }).start(() => {
      animation.setValue(0);
      onClose();
    });
  }, []);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  return (
    <Layout style={[styles.container, style]}>
      <Layout style={styles.bgContainer}>
        <Layout style={styles.header}>
          <TouchableOpacity onPress={onClose} style={styles.close}>
            <Icon
              name="arrow-back-outline"
              fill="#4E7B89"
              style={styles.icon}
            />
          </TouchableOpacity>
          <Text style={styles.headerText}>Create Prepare/Recover</Text>
        </Layout>

        <Layout
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <CreateStep
            type="prepare"
            exerModalVisible={prepExerModalVisible}
            paramModalVisible={prepParamModalVisible}
            setParamModalVisible={setPrepParamModalVisible}
            exerciseList={prepareExerciseList}
            setExerciseList={setPrepareExerciseList}
            loadingSave={loadingSave}
            showExerModal={() => setPrepExerModalVisible(true)}
            hideExerModal={() => setPrepExerModalVisible(false)}
            name={prepareName}
            setName={setPrepareName}
            onSubmit={onSubmit}
            onSubmitEdit={onSubmitEdit}
            data={prepareData}
            disabled={!!!prepareStep}
            id={prepareStep?.id}
          />
          <CreateStep
            type="recover"
            exerModalVisible={recExerModalVisible}
            paramModalVisible={recParamModalVisible}
            setParamModalVisible={setRecParamModalVisible}
            exerciseList={recoverExerciseList}
            setExerciseList={setRecoverExerciseList}
            loadingSave={loadingSave}
            showExerModal={() => setRecExerModalVisible(true)}
            hideExerModal={() => setRecExerModalVisible(false)}
            name={recoverName}
            setName={setRecoverName}
            onSubmit={onSubmit}
            onSubmitEdit={onSubmitEdit}
            data={recoverData}
            disabled={!!!recoverStep}
            id={recoverStep?.id}
          />
        </Layout>
      </Layout>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[
            styles.popUpContainer,
            { opacity, transform: [{ translateY }, { scale }] },
          ]}
          pointerEvents="none"
        >
          <Icon
            style={styles.savedIcon}
            name={
              hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'
            }
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.savedText}>
            {hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}
          </Text>
          {hasError && <Text style={styles.errorText}>{resultError}</Text>}
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '95%',
    height: '80%',
    marginLeft: '26px',
    marginTop: 10,
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  header: {
    width: '948px',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  close: {
    marginLeft: 10,
    marginTop: 10,
  },
  icon: {
    width: 30,
    height: 30,
  },
  saveButton: {
    width: 100,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 'auto',
  },
  loadingSave: {
    position: 'absolute',
    padding: 8,
    right: 30,
    top: 12,
    backgroundColor: 'transparent',
  },
  saveText: {
    margin: 'auto',
    color: 'white',
    fontSize: 12,
  },
  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
});

export default observer(CreateGlobalStep);
