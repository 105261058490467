import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import { AssessmentScoringDisplay as AssessmentScoringDisplayType } from 'o2x-store/src/models/Assessment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { ScrollView, SectionList, TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import AssessmentScoringTable from 'src/components/AssessmentScoring/AssessmentScoringTable';
import ChevronDown from '../../assets/images/chevron-down.svg';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentScoringDisplay'>;
};

const AssessmentScoringDisplay: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const { auth, assessment: assessmentStore } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const insets = useSafeArea();

  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState<AssessmentScoringDisplayType>();
  const [expanded, setExpanded] = useState<Array<string>>(new Array());

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const result = await assessmentStore.fetchPhysicalAssessmentScoringDisplay(
          assessmentId,
        );
        if (result.entities) {
          setDisplay(result.entities);
        }
        setLoading(false);
      })();
    }, [setLoading, setDisplay]),
  );

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const isExpanded = useCallback(
    (title: string) => {
      return indexOf(expanded, title) > -1;
    },
    [expanded],
  );

  const onToggle = useCallback(
    (title: string) => {
      if (isExpanded(title)) {
        setExpanded((prevExpanded) => prevExpanded.filter((s) => s !== title));
      } else {
        setExpanded((prevExpanded) => [...prevExpanded, title]);
      }
    },
    [setExpanded, isExpanded],
  );

  if (!assessment) {
    return null;
  }

  const renderParamHeader = useCallback(
    ({ section: { title } }) => (
      <TouchableOpacity
        onPress={() => onToggle(title)}
        style={styles.expandable}
      >
        <Text class="h3" style={styles.itemTitle}>
          {title}
        </Text>
        <ChevronDown style={isExpanded(title) && styles.changeToUp} />
      </TouchableOpacity>
    ),
    [onToggle, isExpanded],
  );

  const renderParam = useCallback(
    (data) => {
      const {
        section: { title },
        item,
      } = data;
      if (isExpanded(title)) {
        return <AssessmentScoringTable data={item} />;
      }
      return null;
    },
    [expanded, isExpanded],
  );

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav style={styles.nav} showClose={false} />
      </View>
      {loading ? (
        <View style={styles.loading}>
          <Spinner />
        </View>
      ) : (
        <ScrollView style={styles.scroll}>
          <Layout style={styles.content}>
            <View style={styles.header}>
              <Text category="h2">{`${assessment.name} Scoring`}</Text>
            </View>
            {!!display && (
              <View>
                {!!display.badgeDisplay && (
                  <View style={styles.displayBadge}>
                    <TouchableOpacity
                      onPress={() => onToggle('Badges')}
                      style={styles.expandable}
                    >
                      <Text class="h3" style={styles.itemTitle}>
                        Badges
                      </Text>
                      <ChevronDown
                        style={isExpanded('Badges') && styles.changeToUp}
                      />
                    </TouchableOpacity>
                    {isExpanded('Badges') && (
                      <AssessmentScoringTable data={display.badgeDisplay} />
                    )}
                  </View>
                )}
                <SectionList
                  sections={display.scoringDisplay || []}
                  keyExtractor={(item, index) => `data-${index}`}
                  renderItem={renderParam}
                  renderSectionHeader={renderParamHeader}
                />
              </View>
            )}
          </Layout>
        </ScrollView>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    marginBottom: 20,
    color: 'blue-secondary',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  nav: {
    backgroundColor: 'black',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 20,
  },
  displayBadge: {
    marginTop: 20,
  },
  displayItem: {
    marginTop: 20,
  },
  itemTitle: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  itemLabel: {
    marginTop: 6,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  table: {
    marginTop: 6,
    marginBottom: 20,
  },
  changeToUp: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  expandable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'darker-blue',
    marginTop: 20,
  },
});

export default observer(AssessmentScoringDisplay);
