import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import {
  SectionList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Filter from '../../assets/images/filter.svg';
import ExploreFilterItem from '../../components/ExploreFilterItem';
import ExploreFilterSectionHeader from '../../components/ExploreFilterSectionHeader';
import { Filters } from '../../models/ExploreFilter';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { ThriveActivityFilterSection } from './constants';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveYogaFilter'>;
};

const ThriveYogaFilter: React.FC<Props> = (props) => {
  const {
    route: {
      params: { selected },
    },
  } = props;
  const { exploreFilter } = useNativeStore();
  const [search, setSearch] = useState(exploreFilter.search);
  const [filters, setFilters] = useState<Filters>({ ...exploreFilter.filters });
  const [filterSections, setFilterSections] = useState(
    ThriveActivityFilterSection,
  );
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  const filterCount = Object.values(filters).filter((filter) => filter).length;
  const navigation = useNavigation();

  const onSelectFilter = useCallback((filter) => {
    setFilters((filters) => ({
      ...filters,
      [filter]: !filters[filter],
    }));
  }, []);

  const onClearSection = useCallback(
    (sectionKey) => {
      const sectionFilters = filterSections[selected].find(
        (section) => section.key === sectionKey,
      );
      if (!sectionFilters) {
        return;
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...sectionFilters.data.reduce(
          (obj, filter) => ({ ...obj, [filter.key]: false }),
          {},
        ),
      }));
    },
    [filterSections],
  );

  const onClearAll = useCallback(() => {
    setFilters({});
  }, []);

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <ExploreFilterSectionHeader
        section={section}
        fill={theme['orange']}
        onClearSection={onClearSection}
      />
    ),
    [],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <ExploreFilterItem
        fill={theme['orange']}
        item={item}
        checked={filters[item.key]}
        onCheck={onSelectFilter}
      />
    ),
    [filters],
  );

  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);

  const onCancel = useCallback(() => {
    navigation.goBack();
  }, []);

  const onSearch = useCallback(() => {
    exploreFilter.setSearch(search);
    exploreFilter.setFilters(filters);
    navigation.goBack();
  }, [exploreFilter, search, filters, selected]);

  const onBack = useCallback(() => {
    if (!search) {
      exploreFilter.setSearch('');
    }
    navigation.goBack();
  }, [search, selected, exploreFilter]);

  return (
    <TouchableWithoutFeedback onPress={onCancel}>
      <Layout style={styles.container}>
        <Layout style={styles.modal}>
          <Layout style={styles.filter}>
            <Filter height={20} width={20} stroke={theme['white']} />
            <Text style={styles.filterLabel}>Filters</Text>
            <TouchableOpacity style={styles.clearAll} onPress={onClearAll}>
              <Text style={styles.clearAllLabel} category="c1">
                Clear All ({filterCount})
              </Text>
            </TouchableOpacity>
          </Layout>
          <SectionList
            style={styles.list}
            contentContainerStyle={styles.listContainer}
            sections={filterSections[selected]}
            extraData={filters}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            ItemSeparatorComponent={renderSeparator}
            stickySectionHeadersEnabled={false}
          />
          <Layout style={[styles.actions, { marginBottom: insets.bottom }]}>
            <TouchableOpacity style={styles.actionButton} onPress={onCancel}>
              <Text style={styles.actionLabel} category="c1">
                Cancel
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.actionButton,
                { backgroundColor: theme['orange'] },
              ]}
              onPress={onSearch}
            >
              <Text style={styles.actionLabel} category="c1">
                Search
              </Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      </Layout>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  actionButton: {
    flex: 1,
    backgroundColor: 'darker-blue',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  actions: {
    flexDirection: 'row',
    height: 48,
  },
  clearAll: {},
  clearAllLabel: {
    textTransform: 'uppercase',
    color: 'orange',
  },
  container: {
    flex: 1,
    backgroundColor: '#00000080',
  },
  modal: {
    maxWidth: 400,
    flex: 1,
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    marginHorizontal: 24,
  },
  filterLabel: {
    flex: 1,
    marginHorizontal: 16,
  },
  filterButton: {
    paddingRight: 0,
  },
  list: {
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 24,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'white',
    borderBottomWidth: 1,
    marginHorizontal: 24,
    marginVertical: 8,
    paddingVertical: 4,
  },
  searchIcon: {
    height: 24,
    width: 24,
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
  },
  spacer: {
    height: 8,
  },
});

export default observer(ThriveYogaFilter);
