import {
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';

type Props = {
  workoutName: string;
  workoutNotes: string;
  difficulty: string;
  category: string;
  setWorkoutName: Function;
  setWorkoutNotes: Function;
  setDifficulty: Function;
  setCategory: Function;
};

const WorkoutDetails: React.FC<Props> = (props) => {
  const {
    workoutName,
    workoutNotes,
    difficulty,
    category,
    setWorkoutName,
    setWorkoutNotes,
    setDifficulty,
    setCategory,
  } = props;
  const styles = useStyleSheet(themedStyles);

  const difficulties: { [key: string]: string } = {
    '': '---',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    advanced: 'Advanced',
  };
  const categories: { [key: string]: string } = {
    '': '---',
    bw: 'Body Weight Only',
    equipment: 'Tactical Athlete',
  };

  return (
    <Layout style={styles.workoutDetails}>
      <Input
        size="medium"
        value={workoutName}
        onChangeText={(text) => setWorkoutName(text)}
        style={styles.nameInput}
        label="Name of Workout"
      />
      <Input
        size="large"
        multiline={true}
        numberOfLines={2}
        value={workoutNotes || ''}
        onChangeText={(text) => {
          console.log(text);
          setWorkoutNotes(text);
        }}
        style={styles.nameInput}
        label="Notes"
      />
      <Select
        size="medium"
        style={styles.detailInput}
        label="Difficulty"
        placeholder="---"
        value={difficulties[difficulty]}
        onSelect={(index: IndexPath | IndexPath[]) => {
          setDifficulty(Object.keys(difficulties)[index.row]);
        }}
      >
        {Object.values(difficulties).map((diff, index) => {
          return <SelectItem key={index} title={diff} />;
        })}
      </Select>
      <Select
        size="medium"
        style={styles.detailInput}
        label="Category"
        placeholder="---"
        value={categories[category]}
        onSelect={(index: IndexPath | IndexPath[]) => {
          setCategory(Object.keys(categories)[index.row]);
        }}
      >
        {Object.values(categories).map((diff, index) => {
          return <SelectItem key={index} title={diff} />;
        })}
      </Select>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  workoutDetails: {
    width: '40%',
    height: '60%',
    backgroundColor: 'transparent',
    marginLeft: 40,
  },
  nameInput: {
    width: '100%',
    marginTop: 10,
  },
  detailInput: {
    width: '100%',
    marginTop: 10,
  },
});

export default React.memo(WorkoutDetails, (prevProps, nextProps) => {
  if (
    prevProps.workoutName.length !== nextProps.workoutName.length ||
    prevProps.workoutNotes.length !== nextProps.workoutNotes.length ||
    prevProps.difficulty.length !== nextProps.difficulty.length ||
    prevProps.category.length !== nextProps.category.length
  ) {
    return false;
  }
  let equal = true;
  if (
    prevProps.workoutName !== nextProps.workoutName ||
    prevProps.workoutNotes !== nextProps.workoutNotes ||
    prevProps.difficulty !== nextProps.difficulty ||
    prevProps.category !== nextProps.category
  )
    equal = false;
  return equal;
});
