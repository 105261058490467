import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { indexOf, isNull, isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Check from '../../assets/images/check-olive.svg';
import ChecklistClipboard from '../../assets/images/checklist-clipboard-olive.svg';
import TopNav from '../../components/Question/TopNav';
import UserDetail from '../../components/UserDetail';

type Props = {
  route: any;
};

const EventSpecialists: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const today = new Date();
  const user = store.auth.user;
  const event = store.event.events.get(`${id}`);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeArea();
  const [promoterShown, setPromoterShown] = useState(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(() => {
    if (
      (event?.usersWithSurvey || []).length > 0 &&
      !promoterShown &&
      (isUndefined(event?.checkin?.promoterScore) ||
        isNull(event?.checkin?.promoterScore))
    ) {
      navigation.navigate('NetPromoterModal', { eventId: id });
      setPromoterShown(true);
    }
  });

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const goToSurvey = useCallback(
    (specialist: number) => {
      if (indexOf(event?.usersWithSurvey || [], specialist) === -1) {
        navigation.navigate('EventSpecialistSurvey', {
          id,
          specialistId: specialist,
        });
      }
    },
    [event],
  );

  if (!event) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View
              style={[styles.navigationContainer, { marginTop: insets.top }]}
            >
              <TopNav onBack={goToEvent} showBack={true} showClose={false} />
            </View>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isMobile
                    ? styles.contentMobile
                    : isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }
              >
                <Text style={styles.title}>Choose specialist:</Text>
                {event.specialists.map((specialist: number) => {
                  const done =
                    indexOf(event.usersWithSurvey, specialist) !== -1;
                  return (
                    <TouchableOpacity
                      style={[styles.item, done && styles.inactive]}
                      onPress={() => goToSurvey(specialist)}
                      key={`specialist-${specialist}`}
                    >
                      <View style={styles.authorContainer}>
                        <UserDetail
                          style={[styles.author, done && styles.inactive]}
                          id={specialist}
                          label="Host"
                        />
                        <View style={styles.surveyTextContainer}>
                          {done ? (
                            <>
                              <ChecklistClipboard />
                              <Text style={styles.surveyText}>
                                {' '}
                                Survey Completed
                              </Text>
                            </>
                          ) : (
                            <>
                              <Check />
                              <Text style={styles.surveyText}>
                                {' '}
                                Take Survey
                              </Text>
                            </>
                          )}
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </Layout>
            </ScrollView>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  content: {
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  scroll: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 30,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    marginBottom: 14,
  },
  author: {
    paddingVertical: 16,
    paddingHorizontal: 10,
    backgroundColor: 'dark-blue',
  },
  item: {
    marginBottom: 10,
    flex: 1,
    backgroundColor: 'dark-blue',
  },
  inactive: {
    opacity: 0.5,
  },
  surveyText: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    color: 'olive',
    marginLeft: 2,
  },

  authorContainer: {
    paddingBottom: 10,
  },

  surveyTextContainer: {
    flexDirection: 'row',
    paddingLeft: 80,
  },
});

export default observer(EventSpecialists);
