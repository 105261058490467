import '@expo/match-media';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Image, ImageStyle, StyleProp } from 'react-native';
import Logo from '../../assets/images/logo-web.svg';
type Props = {};

const Web: React.FC<Props> = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <Layout style={styles.container}>
      <Image
        style={styles.coverPhoto as StyleProp<ImageStyle>}
        source={require('../../assets/images/cover-photo-web.png')}
      ></Image>
      <LinearGradient
        colors={['#10283E', 'transparent']}
        locations={[0, 0.2]}
        style={styles.linearGradient}
      ></LinearGradient>
      <LinearGradient
        colors={['transparent', '#10283E']}
        locations={[0.7, 1]}
        style={styles.linearGradient}
      ></LinearGradient>
      <Layout style={styles.content}>
        <Layout style={styles.logoAndTextContainer}>
          <Logo style={styles.logo} />
          <Text style={styles.firstParagraph} category="h2">
            Train wherever, whenever.
          </Text>
          <Text style={styles.secondParagraph} category="h3">
            We bring physical and mental training directly to you. Each program
            is customized to meet your needs and executed by our team of over
            150 world-class athletes, special operations veterans, and renowned
            academics.
          </Text>
        </Layout>
        <Layout style={styles.authContainer}>{children}</Layout>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  wallpaper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  coverPhoto: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '45%',
    width: '100%',
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5%',
  },
  authContainer: {
    width: 518,
    height: 645,
    backgroundColor: '#091C2D',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '14%',
  },
  logoAndTextContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 32,
  },
  firstParagraph: {
    textAlign: 'center',
    letterSpacing: 2,
    marginBottom: 32,
    width: '90%',
  },
  secondParagraph: {
    textAlign: 'center',
    letterSpacing: 2,
    width: '100%',
  },
  linearGradient: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '45.1%',
    width: '100%',
  },
});

export default Web;
