import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useMemo } from 'react';
import ExploreTaskTab from './ExploreTaskTab';

type Props = {
  selected: TASK_TYPE;
  onSelect: (type: TASK_TYPE) => void;
  exclude?: Array<TASK_TYPE>;
};

const ExploreTaskTabBar: React.FC<Props> = (props) => {
  const { selected, onSelect, exclude = [] } = props;
  const styles = useStyleSheet(themedStyles);
  const taskTypes = useMemo(
    () =>
      [TASK_TYPE.EAT, TASK_TYPE.SWEAT, TASK_TYPE.THRIVE].filter(
        (t) => !exclude.find((e) => e === t),
      ),
    [exclude],
  );

  return (
    <Layout style={styles.container}>
      {taskTypes.map((type) => (
        <Layout key={type} style={styles.taskTab}>
          <ExploreTaskTab
            key={type}
            type={type}
            selected={type === selected}
            onSelect={onSelect}
          />
        </Layout>
      ))}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    marginBottom: 15,
  },
  taskTab: {
    width: '32%',
  },
});

export default ExploreTaskTabBar;
