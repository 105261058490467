import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Modal,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import {
  FTESweatExercise,
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
} from 'o2x-store/src/models/FTELibrary';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { getErrors } from 'src/utils/errors';

type Props = {
  hideExerModal: () => void;
  content: string;
  exerModalVisible: boolean;
  stepList?: (FTESweatWorkoutStep | SweatWorkoutStep)[];
  setStepList?: Function;
  stepIndex?: number;
  workoutList?: (FTESweatWorkout | SweatWorkout)[];
  setWorkoutList?: Function;
  workout?: FTESweatWorkout | SweatWorkout;
  workoutIndex?: number;
  exerciseList?: SweatGlobalStepExercise[];
  setExerciseList?: Function;
};

const AddExerciseModal: React.FC<Props> = (props) => {
  const {
    hideExerModal,
    content,
    exerModalVisible,
    stepList,
    setStepList,
    stepIndex,
    workoutList,
    setWorkoutList,
    workout,
    workoutIndex,
    exerciseList,
    setExerciseList,
  } = props;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);

  const [exercises, setExercises] = useState<SweatExercise[]>();
  const [nextRequest, setNextRequest] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedName, setSelectedName] = useState('');
  const [selectedVid, setSelectedVid] = useState('');
  const [selectedFteOwner, setSelectedFteOwner] = useState<number>();

  const [exerciseName, setExerciseName] = useState('');
  const [vidURL, setVidURL] = useState('');

  const [create, setCreate] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [search, setSearch] = useState('');

  const [resultError, setResultError] = useState('');
  const [hasError, setHasError] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setSelectedId(0);
        setSelectedName('');
        setCreate(false);
        if (exerModalVisible) {
          setLoading(true);
          const exerciseList = await store.fteLibrary.fetchFTESweatExercises();
          setNextRequest(exerciseList.extra?.next);
          setExercises(exerciseList.extra?.results);
          setLoading(false);
        }
      })();
    }, [exerModalVisible]),
  );

  const onSearch = async () => {
    setLoading(true);
    let url = `${config.urls.fteLibrary}fte-sweat-exercises/?search=${search}&limit=10`;
    const exerciseList = await store.fteLibrary.fetchFTESweatExercises(url);
    setNextRequest(exerciseList.extra?.next);
    setExercises(exerciseList.extra?.results);
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (nextRequest) {
      const exerciseList = await store.fteLibrary.fetchFTESweatExercises(
        nextRequest,
      );
      setExercises([...exercises!, ...exerciseList.extra?.results]);
      setNextRequest(exerciseList.extra?.next);
    }
    setLoadingMore(false);
  };

  const onPressOk = () => {
    if (selectedId > 0) {
      const exerciseObject: Partial<
        FTESweatWorkoutStepExercise | SweatGlobalStepExercise
      > = {
        id: Math.random(),
        exercise: selectedId,
        exerciseName: selectedName,
        exerciseSets: [],
        exerciseVideo: selectedVid,
        fteOwner: selectedFteOwner,
      };
      if (content === 'workout' && setStepList) {
        const list: any = [...stepList!];
        list[stepIndex]['exercises'] = list[stepIndex]['exercises']
          ? [...list[stepIndex]['exercises']!, exerciseObject]
          : [exerciseObject];
        setStepList(list);
      } else if (content === 'program' && workout && setWorkoutList) {
        const workoutCopy = workout;
        const workoutListCopy = [...workoutList!];
        workoutCopy.steps[stepIndex]['exercises'] = workoutCopy.steps[
          stepIndex
        ]['exercises']
          ? [...workoutCopy.steps[stepIndex]['exercises']!, exerciseObject]
          : [exerciseObject];
        workoutListCopy[workoutIndex!] = workoutCopy;
        setWorkoutList(workoutListCopy);
      } else if (content === 'global' && setExerciseList) {
        const list: any = exerciseList
          ? [...exerciseList!, exerciseObject]
          : [exerciseObject];
        setExerciseList(list);
      }
      hideExerModal();
    }
  };

  const createExercise = async () => {
    const data: FTESweatExercise = {
      id: null,
      name: exerciseName,
      videoLink: vidURL,
      fteOwner: store.auth.user?.id!,
    };
    setLoadingCreate(true);
    const result = await store.fteLibrary.createFteSweatExercise(data);
    if (result.ok) {
      setLoadingCreate(false);
      const exerciseObject: Partial<
        FTESweatWorkoutStepExercise | SweatGlobalStepExercise
      > = {
        id: result.extra?.id,
        exercise: result.extra?.id,
        exerciseName: result.extra?.displayName,
        exerciseSets: [],
        exerciseVideo: vidURL,
        fteOwner: result.extra?.fteOwner,
      };
      if (content === 'workout' && setStepList) {
        const list: any = [...stepList!];
        list[stepIndex]['exercises'] = list[stepIndex]['exercises']
          ? [...list[stepIndex]['exercises']!, exerciseObject]
          : [exerciseObject];
        setStepList(list);
      } else if (content === 'program' && workout && setWorkoutList) {
        const workoutCopy = workout;
        const workoutListCopy = [...workoutList!];
        workoutCopy.steps[stepIndex]['exercises'] = workoutCopy.steps[
          stepIndex
        ]['exercises']
          ? [...workoutCopy.steps[stepIndex]['exercises']!, exerciseObject]
          : [exerciseObject];
        workoutListCopy[workoutIndex!] = workoutCopy;
        setWorkoutList(workoutListCopy);
      } else if (content === 'global' && setExerciseList) {
        const list: any = exerciseList
          ? [...exerciseList!, exerciseObject]
          : [exerciseObject];
        setExerciseList(list);
      }
      // hideExerModal();
    } else {
      setResultError(getErrors(result.errors));
      setLoadingCreate(false);
      // hideExerModal();
    }
    setHasError(!result.ok);
    Animated.timing(animation, {
      toValue: 3,
      duration: 2000,
      useNativeDriver: false,
    }).start(() => {
      animation.setValue(0);
      if (result.ok) hideExerModal();
    });
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  return (
    <Modal
      visible={exerModalVisible}
      onBackdropPress={hideExerModal}
      backdropStyle={styles.backdrop}
    >
      {!!create ? (
        <Layout style={{ height: 350, width: 300 }}>
          {!!loading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <Layout style={{ height: '100%', width: '100%' }}>
              <TouchableOpacity
                style={styles.createStyle}
                onPress={() => setCreate(false)}
              >
                <Icon
                  style={styles.icon}
                  name="arrow-back-outline"
                  fill="#8F9BB3"
                />
                <Text style={styles.createText}>Search Exercise</Text>
              </TouchableOpacity>
              <Input
                size="small"
                style={styles.input}
                placeholder="Name of Exercise"
                value={exerciseName}
                onChangeText={(text) => setExerciseName(text)}
              />
              <Input
                size="small"
                style={styles.input}
                placeholder="Video URL"
                value={vidURL}
                onChangeText={(text) => setVidURL(text)}
              />
              <TouchableOpacity
                style={styles.okButton}
                onPress={createExercise}
              >
                <Text style={{ fontSize: 14, letterSpacing: 0.5 }}>Done</Text>
              </TouchableOpacity>
            </Layout>
          )}
        </Layout>
      ) : (
        <Layout style={{ height: 350, width: 300 }}>
          <Layout style={{ height: '100%', width: '100%' }}>
            <TouchableOpacity
              style={[
                styles.createStyle,
                {
                  marginLeft: 'auto',
                  marginRight: 15,
                },
              ]}
              onPress={() => setCreate(true)}
            >
              <Text style={styles.createText}>Create Exercise</Text>
              <Icon
                style={styles.icon}
                name="arrow-forward-outline"
                fill="#8F9BB3"
              />
            </TouchableOpacity>
            <Input
              size="small"
              style={styles.input}
              placeholder="Search Exercise"
              value={search}
              onChangeText={(text) => setSearch(text)}
              accessoryLeft={() => (
                <Icon style={styles.icon} name="search-outline" fill="white" />
              )}
              onKeyPress={(key) =>
                key.nativeEvent.key === 'Enter' ? onSearch() : null
              }
            />
            {!!loading ? (
              <Layout style={styles.loading}>
                <Spinner />
              </Layout>
            ) : (
              <ScrollView
                style={styles.exerciseContainer}
                onScroll={({ nativeEvent }) => {
                  if (isCloseToBottom(nativeEvent)) {
                    loadMore();
                  }
                }}
                scrollEventThrottle={400}
              >
                {exercises?.map((exercise, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={[
                        styles.exercise,
                        exercise.id === selectedId && {
                          backgroundColor: '#767F6A',
                        },
                      ]}
                      onPress={() => {
                        setSelectedName(exercise.displayName);
                        setSelectedId(exercise.id);
                        setSelectedVid(
                          exercise.video ? exercise.video : exercise.videoLink,
                        );
                        setSelectedFteOwner(exercise.fteOwner);
                      }}
                    >
                      <Text style={styles.exerciseText}>
                        {exercise.displayName}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
                {!loading && loadingMore && (
                  <Layout style={styles.loading}>
                    <Spinner />
                  </Layout>
                )}
              </ScrollView>
            )}
            <TouchableOpacity style={styles.okButton} onPress={onPressOk}>
              <Text style={{ fontSize: 14, letterSpacing: 0.5 }}>Done</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      )}
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[
            styles.popUpContainer,
            { opacity, transform: [{ translateY }, { scale }] },
          ]}
          pointerEvents="none"
        >
          <Icon
            style={styles.savedIcon}
            name={
              hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'
            }
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.savedText}>
            {hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}
          </Text>
          {hasError && <Text style={styles.errorText}>{resultError}</Text>}
        </Animated.View>
      </View>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  createStyle: {
    marginTop: 15,
    marginRight: 'auto',
    marginLeft: 15,
    flexDirection: 'row',
  },
  createText: {
    fontSize: 14,
    color: '#8F9BB3',
  },
  input: {
    marginTop: 10,
    width: '90%',
    alignSelf: 'center',
    backgroundColor: '#1A3248',
  },
  okButton: {
    marginTop: 'auto',
    marginBottom: 15,
    marginLeft: 'auto',
    marginRight: 15,
    padding: 5,
    backgroundColor: '#767F6A',
  },
  exerciseContainer: {
    maxHeight: '75%',
    marginTop: 10,
    alignSelf: 'center',
    width: '100%',
  },
  exercise: {
    marginBottom: 5,
    alignSelf: 'center',
    width: '90%',
    padding: 2,
  },
  exerciseText: {
    fontSize: 15,
    letterSpacing: 1.2,
    marginLeft: 3,
  },
  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
});

export default observer(AddExerciseModal);
