import { camelCase } from 'change-case';
import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  prop,
  prop_mapObject,
  SnapshotOutOf,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import { default as Question } from '../models/Question';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/QuestionStore')
export default class QuestionStore extends Model({
  eatProfileQuestions: prop_mapObject(() => new Map<string, Question>()),
  dailyEatHabitQuestions: prop_mapObject(() => new Map<string, Question>()),
  thriveProfileQuestions: prop_mapObject(() => new Map<string, Question>()),
  dailyThriveHabitQuestions: prop_mapObject(() => new Map<string, Question>()),
  findSweatPlanQuestions: prop_mapObject(() => new Map<string, Question>()),
  findThrivePlanQuestions: prop_mapObject(() => new Map<string, Question>()),
  states: prop(() => new Array<Array<string>>()),
  eventSurveyQuestions: prop_mapObject(() => new Map<string, Question>()),
  dailyDebriefQuestions: prop_mapObject(() => new Map<string, Question>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchEatProfileQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchEatProfileQuestions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching eat profile questions', error);
      return getError(error);
    }

    this.eatProfileQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.eatProfileQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyEatHabitQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDailyEatHabitQuestions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching eat habit questions', error);
      return getError(error);
    }

    this.dailyEatHabitQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.dailyEatHabitQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThriveProfileQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchThriveProfileQuestions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive profile questions', error);
      return getError(error);
    }

    this.thriveProfileQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.thriveProfileQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyThriveHabitQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchDailyThriveHabitQuestions(rootStore.auth.token),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive habit questions', error);
      return getError(error);
    }

    this.dailyThriveHabitQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.dailyThriveHabitQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatProfileQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatProfileQuestions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat profile questions', error);
      return getError(error);
    }

    this.findSweatPlanQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.findSweatPlanQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFindThrivePlanQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFindThrivePlanQuestions(rootStore.auth.token),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive plan questions', error);
      return getError(error);
    }

    this.findThrivePlanQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.findThrivePlanQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchStates = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: Array<Array<string>>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchStates(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching states', error);
      return getError(error);
    }

    this.states = entities;
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchEventSurveyQuestions = _async(function* (
    this: QuestionStore,
    eventId: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchEventSurveyQuestions(rootStore.auth.token, eventId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching event survey questions', error);
      return getError(error);
    }

    this.eventSurveyQuestions.clear();

    entities.forEach((data, index) => {
      const id = String(index);
      if (this.eventSurveyQuestions.has(id)) {
        this.eventSurveyQuestions
          .get(id)!
          .update({ ...data, key: camelCase(data.key) });
      } else {
        const task = new Question({ ...data, key: camelCase(data.key) });
        this.eventSurveyQuestions.set(id, task);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyDebriefQuestions = _async(function* (this: QuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [SnapshotOutOf<Question>];
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDailyDebriefQuestions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching daily debrief questions', error);
      return getError(error);
    }

    this.dailyDebriefQuestions.clear();
    entities.forEach((data, index) => {
      const id = String(index);
      this.dailyDebriefQuestions.set(
        id,
        new Question({
          ...data,
          key: camelCase(data.key),
        }),
      );
    });

    this.loading = false;
    return getSuccess();
  });
}
