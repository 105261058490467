import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { useFocusEffect } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import { default as React, useCallback, useState } from 'react';
import { Image, ImageStyle, ScrollView, StyleProp } from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import Logo from '../../assets/images/drawer-logo.svg';
import Content from './Content';

type Props = DrawerContentComponentProps & {};

const DrawerContent: React.FC<Props> = (props) => {
  const { task, auth, organization } = useStore();
  const { state, navigation } = props;
  const styles = useStyleSheet(themedStyles);
  const [image, setImage] = useState<string>('');
  const { navigateWithPayWall } = useIsSubscribed();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        const org = auth.user?.organization;
        if (org) {
          const response = await organization.fetchOrganization(org);
          if (response.ok && response?.extra.logo) {
            setImage(response.extra.logo);
          }
        }
      })();
    }, []),
  );
  const onNavigate = useCallback((route) => {
    switch (route.name) {
      case 'SavedStack':
        navigation.navigate(route.name, {
          screen: 'Saved',
        });
        break;
      case 'ExploreStack':
        navigation.navigate('ExploreStack', {
          screen: 'Explore',
        });
        break;
      case 'Teams':
        navigateWithPayWall('Teams', {
          screen: 'Teams',
        });
        break;
      default:
        navigation.navigate(route.name, {
          screen: route.name,
        });
    }
  }, []);
  return (
    <Layout style={styles.container}>
      <Layout style={styles.header}>
        <Logo />
      </Layout>
      <ScrollView style={styles.body}>
        {state.routes.map((route, index) => {
          if (route.name === 'Organization' && !auth.user?.organization) {
            return null;
          } else if (
            route.name === 'JoinOrganization' &&
            auth.user?.organization
          ) {
            return null;
          } else if (
            route.name === 'AskAnExpertDrawer' &&
            !auth.user?.isSubscribed
          ) {
            return null;
          } else if (
            route.name === 'FTEAdmin' &&
            !auth.user?.isOrganizationFte
          ) {
            return null;
          }
          return (
            <Content
              key={route.key}
              title={route.name}
              selected={state.index === index}
              onNavigate={() => onNavigate(route)}
              hasNotification={route.name === 'Today' && task.today.size > 0}
            />
          );
        })}
      </ScrollView>
      <Layout style={styles.footer}>
        {!!image && (
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: image }}
          />
        )}
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  button: {},
  container: {
    flex: 1,
  },
  header: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: 50,
    paddingBottom: 50,
  },
  body: {
    flexGrow: 1,
  },
  footer: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  footerLink: {
    alignItems: 'center',
    height: 40,
    flexDirection: 'row',
  },
  footerLinkText: {
    textAlignVertical: 'center',
    fontSize: 14,
    width: 155,
  },
  image: {
    width: 90,
    height: 50,
  },
});

export default DrawerContent;
