import Slider from '@react-native-community/slider';
import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { Audio } from 'expo-av';
import {
  INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  INTERRUPTION_MODE_IOS_DO_NOT_MIX,
} from 'expo-av/build/Audio';
import { useKeepAwake } from 'expo-keep-awake';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Breathe from '../../assets/images/breathe.svg';
import NavigationBar from '../../components/NavigationBar';
import ThriveBreatheIndicator from '../../components/ThriveBreatheIndicator';
import ThriveStartTimer from '../../components/ThriveStart/ThriveStartTimer';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveApp'>;
};

const ThriveBreathe: React.FC<Props> = (props) => {
  const { params } = props.route;
  const { initialDuration } = params || {};
  const { thrive } = useStore();
  const { thriveStart } = useNativeStore();
  thriveStart.setActivityType(THRIVE_ACTIVITY_TYPE.BREATHE);
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const [volume, setVolume] = useState(0.3);
  const [allowed, setAllowed] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  useKeepAwake();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useEffect(() => {
    analytics.logThriveActivityOpen(THRIVE_ACTIVITY_TYPE.BREATHE);
  }, []);

  const goBack = useCallback(() => {
    if (thriveStart.playing) {
      if (Platform.OS === 'web') {
        const doEnd = confirm('Are you sure you want to go back?');
        if (doEnd) {
          thriveStart.stop();
          navigation.goBack();
        }
      } else {
        Alert.alert('Are you sure you want to go back?', undefined, [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Go Back',
            onPress: () => {
              thriveStart.stop();
              navigation.goBack();
            },
          },
        ]);
      }
    } else {
      navigation.goBack();
    }
  }, [thriveStart.playing]);

  const onChangeVolume = useCallback(
    (volume) => {
      setVolume(volume);
      thriveStart.setVolume(volume);
    },
    [thriveStart],
  );

  useFocusEffect(
    useCallback(() => {
      Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
        allowsRecordingIOS: false,
        staysActiveInBackground: false,
        interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        shouldDuckAndroid: true,
        interruptionModeAndroid: INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false,
      });
    }, [allowed]),
  );

  const onTimerPlay = useCallback(() => {
    setStartTime((prevStartTime) =>
      prevStartTime ? prevStartTime : moment().toDate(),
    );
  }, [setStartTime]);

  useFocusEffect(
    useCallback(() => {
      const logActivity = () => {
        if (startTime !== null) {
          thrive.logThriveActivity(
            THRIVE_ACTIVITY_TYPE.BREATHE,
            startTime,
            moment().toDate(),
          );
        }
      };
      return logActivity;
    }, [startTime]),
  );

  useEffect(() => {
    /* Only needed if recording audio
    (async () => {
      let permission: PermissionResponse = await Audio.getPermissionsAsync();
      if (
        permission.status !== Audio.PermissionStatus.GRANTED &&
        permission.canAskAgain
      ) {
        permission = await Audio.requestPermissionsAsync();
      }

      if (permission.status === Audio.PermissionStatus.GRANTED) {
        setAllowed(true);
      }
    })();
    */
    thriveStart.setVolume(0.3);
    if (!initialDuration) {
      thriveStart.setDefaultTime(5 * 60); // 5 minutes.
    } else if (initialDuration !== 'infinity') {
      thriveStart.setDefaultTime(initialDuration);
    } else if (initialDuration === 'infinity') {
      thriveStart.setDefaultTime(Infinity);
    }
    thriveStart.setAudio(null);
    thriveStart.setVideo(null);
  }, [initialDuration]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar
                onClose={goBack}
                showBack={false}
                showClose={true}
                withBackground={false}
              />
            </Layout>
            <ScrollView>
              <Layout
                style={
                  isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }
              >
                <Layout style={styles.headerContainer}>
                  <Breathe style={styles.icon} />
                  <Text style={styles.header} category="h2">
                    Breathe
                  </Text>
                </Layout>
                <Layout style={styles.indicator}>
                  <ThriveBreatheIndicator />
                </Layout>
                <Text style={styles.label} category="c2">
                  Volume
                </Text>
                <Slider
                  style={styles.slider}
                  minimumValue={0}
                  minimumTrackTintColor={theme['orange']}
                  maximumValue={1}
                  maximumTrackTintColor={theme['gray']}
                  thumbTintColor={theme['white']}
                  value={volume}
                  onSlidingComplete={onChangeVolume}
                />
              </Layout>
            </ScrollView>
            <ThriveStartTimer
              activity={THRIVE_ACTIVITY.BREATHE}
              addTime={30}
              onTimerPlay={onTimerPlay}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '95%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: '25%',
    paddingBottom: 24,
  },
  contentMaxWidth600: {
    flex: 1,
    paddingHorizontal: '10%',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  icon: {
    marginRight: 16,
  },
  indicator: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  label: {
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  select: {
    marginBottom: 24,
  },
  slider: {},
});

export default observer(ThriveBreathe);
