import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import Task from 'o2x-store/src/models/Task';
import { useStore } from 'o2x-store/src/stores';
import { taskToListType } from 'o2x-store/src/utils/list';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useSaveList } from '../../hooks/list';
import Rating from '../Rating';

export type Props = {
  task: Task;
  showIcon?: boolean;
  showEllipses?: boolean;
  showBookmark?: boolean;
  showRating?: boolean;
  isToday?: boolean;
  key: string;
};

const ProgramItem: React.FC<Props> = (props) => {
  const {
    task,
    showBookmark = false,
    showRating = false,
    isToday = false,
  } = props;
  const navigation = useNavigation();
  const store = useStore();
  const { navigateWithPayWall } = useIsSubscribed();
  const program = store.getProgram(task.type, task.id);
  const [save, setSave] = useState(
    program ? program.saveList.length > 0 : false,
  );
  const totalRatings = get(program?.ratingsBreakdown, 'totalRatings', 0);

  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    taskToListType(program ? program.type : 'all'),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (program) {
          if (!program.ratingsBreakdown) {
            await program.fetchRatings();
          }
        }
      })();
    }, [program, totalRatings]),
  );

  const onOpen = useCallback(() => {
    if (isToday) {
      if (task.type === TASK_TYPE.EAT) {
        navigateWithPayWall('EatStart', { id: task.id });
      } else if (task.type === TASK_TYPE.SWEAT) {
        navigateWithPayWall('SweatStart', { id: task.id });
      } else if (task.type === TASK_TYPE.THRIVE) {
        navigateWithPayWall('ThriveStart', { id: task.id });
      }
    } else {
      navigation.navigate('ProgramDetail', {
        type: task.type,
        id: task.id,
      });
    }
  }, [task, navigateWithPayWall]);

  const onPress = useCallback(() => {
    if (program) {
      if (program.saveList.length === 0) {
        // Save
        setSave(true);
        if (program.type === TASK_TYPE.EAT) {
          navigation.navigate('SaveToList', {
            listType: taskToListType(program.type),
            itemId: program.id,
          });
        } else {
          addToList(savedList, String(program.id));
          // Toast not supported on web
          // Toast.show('Saved to List', {
          //   duration: ToastShortDuration,
          //   position: Toast.positions.BOTTOM,
          // });
        }
      } else {
        // Remove
        setSave(false);
        removeFromList(savedList, String(program.id));
        // Toast not supported on web
        // Toast.show('Removed from List', {
        //   duration: ToastShortDuration,
        //   position: Toast.positions.BOTTOM,
        // });
      }
    }
  }, [savedList, program]);

  return (
    <TouchableOpacity onPress={onOpen}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
      >
        <Layout style={styles.contentContainer} level="2">
          {!!task.image && (
            <Layout style={styles.imageWrapper}>
              <Image
                style={styles.image as StyleProp<ImageStyle>}
                source={{ uri: task.image }}
              />
            </Layout>
          )}
          <Layout style={styles.textContainer}>
            <Text style={styles.title} numberOfLines={1}>
              {task.name}
            </Text>
            {!!task.subtitle && (
              <Text style={[styles.subtitle, { color: task.color }]}>
                {task.subtitle}
              </Text>
            )}
          </Layout>
        </Layout>
        {showBookmark && (
          <TouchableOpacity style={styles.bookmarkButton} onPress={onPress}>
            <Icon
              style={styles.bookmark}
              fill={theme['white']}
              name={save ? 'bookmark' : 'bookmark-outline'}
            />
          </TouchableOpacity>
        )}
        {totalRatings >= 10 && showRating && (
          <View style={styles.rating}>
            <Rating rating={task.rating} size={8} />
          </View>
        )}
      </Layout>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  bookmark: {
    width: 25,
    height: 25,
  },
  bookmarkButton: {
    backgroundColor: 'transparent',
    zIndex: 1,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  container: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
    marginRight: 4,
    marginVertical: 8,
  },

  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 10,
    bottom: 7,
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginTop: 3,
    textTransform: 'uppercase',
  },
  imageWrapper: {
    height: 90,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  textContainer: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'center',
    backgroundColor: '#1A3248',
    height: 55,
  },
  contentContainer: {
    flex: 1,
  },
});

export default observer(ProgramItem);
