import React from 'react';
import { View } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import { VictoryPie } from 'victory';

interface OverallProps {
  score: number | null;
  previousScore: number | null;
  maxScore: number;
}

export const Overall: React.FC<OverallProps> = ({
  score,
  previousScore,
  maxScore,
}) => {
  const innerRadius = 130;
  const initCx = 200;
  const initCy = 200;
  const radius = innerRadius + 10;

  const percent = score === null ? 100 : (score / maxScore) * 100;
  const previousPercent =
    previousScore === null ? 0 : (previousScore / maxScore) * 100;
  const data = [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];

  const previousData = [
    { x: 1, y: previousPercent },
    { x: 2, y: 100 - previousPercent },
  ];

  const strokeColor = (percentage: number): string => {
    if (score === null) {
      return '#C4C4C4';
    }
    if (percentage <= 49) {
      return '#C64141';
    }
    if (percentage <= 74) {
      return '#CFAC42';
    }

    if (percentage <= 89) {
      return '#4E7B89';
    }

    return '#767F6A';
  };

  const getCx = () => {
    return (
      initCx +
      radius * Math.cos(((360 * previousPercent) / 100 + -90) * (Math.PI / 180))
    );
  };

  const getCy = () => {
    return (
      initCy +
      radius * Math.sin(((360 * previousPercent) / 100 + -90) * (Math.PI / 180))
    );
  };

  return (
    <View>
      <Svg viewBox="0 0 400 400" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          width={400}
          height={400}
          data={data}
          innerRadius={innerRadius}
          labels={() => null}
          style={{
            data: {
              opacity: score === null ? '0.1' : '1',
              fill: ({ datum }) => {
                return datum.x === 1 ? strokeColor(percent) : 'transparent';
              },
            },
          }}
        />
        <VictoryPie
          standalone={false}
          width={400}
          height={400}
          data={previousData}
          innerRadius={innerRadius}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                return datum.x === 1
                  ? strokeColor(previousPercent)
                  : 'transparent';
              },
              opacity: 0.1,
            },
          }}
        />
        {previousScore && (
          <Circle
            cx={getCx()}
            cy={getCy()}
            r="15"
            stroke="#767F6A"
            strokeWidth="2"
            fill="white"
          />
        )}
      </Svg>
    </View>
  );
};

export default Overall;
