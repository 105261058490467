import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import {
  CheckBox,
  Input,
  Layout,
  Modal,
  Popover,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
  FTESweatWorkoutStepExerciseSet,
} from 'o2x-store/src/models/FTELibrary';
import SweatGlobalStepExercise, {
  SweatGlobalStepExerciseSet,
} from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import RenderHTML from 'react-native-render-html';
import WebView from 'react-native-webview';
import ReactPlayer from 'react-player';
import Url from 'url-parse';
import ExerciseSetItem from './ExerciseSetItem';
import ExerciseSetPopover from './ExerciseSetPopover';
import LoadModalTemplate from './LoadModalTemplate';
import SaveModalTemplate from './SaveModalTemplate';

type Props = {
  content: string;
  paramModalVisible: boolean;
  setParamModalVisible: Function;
  exercise: FTESweatWorkoutStepExercise | SweatGlobalStepExercise;
  exerciseIndex: number;
  stepList?: (FTESweatWorkoutStep | SweatWorkoutStep)[];
  setStepList?: Function;
  stepIndex?: number;
  workoutList?: (FTESweatWorkout | SweatWorkout)[];
  setWorkoutList?: Function;
  workoutIndex?: number;
  exerciseList?: SweatGlobalStepExercise[];
  setExerciseList?: Function;
};

const paramsSelect: { [key: string]: string } = {
  reps: 'Reps',
  isRepsEachSide: 'Each Side?',
  weight: 'Weight',
  distance: 'Distance',
  time: 'Time (MM:SS)',
  rest: 'Rest (MM:SS)',
  rpe: 'RPE',
};

const ExerciseParamModal: React.FC<Props> = (props) => {
  const {
    content,
    stepList,
    setStepList,
    paramModalVisible,
    setParamModalVisible,
    exercise,
    exerciseIndex,
    stepIndex,
    workoutList,
    setWorkoutList,
    workoutIndex,
    exerciseList,
    setExerciseList,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [paramList, setParamList] = useState<string[]>(
    exercise.exerciseSets
      .map((obj) => {
        return Object.keys(obj)
          .filter((key) => obj[key] && obj[key].length && key !== 'name')
          .flatMap((key) => {
            if (key === 'reps') {
              return [paramsSelect[key], paramsSelect['isRepsEachSide']];
            }
            return paramsSelect[key];
          });
      })
      .flat()
      .filter((x, i, a) => a.indexOf(x) === i),
  );

  const [showVideo, setShowVideo] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [loadModalVisible, setLoadModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [videoUrl, setVideoUrl] = useState(exercise.exerciseVideo);
  const [exerciseSet, setExerciseSet] = useState<
    FTESweatWorkoutStepExerciseSet | SweatGlobalStepExerciseSet
  >();
  const [exerciseSetTemplate, setExerciseSetTemplate] = useState<
    FTESweatWorkoutStepExerciseSet | SweatGlobalStepExerciseSet
  >();

  const onPressDone = () => {
    setParamModalVisible(false);
  };

  const addSet = () => {
    const setObject: Partial<FTESweatWorkoutStepExerciseSet> = {
      reps: '',
      isRepsEachSide: false,
      weight: '',
      distance: '',
      time: '',
      rest: '',
      rpe: '',
    };
    const exerciseCopy = exercise;
    exerciseCopy.exerciseSets = [...exerciseCopy.exerciseSets, setObject];
    if (content === 'workout' && setStepList) {
      const list: any = [...stepList!];
      list[stepIndex]['exercises'][exerciseIndex] = exerciseCopy;
      setStepList(list);
    } else if (content === 'program' && setWorkoutList) {
      const workoutListCopy = [...workoutList!];
      workoutListCopy[workoutIndex].steps[stepIndex]['exercises'][
        exerciseIndex
      ] = exerciseCopy;
      setWorkoutList(workoutListCopy);
    } else if (content === 'global' && setExerciseList) {
      const list: any = [...exerciseList!];
      list[exerciseIndex] = exerciseCopy;
      setExerciseList(list);
    }
  };

  const removeSet = (index: number) => {
    const exerciseCopy = exercise;
    exerciseCopy.exerciseSets.splice(index, 1);
    if (content === 'workout' && setStepList) {
      const list: any = [...stepList!];
      list[stepIndex]['exercises'][exerciseIndex] = exerciseCopy;
      setStepList(list);
    } else if (content === 'program' && setWorkoutList) {
      const workoutListCopy = [...workoutList!];
      workoutListCopy[workoutIndex].steps[stepIndex]['exercises'][
        exerciseIndex
      ] = exerciseCopy;
      setWorkoutList(workoutListCopy);
    } else if (content === 'global' && setExerciseList) {
      const list: any = [...exerciseList!];
      list[exerciseIndex] = exerciseCopy;
      setExerciseList(list);
    }
  };

  const onSelectParam = (param: string, status: boolean) => {
    const list = paramList ? paramList : [];
    if (param === 'Each Side?') return;
    if (paramList && paramList.includes(param)) {
      let exerciseCopy = exercise;
      exerciseCopy.exerciseSets.forEach(
        (set) => (set[getParamKey(param)] = ''),
      );
      if (content === 'workout' && setStepList) {
        const list: any = [...stepList!];
        list[stepIndex]['exercises'][exerciseIndex] = exerciseCopy;
        setStepList([...list]);
      } else if (content === 'program' && setWorkoutList) {
        const workoutListCopy = [...workoutList!];
        workoutListCopy[workoutIndex].steps[stepIndex]['exercises'][
          exerciseIndex
        ] = exerciseCopy;
        setWorkoutList([...workoutListCopy]);
      } else if (content === 'global' && setExerciseList) {
        const list: any = [...exerciseList!];
        list[exerciseIndex] = exerciseCopy;
        setExerciseList(list);
      }
      list.splice(paramList.indexOf(param), 1);
      if (param === 'Reps') list.splice(paramList.indexOf('Each Side?'), 1);
    } else {
      list.push(param);
      if (param === 'Reps') list.push('Each Side?');
    }
    setParamList([...list]);
  };

  const handleTimerInput = useCallback((text: string) => {
    if (text.length > 5) return text.slice(0, -1);
    if (text.length > 0 && isNaN(parseInt(text.charAt(text.length - 1)))) {
      return text.slice(0, -1);
    }

    let result = '';
    let duration = text.replace(/:/g, '');
    if (duration.length % 2 === 1 && duration.length > 1) {
      result = `${duration.slice(0, 1)}:`;
      duration = duration.slice(1);
    }
    result += duration.replace(/..\B/g, '$&:');
    if (result.length < 6) {
      return result;
    }
  }, []);

  const onChangeInput = (
    name: string,
    value: string | boolean,
    setIndex?: number,
  ) => {
    let exerciseCopy = exercise;
    if (name === 'notes') {
      exerciseCopy.instructions = value;
    } else {
      if (name === 'time' || name === 'rest') {
        const temp = handleTimerInput(value);
        exerciseCopy.exerciseSets[setIndex][name] = temp ? temp : '';
      } else {
        exerciseCopy.exerciseSets[setIndex][name] = value;
      }
    }
    if (content === 'workout' && setStepList) {
      const list: any = [...stepList!];
      list[stepIndex]['exercises'][exerciseIndex] = exerciseCopy;
      setStepList([...list]);
    } else if (content === 'program' && setWorkoutList) {
      const workoutListCopy = [...workoutList!];
      workoutListCopy[workoutIndex].steps[stepIndex]['exercises'][
        exerciseIndex
      ] = exerciseCopy;
      setWorkoutList([...workoutListCopy]);
    } else if (content === 'global' && setExerciseList) {
      const list: any = [...exerciseList!];
      list[exerciseIndex] = exerciseCopy;
      setExerciseList(list);
    }
  };

  const getParamKey = (value: string) => {
    return Object.keys(paramsSelect).find(
      (key) => paramsSelect[key] === value,
    )!;
  };

  const inputBox = (change: Function, value: string, label: string) =>
    useMemo(() => {
      return (
        <Input
          label={label}
          multiline={true}
          numberOfLines={4}
          style={styles.notesInput}
          value={value}
          onChangeText={(text) => change('notes', text)}
        />
      );
    }, [value]);

  const saveTemplate = useCallback(async () => {
    let data = exerciseSet;
    data.name = templateName;
    data.fte = store.auth.user?.id;
    if (content === 'global') {
      await store.fteLibrary.saveGlobalStepExerciseSetTemplate(data);
    } else {
      await store.fteLibrary.saveFteWorkoutStepExerciseSetTemplate(data);
    }
    setSaveModalVisible(false);
  }, [templateName]);

  useEffect(() => {
    if (exerciseSetTemplate) {
      exercise.exerciseSets[exercise.exerciseSets.indexOf(exerciseSet!)] =
        exerciseSetTemplate;
      if (content === 'workout' && setStepList) {
        const list: any = [...stepList!];
        list[stepIndex]['exercises'][exerciseIndex] = exercise;
        setStepList([...list]);
      } else if (content === 'program' && setWorkoutList) {
        const workoutListCopy = [...workoutList!];
        workoutListCopy[workoutIndex].steps[stepIndex]['exercises'][
          exerciseIndex
        ] = exercise;
        setWorkoutList([...workoutListCopy]);
      } else if (content === 'global' && setExerciseList) {
        const list: any = [...exerciseList!];
        list[exerciseIndex] = exercise;
        setExerciseList(list);
      }
      setParamList(
        exercise.exerciseSets
          .map((obj) =>
            Object.keys(obj)
              .filter((key) => obj[key] && obj[key].length && key !== 'name')
              .flatMap((key) => {
                if (key === 'reps') {
                  return [paramsSelect[key], paramsSelect['isRepsEachSide']];
                }
                return paramsSelect[key];
              }),
          )
          .flat()
          .filter((x, i, a) => a.indexOf(x) === i),
      );
    }
  }, [exerciseSetTemplate]);

  const renderToggleButton = () => (
    <TouchableOpacity
      // style={{
      //   marginTop: 'auto',
      //   marginBottom: 'auto',
      // }}
      onPress={() => {
        setShowVideo(true);
      }}
    >
      <Text style={{ fontSize: 14, color: '#8F9BB3', marginLeft: '5%' }}>
        See Video
      </Text>
    </TouchableOpacity>
  );

  const customHTMLElementModels = {
    iframe: iframeModel,
  };
  const isYoutubeVideo =
    exercise.exerciseVideo &&
    (exercise.exerciseVideo.includes('youtube.com') ||
      exercise.exerciseVideo.includes('youtu.be'));

  const isVideoFile =
    exercise.exerciseVideo &&
    exercise.exerciseVideo.substring(
      exercise.exerciseVideo.lastIndexOf('.') + 1,
      exercise.exerciseVideo.length,
    ) === 'mp4';

  const videoId = (link: string) => {
    const url = new Url(link, true);
    if (!url.query.v) {
      return url.pathname.replace('/', '');
    }
    return url.query.v;
  };

  const updateExerciseVideo = async () => {
    exercise.exerciseVideo = videoUrl;
    const id = Number.isInteger(exercise.exercise)
      ? exercise.exercise
      : exercise.exercise.id;
    await store.fteLibrary.updateFteSweatExercise(id, {
      videoLink: videoUrl,
    });
    setShowVideo(false);
  };

  return (
    <Modal
      visible={paramModalVisible}
      onBackdropPress={() => setParamModalVisible(false)}
      backdropStyle={styles.backdrop}
    >
      <Layout
        style={{ maxHeight: 500, maxWidth: 700, minHeight: 300, minWidth: 400 }}
      >
        {!!saveModalVisible && (
          <SaveModalTemplate
            saveModalVisible={saveModalVisible}
            hideSaveModalVisible={() => setSaveModalVisible(false)}
            templateName={templateName}
            setTemplateName={setTemplateName}
            saveTemplate={saveTemplate}
          />
        )}
        {!!loadModalVisible && (
          <LoadModalTemplate
            loadModalVisible={loadModalVisible}
            content={content === 'global' ? 'global-sets' : 'exercise-sets'}
            hideLoadModalVisible={() => setLoadModalVisible(false)}
            setExerciseSetTemplate={setExerciseSetTemplate}
            store={store}
          />
        )}
        <Layout style={[styles.exerciseText, { flexDirection: 'row' }]}>
          <Text style={{ textTransform: 'uppercase' }} numberOfLines={1}>
            {exercise.exerciseName}
          </Text>
          <Select
            size="small"
            placeholder="Parameters"
            style={{ marginLeft: 'auto', marginRight: 20 }}
          >
            {Object.values(paramsSelect).map((param, index) => {
              return param === 'Each Side?' ? (
                <Fragment key={index}></Fragment>
              ) : (
                <SelectItem
                  key={index}
                  title={() => (
                    <Layout
                      style={{
                        flexDirection: 'row',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <CheckBox
                        checked={paramList?.includes(param)}
                        onChange={(status) => onSelectParam(param, status)}
                        style={{ marginRight: 5 }}
                      >
                        {param}
                      </CheckBox>
                    </Layout>
                  )}
                ></SelectItem>
              );
            })}
          </Select>
        </Layout>
        {paramList && paramList.length > 0 ? (
          <Layout style={{ flexDirection: 'row', marginLeft: 110 }}>
            {paramList?.map((param, index) => {
              return (
                <Text
                  style={{ fontSize: 12, marginRight: 20, width: 50 }}
                  key={index}
                >
                  {param}
                </Text>
              );
            })}
          </Layout>
        ) : null}
        <View style={{ maxHeight: 200, marginLeft: 20, marginTop: 10 }}>
          <ScrollView>
            {exercise.exerciseSets.map((set, setIndex) => {
              return (
                <Layout
                  key={`${setIndex} - ${set.id} - ${exerciseIndex}`}
                  style={{ flexDirection: 'row', marginBottom: 15 }}
                >
                  <ExerciseSetPopover
                    removeItem={() => removeSet(setIndex)}
                    saveItem={() => {
                      setExerciseSet(set);
                      setSaveModalVisible(true);
                    }}
                    loadItem={() => {
                      setExerciseSet(set);
                      setLoadModalVisible(true);
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    {`SET ${setIndex + 1}`}
                  </Text>
                  <Layout
                    style={{
                      flexDirection: 'row',
                      marginLeft: 35,
                    }}
                  >
                    {paramList?.map((param, index) => {
                      return (
                        <ExerciseSetItem
                          key={`${setIndex} - ${set.id} - ${index} - ${param}`}
                          setIndex={setIndex}
                          index={index}
                          param={param}
                          onChangeInput={onChangeInput}
                          set={set}
                          setData={set[getParamKey(param)]}
                        />
                      );
                    })}
                  </Layout>
                </Layout>
              );
            })}
          </ScrollView>
        </View>
        <TouchableOpacity style={styles.exerciseText} onPress={() => addSet()}>
          <Text style={{ fontSize: 14 }}>+ Add Set</Text>
        </TouchableOpacity>
        {inputBox(onChangeInput, exercise.instructions, 'Notes')}

        <Popover
          visible={showVideo}
          placement={'top end'}
          anchor={renderToggleButton}
          style={
            {
              borderWidth: 0.2,
              borderColor: 'black',
            } as StyleProp<ViewStyle>
          }
          onBackdropPress={() => {
            setShowVideo(false);
          }}
        >
          <Layout style={{ height: 500, width: 500 }}>
            {exercise.fteOwner === store.auth.user?.id && (
              <Layout
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  width: '90%',
                  alignSelf: 'center',
                }}
              >
                <Input
                  style={{
                    width: '70%',
                    backgroundColor: 'transparent',
                  }}
                  value={videoUrl}
                  onChangeText={(text) => setVideoUrl(text)}
                  size="small"
                />
                <TouchableOpacity
                  style={{
                    width: '25%',
                    height: 30,
                    backgroundColor: '#767F6A',
                    marginLeft: 'auto',
                  }}
                  onPress={() => updateExerciseVideo()}
                >
                  <Text style={styles.doneText}>Update</Text>
                </TouchableOpacity>
              </Layout>
            )}
            {isVideoFile ? (
              <ReactPlayer
                url={`${exercise.exerciseVideo}`}
                playing={true}
                loop={true}
                style={{
                  alignSelf: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  width: 450,
                  height: 450,
                }}
              />
            ) : // VIDEO from expo-av crashes when unmounted on version 9.2.3, fixed on 10.1.3 but not supported by expo 42
            // <VideoPlayer
            //   videoLink={exercise.exerciseVideo}
            //   style={{
            //     alignSelf: 'center',
            //     marginTop: 'auto',
            //     marginBottom: 'auto',
            //   }}
            //   size={450}
            //   muted={true}
            // />
            isYoutubeVideo ? (
              <RenderHTML
                renderers={{ iframe: IframeRenderer }}
                tagsStyles={{
                  iframe: {
                    alignSelf: 'center',
                    marginTop: 25,
                    width: 450,
                    height: 400,
                  },
                }}
                WebView={WebView}
                customHTMLElementModels={customHTMLElementModels}
                source={{
                  html: `<iframe src='https://www.youtube.com/embed/${videoId(
                    exercise.exerciseVideo,
                  )}?fs=0&rel=0&showinfo=0&loop=1&playlist=${videoId(
                    exercise.exerciseVideo,
                  )}'allowfullscreen="0" donotallowfullscreen></iframe>`,
                }}
              />
            ) : (
              <Text
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  alignSelf: 'center',
                  fontSize: 14,
                }}
              >
                No Video Available
              </Text>
            )}
          </Layout>
        </Popover>

        <TouchableOpacity
          style={styles.doneButton}
          onPress={() => onPressDone()}
        >
          <Text style={styles.doneText}>Done</Text>
        </TouchableOpacity>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  doneButton: {
    marginLeft: 'auto',
    marginRight: 20,
    marginTop: 'auto',
    marginBottom: 10,
    width: 40,
    height: 20,
    backgroundColor: '#767F6A',
  },
  doneText: {
    alignSelf: 'center',
    fontSize: 12,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  notesInput: {
    alignSelf: 'center',
    minWidth: '90%',
    borderRadius: 0,
  },
  exerciseText: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 15,
  },
  paramInput: {
    width: 50,
    borderColor: 'transparent',
    borderRadius: 0,
    borderBottomColor: 'white',
    backgroundColor: 'transparent',
    marginRight: 20,
  },
});

export default observer(ExerciseParamModal);
