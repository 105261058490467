import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { filter, indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import Team, { ValidatedUser } from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FlatList,
  Image,
  ImageStyle,
  SafeAreaView,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import CheckBox from 'src/components/CheckBox';
import DeleteIcon from '../../assets/images/delete.svg';
import LabeledInput from '../../components/LabeledInput';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const TeamEdit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { teamId },
    },
  } = props;

  const root = useStore();
  const store = root;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeArea();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [items, setItems] = useState<Map<string, ValidatedUser>>(new Map());
  const [itemsPending, setItemsPending] = useState<Map<string, ValidatedUser>>(
    new Map(),
  );
  const [selectedItems, setSelectedItems] = useState<Array<number>>(
    new Array<number>(),
  );
  const [usersToAdd, setUsersToAdd] = useState<Array<number>>(
    new Array<number>(),
  );

  const [toValidate, setToValidate] = useState<string>('');

  let selectedTeam: Team | undefined;
  if (teamId) {
    selectedTeam = store.teams.teams.get(`${teamId}`);
  }

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (selectedTeam) {
        const updateData: {
          name?: string;
          members?: number[];
          pendingMembers?: number[];
        } = {
          name: getValues('name'),
          members: selectedItems,
          pendingMembers: usersToAdd,
        };
        const result = await store.teams.updateTeam(
          updateData,
          `${selectedTeam.id}`,
        );
        setLoading(false);
        if (result.ok) {
          navigation.goBack();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      } else {
        const result = await store.teams.createTeam({
          ...data,
          members: selectedItems,
          pendingMembers: usersToAdd,
        });
        setLoading(false);
        if (result.ok) {
          navigation.goBack();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [selectedTeam, selectedItems, usersToAdd, store.auth.user],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onDelete = useCallback(() => {
    navigation.navigate('TeamDelete', { teamId });
  }, [teamId]);

  const onToggleSelected = (checked: boolean, itemId: number) => {
    if (checked) {
      const newSelectedItems = [...selectedItems, itemId];
      setSelectedItems(newSelectedItems);
    } else {
      const filteredSelectedItems = filter(selectedItems, (i) => i !== itemId);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const onToggleUserToAdd = (checked: boolean, itemId: number) => {
    if (checked) {
      setUsersToAdd([...usersToAdd, itemId]);
    } else {
      const filteredUsers = filter(usersToAdd, (i) => i !== itemId);
      setUsersToAdd(filteredUsers);
    }
  };

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const validateUser = useCallback(async () => {
    const verifier = await store.teams.validateUser(toValidate);
    if (verifier.response) {
      if (verifier.response.entities) {
        setItems((prevItems) => {
          prevItems.set(
            `${verifier.response.entities.id}`,
            verifier.response.entities,
          );
          return prevItems;
        });
        setSelectedItems((prevSelectedItems) => [
          ...prevSelectedItems,
          verifier.response.entities.id,
        ]);
        setErrorMessage('');
      }
    } else {
      setErrorMessage(getErrors(verifier.errors));
    }
  }, [setItems, toValidate, setErrorMessage, setSelectedItems]);

  const validateUserToAdd = useCallback(async () => {
    const verifier = await store.teams.validateUser(toValidate);
    if (verifier.response) {
      if (verifier.response.entities) {
        setItemsPending((prevItems) => {
          prevItems.set(
            `${verifier.response.entities.id}`,
            verifier.response.entities,
          );
          return prevItems;
        });
        setUsersToAdd((prevSelectedItems) => [
          ...prevSelectedItems,
          verifier.response.entities.id,
        ]);
        setErrorMessage('');
      }
    } else {
      setErrorMessage(getErrors(verifier.errors));
    }
  }, [setItemsPending, toValidate, setErrorMessage, setUsersToAdd, usersToAdd]);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <Layout style={styles.userItem}>
          <CheckBox
            key={item.id}
            fill="white"
            checked={indexOf(selectedItems, item.id) > -1}
            onChange={(key: string, checked: boolean) => {
              onToggleSelected(checked, item.id);
            }}
            style={styles.input}
          />
          <View style={styles.item}>
            <Image
              style={styles.itemImage as StyleProp<ImageStyle>}
              source={
                item.imageThumbnail
                  ? { uri: item.imageThumbnail }
                  : require('../../assets/images/user_placeholder.png')
              }
            />
            <Text style={styles.itemName}>{item.name}</Text>
          </View>
        </Layout>
      );
    },
    [selectedItems],
  );

  const renderUserToAddItem = useCallback(
    ({ item }) => {
      return (
        <Layout style={styles.userItem}>
          <CheckBox
            key={item.id}
            fill="white"
            checked={indexOf(usersToAdd, item.id) > -1}
            onChange={(key: string, checked: boolean) => {
              onToggleUserToAdd(checked, item.id);
            }}
            style={styles.input}
          />
          <View style={styles.item}>
            <Image
              style={styles.itemImage as StyleProp<ImageStyle>}
              source={
                item.imageThumbnail
                  ? { uri: item.imageThumbnail }
                  : require('../../assets/images/user_placeholder.png')
              }
            />
            <Text style={styles.itemName}>{item.name}</Text>
          </View>
        </Layout>
      );
    },
    [usersToAdd],
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (selectedTeam) {
          const result = await store.teams.fetchPendingMembers(
            `${selectedTeam.id}`,
          );
          const pendingMembersMap = new Map<string, ValidatedUser>();
          const pendingMembersIds = new Array<number>();
          result.response.entities.forEach((pendingMember: ValidatedUser) => {
            pendingMembersMap.set(`${pendingMember.id}`, pendingMember);
            pendingMembersIds.push(pendingMember.id);
          });
          setItemsPending(pendingMembersMap);
          setUsersToAdd(pendingMembersIds);
        }
      })();
    }, [selectedTeam, setItemsPending, setUsersToAdd]),
  );

  useEffect(() => {
    register({ name: 'name' }, { required: true });
  }, [register]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (selectedTeam) {
          const result = await store.teams.fetchMembers(`${selectedTeam.id}`);
          const membersMap = new Map<string, ValidatedUser>();
          const memberIds = new Array<number>();
          if (result.response) {
            result.response.entities.forEach((member: ValidatedUser) => {
              membersMap.set(`${member.id}`, member);
              memberIds.push(member.id);
            });
            setItems(membersMap);
            setSelectedItems(memberIds);
          }
        }
      })();
    }, [selectedTeam, setItems, setSelectedItems]),
  );

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <View style={[styles.navigationContainer, { marginTop: insets.top }]}>
            <TopNav showBack={false} showClose={true} />
          </View>
          <Layout style={styles.container}>
            <SafeAreaView
              style={
                isMobile
                  ? styles.scrollMobile
                  : isDeviceMaxWidth600
                  ? styles.scrollMaxWidth600
                  : styles.scroll
              }
            >
              <Layout style={styles.scrollWrapper}>
                <View style={styles.headerContainer}>
                  {!selectedTeam ? (
                    <Text style={styles.header}>New Team</Text>
                  ) : (
                    <Text style={styles.header}>Edit Team</Text>
                  )}
                  {!!errorMessage && (
                    <Text style={styles.error} category="label">
                      {errorMessage}
                    </Text>
                  )}
                  <View style={styles.inlineInput}>
                    <LabeledInput
                      style={styles.inputContainer}
                      label="Name"
                      returnKeyType="go"
                      onChangeText={(text) => setValue('name', text)}
                      placeholder="My Favorite Team"
                      divider
                      defaultValue={selectedTeam?.name || ''}
                      multiline
                    />
                    {selectedTeam && (
                      <TouchableOpacity
                        style={styles.delete}
                        onPress={onDelete}
                      >
                        <DeleteIcon style={styles.deleteIcon} />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
                <View style={styles.inlineInput2}>
                  <LabeledInput
                    style={styles.inputContainer}
                    label="Add Member"
                    returnKeyType="go"
                    placeholder="Type an email or o2x id"
                    onChangeText={(text) => {
                      setToValidate(text);
                    }}
                    divider
                  />
                  <TouchableOpacity
                    style={styles.delete}
                    onPress={validateUserToAdd}
                  >
                    <Icon
                      style={styles.deleteIcon}
                      name="person-add-outline"
                      stroke="white"
                    />
                  </TouchableOpacity>
                </View>
                <ScrollView style={styles.listContainer}>
                  {!!items.size && (
                    <View style={styles.listContainer}>
                      <FlatList
                        data={Array.from(items.values())}
                        renderItem={renderItem}
                        keyExtractor={(item) => `${item.id}`}
                        style={styles.listItem}
                      />
                    </View>
                  )}

                  {!!itemsPending.size && (
                    <View style={styles.listContainer}>
                      <Text style={styles.pendingText}>Pending Invites:</Text>
                      <FlatList
                        data={Array.from(itemsPending.values())}
                        renderItem={renderUserToAddItem}
                        keyExtractor={(item) => `${item.id}`}
                        style={styles.listItem}
                        ListEmptyComponent={null}
                      />
                    </View>
                  )}
                </ScrollView>
              </Layout>
            </SafeAreaView>
            {!selectedTeam ? (
              <Layout style={styles.actions}>
                <TouchableOpacity
                  style={styles.button}
                  disabled={loading}
                  onPress={onSubmitWrapped}
                >
                  <Text style={styles.buttonLabel} category="c1">
                    Create
                  </Text>
                </TouchableOpacity>
              </Layout>
            ) : (
              <Layout style={styles.actions}>
                <TouchableOpacity
                  style={[styles.button, styles.buttonCancel]}
                  disabled={loading}
                  onPress={goBack}
                >
                  <Text style={styles.buttonLabel} category="c1">
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.button}
                  disabled={loading}
                  onPress={onSubmit}
                >
                  <Text style={styles.buttonLabel} category="c1">
                    Save
                  </Text>
                </TouchableOpacity>
              </Layout>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'olive',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    marginVertical: 30,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center',
  },
  inputContainer: {
    flex: 1,
  },
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 25,
    paddingHorizontal: '25%',
  },
  scrollMobile: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 10,
    paddingHorizontal: '5%',
  },
  scrollMaxWidth600: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    paddingHorizontal: '15%',
  },
  scrollWrapper: { flex: 1 },
  delete: {
    width: 24,
    marginLeft: 10,
  },
  deleteText: {
    color: 'red',
    fontSize: 18,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    marginBottom: 20,
  },
  inlineInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineInput2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 10,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  input: {
    marginRight: 20,
  },
  itemContainer: {
    flexDirection: 'row',
  },
  inputLabel: {
    flex: 1,
  },
  navigationContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  headerContainer: {},
  listItem: {
    marginBottom: 20,
  },
  listContainer: {
    flex: 1,
  },
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  item: {
    flex: 1,
    backgroundColor: 'dark-blue',
    height: 60,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
  },
  itemName: {
    paddingHorizontal: 12,
    fontSize: 14,
    lineHeight: 17,
  },
  pendingText: {
    fontSize: 14,
    lineHeight: 17,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
});

export default observer(TeamEdit);
