import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageService } from 'o2x-store/src/services/storage';

export class NativeStorageService extends StorageService {
  getItemAsync = (key: string) => {
    return AsyncStorage.getItem(key);
  };

  setItemAsync = (key: string, item: string) => {
    return AsyncStorage.setItem(key, item);
  };

  removeItemAsync = (key: string) => {
    return AsyncStorage.removeItem(key);
  };

  getSecureItemAsync = (key: string) => {
    // secure storage not available for web
    return AsyncStorage.getItem(key);
  };

  setSecureItemAsync = (key: string, item: string) => {
    // secure storage not available for web
    return AsyncStorage.setItem(key, item);
  };

  removeSecureItemAsync = (key: string) => {
    // secure storage not available for web
    return AsyncStorage.removeItem(key);
  };
}
