import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Input,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isNumber, toNumber } from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from '../../components/NavigationBar';
import { useNativeStore } from '../../stores';
import { getTimeHumanDisplay } from '../../utils/timeDisplay';
import { AppStackParamList } from '../AppContainer';

export enum THRIVE_ACTIVITY {
  SLEEP = 'SLEEP',
  BREATHE = 'BREATHE',
  MEDITATE = 'MEDITATE',
}

const timeMapping = {
  [THRIVE_ACTIVITY.SLEEP]: [15 * 60, 30 * 60, 1 * 60 * 60, 2 * 60 * 60],
  [THRIVE_ACTIVITY.BREATHE]: [30, 1 * 60, 5 * 60, 10 * 60],
  [THRIVE_ACTIVITY.MEDITATE]: [1 * 60, 5 * 60, 10 * 60, 30 * 60],
};

type ItemProps = {
  seconds: number;
  infinity?: boolean;
  onSelect: (seconds: number) => void;
};

const ThriveSetTimeItem: React.FC<ItemProps> = (props) => {
  const { seconds, infinity = false, onSelect } = props;
  const styles = useStyleSheet(themedStyles);

  const onPress = useCallback(() => {
    onSelect(seconds);
  }, [seconds]);

  return (
    <TouchableOpacity style={styles.item} onPress={onPress}>
      <Text>{infinity ? 'Infinity' : getTimeHumanDisplay(seconds)}</Text>
    </TouchableOpacity>
  );
};

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveSetTime'>;
};

const ThriveSetTime: React.FC<Props> = (props) => {
  const {
    route: {
      params: { activity },
    },
  } = props;
  const { thriveStart } = useNativeStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const [custom, setCustom] = useState('');
  const insets = useSafeAreaInsets();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onSetTime = useCallback((seconds: number) => {
    thriveStart.setTime(seconds);
    navigation.goBack();
  }, []);

  const onSetInfinity = useCallback(() => {
    thriveStart.setTime(Infinity);
    navigation.goBack();
  }, []);

  const onSetCustomValue = useCallback((custom: string) => {
    setCustom(custom);
  }, []);

  const onSetCustomTime = useCallback(() => {
    const customNumber = toNumber(custom);
    if (isNumber(customNumber) && !isNaN(customNumber)) {
      thriveStart.setTime(customNumber * 60); // asummed minutes, convert to seconds
    } else {
      thriveStart.setTime(moment.duration(custom).as('seconds') / 60);
    }
    navigation.goBack();
  }, [custom]);

  const keyExtractor = useCallback((item) => `${item}`, []);

  const renderItem = useCallback(
    ({ item }) => <ThriveSetTimeItem seconds={item} onSelect={onSetTime} />,
    [],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar
                showBack={true}
                showClose={false}
                goBack={goBack}
                withBackground={false}
              />
            </Layout>
            <FlatList
              contentContainerStyle={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
              data={timeMapping[activity]}
              keyExtractor={keyExtractor}
              renderItem={renderItem}
              ListFooterComponent={
                <>
                  <ThriveSetTimeItem
                    seconds={0}
                    infinity
                    onSelect={onSetInfinity}
                  />
                  <Text style={styles.label} category="c2">
                    Custom
                  </Text>
                  <Input
                    key="custom"
                    placeholder="01:00"
                    value={custom}
                    returnKeyType="go"
                    onChangeText={onSetCustomValue}
                    onSubmitEditing={onSetCustomTime}
                  />
                </>
              }
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    height: 600,
  },
  contentContainer: {
    paddingVertical: 8,
    paddingHorizontal: '25%',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    paddingVertical: 8,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    paddingVertical: 8,
  },
  item: {
    paddingVertical: 10,
  },
  label: {
    marginTop: 30,
    marginBottom: 8,
    textTransform: 'uppercase',
  },
});

export default ThriveSetTime;
