import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import EventModel, { EventsByRange } from 'o2x-store/src/models/Event';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ScrollView,
  SectionList,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import EventCalendar from 'src/components/Events/EventCalendar';
import EventJoinForm from 'src/components/Events/EventJoinForm';
import EventItem from '../../components/Events/EventItem';
import { useSaveList } from '../../hooks/list';
import ConditionalWrapper from '../ConditionalWrapper';

type Props = {
  route?: any | null;
};

type DateRange = {
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
};

const Events: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const theme = useTheme();
  const navigation = useNavigation();
  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    LIST_TYPE.EVENT,
  );

  const [events, setEvents] = useState<Array<EventsByRange>>(new Array());
  const [search, setSearch] = useState('');
  const [range, setRange] = useState<DateRange>({
    dateStart: undefined,
    dateEnd: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      fetchEvents(range);
    }, [setEvents, range]),
  );

  const fetchEvents = useCallback(
    (range: DateRange) => {
      (async () => {
        setLoading(true);
        const result = await store.event.fetchEventsByRange(
          range.dateStart,
          range.dateEnd,
        );
        if (result) {
          setEvents(result);
        }
        setLoading(false);
      })();
    },
    [setEvents, setLoading],
  );

  const doSearch = useCallback(
    (search: string) => {
      setRange({ dateStart: undefined, dateEnd: undefined });
      const q = search;
      (async () => {
        const result = await store.event.fetchEventsByRange(
          undefined,
          undefined,
          q,
        );
        if (result) {
          setEvents(result);
        }
      })();
    },
    [setRange],
  );

  const debouncedDoSearch = useMemo(
    () => debounce((search: string) => doSearch(search), 500),
    [],
  );

  const goToDetail = (item: EventModel) => {
    navigation.navigate('EventDetail', { id: item.id });
  };

  const toggleBookmark = useCallback(
    async (item: EventModel) => {
      const event = item;
      if (event) {
        let success = false;
        if (event.saveList.length === 0) {
          // Save
          success = addToList(savedList, `${event.id}`);
          if (success) {
            fetchEvents(range);
          }
        } else {
          // Remove
          success = removeFromList(savedList, `${event.id}`);
          if (success) {
            fetchEvents(range);
          }
        }
      }
    },
    [savedList, fetchEvents, range],
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }) => (
      <View style={styles.sectionTitle}>
        <Text style={styles.subsectionTitle}>
          {moment(title).format('dddd')}
        </Text>
        <Text style={styles.subsectionTitle}>
          {moment(title).format('MMMM D, YYYY')}
        </Text>
      </View>
    ),
    [],
  );

  const renderSectionFooter = useCallback(
    () => <View style={!isDeviceMaxWidth600 && styles.sectionFooter} />,
    [],
  );

  const renderItem = ({ item }) =>
    item ? (
      <EventItem
        key={`${item.id}`}
        event={item}
        onPress={() => goToDetail(item)}
        onSave={() => toggleBookmark(item)}
      />
    ) : null;

  const setSelectedRange = useCallback(
    (dateStart: Date | undefined, dateEnd: Date | undefined) => {
      setRange({ dateStart, dateEnd });
      fetchEvents({ dateStart, dateEnd });
    },
    [setRange],
  );

  const goToSavedEvents = useCallback(() => {
    navigation.navigate('SavedStack', {
      screen: 'Saved',
      params: {
        type: LIST_TYPE.EVENT,
      },
    });
  }, []);

  return (
    <Layout
      style={
        isDeviceMaxWidth600 ? styles.containerMaxWidth600 : styles.container
      }
    >
      <View style={styles.contentContainer}>
        <View
          style={
            isDeviceMaxWidth600
              ? styles.filterContainerMaxWidth600
              : styles.filterContainer
          }
        >
          <TouchableOpacity
            style={[
              styles.actionItem,
              isDeviceMaxWidth600
                ? styles.actionItemHorizontalMaxWidth600
                : styles.actionItemHorizontal,
            ]}
            onPress={goToSavedEvents}
          >
            <Icon
              style={[
                isDeviceMaxWidth600
                  ? styles.actionIconMaxWidth600
                  : styles.actionIcon,
                styles.actionIconHorizontal,
              ]}
              fill="#C4C4C4"
              name="bookmark-outline"
            />
            <Text
              style={
                isDeviceMaxWidth600
                  ? styles.viewSavedTextMaxWidth600
                  : styles.viewSavedText
              }
            >
              View saved
            </Text>
          </TouchableOpacity>
          <Layout style={styles.search}>
            <Icon
              style={styles.searchIcon}
              name="search"
              fill={theme['white']}
            />
            <TextInput
              style={styles.searchInput}
              placeholder="Search"
              placeholderTextColor={theme['light-gray']}
              returnKeyType="search"
              value={search}
              onChangeText={(text: string) => {
                setSearch(text);
                debouncedDoSearch(text);
              }}
            />
          </Layout>
        </View>
        {/*
        <Layout style={styles.actions}>
          <TouchableOpacity style={styles.saved}>
            <Saved />
            <Text style={styles.savedText}>Saved</Text>
          </TouchableOpacity>
        </Layout>
        */}
        <ConditionalWrapper
          condition={isDeviceMaxWidth600}
          wrapper={(children: any) => <ScrollView>{children}</ScrollView>}
        >
          <Layout
            style={
              isDeviceMaxWidth600
                ? styles.eventListMaxWidth600
                : styles.eventList
            }
          >
            <View style={styles.calendarContainer}>
              <EventCalendar onChangeDate={setSelectedRange} range={range} />
              {!isDeviceMaxWidth600 && (
                <View style={styles.join}>
                  <EventJoinForm onSuccess={() => fetchEvents(range)} />
                </View>
              )}
            </View>
            <View
              style={
                isDeviceMaxWidth600
                  ? styles.listContainerMaxWidth600
                  : styles.listContainer
              }
            >
              {!!range.dateStart && !!range.dateEnd && (
                <View>
                  <Text style={styles.range}>{`${moment(range.dateStart).format(
                    'MMMM D',
                  )} - ${moment(range.dateEnd).format('MMMM D')}`}</Text>
                </View>
              )}
              {loading ? (
                <View style={styles.loading}>
                  <Spinner />
                </View>
              ) : (
                <SectionList
                  sections={events}
                  renderSectionHeader={renderSectionHeader}
                  renderItem={renderItem}
                  renderSectionFooter={renderSectionFooter}
                  keyExtractor={(item: EventModel) => `${item.id}`}
                  style={!isDeviceMaxWidth600 && styles.listItem}
                />
              )}
            </View>
            {isDeviceMaxWidth600 && (
              <View>
                <EventJoinForm onSuccess={() => fetchEvents(range)} />
              </View>
            )}
          </Layout>
        </ConditionalWrapper>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingVertical: 33,
  },
  containerMaxWidth600: {
    flex: 1,
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 15,
  },
  contentContainer: {
    flex: 1,
  },
  header: {
    marginBottom: 26,
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  selection: {
    flexDirection: 'row',
    paddingTop: 4,
    marginBottom: 24,
  },
  selectionContainer: {
    marginRight: 50,
  },
  selectionContainerSelected: {},
  selectionText: {
    color: 'white',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 3,
    alignSelf: 'center',
  },
  selectionTextSelected: {
    opacity: 1,
    borderBottomWidth: 2,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  actionText: {
    color: 'blue-secondary',
    fontSize: 14,
  },
  link: {
    fontSize: 14,
    color: 'blue-secondary',
    borderBottomWidth: 1,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  listContainer: {
    flex: 1,
    paddingBottom: 24,
  },
  listContainerMaxWidth600: {
    flex: 1,
    marginVertical: 20,
  },
  listItem: {
    paddingHorizontal: 24,
    marginRight: -24,
  },
  search: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  searchIcon: {
    height: 24,
    width: 24,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    marginHorizontal: 10,
    outlineWidth: 0,
  },
  searchWrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
  },
  eventList: {
    flexDirection: 'row',
  },
  eventListMaxWidth600: {
    width: '100%',
  },
  calendarContainer: {},
  sectionTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionFooter: {
    marginBottom: 30,
  },
  subsectionTitle: {
    fontSize: 18,
    lineHeight: 21,
    color: 'light-gray',
  },
  range: {
    color: 'white',
    fontSize: 24,
    lineHeight: 29,
    fontWeight: '700',
    marginHorizontal: 24,
    marginBottom: 18,
  },
  join: {
    marginTop: 40,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  actions: {
    marginBottom: 20,
  },
  saved: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  savedText: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginHorizontal: 20,
  },
  actionItem: {
    alignItems: 'center',
    paddingRight: 12,
    marginRight: 20,
  },
  actionItemHorizontal: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  actionItemHorizontalMaxWidth600: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  actionIcon: {
    width: 30,
    height: 30,
  },
  actionIconMaxWidth600: {
    height: 20,
    width: 20,
  },
  actionIconHorizontal: {
    marginRight: 12,
  },
  viewSavedText: {
    textTransform: 'uppercase',
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    textAlign: 'center',
  },
  viewSavedTextMaxWidth600: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 36,
  },
  filterContainerMaxWidth600: {
    width: '100%',
    marginBottom: 20,
  },
});

export default observer(Events);
