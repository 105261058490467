import { useNavigation } from '@react-navigation/core';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { observer } from 'mobx-react-lite';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DLWorkoutBottomSheet from 'src/components/Downloaded/DLWorkoutBottomSheet';
import DLWorkoutItem from 'src/components/Downloaded/DLWorkoutItem';
import DLWorkoutVideo from 'src/components/Downloaded/DLWorkoutVideo';
import TopNav from 'src/components/Question/TopNav';
import DownloadedItem from 'src/models/DownloadedItem';
import { useNativeStore } from 'src/stores';
import VideoFilledIcon from '../../assets/images/video-filled.svg';

enum WORKOUT_TYPE {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

type Props = {
  route: any;
};

const DownloadedWorkout: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { downloads } = useNativeStore();
  useKeepAwake();

  const { id, workoutId, programId } = props.route.params;

  const [workoutType, setWorkoutType] = useState(WORKOUT_TYPE.PREPARE);

  const [isPaused, setIsPaused] = useState(true);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [exerciseIndex, setExerciseIndex] = useState(0);

  const [title, setTitle] = useState('PREPARE');
  const [data, setData] = useState<DownloadedItem[]>([]);

  const [totalTime, setTotalTime] = useState(0);
  const [time, setTime] = useState(0);

  const interval = useRef<any>(null);
  const ref = useRef<FlatList>(null);

  const color = getTaskColor(TASK_TYPE.SWEAT);
  const program = downloads.sweatPrograms.get(`${programId}`);

  const workout =
    programId && program
      ? program.workouts.get(`${workoutId}`)
      : downloads.sweatWorkouts.get(`${id}`);

  useEffect(() => {
    if (
      !workout ||
      workout.sections.length <= sectionIndex ||
      workout.sections[sectionIndex].exercises.length <= exerciseIndex
    ) {
      return;
    }

    const currentData: DownloadedItem[] = [];
    workout.sections[sectionIndex].exercises.forEach((s) => {
      const exercise = downloads.sweatExercises.get(`${s}`);
      if (exercise) currentData.push(exercise);
    });

    setIsVideoVisible(false);
    setData(currentData);
    setTime(0);
    setTitle(workoutType);
  }, [workout, sectionIndex, exerciseIndex, workoutType]);

  const renderItem = useCallback(
    ({ item, index }) => (
      <DLWorkoutItem
        item={item}
        index={index}
        currentExercise={exerciseIndex}
      />
    ),
    [exerciseIndex],
  );

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const keyExtractor = useCallback((i, j) => `${j}:${i.id}${i.exercise}`, []);

  const onStop = () => {
    clearInterval(interval.current);
    setIsPaused(true);
    setTotalTime(totalTime + time);
    setTime(0);
  };

  const onRestart = useCallback(() => {
    setTotalTime(totalTime + time);
    setTime(0);
  }, [totalTime, time]);

  const onBack = () => {
    if (!workout) return;
    setIsVideoVisible(false);
    let success = true;
    if (exerciseIndex === 0) {
      if (sectionIndex > 0) {
        const nextIndex =
          workout.sections[sectionIndex - 1].exercises.length - 1;
        setExerciseIndex(nextIndex);
        setSectionIndex(sectionIndex - 1);
        setWorkoutType(
          sectionIndex === 1 ? WORKOUT_TYPE.PREPARE : WORKOUT_TYPE.SWEAT,
        );
        setTimeout(() => {
          try {
            ref.current?.scrollToOffset({
              offset: nextIndex * 60,
              animated: true,
            });
          } catch {
            console.log('[DEBUG]');
          }
        }, 300);
      } else {
        success = false;
      }
    } else {
      try {
        ref.current?.scrollToOffset({
          offset: (exerciseIndex - 1) * 60,
          animated: true,
        });
      } catch {
        console.log('[DEBUG]');
      }
      setExerciseIndex(exerciseIndex - 1);
    }
    if (success) {
      setTotalTime(totalTime + time);
      setTime(0);
    }
  };

  const onNext = () => {
    if (!workout) return;
    setIsVideoVisible(false);
    if (data.length === exerciseIndex + 1) {
      if (workout.sections.length === sectionIndex + 1) {
        navigation.goBack();
      } else {
        setExerciseIndex(0);
        setSectionIndex(sectionIndex + 1);
        setWorkoutType(
          sectionIndex === workout.sections.length - 2
            ? WORKOUT_TYPE.RECOVER
            : WORKOUT_TYPE.SWEAT,
        );
      }
    } else {
      try {
        ref.current?.scrollToOffset({
          offset: (exerciseIndex + 1) * 60,
          animated: true,
        });
      } catch {
        console.log('[DEBUG]');
      }
      setExerciseIndex(exerciseIndex + 1);
    }
    setTotalTime(totalTime + time);
    setTime(0);
  };

  const onSkip = useCallback(() => {
    setIsVideoVisible(false);
    if (workoutType === WORKOUT_TYPE.PREPARE) {
      setSectionIndex(1);
      setExerciseIndex(0);
      setWorkoutType(WORKOUT_TYPE.SWEAT);
    } else if (workoutType === WORKOUT_TYPE.RECOVER) {
      navigation.goBack();
    }
  }, [workoutType]);

  const onPlayOrPause = () => {
    if (isPaused) {
      setIsPaused(false);
      interval.current = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    } else {
      setIsPaused(true);
      clearInterval(interval.current);
    }
  };

  const onGoBack = () => {
    navigation.goBack();
  };

  if (!workout) return null;

  return (
    <Layout
      style={[
        styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={styles.content}>
        <View style={styles.header}>
          <Text style={[styles.title, { color }]}>{title}</Text>
          <TouchableOpacity
            style={styles.video}
            onPress={() => setIsVideoVisible(true)}
          >
            <VideoFilledIcon />
          </TouchableOpacity>
          <View style={styles.navigationContainer}>
            <TopNav
              style={styles.navigationStyle}
              showClose={false}
              onClose={onGoBack}
              onBack={onGoBack}
            />
          </View>
        </View>
        <View style={styles.content}>
          <FlatList
            ref={ref}
            renderItem={renderItem}
            ListFooterComponent={renderFooter}
            keyExtractor={keyExtractor}
            data={data}
          />
        </View>
        <DLWorkoutVideo
          items={data}
          index={exerciseIndex}
          hidden={!isVideoVisible}
          setIsVisible={setIsVideoVisible}
        />
      </View>
      <DLWorkoutBottomSheet
        time={time}
        totalTime={totalTime + time}
        workoutType={workoutType}
        onBack={onBack}
        onPlayOrPause={onPlayOrPause}
        onNext={onNext}
        onSkip={onSkip}
        onStop={onStop}
        onRestart={onRestart}
        isPlaying={!isPaused}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  content: {
    flex: 1,
  },
  tabs: {
    flexDirection: 'row',
  },
  navigationContainer: {
    paddingHorizontal: 16,
    position: 'absolute',
    width: '100%',
  },
  navigationStyle: {
    backgroundColor: 'transparent',
  },
  video: {
    height: 60,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingLeft: 60,
    paddingRight: 6,
  },
  footer: {
    height: 15,
  },
  label: {
    textAlign: 'center',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'olive',
  },
  cover: {
    height: 170,
  },
  coverGradient: {
    position: 'absolute',
    height: 120,
    width: '100%',
    top: 50,
  },
  title: {
    flex: 1,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  subtitle: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
    marginHorizontal: 25,
    marginTop: 8,
  },
});

export default observer(DownloadedWorkout);
