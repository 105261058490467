import {
  CheckBox,
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useEffect, useState } from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  opacity: Animated.AnimatedInterpolation;
  scale: Animated.AnimatedInterpolation;
  translateY: Animated.AnimatedInterpolation;
  isSavingSuccess: boolean;
  isLoading: boolean;
  contentSearch: string;
  setContentSearch: Function;
  onSearchContent: Function;
  loadMoreContent: Function;
  loadingExistingContents: boolean;
  loadingMoreContent: boolean;
  loadingContents: boolean;
  existingContents?: SweatProgram[] | SweatWorkout[];
  addToLibrary: Function;
  removeFromLibrary: Function;
  libraryFuzzy: string;
  setLibraryFuzzy: Function;
  searchLibraryItem: Function;
  onDelete: Function;
  myLibrary?: SweatProgram[] | SweatWorkout[];
  loadingSave: boolean;
  onSaveLibrary: () => void;
  content: string;
};

const { width, height } = Dimensions.get('window');

const LibraryList: React.FC<Props> = (props) => {
  const {
    opacity,
    scale,
    translateY,
    isSavingSuccess,
    isLoading,
    contentSearch,
    setContentSearch,
    loadMoreContent,
    onSearchContent,
    loadingContents,
    loadingExistingContents,
    loadingMoreContent,
    existingContents,
    addToLibrary,
    removeFromLibrary,
    libraryFuzzy,
    setLibraryFuzzy,
    searchLibraryItem,
    onDelete,
    myLibrary,
    loadingSave,
    onSaveLibrary,
    content,
  } = props;

  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [showExisting, setShowExisting] = useState(true);
  const [showCustomized, setShowCustomized] = useState(true);
  const [allContents, setAllContents] = useState(existingContents);

  useEffect(() => {
    if (existingContents) {
      let arr = [];
      if (showExisting) {
        arr.push(
          ...existingContents.filter(
            (content) => !content.fteOwner || !content.fteOwner.id,
          ),
        );
      }
      if (showCustomized) {
        arr.push(
          ...existingContents.filter(
            (content) => !!content.fteOwner && !!content.fteOwner.id,
          ),
        );
      }
      setAllContents(arr);
    }
  }, [showExisting, showCustomized, existingContents]);

  //For screens greater than 1920x1080, scroll view cuts the list and infinite
  //scrolling is not applicable
  // const isCloseToBottom = ({
  //   layoutMeasurement,
  //   contentOffset,
  //   contentSize,
  // }: NativeScrollEvent) => {
  //   const paddingToBottom = 20;
  //   return (
  //     layoutMeasurement.height + contentOffset.y >=
  //     contentSize.height - paddingToBottom
  //   );
  // };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <View style={!!isMobile ? styles.rowMobile : styles.row}>
      <Layout
        style={
          isMobile
            ? [styles.listContainerMobile, { marginLeft: 40 }]
            : [styles.listContainer, { marginLeft: 40 }]
        }
      >
        <View style={styles.headerContainer}>
          <Text
            style={
              !!isMobile
                ? styles.headerTextMobile
                : [styles.headerText, { marginBottom: 0 }]
            }
          >
            Existing SWEAT {content === 'program' ? 'Programs' : 'Workouts'}
          </Text>
          <View style={isMobile ? styles.checkBoxMobile : styles.checkBox}>
            <CheckBox
              checked={showExisting}
              onChange={(status) => setShowExisting(status)}
              style={{
                marginLeft: 'auto',
                marginRight: 10,
              }}
            >
              {() => (
                <Text style={{ marginLeft: 5, fontSize: 10 }}>
                  Existing Programs
                </Text>
              )}
            </CheckBox>
            <CheckBox
              checked={showCustomized}
              onChange={(status) => setShowCustomized(status)}
              style={{
                marginRight: 'auto',
              }}
            >
              {() => (
                <Text style={{ marginLeft: 5, fontSize: 10 }}>
                  Customized Programs
                </Text>
              )}
            </CheckBox>
          </View>
        </View>
        <View style={styles.headerContainer}>
          <Input
            size="small"
            style={styles.search}
            accessoryLeft={() => (
              <Icon style={styles.icon} name="search-outline" fill="white" />
            )}
            value={contentSearch}
            placeholder="Search"
            onChangeText={(text) => setContentSearch(text)}
            onKeyPress={(key) =>
              key.nativeEvent.key === 'Enter' ? onSearchContent() : null
            }
          />
        </View>
        <ScrollView
          style={styles.library}
          // onScroll={({ nativeEvent }) => {
          //   if (isCloseToBottom(nativeEvent)) {
          //     loadMoreContent();
          //   }
          // }}
          // scrollEventThrottle={400}
        >
          {isLoading || loadingExistingContents || loadingContents ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <>
              {allContents?.map((content, index) => {
                return (
                  <Layout key={index} style={[styles.dataContainer]}>
                    <Text
                      style={isMobile ? styles.dataTextMobile : styles.dataText}
                    >
                      {`${
                        content.displayName ? content.displayName : content.name
                      }` +
                        ` ${
                          content.fteOwner
                            ? `(${
                                content.fteOwner.fullName &&
                                content.fteOwner.fullName.length > 0
                                  ? content.fteOwner.fullName
                                  : content.fteOwner.email
                              })`
                            : ''
                        }`}
                    </Text>
                    <TouchableOpacity
                      style={styles.buttonIcon}
                      onPress={() => addToLibrary(content)}
                    >
                      <Icon
                        name="plus-outline"
                        fill="white"
                        style={styles.icon}
                      />
                    </TouchableOpacity>
                  </Layout>
                );
              })}
              {!isLoading && loadingMoreContent ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                <TouchableOpacity
                  style={{
                    alignSelf: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  onPress={() => loadMoreContent()}
                >
                  <Text
                    style={[
                      styles.dataText,
                      { maxWidth: '100%', opacity: 0.5 },
                    ]}
                  >
                    Load More
                  </Text>
                </TouchableOpacity>
              )}
            </>
          )}
        </ScrollView>
      </Layout>
      <Layout
        style={
          isMobile
            ? [styles.listContainerMobile, { marginLeft: 5 }]
            : [styles.listContainer, { marginLeft: 5 }]
        }
      >
        <View style={styles.headerContainer}>
          <Text
            style={!!isMobile ? styles.headerTextMobile : styles.headerText}
          >
            OSS SWEAT {content === 'program' ? 'Program' : 'Workout'} Library
          </Text>
        </View>
        <View style={styles.headerContainer}>
          <Input
            size="small"
            style={styles.search}
            accessoryLeft={() => (
              <Icon style={styles.icon} name="search-outline" fill="white" />
            )}
            value={libraryFuzzy}
            onChangeText={(text) => setLibraryFuzzy(text)}
            placeholder="Search"
            onKeyPress={(key) =>
              key.nativeEvent.key === 'Enter' ? searchLibraryItem() : null
            }
          />
        </View>
        <ScrollView style={styles.library}>
          {isLoading || loadingContents ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            myLibrary?.map((program, index) => {
              return (
                <Layout key={index} style={styles.dataContainer}>
                  <Text
                    style={isMobile ? styles.dataTextMobile : styles.dataText}
                  >
                    {`${
                      program.displayName ? program.displayName : program.name
                    }` +
                      ` ${
                        program.fteOwner && !!program.fteOwner.id
                          ? `(${
                              program.fteOwner.fullName
                                ? program.fteOwner.fullName
                                : program.fteOwner.email
                            })`
                          : ''
                      }`}
                  </Text>
                  {program.fteOwner &&
                    program.fteOwner.id === store.auth.user?.id && (
                      <TouchableOpacity
                        style={[styles.buttonIcon, { right: 40 }]}
                        onPress={() =>
                          onDelete(
                            program.id,
                            `${content === 'program' ? 'program' : 'workout'}`,
                          )
                        }
                      >
                        <Icon
                          name="trash-2-outline"
                          fill="white"
                          style={styles.icon}
                        />
                      </TouchableOpacity>
                    )}
                  <TouchableOpacity
                    style={styles.buttonIcon}
                    onPress={() => removeFromLibrary(program)}
                  >
                    <Icon
                      name="minus-outline"
                      fill="white"
                      style={styles.icon}
                    />
                  </TouchableOpacity>
                </Layout>
              );
            })
          )}
        </ScrollView>
        {!!loadingSave ? (
          <Layout style={styles.loadingSave}>
            <Spinner />
          </Layout>
        ) : (
          <TouchableOpacity
            disabled={!!loadingContents || !!loadingMoreContent}
            style={styles.saveButton}
            onPress={onSaveLibrary}
          >
            <Text style={styles.saveText}>Save</Text>
          </TouchableOpacity>
        )}
      </Layout>

      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[
            styles.popUpContainer,
            {
              opacity: opacity,
              transform: [{ translateY: translateY }, { scale: scale }],
            },
          ]}
          pointerEvents="none"
        >
          <Icon
            style={styles.savedIcon}
            name={
              isSavingSuccess
                ? 'checkmark-circle-2-outline'
                : 'close-circle-outline'
            }
            fill={isSavingSuccess ? 'olive' : 'warning'}
          />
          <Text style={styles.saveText}>
            {isSavingSuccess ? 'SAVED SUCCESSFULLY' : 'SAVING FAILED'}
          </Text>
        </Animated.View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  listContainer: {
    flex: 1,
    backgroundColor: '#203950',
    height: '95%',
  },
  listContainerMobile: {
    flex: 1,
    backgroundColor: '#203950',
    height: '95%',
    width: 300,
  },
  headerText: {
    color: 'white',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'center',
    fontSize: 14,
  },
  headerTextMobile: {
    color: 'white',
    marginHorizontal: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 12,
  },
  headerContainer: {
    width: '100%',
    height: '50px',
    // flexDirection: 'row',
    justifyContent: 'center',
  },
  search: {
    width: '90%', //
    margin: 'auto',
    backgroundColor: '#1A3248',
  },
  icon: {
    width: 20,
    height: 20,
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 14,
    maxWidth: '70%',
  },
  dataTextMobile: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 12,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
    top: -2,
  },
  library: {
    flexDirection: 'column',
    backgroundColor: '#051023',
    marginBottom: 45,
    width: '100%',
  },
  saveContainer: {
    width: '92%',
    marginLeft: 10,
    marginTop: 15,
    marginBottom: 45,
    backgroundColor: '#091C2D',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  saveButton: {
    position: 'absolute',
    backgroundColor: 'olive',
    width: 70,
    height: 30,
    right: 10,
    bottom: 8,
    padding: 5,
  },
  loadingSave: {
    position: 'absolute',
    right: 10,
    bottom: 8,
    padding: 5,
    backgroundColor: 'transparent',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  row: {
    flexDirection: 'row',
    width: '53%',
    padding: 0,
    justifyContent: 'space-between',
  },
  rowMobile: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
    justifyContent: 'space-between',
  },
  checkBox: {
    flexDirection: 'row',
    marginTop: 3,
    alignSelf: 'center',
  },
  checkBoxMobile: {
    flexDirection: 'row',
    marginTop: 3,
  },
});

export default observer(LibraryList);
