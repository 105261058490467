import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { MessageModel } from 'o2x-store/src/models/Message';
import { useStore } from 'o2x-store/src/stores';
import React from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';

type Props = {
  message: MessageModel;
  onSelect: () => void;
  onDelete: () => void;
  selected: boolean;
  authUserId: number | undefined;
};

const UserMessage: React.FC<Props> = (props) => {
  const { message, onSelect, onDelete, selected, authUserId } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const groupMessage = !!message.group;
  const noReply = store.auth.user?.id === message.sender;

  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.selected]}
      onPress={onSelect}
    >
      {message.isUnread && message.sender != authUserId && (
        <View style={styles.notification} />
      )}
      <Image
        style={styles.profile as StyleProp<ImageStyle>}
        source={
          noReply
            ? message.receiverDetails?.profileImage
              ? { uri: message.receiverDetails?.profileImage }
              : require('../../../assets/images/user_placeholder.png')
            : message.senderDetails?.profileImage
            ? { uri: message.senderDetails?.profileImage }
            : require('../../../assets/images/user_placeholder.png')
        }
      />
      <Layout style={styles.detail}>
        <Text numberOfLines={1} style={styles.email}>
          {groupMessage
            ? message.groupDetails
            : noReply
            ? message.receiverDetails.fullName
              ? message.receiverDetails.fullName
              : message.receiverDetails.email
            : message.senderDetails.fullName
            ? message.senderDetails.fullName
            : message.senderDetails.email}
        </Text>
        <Text style={styles.date}>
          {format(parseISO(message.created), 'yyyy-MM-dd H:mma')}
        </Text>
      </Layout>
      <TouchableOpacity
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          marginLeft: 'auto',
          marginRight: 20,
        }}
        onPress={onDelete}
      >
        <Icon
          name="trash-2-outline"
          fill="white"
          style={{ width: 20, height: 20 }}
        />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#203950',
    borderBottom: '1px solid black',
    height: 70,
  },
  selected: {
    backgroundColor: '#091C2D',
  },
  profile: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: 15,
    marginLeft: 20,
  },
  detail: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 10,
    flexDirection: 'column',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  email: {
    lineHeight: 17,
    letterSpacing: 0.05,
    color: 'white',
    fontSize: 16,
    maxWidth: 200,
  },
  date: {
    lineHeight: 11,
    letterSpacing: 0.05,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#C4C4C4',
    fontSize: 10,
  },
  notification: {
    position: 'absolute',
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'olive',
    marginTop: 15,
    marginLeft: 12,
  },
});

export default observer(UserMessage);
