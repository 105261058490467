import { computed, observable } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';
import { InteractionManager } from 'react-native';

export enum TIMER_TYPE {
  TIMER = 'timer', // N to 0
  STOPWATCH = 'stopwatch', // 0 to N
}

export const DEFAULT_TIME = 60;

@model('o2x-native/TimerStart')
export default class TimerStart extends Model({
  // Time values are in seconds.
  totalTime: prop<number>(0),
}) {
  timer: NodeJS.Timeout | null = null;
  timerUpdated: number = 0;

  @observable
  type: TIMER_TYPE = TIMER_TYPE.TIMER;

  @observable
  countDown: number = DEFAULT_TIME;

  @observable
  defaultTime: number = DEFAULT_TIME;

  @observable
  loading: boolean = false;

  @observable
  playing: boolean = false;

  @observable
  paused: boolean = false;

  @modelAction
  stop = () => {
    this.stopTimer();
    this.playing = false;
    this.paused = false;
  };

  @modelAction
  start = () => {
    this.startTimer();
    this.playing = true;
    this.paused = false;
  };

  @modelAction
  pause = () => {
    this.stopTimer();
    this.playing = false;
    this.paused = true;
  };

  @modelAction
  reset = () => {
    this.stopTimer();
    this.totalTime = 0;
    this.countDown = this.defaultTime;
    this.playing = false;
    this.paused = false;
  };

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = null;
  };

  startTimer = () => {
    if (this.timer) {
      return;
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timerUpdated = Date.now();
    this.timer = setInterval(() => {
      while (Date.now() - this.timerUpdated > 1000) {
        this.timerUpdated += 1000;
        InteractionManager.runAfterInteractions(() => {
          this.updateTime();
        });
      }
    }, 100);
  };

  @modelAction
  updateTime() {
    if (this.type === TIMER_TYPE.TIMER) {
      this.countDown -= 1;
      if (this.countDown <= 0) {
        this.stop();
      }
    } else if (this.type === TIMER_TYPE.STOPWATCH) {
      this.totalTime += 1;
    }
  }

  @modelAction
  setDefaultTime = (seconds: number) => {
    this.defaultTime = seconds;
    this.countDown = seconds;
  };

  @modelAction
  setType = (type: TIMER_TYPE) => {
    this.type = type;
  };

  @computed
  get timeDisplay(): number {
    if (this.type === TIMER_TYPE.TIMER) {
      return this.countDown;
    } else if (this.type === TIMER_TYPE.STOPWATCH) {
      return this.totalTime;
    }
    return 0;
  }
}
