import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { View } from 'react-native';

interface Props {
  numBars: number;
  currentBar: number;
}

const ProgressBar: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { numBars, currentBar } = props;
  return (
    <Layout style={styles.progressBar}>
      {Array.from(Array(numBars).keys()).map((num) => (
        <View
          key={num}
          style={[
            styles.bar,
            { width: `${(100 - numBars) / numBars}%` },
            num <= currentBar && styles.barActive,
          ]}
        ></View>
      ))}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  progressBar: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 3,
    backgroundColor: 'transparent',
  },
  bar: {
    marginVertical: 1,
    height: 4,
    backgroundColor: 'gray',
  },
  barActive: {
    backgroundColor: 'white',
  },
});

export default ProgressBar;
