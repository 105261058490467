import * as FileSystem from 'expo-file-system';
import * as VideoThumbnail from 'expo-video-thumbnails';
import { computed, observable } from 'mobx';
import { model, Model, modelFlow, prop, _async, _await } from 'mobx-keystone';
import { Platform } from 'react-native';

@model('o2x-native/DownloadedMedia')
export default class DownloadedMedia extends Model({
  key: prop<string>(''),
  uri: prop<string>(''),
  downloaded: prop<boolean>(false),
  isSecured: prop<boolean>(false),
}) {
  @observable
  loading = false;

  @observable
  thumbnail = '';

  @computed
  get relativeFileUri(): string {
    if (Platform.OS === 'web') return '';

    const extension = this.uri.split('.').pop();
    const filename = `${this.key.replace(/[/\\?%*:|"<>]/g, '-')}.${extension}`;
    return filename;
  }

  @computed
  get localUri(): string {
    if (Platform.OS === 'web') return '';

    return `${FileSystem.documentDirectory}${this.relativeFileUri}`;
  }

  @modelFlow
  downloadMedia = _async(function* (this: DownloadedMedia) {
    if (Platform.OS === 'web' || this.loading) return;

    const localUri = this.localUri;
    try {
      const info = yield* _await(FileSystem.getInfoAsync(localUri));
      if (info.exists) {
        this.downloaded = true;
        return localUri;
      }
      this.downloaded = false;
    } catch (e) {
      console.warn('[DEBUG] failed to get media info', e);
      this.downloaded = false;
      return;
    }

    this.loading = true;
    console.log('[DEBUG] downloading media (downloaded)', this.key);

    const dir = localUri.substring(0, localUri.lastIndexOf('/') + 1);
    if (dir) {
      try {
        const dirInfo = yield* _await(FileSystem.getInfoAsync(dir));
        if (dirInfo.exists) {
          if (!dirInfo.isDirectory) {
            console.warn(
              '[DEBUG] file with same name as directory exists. deleting',
              dir,
            );
            yield* _await(FileSystem.deleteAsync(dir, { idempotent: true }));
            yield* _await(
              FileSystem.makeDirectoryAsync(dir, { intermediates: true }),
            );
          }
        } else {
          yield* _await(
            FileSystem.makeDirectoryAsync(dir, { intermediates: true }),
          );
        }
      } catch (e) {
        console.warn('[DEBUG] error creating directory:', e);
        this.downloaded = false;
        this.loading = false;
        return;
      }
    }

    try {
      const result = yield* _await(
        FileSystem.downloadAsync(this.uri, localUri),
      );
      if (result.status !== 200) {
        console.warn('[DEBUG] failed to download media', result);
        this.downloaded = false;
        this.loading = false;
        return;
      }
    } catch (e) {
      console.warn('[DEBUG] error downloading media:', e);
      this.downloaded = false;
      this.loading = false;
      return;
    }

    this.downloaded = true;
    this.loading = false;
    return localUri;
  });

  @modelFlow
  getThumbnail = _async(function* (this: DownloadedMedia) {
    if (Platform.OS === 'web') return '';

    if (!this.thumbnail) {
      try {
        const result = yield* _await(
          VideoThumbnail.getThumbnailAsync(this.localUri, { time: 0 }),
        );
        if (result) {
          this.thumbnail = result.uri;
        }
      } catch (e) {
        console.log('[DEBUG] Failed to get video thumbnail', e);
      }
    }
    return this.thumbnail;
  });

  @modelFlow
  deleteMedia = _async(function* (this: DownloadedMedia) {
    if (Platform.OS === 'web') return;

    console.log('[DEBUG] deleting media (downloaded)', this.key);
    yield* _await(FileSystem.deleteAsync(this.localUri, { idempotent: true }));
    this.downloaded = false;
  });
}
