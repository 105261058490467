import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { useNativeStore } from '../../stores';
import moment from 'moment';
import { useAppStateChange } from '../../hooks/useAppStateChange';

type Props = {};

const ThriveBreatheIndicator: React.FC<Props> = (props) => {
  const { thriveStart } = useNativeStore();
  const styles = useStyleSheet(themedStyles);
  const [inhale, setInhale] = useState(true);
  const [startTime, setStartTime] = useState<Date>();
  const scale = useRef(new Animated.Value(1)).current;
  const appState = useAppStateChange();

  useEffect(() => {
    if (thriveStart.playing) {
      animate();
      setStartTime(moment().toDate());
      thriveStart.playDingAndDong();
    } else {
      scale.setValue(1);
      setStartTime(undefined);
      setInhale(true);
    }
  }, [thriveStart.playing]);

  useEffect(() => {
    if (startTime) {
      const timerId = setInterval(() => {
        const seconds = moment().diff(startTime, 'seconds');
        if (seconds % 12 <= 5) {
          setInhale(true);
        } else {
          setInhale(false);
        }
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }
  }, [startTime, appState]);

  const animate = useCallback(() => {
    if (!thriveStart.playing) {
      return;
    }
    const seconds = moment().diff(startTime, 'seconds');
    if (inhale) {
      const remainingSecondsToSwitch = 5 - (seconds % 12);
      Animated.timing(scale, {
        toValue: 2,
        duration: remainingSecondsToSwitch * 1000,
        useNativeDriver: true,
      }).start();
    } else {
      const remainingSecondsToSwitch = 7 - ((5 - seconds) % 12);
      Animated.timing(scale, {
        toValue: 1,
        duration: remainingSecondsToSwitch * 1000,
        useNativeDriver: true,
      }).start();
    }
  }, [thriveStart, inhale, appState]);

  useEffect(() => {
    animate();
  }, [inhale]);

  return (
    <Layout style={styles.outer}>
      <Animated.View style={[styles.middle, { transform: [{ scale }] }]} />
      <Layout style={[styles.inner, !inhale && styles.exhale]}>
        <Text style={styles.label} category="h2">
          {inhale ? 'Inhale' : 'Exhale'}
        </Text>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  exhale: {
    backgroundColor: 'blue-secondary',
  },
  inner: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 62.5,
    left: 62.5,
    width: 125,
    height: 125,
    borderRadius: 62.5,
    backgroundColor: 'blue',
  },
  label: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  middle: {
    width: 125,
    height: 125,
    borderRadius: 100,
    backgroundColor: 'dark-blue',
  },
  outer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 250,
    height: 250,
    borderRadius: 125,
    backgroundColor: 'darker-blue',
  },
});

export default observer(ThriveBreatheIndicator);
