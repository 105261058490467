import { useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import Task from 'o2x-store/src/models/Task';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useNativeStore } from '../../stores';
export type Props = {
  task: Task;
  key: string;
};

const WorkoutItem: React.FC<Props> = (props) => {
  const { task } = props;
  const styles = useStyleSheet(themedStyles);
  const { navigateWithPayWall } = useIsSubscribed();
  const { mediaStorage } = useNativeStore();
  const { sweat } = useStore();
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        try {
          await sweat.fetchSweatWorkout(task.id);
        } catch {
          await sweat.searchSweatPrograms(task.id);
        }
        setLoading(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      if (image) {
        return;
      }

      const sweatWorkout = sweat.sweatWorkouts.get(`${task.id}`);
      if (sweatWorkout?.userProgress?.programProgress) {
        const program = sweat.sweatPrograms.get(`${sweatWorkout.program}`);
        if (program?.coverImage) {
          setImage(program.coverImage);
        }
      }
    }, [loading]),
  );

  const onPress = useCallback(() => {
    const sweatWorkout = sweat.sweatWorkouts.get(`${task.id}`);
    if (sweatWorkout?.userProgress?.programProgress) {
      navigateWithPayWall('SweatStart', { id: sweatWorkout.program });
    } else {
      navigateWithPayWall('SweatContinue', { workoutId: task.id });
    }
  }, [navigateWithPayWall]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
        level="2"
      >
        <Layout style={styles.contentContainer} level="2">
          {!loading && (
            <Layout style={styles.imageWrapper}>
              <Image
                style={styles.image as StyleProp<ImageStyle>}
                source={
                  image
                    ? { uri: image }
                    : require('../../assets/images/workout-generator-thumbnail.png')
                }
              />
            </Layout>
          )}
          <Layout
            style={
              Platform.OS !== 'web'
                ? styles.textContainer
                : styles.textContainerWeb
            }
          >
            <Text
              style={Platform.OS !== 'web' ? styles.title : styles.titleWeb}
            >
              {task.title}
            </Text>
            {!!task.subtitle && (
              <Text
                style={[
                  Platform.OS !== 'web' ? styles.subtitle : styles.subtitleWeb,
                  { color: task.color },
                ]}
              >
                {task.subtitle}
              </Text>
            )}
          </Layout>
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 12,
    marginRight: 16,
    marginVertical: 8,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  title: {
    fontSize: 18,
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 17,
    marginTop: 7,
    textTransform: 'uppercase',
  },
  titleWeb: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  subtitleWeb: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginTop: 3,
    textTransform: 'uppercase',
  },
  imageWrapper: {
    height: 90,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    width: '100%',
  },
  textContainer: {
    marginVertical: 5,
    marginHorizontal: 10,
    backgroundColor: 'tranparent',
  },
  textContainerWeb: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'center',
    backgroundColor: '#1A3248',
    height: 55,
  },
  contentContainer: {
    flex: 1,
  },
});

export default observer(WorkoutItem);
