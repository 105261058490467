import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserDailyEatHabit } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from '../../components/Question/BottomNav';
import DailyLogInput from '../../components/Question/DailyLogInput';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route?: any | null;
};

const EatHabitQuestion: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();

  const [index, setIndex] = useState(0);
  const [eatHabit, setEatHabit] = useState<UserDailyEatHabit | null>(
    new UserDailyEatHabit({}),
  );

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const question = store.question.dailyEatHabitQuestions.has(`${index}`)
    ? store.question.dailyEatHabitQuestions.get(`${index}`)
    : null;
  const numBars = store.question.dailyEatHabitQuestions.size;

  const validateAnswer = useCallback(
    (question) => {
      // Check if has answer because field is required by default
      const isRequired =
        question && question.required !== undefined ? question.required : true;
      return (
        (isRequired &&
          question &&
          eatHabit &&
          get(eatHabit, question.key) !== null &&
          get(eatHabit, question.key) !== '' &&
          get(eatHabit, question.key) !== -1) ||
        !isRequired
      );
    },
    [eatHabit],
  );

  useFocusEffect(
    useCallback(() => {
      store.user.fetchDailyEatHabit(); // Always refresh on this page
      store.question.fetchDailyEatHabitQuestions();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      const eatHabit = store.user.dailyEatHabit;
      if (eatHabit) {
        setEatHabit(eatHabit);
      } else {
        setEatHabit(new UserDailyEatHabit({}));
      }
    }, [store.user.dailyEatHabit]),
  );

  const onBack = useCallback(() => {
    const newIndex = index - 1;
    const id = String(newIndex);
    if (store.question.dailyEatHabitQuestions.has(id)) {
      setIndex(newIndex);
    } else {
      navigation.goBack();
    }
  }, [index, setIndex, question, store]);

  const onNext = useCallback(async () => {
    const newIndex = index + 1;
    const id = String(newIndex);
    if (store.question.dailyEatHabitQuestions.has(id)) {
      if (validateAnswer(question)) {
        setIndex(newIndex);
      }
    } else {
      //handle final question
      const lastQuestion = store.question.dailyEatHabitQuestions.get(
        `${index}`,
      );
      if (!validateAnswer(lastQuestion)) {
        return;
      }

      if (eatHabit) {
        await store.user.updateDailyEatHabit(getSnapshot(eatHabit));
      }
      navigation.navigate('EatHabitSummary');
    }
  }, [index, setIndex, question, store]);

  const onChange = useCallback(
    (key: string, value: any) => {
      eatHabit?.set(key, value);
    },
    [setEatHabit, eatHabit],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose={true} onClose={goBack} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <Text style={isMobile ? styles.titleMobile : styles.title}>
                Record your EAT habits
              </Text>
              <Text style={styles.description}>{question?.question}</Text>
              {question && (
                <View style={styles.questionContainer}>
                  <DailyLogInput
                    key={question.key}
                    question={question}
                    onChange={onChange}
                    initial={
                      question && eatHabit ? get(eatHabit, question.key) : ''
                    }
                    status="basic-eat"
                    fill={theme['green']}
                  />
                </View>
              )}
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={index} />
              <BottomNav
                onBack={onBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '15%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '10%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 24,
  },
  titleMobile: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 15,
  },
  description: {
    fontSize: 16,
    color: 'white',
    lineHeight: 22,
    marginBottom: 12,
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  questionContainer: {
    flex: 1,
  },
});

export default observer(EatHabitQuestion);
