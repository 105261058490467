import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';
import { FTESweatWorkout } from '../../../../o2x-store/src/models/FTELibrary';

type Props = {
  week: FTESweatWorkout[];
  onPressDay: (day: FTESweatWorkout) => void;
};

const ProgramWeek: React.FC<Props> = ({ week, onPressDay }) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <FlatList
      style={styles.container}
      data={week}
      renderItem={({ item: day, index }) => (
        <Layout style={styles.day} key={index}>
          <Text style={styles.dayText}> Day {day.day} </Text>
          {/* <TouchableOpacity style={styles.dayButton} onPress={() => {}}>
            <Text style={styles.buttonText}>View Daily Debrief</Text>
          </TouchableOpacity> */}
          <TouchableOpacity
            style={styles.dayButton}
            onPress={() => {
              onPressDay(day);
            }}
          >
            <Text style={styles.buttonText}>View Edits/Comments</Text>
          </TouchableOpacity>
        </Layout>
      )}
    />
  );
};
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  day: {
    width: '135px',
    height: 75,
    margin: '10px',
    border: '4px solid #1A3248',
  },
  dayText: {
    color: 'white',
    fontSize: 18,
    margin: '5px',
    textAlign: 'center',
    // marginBottom: '20px',
  },
  dayButton: {
    height: 'auto',
    backgroundColor: 'olive',
    justifyContent: 'center',
    width: '95%',
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 5,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  buttonText: {
    color: 'white',
    margin: 'auto',
    fontSize: 12,
    textAlign: 'center',
  },
});

export default observer(ProgramWeek);
