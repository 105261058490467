import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';

export enum POPUP_CONTAINER_CHOICES {
  EXPLORE_EAT = 'explore_eat',
  EXPLORE_SWEAT = 'explore_sweat',
  EXPLORE_THRIVE = 'explore_thrive',
  TODAY = 'today',
  EAT_TODAY = 'eat_today',
  SWEAT_TODAY = 'sweat_today',
  THRIVE_TODAY = 'thrive_today',
  ASSESSMENT = 'assessment',
  ASSESSMENT_SECTION_MENU = 'assessment_section_menu',
  ASSESSMENT_RESULT = 'assessment_result',

  DAILY_DEBRIEF_EAT = 'daily_debrief_eat',
  DAILY_DEBRIEF_SWEAT = 'daily_debrief_sweat',
  DAILY_DEBRIEF_THRIVE = 'daily_debrief_thrive',

  DAILY_DEBRIEF_ADD_EAT = 'daily_debrief_add_eat',
  DAILY_DEBRIEF_ADD_SWEAT = 'daily_debrief_add_sweat',
  DAILY_DEBRIEF_ADD_THRIVE = 'daily_debrief_add_thrive',

  USER_SUMMARY = 'user_summary',
  WORKOUT_SUMMARY = 'workout_summary',
  SETTINGS = 'settings',
  TEAMS = 'teams',
  TEAM_DETAIL = 'team_detail',
  WORKOUT_GENERATOR_FILTER = 'workout_generator_filter',
  WORKOUT_GENERATOR_RESULT = 'workout_generator_result',
  WORKOUT_GENERATOR_PREVIEW = 'workout_generator_preview',
  WORKOUT_PREPARE = 'workout_prepare',
  WORKOUT_SWEAT_PREVIEW = 'workout_sweat_preview',
  WORKOUT_SWEAT = 'workout_sweat',
  WORKOUT_RECOVER = 'workout_recover',
  WORKOUT_COMPLETE = 'workout_complete',
  SWEAT_PROGRAM = 'sweat_program',
}

export type Popup = {
  id: number;
  image: string;
  label: string;
  order: number;
};

@model('o2x-store/PopupContainer')
export default class PopupContainer extends Model({
  id: prop<number>(),
  component: prop<string>(''),
  popups: prop<Array<Popup>>(() => new Array<Popup>()),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(data: ModelInstanceCreationData<PopupContainer>) {
    Object.assign(this, data);
  }

  @modelFlow
  fetchPopups = _async(function* (this: PopupContainer) {
    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelInstanceCreationData<PopupContainer>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPopups(rootStore.auth.token, this.id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching popups', error);
      return getError(error);
    }

    results.forEach((page) => {
      //sweat.createOrUpdateSweatWorkout(workout);
      console.log(page);
    });

    this.loading = false;
    return getSuccess();
  });
}
