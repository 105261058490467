import '@expo/match-media';
import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import config from '../../../o2x-store/src/config';
import LabeledInput from '../../components/LabeledInput';
import { getErrors } from '../../utils/errors';
import Account from '../Account';
import { AuthStackParamList } from '../AppContainer/stacks/AuthStack';

type Props = {
  route: RouteProp<AuthStackParamList, 'CreateProfile'>;
};

const CreateProfile: React.FC<Props> = (props) => {
  const {
    route: {
      params: { registrationCode },
    },
  } = props;
  const { auth } = useStore();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { register, setValue, handleSubmit, errors } = useForm();
  const passwordRef = useRef<TextInput>(null);
  const firstNameRef = useRef<TextInput>(null);
  const lastNameRef = useRef<TextInput>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitEditingEmail = useCallback(() => {
    passwordRef.current?.focus();
  }, []);

  const onSubmitEditingPassword = useCallback(() => {
    firstNameRef.current?.focus();
  }, []);

  const onSubmitEditingFirstName = useCallback(() => {
    lastNameRef.current?.focus();
  }, []);

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    const result = await auth.signUp({ ...data, registrationCode });
    setLoading(false);
    if (!result.ok) {
      setErrorMessage(getErrors(result.errors));
    }
  }, []);

  const onSubmitWrapped = handleSubmit(onSubmit);

  useEffect(() => {
    register({ name: 'email' }, { required: true });
    register({ name: 'password' }, { required: true });
    register({ name: 'firstName' }, { required: true });
    register({ name: 'lastName' }, { required: true });
  }, [register]);

  const onSignIn = useCallback(() => {
    navigation.navigate('SignIn');
  }, []);

  const onTerms = useCallback(() => {
    window.open(config.urls.terms, '_blank');
  }, []);

  const onPrivacyAndPolicy = useCallback(() => {
    window.open(config.urls.privacy, '_blank');
  }, []);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Account>
      <Text
        style={
          isTabletOrMobile ? styles.portalTextTabletOrMobile : styles.portalText
        }
        category={isTabletOrMobile ? 'label' : 'h1'}
      >
        Create a Profile
      </Text>
      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      <Layout
        style={isMobile ? styles.signUpContainerMobile : styles.signUpContainer}
      >
        <LabeledInput
          style={styles.inputContainer}
          autoCapitalize="none"
          keyboardType="email-address"
          returnKeyType="next"
          autoFocus
          onChangeText={(text) => setValue('email', text)}
          onSubmitEditing={onSubmitEditingEmail}
          label="Email"
          divider
        />
        <LabeledInput
          style={styles.inputContainer}
          autoCapitalize="none"
          returnKeyType="next"
          secureTextEntry
          onChangeText={(text) => setValue('password', text)}
          onSubmitEditing={onSubmitEditingPassword}
          label="Password"
          ref={passwordRef}
          divider
        />
        <LabeledInput
          style={styles.inputContainer}
          label="First Name"
          returnKeyType="go"
          onChangeText={(text) => setValue('firstName', text)}
          onSubmitEditing={onSubmitEditingFirstName}
          ref={firstNameRef}
          divider
        />
        <LabeledInput
          style={styles.inputContainer}
          label="Last Name"
          returnKeyType="go"
          onChangeText={(text) => setValue('lastName', text)}
          onSubmitEditing={onSubmitWrapped}
          ref={lastNameRef}
          divider
        />
        <Layout style={styles.termsAndConditionContainer}>
          <Text style={styles.termsAndCondition}>
            {`By continuing, you agree to our `}
          </Text>
          <TouchableOpacity onPress={onTerms}>
            <Text style={[styles.termsAndCondition, styles.link]}>Terms</Text>
          </TouchableOpacity>
          <Text style={styles.termsAndCondition}>{` and `}</Text>
          <TouchableOpacity onPress={onPrivacyAndPolicy}>
            <Text style={[styles.termsAndCondition, styles.link]}>
              Privacy Policy
            </Text>
          </TouchableOpacity>
          <Text style={styles.termsAndCondition}>.</Text>
        </Layout>
        <TouchableOpacity
          style={styles.button}
          disabled={loading}
          onPress={onSubmitWrapped}
        >
          <Text style={styles.buttonLabel} category="s2">
            Continue
          </Text>
        </TouchableOpacity>
        <Layout style={[styles.row, { marginTop: 30 }]}>
          <Text category="label" style={styles.normal}>
            Already have an Account?{' '}
          </Text>
          <TouchableOpacity onPress={onSignIn}>
            <Text category="label" style={[styles.normal, styles.link]}>
              Sign In
            </Text>
          </TouchableOpacity>
        </Layout>
      </Layout>
    </Account>
  );
};

const themedStyles = StyleService.create({
  signUpContainer: {
    backgroundColor: 'trasparent',
    width: '50%',
  },
  signUpContainerMobile: {
    backgroundColor: 'trasparent',
    width: '70%',
  },
  button: {
    justifyContent: 'center',
    height: 40,
    width: '100%',
    backgroundColor: 'olive',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    textAlign: 'center',
    color: 'danger',
    marginBottom: 15,
  },
  inputContainer: {
    width: '100%',
  },
  label: {
    textTransform: 'uppercase',
  },
  portalText: {
    textAlign: 'center',
    letterSpacing: 1,
    marginBottom: '10%',
  },
  portalTextTabletOrMobile: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  row: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  normal: {
    fontWeight: 'normal',
  },
  link: {
    textDecorationLine: 'underline',
  },
  termsAndConditionContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 10,
  },
  termsAndCondition: {
    fontSize: 12,
    color: 'white',
  },
});

export default CreateProfile;
