import { Base64 } from 'js-base64';
import {
  default as decamelizeKeys,
  default as snakeCaseKeys,
} from 'snakecase-keys';
import utf8 from 'utf8';
import config from '../../config';
import { callApi, callApiWithToken } from './base';

export const signUp = async (data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  registrationCode: string;
}) =>
  callApi(
    config.urls.signUp,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        email: data.email,
        password1: data.password,
        password2: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        registrationCode: data.registrationCode,
      }),
    ),
  );

export const signIn = async (data: { email: string; password: string }) =>
  callApi(config.urls.signIn, 'POST', {
    'Content-Type': 'application/json',
    Authorization: `Basic ${Base64.encode(
      utf8.encode(`${data.email}:${data.password}`),
    )}`,
  });

export const signInWithFacebook = async (accessToken: string) =>
  callApi(
    config.urls.signInWithFacebook,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ accessToken })),
  );

export const signInWithGoogle = async (accessToken: string) =>
  callApi(
    config.urls.signInWithGoogle,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ accessToken })),
  );

export const logOut = async (token: string) =>
  callApiWithToken(config.urls.logOut, token, 'POST');

export const passwordReset = async (token: string, data: { email: string }) =>
  callApiWithToken(
    config.urls.passwordReset,
    token,
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(
      snakeCaseKeys({
        email: data.email,
      }),
    ),
  );
