import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import RightArrow from '../../assets/images/assessment-arrow.svg';
import Close from '../../assets/images/close-modal.svg';
import Eat from '../../assets/images/eat-logo.svg';
import Info from '../../assets/images/info.svg';
import Sweat from '../../assets/images/sweat-logo.svg';
import Thrive from '../../assets/images/thrive-logo.svg';
import BarGraph from '../../components/BarGraph';
import HPAGraph from '../../components/BarGraph/HPAGraph';
import OverallGraph from '../../components/BarGraph/OverallGraph';
import CircularProgressBar from '../../components/CircularProgressBar';
import Overall from '../../components/CircularProgressBar/Overall';
import HpaChartInfo from './HpaChartInfo';

type Props = {
  assessment: FormAssessment;
};

const HumanPerformanceAssessment: React.FC<Props> = ({ assessment }) => {
  const styles = useStyleSheet(themedStyles);
  const [isOverall, setIsOverall] = useState(false);
  const [isEat, setIsEat] = useState(false);
  const [isSweat, setIsSweat] = useState(false);
  const [isThrive, setIsThrive] = useState(false);
  const navigation = useNavigation();
  const [isModalVisible, setModalVisible] = useState(false);

  const { assessment: assessmentStore } = useStore();

  const {
    previousScore,
    maxScore,
    eatMaxScore,
    sweatMaxScore,
    thriveMaxScore,
    averageScore,
    eatAverageScore,
    sweatAverageScore,
    thriveAverageScore,
    previousSubmission,
  } = assessment;

  if (!assessment || !assessment.allUserSummary || !previousSubmission) {
    return null;
  }

  const isDeviceMaxWidth1024 = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const setOverall = () => {
    setIsEat(false);
    setIsSweat(false);
    setIsThrive(false);
    setIsOverall((prevState) => !prevState);
  };

  const setEat = () => {
    setIsOverall(false);
    setIsSweat(false);
    setIsThrive(false);
    setIsEat((prevState) => !prevState);
  };

  const setSweat = () => {
    setIsOverall(false);
    setIsEat(false);
    setIsThrive(false);
    setIsSweat((prevState) => !prevState);
  };

  const setThrive = () => {
    setIsOverall(false);
    setIsEat(false);
    setIsSweat(false);
    setIsThrive((prevState) => !prevState);
  };

  let submission: FormAssessmentSubmission;

  if (previousSubmission) {
    submission = assessmentStore.formAssessmentSubmissions.get(
      `${previousSubmission}`,
    )!;
  }

  const { eatScore, sweatScore, thriveScore } = submission!;

  const summary = submission!.result || {};

  const getBadge = (score: number): string => {
    if (score <= 49) return 'Yikes (0-49)';
    if (score <= 74) return 'Standard (50-74)';
    if (score <= 89) return 'Outstanding (75-89)';
    return 'Elite (90-100)';
  };

  const getBadgeColor = (score: number): string => {
    if (score <= 49) return '#ff3333';
    if (score <= 74) return '#95b8dc';
    if (score <= 89) return '#2371b8';
    return '#3d4d58';
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (previousSubmission)
          await assessmentStore.fetchFormAssessmentSubmissionResult(
            previousSubmission,
          );
      })();
    }, [previousSubmission]),
  );

  return (
    <>
      <TouchableOpacity
        style={
          isDeviceMaxWidth600
            ? styles.hpaInfoContainerMaxWidth600
            : styles.hpaInfoContainer
        }
        onPress={() => setModalVisible(true)}
      >
        <Layout style={styles.hpaInfoContent}>
          <Layout style={styles.hpaInfoHeaderContainer}>
            <Info />
            <Text
              style={
                isDeviceMaxWidth600
                  ? styles.hpaInfoHeaderMaxWidth600
                  : styles.hpaInfoHeader
              }
            >
              Welcome to the O2X® Human Performance Program!
            </Text>
          </Layout>
          <Text style={styles.hpaInfo}>
            Asking targeted EAT - SWEAT - THRIVE questions is the first step
            toward real and meaningful change. The following science-based,
            expertly formulated O2X® HP Assessment should take approximately 5-7
            minutes to complete. The goal is to help you make incremental
            changes that will, over time, build into meaningful improvements in
            satisfaction, happiness, health and longevity. Your answers and
            Custom O2X® HP Profile Report are completely confidential. O2X® is
            backed by the research, training and scientific data of our
            world-class team of HP Experts.
          </Text>
        </Layout>
        <RightArrow />
      </TouchableOpacity>
      <Layout
        style={
          isDeviceMaxWidth768
            ? styles.hpaResultContainerMaxWidth768
            : styles.hpaResultContainer
        }
      >
        <Layout
          style={
            isDeviceMaxWidth768
              ? styles.hpaScoresContainerMaxWidth768
              : styles.hpaScoresContainer
          }
        >
          <TouchableOpacity
            style={[
              isDeviceMaxWidth768
                ? styles.overallScoreContainerMaxWidth768
                : styles.overallScoreContainer,
              isOverall && styles.selected,
            ]}
            onPress={setOverall}
          >
            <Text
              style={
                isDeviceMaxWidth768
                  ? styles.hpaScoreTitleMaxWidth768
                  : styles.hpaScoreTitle
              }
            >
              Overall
            </Text>
            <Layout style={styles.circularProgressContainer}>
              <Overall
                score={averageScore}
                previousScore={previousScore}
                maxScore={maxScore}
              />

              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.overallScoreMaxWidth768
                    : styles.overallScore
                }
              >
                {averageScore === null ? 'N/A' : averageScore.toFixed(1)}
              </Text>
            </Layout>
            {previousScore && (
              <Layout
                style={[
                  styles.overallPreviousScoreContainer,
                  isDeviceMaxWidth768 && { right: 15, bottom: 15 },
                ]}
              >
                <Layout style={styles.marker}></Layout>
                <Text
                  style={
                    isDeviceMaxWidth768
                      ? styles.overallPreviousScoreMaxWidth768
                      : styles.overallPreviousScore
                  }
                >
                  {'Previous: '}
                  {previousScore.toFixed(1)}
                </Text>
              </Layout>
            )}
          </TouchableOpacity>
          <Layout style={styles.eatSweatThriveContainer}>
            <TouchableOpacity
              style={[
                isDeviceMaxWidth768
                  ? styles.scoreContainerMaxWidth768
                  : styles.scoreContainer,
                isEat && styles.selected,
              ]}
              onPress={setEat}
            >
              <Layout style={styles.scoreHeader}>
                <Eat width={16} height={16} viewBox="0 0 107 134" />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreHeaderTextMaxWidth768
                      : styles.scoreHeaderText,
                    { color: getTaskColor(TASK_TYPE.EAT) },
                  ]}
                >
                  Eat
                </Text>
              </Layout>
              <Layout style={styles.progressContainer}>
                <CircularProgressBar
                  score={eatAverageScore}
                  maxScore={eatMaxScore}
                />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreMaxWidth768
                      : styles.score,
                    { color: getTaskColor(TASK_TYPE.EAT) },
                  ]}
                >
                  {eatAverageScore === null
                    ? 'N/A'
                    : eatAverageScore.toFixed(1)}
                </Text>
              </Layout>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                isDeviceMaxWidth768
                  ? styles.scoreContainerMaxWidth768
                  : styles.scoreContainer,
                isSweat && styles.selected,
              ]}
              onPress={setSweat}
            >
              <Layout style={styles.scoreHeader}>
                <Sweat width={16} height={16} viewBox="0 0 145 89" />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreHeaderTextMaxWidth768
                      : styles.scoreHeaderText,
                    { color: getTaskColor(TASK_TYPE.SWEAT) },
                  ]}
                >
                  Sweat
                </Text>
              </Layout>
              <Layout style={styles.progressContainer}>
                <CircularProgressBar
                  score={sweatAverageScore}
                  maxScore={sweatMaxScore}
                />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreMaxWidth768
                      : styles.score,
                    { color: getTaskColor(TASK_TYPE.SWEAT) },
                  ]}
                >
                  {sweatAverageScore === null
                    ? 'N/A'
                    : sweatAverageScore.toFixed(1)}
                </Text>
              </Layout>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                isDeviceMaxWidth768
                  ? styles.scoreContainerMaxWidth768
                  : styles.scoreContainer,
                isThrive && styles.selected,
              ]}
              onPress={setThrive}
            >
              <Layout style={styles.scoreHeader}>
                <Thrive width={16} height={16} viewBox="0 0 119 134" />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreHeaderTextMaxWidth768
                      : styles.scoreHeaderText,
                    { color: getTaskColor(TASK_TYPE.THRIVE) },
                  ]}
                >
                  Thrive
                </Text>
              </Layout>
              <Layout style={styles.progressContainer}>
                <CircularProgressBar
                  score={thriveAverageScore}
                  maxScore={thriveMaxScore}
                />
                <Text
                  style={[
                    isDeviceMaxWidth768
                      ? styles.scoreMaxWidth768
                      : styles.score,
                    { color: getTaskColor(TASK_TYPE.THRIVE) },
                  ]}
                >
                  {thriveAverageScore === null
                    ? 'N/A'
                    : thriveAverageScore.toFixed(1)}
                </Text>
              </Layout>
            </TouchableOpacity>
          </Layout>
        </Layout>
        <Layout
          style={
            isDeviceMaxWidth768
              ? styles.hpaSummaryContainerMaxWidth768
              : styles.hpaSummaryContainer
          }
        >
          <Text
            style={
              isDeviceMaxWidth768
                ? styles.hpaScoreTitleMaxWidth768
                : styles.hpaScoreTitle
            }
          >
            Your Progress over time
          </Text>
          {!isEat && !isSweat && !isThrive && !isOverall ? (
            <BarGraph
              eatMaxScore={eatMaxScore}
              sweatMaxScore={sweatMaxScore}
              thriveMaxScore={thriveMaxScore}
              summary={assessment.summary}
            />
          ) : isOverall ? (
            <OverallGraph maxScore={maxScore} summary={assessment.summary} />
          ) : (
            <BarGraph
              eatMaxScore={eatMaxScore}
              sweatMaxScore={sweatMaxScore}
              thriveMaxScore={thriveMaxScore}
              summary={assessment.summary}
              isEat={isEat}
              isSweat={isSweat}
              isThrive={isThrive}
            />
          )}
        </Layout>
      </Layout>

      <Layout
        style={
          isDeviceMaxWidth768
            ? styles.hpaTextResultContainerMaxWidth768
            : styles.hpaTextResultContainer
        }
      >
        {previousScore && eatScore && sweatScore && thriveScore ? (
          <>
            <Layout
              style={
                isDeviceMaxWidth768
                  ? styles.hpaTextSummaryContainerMaxWidth768
                  : styles.hpaTextSummaryContainer
              }
            >
              <Text
                style={[
                  isDeviceMaxWidth768
                    ? styles.hpaTextSummaryHeaderMaxWidth768
                    : styles.hpaTextSummaryHeader,
                  { marginBottom: 30 },
                ]}
              >
                {`Your result: You scored ${previousScore} out of ${maxScore}`}
              </Text>
              <Text
                style={[
                  isDeviceMaxWidth768
                    ? styles.hpaTextSummaryHeaderMaxWidth768
                    : styles.hpaTextSummaryHeader,
                  { backgroundColor: getBadgeColor(eatScore) },
                ]}
              >
                Eat: {getBadge(eatScore)}
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.hpaScoreText
                    : styles.hpaScoreText
                }
              >
                {'Your eat score of '}
                {Number(eatScore).toFixed(1)}
                {get(get(summary.eat, 'recommendation'), 'description')}
              </Text>
              <HPAGraph
                backgroundColor="#91C300"
                scoreColor="#E8E8E8"
                distribution={assessment.allUserSummary.eatDistribution}
                score={eatScore}
              />
            </Layout>
            <Layout
              style={
                isDeviceMaxWidth768
                  ? styles.hpaTextSummaryContainerMaxWidth768
                  : styles.hpaTextSummaryContainer
              }
            >
              <Text
                style={[
                  isDeviceMaxWidth768
                    ? styles.hpaTextSummaryHeaderMaxWidth768
                    : styles.hpaTextSummaryHeader,
                  { backgroundColor: getBadgeColor(sweatScore) },
                ]}
              >
                Sweat: {getBadge(sweatScore)}
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.hpaScoreText
                    : styles.hpaScoreText
                }
              >
                {'Your sweat score of '}
                {Number(sweatScore).toFixed(1)}
                {get(get(summary.sweat, 'recommendation'), 'description')}
              </Text>
              <HPAGraph
                backgroundColor="#04B7D6"
                scoreColor="#E8E8E8"
                distribution={assessment.allUserSummary.sweatDistribution}
                score={sweatScore}
              />
            </Layout>
            <Layout
              style={
                isDeviceMaxWidth768
                  ? styles.hpaTextSummaryContainerMaxWidth768
                  : styles.hpaTextSummaryContainer
              }
            >
              <Text
                style={[
                  isDeviceMaxWidth768
                    ? styles.hpaTextSummaryHeaderMaxWidth768
                    : styles.hpaTextSummaryHeader,
                  { backgroundColor: getBadgeColor(thriveScore) },
                ]}
              >
                Thrive: {getBadge(thriveScore)}
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.hpaScoreText
                    : styles.hpaScoreText
                }
              >
                {'Your thrive score of '}
                {Number(thriveScore).toFixed(1)}
                {get(get(summary.thrive, 'recommendation'), 'description')}
              </Text>
              <HPAGraph
                backgroundColor="#FF7A00"
                scoreColor="#E8E8E8"
                distribution={assessment.allUserSummary.thriveDistribution}
                score={thriveScore}
              />
            </Layout>
          </>
        ) : (
          <Text
            style={
              isDeviceMaxWidth768
                ? styles.hpaScoreTitleMaxWidth768
                : styles.hpaScoreTitle
            }
          >
            You did not complete your previous submission
          </Text>
        )}
      </Layout>
      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        style={styles.modal}
        onBackdropPress={() => setModalVisible(false)}
      >
        <Layout
          style={
            isMobile
              ? styles.cardMobile
              : isDeviceMaxWidth600
              ? styles.cardMaxWidth600
              : isDeviceMaxWidth768
              ? styles.cardMaxWidth768
              : isDeviceMaxWidth1024
              ? styles.cardMaxWidth1024
              : styles.card
          }
        >
          <TouchableOpacity
            style={[styles.closeModal, isDeviceMaxWidth768 && { padding: 5 }]}
            onPress={() => setModalVisible(false)}
          >
            <Close />
          </TouchableOpacity>
          <HpaChartInfo />
        </Layout>
      </Modal>
    </>
  );
};

const themedStyles = StyleService.create({
  overallScore: {
    position: 'absolute',
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#767F6A',
  },
  overallScoreMaxWidth768: {
    position: 'absolute',
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#767F6A',
  },
  overallPreviousScoreContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: 22,
    bottom: 22,
  },
  marker: {
    backgroundColor: 'white',
    width: 8,
    height: 8,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: 'olive',
  },
  overallPreviousScore: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    paddingLeft: 8,
  },
  overallPreviousScoreMaxWidth768: {
    fontSize: 10,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    paddingLeft: 6,
  },
  hpaInfoContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderLeftWidth: 4,
    borderColor: 'olive',
    marginTop: 20,
  },
  hpaInfoContainerMaxWidth600: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderLeftWidth: 4,
    borderColor: 'olive',
    marginTop: 20,
  },
  hpaInfoContent: {
    backgroundColor: 'transparent',
    width: '92%',
  },
  hpaInfoHeaderContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
  },
  hpaInfoHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'olive',
    marginLeft: 20,
  },
  hpaInfoHeaderMaxWidth600: {
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'olive',
    marginLeft: 10,
    width: '85%',
  },
  hpaInfo: {
    width: '100%',
    fontSize: 12,
    letterSpacing: 1,
  },
  hpaTextResultContainer: {
    width: '100%',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  hpaTextResultContainerMaxWidth768: {
    width: '100%',
    marginTop: 20,
  },
  hpaResultContainer: {
    width: '100%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hpaResultContainerMaxWidth768: {
    width: '100%',
    marginTop: 20,
  },
  hpaScoresContainer: {
    flexGrow: 0,
    height: 374,
    width: 400,
  },
  hpaScoresContainerMaxWidth768: {
    flexGrow: 0,
    height: 374,
    width: '100%',
  },
  overallScoreContainer: {
    width: '100%',
    height: 236,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    padding: 15,
    backgroundColor: '#203950',
  },
  overallScoreContainerMaxWidth768: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    padding: 15,
    backgroundColor: '#203950',
  },
  eatSweatThriveContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scoreContainer: {
    height: 119,
    padding: 7,
    width: 120,
    backgroundColor: '#203950',
  },
  scoreContainerMaxWidth768: {
    padding: 10,
    width: '32%',
    backgroundColor: '#203950',
  },
  hpaTextSummaryContainer: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#203950',
    padding: 22,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  hpaTextSummaryContainerMaxWidth768: {
    flex: 1,
    backgroundColor: '#203950',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 22,
    marginBottom: 20,
  },
  hpaSummaryContainer: {
    height: 374,
    marginLeft: 20,
    flexGrow: 1,
    backgroundColor: '#203950',
    padding: 22,
  },
  hpaSummaryContainerMaxWidth768: {
    backgroundColor: '#203950',
    padding: 15,
    width: '100%',
    marginTop: 5,
  },
  hpaScoreText: {
    fontSize: 15,
    lineHeight: 16,
    marginBottom: 20,
  },
  hpaTextSummaryHeader: {
    fontSize: 18,
    lineHeight: 20,
    letterSpacing: 1,
    marginBottom: 15,
    paddingVertical: 15,
    paddingHorizontal: 10,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  hpaTextSummaryHeaderMaxWidth768: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 10,
    paddingVertical: 12,
    paddingHorizontal: 8,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  hpaScoreTitle: {
    fontSize: 18,
    lineHeight: 20,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    position: 'absolute',
    left: 22,
    top: 22,
  },
  hpaScoreTitleMaxWidth768: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    position: 'absolute',
    left: 15,
    top: 15,
  },
  barGraphContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scoreHeader: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  scoreHeaderText: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingLeft: 8,
  },
  scoreHeaderTextMaxWidth768: {
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingLeft: 6,
  },
  progressContainer: {
    width: '100%',
    maxWidth: 120,
    height: 82,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    marginBottom: 5,
  },
  circularProgressContainer: {
    maxWidth: 250,
    width: '70%',
    height: 190,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  score: {
    position: 'absolute',
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '700',
  },
  scoreMaxWidth768: {
    position: 'absolute',
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '700',
  },
  selected: {
    backgroundColor: '#091C2D',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    backgroundColor: '#1A3248',
    width: '70%',
    maxWidth: 900,
    padding: 25,
  },
  cardMaxWidth1024: {
    backgroundColor: '#1A3248',
    width: '85%',
    padding: 25,
  },
  cardMaxWidth768: {
    backgroundColor: '#1A3248',
    width: '85%',
    padding: 15,
  },
  cardMaxWidth600: {
    backgroundColor: '#1A3248',
    width: '67%',
    maxHeight: '90%',
    padding: 15,
  },
  cardMobile: {
    backgroundColor: '#1A3248',
    width: '90%',
    maxHeight: '90%',
    padding: 10,
  },
  closeModal: {
    marginBottom: 3,
    alignSelf: 'flex-end',
  },
});

export default observer(HumanPerformanceAssessment);
