import { useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import SavedList from 'o2x-store/src/models/SavedList';
import { useStore } from 'o2x-store/src/stores';
import { taskToListType } from 'o2x-store/src/utils/list';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect } from 'react';
import { ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native';
import ImageLoad from 'react-native-image-placeholder';
import { useSaveListMultiple } from '../../hooks/list';
import Rating from '../Rating';

export type Props = {
  program: Program;
  savedList: Map<string, SavedList> | undefined;
};

const ExploreItem: React.FC<Props> = (props) => {
  const { program, savedList } = props;
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const totalRatings = get(program.ratingsBreakdown, 'totalRatings', 0);
  const [addToList, removeFromList, errorMessage] = useSaveListMultiple(
    store,
    taskToListType(program.type),
  );

  useEffect(() => {
    (async () => {
      if (!program.ratingsBreakdown) {
        await program.fetchRatings();
      }
    })();
  }, [program]);

  const onOpen = useCallback(() => {
    navigation.navigate('ExploreStack', {
      screen: 'ProgramDetail',
      params: {
        type: program.type,
        id: program.id,
      },
    });
  }, [program]);

  const toggleBookmark = useCallback(async () => {
    if (program.saveList.length === 0) {
      // Save
      if (program.type === TASK_TYPE.EAT) {
        navigation.navigate('SaveToList', {
          listType: taskToListType(program.type),
          itemId: program.id,
        });
      } else {
        addToList(savedList, String(program.id));
        // Toast unsupported on web
        // Toast.show('Saved to List', {
        //   duration: ToastShortDuration,
        //   position: Toast.positions.BOTTOM,
        // });
      }
    } else {
      // Remove
      removeFromList(savedList, String(program.id));
      // Toast unsupported on web
      // Toast.show('Removed from List', {
      //   duration: ToastShortDuration,
      //   position: Toast.positions.BOTTOM,
      // });
    }
  }, [savedList, program]);

  return (
    <Layout style={styles.container}>
      <TouchableOpacity
        style={[styles.explore, { borderColor: program.color }]}
        onPress={onOpen}
      >
        <TouchableOpacity
          style={styles.bookmarkContainer}
          onPress={toggleBookmark}
        >
          <Icon
            style={styles.bookmark}
            fill={theme['white']}
            name={
              program.saveList.length === 0 ? 'bookmark-outline' : 'bookmark'
            }
          />
        </TouchableOpacity>
        <Layout style={styles.imageWrapper}>
          {!!program.image && (
            <ImageLoad
              style={styles.image as StyleProp<ImageStyle>}
              source={{ uri: program.image }}
              loadingStyle={{ size: 'small', color: '#091C2D' }}
              placeholderSource={require('../../assets/images/sweat-icon.svg')}
            />
          )}
        </Layout>
        <Layout style={styles.content} level="2">
          <View style={styles.titleContainer}>
            <Text numberOfLines={2} style={styles.programText}>
              {program.name}
            </Text>
          </View>

          <Layout style={styles.footerContainer}>
            {!!program.subtitle && (
              <View style={styles.subtitleContainer}>
                <Text style={[styles.subtitle, { color: program.color }]}>
                  {program.subtitle}
                </Text>
              </View>
            )}
            {program.type === TASK_TYPE.EAT && totalRatings >= 10 && (
              <Rating rating={program.rating} size={8} />
            )}
            {program.type === TASK_TYPE.THRIVE && (
              <Text style={styles.difficulty}>
                {program.difficulty || 'All Levels'}
              </Text>
            )}
          </Layout>
        </Layout>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bookmark: {
    width: 25,
    height: 25,
  },
  bookmarkContainer: {
    backgroundColor: 'transparent',
    zIndex: 1,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  bookmarkFilled: {
    background: 'white',
    color: 'white',
  },
  container: {
    backgroundColor: 'transparent',
    width: 242,
  },
  content: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
    marginRight: 4,
    marginVertical: 8,
  },
  explore: {
    width: 232,
    height: 220,
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  imageWrapper: {
    width: 228,
    height: 140,
    backgroundColor: 'transparent',
  },
  indicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  programText: {
    fontSize: 14,
    lineHeight: 17,
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  ratingBackground: {
    position: 'absolute',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    paddingVertical: 3,
  },
  titleContainer: {
    flex: 1,
  },
  footerContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    paddingRight: 5,
  },

  subtitleContainer: {
    flex: 1,
  },
  difficulty: {
    textTransform: 'uppercase',
    fontSize: 9,
    lineHeight: 11,
    color: 'white',
  },
});

export default observer(ExploreItem);
