import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserDebriefProfile } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from '../../components/Question/BottomNav';
import DailyLogInput from '../../components/Question/DailyLogInput';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

const taskQuestions = [
  'overallThrive',
  'hoursSleep',
  'qualitySleep',
  'stressLevel',
  'balanceInLife',
  'goalsAchieved',
  'skillsToManageStress',
];

type Props = {
  route: RouteProp<AppStackParamList, 'DailyDebriefQuestion'>;
};

const QuestionThrive: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();

  const { dailyDebriefQuestions } = store.question;

  const { debriefData = {}, habits = [] } = props?.route.params ?? {};

  const [index, setIndex] = useState(0);
  const [questionKeys, setQuestionKeys] = useState<string[]>([]);
  const [profile] = useState(
    new UserDebriefProfile({
      ...debriefData,
      qualitySleep: 0,
    }),
  );
  const [loading, setIsloading] = useState(true);

  const question =
    index < questionKeys.length
      ? dailyDebriefQuestions.get(questionKeys[index])
      : null;

  useEffect(() => {
    const keys: string[] = [];
    dailyDebriefQuestions.forEach((q, i) => {
      if (habits.includes(q.key) && taskQuestions.includes(q.key)) {
        keys.push(i);
      }
    });
    setQuestionKeys(keys);
    setIsloading(false);
  }, []);

  const validateAnswer = useCallback(
    (question) => {
      const isRequired = question?.required ?? true;
      return (
        !isRequired ||
        (question &&
          profile &&
          get(profile, question.key) !== '' &&
          get(profile, question.key) !== null &&
          get(profile, question.key) !== undefined)
      );
    },
    [question, profile],
  );

  const onBack = useCallback(() => {
    const newIndex = index - 1;
    if (newIndex < 0) {
      navigation.goBack();
    } else {
      setIndex(newIndex);
    }
  }, [index, setIndex]);

  const onNext = useCallback(async () => {
    const newIndex = index + 1;
    if (!validateAnswer(question)) {
      return;
    } else if (newIndex < questionKeys.length) {
      setIndex(newIndex);
    } else {
      const data = {
        ...debriefData,
        ...getSnapshot(profile),
        qualitySleep: profile.qualitySleep || 1,
      };
      if (store.user.debriefProfile) {
        const debriefProfile = {
          ...data,
          id: store.user.debriefProfile.id,
        };
        await store.user.updateDailyDebrief(data);
        await store.user.updateDebriefProfile(debriefProfile);
      } else {
        await store.user.updateDebriefProfile(data);
      }
      navigation.navigate('Today');
    }
  }, [index, question, questionKeys, profile, debriefData]);

  const onChange = useCallback(
    (key: string, value: any) => {
      profile.set(key, value);
    },
    [profile],
  );

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav
                onBack={() => navigation.goBack()}
                onClose={() => navigation.navigate('Today')}
                showBack
                showClose
              />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <Text style={styles.title}>THRIVE Baseline Survey</Text>
              {!loading && (
                <View style={styles.questionContainer}>
                  <Text style={[styles.description, { fontWeight: 'bold' }]}>
                    In the last 24 hours...
                  </Text>
                  <Text style={styles.description}>{question?.question}</Text>
                  {question && (
                    <DailyLogInput
                      key={question.key}
                      question={question}
                      onChange={onChange}
                      initial={question ? get(profile, question.key) : ''}
                      status="basic-thrive"
                      fill="#FF7A00"
                    />
                  )}
                </View>
              )}
            </View>
            <View>
              <ProgressBar numBars={questionKeys.length} currentBar={index} />
              <BottomNav
                onBack={onBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '10%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 24,
    marginHorizontal: 20,
  },
  description: {
    fontSize: 16,
    color: 'white',
    lineHeight: 22,
    marginBottom: 12,
    marginHorizontal: 20,
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  questionContainer: {
    flex: 1,
  },
});

export default observer(QuestionThrive);
