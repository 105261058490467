import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import {
  filter,
  find,
  findIndex,
  get,
  indexOf,
  isUndefined,
  keys,
  omitBy,
  parseInt,
  set,
  uniq,
} from 'lodash';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  BackHandler,
  FlatList,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import EditIcon from '../../assets/images/edit.svg';
import LabeledInput from '../../components/LabeledInput';
import ProfileHeader from '../../components/ProfileHeader';
import HeightInput from '../../components/Question/HeightInput';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

enum FIELD_TYPE {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
  HEIGHT = 'height',
}

enum SELECTION_TYPE {
  PROFILE = 'profile',
  CONTACT = 'contact',
}

const EDITABLE_FIELDS = [
  'firstName',
  'lastName',
  'birthDate',
  'gender',
  'height',
  'weight',
  'profession',
  'email',
  'phone',
  'address',
  'city',
  'stateCode',
  'zipCode',
];

type EditableField = {
  key: string;
  label: string;
  value: string | number | Date;
  type: FIELD_TYPE;
  options?: Array<FieldOption> | undefined;
};

type UserData = {
  user: User;
  values: Partial<User>;
};

type FieldOption = {
  label: string;
  value: string;
};

type Props = {
  route: any;
};

const ProfileEdit: React.FC<Props> = (props) => {
  const root = useStore();
  const { auth, user: userStore } = root;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const { dateString: newDateString } = props?.route.params ?? {};

  const insets = useSafeAreaInsets();

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dateString, setDateString] = useState('');
  const [dateItemKey, setDateItemKey] = useState('');
  const [userData, setUserData] = useState<UserData>();
  const [selected, setSelected] = useState(SELECTION_TYPE.PROFILE);
  const [editingFields, setEditingFields] = useState<Array<string>>(
    new Array<string>(),
  );
  const [datePickerData, setDatePickerData] = useState({
    show: false,
    value: format(new Date(), 'yyyy-MM-dd'),
    field: '',
  });
  const [pickerData, setPickerData] = useState({
    show: false,
    options: [],
    value: '',
    field: '',
  });
  const [pictureLoading, setPictureLoading] = useState(false);

  const profileFields: Array<EditableField> = [
    {
      key: 'firstName',
      label: 'First Name',
      value: userData?.values.firstName || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      value: userData?.values.lastName || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'birthDate',
      label: 'Date of Birth',
      value: userData?.values.birthDate || '',
      type: FIELD_TYPE.DATE,
    },
    {
      key: 'gender',
      label: 'Gender',
      value: userData?.values.gender || '',
      type: FIELD_TYPE.SELECT,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    },
    {
      key: 'height',
      label: 'Height',
      value: userData?.values.height || '',
      type: FIELD_TYPE.HEIGHT,
    },
    {
      key: 'weight',
      label: 'Weight (lbs)',
      value: userData?.values.weight || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'profession',
      label: 'Profession',
      value: userData?.values.profession || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  const contactFields: Array<EditableField> = [
    {
      key: 'email',
      label: 'Email',
      value: userData?.values.email || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'phone',
      label: 'Phone',
      value: userData?.values.phone || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'address',
      label: 'Address',
      value: userData?.values.address || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'city',
      label: 'City',
      value: userData?.values.city || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'stateCode',
      label: 'State',
      value: userData?.values.stateCode || '',
      type: FIELD_TYPE.SELECT,
      options: root.question.states,
    },
    {
      key: 'zipCode',
      label: 'Zip Code',
      value: userData?.values.zipCode || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  const isDisabled = (key: string) => {
    return indexOf(editingFields, key) === -1;
  };

  useEffect(() => {
    root.question.fetchStates();
  }, []);

  useEffect(() => {
    if (newDateString && dateItemKey) {
      setValue(dateItemKey, newDateString);
      const data = { [dateItemKey]: getValues(dateItemKey) };
      onSubmit(data);
      setUserData({
        ...userData,
        values: { ...userData?.values, [dateItemKey]: newDateString },
      } as any);
      navigation.setParams({ dateString: undefined });
      setDateItemKey('');
    }
  }, [newDateString]);

  useEffect(() => {
    const onBackPress = () => {
      if (pictureLoading) {
        Alert.alert(
          'Hold on!',
          'Your picture is uploading. Are you sure you want to go back?',
          [
            {
              text: 'Cancel',
              onPress: () => null,
              style: 'cancel',
            },
            { text: 'YES', onPress: () => navigation.goBack() },
          ],
        );
        return true;
      }
      return false;
    };

    BackHandler.addEventListener('hardwareBackPress', onBackPress);

    return () =>
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
  }, [pictureLoading]);

  useEffect(() => {
    (async () => {
      await userStore.fetchMe();
    })();
  }, [auth.user]);

  useEffect(() => {
    const storedUser = auth.user;
    if (storedUser) {
      const storedUserValues: Partial<User> = {};
      EDITABLE_FIELDS.forEach((f: string) =>
        set(storedUserValues, f, get(storedUser, f)),
      );
      setUserData({
        user: storedUser,
        values: storedUserValues,
      });
    }
  }, [auth.user, userStore.loading]);

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f) => {
      register({ name: f }, { required: false });
    });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (data) {
        const result = await userStore.updateMe(omitBy(data, isUndefined));
        setLoading(false);
        if (result.ok) {
          keys(data).forEach((k: string) => {
            setValue(k, data[k]);
            onSetEditing(k, false);
          });
          setErrorMessage('');
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [userData],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSetEditing = useCallback((key: string, isEditing: boolean) => {
    if (isEditing) {
      setEditingFields(uniq([...editingFields, key]));
    } else {
      const filteredEditingFields = filter(editingFields, (f) => f !== key);
      setEditingFields(filteredEditingFields);
    }
  }, []);

  const onSubmitEdit = useCallback((key: string) => {
    const data = { [key]: getValues(key) };
    return onSubmit(data);
  }, []);

  const getIndex = (arr: Array<FieldOption>, value: number | string) => {
    const idx = findIndex(arr, (o) => o.value === value);
    // if (idx < 0) return 0;
    return idx;
  };

  const getSelectValue = (
    options: Array<FieldOption>,
    value: string | number,
  ) => {
    const valueOption = find(options, (o) => o.value === value);
    if (valueOption) {
      return valueOption.label;
    }
    return '';
  };

  const renderSelectField = useCallback(
    (item: EditableField) => {
      return isDisabled(item.key) ? (
        <LabeledInput
          style={styles.inputContainer}
          label={item.label}
          labelStyle={styles.inputLabel}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => setValue(item.key, text)}
          defaultValue={getSelectValue(item.options, item.value)}
          underlineColorAndroid="transparent"
          disabled={isDisabled(item.key)}
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
        />
      ) : (
        <View style={styles.inputContainer}>
          <Text style={styles.inputLabel}>{item.label}</Text>
          <Select
            placeholder="Select an option"
            value={getSelectValue(
              item.options,
              get(userData?.values, item.key),
            )}
            selectedIndex={
              getIndex(item.options, get(userData?.values, item.key)) > -1
                ? new IndexPath(
                    getIndex(item.options, get(userData?.values, item.key)),
                  )
                : null
            }
            onSelect={(index) => {
              const indexStr = index.toString();
              setUserData({
                user: userData?.user,
                values: {
                  ...userData?.values,
                  [item.key]: item.options[Number(indexStr) - 1].value,
                },
              });
              setValue(item.key, item.options[Number(indexStr) - 1].value);
            }}
          >
            {item.options?.map((o) => (
              <SelectItem title={o.label} key={o.value} />
            ))}
          </Select>
        </View>
      );
    },
    [root.question.states, selected, editingFields, userData],
  );

  const renderDatePickerField = (item: EditableField) => {
    return (
      <View style={[styles.inputContainer, { flexDirection: 'row' }]}>
        <LabeledInput
          style={styles.inputContainer}
          label={item.label}
          labelStyle={styles.inputLabel}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => {
            setValue(item.key, text);
            setDateString(text);
          }}
          defaultValue={String(item.value) || ''}
          placeholder="YYYY-MM-DD"
          placeholderTextColor="rgba(196,196,196,0.5)"
          underlineColorAndroid="transparent"
          disabled={isDisabled(item.key)}
          // disabled={true} // isDisabled(item.key)
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
        />
        <TouchableOpacity
          style={{ height: 30, width: 30, top: 20 }}
          onPress={() => {
            if (item.type === FIELD_TYPE.SELECT) {
              setPickerData({
                show: true,
                options: item.options,
                value: item.value,
                field: item.key,
              });
              onSetEditing(item.key, true);
            } else if (item.type === FIELD_TYPE.DATE) {
              setDateItemKey(item.key);
              const dateValues = item.value.split('-');

              let startMonth = 1;
              let startDay = 1;
              let startYear = 2021;
              if (dateValues.length > 2) {
                startYear = parseInt(dateValues[0], 10);
                startMonth = parseInt(dateValues[1], 10);
                startDay = parseInt(dateValues[2], 10);
              }
              navigation.navigate('DatePicker', {
                screen: 'ProfileEdit',
                startMonth,
                startDay,
                startYear,
              });
              onSetEditing(item.key, true);
            } else {
              onSetEditing(item.key, true);
            }
          }}
          disabled={isDisabled(item.key)}
        >
          <Icon
            style={styles.inputActionIcon2}
            fill="white"
            name="calendar-outline"
          />
        </TouchableOpacity>

        {false && (
          <DateTimePickerModal
            date={
              get(userData?.values, item.key)
                ? parseISO(get(userData?.values, item.key))
                : new Date()
            }
            isVisible={!isDisabled(item.key) && isVisible}
            isDarkModeEnabled={false}
            mode="date"
            onConfirm={(value: Date) => {
              setErrorMessage('');
              onSetEditing(item.key, false);
              setIsVisible(false);
              const newValue = value ? format(value, 'yyyy-MM-dd') : null;
              setValue(item.key, newValue);
              const data = { [item.key]: getValues(item.key) };
              onSubmit(data);
              setUserData({
                ...userData,
                values: { ...userData?.values, [item.key]: newValue },
              });
            }}
            onCancel={() => {
              setErrorMessage('');
              onSetEditing(item.key, false);
              setIsVisible(false);
            }}
            maximumDate={new Date()}
            minimumDate={new Date(1900, 0, 1)}
          />
        )}
      </View>
    );
  };

  useFocusEffect(
    useCallback(() => {
      if (dateItemKey) {
        onSetEditing(dateItemKey, false);
      }
    }, [dateItemKey]),
  );

  const renderHeightField = useCallback(
    (item: EditableField) => {
      return (
        <View>
          <Text style={styles.inputLabel} category="c2">
            {item.label}
          </Text>
          <HeightInput
            question={{
              question: item.label,
              type: item.type,
              key: item.key,
              options: [],
            }}
            style={styles.inputContainer}
            inputStyle={styles.inputFieldHeight}
            textStyle={styles.inputFieldText}
            underlineColorAndroid="transparent"
            disabled={isDisabled(item.key)}
            keyboardType="decimal-pad"
            multiline
            onChange={(k, v) => setValue(item.key, v)}
            divider={false}
            initial={item.value}
            required={false}
          />
        </View>
      );
    },
    [root.question.states, selected, editingFields],
  );

  const renderTextField = useCallback(
    (item: EditableField) => {
      return (
        <LabeledInput
          style={styles.inputContainer}
          label={item.label}
          labelStyle={styles.inputLabel}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => setValue(item.key, text)}
          defaultValue={item.value || ''}
          underlineColorAndroid="transparent"
          disabled={isDisabled(item.key)}
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
          multiline
        />
      );
    },
    [root.question.states, selected, editingFields],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.itemContainer}>
        <View style={styles.itemEditable}>
          {item.type === FIELD_TYPE.DATE && renderDatePickerField(item)}
          {item.type === FIELD_TYPE.TEXT && renderTextField(item)}
          {item.type === FIELD_TYPE.SELECT && renderSelectField(item)}
          {item.type === FIELD_TYPE.HEIGHT && renderHeightField(item)}
          <View style={styles.divider} />
        </View>
        <View style={styles.inputAction}>
          {isDisabled(item.key) ? (
            <TouchableOpacity
              onPress={() => {
                if (item.type === FIELD_TYPE.SELECT) {
                  setPickerData({
                    show: true,
                    options: item.options,
                    value: item.value,
                    field: item.key,
                  });
                  onSetEditing(item.key, true);
                }
                // else if (item.type === FIELD_TYPE.DATE) {
                //   setDateItemKey(item.key);
                //   const dateValues = item.value.split('-');

                //   let startMonth = 1;
                //   let startDay = 1;
                //   let startYear = 2021;
                //   if (dateValues.length > 2) {
                //     startYear = parseInt(dateValues[0], 10);
                //     startMonth = parseInt(dateValues[1], 10);
                //     startDay = parseInt(dateValues[2], 10);
                //   }
                //   navigation.navigate('DatePicker', {
                //     screen: 'ProfileEdit',
                //     startMonth,
                //     startDay,
                //     startYear,
                //   });
                //   onSetEditing(item.key, true);
                // }
                else {
                  onSetEditing(item.key, true);
                }
              }}
              style={styles.editAction}
            >
              <EditIcon style={styles.inputActionIcon} />
            </TouchableOpacity>
          ) : (
            <View style={styles.multiAction}>
              <TouchableOpacity
                style={styles.editAction2}
                onPress={() => {
                  setErrorMessage('');
                  setUserData({
                    ...userData,
                    values: {
                      ...userData?.values,
                      [item.key]: get(userData?.user, item.key),
                    },
                  });
                  onSetEditing(item.key, false);
                }}
              >
                <Icon
                  style={styles.inputActionIcon2}
                  fill="white"
                  name="close-outline"
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.editAction2}
                onPress={() => {
                  onSubmitWrapped();
                  // if (item.type !== FIELD_TYPE.DATE) {
                  //   onSubmitWrapped();
                  // }
                }}
              >
                <Icon
                  style={styles.inputActionIcon2}
                  fill="white"
                  name="checkmark"
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    ),
    [
      editingFields,
      onSubmitWrapped,
      onSetEditing,
      userData,
      setDatePickerData,
      datePickerData,
      profileFields,
      userData,
    ],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onSubmitDatePicker = useCallback(
    (value: Date) => {
      const newValue = value ? format(value, 'yyyy-MM-dd') : null;
      setDatePickerData({ show: false, field: '', date: new Date() });
      setUserData({
        ...userData,
        values: { ...userData?.values, [datePickerData.field]: newValue },
      });
      setValue(datePickerData.field, newValue);
    },
    [userData, setUserData, setValue, datePickerData, setDatePickerData],
  );

  const onSubmitPicker = useCallback((itemValue: string, itemIndex: number) => {
    setPickerData({ show: false, field: '', value: '', options: [] });
    setUserData({
      ...userData,
      values: { ...userData?.values, [pickerData.field]: itemValue },
    });
    setValue(pickerData.field, itemValue);
  }, []);

  const renderSeparator = useCallback(
    () => <View style={styles.divider} />,
    [],
  );

  const onSetPictureLoading = useCallback(
    (isLoading: boolean) => setPictureLoading(isLoading),
    [setPictureLoading],
  );

  return (
    <Layout style={styles.container}>
      <View style={styles.header}>
        <TopNav onBack={goBack} />
      </View>
      <ScrollView style={styles.scroll}>
        <Layout style={styles.scrollWrapper}>
          <View style={styles.imageContainer}>
            <ProfileHeader onLoading={onSetPictureLoading} />
          </View>
          <View style={styles.subSelection}>
            <TouchableOpacity
              onPress={() => {
                setSelected(SELECTION_TYPE.PROFILE);
              }}
              style={[
                styles.subSelectionContainer,
                selected === SELECTION_TYPE.PROFILE &&
                  styles.subSelectionContainerSelected,
              ]}
            >
              <Text
                style={[
                  styles.selectionText,
                  selected === SELECTION_TYPE.PROFILE &&
                    styles.selectionTextSelected,
                ]}
              >
                Profile
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setSelected(SELECTION_TYPE.CONTACT);
              }}
              style={[
                styles.subSelectionContainer,
                selected === SELECTION_TYPE.CONTACT &&
                  styles.subSelectionContainerSelected,
              ]}
            >
              <Text
                style={[
                  styles.selectionText,
                  selected === SELECTION_TYPE.CONTACT &&
                    styles.selectionTextSelected,
                ]}
              >
                Contact Info
              </Text>
            </TouchableOpacity>
          </View>
          {!!errorMessage && (
            <Text style={styles.error} category="label">
              {`${errorMessage}`}
            </Text>
          )}
          {selected === SELECTION_TYPE.PROFILE && (
            <FlatList
              data={Array.from(profileFields.values())}
              renderItem={renderItem}
              keyExtractor={(item) => `${item.key}`}
            />
          )}
          {selected === SELECTION_TYPE.CONTACT && (
            <FlatList
              data={Array.from(contactFields.values())}
              renderItem={renderItem}
              keyExtractor={(item) => `${item.key}`}
            />
          )}
        </Layout>
      </ScrollView>
      <View style={[styles.bottomPadding, { marginBottom: insets.bottom }]} />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
    marginRight: 24,
  },
  datepicker: {
    height: 50,
    width: '60%',
  },
  header: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  imageContainer: {
    alignSelf: 'center',
    marginBottom: 41,
  },
  image: {
    width: 91,
    height: 91,
    borderRadius: 60,
  },
  imagePlaceholder: {
    width: 91,
    height: 91,
    borderRadius: 60,
    backgroundColor: 'light-gray',
  },
  inputContainer: {
    flex: 1,
  },
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    paddingVertical: 24,
    paddingLeft: 24,
  },
  scrollWrapper: {
    marginTop: -20,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemEditable: {
    flexDirection: 'column',
    flex: 1,
  },
  input: {
    paddingBottom: 9,
    width: 20,
  },
  inputLabel: {
    color: 'light-gray',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  inputField: {
    width: '100%',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldHeight: {
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 30,
    marginTop: 0,
    height: 1,
  },
  inputFieldText: {
    fontSize: 18,
    color: 'light-gray',
  },
  inputAction: {
    paddingLeft: 10,
    paddingBottom: 8,
  },
  inputActionIcon: {
    width: 20,
    height: 20,
  },
  inputActionIcon2: {
    width: 28,
    height: 28,
  },
  editAction2: {
    padding: 10,
    paddingHorizontal: 5,
  },
  editAction: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 15,
  },
  multiAction: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
  },
  subSelection: {
    flexDirection: 'row',
    paddingTop: 4,
    marginBottom: 24,
  },
  subSelectionContainer: {
    flex: 1,
  },
  subSelectionContainerSelected: {},
  selectionText: {
    color: 'white',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 3,
    alignSelf: 'center',
  },
  selectionTextSelected: {
    opacity: 1,
    borderBottomWidth: 2,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  bottomPadding: {
    paddingVertical: 16,
  },
});

export default observer(ProfileEdit);
