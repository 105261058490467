import EventModel from '../models/Event';
import { Program } from '../models/Program';
import SavedList from '../models/SavedList';
import ThriveExercise from '../models/ThriveExercise';
import { RootStore } from '../stores';
import SavedListStore from '../stores/SavedListStore';
import { TASK_TYPE } from './tasks';

export enum LIST_TYPE {
  RECIPE = 'recipe_list',
  SWEAT_PROGRAM = 'sweat_program_list',
  SWEAT_WORKOUT = 'sweat_workout_list',
  THRIVE_PROGRAM = 'thrive_program_list',
  THRIVE_EXERCISE = 'thrive_exercise_list',
  EVENT = 'event_list',
}

export const getListColor = (type: LIST_TYPE): string => {
  switch (type) {
    case LIST_TYPE.RECIPE:
      return '#91C300';
    case LIST_TYPE.SWEAT_PROGRAM:
      return '#04B7D6';
    case LIST_TYPE.SWEAT_WORKOUT:
      return '#04B7D6';
    case LIST_TYPE.THRIVE_PROGRAM:
      return '#FF7A00';
    case LIST_TYPE.THRIVE_EXERCISE:
      return '#FF7A00';
    default:
      return '#FFFFFF';
  }
};

export const listToTaskType = (listType: LIST_TYPE): TASK_TYPE | 'all' => {
  switch (listType) {
    case LIST_TYPE.RECIPE:
      return TASK_TYPE.EAT;
    case LIST_TYPE.SWEAT_PROGRAM:
      return TASK_TYPE.SWEAT;
    case LIST_TYPE.SWEAT_WORKOUT:
      return TASK_TYPE.SWEAT;
    case LIST_TYPE.THRIVE_PROGRAM:
      return TASK_TYPE.THRIVE;
    case LIST_TYPE.THRIVE_EXERCISE:
      return TASK_TYPE.THRIVE;
    default:
      return 'all';
  }
};

export const listToTypeWeb = (listType: LIST_TYPE): TASK_TYPE | 'Event' => {
  switch (listType) {
    case LIST_TYPE.RECIPE:
      return TASK_TYPE.EAT;
    case LIST_TYPE.SWEAT_PROGRAM:
      return TASK_TYPE.SWEAT;
    case LIST_TYPE.SWEAT_WORKOUT:
      return TASK_TYPE.SWEAT;
    case LIST_TYPE.THRIVE_PROGRAM:
      return TASK_TYPE.THRIVE;
    case LIST_TYPE.THRIVE_EXERCISE:
      return TASK_TYPE.THRIVE;
    default:
      return 'Event';
  }
};

export const taskToListType = (
  taskType: TASK_TYPE | 'all',
  taskSubType?: string,
): LIST_TYPE => {
  switch (taskType) {
    case TASK_TYPE.EAT:
      return LIST_TYPE.RECIPE;
    case TASK_TYPE.SWEAT:
      return taskSubType === 'workout'
        ? LIST_TYPE.SWEAT_WORKOUT
        : LIST_TYPE.SWEAT_PROGRAM;
    case TASK_TYPE.THRIVE:
      return taskSubType === 'exercise'
        ? LIST_TYPE.THRIVE_EXERCISE
        : LIST_TYPE.THRIVE_PROGRAM;
    default:
      return LIST_TYPE.RECIPE;
  }
};

export const taskToType = (
  taskType: TASK_TYPE | 'Event',
  taskSubType?: 'workout',
): LIST_TYPE => {
  switch (taskType) {
    case TASK_TYPE.EAT:
      return LIST_TYPE.RECIPE;
    case TASK_TYPE.SWEAT:
      return taskSubType === 'workout'
        ? LIST_TYPE.SWEAT_WORKOUT
        : LIST_TYPE.SWEAT_PROGRAM;
    case TASK_TYPE.THRIVE:
      return LIST_TYPE.THRIVE_PROGRAM;
    case 'Event':
      return LIST_TYPE.EVENT;
    default:
      return LIST_TYPE.RECIPE;
  }
};

export const getListMapByType = (
  savedList: SavedListStore,
  listType: LIST_TYPE,
): Map<string, SavedList> | undefined => {
  if (listType === LIST_TYPE.RECIPE) {
    return savedList.recipeList;
  } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
    return savedList.thriveProgramList;
  } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
    return savedList.thriveExerciseList;
  } else if (listType === LIST_TYPE.SWEAT_PROGRAM) {
    return savedList.sweatProgramList;
  } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
    return savedList.sweatWorkoutList;
  } else if (listType === LIST_TYPE.EVENT) {
    return savedList.eventList;
  }
};

export const getListByType = (
  savedList: SavedListStore,
  listType: LIST_TYPE,
  id: string,
): SavedList | undefined => {
  const strId = String(id);
  if (listType === LIST_TYPE.RECIPE) {
    return savedList.recipeList.get(strId);
  } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
    return savedList.thriveProgramList.get(strId);
  } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
    return savedList.thriveExerciseList.get(strId);
  } else if (listType === LIST_TYPE.SWEAT_PROGRAM) {
    return savedList.sweatProgramList.get(strId);
  } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
    return savedList.sweatWorkoutList.get(strId);
  } else if (listType === LIST_TYPE.EVENT) {
    return savedList.eventList.get(strId);
  }
};

export const getSource = (root: RootStore, listType: LIST_TYPE) => {
  let source: Map<string, Program | EventModel | ThriveExercise> = new Map<
    string,
    Program | EventModel
  >();
  if (listType === LIST_TYPE.RECIPE) {
    source = root.eat.recipes;
  } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
    source = root.thrive.thrivePrograms;
  } else if (listType === LIST_TYPE.SWEAT_PROGRAM) {
    source = root.sweat.sweatPrograms;
  } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
    source = root.sweat.sweatWorkouts;
  } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
    source = root.thrive.thriveExercises;
  } else if (listType === LIST_TYPE.EVENT) {
    source = root.event.events;
  }

  return source;
};
