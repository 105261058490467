import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import EventModel, {
  EventCheckin,
  EventsByRange,
  EventSurvey,
} from '../models/Event';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/EventStore')
export default class EventStore extends Model({
  events: prop_mapObject(() => new Map<string, EventModel>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchEvents = _async(function* (this: EventStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchEvents(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching events', error);
      return getError(error);
    }

    this.events.clear();

    (entities.results || []).forEach(
      (eventData: ModelInstanceCreationData<EventModel>) => {
        const id = `${eventData.id}`;
        if (this.events.has(id)) {
          this.events.get(id)!.update({
            ...eventData,
            checkin: eventData.checkin
              ? new EventCheckin(eventData.checkin)
              : null,
          });
        } else {
          const event = new EventModel({
            ...eventData,
            checkin: eventData.checkin
              ? new EventCheckin(eventData.checkin)
              : null,
          });
          this.events.set(id, event);
        }
      },
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchEventsByRange = _async(function* (
    this: EventStore,
    dateStart?: Date,
    dateEnd?: Date,
    q?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchEventsByRange(rootStore.auth.token, dateStart, dateEnd, q),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching events by range', error);
      return getError(error);
    }

    this.events.clear();

    const results = (entities.results || []).map(
      (eventsByDate: EventsByRange) => ({
        ...eventsByDate,
        data: eventsByDate.data.map(
          (eventData: ModelInstanceCreationData<EventModel>) => {
            const id = `${eventData.id}`;
            if (this.events.has(id)) {
              this.events.get(id)!.update({
                ...eventData,
                checkin: eventData.checkin
                  ? new EventCheckin(eventData.checkin)
                  : null,
              });
            } else {
              const event = new EventModel({
                ...eventData,
                checkin: eventData.checkin
                  ? new EventCheckin(eventData.checkin)
                  : null,
              });
              this.events.set(id, event);
            }
            return this.events.get(id);
          },
        ),
      }),
    );

    this.loading = false;
    return results;
  });

  @modelFlow
  fetchEventsByIds = _async(function* (
    this: EventStore,
    ids: Array<string>,
    stopClear?: boolean,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchEventsByIds(rootStore.auth.token, ids.toString()),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching events', error);
      return getError(error);
    }

    if (!stopClear) {
      this.events.clear();
    }

    (entities.results || []).forEach(
      (eventData: ModelInstanceCreationData<EventModel>) => {
        const id = `${eventData.id}`;
        if (this.events.has(id)) {
          this.events.get(id)!.update({
            ...eventData,
            checkin: eventData.checkin
              ? new EventCheckin(eventData.checkin)
              : null,
          });
        } else {
          const event = new EventModel({
            ...eventData,
            checkin: eventData.checkin
              ? new EventCheckin(eventData.checkin)
              : null,
          });
          this.events.set(id, event);
        }
      },
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  joinEvent = _async(function* (this: EventStore, code: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.joinEvent(rootStore.auth.token, { inviteCode: code }),
      ));
    } catch (error) {
      console.warn('[DEBUG] error joining event', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateCheckin = _async(function* (
    this: EventStore,
    eventId: string,
    data: Partial<EventCheckin>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<EventCheckin>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(
          api.updateCheckin(rootStore.auth.token, eventId, data),
        ));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createCheckin(rootStore.auth.token, eventId, data),
        ));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating event waiver', error);
      return getError(error);
    }

    if (entities) {
      const checkinInstance = new EventCheckin(entities);
      const event = this.events.get(`${eventId}`);
      if (event) {
        event.checkin = checkinInstance;
        this.events.set(`${eventId}`, event);
      }
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createSurvey = _async(function* (
    this: EventStore,
    eventId: string,
    data: Partial<EventSurvey>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<EventSurvey>;
    let response;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.createEventSurvey(rootStore.auth.token, eventId, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating event survey', error);
      return getError(error);
    }

    if (entities) {
      const event = this.events.get(`${eventId}`);
      if (event) {
        event.usersWithSurvey.push(entities.specialist);
        event.usersWithoutSurvey = event.usersWithoutSurvey.filter(
          (u) => u !== entities.specialist,
        );
        this.events.set(`${eventId}`, event);
      }
    }

    this.loading = false;
    return getSuccess();
  });
}
