import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Alert, Animated, Dimensions, ScrollView, View } from 'react-native';
import RenderHTML from 'react-native-render-html';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import WebView from 'react-native-webview';
import NavigationBar from 'src/components/NavigationBar';
import VideoPlayer from 'src/components/VideoPlayer';
import { useNativeStore } from 'src/stores';
import Url from 'url-parse';
import { AppStackParamList } from '../AppContainer';

type Props = { route: RouteProp<AppStackParamList, 'SweatWorkoutFTEComment'> };

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const SweatStartFTEVideo: React.FC<Props> = (props) => {
  const { exercise, step, stepExercise } = props.route.params;
  const { mediaStorage } = useNativeStore();
  const titleOpacity = useRef(new Animated.Value(0));
  const [width, setWidth] = useState(Dimensions.get('window').width * 0.9);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const [playing, setPlaying] = useState(false);
  const exerciseVideoFile = useMemo(() => {
    return exercise && mediaStorage.getOrCreateMediaFile(exercise, 'video');
  }, [exercise]);

  const customHTMLElementModels = {
    iframe: iframeModel,
  };

  const goBack = useCallback(() => {
    if (playing) {
      Alert.alert('Are you sure you want to go back?', undefined, [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Go Back',
          onPress: () => {
            navigation.goBack();
          },
        },
      ]);
    } else {
      navigation.goBack();
    }
  }, [playing]);

  if (!exercise) {
    return (
      <Layout style={styles.container}>
        <Layout style={{ paddingTop: insets.top }}>
          <NavigationBar goBack={goBack} />
        </Layout>
      </Layout>
    );
  }

  const videoId = (link: string) => {
    const url = new Url(link, true);
    if (!url.query.v) {
      return url.pathname.replace('/', '');
    }
    return url.query.v;
  };

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar goBack={goBack} />
      </Layout>

      <AnimatedScrollView
        style={styles.scroll}
        onScroll={Animated.event(
          [
            {
              nativeEvent: { contentOffset: { y: titleOpacity.current } },
            },
          ],
          { useNativeDriver: true },
        )}
      >
        <View style={[styles.videoContainer]}>
          {/* <Layout style={styles.stepContainer}>
            <Text style={styles.stepText}>Step {step.order}</Text>
          </Layout> */}
          <Layout style={styles.exerciseContainer}>
            <Text style={styles.exerciseText}>{exercise.name}</Text>
          </Layout>
          {!!exerciseVideoFile && (
            <VideoPlayer
              videoFile={exerciseVideoFile}
              size={width}
              muted={true}
            />
          )}
          {!exerciseVideoFile && !!videoId(exercise.videoLink) && (
            <RenderHTML
              baseStyle={{ width: width, marginBottom: 50 }}
              renderers={{ iframe: IframeRenderer }}
              WebView={WebView}
              customHTMLElementModels={customHTMLElementModels}
              source={{
                html: `<iframe src='https://www.youtube.com/embed/${videoId(
                  exercise.videoLink,
                )}?rel=0&showinfo=0&loop=1&playlist=${videoId(
                  exercise.videoLink,
                )}'  frameborder="0" allowfullscreen>`,
              }}
              contentWidth={width}
            />
          )}
        </View>
      </AnimatedScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'dark-blue',
  },
  stepContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  stepText: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: 'bold',
  },
  exerciseContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    paddingBottom: 32,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  exerciseText: {
    fontSize: 16,
    lineHeight: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  fteMessage: {
    flex: 1,
    padding: 16,
    backgroundColor: 'black',
  },
  fteMessageText: {},
  actions: {
    marginTop: 20,
    marginBottom: 30,
  },
  author: {
    marginTop: 29,
    marginBottom: 6,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  rating: {
    marginVertical: 5,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
    color: 'orange',
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    color: 'orange',
  },
  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    margiVertical: 20,
  },
  description: {
    marginTop: 10,
  },
  videoContainer: {
    alignSelf: 'center',
  },
});

export default observer(SweatStartFTEVideo);
