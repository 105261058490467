import {
  Icon,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  size?: number;
  rating: number;
};

const Rating: React.FC<Props> = (props) => {
  const { style, size = 8, rating } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const stars = useMemo(() => new Array(5).fill(0), []);
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.label}>{rating.toFixed(1)}</Text>
      {stars.map((_value, index) => (
        <Icon
          key={`${index}`}
          style={[styles.icon, { height: size, width: size }]}
          name={rating > index ? 'star' : 'star-outline'}
          fill={theme['white']}
        />
      ))}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 1,
  },
  icon: {
    marginRight: 3,
  },
  label: {
    fontSize: 9,
    fontWeight: '700',
    lineHeight: 11,
    marginRight: 5,
  },
});

export default Rating;
