import { observable } from 'mobx';
import {
  applySnapshot,
  getSnapshot,
  model,
  Model,
  modelFlow,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import { StorageService } from 'o2x-store/src/services/storage';
import WorkoutFilter from 'src/models/WorkoutFilter';
import config from '../config';
import DownloadedList from '../models/DownloadedList';
import ExploreFilter from '../models/ExploreFilter';
import MediaStorage from '../models/MediaStorage';
import Notification from '../models/Notification';
import Settings from '../models/Settings';
import SweatStart from '../models/SweatStart';
import ThriveStart from '../models/ThriveStart';
import TimerStart from '../models/TimerStart';

@model('o2x-native/NativeStore')
export default class NativeStore extends Model({
  exploreFilter: prop<ExploreFilter>(
    () => new ExploreFilter({ search: '', filters: {} }),
  ),
  workoutFilter: prop<WorkoutFilter>(() => new WorkoutFilter({ filters: {} })),
  sweatStart: prop<SweatStart>(() => new SweatStart({})),
  thriveStart: prop<ThriveStart>(() => new ThriveStart({})),
  timerStart: prop<TimerStart>(() => new TimerStart({})),
  mediaStorage: prop<MediaStorage>(() => new MediaStorage({})),
  settings: prop<Settings>(() => new Settings({})),
  notification: prop<Notification>(() => new Notification({})),
  downloads: prop<DownloadedList>(() => new DownloadedList({})),
}) {
  storageService!: StorageService;

  blacklist = ['sweatStart', 'thriveStart'];

  @observable
  loading = false;

  @modelFlow
  load = _async(function* (this: NativeStore) {
    this.loading = true;

    let data: any | string | null;
    try {
      data = yield* _await(
        this.storageService.getItemAsync(config.nativeStoreKey),
      );
    } catch (e) {
      console.warn('[DEBUG] fetching native store failed', e);
      yield* _await(this.mediaStorage.clear());
      data = null;
    }
    if (data) {
      data = JSON.parse(data);
      this.blacklist.forEach((key) => {
        delete data[key];
      });

      try {
        applySnapshot(this, {
          ...getSnapshot(this),
          ...data,
          $modelId: this.$modelId,
        });
      } catch (error) {
        console.warn('[DEBUG] error while loading from stored data', error);
      }
    }

    this.loading = false;
  });

  @modelFlow
  reset = _async(function* (this: NativeStore) {
    yield* _await(this.storageService.removeItemAsync(config.nativeStoreKey));
    yield* _await(this.mediaStorage.clear());
    yield* _await(this.notification.removeAllNotifications());
    yield* _await(this.downloads.resetMedia());

    this.exploreFilter = new ExploreFilter({});
    this.workoutFilter = new WorkoutFilter({});
    this.sweatStart = new SweatStart({});
    this.thriveStart = new ThriveStart({});
    this.mediaStorage = new MediaStorage({});
    this.settings = new Settings({});
    this.notification = new Notification({});
    this.downloads = new DownloadedList({});
  });

  save = () => {
    const data: any = { ...getSnapshot(this) };
    delete data.downloads;
    this.storageService.setItemAsync(
      config.nativeStoreKey,
      JSON.stringify(data),
    );
  };
}
