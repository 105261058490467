import { RouteProp, useTheme } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import { taskToListType } from 'o2x-store/src/utils/list';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  ScrollView,
  Share,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useSaveList } from 'src/hooks/list';
import SaveIcon from '../../assets/images/action-save.svg';
import ShareIcon from '../../assets/images/action-share.svg';
import PlanRepeat from '../../assets/images/plan-repeat.svg';
import SweatWorkoutCompleteIcon from '../../assets/images/sweat-workout-complete-icon.svg';
import UsersIcon from '../../assets/images/users.svg';
import TopNav from '../../components/Question/TopNav';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveProgramDayComplete'>;
  navigation: StackNavigationProp<
    AppStackParamList,
    'ThriveProgramDayComplete'
  >;
};

const ThriveProgramDayComplete: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {
    route: {
      params: { programId, dayId },
    },
    navigation,
  } = props;
  const store = useStore();
  const { thrive, user } = store;
  const { thriveStart } = useNativeStore();
  const program = thrive.thrivePrograms.get(`${programId}`);
  const programDay = thrive.thriveProgramDays.get(`${dayId}`)!;
  const nextProgramDay = thrive.thriveProgramDays.get(
    `${program?.userProgress?.currentDay}`,
  );
  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    taskToListType(program ? program.type : 'all'),
  );
  const styles = useStyleSheet(themedStyles);
  const [isSharing, setIsSharing] = useState<boolean>(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const goBack = useCallback(() => {
    navigation.popToTop();
  }, []);

  const startNextDay = useCallback(async () => {
    if (
      !program ||
      program.loading ||
      !nextProgramDay ||
      nextProgramDay?.loading
    ) {
      return;
    }

    await nextProgramDay.start();
    await program.fetch();
    navigation.navigate('ThriveStart', {
      id: program.id,
      jumpDayId: nextProgramDay.id,
    });
  }, [program, nextProgramDay, thriveStart, thrive]);

  const repeatProgram = useCallback(async () => {
    if (!program || program?.loading) {
      return;
    }

    await program.startProgram();
    await program.fetch();
    const firstDayId = get(program, 'userProgress.currentDay', null);

    navigation.navigate('ThriveStart', {
      id: program.id,
      jumpDayId: firstDayId,
    });
  }, [program, thriveStart, thrive]);

  const explorePrograms = useCallback(async () => {
    await user.fetchThriveProfile();
    if (user.thriveProfile) {
      navigation.navigate('FindThrivePlanSummary');
    } else {
      navigation.navigate('FindThrivePlanQuestion');
    }
  }, [user.thriveProfile]);

  const onSave = useCallback(() => {
    if (program) {
      if (program.saveList.length === 0) {
        addToList(savedList, String(program.id));
        // Toast not supported on web
        // Toast.show('Saved to List', {
        //   duration: ToastShortDuration,
        //   position: Toast.positions.BOTTOM,
        // });
      } else {
        // Toast not supported on web
        // Toast.show('Already Saved', {
        //   duration: ToastShortDuration,
        //   position: Toast.positions.BOTTOM,
        // });
      }
    }
  }, [savedList, program]);

  const onClose = useCallback(() => {
    navigation.popToTop();
  }, []);

  const onShare = useCallback(() => {
    setIsSharing(true);
  }, [setIsSharing]);

  const stopSharing = useCallback(() => {
    setIsSharing(false);
  }, [setIsSharing]);

  useEffect(
    useCallback(() => {
      if (isSharing) {
        doShare();
      }
    }, [isSharing]),
  );

  const doShare = useCallback(async () => {
    if (Platform.OS === 'web') {
      alert('Share is not supported in this browser');
      return;
    }

    if (program) {
      const shareData = {
        message: program.viewUrl,
        url: program.viewUrl,
        title: program.name,
      };
      try {
        const result = await Share.share(shareData);
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        alert(error.message);
      }
    }
    stopSharing();
  }, [program, stopSharing]);

  const onChallenge = useCallback(() => {
    navigation.navigate('TeamChallenge', {
      name: program?.name || '',
      thriveProgramId: programId,
    });
  }, [navigation, program, programId]);

  const repeatDay = useCallback(async () => {
    if (!program || program?.loading) {
      return;
    }
    await program.fetchProgramDays();
    navigation.navigate('ThriveStart', {
      id: program.id,
      jumpDayId: dayId,
    });
  }, [program, dayId, thriveStart, thrive]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Image
              style={styles.cover as StyleProp<ImageStyle>}
              source={{ uri: program?.imageBanner }}
            />
            <LinearGradient
              colors={['transparent', '#10283E']}
              style={styles.gradient}
            />
            <Layout
              style={[styles.navigationContainer, styles.transparentBackground]}
            >
              <TopNav
                showBack={false}
                showClose={true}
                onClose={onClose}
                style={styles.transparentBackground}
              />
            </Layout>

            <ScrollView
              style={styles.scroll}
              contentContainerStyle={styles.contentScroll}
            >
              <View style={styles.screenshotContainer}>
                <SweatWorkoutCompleteIcon
                  style={styles.icon}
                  width={74}
                  height={74}
                />
                <Text style={styles.label} category="h1">
                  {program?.userProgress?.isComplete
                    ? 'Program\nComplete!'
                    : 'Day\nComplete!'}
                </Text>
              </View>

              {program && (
                <View style={[styles.titleContainer]}>
                  <Text style={styles.programName} category="h2">
                    {program.name}
                  </Text>
                  {programDay && (
                    <Text style={styles.dayDisplay} category="c1">
                      {programDay.dayDisplay}
                    </Text>
                  )}
                </View>
              )}

              <View style={styles.actions}>
                <TouchableOpacity style={styles.actionItem} onPress={onSave}>
                  <SaveIcon
                    style={styles.actionIcon}
                    stroke="#C4C4C4"
                    color="#C4C4C4"
                  />
                  <Text style={styles.actionText}>Save</Text>
                </TouchableOpacity>

                {!!program && (
                  <TouchableOpacity
                    style={styles.actionItem}
                    onPress={onChallenge}
                  >
                    <UsersIcon
                      style={styles.actionIcon}
                      stroke="#C4C4C4"
                      color="#C4C4C4"
                    />
                    <Text style={styles.actionText}>Challenge</Text>
                  </TouchableOpacity>
                )}

                {!!program && (
                  <TouchableOpacity style={styles.actionItem} onPress={onShare}>
                    <ShareIcon
                      style={styles.actionIcon}
                      stroke="#C4C4C4"
                      color="#C4C4C4"
                    />
                    <Text style={styles.actionText}>Share</Text>
                  </TouchableOpacity>
                )}
              </View>

              {program && nextProgramDay && !program.userProgress?.isComplete && (
                <>
                  <TouchableOpacity
                    style={styles.repeatContainer}
                    onPress={repeatDay}
                  >
                    <PlanRepeat />
                    <Text style={styles.repeatText}> Repeat Exercise</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.startButton}
                    onPress={startNextDay}
                  >
                    {program.loading || nextProgramDay.loading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Text style={styles.startLabel}>
                          Start Next Program Day
                        </Text>
                        {nextProgramDay && (
                          <Text style={styles.startDayLabel} category="c1">
                            {nextProgramDay.dayDisplay}
                          </Text>
                        )}
                      </>
                    )}
                  </TouchableOpacity>
                </>
              )}

              {program && program.userProgress?.isComplete && (
                <>
                  <TouchableOpacity
                    style={styles.repeatContainer}
                    onPress={repeatProgram}
                  >
                    <PlanRepeat />
                    <Text style={styles.repeatText}> Repeat Program</Text>
                  </TouchableOpacity>
                  {/* <TouchableOpacity
              style={styles.challengeButton}
              onPress={onChallenge}
            >
              <Text style={styles.challengeText}>Challenge a team</Text>
            </TouchableOpacity> */}
                  <TouchableOpacity
                    style={styles.startButton}
                    onPress={explorePrograms}
                  >
                    <Text style={styles.startLabel}>Start Next Programs</Text>
                    <Text style={styles.startDayLabel} category="c1">
                      Explore Programs
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </ScrollView>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  contentScroll: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 24,
    paddingTop: 0,
  },
  cover: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  gradient: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  dayDisplay: {
    color: 'orange',
    textTransform: 'uppercase',
    marginBottom: 60,
  },
  icon: {
    marginBottom: 24,
    alignSelf: 'center',
  },
  label: {
    textAlign: 'center',
  },
  programName: {
    marginBottom: 8,
    textAlign: 'center',
  },
  scroll: {
    flex: 1,
  },
  startButton: {
    marginTop: 48,
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
    paddingHorizontal: 37,
    paddingVertical: 10,
  },
  startDayLabel: {
    color: 'orange',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  startLabel: {
    marginBottom: 6,
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  actions: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  actionIcon: {
    marginBottom: 7,
  },
  actionItem: {
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  actionText: {
    color: 'light-gray',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  titleContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  repeatText: {
    color: 'light-gray',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
  repeatContainer: {
    flexDirection: 'row',
    paddingTop: 30,
    alignItems: 'center',
  },
  challengeButton: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 20,
    alignItems: 'center',
  },
  challengeText: {
    color: 'light-gray',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 17,
    textTransform: 'uppercase',
  },
  screenshotContainer: {
    width: '100%',
    paddingBottom: 30,
    justifyContent: 'center',
  },
  logoContainer: {
    marginTop: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export default observer(ThriveProgramDayComplete);
