import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import ReactPlayer from 'react-player';
import { DEFAULT_TIME_TABATA } from 'src/models/SweatStart';
import { getTimeDisplay } from 'src/utils/timeDisplay';
import ClockIcon from '../../assets/images/clock-gray.svg';
import { useNativeStore } from '../../stores';

type Props = {
  workout?: SweatWorkout;
  step: SweatWorkoutStep;
  stepExercise: SweatWorkoutStepExercise;
  onPress: () => void;
};

const SweatStartDetailItem: React.FC<Props> = (props) => {
  const { step, stepExercise, onPress } = props;
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const { sweatStart, mediaStorage } = useNativeStore();
  const { sweat } = useStore();

  const exercise = sweat.getSweatExercise(stepExercise.exercise);
  const exerciseVideoFile = useMemo(() => {
    return exercise && mediaStorage.getOrCreateMediaFile(exercise, 'video');
  }, [exercise]);

  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [localUri, setLocalUri] = useState<string | null>(null);
  const onLayout = useCallback((event) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);
  const [timerRunning, setTimerRunning] = useState<boolean>(false);
  const [timeElasped, setTimeElasped] = useState(0);
  const [isRest, setIsRest] = useState<boolean>(
    (!stepExercise.workoutTimerWork && !!stepExercise.workoutTimerRest) ||
      false,
  );
  const [restTime, setRestTime] = useState(0);

  const setTimerDisplay = useMemo(() => {
    let timerDisplay = [];

    if (!!stepExercise.workoutTimerWork) {
      timerDisplay.push(
        getTimeDisplay(stepExercise.workoutTimerWork || undefined),
      );
    } else if (!!step.circuitTimerWork && step.circuitTimer === 'variable') {
      timerDisplay.push(getTimeDisplay(step.circuitTimerWork || undefined));
    }

    if (!!stepExercise.workoutTimerRestComputed) {
      setRestTime(stepExercise.workoutTimerRestComputed);
      timerDisplay.push(getTimeDisplay(restTime));
    } else if (!!step.circuitTimerRest && step.circuitTimer === 'variable') {
      setRestTime(step.circuitTimerRest);
      timerDisplay.push(getTimeDisplay(restTime));
    }

    return timerDisplay.join(' | ');
  }, [step, stepExercise, restTime]);

  const toggleTimer = useCallback(() => {
    const isCurrentlyRest =
      (!stepExercise.workoutTimerWork && !!stepExercise.workoutTimerRest) ||
      false;
    setIsRest(isCurrentlyRest);
    if (!timerRunning) {
      setTimeElasped(
        (isCurrentlyRest
          ? stepExercise.workoutTimerRestComputed
          : stepExercise.workoutTimerWork) || 0,
      );
    }
    setTimerRunning(!timerRunning);
  }, [step, stepExercise, timerRunning]);

  useEffect(() => {
    if (timerRunning) {
      if (!isRest) {
        if (!!stepExercise.workoutTimerWork && timeElasped > 0) {
          setTimeElasped((prev) => prev - 1);
        } else {
          setIsRest(true);
          if (!!stepExercise.workoutTimerRest) {
            setTimeElasped(stepExercise.workoutTimerRestComputed || 0);
          } else {
            setTimeElasped(0);
            setTimerRunning(false);
          }
        }
      } else {
        if (!!stepExercise.workoutTimerRest && timeElasped > 0) {
          setTimeElasped((prev) => prev - 1);
        } else {
          setTimerRunning(false);
        }
      }
    }
  }, [sweatStart.totalTime, isRest]);

  let subscribed = true;

  useEffect(() => {
    (async () => {
      if (
        exerciseVideoFile &&
        (!exerciseVideoFile.loading || Platform.OS === 'web')
      ) {
        const localUri =
          Platform.OS !== 'web'
            ? await exerciseVideoFile.downloadMedia()
            : exerciseVideoFile.uri;
        if (localUri) {
          setLocalUri(localUri);
        }
      }
    })();
    return () => {
      subscribed = false;
    };
  }, [exerciseVideoFile, exerciseVideoFile?.loading, subscribed, setLocalUri]);

  if (!exercise) {
    return null;
  }

  const shouldDisplayTimer =
    !!(stepExercise.workoutTimerRest || stepExercise.workoutTimerWork) &&
    ['emom', 'amrap'].includes(step.circuitTimer);

  return (
    <Layout style={styles.container}>
      <Layout style={[styles.contentContainer]}>
        <Layout style={styles.content}>
          <Layout style={styles.contentItem}>
            {!!exercise.video && localUri && (
              <Layout style={styles.thumbnailWrapper}>
                {/* <Video
                  style={styles.thumbnail}
                  source={{ uri: localUri }}
                  resizeMode="cover"
                /> */}
                <ReactPlayer
                  url={localUri}
                  style={{
                    width: 60,
                    height: 60,
                  }}
                  height={60}
                  width={60}
                />
              </Layout>
            )}

            <Layout
              style={{
                flexDirection: 'row',
                flex: 1,
              }}
            >
              {!!(stepExercise.workoutTimerWork || step.circuitTimerWork) && (
                <Layout
                  style={[
                    styles.background,
                    {
                      backgroundColor: theme['black'],
                      width: `${
                        isRest
                          ? (timeElasped / restTime) * 100
                          : (timeElasped /
                              (stepExercise.workoutTimerWork ||
                                step.circuitTimerWork) || 1) * 100
                      }%`,
                    },
                  ]}
                />
              )}
              <Layout
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <TouchableOpacity onPress={onPress}>
                  <Layout style={[styles.contentTextItem]}>
                    <Layout style={styles.subtitleTextContainer}>
                      {!!stepExercise.subtitle && (
                        <Text style={styles.subtitleText}>
                          {stepExercise.subtitle}
                        </Text>
                      )}
                    </Layout>
                    <Layout style={styles.subtitleTextContainer}>
                      {(!!stepExercise.workoutTimerWork ||
                        (!!step.circuitTimerWork &&
                          step.circuitTimer === 'variable')) && (
                        <Text style={styles.timerText}>{setTimerDisplay}</Text>
                      )}
                    </Layout>
                    <Text style={styles.title} numberOfLines={1}>
                      {exercise.name}
                    </Text>
                    {step?.circuitTimer === 'tabata' && (
                      <Text style={styles.timeText}>
                        {getTimeDisplay(DEFAULT_TIME_TABATA)}
                      </Text>
                    )}
                  </Layout>
                </TouchableOpacity>
              </Layout>
              {shouldDisplayTimer && (
                <Layout
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                  }}
                >
                  <View
                    style={{ paddingRight: 24, backgroundColor: 'transparent' }}
                  >
                    {timerRunning ? (
                      <TouchableOpacity
                        onPress={toggleTimer}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <Layout style={styles.clockButton}>
                          <Text
                            style={{
                              fontSize: 20,
                              lineHeight: 24,
                              backgroundColor: 'transparent',
                            }}
                          >
                            {getTimeDisplay(timeElasped)}
                          </Text>
                          {!!stepExercise.workoutTimerRest && isRest && (
                            <Text
                              style={{
                                fontSize: 16,
                                lineHeight: 19,
                                color: theme['blue-secondary'],
                              }}
                            >
                              REST
                            </Text>
                          )}
                        </Layout>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={toggleTimer}
                        style={{ backgroundColor: 'transparent' }}
                      >
                        <Layout style={styles.clockButton}>
                          <ClockIcon />
                        </Layout>
                      </TouchableOpacity>
                    )}
                  </View>
                </Layout>
              )}
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      {!!stepExercise.instructions && (
        <Layout style={{ paddingVertical: 8, paddingHorizontal: 24 }}>
          <Text style={styles.instructionText}>
            {stepExercise.instructions}
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  blurContainer: {
    opacity: 0.5,
  },
  container: {
    flex: 1,
    paddingBottom: 2,
    backgroundColor: 'darker-blue',
  },
  headerContainer: {
    backgroundColor: 'black',
    height: 40,
    justifyContent: 'center',
  },
  headerContent: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginHorizontal: 3,
  },
  cyanText: {
    color: 'cyan',
  },
  grayText: {
    color: 'light-gray',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: 'wallpaper',
    minHeight: 60,
  },
  description: {
    margin: 12,
  },
  clockButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  expandContainer: {
    backgroundColor: 'wallpaper',
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'cyan',
    width: 4,
  },
  label: {
    flex: 1,
    textAlign: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    width: 60,
    marginRight: 8,
    backgroundColor: 'black',
  },
  title: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  timeText: {
    fontSize: 12,
    lineHeight: 14,
  },
  contentItem: {
    //flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  contentTextItem: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
    width: 60,
    height: 60,
  },
  thumbnailWrapper: {
    width: 60,
    height: 60,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: 'black',
  },
  subtitleTextContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    paddingVertical: 2,
  },
  timerText: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'cyan',
  },
  subtitleText: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'light-gray',
  },
  changeToUp: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  background: {
    position: 'absolute',
    height: 60,
  },
  instructionText: {
    fontSize: 14,
    lineHeight: 17,
  },
});

export default observer(SweatStartDetailItem);
