import Slider from '@react-native-community/slider';
import { useNavigation } from '@react-navigation/native';
import {
  IndexPath,
  Layout,
  Select,
  SelectGroup,
  SelectItem,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { Audio } from 'expo-av';
import {
  INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  INTERRUPTION_MODE_IOS_DO_NOT_MIX,
} from 'expo-av/build/Audio';
import { useKeepAwake } from 'expo-keep-awake';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { THRIVE_MEDIA_FILE_TYPE } from 'o2x-store/src/models/ThriveMediaFile';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SecuredMedia } from 'src/models/DownloadedList';
import Meditate from '../../assets/images/meditate.svg';
import NavigationBar from '../../components/NavigationBar';
import ThriveMeditateVisuals from '../../components/ThriveMeditateVisuals';
import ThriveStartTimer from '../../components/ThriveStart/ThriveStartTimer';
import { useNativeStore } from '../../stores';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';

type Props = {
  route: any;
};

const { width } = Dimensions.get('window');

const DownloadedMeditate: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const theme = useTheme();
  const { downloads, thriveStart } = useNativeStore();
  useKeepAwake();

  const { initialDuration } = props.route.params;

  const [volume, setVolume] = useState(1);
  const [selectedAudio, setSelectedAudio] = useState<IndexPath>();
  const [selectedVisual, setSelectedVisual] = useState<IndexPath>();

  const audioRef = useRef<Select>(null);
  const visualRef = useRef<Select>(null);

  const files = Array.from(downloads.securedMedia.values());

  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      allowsRecordingIOS: false,
      staysActiveInBackground: true,
      interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      shouldDuckAndroid: true,
      interruptionModeAndroid: INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    });

    thriveStart.stop();
    thriveStart.setAudio(null);
    thriveStart.setVideo(null);
    thriveStart.setVolume(1);
    if (!initialDuration) {
      thriveStart.setDefaultTime(15 * 60);
    } else if (initialDuration !== 'infinity') {
      thriveStart.setDefaultTime(initialDuration);
    } else if (initialDuration === 'infinity') {
      thriveStart.setDefaultTime(Infinity);
    } else {
      thriveStart.setDefaultTime(initialDuration);
    }
  }, []);

  const audios = useMemo(() => {
    const sleepFiles = files.filter(
      (f) => f.type === THRIVE_MEDIA_FILE_TYPE.AUDIO && f.inSleep,
    );
    const guided = sortBy(
      sleepFiles.filter((f) => f.isGuided),
      (f) => f.name,
    );
    const notGuided = sortBy(
      sleepFiles.filter((f) => !f.isGuided),
      (f) => f.name,
    );
    const sections: { title: string; data: SecuredMedia[] }[] = [];
    if (guided.length > 0) {
      sections.push({ title: 'Guided Audio', data: guided });
    }
    if (notGuided.length > 0) {
      sections.push({ title: 'Audio', data: notGuided });
    }
    return sections;
  }, [files.length]);

  const visuals = useMemo(() => {
    const meditateFiles = files.filter((f) => f.inMeditate);
    const guided = sortBy(
      meditateFiles.filter(
        (f) => f.type === THRIVE_MEDIA_FILE_TYPE.VIDEO && f.isGuided,
      ),
      (f) => f.name,
    );
    const notGuided = sortBy(
      meditateFiles.filter(
        (f) => f.type === THRIVE_MEDIA_FILE_TYPE.VIDEO && !f.isGuided,
      ),
      (f) => f.name,
    );
    const images = sortBy(
      meditateFiles.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.IMAGE),
      (f) => f.name,
    );
    const sections: { title: string; data: SecuredMedia[] }[] = [];
    if (guided.length > 0) {
      sections.push({ title: 'Guided Videos', data: guided });
    }
    if (notGuided.length > 0) {
      sections.push({ title: 'Videos', data: notGuided });
    }
    if (images.length > 0) {
      sections.push({ title: 'Images', data: images });
    }
    return sections;
  }, [files.length]);

  const goBack = useCallback(() => {
    if (audioRef.current?.isFocused() || visualRef.current?.isFocused()) {
      audioRef.current?.blur();
      visualRef.current?.blur();
    } else {
      thriveStart.stop();
      thriveStart.setAudio(null);
      thriveStart.setVideo(null);
      navigation.goBack();
    }
  }, []);

  const onSelectAudio = useCallback((index) => {
    if (
      audios.length > index.section &&
      audios[index.section].data.length > index.row
    ) {
      const audio = audios[index.section].data[index.row];
      thriveStart.setAudio(audio as any, true);
    } else {
      thriveStart.setAudio(null);
    }
    setSelectedAudio(index);
  }, []);

  const onSelectVisual = useCallback((index) => {
    if (
      visuals.length > index.section &&
      visuals[index.section].data.length > index.row
    ) {
      const visual = visuals[index.section].data[index.row];
      thriveStart.setVideo(visual as any);
    } else {
      thriveStart.setVideo(null);
    }
    setSelectedVisual(index);
  }, []);

  const onSetVolume = useCallback((value) => {
    thriveStart.setVolume(value);
    setVolume(value);
  }, []);

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar goBack={goBack} />
      </Layout>
      <ScrollView style={styles.content}>
        <Layout style={styles.headerContainer}>
          <Meditate style={styles.icon} />
          <Text style={styles.header} category="h2">
            Meditate
          </Text>
        </Layout>
        {(thriveStart.playing || thriveStart.paused) && (
          <ThriveMeditateVisuals
            style={styles.videoWrapper}
            object={thriveStart.videoObject}
            size={width}
          />
        )}
        <Text style={styles.label} category="c2">
          Sound
        </Text>
        <Select
          ref={audioRef}
          placeholder="Select an audio"
          style={styles.select}
          value={thriveStart.audioObject?.name}
          selectedIndex={selectedAudio}
          onSelect={onSelectAudio}
        >
          {audios.map((s) => (
            <SelectGroup key={s.title} title={s.title}>
              {s.data.map((f) => {
                const file = downloads.getOrCreateSecuredMediaFile(f);
                file?.downloadMedia();

                return (
                  <SelectItem
                    key={`${f.id}`}
                    title={f.name}
                    disabled={!file?.downloaded}
                    accessoryLeft={() => {
                      if (file?.loading) {
                        return <Spinner status="basic" size="tiny" />;
                      }
                      return <View />;
                    }}
                  />
                );
              })}
            </SelectGroup>
          ))}
        </Select>
        <Text style={styles.label} category="c2">
          Visual
        </Text>
        <Select
          ref={visualRef}
          placeholder="Select a visual"
          style={styles.select}
          value={thriveStart.videoObject?.name}
          selectedIndex={selectedVisual}
          onSelect={onSelectVisual}
        >
          {visuals.map((s) => (
            <SelectGroup key={s.title} title={s.title}>
              {s.data.map((f) => {
                const file = downloads.getOrCreateSecuredMediaFile(f);
                file?.downloadMedia();

                return (
                  <SelectItem
                    key={`${f.id}`}
                    title={f.name}
                    disabled={!file?.downloaded}
                    accessoryLeft={() => {
                      if (file?.loading) {
                        return <Spinner status="basic" size="tiny" />;
                      }
                      return <View />;
                    }}
                  />
                );
              })}
            </SelectGroup>
          ))}
        </Select>
        <Text style={styles.label} category="c2">
          Volume
        </Text>
        <Slider
          value={volume}
          onSlidingStart={onSetVolume}
          onSlidingComplete={onSetVolume}
          style={styles.slider}
          minimumValue={0}
          maximumValue={1}
          maximumTrackTintColor={theme['gray']}
          minimumTrackTintColor={theme['orange']}
          thumbTintColor={theme['white']}
        />
      </ScrollView>
      <ThriveStartTimer
        isMeditate={!!thriveStart.video}
        activity={THRIVE_ACTIVITY.MEDITATE}
        addTime={15 * 60}
        dontShowComplete
        useDownloaded
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  icon: {
    marginRight: 16,
  },
  header: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  label: {
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  select: {
    marginBottom: 24,
  },
  slider: {},
  videoWrapper: {
    marginLeft: -24, // offset left padding.
    marginBottom: 16,
  },
});

export default observer(DownloadedMeditate);
