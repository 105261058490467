import { debounce } from 'lodash';
import { onSnapshot, registerRootStore } from 'mobx-keystone';
import React from 'react';
import { StorageService } from '../services/storage';
import AssessmentStore from './AssessmentStore';
import AuthStore from './AuthStore';
import ContactStore from './ContactStore';
import DashboardStore from './DashboardStore';
import EatStore from './EatStore';
import EventStore from './EventStore';
import FTEEventStore from './FTEEventStore';
import FTELibraryStore from './FTELibraryStore';
import OrganizationStore from './OrganizationStore';
import QuestionStore from './QuestionStore';
import ResourceStore from './ResourceStore';
import RootStore from './RootStore';
import SavedListStore from './SavedListStore';
import SweatStore from './SweatStore';
import TagStore from './TagStore';
import TaskStore from './TaskStore';
import TeamStore from './TeamStore';
import ThriveStore from './ThriveStore';
import TutorialStore from './TutorialStore';
import UserQuestionStore from './UserQuestionStore';
import UserStore from './UserStore';
import ContactPortalStore from './ContactPortalStore';
const StoreContext = React.createContext<RootStore>({} as RootStore);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

const createRootStore = (storageService: StorageService) => {
  const assessment = new AssessmentStore({});
  const auth = new AuthStore({});
  const eat = new EatStore({});
  const event = new EventStore({});
  const sweat = new SweatStore({});
  const thrive = new ThriveStore({});
  const tags = new TagStore({});
  const task = new TaskStore({});
  const users = new UserStore({});
  const question = new QuestionStore({});
  const user = new UserStore({});
  const savedList = new SavedListStore({});
  const tutorial = new TutorialStore({});
  const userQuestion = new UserQuestionStore({});
  const teams = new TeamStore({});
  const organization = new OrganizationStore({});
  const resources = new ResourceStore({});
  const dashboard = new DashboardStore({});
  const contact = new ContactStore({});
  const contactPortal = new ContactPortalStore({});
  const fteEvent = new FTEEventStore({});
  const fteLibrary = new FTELibraryStore({});
  const rootStore = new RootStore({
    assessment,
    auth,
    eat,
    event,
    sweat,
    thrive,
    tags,
    task,
    users,
    question,
    user,
    savedList,
    tutorial,
    userQuestion,
    teams,
    organization,
    resources,
    dashboard,
    contact,
    contactPortal,
    fteEvent,
    fteLibrary,
  });

  auth.storageService = storageService;
  rootStore.storageService = storageService;
  registerRootStore(rootStore);
  onSnapshot(
    rootStore,
    debounce(() => {
      rootStore.save();
    }, 3000),
  );

  return rootStore;
};

export { RootStore, StoreProvider, createRootStore, useStore };
