import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  fill: string;
  item: {
    key: string;
    label: string;
  };
  checked: boolean;
  onCheck: (filter: string) => void;
  onSelect?: (
    filter: string,
    key: string,
    value: string,
    checked: boolean,
  ) => void;
  sectionKey?: string;
};

const ExploreFilterItem: React.FC<Props> = (props) => {
  const { fill, item, checked, onCheck, onSelect, sectionKey } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  const onPress = useCallback(() => {
    if (!!onSelect && !!sectionKey) {
      onSelect(item.key, sectionKey, item.label, checked);
    } else {
      onCheck(item.key);
    }
  }, [checked, sectionKey]);

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Layout
        style={[
          styles.box,
          checked && styles.checked,
          checked && { backgroundColor: fill },
        ]}
      >
        {checked && (
          <Icon
            style={styles.checkmark}
            name="checkmark"
            fill={theme['black']}
          />
        )}
      </Layout>
      <Text style={[styles.label, checked && { color: fill }]}>
        {item.label}
      </Text>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  box: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderColor: 'white',
    borderWidth: 2,
    marginRight: 10,
  },
  checked: {
    borderWidth: 0,
  },
  checkmark: {
    height: 10,
    width: 10,
  },
  container: {
    backgroundColor: 'dark-blue',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  label: {
    flex: 1,
  },
});

export default ExploreFilterItem;
