import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { SweatEquipment, SweatProfession } from 'o2x-store/src/models/Sweat';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  SectionList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Filter from '../../assets/images/filter.svg';
import ExploreFilterItem from '../../components/ExploreFilterItem';
import ExploreFilterSectionHeader from '../../components/ExploreFilterSectionHeader';
import { Filters } from '../../models/ExploreFilter';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { ExploreFilterSections } from './constants';

type Props = {
  route: RouteProp<AppStackParamList, 'ExploreFilter'>;
};

const ExploreFilter: React.FC<Props> = (props) => {
  const {
    route: {
      params: { selected },
    },
  } = props;
  const { exploreFilter } = useNativeStore();
  const store = useStore();
  const [filters, setFilters] = useState<Filters>({ ...exploreFilter.filters });
  const [filterSections, setFilterSections] = useState(ExploreFilterSections);
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const fill = getTaskColor(selected);
  const filterCount = Object.values(filters).filter((filter) => filter).length;
  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      store.sweat.fetchSweatEquipments();
      store.sweat.fetchSweatProfessions();
    }, []),
  );

  useEffect(() => {
    const newFilterSections = {
      ...filterSections,
      [TASK_TYPE.SWEAT]: [
        ...filterSections[TASK_TYPE.SWEAT],
        {
          key: 'equipment',
          data: Array.from(store.sweat.sweatEquipments.values()).map(
            (eq: SweatEquipment) => ({
              key: `equipment-${eq.id}`,
              label: eq.name,
            }),
          ),
        },
        {
          key: 'professions',
          data: Array.from(store.sweat.sweatProfessions.values()).map(
            (eq: SweatProfession) => ({
              key: `professions-${eq.id}`,
              label: eq.name,
            }),
          ),
        },
      ],
    };
    setFilterSections(newFilterSections);
  }, [
    store.sweat.sweatEquipments,
    store.sweat.sweatProfessions,
    setFilterSections,
  ]);

  const onSelectFilter = useCallback(
    (filter) => {
      setFilters((filters) => ({
        ...filters,
        [filter]: !filters[filter],
      }));
    },
    [exploreFilter, filters, selected],
  );

  const onClearSection = useCallback(
    (sectionKey) => {
      const sectionFilters = filterSections[selected].find(
        (section) => section.key === sectionKey,
      );
      if (!sectionFilters) {
        return;
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...sectionFilters.data.reduce(
          (obj, filter) => ({ ...obj, [filter.key]: false }),
          {},
        ),
      }));
    },
    [filterSections],
  );

  const onClearAll = useCallback(() => {
    setFilters({});
  }, []);

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <ExploreFilterSectionHeader
        section={section}
        fill={fill}
        onClearSection={onClearSection}
      />
    ),
    [fill, filterSections],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <ExploreFilterItem
        fill={fill}
        item={item}
        checked={filters[item.key]}
        onCheck={onSelectFilter}
      />
    ),
    [fill, filters, selected],
  );

  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);
  const onCancel = useCallback(() => {
    navigation.goBack();
  }, []);

  const onSearch = useCallback(() => {
    exploreFilter.setFilters(filters);
    navigation.goBack();
  }, [exploreFilter, filters, selected]);

  return (
    <TouchableWithoutFeedback onPress={onCancel}>
      <Layout style={styles.container}>
        <Layout style={styles.modal}>
          <Layout style={styles.filter}>
            <Filter height={20} width={20} stroke={theme['white']} />
            <Text style={styles.filterLabel}>Filters</Text>

            <TouchableOpacity style={styles.filterText} onPress={onClearAll}>
              <Text
                style={[styles.clearAllLabel, { color: fill }]}
                category="c1"
              >
                Clear All ({filterCount})
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.filterText} onPress={onSearch}>
              <Text
                style={[styles.clearAllLabel, { color: fill }]}
                category="c1"
              >
                Apply
              </Text>
            </TouchableOpacity>
          </Layout>
          <SectionList
            style={styles.list}
            contentContainerStyle={styles.listContainer}
            sections={filterSections[selected]}
            extraData={filters}
            renderSectionHeader={renderSectionHeader}
            renderItem={renderItem}
            ItemSeparatorComponent={renderSeparator}
            stickySectionHeadersEnabled={false}
          />
          <Layout style={styles.bottomPadding} />
        </Layout>
      </Layout>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  actionButton: {
    flex: 1,
    backgroundColor: 'darker-blue',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  bottomPadding: {
    height: 48,
  },
  filterText: {
    paddingHorizontal: 10,
  },

  clearAllLabel: {
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    backgroundColor: '#00000080',
  },
  modal: {
    maxWidth: 400,
    flex: 1,
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    marginHorizontal: 24,
  },
  filterLabel: {
    flex: 1,
    marginHorizontal: 16,
  },
  filterButton: {
    paddingRight: 0,
  },
  list: {
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 20,
    flex: 1,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'white',
    borderBottomWidth: 1,
    marginHorizontal: 24,
    marginVertical: 8,
    paddingVertical: 4,
  },
  searchIcon: {
    height: 24,
    width: 24,
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
  },
  spacer: {
    height: 8,
  },
});

export default observer(ExploreFilter);
