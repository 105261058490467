import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import { GroupConversation, MessageModel } from '../models/Message';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/ContactStore')
export default class ContactStore extends Model({
  messages: prop_mapObject(() => new Map<string, MessageModel>()),
}) {
  @observable
  loading = false;

  @modelFlow
  checkUnread = _async(function* (this: ContactStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.checkUnread(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchMessages = _async(function* (this: ContactStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMyMessages(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchConversation = _async(function* (
    this: ContactStore,
    data?: MessageModel,
    url?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchConversation(rootStore.auth.token, data, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createMessage = _async(function* (this: ContactStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: MessageModel;
    data.sender = rootStore.auth.user?.id!;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createMessages(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createGroupConversation = _async(function* (
    this: ContactStore,
    data: Partial<GroupConversation>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: GroupConversation;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.createGroupConversation(rootStore.auth.token, data),
      ));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating group conversation', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  sendEmailMessage = _async(function* (this: ContactStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    data.sender = rootStore.auth.user?.id!;
    try {
      yield* _await(api.sendEmailMessage(rootStore.auth.token, data));
    } catch (error) {
      console.warn('[DEBUG] error sending email', error);
      return getError(error as Error);
    }
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markReadMessage = _async(function* (this: ContactStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: MessageModel;
    data.isUnread = false;
    try {
      ({
        response: { entities },
      } = yield* _await(api.markReadMessage(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteConversation = _async(function* (
    this: ContactStore,
    data: { user: number; sender: number; receiver: number },
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: {};
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteConversation(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });
}
