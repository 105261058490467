import { useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { AssessmentScoringDisplay as AssessmentScoringDisplayType } from 'o2x-store/src/models/Assessment';
import PhysicalAssessmentModel from 'o2x-store/src/models/PhysicalAssessment';
import PhysicalAssessmentItem from 'o2x-store/src/models/PhysicalAssessmentItem';
import { PhysicalAssessmentSubmissionResult } from 'o2x-store/src/models/PhysicalAssessmentSubmission';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { SectionList, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  assessment: PhysicalAssessmentModel;
  items: number[];
  physicalAssessmentItems: Map<string, PhysicalAssessmentItem>;
  previousScoreBadge: string | null;
  previousResult: PhysicalAssessmentSubmissionResult[] | null;
};

const PhysicalAssessment: React.FC<Props> = ({
  assessment,
  items,
  physicalAssessmentItems,
  previousScoreBadge,
  previousResult,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { assessment: assessmentStore } = useStore();

  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState<AssessmentScoringDisplayType>();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const result = await assessmentStore.fetchPhysicalAssessmentScoringDisplay(
          assessment.id,
        );
        if (result.entities) {
          setDisplay(result.entities);
        }
        setLoading(false);
      })();
    }, [setLoading, setDisplay, assessment]),
  );

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const convertResultValue = (unit: string, value: number): string => {
    switch (unit) {
      case 'lbs':
        return `${value} lbs`;
      case 'meters':
        return `${value} m`;
      case 'seconds':
        return `${new Date(value * 1000).toISOString().substr(11, 8)}`;
      case 'inches':
        return `${value} ″`;
      case 'reps':
        return `${Math.trunc(value)}`;
      case 'yards':
        return `${value} yd`;
      case 'pass_fail':
        return value >= 100 ? 'Passed' : 'Failed';
      default:
        return `${value}`;
    }
  };

  const renderScoreTable = useCallback(
    (result: PhysicalAssessmentSubmissionResult) => (
      <Layout key={`${result.id}`} style={styles.tr}>
        <Text
          style={[
            isMobile
              ? styles.tdMobile
              : isDeviceMaxWidth900
              ? styles.tdMaxWidth900
              : styles.td,
            styles.column1,
          ]}
        >
          {result.title}
        </Text>
        <Text
          style={[
            isMobile
              ? styles.tdMobile
              : isDeviceMaxWidth900
              ? styles.tdMaxWidth900
              : styles.td,
            styles.column2,
          ]}
        >
          {convertResultValue(result.unit, result.value)}
        </Text>
        <Text
          style={[
            isMobile
              ? styles.tdMobile
              : isDeviceMaxWidth900
              ? styles.tdMaxWidth900
              : styles.td,
            styles.column3,
          ]}
        >
          {Math.trunc(result.savedScore)}
        </Text>
        <Text
          style={[
            isMobile
              ? styles.tdMobile
              : isDeviceMaxWidth900
              ? styles.tdMaxWidth900
              : styles.td,
            styles.column4,
          ]}
        >
          {result.ranking}
        </Text>
      </Layout>
    ),
    [],
  );

  const renderParamHeader = useCallback(
    ({ section: { title } }) => <Text style={styles.scoreName}>{title}</Text>,
    [],
  );

  const renderParam = useCallback((data) => {
    const { item, index } = data;
    return (
      <Layout key={`data-${index}`} style={styles.paramTable}>
        <Text style={styles.exerciseName}>{item.label}</Text>
        <Layout style={styles.paramTh}>
          {(item.headers || []).map((header: string, index: number) => (
            <Text
              key={index}
              style={[
                isMobile
                  ? styles.paramThNameMobile
                  : isDeviceMaxWidth900
                  ? styles.paramThNameMaxWidth900
                  : styles.paramThName,
                styles.paramResultColumn,
              ]}
            >
              {header}
            </Text>
          ))}
        </Layout>
        {(item.values || []).map((arrValue: Array<string>, idx2: number) => (
          <Layout key={`row-${index}-${idx2}`} style={styles.paramTr}>
            {(arrValue || []).map((value: string, idx3: number) => (
              <Text
                key={`cell-${index}-${idx2}-${idx3}`}
                style={[
                  isMobile
                    ? styles.paramTdMobile
                    : isDeviceMaxWidth900
                    ? styles.paramTdMaxWidth900
                    : styles.paramTd,
                  styles.paramResultColumn,
                ]}
              >
                {value}
              </Text>
            ))}
          </Layout>
        ))}
      </Layout>
    );
  }, []);

  return (
    <>
      <Layout style={styles.physicalAssessmentContainer}>
        {!!previousScoreBadge && (
          <Layout style={styles.physicalAssessmentScoresContainer}>
            <Text
              style={
                isDeviceMaxWidth900
                  ? styles.overallTextMaxWidth900
                  : styles.overallText
              }
            >
              Overall Score
            </Text>
            <Text
              style={
                isDeviceMaxWidth900
                  ? styles.historyBadgeMaxWidth900
                  : styles.historyBadge
              }
            >
              {previousScoreBadge}
            </Text>
            <Layout style={styles.table}>
              <Layout style={styles.th}>
                <Text
                  style={[
                    isMobile
                      ? styles.thNameMobile
                      : isDeviceMaxWidth900
                      ? styles.thNameMaxWidth900
                      : styles.thName,
                    styles.column1,
                  ]}
                >
                  Exercise
                </Text>
                <Text
                  style={[
                    isMobile
                      ? styles.thNameMobile
                      : isDeviceMaxWidth900
                      ? styles.thNameMaxWidth900
                      : styles.thName,
                    styles.column2,
                  ]}
                >
                  Result
                </Text>
                <Text
                  style={[
                    isMobile
                      ? styles.thNameMobile
                      : isDeviceMaxWidth900
                      ? styles.thNameMaxWidth900
                      : styles.thName,
                    styles.column3,
                  ]}
                >
                  Point Scored
                </Text>
                <Text
                  style={[
                    isMobile
                      ? styles.thNameMobile
                      : isDeviceMaxWidth900
                      ? styles.thNameMaxWidth900
                      : styles.thName,
                    styles.column4,
                  ]}
                >
                  Ranking
                </Text>
              </Layout>
              {previousResult &&
                previousResult.map(
                  (result: PhysicalAssessmentSubmissionResult) =>
                    renderScoreTable(result),
                )}
            </Layout>
          </Layout>
        )}
        <Layout style={styles.resultParameterContainer}>
          <Text
            style={
              isDeviceMaxWidth900
                ? styles.resultParameterTextMaxWidth900
                : styles.resultParameterText
            }
          >
            {assessment?.name} Result Parameters
          </Text>
          {display?.badgeDisplay && (
            <View>
              <Text style={styles.scoreName}>BADGES</Text>
              {renderParam({ item: display?.badgeDisplay, index: 0 })}
            </View>
          )}
          {!!display?.scoringDisplay && (
            <SectionList
              sections={display?.scoringDisplay || []}
              keyExtractor={(item, index) => `data-${index}`}
              renderItem={renderParam}
              renderSectionHeader={renderParamHeader}
            />
          )}
        </Layout>
      </Layout>
    </>
  );
};

const themedStyles = StyleService.create({
  physicalAssessmentContainer: {
    width: '100%',
    marginTop: 25,
    backgroundColor: 'transparent',
  },
  physicalAssessmentScoresContainer: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  overallTextMaxWidth900: {
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  historyBadge: {
    fontSize: 32,
    lineHeight: 38,
    fontWeight: '700',
    paddingTop: 15,
    color: '#4E7B89',
  },
  historyBadgeMaxWidth900: {
    fontSize: 25,
    fontWeight: '700',
    paddingTop: 10,
    color: '#4E7B89',
  },
  table: {
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  th: {
    width: '100%',
    marginTop: 30,
    backgroundColor: '#31576D',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 5,
  },
  thName: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
  },
  thNameMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
  },
  thNameMobile: {
    fontSize: 11,
    letterSpacing: 1,
    fontWeight: '400',
  },
  tr: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    paddingHorizontal: 24,
    paddingVertical: 9,
  },
  td: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
  },
  tdMaxWidth900: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
  },
  tdMobile: {
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
  },
  column1: {
    width: '34%',
  },
  column2: {
    width: '23%',
  },
  column3: {
    width: '18%',
  },
  column4: {
    width: '25%',
  },
  resultParameterContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: 30,
  },
  resultParameterText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    marginBottom: 20,
    color: '#C4C4C4',
  },
  resultParameterTextMaxWidth900: {
    fontSize: 15,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    marginBottom: 20,
    color: '#C4C4C4',
  },
  gridView: {
    marginVertical: 10,
    flex: 1,
  },
  paramTable: {
    width: '100%',
    marginBottom: 15,
    backgroundColor: 'transparent',
  },
  exerciseName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
    color: '#C4C4C4',
    marginBottom: 10,
  },
  scoreName: {
    fontSize: 18,
    textTransform: 'uppercase',
    fontWeight: '700',
    color: '#C4C4C4',
    marginBottom: 10,
  },
  paramTh: {
    backgroundColor: '#31576D',
    paddingHorizontal: 10,
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  paramThName: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
    width: '25%',
  },
  paramThNameMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
    width: '25%',
  },
  paramThNameMobile: {
    fontSize: 11,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
    width: '25%',
  },
  paramTr: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 9,
    paddingHorizontal: 5,
  },
  paramTd: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    paddingHorizontal: 5,
    color: '#4E7B89',
    width: '25%',
  },
  paramTdMaxWidth900: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '400',
    paddingHorizontal: 5,
    color: '#4E7B89',
    width: '25%',
  },
  paramTdMobile: {
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '400',
    paddingHorizontal: 5,
    color: '#4E7B89',
    width: '25%',
  },
  paramResultColumn: {
    width: '30%',
  },
  paramScoreColumn: {
    width: '20%',
  },
});

export default observer(PhysicalAssessment);
