import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import UserSweatWorkoutProgress from 'o2x-store/src/models/UserSweatWorkoutProgress';
import React from 'react';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
} from '../../../../o2x-store/src/models/FTELibrary';
import WorkoutStepExercise from './WorkoutStepExercise';

type Props = {
  day: FTESweatWorkout;
  step: FTESweatWorkoutStep;
  index: number;
  onPressExercise: (exercise: FTESweatWorkoutStepExercise) => void;
  exercisesUserNotes: Map<number, string> | undefined;
  workoutMap: Map<number, UserSweatWorkoutProgress> | undefined;
  exercisesWeightInputs: Map<number, string[]> | undefined;
};

const WorkoutStep: React.FC<Props> = ({
  day,
  step,
  index,
  onPressExercise,
  exercisesUserNotes,
  workoutMap,
  exercisesWeightInputs,
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <>
      <Layout style={styles.stepHeader}>
        <Text style={[styles.textStyle, { fontSize: 16 }]}>
          Step {index + 1}
          {step.circuitTimer ? '  - ' + step.circuitTimer.toUpperCase() : ''}
          {step.circuitTimerWork ? ` ( ${step.circuitTimerWork} min)` : ' '}
        </Text>
      </Layout>
      {step.exercises &&
        step.exercises.map((exercise, index) => (
          <WorkoutStepExercise
            exercise={exercise}
            userNotes={
              exercisesUserNotes && exercise.id
                ? exercisesUserNotes.get(exercise.id)
                : ''
            }
            key={`${exercise.id} - ${index}`}
            onPressExercise={onPressExercise}
            day={day}
            workoutMap={workoutMap}
            exercisesWeightInputs={exercisesWeightInputs}
          />
        ))}
    </>
  );
};
const themedStyles = StyleService.create({
  stepHeader: {
    width: '100%',
    backgroundColor: '#203950',
    alignSelf: 'center',
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 5,
  },
  textStyle: {
    color: '#ffffff',
    lineHeight: 22,
    letterSpacing: 0.05,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

export default observer(WorkoutStep);
