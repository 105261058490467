import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import {
  find,
  findIndex,
  get,
  indexOf,
  isUndefined,
  keys,
  omitBy,
  set,
} from 'lodash';
import { observer } from 'mobx-react-lite';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Animated, FlatList, ScrollView, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LabeledInput from 'src/components/LabeledInput';
import NavigationBar from 'src/components/NavigationBar';
import BottomNav from 'src/components/Question/BottomNav';
import HeightInput from 'src/components/Question/HeightInput';
import config from 'src/config';
import { useIsSubscribed } from 'src/hooks/subscription';
import { getErrors } from '../../utils/errors';

enum FIELD_TYPE {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
  HEIGHT = 'height',
}

type EditableField = {
  key: string;
  label: string;
  value: string | number | Date;
  type: FIELD_TYPE;
  options?: Array<FieldOption> | undefined;
};

type UserData = {
  user: User;
  values: Partial<User>;
};

type FieldOption = {
  label: string;
  value: string;
};

const EDITABLE_FIELDS = ['birthDate', 'gender', 'height', 'weight'];

type Props = {
  route: any;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const PreassessmentForm: React.FC<Props> = (props) => {
  const { type, assessmentId, dateString: newDateString } =
    props?.route.params ?? {};
  const {
    auth,
    assessment: assessmentStore,
    sweat,
    user: userStore,
  } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const { navigateWithPayWall, isSubscribed } = useIsSubscribed();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const currentUser = auth.user;
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const titleOpacity = useRef(new Animated.Value(0));

  const [dateItemKey, setDateItemKey] = useState('');
  const [dateString, setDateString] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateEdit, setDateEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState<UserData>();
  const [datePickerData, setDatePickerData] = useState({
    show: false,
    value: format(new Date(), 'yyyy-MM-dd'),
    field: '',
  });
  const [pickerData, setPickerData] = useState({
    show: false,
    options: [],
    value: '',
    field: '',
  });

  const profileFields: Array<EditableField> = [
    {
      key: 'birthDate',
      label: 'Date of Birth',
      value: userData?.values.birthDate || '',
      type: FIELD_TYPE.DATE,
    },
    {
      key: 'gender',
      label: 'Gender',
      value: userData?.values.gender || '',
      type: FIELD_TYPE.SELECT,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    },
    {
      key: 'height',
      label: 'Height',
      value: userData?.values.height || '',
      type: FIELD_TYPE.HEIGHT,
    },
    {
      key: 'weight',
      label: 'Weight (lbs)',
      value: userData?.values.weight || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  useEffect(() => {
    if (newDateString && dateItemKey) {
      setValue(dateItemKey, newDateString);
      setUserData({
        ...userData,
        values: { ...userData?.values, [dateItemKey]: newDateString },
      } as any);
      navigation.setParams({ dateString: undefined });
      setDateItemKey('');
    }
  }, [newDateString]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!isSubscribed) {
          navigateWithPayWall('PayWall');
          return <></>;
        }
      })();
    }, [auth.user, isSubscribed, userStore]),
  );

  useEffect(() => {
    userStore.fetchMe();
  }, []);

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f) => {
      register({ name: f }, { required: true });
    });
  }, [EDITABLE_FIELDS, register]);

  useEffect(() => {
    const storedUser = auth.user;
    if (storedUser) {
      const storedUserValues: Partial<User> = {};
      EDITABLE_FIELDS.forEach((f: string) =>
        set(storedUserValues, f, get(storedUser, f)),
      );
      setUserData({
        user: storedUser,
        values: storedUserValues,
      });
    }
  }, [auth.user, userStore.loading, EDITABLE_FIELDS]);

  const onNext = useCallback(() => {
    if (type === ASSESSMENT_TYPE.FORM) {
      if (
        assessment instanceof FormAssessment &&
        assessment?.displayPerSection
      ) {
        navigation.navigate('AssessmentSectionMenu', { type, assessmentId });
      } else {
        navigation.navigate('FormAssessmentDetail', { assessmentId });
      }
    } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
      navigation.navigate('PhysicalAssessmentDetail', { assessmentId });
    } else {
      console.warn('[DEBUG] assessment type is not yet handled', type);
    }
  }, [isSubscribed, assessment]);

  const onSubmit = useCallback(
    async (data) => {
      setErrorMessage('');
      const date = new Date(dateString);
      if (isNaN(date as any)) {
        setErrorMessage('Invalid date.');
        return;
      }
      setLoading(true);
      if (data) {
        const result = await userStore.updateMe(omitBy(data, isUndefined));
        setLoading(false);
        if (result.ok) {
          keys(data).forEach((k: string) => {
            setValue(k, data[k]);
          });
          onNext();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [userData, dateString],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const getIndex = (arr: Array<FieldOption>, value: number | string) => {
    const idx = findIndex(arr, (o) => o.value === value);
    // if (idx < 0) return 0;
    return idx;
  };

  const getSelectValue = (
    options: Array<FieldOption>,
    value: string | number,
  ) => {
    const valueOption = find(options, (o) => o.value === value);
    if (valueOption) {
      return valueOption.label;
    }
    return '';
  };

  const renderSelectField = useCallback(
    (item: EditableField) => (
      <View style={styles.inputContainer}>
        {get(errors, item.key) && (
          <Text style={styles.error}>This field is required</Text>
        )}
        <Text style={styles.inputLabel}>{item.label} *</Text>
        <Select
          placeholder="Select an option"
          value={getSelectValue(item.options, get(userData?.values, item.key))}
          selectedIndex={
            getIndex(item.options, get(userData?.values, item.key)) > -1
              ? new IndexPath(
                  getIndex(item.options, get(userData?.values, item.key)),
                )
              : null
          }
          onSelect={(index) => {
            const indexStr = index.toString();
            setUserData({
              user: userData?.user,
              values: {
                ...userData?.values,
                [item.key]: item.options[Number(indexStr) - 1].value,
              },
            });
            setValue(item.key, item.options[Number(indexStr) - 1].value);
          }}
        >
          {item.options?.map((o) => (
            <SelectItem title={o.label} key={o.value} />
          ))}
        </Select>
      </View>
    ),
    [userData, errors],
  );

  const renderDatePickerField = useCallback(
    (item: EditableField) => {
      return (
        <View style={styles.inputContainer}>
          {get(errors, item.key) && (
            <Text style={styles.error}>This field is required</Text>
          )}
          <View style={{ flexDirection: 'row' }}>
            <LabeledInput
              style={styles.inputContainer}
              placeholder="YYYY-MM-DD"
              label={item.label + ' *'}
              labelStyle={styles.inputLabel}
              inputStyle={styles.inputField}
              textStyle={styles.inputFieldText}
              onChangeText={(text) => {
                setDateString(text);
                setValue(item.key, text);
              }}
              onFocus={() => setDateString(String(item.value))}
              defaultValue={String(item.value) || ''}
              underlineColorAndroid="transparent"
              keyboardType={
                indexOf(['height', 'weight'], item.key) !== -1
                  ? 'decimal-pad'
                  : 'default'
              }
              // disabled={true}
            />
            <TouchableOpacity
              style={{ height: 30, width: 30, top: 20 }}
              onPress={() => {
                setDateItemKey(item.key);
                const dateValues = item.value.toString().split('-');

                let startMonth = 1;
                let startDay = 1;
                let startYear = 2021;
                if (dateValues.length > 2) {
                  startYear = parseInt(dateValues[0], 10);
                  startMonth = parseInt(dateValues[1], 10);
                  startDay = parseInt(dateValues[2], 10);
                }
                navigation.navigate('DatePicker', {
                  screen: 'PreassessmentForm',
                  startMonth,
                  startDay,
                  startYear,
                });
              }}
            >
              <Icon
                style={styles.inputActionIcon2}
                fill="white"
                name="calendar-outline"
              />
            </TouchableOpacity>
          </View>
          {false && (
            <DateTimePickerModal
              date={
                get(userData?.values, item.key)
                  ? parseISO(get(userData?.values, item.key))
                  : new Date()
              }
              isVisible={dateEdit}
              isDarkModeEnabled={false}
              mode="date"
              onConfirm={(value: Date) => {
                setDateEdit(false);
                const newValue = value ? format(value, 'yyyy-MM-dd') : null;
                setValue(item.key, newValue);
                setDateString(newValue);
                setUserData({
                  ...userData,
                  values: { ...userData?.values, [item.key]: newValue },
                });
              }}
              onCancel={() => {
                setDateEdit(false);
              }}
              maximumDate={new Date()}
              minimumDate={new Date(1900, 0, 1)}
            />
          )}
        </View>
      );
    },
    [userData, errors, dateEdit],
  );

  const renderHeightField = useCallback(
    (item: EditableField) => {
      return (
        <View>
          {get(errors, item.key) && (
            <Text style={styles.error}>This field is required</Text>
          )}
          <Text style={styles.inputLabel} category="c2">
            {item.label} *
          </Text>
          <HeightInput
            question={{
              question: item.label,
              type: item.type,
              key: item.key,
              options: [],
            }}
            style={styles.inputContainer}
            inputStyle={styles.inputFieldHeight}
            textStyle={styles.inputFieldText}
            underlineColorAndroid="transparent"
            keyboardType="decimal-pad"
            multiline
            onChange={(k, v) => setValue(item.key, v)}
            divider={false}
            initial={item.value}
            required={false}
            disableFocus={true}
          />
        </View>
      );
    },
    [errors],
  );

  const renderTextField = useCallback(
    (item: EditableField) => {
      return (
        <>
          {get(errors, item.key) && (
            <Text style={styles.error}>This field is required</Text>
          )}

          <LabeledInput
            style={styles.inputContainer}
            label={`${item.label} *`}
            labelStyle={styles.inputLabel}
            inputStyle={styles.inputField}
            textStyle={styles.inputFieldText}
            onChangeText={(text) => {
              setUserData({
                ...userData,
                values: {
                  ...userData?.values,
                  [item.key]: get(userData?.user, item.key),
                },
              });
              setValue(item.key, text);
            }}
            defaultValue={item.value || ''}
            underlineColorAndroid="transparent"
            keyboardType={
              indexOf(['height', 'weight'], item.key) !== -1
                ? 'decimal-pad'
                : 'default'
            }
            multiline
          />
        </>
      );
    },
    [userData, errors],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.itemContainer}>
        <View style={styles.itemEditable}>
          {item.type === FIELD_TYPE.DATE && renderDatePickerField(item)}
          {item.type === FIELD_TYPE.TEXT && renderTextField(item)}
          {item.type === FIELD_TYPE.SELECT && renderSelectField(item)}
          {item.type === FIELD_TYPE.HEIGHT && renderHeightField(item)}
          <View style={styles.divider} />
        </View>
      </View>
    ),
    [
      onSubmitWrapped,
      userData,
      setDatePickerData,
      datePickerData,
      profileFields,
      errors,
      setDateEdit,
      dateEdit,
    ],
  );

  // checks if the initial values have been set.
  useEffect(() => {
    if (!getValues()['height']) {
      Array.from(profileFields.values()).map((item) => {
        setValue(item.key, item.value);
        if (item.key === 'birthDate') {
          // set date here
          setDateString(String(item.value));
        }
      });
    }
  }, [profileFields]);

  if (!assessment) return <></>;

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar
          title={'Pre-assessment Form'}
          titleOpacity={titleOpacity.current.interpolate({
            inputRange: [0, config.titleDisplayOffsetOnScroll],
            outputRange: [0, 1],
          })}
        />
      </Layout>
      <AnimatedScrollView
        style={styles.content}
        contentContainerStyle={styles.contentContainer}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: titleOpacity.current } } }],
          { useNativeDriver: true },
        )}
      >
        <Text style={styles.category}>Pre-assessment</Text>
        {!!errorMessage && (
          <Text style={styles.error} category="label">
            {errorMessage}
          </Text>
        )}
        <FlatList
          data={Array.from(profileFields.values())}
          renderItem={renderItem}
          keyExtractor={(item) => `${item.key}`}
        />
      </AnimatedScrollView>
      <Layout>
        <BottomNav onNext={onSubmitWrapped} />
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 16,
    marginHorizontal: 24,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 16,
    marginBottom: 4,
  },
  input: {
    marginTop: 12,
  },
  inputAction: {
    paddingLeft: 10,
    paddingBottom: 8,
  },
  inputActionIcon: {
    width: 20,
    height: 20,
  },
  editAction: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  inputActionIcon2: {
    width: 28,
    height: 28,
  },
  multiAction: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
  },
  category: {
    textTransform: 'uppercase',
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 32,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 30,
    marginTop: 0,
    height: 1,
  },
  inputContainer: {
    flex: 1,
  },
  inputLabel: {
    color: 'light-gray',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  inputField: {
    width: '100%',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldHeight: {
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldText: {
    fontSize: 18,
    color: 'light-gray',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemEditable: {
    flexDirection: 'column',
    flex: 1,
  },
});

export default observer(PreassessmentForm);
