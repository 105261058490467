import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Fuse from 'fuse.js';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Animated,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LibraryGlobalStep from 'src/components/FTE/LibraryGlobalStep';
import LibraryList from 'src/components/FTE/LibraryList';
import CreateGlobalStep from './CreateGlobalStep';
import CreateSweatProgram from './CreateSweatProgram';
import CreateSweatWorkout from './CreateSweatWorkout';

type Props = {};

const { width, height } = Dimensions.get('window');

const Library: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const [existingPrograms, setExistingPrograms] = useState<SweatProgram[]>();
  const [existingWorkouts, setExistingWorkouts] = useState<SweatWorkout[]>();
  const [myProgramLibrary, setMyProgramLibrary] = useState<SweatProgram[]>();
  const [myWorkoutLibrary, setMyWorkoutLibrary] = useState<SweatWorkout[]>();
  const [workoutLibraryId, setWorkoutLibraryId] = useState<number>();
  const [programLibraryId, setProgramLibraryId] = useState<number>();
  const [programIds, setProgramIds] = useState<number[]>();
  const [workoutIds, setWorkoutIds] = useState<number[]>();

  const [prepareStep, setPrepareStep] = useState<SweatGlobalStep>();
  const [recoverStep, setRecoverStep] = useState<SweatGlobalStep>();
  const [prepareList, setPrepareList] = useState<SweatGlobalStep[]>();
  const [recoverList, setRecoverList] = useState<SweatGlobalStep[]>();
  const [prepSearch, setPrepSearch] = useState('');
  const [recSearch, setRecSearch] = useState('');
  const [loadingPrep, setLoadingPrep] = useState(false);
  const [loadingRec, setLoadingRec] = useState(false);

  const [prepNextRequest, setPrepNextRequest] = useState('');
  const [recNextRequest, setRecNextRequest] = useState('');

  const [loadingMoreProgram, setLoadingMoreProgram] = useState(false);
  const [loadingMoreWorkout, setLoadingMoreWorkout] = useState(false);
  const [nextRequestProgram, setNextRequestProgram] = useState('');
  const [nextRequestWorkout, setNextRequestWorkout] = useState('');

  const [loadingExistingPrograms, setLoadingExistingPrograms] = useState(false);
  const [loadingExistingWorkouts, setLoadingExistingWorkouts] = useState(false);
  const [programSearch, setProgramSearch] = useState('');
  const [workoutSearch, setWorkoutSearch] = useState('');

  const [programSearchList, setProgramSearchList] = useState<SweatProgram[]>();
  const [workoutSearchList, setWorkoutSearchList] = useState<SweatWorkout[]>();
  const [programFuzzy, setProgramFuzzy] = useState('');
  const [workoutFuzzy, setWorkoutFuzzy] = useState('');

  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [loadingWorkouts, setLoadingWorkouts] = useState(false);
  const [loadingMorePrepare, setLoadingMorePrepare] = useState(false);
  const [loadingMoreRecover, setLoadingMoreRecover] = useState(false);

  const [createContent, setCreateContent] = useState('select');

  const [initialLoadingA, setInitialLoadingA] = useState(false);
  const [initialLoadingB, setInitialLoadingB] = useState(false);
  const [initialLoadingC, setInitialLoadingC] = useState(false);
  const [initialLoadingD, setInitialLoadingD] = useState(false);
  const [initialLoadingE, setInitialLoadingE] = useState(false);
  const [initialLoadingF, setInitialLoadingF] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [resultInfo, setResultInfo] = useState('');
  const [hasError, setHasError] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(0);
  const [toBeDeletedType, setToBeDeletedType] = useState('');

  const { handleSubmit } = useForm();
  const store = useStore();

  const [isSavingProgramSuccess, setIsSavingProgramSuccess] = useState(false);
  const [isSavingWorkoutSuccess, setIsSavingWorkoutSuccess] = useState(false);
  const programAnimation = useRef(new Animated.Value(0)).current;
  const workoutAnimation = useRef(new Animated.Value(0)).current;
  const animation = useRef(new Animated.Value(0)).current;

  const fuseOptions = {
    distance: 100,
    includeMatches: true,
    keys: ['name', 'displayName'],
    location: 0,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.6,
  };

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingA(true);
        const sweatPrograms =
          await store.fteLibrary.fetchExistingSweatPrograms();
        setExistingPrograms(sweatPrograms.extra?.results);
        setNextRequestProgram(sweatPrograms.extra?.next);
        setInitialLoadingA(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingB(true);
        const sweatWorkouts =
          await store.fteLibrary.fetchExistingSweatWorkouts();
        setExistingWorkouts(sweatWorkouts.extra?.results);
        setNextRequestWorkout(sweatWorkouts.extra?.next);
        setInitialLoadingB(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingC(true);
        const myPrograms = await store.fteLibrary.fetchMySweatPrograms();
        setMyProgramLibrary(myPrograms.extra?.results[0].programDetails);
        setProgramSearchList(myPrograms.extra?.results[0].programDetails);
        setProgramIds(myPrograms.extra?.results[0].programs);
        setProgramLibraryId(myPrograms.extra?.results[0].id);
        setInitialLoadingC(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingD(true);
        const myWorkouts = await store.fteLibrary.fetchMySweatWorkouts();
        setMyWorkoutLibrary(myWorkouts.extra?.results[0].workoutDetails);
        setWorkoutSearchList(myWorkouts.extra?.results[0].workoutDetails);
        setWorkoutIds(myWorkouts.extra?.results[0].workouts);
        setWorkoutLibraryId(myWorkouts.extra?.results[0].id);
        setInitialLoadingD(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingE(true);
        const fetchData = await store.sweat.fetchFTESweatGlobalSteps(
          store.auth.user?.id!,
          'prepare',
        );
        setPrepNextRequest(fetchData.extra?.next);
        setPrepareList(fetchData.extra?.results);
        setInitialLoadingE(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingF(true);
        const fetchData = await store.sweat.fetchFTESweatGlobalSteps(
          store.auth.user?.id!,
          'recover',
        );
        setRecNextRequest(fetchData.extra?.next);
        setRecoverList(fetchData.extra?.results);
        setInitialLoadingF(false);
      })();
    }, []),
  );

  const addToPrograms = (program: SweatProgram) => {
    if (programIds?.includes(program.id) || myProgramLibrary?.includes(program))
      return;
    setProgramIds(programIds ? [...programIds!, program.id] : [program.id]);
    setMyProgramLibrary(
      myProgramLibrary ? [...myProgramLibrary!, program] : [program],
    );
    setProgramSearchList(
      programSearchList ? [...programSearchList!, program] : [program],
    );
  };

  const removeFromPrograms = (program: SweatProgram) => {
    const arr = [...programIds!];
    const arr2 = [...programSearchList!];
    const arr3 = [...myProgramLibrary!];
    const index = arr.indexOf(program.id);
    const index2 = arr3.indexOf(program);
    arr3.splice(index2, 1);
    arr2.splice(index, 1);
    arr.splice(index, 1);
    setProgramIds([...arr]);
    setProgramSearchList([...arr2]);
    setMyProgramLibrary([...arr3]);
  };

  const saveProgram = useCallback(
    async (data) => {
      data.id = programLibraryId;
      data.programs = programIds;
      setLoadingSave(true);
      const result = await store.fteLibrary.updateFteSweatProgramLibrary(data);
      //refetch programs and set searchable name after updating the library
      // if (result.ok) {
      //   const myPrograms = await store.fteLibrary.fetchMySweatPrograms();
      //   setMyProgramLibrary(myPrograms.extra?.results[0].programDetails);
      //   setProgramSearchList(myPrograms.extra?.results[0].programDetails);
      //   setProgramIds(myPrograms.extra?.results[0].programs);
      // }
      setLoadingSave(false);
      setIsSavingProgramSuccess(result.ok);
      Animated.timing(programAnimation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => programAnimation.setValue(0));
    },
    [programLibraryId, programIds],
  );
  const onSaveProgramWrapped = handleSubmit(saveProgram);

  const addToWorkouts = (workout: SweatWorkout) => {
    if (workoutIds?.includes(workout.id) || myWorkoutLibrary?.includes(workout))
      return;
    setWorkoutIds(workoutIds ? [...workoutIds!, workout.id] : [workout.id]);
    setMyWorkoutLibrary(
      myWorkoutLibrary ? [...myWorkoutLibrary!, workout] : [workout],
    );
    setWorkoutSearchList(
      workoutSearchList ? [...workoutSearchList!, workout] : [workout],
    );
  };

  const removeFromWorkouts = (workout: SweatWorkout) => {
    const arr = [...workoutIds!];
    const arr2 = [...workoutSearchList!];
    const arr3 = [...myWorkoutLibrary!];
    const index = arr.indexOf(workout.id);
    const index2 = arr3.indexOf(workout);
    arr3.splice(index2, 1);
    arr2.splice(index, 1);
    arr.splice(index, 1);
    setWorkoutIds([...arr]);
    setWorkoutSearchList([...arr2]);
    setMyWorkoutLibrary([...arr3]);
  };

  const saveWorkout = useCallback(
    async (data) => {
      data.id = workoutLibraryId;
      data.workouts = workoutIds;
      setLoadingSave(true);
      const result = await store.fteLibrary.updateFteSweatWorkoutLibrary(data);
      //refetch workouts and set searchable name after updating the library
      // if (result.ok) {
      //   const myWorkouts = await store.fteLibrary.fetchMySweatWorkouts();
      //   setMyWorkoutLibrary(myWorkouts.extra?.results[0].workoutDetails);
      //   setWorkoutSearchList(myWorkouts.extra?.results[0].workoutDetails);
      //   setWorkoutIds(myWorkouts.extra?.results[0].workouts);
      // }
      setLoadingSave(false);
      setIsSavingWorkoutSuccess(result.ok);
      Animated.timing(workoutAnimation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => workoutAnimation.setValue(0));
    },
    [workoutLibraryId, workoutIds],
  );
  const onSaveWorkoutWrapped = handleSubmit(saveWorkout);

  const onSaveProgram = async () => {
    setCreateContent('select');
    setLoadingPrograms(true);
    const sweatPrograms = await store.fteLibrary.fetchExistingSweatPrograms();
    const myPrograms = await store.fteLibrary.fetchMySweatPrograms();
    setMyProgramLibrary(myPrograms.extra?.results[0].programDetails);
    setProgramSearchList(myPrograms.extra?.results[0].programDetails);
    setProgramIds(myPrograms.extra?.results[0].programs);
    setProgramLibraryId(myPrograms.extra?.results[0].id);
    setExistingPrograms(sweatPrograms.extra?.results);
    setNextRequestProgram(sweatPrograms.extra?.next);
    setLoadingPrograms(false);
  };

  const onSaveWorkout = async () => {
    setCreateContent('select');
    setLoadingWorkouts(true);
    const sweatWorkouts = await store.fteLibrary.fetchExistingSweatWorkouts();
    const myWorkouts = await store.fteLibrary.fetchMySweatWorkouts();
    setMyWorkoutLibrary(myWorkouts.extra?.results[0].workoutDetails);
    setWorkoutSearchList(myWorkouts.extra?.results[0].workoutDetails);
    setWorkoutIds(myWorkouts.extra?.results[0].workouts);
    setWorkoutLibraryId(myWorkouts.extra?.results[0].id);
    setExistingWorkouts(sweatWorkouts.extra?.results);
    setNextRequestWorkout(sweatWorkouts.extra?.next);
    setLoadingWorkouts(false);
  };

  const onCloseStep = async () => {
    setCreateContent('select');
    setLoadingPrep(true);
    setLoadingRec(true);
    const prepareData = await store.sweat.fetchFTESweatGlobalSteps(
      store.auth.user?.id!,
      'prepare',
    );
    const recoverData = await store.sweat.fetchFTESweatGlobalSteps(
      store.auth.user?.id!,
      'recover',
    );
    setRecNextRequest(recoverData.extra?.next);
    setRecoverList(recoverData.extra?.results);
    setPrepNextRequest(prepareData.extra?.next);
    setPrepareList(prepareData.extra?.results);
    setLoadingPrep(false);
    setLoadingRec(false);
  };

  const loadMoreProgram = async () => {
    setLoadingMoreProgram(true);
    if (nextRequestProgram && !loadingExistingPrograms && !loadingPrograms) {
      const sweatPrograms = await store.fteLibrary.fetchExistingSweatPrograms(
        nextRequestProgram,
      );
      setExistingPrograms([
        ...existingPrograms!,
        ...sweatPrograms.extra?.results,
      ]);
      setNextRequestProgram(sweatPrograms.extra?.next);
    }
    setLoadingMoreProgram(false);
  };

  const searchProgramItem = () => {
    const fuse = new Fuse(programSearchList!, fuseOptions);
    const matches = fuse.search(programFuzzy);
    if (matches.length > 0) {
      setMyProgramLibrary(matches.map((result) => result.item));
    }
    if (programFuzzy.length === 0) {
      setMyProgramLibrary(programSearchList);
    }
  };

  const onSearchProgram = async () => {
    setLoadingExistingPrograms(true);
    let url = `${
      config.urls.fteLibrary
    }existing-sweat-programs/?search=${programSearch.trim()}&limit=10`;
    const sweatPrograms = await store.fteLibrary.fetchExistingSweatPrograms(
      url,
    );
    setExistingPrograms(sweatPrograms.extra?.results);
    setNextRequestProgram(sweatPrograms.extra?.next);
    setLoadingExistingPrograms(false);
  };

  const loadMoreWorkout = async () => {
    setLoadingMoreWorkout(true);
    if (nextRequestWorkout && !loadingExistingWorkouts && !loadingWorkouts) {
      const sweatWorkouts = await store.fteLibrary.fetchExistingSweatWorkouts(
        nextRequestWorkout,
      );
      setExistingWorkouts([
        ...existingWorkouts!,
        ...sweatWorkouts.extra?.results,
      ]);
      setNextRequestWorkout(sweatWorkouts.extra?.next);
    }
    setLoadingMoreWorkout(false);
  };

  const searchWorkoutItem = () => {
    const fuse = new Fuse(workoutSearchList!, fuseOptions);
    const matches = fuse.search(workoutFuzzy);
    if (matches.length > 0) {
      setMyWorkoutLibrary(matches.map((result) => result.item));
    }
    if (workoutFuzzy.length === 0) {
      setMyWorkoutLibrary(workoutSearchList);
    }
  };

  const onSearchWorkout = async () => {
    setLoadingExistingWorkouts(true);
    let url = `${
      config.urls.fteLibrary
    }existing-sweat-workouts/?search=${workoutSearch.trim()}&limit=10`;
    const sweatWorkouts = await store.fteLibrary.fetchExistingSweatWorkouts(
      url,
    );
    setExistingWorkouts(sweatWorkouts.extra?.results);
    setNextRequestWorkout(sweatWorkouts.extra?.next);
    setLoadingExistingWorkouts(false);
  };

  const loadMoreStep = async (type: string) => {
    if (
      (type === 'prepare' && prepNextRequest) ||
      (type === 'recover' && recNextRequest)
    ) {
      type === 'prepare'
        ? setLoadingMorePrepare(true)
        : setLoadingMoreRecover(true);
      const fetchData = await store.sweat.fetchFTESweatGlobalSteps(
        store.auth.user?.id!,
        type,
        prepNextRequest,
      );
      if (type === 'prepare') {
        setPrepareList([...prepareList!, ...fetchData.extra?.results]);
        setPrepNextRequest(fetchData.extra?.next);
        setLoadingMorePrepare(false);
      } else {
        setRecoverList([...recoverList!, ...fetchData.extra?.results]);
        setRecNextRequest(fetchData.extra?.next);
        setLoadingMoreRecover(false);
      }
    }
  };

  const onSearchStep = async (type: string) => {
    type === 'prepare' ? setLoadingPrep(true) : setLoadingRec(true);
    let url = `${config.urls.sweatGlobalSteps}?search=${
      type === 'prepare' ? prepSearch : recSearch
    }&fte=${store.auth.user?.id!}&step_type=${type}&limit=10`;
    const fetchData = await store.sweat.fetchFTESweatGlobalSteps(
      store.auth.user?.id!,
      type,
      url,
    );
    if (type === 'prepare') {
      setPrepareList(fetchData.extra?.results);
      setPrepNextRequest(fetchData.extra?.next);
      setLoadingPrep(false);
    } else {
      setRecoverList(fetchData.extra?.results);
      setRecNextRequest(fetchData.extra?.next);
      setLoadingRec(false);
    }
  };

  const onRemoveStep = async (type: string, id: number, index: number) => {
    type === 'prepare' ? setLoadingPrep(true) : setLoadingRec(true);
    const result = await store.sweat.deleteSweatGlobalStep(id);
    if (result.ok) {
      if (type === 'prepare') {
        let arr = prepareList;
        arr?.splice(index, 1);
        setPrepareList(arr);
        setLoadingPrep(false);
      } else {
        let arr = recoverList;
        arr?.splice(index, 1);
        setRecoverList(arr);
        setLoadingRec(false);
      }
    }
  };

  const onDelete = useCallback((id: number, type: string) => {
    setIsDeleting(true);
    setToBeDeletedType(type);
    setToBeDeletedId(id);
    Animated.timing(animation, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsDeleting(false);
    animation.setValue(0);
  }, []);

  const onConfirmDeleteWorkout = useCallback(async () => {
    setIsDeleting(false);
    animation.setValue(0);
    setLoadingWorkouts(true);
    const result = await store.fteLibrary.deleteFteSweatWorkout(toBeDeletedId);
    const sweatWorkouts = await store.fteLibrary.fetchExistingSweatWorkouts();
    const myWorkouts = await store.fteLibrary.fetchMySweatWorkouts();
    setMyWorkoutLibrary(myWorkouts.extra?.results[0].workoutDetails);
    setWorkoutSearchList(myWorkouts.extra?.results[0].workoutDetails);
    setWorkoutIds(myWorkouts.extra?.results[0].workouts);
    setWorkoutLibraryId(myWorkouts.extra?.results[0].id);
    setExistingWorkouts(sweatWorkouts.extra?.results);
    setNextRequestWorkout(sweatWorkouts.extra?.next);
    setLoadingWorkouts(false);
  }, [toBeDeletedId, toBeDeletedType]);

  const onConfirmDeleteProgram = useCallback(async () => {
    setIsDeleting(false);
    animation.setValue(0);
    setLoadingPrograms(true);
    const result = await store.fteLibrary.deleteFteSweatProgram(toBeDeletedId);
    const sweatPrograms = await store.fteLibrary.fetchExistingSweatPrograms();
    const myPrograms = await store.fteLibrary.fetchMySweatPrograms();
    setMyProgramLibrary(myPrograms.extra?.results[0].programDetails);
    setProgramSearchList(myPrograms.extra?.results[0].programDetails);
    setProgramIds(myPrograms.extra?.results[0].programs);
    setProgramLibraryId(myPrograms.extra?.results[0].id);
    setExistingPrograms(sweatPrograms.extra?.results);
    setNextRequestProgram(sweatPrograms.extra?.next);
    setLoadingPrograms(false);
  }, [toBeDeletedId, toBeDeletedType]);

  const programScale = programAnimation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const programOpacity = programAnimation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const programTranslateY = programAnimation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const workoutScale = workoutAnimation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const workoutOpacity = workoutAnimation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const workoutTranslateY = workoutAnimation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  const isLoading =
    initialLoadingA ||
    initialLoadingB ||
    initialLoadingC ||
    initialLoadingD ||
    initialLoadingE ||
    initialLoadingF;

  return (
    <Layout style={styles.main}>
      {createContent === 'sweat-program' && (
        <CreateSweatProgram
          onClose={() => setCreateContent('select')}
          onSave={() => onSaveProgram()}
          programId={programLibraryId}
          programLibrary={programIds}
        />
      )}
      {createContent === 'sweat-workout' && (
        <CreateSweatWorkout
          onClose={() => setCreateContent('select')}
          onSave={() => onSaveWorkout()}
          libraryId={workoutLibraryId}
          workoutLibrary={workoutIds}
        />
      )}
      {createContent === 'global' && (
        <CreateGlobalStep
          onClose={() => onCloseStep()}
          prepareStep={prepareStep}
          recoverStep={recoverStep}
        />
      )}

      {createContent === 'select' && (
        <Layout style={styles.container}>
          <Layout
            style={
              isMobile ? styles.MobileCreateContainer : styles.createContainer
            }
          >
            <TouchableOpacity
              disabled={isLoading}
              style={[
                isMobile ? styles.MobileCreateButton : styles.createButton,
                isLoading && styles.disabledButton,
              ]}
              onPress={() => setCreateContent('sweat-program')}
            >
              <Text style={[styles.createText, isMobile && { fontSize: 10 }]}>
                CREATE SWEAT PROGRAM
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isLoading}
              style={[
                isMobile ? styles.MobileCreateButton : styles.createButton,
                isLoading && styles.disabledButton,
              ]}
              onPress={() => setCreateContent('sweat-workout')}
            >
              <Text style={[styles.createText, isMobile && { fontSize: 10 }]}>
                CREATE SWEAT WORKOUT
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isLoading}
              style={[
                isMobile ? styles.MobileCreateButton : styles.createButton,
                isLoading && styles.disabledButton,
              ]}
              onPress={() => {
                setPrepareStep(undefined);
                setRecoverStep(undefined);
                setCreateContent('global');
              }}
            >
              <Text style={[styles.createText, isMobile && { fontSize: 10 }]}>
                CREATE PREPARE/RECOVER
              </Text>
            </TouchableOpacity>
          </Layout>
          <ScrollView
            horizontal={true}
            contentContainerStyle={styles.bgContainer}
            style={{ marginRight: 20 }}
          >
            {/* SWEAT PROGRAM LIBRARY */}
            <LibraryList
              opacity={programOpacity}
              scale={programScale}
              translateY={programTranslateY}
              isSavingSuccess={isSavingProgramSuccess}
              isLoading={isLoading}
              contentSearch={programSearch}
              setContentSearch={setProgramSearch}
              loadMoreContent={loadMoreProgram}
              onSearchContent={onSearchProgram}
              loadingContents={loadingPrograms}
              loadingExistingContents={loadingExistingPrograms}
              loadingMoreContent={loadingMoreProgram}
              existingContents={existingPrograms}
              addToLibrary={addToPrograms}
              removeFromLibrary={removeFromPrograms}
              libraryFuzzy={programFuzzy}
              setLibraryFuzzy={setProgramFuzzy}
              searchLibraryItem={searchProgramItem}
              onDelete={onDelete}
              myLibrary={myProgramLibrary}
              loadingSave={loadingSave}
              onSaveLibrary={onSaveProgramWrapped}
              content="program"
            />
            {/* SWEAT WORKOUT LIBRARY */}
            <LibraryList
              opacity={workoutOpacity}
              scale={workoutScale}
              translateY={workoutTranslateY}
              isSavingSuccess={isSavingWorkoutSuccess}
              isLoading={isLoading}
              contentSearch={workoutSearch}
              setContentSearch={setWorkoutSearch}
              loadMoreContent={loadMoreWorkout}
              onSearchContent={onSearchWorkout}
              loadingContents={loadingWorkouts}
              loadingExistingContents={loadingExistingWorkouts}
              loadingMoreContent={loadingMoreWorkout}
              existingContents={existingWorkouts}
              addToLibrary={addToWorkouts}
              removeFromLibrary={removeFromWorkouts}
              libraryFuzzy={workoutFuzzy}
              setLibraryFuzzy={setWorkoutFuzzy}
              searchLibraryItem={searchWorkoutItem}
              onDelete={onDelete}
              myLibrary={myWorkoutLibrary}
              loadingSave={loadingSave}
              onSaveLibrary={onSaveWorkoutWrapped}
              content="workout"
            />
            {/* PREPARE / RECOVER LIST */}
            <LibraryGlobalStep
              prepSearch={prepSearch}
              recSearch={recSearch}
              setRecSearch={setRecSearch}
              setPrepSearch={setPrepSearch}
              onSearchStep={onSearchStep}
              isLoading={isLoading}
              loadingPrep={loadingPrep}
              loadingRec={loadingRec}
              loadingMoreRecover={loadingMoreRecover}
              loadingMorePrepare={loadingMorePrepare}
              prepareList={prepareList}
              recoverList={recoverList}
              setRecoverStep={setRecoverStep}
              setPrepareStep={setPrepareStep}
              setCreateContent={setCreateContent}
              onRemoveStep={onRemoveStep}
              loadMoreStep={loadMoreStep}
            />
          </ScrollView>
          <View
            style={!!isMobile ? styles.mobileCenter : styles.center}
            pointerEvents={isDeleting ? undefined : 'none'}
          >
            {isDeleting && (
              <TouchableOpacity
                activeOpacity={1}
                style={!!isMobile ? styles.mobileCenter : styles.center}
                onPress={onCancelDelete}
              />
            )}
            <Animated.View
              style={[
                styles.popUpContainer,
                {
                  opacity,
                  transform: [{ scale }, { translateY }],
                },
              ]}
            >
              <Icon
                style={styles.savedIcon}
                name={
                  isDeleting
                    ? 'question-mark-circle-outline'
                    : hasError
                    ? 'close-circle-outline'
                    : 'checkmark-circle-2-outline'
                }
                fill={isDeleting ? 'orange' : hasError ? 'warning' : 'olive'}
              />
              <Text style={styles.saveText}>
                {isDeleting ? 'Are you sure you want to' : resultInfo}
              </Text>
              {isDeleting && (
                <Text style={styles.saveText}>
                  {`delete this ${
                    toBeDeletedType === 'workout'
                      ? 'Workout?'
                      : toBeDeletedType === 'program'
                      ? 'Program?'
                      : 'Item?'
                  }`}{' '}
                </Text>
              )}
              {isDeleting && (
                <View style={styles.buttons}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={onCancelDelete}
                  >
                    <Text style={styles.deleteText}>NO</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={
                      toBeDeletedType === 'workout'
                        ? onConfirmDeleteWorkout
                        : toBeDeletedType === 'program'
                        ? onConfirmDeleteProgram
                        : undefined
                    }
                  >
                    <Text style={styles.deleteText}>YES</Text>
                  </TouchableOpacity>
                </View>
              )}
            </Animated.View>
          </View>
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  main: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bgContainer: {
    width: '100%',
    // height: '95%',
    marginTop: 15,
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  createContainer: {
    // height: '24px',
    width: '570px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '26px',
  },
  createButton: {
    width: '180px',
    height: '30px',
    backgroundColor: 'olive',
  },
  MobileCreateContainer: {
    height: 'auto',
    width: '95%',
    flexDirection: 'row',
    marginLeft: '26px',
  },
  MobileCreateButton: {
    width: '30%',
    marginRight: '2%',
    padding: 2,
    height: '30px',
    backgroundColor: 'olive',
  },
  disabledButton: {
    opacity: 0.5,
  },
  createText: {
    color: 'white',
    margin: 'auto',
    fontSize: 12,
  },
  listContainer: {
    flex: 1,
    height: '95%',
    backgroundColor: '#203950',
    marginLeft: 40,
  },
  headerContainer: {
    width: '100%',
    height: '50px',
  },
  headerText: {
    color: 'white',
    margin: 'auto',
    fontSize: 14,
  },
  headerTextMobile: {
    color: 'white',
    paddingLeft: 7,
    margin: 'auto',
    fontSize: 14,
  },
  search: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1A3248',
  },
  icon: {
    width: 20,
    height: 20,
  },
  library: {
    flexDirection: 'column',
    backgroundColor: '#051023',
    marginBottom: 45,
    width: '100%',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 14,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
    top: -2,
  },
  row: {
    flexDirection: 'row',
    width: '58%',
    padding: 0,
    justifyContent: 'space-between',
  },
  rowMobile: {
    flexDirection: 'row',
    height: '400px',
    width: '100%',
    flexWrap: 'wrap',
    padding: 0,
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: width,
    height: height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
    textTransform: 'uppercase',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
});

export default observer(Library);
