import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  prop,
  prop_mapObject,
  SnapshotOutOf,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import Task from '../models/Task';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import { TASK_COMPONENT_TYPE, TASK_TYPE } from '../utils/tasks';

@model('o2x-store/TaskStore')
export default class TaskStore extends Model({
  today: prop_mapObject(() => new Map<string, Task>()),
  recommended: prop_mapObject(() => new Map<string, Task>()),
  miscellaneous: prop_mapObject(() => new Map<string, Task>()),
  savedRecipes: prop_mapObject(() => new Map<string, Task>()),
  allSavedRecipesCount: prop<Number>(() => 0),
  savedSweatWorkouts: prop_mapObject(() => new Map<string, Task>()),
  allSavedSweatWorkoutsCount: prop<Number>(() => 0),
  savedSweatPrograms: prop_mapObject(() => new Map<string, Task>()),
  allSavedSweatProgramsCount: prop<Number>(() => 0),
  activeSweatPrograms: prop_mapObject(() => new Map<string, Task>()),
  allActiveSweatProgramsCount: prop<Number>(() => 0),
  savedThriveExercises: prop_mapObject(() => new Map<string, Task>()),
  allSavedThriveExercisesCount: prop<Number>(() => 0),
  savedThrivePrograms: prop_mapObject(() => new Map<string, Task>()),
  allSavedThriveProgramsCount: prop<Number>(() => 0),
  activeThrivePrograms: prop_mapObject(() => new Map<string, Task>()),
  allActiveThriveProgramsCount: prop<Number>(() => 0),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchTasks = _async(function* (
    this: TaskStore,
    task_type: TASK_TYPE | 'all',
    clear: boolean = true,
    clearCache: boolean = false,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const getId = (task: SnapshotOutOf<Task>) => {
      let id: string = task.component;
      if (task.id) {
        id += `-${task.id}`;
      }
      return id;
    };

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTasks(rootStore.auth.token, task_type, clearCache),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching tasks', error);
      return getError(error);
    }

    if (clear) {
      this.today.clear();
      this.recommended.clear();
      this.miscellaneous.clear();
      this.savedRecipes.clear();
      this.savedSweatWorkouts.clear();
      this.savedSweatPrograms.clear();
      this.activeSweatPrograms.clear();
      this.savedThriveExercises.clear();
      this.savedThrivePrograms.clear();
      this.activeThrivePrograms.clear();
    }

    const blockedTasks = [
      TASK_COMPONENT_TYPE.EAT_PROFILE,
      TASK_COMPONENT_TYPE.EAT_HABIT,
      TASK_COMPONENT_TYPE.THRIVE_PROFILE,
      TASK_COMPONENT_TYPE.THRIVE_HABIT,
    ];

    if (task_type === 'all') {
      (entities.today || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        if (!blockedTasks.includes(taskData.component)) {
          this.today.set(id, new Task(taskData));
        }
      });
      (entities.recommended || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        this.recommended.set(id, new Task(taskData));
      });
      (entities.miscellaneous || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.miscellaneous.set(id, new Task(taskData));
        },
      );
    }

    if (task_type === TASK_TYPE.EAT) {
      this.today.clear();
      (entities.today || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        if (!blockedTasks.includes(taskData.component)) {
          this.today.set(id, new Task(taskData));
        }
      });
      (entities.savedRecipes || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        this.savedRecipes.set(id, new Task(taskData));
      });
      this.allSavedRecipesCount = entities.allSavedRecipesCount;
    }

    if (task_type === TASK_TYPE.SWEAT) {
      this.today.clear();
      this.savedSweatWorkouts.clear();
      this.savedSweatPrograms.clear();
      this.activeSweatPrograms.clear();

      (entities.today || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        this.today.set(id, new Task(taskData));
      });
      (entities.savedSweatWorkouts || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.savedSweatWorkouts.set(id, new Task(taskData));
        },
      );
      (entities.savedSweatPrograms || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.savedSweatPrograms.set(id, new Task(taskData));
        },
      );
      (entities.activeSweatPrograms || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.activeSweatPrograms.set(id, new Task(taskData));
        },
      );
      this.allSavedSweatWorkoutsCount = entities.allSavedSweatWorkoutsCount;
      this.allSavedSweatProgramsCount = entities.allSavedSweatProgramsCount;
      this.allActiveSweatProgramsCount = entities.allActiveSweatProgramsCount;
    }

    if (task_type === TASK_TYPE.THRIVE) {
      this.today.clear();
      this.savedThriveExercises.clear();
      this.savedThrivePrograms.clear();
      this.activeThrivePrograms.clear();

      (entities.today || []).forEach((taskData: SnapshotOutOf<Task>) => {
        const id = getId(taskData);
        this.today.set(id, new Task(taskData));
      });
      (entities.savedThriveExercises || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.savedThriveExercises.set(id, new Task(taskData));
        },
      );
      (entities.savedThrivePrograms || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.savedThrivePrograms.set(id, new Task(taskData));
        },
      );
      (entities.activeThrivePrograms || []).forEach(
        (taskData: SnapshotOutOf<Task>) => {
          const id = getId(taskData);
          this.activeThrivePrograms.set(id, new Task(taskData));
        },
      );
      this.allSavedThriveExercisesCount = entities.allSavedThriveExercisesCount;
      this.allSavedThriveProgramsCount = entities.allSavedThriveProgramsCount;
      this.allActiveThriveProgramsCount = entities.allActiveThriveProgramsCount;
    }

    this.loading = false;
    return getSuccess();
  });
}
