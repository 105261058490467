import { useNavigation } from '@react-navigation/native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get, isUndefined, omitBy, set } from 'lodash';
import { observer } from 'mobx-react-lite';
import User, { UserSetting } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import CheckBox from '../../components/CheckBox';
import { getErrors } from '../../utils/errors';
import SettingsContainer from './SettingsContainer';

type UserData = {
  user: User | undefined;
  values: Partial<UserSetting>;
};

type Field = {
  label: string;
  key: string;
  checked: boolean;
};

const EDITABLE_FIELDS = [
  'shareEatActivity',
  'shareSweatActivity',
  'shareThriveActivity',
  'shareAssessmentResults',
];

type Props = {};

const Team: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const { auth, user: userStore } = store;
  const navigation = useNavigation();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [userData, setUserData] = useState<UserData>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const editableFields = [
    {
      label: 'Share EAT Activity',
      key: 'shareEatActivity',
      checked: userData?.values.shareEatActivity,
    },
    {
      label: 'Share SWEAT Activity',
      key: 'shareSweatActivity',
      checked: userData?.values.shareSweatActivity,
    },
    {
      label: 'Share THRIVE Activity',
      key: 'shareThriveActivity',
      checked: userData?.values.shareThriveActivity,
    },
    {
      label: 'Share Assessment Results',
      key: 'shareAssessmentResults',
      checked: userData?.values.shareAssessmentResults,
    },
  ];

  useEffect(() => {
    const storedUser = auth.user;
    if (storedUser) {
      const storedUserValues: Partial<UserSetting> = {};
      editableFields.forEach((f) =>
        set(storedUserValues, f.key, get(storedUser.settings, f.key)),
      );
      setUserData({ user: storedUser, values: storedUserValues });
    }
  }, [auth.user]);

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f: string) => {
      register({ name: f }, { required: false });
    });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (data) {
        const result = await userStore.updateSettings(
          omitBy(data, isUndefined),
        );
        setLoading(false);
        if (result.ok) {
        } else {
          setErrorMessage(getErrors(result.errors));
          // Revert
          const storedUserValues: Partial<UserSetting> = {};
          editableFields.forEach((f) =>
            set(storedUserValues, f.key, get(userData?.user?.settings, f.key)),
          );
          setUserData({ user: userData?.user, values: storedUserValues });
        }
      }
    },
    [userData],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <SettingsContainer>
      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      {editableFields.map((f) => (
        <View style={styles.inputContainer} key={f.key}>
          <Text style={styles.inputLabel}>{f.label}</Text>
          <CheckBox
            fill="white"
            key={f.key}
            checked={f.checked || false}
            onChange={(k: string, v: boolean) => {
              setUserData({
                user: userData?.user,
                values: { ...userData?.values, [f.key]: v },
              });
              onSubmit({ [f.key]: v });
            }}
          />
        </View>
      ))}
    </SettingsContainer>
  );
};

const themedStyles = StyleService.create({
  inputLabel: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
    letterSpacing: 1,
  },
});

export default observer(Team);
