import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import Team from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Back from '../../assets/images/back.svg';
import TeamIcon from '../../assets/images/team.svg';
import JoinItem from './JoinItem';
import TeamActivity from './TeamActivity';
import TeamItem from './TeamItem';

type Props = {};

const Teams: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const store = useStore();
  const navigation = useNavigation();
  const [selectedTeam, setSelectedTeam] = useState<number | null>(null);

  const isTablet = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      store.user.fetchMe();
      store.teams.fetchTeams();
      store.teams.fetchPendingTeams();
    }, []),
  );

  const goToCreate = useCallback(() => {
    navigation.navigate('TeamEdit', { teamId: null });
  }, []);

  const goToInvite = useCallback(() => {
    navigation.navigate('TeamInvite');
  }, []);

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <TeamItem team={item} onPress={() => setSelectedTeam(item.id)} />
      ) : null,
    [setSelectedTeam],
  );

  const keyExtractor = useCallback((item: Team) => `${item.id}`, []);

  const teamArray = useMemo(() => {
    return Array.from(store.teams.teams.values());
  }, [store.teams.teams.values()]);

  return (
    <Layout
      style={[
        isTablet ? styles.containerMobile : styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View
        style={
          isTablet && selectedTeam
            ? styles.contentMobileHidden
            : isMobile
            ? styles.contentMobile
            : styles.content
        }
      >
        <View style={isMobile ? styles.headerMobile : styles.header}>
          <TeamIcon height={isMobile ? 24 : 30} width={isMobile ? 24 : 30} />
          <Text style={isMobile ? styles.titleMobile : styles.title}>
            {' '}
            Teams
          </Text>
          <TouchableOpacity
            style={
              isTablet ? styles.pendingContainerMobile : styles.pendingContainer
            }
            onPress={goToInvite}
          >
            <Text
              style={isMobile ? styles.pendingTextMobile : styles.pendingText}
            >
              View Pending Invites ({store.teams.pending_teams.size})
            </Text>
          </TouchableOpacity>
        </View>

        <FlatList
          data={teamArray}
          renderItem={renderItem}
          ListEmptyComponent={null}
          keyExtractor={keyExtractor}
        />
        <View
          style={isMobile ? styles.newContainerMobile : styles.newContainer}
        >
          <JoinItem onPress={goToCreate} />
        </View>
      </View>
      {selectedTeam && (
        <View
          style={
            isMobile
              ? styles.sidebarMobile
              : isTablet
              ? styles.sidebarTablet
              : styles.sidebar
          }
        >
          {isTablet && (
            <TouchableOpacity
              style={styles.back}
              onPress={() => setSelectedTeam(null)}
            >
              <Back />
            </TouchableOpacity>
          )}
          <TeamActivity teamId={selectedTeam} />
        </View>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  containerMobile: {
    flex: 1,
  },
  content: {
    paddingTop: 30,
    flex: 2,
  },
  contentMobile: {
    paddingTop: 10,
    flex: 1,
  },
  contentMobileHidden: {
    display: 'none',
  },
  header: {
    paddingHorizontal: 36,
    marginBottom: 27,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerMobile: {
    paddingHorizontal: 15,
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    color: 'white',
  },
  titleMobile: {
    fontSize: 18,
    color: 'white',
  },
  newContainer: {
    paddingHorizontal: 36,
    paddingVertical: 26,
  },
  newContainerMobile: {
    padding: 15,
  },
  sidebar: {
    flex: 3,
  },
  sidebarMobile: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 5,
  },
  sidebarTablet: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 15,
  },
  pendingText: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: 'blue-secondary',
    fontWeight: 'bold',
  },
  pendingTextMobile: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'blue-secondary',
    fontWeight: 'bold',
  },
  pendingContainer: {
    paddingVertical: 6,
    width: 200,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 24,
  },
  pendingContainerMobile: {
    paddingVertical: 6,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 24,
  },
  back: {
    width: 30,
    height: 30,
    marginTop: 10,
    marginLeft: 5,
    alignItems: 'center',
  },
});

export default observer(Teams);
