import React, { useCallback } from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  Linking,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Hyperlink from 'react-native-hyperlink';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../o2x-store/src/stores';
import {
  MessageModel,
  MessageFileModel,
} from '../../../o2x-store/src/models/Message';
import config from '../../../o2x-store/src/config';

type Props = {
  message: MessageModel;
  file?: MessageFileModel;
  onPressMessage: () => void;
  showDate: boolean | undefined;
};

const { width } = Dimensions.get('window');

const Message: React.FC<Props> = ({
  message,
  onPressMessage,
  showDate,
  file,
}) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const onPressAttachment = (id: number) => {
    return Linking.openURL(
      `${config.urls.api}${config.urls.contactFiles}download/${id}`,
    );
  };
  return (
    <Layout style={styles.messageContainer}>
      <View
        style={[
          {
            flexDirection: 'row',
            marginBottom: '2%',
            maxWidth: width * 0.8,
          },
          message.receiver === message.sender ||
          message.sender === store.auth.user?.id
            ? { marginLeft: 'auto' }
            : { marginRight: 'auto' },
        ]}
      >
        {!(
          message.receiver === message.sender ||
          message.sender === store.auth.user?.id
        ) && (
          <Image
            style={
              {
                height: 30,
                width: 30,
                borderRadius: 15,
                marginRight: 8,
                alignSelf: 'flex-end',
              } as StyleProp<ImageStyle>
            }
            source={
              message.senderDetails.profileImage
                ? { uri: message.senderDetails.profileImage }
                : require('../../assets/images/user_placeholder.png')
            }
          />
        )}
        <View>
          <Text
            style={
              message.receiver === message.sender ||
              message.sender === store.auth.user?.id
                ? styles.senderDate
                : styles.receiverDate
            }
          >
            {message.senderDetails.fullName
              ? message.senderDetails.fullName
              : message.senderDetails.email}
          </Text>
          {file ? (
            <TouchableOpacity
              style={
                message.receiver === message.sender ||
                message.sender === store.auth.user?.id
                  ? styles.senderFile
                  : styles.receiverFile
              }
              onPress={async () => {
                await onPressAttachment(file.id);
              }}
            >
              <Text style={styles.fileName}>
                {file.name.length > 20
                  ? file.name.substring(0, 20) + '...'
                  : file.name}
              </Text>
              <Icon
                style={styles.downloadIcon}
                name="download-outline"
                fill="white"
              />
            </TouchableOpacity>
          ) : (
            <Hyperlink
              linkDefault={true}
              linkStyle={{
                padding: 0,
                fontSize: 16,
                color: '#203950',
              }}
            >
              <Text
                style={
                  message.receiver === message.sender ||
                  message.sender === store.auth.user?.id
                    ? styles.senderMessage
                    : styles.receiverMessage
                }
                onPress={onPressMessage}
              >
                {message.body}
              </Text>
            </Hyperlink>
          )}
        </View>
        {(message.receiver === message.sender ||
          message.sender === store.auth.user?.id) && (
          <Image
            style={
              {
                height: 30,
                width: 30,
                borderRadius: 15,
                marginLeft: 8,
                alignSelf: 'flex-end',
              } as StyleProp<ImageStyle>
            }
            source={
              message.senderDetails.profileImage
                ? { uri: message.senderDetails.profileImage }
                : require('../../assets/images/user_placeholder.png')
            }
          />
        )}
      </View>
      {showDate && message.created && (
        <Text
          style={
            message.receiver === message.sender ||
            message.sender === store.auth.user?.id
              ? [styles.senderDate, { marginRight: 38 }]
              : [styles.receiverDate, { marginLeft: 38 }]
          }
        >
          Sent {format(parseISO(message.created), 'MM/dd/yy hh:mm a')}
        </Text>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    paddingLeft: 60,
    paddingRight: 20,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  receiverMessage: {
    color: 'white',
    padding: 10,
    backgroundColor: 'olive',
    fontSize: 14,
    borderRadius: 4,
  },
  receiverFile: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 'auto',
    padding: 10,
    fontSize: 14,
    backgroundColor: 'olive',
    borderRadius: 4,
  },
  senderMessage: {
    marginLeft: 'auto',
    color: 'white',
    padding: 10,
    maxWidth: width * 0.65,
    backgroundColor: '#091C2D',
    fontSize: 14,
    borderRadius: 4,
  },
  senderFile: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginLeft: 'auto',
    padding: 10,
    maxWidth: width * 0.65,
    backgroundColor: '#091C2D',
    fontSize: 14,
    borderRadius: 4,
  },
  loading: {
    margin: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  conversationContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  messageContainer: {
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  receiverDate: {
    marginRight: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  senderDate: {
    marginLeft: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  send: {
    width: 24,
    height: 24,
  },
  input: {
    backgroundColor: '#0B1F35',
    color: 'white',
    maxHeight: 70,
    borderColor: 'transparent',
  },
  sendButton: {
    height: 40,
    width: 40,
    backgroundColor: 'olive',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  downloadIcon: {
    width: 20,
    height: 20,
    padding: 1,
    marginRight: 5,
    marginHorizontal: 5,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  fileName: {
    color: 'white',
    fontSize: 14,
  },
});

export default observer(Message);
