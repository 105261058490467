import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { range } from 'lodash';
import moment from 'moment';
import { BodyCompositionSummary } from 'o2x-store/src/models/BodyComposition';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  summary: BodyCompositionSummary[];
};

// !!!: Month in moment is zero indexed while month in Django is not.
const ProgressBarGraph: React.FC<Props> = ({ summary }) => {
  const styles = useStyleSheet(themedStyles);
  // Add 2 since it's zero indexed and we want to include this month.
  const end = moment().month() + 2;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const barHeight = isMobile ? 200 : 240;
  const [showSmmModal, setShowSmmModal] = useState(-1);
  const [showBfmModal, setShowBfmModal] = useState(-1);

  const barGraphValues = [0, 50, 100, 150, 200];
  const [modalInfo, setModalInfo] = useState<BodyCompositionSummary>();
  const toggleSmmModal = useCallback((month: number) => {
    const info = summary.find((i) => month === i.month);
    setModalInfo(info);
    setShowSmmModal(month);
    setShowBfmModal(-1);
  }, []);

  const toggleBfmModal = useCallback((month: number) => {
    const info = summary.find((i) => month === i.month);
    setModalInfo(info);
    setShowBfmModal(month);
    setShowSmmModal(-1);
  }, []);

  return (
    <Layout style={isMobile ? styles.chartMobile : styles.chart}>
      {/* <View style={styles.graphLabelContainer}>
        <View style={{ flexDirection: 'column-reverse' }}>
          {barGraphValues.map((value) => (
            <Text style={styles.label} key={value}>
              {value}
            </Text>
          ))}
        </View>

        <Layout style={styles.horizontalDivider} />
      </View> */}
      <Layout style={isMobile ? styles.containerMobile : styles.container}>
        {range(end - 7, end).map((month) => {
          const item = summary.find((i) => i.month === month);

          return (
            <Layout style={styles.item} key={month}>
              <Layout
                style={
                  isMobile ? styles.barContainerMobile : styles.barContainer
                }
              >
                {showSmmModal === month && (
                  <Layout>
                    <View style={styles.popUpContainer}>
                      <Text style={[styles.popUpText, { color: 'white' }]}>
                        SKELETAL MUSCLE MASS: {modalInfo?.averageSmm} lbs
                      </Text>
                      <Text style={styles.popUpText}>
                        TOTAL BODY WEIGHT: {modalInfo?.averageWeight} lbs
                      </Text>
                      <Text style={styles.popUpText}>
                        BODY MASS INDEX(kg/m2): {modalInfo?.averageBmi}
                      </Text>
                    </View>
                  </Layout>
                )}
                {showBfmModal === month && (
                  <Layout>
                    <View style={styles.popUpContainer}>
                      <Text style={[styles.popUpText, { color: 'white' }]}>
                        BODY FAT MASS: {modalInfo?.averageBfm} lbs
                      </Text>
                      <Text style={styles.popUpText}>
                        TOTAL BODY WEIGHT: {modalInfo?.averageWeight} lbs
                      </Text>
                      <Text style={styles.popUpText}>
                        BODY MASS INDEX(kg/m2): {modalInfo?.averageBmi}
                      </Text>
                    </View>
                  </Layout>
                )}
                <TouchableOpacity onPress={() => toggleSmmModal(month)}>
                  <Layout
                    style={[
                      isMobile ? styles.barMobile : styles.bar,
                      {
                        backgroundColor: '#4E7B89',
                        height:
                          barHeight * (item?.averageSmm / item?.averageWeight),
                      },
                    ]}
                  />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => toggleBfmModal(month)}>
                  <Layout
                    style={[
                      isMobile ? styles.barMobile : styles.bar,
                      {
                        backgroundColor: '#C64141',
                        height:
                          barHeight * (item?.averageBfm / item?.averageWeight),
                      },
                    ]}
                  />
                </TouchableOpacity>
              </Layout>
              <Layout style={styles.divider} />
              <Text
                style={[styles.label, isMobile && { marginTop: 14 }]}
                category="c2"
              >
                {/* Subtract 1 since moment is zero indexed. */}
                {moment()
                  .month(month - 1)
                  .format('MMM')}
              </Text>
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    backgroundColor: 'blue-secondary',
    width: 30,
    marginRight: 10,
  },
  barMobile: {
    backgroundColor: 'blue-secondary',
    width: 10,
    marginHorizontal: 2,
  },
  barContainer: {
    height: 240,
    width: 49,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  barContainerMobile: {
    height: 200,
    width: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  container: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: '95%',
  },
  containerMobile: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    backgroundColor: 'transparent',
  },
  divider: {
    height: 2.7,
    alignSelf: 'stretch',
    backgroundColor: '#C4C4C4',
  },
  item: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  label: {
    marginTop: 18,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#C4C4C4',
    flex: 1,
  },
  spacer: {
    backgroundColor: 'transparent',
  },
  chartMobile: {
    marginTop: 20,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  chart: {
    height: 310,
    marginTop: 40,
    backgroundColor: 'tranparent',
  },
  horizontalDivider: {
    width: 2.7,
    backgroundColor: '#C4C4C4',
    height: 242,
  },
  graphLabelContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  popUpContainer: {
    backgroundColor: 'black',
    padding: 10,
    position: 'absolute',
    bottom: 1,
    width: 200,
    right: 3,
  },
  popUpText: {
    fontSize: 10,
    lineHeight: 12,
    paddingVertical: 1,
    color: 'gray',
  },
});

export default ProgressBarGraph;
