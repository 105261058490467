import {
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import moment from 'moment';
import { useStore } from 'o2x-store/src/stores';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
type Props = {
  goBack: () => void;
  isEditingTimer: boolean;
  setTotalTime: (time: string) => void;
  value: string;
};

const hours = new Array(24).fill('').map((item, index) => {
  return index;
});

const minutes = new Array(60).fill('').map((item, index) => {
  return index;
});
const seconds = new Array(60).fill('').map((item, index) => {
  return index;
});

const TimerInput: React.FC<Props> = (props) => {
  const { goBack, isEditingTimer, setTotalTime, value } = props;
  const store = useStore();
  const duration = moment.utc(0 * 1000);
  const [selectedHour, setSelectedHour] = useState(
    parseInt(value.split(':')[0]) || 0,
  );
  const [selectedMinute, setSelectedMinute] = useState(
    parseInt(value.split(':')[1]) || 0,
  );
  const [selectedSeconds, setSelectedSeconds] = useState(
    parseInt(value.split(':')[2]) || 0,
  );
  const styles = useStyleSheet(themedStyles);

  const hourListRef = useRef<FlatList>(null);
  const minuteListRef = useRef<FlatList>(null);
  const secondListRef = useRef<FlatList>(null);
  const FILLERS = ['filler'];

  //   const goBack = useCallback(() => {
  //     navigation.goBack();
  //   }, []);

  useEffect(() => {
    hourListRef.current?.scrollToIndex({ index: selectedHour });
    minuteListRef.current?.scrollToIndex({ index: selectedMinute });
    secondListRef.current?.scrollToIndex({ index: selectedSeconds });
  }, [hourListRef, minuteListRef, secondListRef]);

  const keyExtractor = useCallback((item, index) => `${item} - ${index}`, []);

  const onPressHour = useCallback((item) => {
    setSelectedHour(item);
  }, []);

  const onPressMinute = useCallback((item) => {
    setSelectedMinute(item);
  }, []);

  const onPressSecond = useCallback((item) => {
    setSelectedSeconds(item);
  }, []);

  const renderHour = useCallback(
    ({ item }) => {
      if (item === 'filler') {
        return <View style={styles.item} />;
      }
      return (
        <TouchableOpacity
          // onPress={() => onPressHour(item)}
          style={[styles.item, selectedHour === item && styles.itemIsSelected]}
        >
          <Text
            style={[
              styles.itemText,
              selectedHour === item && { opacity: 1, fontSize: 22 },
            ]}
            category="c1"
          >
            {item}
          </Text>
        </TouchableOpacity>
      );
    },
    [selectedHour],
  );

  const renderMinute = useCallback(
    ({ item }) => {
      if (item === 'filler') {
        return <View style={styles.item} />;
      }
      return (
        <TouchableOpacity
          // onPress={() => onPressMinute(item)}
          style={[
            styles.item,
            selectedMinute === item && styles.itemIsSelected,
          ]}
        >
          <Text
            style={[
              styles.itemText,
              selectedMinute === item && { opacity: 1, fontSize: 22 },
            ]}
            category="c1"
          >
            {item}
          </Text>
        </TouchableOpacity>
      );
    },
    [selectedMinute],
  );

  const renderSecond = useCallback(
    ({ item }) => {
      if (item === 'filler') {
        return <View style={styles.item} />;
      }
      return (
        <TouchableOpacity
          // onPress={() => onPressSecond(item)}
          style={[
            styles.item,
            selectedSeconds === item && [
              styles.itemIsSelected,
              { marginVertical: 'auto' },
            ],
          ]}
        >
          <Text
            style={[
              styles.itemText,
              selectedSeconds === item && { opacity: 1, fontSize: 22 },
            ]}
            category="c1"
          >
            {item}
          </Text>
        </TouchableOpacity>
      );
    },
    [selectedSeconds],
  );

  const currentTime = useMemo(() => {
    const data = moment.utc(
      (selectedHour * 3600 + selectedMinute * 60 + selectedSeconds) * 1000,
    );
    return data.format('HH:mm:ss');
  }, [selectedHour, selectedMinute, selectedSeconds]);

  const onSubmit = useCallback(() => {
    let hours = '';
    let minutes = '';
    let seconds = '';
    if (selectedHour > 0) {
      hours = selectedHour.toString();
      minutes = selectedMinute.toString();
      seconds = selectedSeconds.toString();
      if (selectedHour < 10) {
        hours = `0${selectedHour}`;
      }
      if (selectedMinute >= 0 && selectedMinute < 10) {
        minutes = `0${selectedMinute}`;
      }
      if (selectedSeconds >= 0 && selectedSeconds < 10) {
        seconds = `0${selectedSeconds}`;
      }
      setTotalTime(`${hours}:${minutes}:${seconds}`);
    } else if (!selectedHour && selectedMinute > 0) {
      minutes = selectedMinute.toString();
      seconds = selectedSeconds.toString();
      if (selectedMinute < 10) {
        minutes = `0${selectedMinute}`;
      }
      if (selectedSeconds >= 0 && selectedSeconds < 10) {
        seconds = `0${selectedSeconds}`;
      }
      setTotalTime(`00:${minutes}:${seconds}`);
    } else {
      seconds = selectedSeconds.toString();
      if (selectedSeconds >= 0 && selectedSeconds < 10) {
        seconds = `0${selectedSeconds}`;
      }
      setTotalTime(`00:00:${seconds}`);
    }
    goBack();
  }, [selectedHour, selectedMinute, selectedSeconds]);

  const onScrollEndHour = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    // setTimeout(() => {
    //   if (seconds.length > 0) {
    //     hourListRef.current?.scrollToIndex({
    //       index: currentIndex,
    //       animated: true,
    //     });
    //   }
    // }, 100);
    setSelectedHour(currentIndex);
  }, []);

  const onScrollEndMinute = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    // setTimeout(() => {
    //   if (seconds.length > 0) {
    //     minuteListRef.current?.scrollToIndex({
    //       index: currentIndex,
    //       animated: true,
    //     });
    //   }
    // }, 100);
    setSelectedMinute(currentIndex);
  }, []);

  const onScrollEndSecond = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    // setTimeout(() => {
    //   if (seconds.length > 0) {
    //     secondListRef.current?.scrollToIndex({
    //       index: currentIndex,
    //       animated: true,
    //     });
    //   }
    // }, 100);
    setSelectedSeconds(currentIndex);
  }, []);

  const getItemLayout = useCallback(
    (_data, index) => ({
      length: 44,
      offset: 44 * index,
      index,
    }),
    [],
  );

  return (
    <Modal visible={isEditingTimer} style={styles.container}>
      <Layout style={styles.modal}>
        <View style={styles.content}>
          <Text style={styles.timeText}>{currentTime}</Text>
        </View>
        <View style={styles.pickerContainer}>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Hr</Text>
            <FlatList
              ref={hourListRef}
              data={[...FILLERS, ...hours, ...FILLERS]}
              renderItem={renderHour}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                hourListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (hours.length > 0) {
                    hourListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
              onScroll={onScrollEndHour}
              initialScrollIndex={selectedHour}
              getItemLayout={getItemLayout}
              decelerationRate="fast"
            />
          </View>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Min</Text>
            <FlatList
              ref={minuteListRef}
              data={[...FILLERS, ...minutes, ...FILLERS]}
              renderItem={renderMinute}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                minuteListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (minutes.length > 0) {
                    minuteListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
              onScroll={onScrollEndMinute}
              initialScrollIndex={selectedMinute}
              getItemLayout={getItemLayout}
              decelerationRate="fast"
            />
          </View>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Sec</Text>
            <FlatList
              ref={secondListRef}
              data={[...FILLERS, ...seconds, ...FILLERS]}
              renderItem={renderSecond}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                secondListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (seconds.length > 0) {
                    secondListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
              onScroll={onScrollEndSecond}
              initialScrollIndex={selectedSeconds}
              getItemLayout={getItemLayout}
              decelerationRate="fast"
            />
          </View>
        </View>
        <View style={styles.actions}>
          <TouchableOpacity style={styles.action} onPress={goBack}>
            <Text style={styles.actionText} category="c1">
              Cancel
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.action, styles.submitButton]}
            onPress={onSubmit}
          >
            <Text style={styles.actionText} category="c1">
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  action: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    marginTop: 12,
  },
  actionText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  content: {
    padding: 24,
  },
  scroll: {
    height: 120,
  },
  header: {
    marginBottom: 30,
  },
  modal: {
    width: '80%',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#051023',
  },
  item: {
    borderColor: '#051023',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    // flex: 1,
  },
  itemIsSelected: {
    backgroundColor: 'olive',
  },
  categoryText: {
    fontWeight: '600',
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
    color: '#8F9BB3',
  },
  timeText: {
    color: 'white',
    fontSize: 15,
    lineHeight: 20,
    paddingHorizontal: 16,
    padding: 10,
  },
  itemText: {
    fontWeight: '600',
    fontSize: 12,
    lineHeight: 24,
    textAlign: 'center',
    color: 'white',
    opacity: 0.4,
  },
  submitButton: {
    backgroundColor: 'olive',
  },
  pickerContainer: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  durationListContainer: {
    flex: 1,
  },
});

export default TimerInput;
