import { Audio, AVPlaybackStatus, Video } from 'expo-av';
import { action, computed, observable } from 'mobx';
import {
  getRoot,
  getSnapshot,
  model,
  Model,
  modelFlow,
  _async,
  _await,
} from 'mobx-keystone';
import moment, { Moment } from 'moment';
import ThriveMediaFile from 'o2x-store/src/models/ThriveMediaFile';
import * as analytics from 'o2x-store/src/services/analytics';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import { InteractionManager, Platform } from 'react-native';
import { NativeStore } from '../stores';

@model('o2x-native/ThriveStart')
export default class ThriveStart extends Model({}) {
  timer: NodeJS.Timeout | null = null;
  timerUpdated: number = 0;
  audioSound: Audio.Sound | null = null;
  dingSound: Audio.Sound | null = null;
  video: Video | null = null;
  audioId: number | null = null; // The Audio key/identifier that is currently playing.
  activityType: THRIVE_ACTIVITY_TYPE | null = null;

  @observable
  countDown: number = 60;

  @observable
  initialCountDown: number = 60;

  @observable
  defaultTime: number = 60;

  @observable
  guidedTime: number = 60;

  @observable
  duration: number | undefined;

  @observable
  position: number | undefined;

  @observable
  audioObject: ThriveMediaFile | null = null;

  @observable
  videoObject: ThriveMediaFile | null = null;

  @observable
  playing: boolean = false;

  @observable
  paused: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  volume: number = 1;

  @observable
  playStart: Moment | undefined;

  @observable
  done: boolean = false;

  @computed
  get guided(): boolean {
    return !!this.audioObject?.isGuided;
  }

  @modelFlow
  load = _async(function* (this: ThriveStart, useDownloaded?: boolean) {
    if (this.loading) {
      return;
    }
    if (this.audioSound && this.audioId !== this.audioObject!.id) {
      yield* _await(this.stop());
    }
    this.loading = true;
    if (this.audioObject) {
      const rootStore = getRoot<NativeStore>(this);
      const { mediaStorage, downloads } = rootStore;
      const mediaFile = useDownloaded
        ? downloads.getOrCreateSecuredMediaFile(this.audioObject)
        : mediaStorage.getOrCreateMediaFile(this.audioObject, 'content');
      if (!mediaFile) {
        console.warn(
          '[DEBUG] failed to load audio',
          getSnapshot(this.audioObject),
          getSnapshot(mediaFile),
        );
        this.loading = false;
        return;
      }

      let localUri: string | undefined;
      if (Platform.OS !== 'web') {
        localUri = yield* _await(mediaFile.downloadMedia());
      } else {
        localUri = mediaFile.uri;
      }

      if (!localUri) {
        console.warn(
          '[DEBUG] failed to download audio',
          localUri,
          getSnapshot(this.audioObject),
          getSnapshot(mediaFile),
        );
        this.loading = false;
        return;
      }

      try {
        const { sound, status } = yield* _await(
          Audio.Sound.createAsync(
            { uri: localUri },
            {
              isLooping: true,
              volume: this.volume,
            },
          ),
        );
        this.audioSound = sound;
        this.audioId = this.audioObject.id;
        if (this.audioObject.isGuided && status.isLoaded) {
          let duration;
          if (status.durationMillis) {
            duration = status.durationMillis / 1000;
          } else if (Platform.OS === 'web') {
            const audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            const audioResponse = yield* _await(fetch(localUri));
            if (audioResponse.ok) {
              const audioArrayBuffer = yield* _await(
                audioResponse.arrayBuffer(),
              );
              const decodedData = yield* _await(
                audioContext.decodeAudioData(audioArrayBuffer),
              );
              duration = decodedData.duration;
            }
          }
          if (duration) {
            this.guidedTime = duration;
            this.countDown = this.guidedTime;
            this.initialCountDown = this.countDown;
          }
        }
      } catch (error) {
        console.warn(
          '[DEBUG] failed to play audioObject',
          error,
          getSnapshot(this.audioObject),
        );
        this.loading = false;
        return;
      }
    }

    this.loading = false;
  });

  @modelFlow
  unload = _async(function* (this: ThriveStart) {
    try {
      if (this.audioSound) {
        yield* _await(this.audioSound.stopAsync());
        yield* _await(this.audioSound.unloadAsync());
      }

      if (this.dingSound) {
        yield* _await(this.dingSound.stopAsync());
      }
      this.audioSound = null;
      this.audioId = null;
      this.countDown = this.defaultTime;
      this.initialCountDown = this.countDown;
    } catch (e) {
      console.log('[DEBUG]');
    }
  });

  @modelFlow
  stop = _async(function* (this: ThriveStart) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.stopTimer();
    yield* _await(this.unload());
    if (this.audioObject?.isGuided) {
      this.countDown = this.guidedTime;
      this.initialCountDown = this.countDown;
    }

    this.playing = false;
    this.loading = false;
  });

  @modelFlow
  play = _async(function* (this: ThriveStart, useDownloaded?: boolean) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    if (!this.audioSound && this.audioObject) {
      this.loading = false;
      yield* _await(this.load(useDownloaded));
      this.loading = true;
    }
    if (this.audioSound) {
      try {
        yield* _await(this.audioSound.playAsync());
      } catch (error) {
        console.warn(
          '[DEBUG] failed to play audioObject',
          error,
          getSnapshot(this.audioObject),
        );
        this.loading = false;
        return;
      }
    }
    this.loading = false;
    this.startTimer(useDownloaded);
    this.playing = true;
    this.paused = false;
    this.done = false;

    if (this.activityType && !useDownloaded) {
      analytics.logThriveActivityStart(
        this.activityType,
        this.audioObject || undefined,
        this.videoObject || undefined,
      );
    }
  });

  @modelFlow
  pause = _async(function* (this: ThriveStart) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.stopTimer();
    this.playing = false;
    this.paused = true;

    if (this.audioSound) {
      yield* _await(this.audioSound.pauseAsync());
    }

    if (this.dingSound) {
      yield* _await(this.dingSound.setIsMutedAsync(true));
    }

    this.loading = false;
  });

  @action
  onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      this.duration = status.durationMillis;
      this.position = status.positionMillis;
    }
  };

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = null;
  };

  @action
  startTimer = (useDownloaded?: boolean) => {
    if (this.timer) {
      return;
    }
    this.playStart = moment();
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timerUpdated = Date.now();
    this.timer = setInterval(() => {
      while (Date.now() - this.timerUpdated > 1000) {
        this.timerUpdated += 1000;
        InteractionManager.runAfterInteractions(() => {
          this.updateTime(useDownloaded);
        });
      }
    }, 100);
  };

  @action
  updateCountDownFromStart() {
    if (this.playStart) {
      const diff = moment().diff(this.playStart, 'seconds', true);
      this.countDown = this.initialCountDown - diff;
    }
  }

  @action
  updateTime(useDownloaded?: boolean) {
    this.countDown -= 1;

    if (this.countDown <= 0) {
      this.done = true;
      this.stop();
      if (this.activityType && !useDownloaded) {
        analytics.logThriveActivityComplete(
          this.activityType,
          this.audioObject || undefined,
          this.videoObject || undefined,
        );
      }
    }
  }

  @action
  setDefaultTime = (seconds: number) => {
    this.defaultTime = seconds;
    this.countDown = seconds;
    this.initialCountDown = this.countDown;
  };

  @action
  setTime = (seconds: number) => {
    this.countDown = seconds;
    this.initialCountDown = this.countDown;
  };

  @action
  addTime = (seconds: number) => {
    this.countDown += seconds;
    this.initialCountDown = this.countDown;
  };

  @action
  setAudio = async (
    audioObject: ThriveMediaFile | null,
    useDownloaded?: boolean,
  ) => {
    try {
      const didChange = audioObject?.id !== this.audioObject?.id;
      if (!didChange) return;

      this.audioObject = audioObject;

      // If audio changed, unload previous audio.
      await this.unload();

      // If audio is guided, get time from audio file.
      if (this.audioObject?.isGuided) {
        await this.load(useDownloaded);
      } else {
        this.countDown = this.defaultTime;
        this.initialCountDown = this.countDown;
      }

      if (this.playing) {
        this.play(useDownloaded);
      }
    } catch {
      console.log('[DEBUG] set audio');
    }
  };

  @action
  setVideo = (videoObject: ThriveMediaFile | null) => {
    const didChange = videoObject?.id !== this.videoObject?.id;
    if (!didChange) return;
    this.videoObject = videoObject;
  };

  @action
  setVolume = (volume: number) => {
    this.volume = volume;
    if (this.audioSound) {
      this.audioSound.setVolumeAsync(volume);
    }
    if (this.dingSound) {
      this.dingSound.setVolumeAsync(volume);
    }
  };

  @action
  setActivityType = (type: THRIVE_ACTIVITY_TYPE) => {
    this.activityType = type;
  };

  setPosition = async (position: number) => {
    if (this.video) {
      await this.video.setPositionAsync(position);
    }
    if (this.audioSound) {
      await this.audioSound.setPositionAsync(position);
    }
    await this.play();
  };

  playDing = async () => {
    if (!this.dingSound) {
      try {
        const { sound } = await Audio.Sound.createAsync(
          require('../assets/audios/inhale.mp3'),
          {
            shouldPlay: true,
            volume: this.volume,
          },
        );
        this.dingSound = sound;
        console.log('CREATED? DING');
      } catch (error) {
        console.warn('[DEBUG] failed to play dingSound', error);
        return;
      }
    } else {
      if (this.playing) {
        await this.dingSound.setIsMutedAsync(false);
        await this.dingSound.replayAsync();
      }
    }
  };

  playDong = async () => {
    if (!this.dingSound) {
      try {
        const { sound } = await Audio.Sound.createAsync(
          require('../assets/audios/exhale.mp3'),
          {
            shouldPlay: true,
            volume: this.volume,
          },
        );
        this.dingSound = sound;
        console.log('CREATED? DONG');
      } catch (error) {
        console.warn('[DEBUG] failed to play dongSound', error);
        return;
      }
    } else {
      if (this.playing) {
        await this.dingSound.setIsMutedAsync(false);
        await this.dingSound.replayAsync();
      }
    }
  };

  playDingAndDong = async () => {
    if (!this.dingSound) {
      try {
        const { sound } = await Audio.Sound.createAsync(
          require('../assets/audios/inhale-exhale-2.mp3'),
          {
            shouldPlay: true,
            volume: this.volume,
            isLooping: true,
          },
        );
        this.dingSound = sound;
        console.log('CREATED? DONG');
      } catch (error) {
        console.warn('[DEBUG] failed to play dongSound', error);
        return;
      }
    } else {
      if (this.playing) {
        await this.dingSound.setIsMutedAsync(false);
        await this.dingSound.replayAsync();
      }
    }
  };
}
