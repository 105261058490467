import Humanize from 'humanize-plus';
import { filter, uniq } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import Url from 'url-parse';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import { getTaskColor, TASK_TYPE } from '../utils/tasks';
import { RatingsBreakdown } from './Program';

@model('o2x-store/Recipe')
export default class Recipe extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  slug: prop<string>(''),
  servings: prop<number>(0),
  ingredients: prop<string>(''),
  directions: prop<string>(''),
  rating: prop<number>(0),
  userRating: prop<number>(0),
  image: prop<string>(''),
  imageBanner: prop<string>(''),
  imageCard: prop<string>(''),
  imageThumbnail: prop<string>(''),
  video: prop<string | null>(null),
  videoLink: prop<string | null>(null),
  saveList: prop<Array<number>>(() => new Array<number>()),
  viewUrl: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(data: ModelInstanceCreationData<Recipe>) {
    Object.assign(this, data);
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @computed
  get subtitle(): string {
    if (this.servings) {
      return `${this.servings} ${Humanize.pluralize(this.servings, 'serving')}`;
    }
    return '';
  }

  @computed
  get type(): TASK_TYPE {
    return TASK_TYPE.EAT;
  }

  @computed
  get color(): string {
    return getTaskColor(TASK_TYPE.EAT);
  }

  @computed
  get ratingsBreakdown(): RatingsBreakdown | undefined {
    const rootStore = getRoot<RootStore>(this);
    if (rootStore.getProgramRatings) {
      return rootStore.getProgramRatings(this.type, this.id);
    }
  }

  @modelFlow
  fetchRatings = _async(function* (this: Recipe) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchRecipesRatings(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching ratings', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  postRate = _async(function* (this: Recipe, rate: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.postRecipesRate(rootStore.auth.token, this.id, rate),
      ));
    } catch (error) {
      console.warn('[DEBUG] error post rate', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;
    this.userRating = rate;

    this.loading = false;
    return getSuccess();
  });

  @computed
  get videoId(): string | undefined {
    // Assumes that the videoLink is youtube.
    const url = new Url(this.videoLink, true);
    return url.query.v;
  }
}
