import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { SCORE_TYPE } from 'o2x-store/src/utils/scores';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import DangerBadge from '../../assets/images/danger-badge.svg';
import WarningBadge from '../../assets/images/warning-badge.svg';

type Props = {
  type: SCORE_TYPE | null | undefined;
  size?: number;
  style?: StyleProp<ViewStyle>;
};

const ScoreBadge: React.FC<Props> = (props) => {
  const { style, type, size = 16 } = props;
  const styles = useStyleSheet(themedStyles);
  return (
    <View style={[styles.container, style]}>
      {/*type === SCORE_TYPE.GOOD && <GoodBadge />*/}
      {type === SCORE_TYPE.OKAY && (
        <WarningBadge width={size} height={size} viewBox="0 0 25 25" />
      )}
      {type === SCORE_TYPE.IMPROVE && (
        <DangerBadge width={size} height={size} viewBox="0 0 25 25" />
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
});

export default ScoreBadge;
