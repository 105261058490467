import { observable } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { ASSESSMENT_TYPE } from './Assessment';
import { AssessmentSubmissionItem } from './AssessmentSubmissionItem';

export type PhysicalAssessmentSubmissionResult = {
  id: number;
  unit: string;
  value: number;
  savedScore: number;
  title: string;
  ranking: string;
};

@model('o2x-store/PhysicalAssessmentSubmission')
export default class PhysicalAssessmentSubmission extends Model({
  id: prop<number>(),
  user: prop<number>(),
  assessment: prop<number>(),
  assessmentName: prop<string>(),
  coverImageThumbnail: prop<string | null>(null),
  created: prop<string>(),
  modified: prop<string>(),
  score: prop<number | null>(),
  badge: prop<string>(),
  items: prop<Array<AssessmentSubmissionItem>>(),
  result: prop<PhysicalAssessmentSubmissionResult[]>(() => []),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;
  type = ASSESSMENT_TYPE.PHYSICAL;

  @modelAction
  update(data: ModelInstanceCreationData<PhysicalAssessmentSubmission>) {
    Object.assign(this, data);
  }
}
