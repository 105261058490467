import * as Google from 'expo-google-app-auth';
import config from '../config';

class Social {
  signInWithFacebook = async () => {
    // iOS app crashes on FBSDKKeychainStore
    // try {
    //   await Facebook.initializeAsync('879126382606349', 'o2x-test');
    //   const result = await Facebook.logInWithReadPermissionsAsync({
    //     permissions: ['public_profile', 'email'],
    //   });
    //   if (result.type === 'cancel') {
    //     return { ok: false, errors: null };
    //   }
    //   return { ok: true, token: result.token };
    // } catch (error) {
    //   return { ok: false, errors: { detail: error.message } };
    // }
    return {
      ok: false,
      errors: { detail: 'Sign in with Facebook not currently supported' },
    };
  };

  signInWithGoogle = async () => {
    try {
      const result = await Google.logInAsync({
        ...config.googleSignInConfig,
        scopes: ['profile', 'email'],
      });
      if (result.type === 'cancel') {
        return { ok: false, errors: null };
      }

      return { ok: true, token: result.accessToken };
    } catch (error) {
      return { ok: false, errors: { detail: error.message } };
    }
  };
}

export default new Social();
