import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import { Assessment, ASSESSMENT_TYPE } from '../models/Assessment';
import { AssessmentSubmission } from '../models/AssessmentSubmission';
import BodyComposition, {
  BodyCompositionSummary,
} from '../models/BodyComposition';
import FormAssessment from '../models/FormAssesment';
import FormAssessmentItem from '../models/FormAssessmentItem';
import FormAssessmentSubmission from '../models/FormAssessmentSubmission';
import PhysicalAssessment from '../models/PhysicalAssessment';
import PhysicalAssessmentItem from '../models/PhysicalAssessmentItem';
import PhysicalAssessmentSubmission from '../models/PhysicalAssessmentSubmission';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/AssessmentStore')
export default class AssessmentStore extends Model({
  formAssessments: prop_mapObject(() => new Map<string, FormAssessment>()),
  formAssessmentItems: prop_mapObject(
    () => new Map<string, FormAssessmentItem>(),
  ),
  formAssessmentSubmissions: prop_mapObject(
    () => new Map<string, FormAssessmentSubmission>(),
  ),
  physicalAssessments: prop_mapObject(
    () => new Map<string, PhysicalAssessment>(),
  ),
  physicalAssessmentItems: prop_mapObject(
    () => new Map<string, PhysicalAssessmentItem>(),
  ),
  physicalAssessmentSubmissions: prop_mapObject(
    () => new Map<string, PhysicalAssessmentSubmission>(),
  ),
  bodyComposition: prop_mapObject(() => new Map<string, BodyComposition>()),
}) {
  @observable
  loading = false;

  @observable
  recentBodyComposition: string = '';

  @modelAction
  createOrUpdateFormAssessment(
    data: ModelInstanceCreationData<FormAssessment>,
  ) {
    const id = `${data.id}`;
    if (this.formAssessments.has(id)) {
      this.formAssessments.get(id)!.update(data);
    } else {
      const formAssesment = new FormAssessment(data);
      this.formAssessments.set(id, formAssesment);
      formAssesment.update(data);
    }
  }

  @modelAction
  createOrUpdateFormAssessmentItem(
    data: ModelInstanceCreationData<FormAssessmentItem>,
  ) {
    const id = `${data.id}`;
    if (this.formAssessmentItems.has(id)) {
      this.formAssessmentItems.get(id)!.update(data);
    } else {
      const formAssessmentItem = new FormAssessmentItem(data);
      this.formAssessmentItems.set(id, formAssessmentItem);
      formAssessmentItem.update(data);
    }
  }

  @modelAction
  filterFormAssessmentItemSection(id: number, section: string) {
    let data: number[] = [];
    this.formAssessmentItems.forEach((value) => {
      if (value.section === section && value.assessment === id) {
        data.push(value.id);
      }
    });
    return data;
  }

  @modelAction
  createOrUpdateFormAssessmentSubmission(
    data: ModelInstanceCreationData<FormAssessmentSubmission>,
  ) {
    const id = `${data.id}`;
    if (this.formAssessmentSubmissions.has(id)) {
      this.formAssessmentSubmissions.get(id)!.update(data);
    } else {
      const formAssessmentSubmission = new FormAssessmentSubmission(data);
      this.formAssessmentSubmissions.set(id, formAssessmentSubmission);
      formAssessmentSubmission.update(data);
    }
  }

  @modelAction
  removeFormAssessmentSubmission(id: string | number) {
    const assessmentId = `${id}`;
    if (this.formAssessmentSubmissions.has(assessmentId)) {
      this.formAssessmentSubmissions.delete(assessmentId);
    }
  }

  @modelAction
  createOrUpdatePhysicalAssessment(
    data: ModelInstanceCreationData<PhysicalAssessment>,
  ) {
    const id = `${data.id}`;
    if (this.physicalAssessments.has(id)) {
      this.physicalAssessments.get(id)!.update(data);
    } else {
      const physicalAssessment = new PhysicalAssessment(data);
      this.physicalAssessments.set(id, physicalAssessment);
      physicalAssessment.update(data);
    }
  }

  @modelAction
  createOrUpdatePhysicalAssessmentItem(
    data: ModelInstanceCreationData<PhysicalAssessmentItem>,
  ) {
    const id = `${data.id}`;
    if (this.physicalAssessmentItems.has(id)) {
      this.physicalAssessmentItems.get(id)!.update(data);
    } else {
      const physicalAssessmentItem = new PhysicalAssessmentItem(data);
      this.physicalAssessmentItems.set(id, physicalAssessmentItem);
      physicalAssessmentItem.update(data);
    }
  }

  @modelAction
  createOrUpdatePhysicalAssessmentSubmission(
    data: ModelInstanceCreationData<PhysicalAssessmentSubmission>,
  ) {
    const id = `${data.id}`;
    if (this.physicalAssessmentSubmissions.has(id)) {
      this.physicalAssessmentSubmissions.get(id)!.update(data);
    } else {
      const physicalAssessmentSubmission = new PhysicalAssessmentSubmission(
        data,
      );
      this.physicalAssessmentSubmissions.set(id, physicalAssessmentSubmission);
      physicalAssessmentSubmission.update(data);
    }
  }

  @modelAction
  removePhysicalAssessmentSubmission(id: string | number) {
    const assessmentId = `${id}`;
    if (this.physicalAssessmentSubmissions.has(assessmentId)) {
      this.physicalAssessmentSubmissions.delete(assessmentId);
    }
  }

  @modelAction
  createOrUpdateBodyComposition(
    data: ModelInstanceCreationData<BodyComposition>,
  ) {
    const id = `${data.id}`;
    if (this.bodyComposition.has(id)) {
      this.bodyComposition.get(id)!.update(data);
    } else {
      const bodyComposition = new BodyComposition(data);
      this.bodyComposition.set(id, bodyComposition);
      bodyComposition.update(data);
    }
  }

  @modelFlow
  fetchFormAssessments = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<FormAssessment>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchFormAssessments(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessments', error);
      return getError(error);
    }

    this.formAssessments.clear();
    results.forEach((data) => this.createOrUpdateFormAssessment(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormAssessmentByIds = _async(function* (
    this: AssessmentStore,
    ids: Array<string>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<FormAssessment>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchFormAssessmentByIds(rootStore.auth.token, ids.toString()),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessment by ids', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateFormAssessment(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormAssessmentItems = _async(function* (
    this: AssessmentStore,
    assessmentId: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<FormAssessmentItem>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchFormAssessmentItems(rootStore.auth.token, assessmentId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessment items', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateFormAssessmentItem(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormAssessmentSubmissions = _async(function* (
    this: AssessmentStore,
    assessmentId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<FormAssessmentSubmission>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchFormAssessmentSubmissions(rootStore.auth.token, assessmentId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessment submissions', error);
      return getError(error);
    }

    results.forEach((data) =>
      this.createOrUpdateFormAssessmentSubmission(data),
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormAssessmentSubmission = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<FormAssessmentSubmission>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFormAssessmentSubmission(rootStore.auth.token, id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessment submission', error);
      return getError(error);
    }

    this.createOrUpdateFormAssessmentSubmission(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchFormAssessmentSubmissionResult = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFormAssessmentSubmissionResult(rootStore.auth.token, id),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching form assessment submission result',
        error,
      );
      return getError(error);
    }
    this.createOrUpdateFormAssessmentSubmission(entities);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteFormAssessmentSubmission = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(
        api.deleteFormAssessmentSubmission(rootStore.auth.token, id),
      );
    } catch (error) {
      console.warn('[DEBUG] error deleting form assessment submission', error);
      return getError(error);
    }

    if (!('errors' in response)) {
      this.removeFormAssessmentSubmission(id);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessments = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<PhysicalAssessment>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchPhysicalAssessments(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching physical assessments', error);
      return getError(error);
    }

    this.physicalAssessments.clear();
    results.forEach((data) => this.createOrUpdatePhysicalAssessment(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessmentByIds = _async(function* (
    this: AssessmentStore,
    ids: Array<string>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<PhysicalAssessment>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchPhysicalAssessmentByIds(rootStore.auth.token, ids.toString()),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching physical assessment by ids', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePhysicalAssessment(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessmentItems = _async(function* (
    this: AssessmentStore,
    assessmentId: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<PhysicalAssessmentItem>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchPhysicalAssessmentItems(rootStore.auth.token, assessmentId),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching physical assessment items', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePhysicalAssessmentItem(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessmentSubmissions = _async(function* (
    this: AssessmentStore,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<PhysicalAssessmentSubmission>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchPhysicalAssessmentSubmissions(rootStore.auth.token),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching physical assessment submissions',
        error,
      );
      return getError(error);
    }

    results.forEach((data) =>
      this.createOrUpdatePhysicalAssessmentSubmission(data),
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessmentSubmission = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<PhysicalAssessmentSubmission>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchPhysicalAssessmentSubmission(rootStore.auth.token, id),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching physical assessment submission',
        error,
      );
      return getError(error);
    }
    this.createOrUpdatePhysicalAssessmentSubmission(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPhysicalAssessmentScoringDisplay = _async(function* (
    this: AssessmentStore,
    assessmentId: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      ({ response } = yield* _await(
        api.fetchPhysicalAssessmentScoringDisplay(
          rootStore.auth.token,
          assessmentId,
        ),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching physical assessment scoring display',
        error,
      );
      return getError(error);
    }

    this.loading = false;
    return response;
  });

  @modelFlow
  deletePhysicalAssessmentSubmission = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      response = yield* _await(
        api.deletePhysicalAssessmentSubmission(rootStore.auth.token, id),
      );
    } catch (error) {
      console.warn(
        '[DEBUG] error deleting physical assessment submission',
        error,
      );
      return getError(error);
    }

    if (!('errors' in response)) {
      this.removePhysicalAssessmentSubmission(id);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchBodyComposition = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<BodyComposition>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchBodyComposition(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching body composition', error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateBodyComposition(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchBodyCompositionSummary = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: [BodyCompositionSummary];

    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchBodyCompositionSummary(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching body composition', error);
      return getError(error);
    }

    this.loading = false;
    console.log(entities);
    return getSuccess(entities);
  });

  @computed
  get assessments(): Assessment[] {
    return [
      ...Array.from(this.formAssessments.values()),
      ...Array.from(this.physicalAssessments.values()),
    ];
  }

  getAssessment = (id: number, type: ASSESSMENT_TYPE) => {
    if (type === ASSESSMENT_TYPE.FORM) {
      return this.formAssessments.get(`${id}`);
    } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
      return this.physicalAssessments.get(`${id}`);
    }

    return;
  };

  @computed
  get assessmentSubmissions(): AssessmentSubmission[] {
    return [
      ...Array.from(this.formAssessmentSubmissions.values()),
      ...Array.from(this.physicalAssessmentSubmissions.values()),
    ];
  }

  getSubmission = (id: number | null | undefined, type: ASSESSMENT_TYPE) => {
    if (!id) {
      return;
    }

    if (type === ASSESSMENT_TYPE.FORM) {
      return this.formAssessmentSubmissions.get(`${id}`);
    } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
      return this.physicalAssessmentSubmissions.get(`${id}`);
    }

    return;
  };
}
