import { useFocusEffect } from '@react-navigation/native';
import {
  CheckBox,
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as DocumentPicker from 'expo-document-picker';
import { observer } from 'mobx-react-lite';
import { DashboardFile, DashboardModel } from 'o2x-store/src/models/Dashboard';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Animated,
  Dimensions,
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMediaQuery } from 'react-responsive';
import { getErrors } from '../../../utils/errors';
import DashboardContent from './DashboardContent';
import QuillToolbar, { modules } from './QuillToolbar';

type Props = {};

const { width, height } = Dimensions.get('window');

const Dashboard: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const [dashboard, setDashboard] = useState<Array<DashboardModel>>();
  const store = useStore();
  const { register, setValue, handleSubmit } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [id, setId] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextRequest, setNextRequest] = useState('');
  const [showInput, setShowInput] = useState(false);

  const [resultInfo, setResultInfo] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(0);
  const animation = useRef(new Animated.Value(0)).current;

  const [onNotification, setOnNotification] = useState(true);

  const [members, setMembers] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentData, setAttachmentData] = useState<DashboardFile[]>([]);
  const [loadingSubmission, setLoadingSubmission] = useState(false);

  var Align = Quill.import('attributors/style/align');
  var Icons = Quill.import('ui/icons');
  Icons.align['left'] = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
  Align.whitelist = ['left', 'center', 'right', 'justify']; // add explicit 'left' option
  Quill.register(Align, true);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const fetchData = await store.dashboard.fetchDashboard(
          store.auth.user?.id,
        );
        const fetchOrganizations =
          await store.fteLibrary.fetchFTEOrganization();
        let orgMembers: number[] = [];
        fetchOrganizations.extra?.forEach((organization) => {
          organization.users.forEach((user) => {
            if (user.id !== store.auth.user.id) orgMembers.push(user.id);
          });
        });
        setMembers(orgMembers.toString());
        setDashboard(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setBody('');
        setTitle('');
        setAttachments([]);
        setLoading(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      register({ name: 'title' }, { required: true });
      register({ name: 'body' }, { required: false });
      register({ name: 'id' }, { required: true });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('body', body);
    setValue('title', title);
    setValue('id', id);
  }, [body, title, id]);

  useEffect(() => {
    var customButton = document.getElementById('attach');
    var newButton = customButton?.cloneNode(true);
    newButton?.addEventListener('touchstart', onAttach);
    newButton?.addEventListener('click', onAttach);
    customButton?.parentNode?.replaceChild(newButton, customButton);
  }, [attachments, showInput]);

  const onSubmit = useCallback(
    async (data) => {
      setLoadingSubmission(true);
      const result = await store.dashboard.createDashboardContent(
        data,
        attachments,
      );
      if (result.ok) {
        const fetchData = await store.dashboard.fetchDashboard(
          store.auth.user?.id,
        );
        setDashboard(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setBody('');
        setTitle('');
        setAttachments([]);
        if (onNotification) {
          await store.fteEvent.sendNotification({
            recipientUsers: members.toString(),
            title: 'You have a new dashboard post from your On-site Specialist',
            body: 'New Dashboard Post',
            extra: {
              type: 'New Dashboard Post',
              fte: {
                id: store.auth.user.id,
                email: store.auth.user?.email,
                fullName: store.auth.user?.fullName,
                firstName: store.auth.user?.firstName,
                lastName: store.auth.user?.lastName,
                o2xId: store.auth.user?.o2XId,
                organizations: store.auth.user?.organization,
                profileImage: store.auth.user?.profileImage,
              },
            },
          });
        }
      } else {
        console.log(getErrors(result.errors));
      }
      setHasError(!result.ok);
      setResultInfo(result.ok ? 'ADDED SUCCESSFULLY' : 'ADDING FAILED');
      setLoadingSubmission(false);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: true,
      }).start(() => animation.setValue(0));
    },
    [members, onNotification, attachments],
  );
  const onSubmitWrapped = handleSubmit(onSubmit);

  const onDelete = useCallback((id: number) => {
    setBody('');
    setTitle('');
    setIsEditing(false);
    setIsDeleting(true);
    setToBeDeletedId(id);
    Animated.timing(animation, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsDeleting(false);
    animation.setValue(0);
  }, []);

  const onConfirmDelete = useCallback(async () => {
    setIsDeleting(false);
    animation.setValue(0);
    const result = await store.dashboard.deleteDashboardContent(toBeDeletedId);
    const fetchData = await store.dashboard.fetchDashboard(store.auth.user?.id);
    setDashboard(fetchData.extra?.results);
    setNextRequest(fetchData.extra?.next);
  }, [toBeDeletedId]);

  const onEdit = useCallback((content: DashboardModel) => {
    setIsEditing(true);
    setId(content.id);
    setTitle(content.title);
    setBody(content?.body);
    setAttachmentData(content?.files);
  }, []);

  const saveChanges = useCallback(
    async (data) => {
      setLoadingSubmission(true);
      const result = await store.dashboard.updateDashboardContent(
        data,
        attachments,
        attachmentData.map((file) => file.id),
      );
      if (result.ok) {
        const fetchData = await store.dashboard.fetchDashboard(
          store.auth.user?.id,
        );
        setDashboard(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setBody('');
        setTitle('');
        setAttachments([]);
        setAttachmentData([]);
        setIsEditing(false);
      } else {
        console.log(getErrors(result.errors));
      }
      setHasError(!result.ok);
      setResultInfo(result.ok ? 'SAVED SUCCESSFULLY' : 'SAVING FAILED');
      setLoadingSubmission(false);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: true,
      }).start(() => animation.setValue(0));
    },
    [attachments, attachmentData],
  );
  const onSaveWrapped = handleSubmit(saveChanges);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (nextRequest) {
      const fetchData = await store.dashboard.fetchDashboard(
        undefined,
        nextRequest,
      );
      setDashboard([...dashboard!, ...fetchData.extra?.results]);
      setNextRequest(fetchData.extra?.next);
    }
    setLoadingMore(false);
  };

  const onCancel = useCallback(() => {
    setIsEditing(false);
    setTitle('');
    setBody('');
    setAttachments([]);
    setAttachmentData([]);
  }, []);

  const onRemoveAttachment = useCallback(
    (index) => {
      if (index > attachmentData.length || attachmentData.length === 0) {
        let tempArr = [...attachments];
        tempArr.splice(index - attachmentData.length, 1);
        setAttachments(tempArr);
      } else {
        let tempArr = [...attachmentData];
        tempArr.splice(index, 1);
        setAttachmentData(tempArr);
      }
    },
    [attachments, attachmentData],
  );

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const onAttach = async (event) => {
    event.preventDefault();
    let result = await DocumentPicker.getDocumentAsync({ multiple: true });
    if (!result.cancelled) {
      const fileList = Array.from(result.output);
      setAttachments([...attachments, ...fileList] as File[]);
    }
  };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Layout style={styles.container}>
      {!!isMobile ? (
        <ScrollView contentContainerStyle={styles.mobileBg}>
          {showInput ? (
            <Layout style={styles.mobileInputContainer}>
              <Layout style={styles.headerStyle}>
                <Text style={styles.headerText}>Create New Dashboard Post</Text>
                <TouchableOpacity
                  style={[styles.add, { marginLeft: 'auto', marginRight: 10 }]}
                  onPress={() => {
                    setShowInput(false);
                  }}
                >
                  <Icon name="close-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
              </Layout>
              <Input
                style={styles.titleContainer}
                placeholder="Title"
                size="medium"
                value={title}
                onChangeText={(text) => setTitle(text)}
              />
              <View style={styles.quillContainer}>
                <QuillToolbar />
                <ReactQuill
                  value={body}
                  onChange={setBody}
                  style={styles.quill as React.CSSProperties}
                  modules={modules}
                  bounds={'.quill'}
                  theme="snow"
                />
                {[...attachmentData, ...attachments].length > 0 && (
                  <Layout style={styles.attachmentViewMobile}>
                    <ScrollView
                      style={styles.attachScroll}
                      contentContainerStyle={styles.attachContent}
                      horizontal={true}
                    >
                      {[...attachmentData, ...attachments].map(
                        (file, index) => {
                          return (
                            <Layout
                              key={index}
                              style={styles.attachmentContainer}
                            >
                              <TouchableOpacity
                                onPress={() => onRemoveAttachment(index)}
                              >
                                <Icon
                                  name="close-circle-outline"
                                  fill="grey"
                                  style={styles.remove}
                                />
                              </TouchableOpacity>
                              <Text style={styles.fileName} numberOfLines={1}>
                                {file.name}
                              </Text>
                              <Text style={styles.fileSize} numberOfLines={1}>
                                {file.size
                                  ? (file.size / (1024 * 1024)).toFixed(2)
                                  : (file.fileSize / (1024 * 1024)).toFixed(
                                      2,
                                    )}{' '}
                                MB
                              </Text>
                            </Layout>
                          );
                        },
                      )}
                    </ScrollView>
                  </Layout>
                )}
              </View>
              {isEditing ? (
                <Layout style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={onCancel}
                  >
                    <Text style={styles.buttonText}>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.editButton}
                    onPress={onSaveWrapped}
                  >
                    <Text style={styles.buttonText}>Save Changes</Text>
                  </TouchableOpacity>
                </Layout>
              ) : (
                <TouchableOpacity
                  style={styles.submitButton}
                  onPress={onSubmitWrapped}
                >
                  <Text style={styles.buttonText}>Add to Dashboard</Text>
                </TouchableOpacity>
              )}
              <CheckBox
                checked={onNotification}
                style={styles.notifCheckMobile}
                onChange={(status) => setOnNotification(status)}
              >
                {() => (
                  <Text style={styles.notificationText}>
                    Notify Organization members about this post
                  </Text>
                )}
              </CheckBox>
            </Layout>
          ) : (
            <Layout style={styles.mobileContentContainer}>
              <Layout style={styles.headerStyle}>
                <Text style={styles.headerText}>My Dashboard</Text>
                <TouchableOpacity
                  style={styles.add}
                  onPress={() => {
                    setShowInput(true);
                  }}
                >
                  <Icon name="plus-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
              </Layout>
              <ScrollView
                style={styles.mobileContentList}
                onScroll={({ nativeEvent }) => {
                  if (isCloseToBottom(nativeEvent)) {
                    loadMore();
                  }
                }}
                scrollEventThrottle={400}
              >
                {loading ? (
                  <Layout style={styles.loading}>
                    <Spinner />
                  </Layout>
                ) : (
                  dashboard?.map((content, index) => {
                    return (
                      <DashboardContent
                        key={index.toString()}
                        content={content}
                        onDelete={() => onDelete(content?.id)}
                        onEdit={() => onEdit(content)}
                        isMobile={isMobile}
                      />
                    );
                  })
                )}
                {!loading && loadingMore && (
                  <Layout style={styles.loading}>
                    <Spinner />
                  </Layout>
                )}
              </ScrollView>
            </Layout>
          )}
        </ScrollView>
      ) : (
        <Layout style={styles.bgContainer}>
          <Layout style={styles.inputContainer}>
            <Input
              style={styles.titleContainer}
              placeholder="Title"
              size="medium"
              value={title}
              onChangeText={(text) => setTitle(text)}
            />
            <View style={styles.quillContainer}>
              <QuillToolbar />
              <ReactQuill
                value={body}
                onChange={setBody}
                style={styles.quill as React.CSSProperties}
                modules={modules}
                bounds={'.quill'}
              />
              {[...attachmentData, ...attachments].length > 0 && (
                <Layout
                  style={{
                    width: '100%',
                    height: 50,
                  }}
                >
                  <ScrollView
                    style={styles.attachmentView}
                    contentContainerStyle={styles.attachContent}
                    horizontal={true}
                  >
                    {[...attachmentData, ...attachments].map((file, index) => {
                      return (
                        <Layout key={index} style={styles.attachmentContainer}>
                          <TouchableOpacity
                            onPress={() => onRemoveAttachment(index)}
                          >
                            <Icon
                              name="close-circle-outline"
                              fill="grey"
                              style={styles.remove}
                            />
                          </TouchableOpacity>
                          <Text style={styles.fileName} numberOfLines={1}>
                            {file.name}
                          </Text>
                          <Text style={styles.fileSize} numberOfLines={1}>
                            {file.size
                              ? (file.size / (1024 * 1024)).toFixed(2)
                              : (file.fileSize / (1024 * 1024)).toFixed(2)}{' '}
                            MB
                          </Text>
                        </Layout>
                      );
                    })}
                  </ScrollView>
                </Layout>
              )}
            </View>
            {loadingSubmission ? (
              <Layout style={styles.buttonContainer}>
                <Layout
                  style={[
                    styles.loading,
                    { marginTop: 0, backgroundColor: 'transparent' },
                  ]}
                >
                  <Spinner />
                </Layout>
              </Layout>
            ) : isEditing ? (
              <Layout style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.cancelButton}
                  onPress={onCancel}
                >
                  <Text style={styles.buttonText}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.editButton}
                  onPress={onSaveWrapped}
                >
                  <Text style={styles.buttonText}>Save Changes</Text>
                </TouchableOpacity>
              </Layout>
            ) : (
              <TouchableOpacity
                style={styles.submitButton}
                onPress={onSubmitWrapped}
              >
                <Text style={styles.buttonText}>Add to Dashboard</Text>
              </TouchableOpacity>
            )}
            <CheckBox
              checked={onNotification}
              style={styles.notifCheck}
              onChange={(status) => setOnNotification(status)}
            >
              {() => (
                <Text style={styles.notificationText}>
                  Notify Organization members about this post
                </Text>
              )}
            </CheckBox>
          </Layout>
          <Layout style={styles.contentContainer}>
            <Text style={styles.headerText}>My Dashboard</Text>
            <ScrollView
              style={styles.contentList}
              onScroll={({ nativeEvent }) => {
                if (isCloseToBottom(nativeEvent)) {
                  loadMore();
                }
              }}
              scrollEventThrottle={400}
            >
              {loading ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                dashboard?.map((content, index) => {
                  return (
                    <DashboardContent
                      key={index}
                      content={content}
                      onDelete={() => onDelete(content?.id)}
                      onEdit={() => onEdit(content)}
                      isMobile={isMobile}
                    />
                  );
                })
              )}
              {!loading && loadingMore && (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              )}
            </ScrollView>
          </Layout>
        </Layout>
      )}
      <View
        style={!!isMobile ? styles.mobileCenter : styles.center}
        pointerEvents={isDeleting ? undefined : 'none'}
      >
        {isDeleting && (
          <TouchableOpacity
            activeOpacity={1}
            style={!!isMobile ? styles.mobileCenter : styles.center}
            onPress={onCancelDelete}
          />
        )}
        <Animated.View
          style={[
            styles.popUpContainer,
            {
              opacity,
              transform: [{ scale }, { translateY }],
            },
          ]}
        >
          <Icon
            style={styles.savedIcon}
            name={
              isDeleting
                ? 'question-mark-circle-outline'
                : hasError
                ? 'close-circle-outline'
                : 'checkmark-circle-2-outline'
            }
            fill={isDeleting ? 'orange' : hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>
            {isDeleting ? 'Are you sure you want to' : resultInfo}
          </Text>
          {isDeleting && (
            <Text style={styles.saveText}>delete this dashboard item?</Text>
          )}
          {isDeleting && (
            <View style={styles.buttons}>
              <TouchableOpacity style={styles.button} onPress={onCancelDelete}>
                <Text style={styles.deleteText}>NO</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={onConfirmDelete}>
                <Text style={styles.deleteText}>YES</Text>
              </TouchableOpacity>
            </View>
          )}
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    position: 'absolute',
    width: '90%',
    height: '95%',
    left: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'row',
  },
  mobileBg: {
    position: 'absolute',
    width: '90%',
    height: '92%',
    left: '26px',
    right: '26px',
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  inputContainer: {
    width: '50%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileInputContainer: {
    width: '100%',
    height: '90%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '50%',
    height: '100%',
    backgroundColor: '#203950',
    flexDirection: 'column',
    flex: 1,
  },
  mobileContentContainer: {
    width: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  titleContainer: {
    backgroundColor: '#0B1F35',
    marginTop: '7.5%',
    marginLeft: '7.5%',
    width: '85%',
    color: 'white',
  },
  quillContainer: {
    // backgroundColor: 'transparent',
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '85%',
    height: '70%',
    backgroundColor: '#0B1F35',
    borderWidth: 10,
    borderColor: '#0B1F35',
  },
  quill: {
    width: '100%',
    height: '100%',
    color: 'white',
    overflow: 'hidden',
    userSelect: 'text',
    MozUserSelect: 'text',
    WebkitUserSelect: 'text',
    msUserSelect: 'text',
    backgroundColor: '#0B1F35',
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
    backgroundColor: 'transparent',
  },
  cancelButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'olive',
    height: 40,
    width: '48%',
  },
  editButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '48%',
    marginLeft: '4%',
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: 'white',
    marginTop: 10,
    alignSelf: 'center',
  },
  contentList: {
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '32px',
    backgroundColor: '#10283E',
    width: '92%',
    flex: 1,
  },
  mobileContentList: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: '#10283E',
    width: '100%',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: width,
    height: height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  add: {
    marginTop: 10,
    marginLeft: 15,
    alignSelf: 'center',
  },
  headerStyle: {
    flexDirection: 'row',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    height: 40,
    backgroundColor: 'transparent',
  },
  attachmentContainer: {
    height: '80%',
    width: '25%',
    backgroundColor: '#1A3248',
    marginVertical: 'auto',
    marginHorizontal: 5,
  },
  remove: {
    height: 18,
    width: 18,
    position: 'absolute',
    top: -5,
    right: -4,
  },
  fileName: {
    fontSize: 12,
    marginVertical: 'auto',
    marginLeft: 5,
  },
  fileSize: {
    fontSize: 8,
    marginVertical: 'auto',
    marginLeft: 5,
    color: 'grey',
  },
  icon: { height: 20, width: 20 },
  attachmentViewMobile: {
    width: '100%',
    height: 50,
  },
  attachmentView: { width: '100%', backgroundColor: 'transparent' },
  attachScroll: { width: '100%', backgroundColor: 'transparent' },
  attachContent: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  notifCheckMobile: {
    marginLeft: '7.5%',
    marginTop: '7.5px',
  },
  notifCheck: {
    marginLeft: '7.5%',
    marginVertical: '2.5%',
  },
  notificationText: {
    marginLeft: 5,
    fontSize: 12,
    opacity: 0.5,
  },
});

export default observer(Dashboard);
