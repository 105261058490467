import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import NavigationBar from 'src/components/NavigationBar';
import config from 'src/config';
import { AppStackParamList } from '../AppContainer';

// type Props = {
//   loading: boolean;
//   program?: SweatProgram;
//   workout?: SweatWorkout;
//   stepExercise: SweatWorkoutStepExercise;
//   step: SweatWorkoutStep;
// };

type Props = { route: RouteProp<AppStackParamList, 'SweatWorkoutFTEComment'> };

const SweatStartFTEComment: React.FC<Props> = (props) => {
  const { exercise, step, stepExercise, workoutTitle, workoutId } =
    props.route.params;
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);

  const navigation = useNavigation();

  const [isTimerOpen, setIsTimerOpen] = useState(false);
  const titleOpacity = useRef(new Animated.Value(0));
  const [title, day] = workoutTitle.split(': ');
  const { sweat, auth, fteLibrary, user } = useStore();
  const [loading, setLoading] = useState(false);

  const [userNotes, setUserNotes] = useState('');
  const [progressId, setProgressId] = useState(0);
  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        if ('bwOrEquipment' in stepExercise && stepExercise.id > 0) {
          const summary = await fteLibrary.fetchFteUserSweatWorkoutProgress(
            auth.user?.id,
            workoutId,
          );
          const exerciseProgress =
            await fteLibrary.fetchFteUserSweatExerciseProgress(
              summary.extra?.results[0].id,
              stepExercise.id,
            );
          setProgressId(exerciseProgress.extra?.results[0].id);
          setUserNotes(exerciseProgress.extra?.results[0].notes);
        }
        setLoading(false);
      })();
    }, [workoutId, stepExercise]),
  );

  const onAddNotes = async () => {
    await user.updateSweatWorkoutExerciseProgress({
      id: progressId,
      userNotes: userNotes,
    });
  };
  const renderHeader = useCallback(() => {
    return (
      <Layout style={styles.headerContainer}>
        <LinearGradient
          colors={['transparent', '#10283E']}
          style={styles.coverGradient}
        />
        <Layout style={styles.headerContent}>
          <Layout style={styles.header}>
            <Text numberOfLines={2} category="h2" style={styles.name}>
              {title}
            </Text>
          </Layout>
          <Layout style={styles.subheader}>
            <Text style={styles.subheaderText} category="c1">
              {day}
            </Text>
          </Layout>
        </Layout>
      </Layout>
    );
  }, [workoutTitle]);

  return (
    <Layout style={styles.container}>
      <Layout>
        <NavigationBar
          titleOpacity={titleOpacity.current.interpolate({
            inputRange: [0, config.titleDisplayOffsetOnScroll],
            outputRange: [0, 1],
          })}
          style={{ marginTop: 20 }}
          goBack={goBack}
        />
      </Layout>
      {renderHeader()}
      {/* <Layout style={styles.stepContainer}>
        <Text style={styles.stepText}>
          Step {step.order > 0 ? step.order : 1}
        </Text>
      </Layout> */}
      <Layout style={styles.exerciseContainer}>
        <Text style={styles.exerciseText}>{exercise.name}</Text>
      </Layout>
      {loading ? (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      ) : (
        <View style={styles.content}>
          <Text>Instructions</Text>
          <View
            style={[
              styles.fteMessage,
              !('bwOrEquipment' in stepExercise) &&
                stepExercise.id < 0 && { flex: 1 },
            ]}
          >
            <ScrollView>
              <Hyperlink
                linkDefault={true}
                linkStyle={{
                  margin: 0,
                  padding: 0,
                  color: '#ADD8E6',
                }}
              >
                <Text style={styles.fteMessageText}>
                  {stepExercise.instructions}
                </Text>
              </Hyperlink>
            </ScrollView>
          </View>
          {'bwOrEquipment' in stepExercise && stepExercise.id > 0 && (
            <View>
              <Text style={{ marginTop: 5 }}>Your Notes</Text>
              <View style={styles.fteMessage}>
                <ScrollView>
                  <TextInput
                    value={userNotes || ''}
                    onChangeText={(text) => setUserNotes(text)}
                    style={styles.textNotes}
                    multiline
                    // autoFocus
                  />
                  {/* <Input style={styles.fteMessage} /> */}
                </ScrollView>
              </View>
              <TouchableOpacity
                style={{
                  height: 40,
                  width: '100%',
                  backgroundColor: '#767F6A',
                }}
                onPress={onAddNotes}
              >
                <Text
                  style={{
                    alignSelf: 'center',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    fontSize: 14,
                  }}
                >
                  Add Notes
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  content: {
    flex: 1,
    height: Dimensions.get('window').height / 2,
    padding: 16,
    backgroundColor: 'dark-blue',
  },
  stepContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  stepText: {},
  exerciseContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  exerciseText: {
    fontSize: 16,
    lineHeight: 20,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  textNotes: {
    color: 'white',
    fontSize: 14,
    lineHeight: 20,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: 'darker-blue',
    width: '100%',
    padding: 10,
  },
  fteMessage: {
    // flex: 1,
    padding: 16,
    marginTop: 5,
    height: '40%',
    backgroundColor: 'black',
  },
  fteMessageText: {
    fontSize: 14,
  },
  headerContainer: {
    padding: 24,
    height: 30,
  },
  headerContent: {
    paddingTop: 10,
  },
  completeButton: {
    paddingHorizontal: 16,
    marginLeft: Dimensions.get('window').width - 90,
  },
  completeButtonFte: {
    marginLeft: 0,
  },
  testButton: {
    paddingHorizontal: 16,
    marginLeft: Dimensions.get('window').width - 140,
  },
  completeIcon: {
    marginRight: 8,
  },
  completeText: {
    color: 'white',
    textTransform: 'uppercase',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    position: 'relative',
  },
  name: {
    fontSize: 24,
    fontWeight: '700',
    position: 'absolute',
    bottom: 0,
    width: '70%',
  },
  subheader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 24,
    backgroundColor: 'transparent',
  },
  subheaderText: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
  },
  coverGradient: {
    position: 'absolute',
    top: 50,
    height: 20,
    width: '120%',
    marginHorizontal: -24,
  },
  loading: {
    // margin: 'auto',
    // marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: Dimensions.get('window').height / 2,
    padding: 16,
    backgroundColor: 'dark-blue',
  },
});

export default observer(SweatStartFTEComment);
