import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { UserReaction } from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import { EMOJI_NAMES } from 'o2x-store/src/utils/emoji';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FlatList,
  LayoutRectangle,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import EmojiSelector from 'src/components/EmojiSelector';
import TeamActivityItem from 'src/components/Teams/TeamActivityItem';
import UserReactions from 'src/components/UserReactions';
import SettingsIcon from '../../assets/images/settings.svg';

type Props = {
  teamId: number | null;
};

const TeamActivity: React.FC<Props> = (props) => {
  const { teamId } = props;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const team = store.teams.teams.get(`${teamId}`);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState<boolean>(false);
  const [visibleReactions, setVisibleReactions] = useState<boolean>(false);
  const [tapCoords, setTapCoords] = useState<LayoutRectangle>();
  const [reactions, setReactions] = useState<Array<UserReaction>>(new Array());

  const isTablet = useMediaQuery({
    maxDeviceWidth: 900,
  });

  useFocusEffect(
    useCallback(() => {
      if (teamId) {
        store.teams.fetchTeamActivity(teamId, 1);
      }
    }, [teamId]),
  );

  const resetPage = useCallback(() => {
    setPage(1);
  }, [setPage]);

  const incrementPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, [setPage]);

  const fetchNextActivities = useCallback(async () => {
    if (team) {
      const result = await store.teams.fetchTeamActivity(team.id, page + 1);
      if (result.ok) {
        incrementPage();
      }
    }
  }, [team, page]);

  const refreshActivities = useCallback(async () => {
    if (team) {
      setRefreshing(true);
      const result = await store.teams.fetchTeamActivity(team.id, 1);
      if (result.ok) {
        resetPage();
      }
      setRefreshing(false);
    }
  }, [team, page, setRefreshing]);

  const onAddReaction = useCallback(
    (key: EMOJI_NAMES) => {
      if (team && selectedActivity) {
        store.teams.addReaction(key, selectedActivity, `${team.id}`);
        setVisibleEmojiPicker(false);
      }
    },
    [selectedActivity, team, setVisibleEmojiPicker],
  );

  const toggleEmojiPicker = useCallback(() => {
    setVisibleEmojiPicker((prev) => !prev);
    setVisibleReactions(false);
  }, [setVisibleEmojiPicker, setVisibleReactions]);

  const closeEmojiPicker = useCallback(() => {
    setVisibleEmojiPicker(false);
  }, [setVisibleEmojiPicker]);

  const toggleReactions = useCallback(() => {
    setVisibleReactions((prev) => !prev);
    setVisibleEmojiPicker(false);
  }, [setVisibleEmojiPicker, setVisibleReactions]);

  const closeReactions = useCallback(() => {
    setVisibleReactions(false);
  }, [setVisibleReactions]);

  useEffect(() => {
    setVisibleEmojiPicker(false);
    setVisibleReactions(false);
  }, [team?.id, setVisibleReactions, setVisibleEmojiPicker]);

  const renderActivity = useCallback(
    ({ item }) => (
      <TeamActivityItem
        activity={item}
        onSelectEmoji={(measurements: LayoutRectangle) => {
          setSelectedActivity(`${item.id}`);
          toggleEmojiPicker();
          setTapCoords(measurements);
        }}
        onViewReactions={() => {
          setSelectedActivity(`${item.id}`);
          toggleReactions();
        }}
      />
    ),
    [toggleEmojiPicker, setSelectedActivity],
  );

  const onEdit = useCallback(() => {
    navigation.navigate('TeamEdit', { teamId });
  }, [teamId]);

  const isAuthor = useMemo(() => {
    return team && store.auth.user && store.auth.user?.id === team?.user;
  }, [team, store.auth.user]);

  return (
    <Layout
      style={[
        isTablet ? styles.containerTablet : styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={isTablet ? styles.contentTablet : styles.content}>
        <View style={isTablet ? styles.headerTablet : styles.header}>
          <View style={!isTablet && styles.titleContainer}>
            <Text style={isTablet ? styles.titleTablet : styles.title}>
              Team Activity
            </Text>
          </View>
          {isAuthor && (
            <TouchableOpacity
              style={!isTablet && styles.settingsContainer}
              onPress={onEdit}
            >
              <SettingsIcon
                style={
                  isTablet ? styles.settingsIconTablet : styles.settingsIcon
                }
              />
            </TouchableOpacity>
          )}
        </View>
        {!!team && (
          <FlatList
            style={isTablet ? styles.activityListTablet : styles.activityList}
            data={team.teamActivities}
            renderItem={renderActivity}
            keyExtractor={(item) => `activity-${item.id}`}
            onEndReached={fetchNextActivities}
            onRefresh={refreshActivities}
            refreshing={refreshing}
          />
        )}
      </View>
      <EmojiSelector
        onSelect={onAddReaction}
        isVisible={visibleEmojiPicker}
        onClose={closeEmojiPicker}
        tapCoords={tapCoords}
      />
      <UserReactions
        reactions={reactions}
        teamId={`${teamId}`}
        activityId={selectedActivity}
        isVisible={visibleReactions}
        onClose={closeReactions}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  containerTablet: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  content: {
    paddingTop: 28,
    paddingHorizontal: 16,
  },
  contentTablet: {
    flex: 1,
    padding: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginLeft: 10,
  },
  headerTablet: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
  },
  titleTablet: {
    fontSize: 18,
    color: 'white',
  },
  titleContainer: {
    paddingHorizontal: 12,
  },
  activityList: {
    marginVertical: 12,
    height: 540,
  },
  activityListTablet: {
    marginVertical: 12,
    height: '80%',
  },
  activityItem: {
    backgroundColor: 'dark-blue',
    paddingVertical: 10,
    marginBottom: 26,
    flexDirection: 'row',
    alignItems: 'center',
  },
  activityImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
    marginRight: 10,
  },
  activityText: {
    fontSize: 14,
    lineHeight: 17,
  },
  settingsContainer: {
    marginRight: 20,
  },
  settingsIcon: {
    width: 26,
    height: 26,
  },
  settingsIconTablet: {
    width: 24,
    height: 24,
  },
});

export default observer(TeamActivity);
