import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { observer } from 'mobx-react-lite';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef } from 'react';
import { Alert, Animated, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ChevronDown from '../../assets/images/chevron-down-gray.svg';
import ClockBlue from '../../assets/images/clock-dark-blue.svg';
import SweatStartWorkout from '../../components/SweatStart/SweatStartWorkout';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = { route: RouteProp<AppStackParamList, 'SweatContinue'> };

const SweatStartWorkoutDetails: React.FC<Props> = (props) => {
  const { programId } = props.route.params;
  const { sweat } = useStore();
  const { sweatStart } = useNativeStore();

  const program = !!programId && sweat.sweatPrograms.get(`${programId}`);
  const titleOpacity = useRef(new Animated.Value(0));
  let workout: SweatWorkout | undefined;
  let step: SweatWorkoutStep | undefined;
  let stepExercise:
    | SweatWorkoutStepExercise
    | SweatGlobalStepExercise
    | undefined;
  let exercise: SweatExercise | undefined;
  let nextStepExercise:
    | SweatWorkoutStepExercise
    | SweatGlobalStepExercise
    | undefined;
  let nextExercise: SweatExercise | undefined;

  ({ workout, step, stepExercise, exercise } = sweatStart);

  const isCircuit = step?.circuitSets !== 1 || !!step?.circuitTimer;
  const isPrepare = step?.id === -1;
  const isRecover = step?.id === -2;
  const isSweat = step?.id !== -1 && step?.id !== -2;
  const maxRound = step?.exercises[step?.exercises.length - 1].round + 1;
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  useKeepAwake();

  const goBack = useCallback(() => {
    if (sweatStart.active) {
      Alert.alert('Are you sure you want to go back?', undefined, [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Go Back',
          onPress: () => {
            sweatStart.endWorkout();
            sweatStart.reset();
            navigation.navigate('Today');
          },
        },
      ]);
    } else {
      sweatStart.reset();
      navigation.goBack();
    }
  }, [sweatStart.active]);

  const renderListHeader = useCallback(
    () => (
      <Layout style={[styles.headerContainer, { marginTop: insets.top }]}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
        >
          <ChevronDown />
        </TouchableOpacity>

        <Layout style={styles.modalHeader}>
          {isCircuit && (
            <Text style={[styles.modalHeaderText, styles.blueText]}>
              {step.circuitSets === 1
                ? `Round ${sweatStart.currentCircuitRound} / ${maxRound}`
                : step.circuitTimer === 'tabata'
                ? `Exercise ${sweatStart.currentCircuitRound} / ${step.circuitSets}`
                : step.circuitSets !== 0
                ? `Round ${sweatStart.currentCircuitRound} / ${step.circuitSets}`
                : `Round ${sweatStart.currentCircuitRound}`}
            </Text>
          )}
          <Text style={styles.modalHeaderText}>
            {sweatStart.exerciseTimeDisplay(stepExercise.id)}
          </Text>
          {isSweat && (
            <View style={{ paddingLeft: 3 }}>
              <ClockBlue />
            </View>
          )}
          <Text style={[styles.modalHeaderText, styles.blueText]}>
            {isSweat
              ? sweatStart.totalTimeDisplay
              : isPrepare
              ? 'PREPARE'
              : 'RECOVER'}
          </Text>
        </Layout>
        {!!step?.circuitInstructions && (
          <View style={{ paddingTop: 20, alignSelf: 'flex-start' }}>
            <Text style={styles.circuitInstructionsTitleText}>
              Circuit Instructions
            </Text>
            <Text style={styles.circuitInstructionsText}>
              {step.circuitInstructions}
            </Text>
          </View>
        )}
      </Layout>
    ),
    [step, stepExercise],
  );

  if (!workout || !step || !stepExercise || !exercise) {
    return null;
  }

  return (
    <Layout style={styles.container}>
      <SweatStartWorkout
        program={program ? program : undefined}
        loading={workout?.loading || false}
        workout={workout}
        titleOpacity={titleOpacity.current}
        renderListHeader={renderListHeader}
        hideMinimize={true}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  headerContainer: {
    paddingTop: 10,
    marginBottom: 24,
    alignItems: 'center',
    backgroundColor: 'black',
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    maxWidth: '90%',
  },

  header: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  subheader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  subheaderText: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
  },
  modalHeader: {
    marginTop: 30,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  modalHeaderContainer: {
    flexDirection: 'row',
  },
  modalHeaderText: {
    fontSize: 12,
    lineHeight: 14,
    paddingHorizontal: 5,
  },
  blueText: {
    color: 'blue-secondary',
  },
  circuitInstructionsTitleText: {
    color: 'cyan',
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '700',
  },
  circuitInstructionsText: {
    fontSize: 14,
    lineHeight: 17,
    paddingTop: 10,
  },
});

export default observer(SweatStartWorkoutDetails);
