import SavedList from 'o2x-store/src/models/SavedList';
import { RootStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import { useCallback, useEffect, useState } from 'react';
import { Keyboard, Platform } from 'react-native';
import { getErrors } from '../utils/errors';

export const useIsKeyboardShown = () => {
  const [isKeyboardShown, setIsKeyboardShown] = useState(false);

  useEffect(() => {
    const handleKeyboardShow = () => setIsKeyboardShown(true);
    const handleKeyboardHide = () => setIsKeyboardShown(false);

    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
      Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
    } else {
      Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
      Keyboard.addListener('keyboardDidHide', handleKeyboardHide);
    }

    return () => {
      if (Platform.OS === 'ios') {
        Keyboard.removeListener('keyboardWillShow', handleKeyboardShow);
        Keyboard.removeListener('keyboardWillHide', handleKeyboardHide);
      } else {
        Keyboard.removeListener('keyboardDidShow', handleKeyboardShow);
        Keyboard.removeListener('keyboardDidHide', handleKeyboardHide);
      }
    };
  }, []);

  return isKeyboardShown;
};

export const useFetchedSavedList = (
  store: RootStore,
  type: LIST_TYPE,
): [Map<string, SavedList> | undefined, string] => {
  const [data, setData] = useState<Map<string, SavedList>>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      const response = await store.savedList.fetchLists(type);
      if (!('errors' in response)) {
        setData(response);
      } else {
        setErrorMessage(getErrors(response.errors));
      }
    })();
  }, [type]);

  return [data, errorMessage];
};

export const useSaveList = (
  store: RootStore,
  type: LIST_TYPE,
): [Map<string, SavedList> | undefined, Function, Function, string] => {
  const [savedList, fetchErrorMessage] = useFetchedSavedList(store, type);
  const [saveErrorMessage, setErrorMessage] = useState('');

  const addToList = useCallback(
    async (savedList: Map<string, SavedList> | undefined, itemId: string) => {
      if (savedList && savedList.size > 0) {
        const keys = Array.from(savedList.keys());
        const list = savedList.get(keys[0]);
        if (list) {
          const result = await store.savedList.addItems(
            [itemId],
            type,
            list.id,
          );
          if (result.ok) {
            return true;
          } else {
            setErrorMessage(getErrors(result.errors));
            return false;
          }
        }
      }
    },
    [savedList],
  );

  const removeFromList = useCallback(
    async (savedList: Map<string, SavedList> | undefined, itemId: string) => {
      if (savedList && savedList.size > 0) {
        const keys = Array.from(savedList.keys());
        const list = savedList.get(keys[0]);
        if (list) {
          const result = await store.savedList.removeItems([itemId], type);
          if (result.ok) {
            return true;
          } else {
            setErrorMessage(getErrors(result.errors));
            return false;
          }
        }
      }
    },
    [savedList],
  );

  return [
    savedList,
    addToList,
    removeFromList,
    fetchErrorMessage || saveErrorMessage,
  ];
};

export const useSaveListMultiple = (
  store: RootStore,
  type: LIST_TYPE,
): [Function, Function, string] => {
  // Difference from above is it doesn't automatically fetch saved lists.
  // This needs to be passed from the outside.
  // Useful if savedList is fetched from the list page, but used per list item.
  const [saveErrorMessage, setErrorMessage] = useState('');

  const addToList = useCallback(
    async (savedList: Map<string, SavedList> | undefined, itemId: string) => {
      if (savedList && savedList.size > 0) {
        const keys = Array.from(savedList.keys());
        const list = savedList.get(keys[0]);
        if (list) {
          const result = await store.savedList.addItems(
            [itemId],
            type,
            list.id,
          );
          if (result.ok) {
          } else {
            setErrorMessage(getErrors(result.errors));
          }
        }
      }
    },
    [],
  );

  const removeFromList = useCallback(
    async (savedList: Map<string, SavedList> | undefined, itemId: string) => {
      if (savedList && savedList.size > 0) {
        const keys = Array.from(savedList.keys());
        const list = savedList.get(keys[0]);
        if (list) {
          const result = await store.savedList.removeItems([itemId], type);
          if (result.ok) {
          } else {
            setErrorMessage(getErrors(result.errors));
          }
        }
      }
    },
    [],
  );

  return [addToList, removeFromList, saveErrorMessage];
};
