import config from '../../config';
import { callApiWithToken } from './base';

export const fetchEatProfileQuestions = async (token: string) =>
  callApiWithToken(
    config.urls.eatProfile + 'questions/',
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchDailyEatHabitQuestions = async (token: string) =>
  callApiWithToken(
    config.urls.dailyEatHabit + 'questions/',
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchThriveProfileQuestions = async (token: string) =>
  callApiWithToken(
    config.urls.thriveProfile + 'initial_questions/',
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchDailyThriveHabitQuestions = async (token: string) =>
  callApiWithToken(
    config.urls.dailyThriveHabit + 'questions/',
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchSweatProfileQuestions = async (token: string) =>
  callApiWithToken(config.urls.sweatProfile + 'questions/', token, 'GET');

export const fetchFindThrivePlanQuestions = async (token: string) =>
  callApiWithToken(config.urls.thriveProfile + 'questions/', token, 'GET');

export const fetchStates = async (token: string) =>
  callApiWithToken(
    config.urls.utils + 'states/',
    token,
    'GET',
    undefined,
    undefined,
    true,
  );

export const fetchEventSurveyQuestions = async (
  token: string,
  eventId: string,
) =>
  callApiWithToken(
    `${config.urls.events}${eventId}/survey/questions/`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchDailyDebriefQuestions = async (token: string) =>
  callApiWithToken(
    config.urls.debriefProfile + 'questions/',
    token,
    'GET',
    {},
    undefined,
    true,
  );
