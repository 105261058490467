import { useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Back from '../../assets/images/back.svg';

interface Props {
  onBack?: () => void;
  showBack?: boolean;
  onClose?: () => void;
  showClose?: boolean;
  style?: any;
}

const TopNav: React.FC<Props> = (props) => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { showClose = true, showBack = true, style = null } = props;

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <Layout style={[styles.topNav, { paddingTop: insets.top }, style]}>
      {showBack && (
        <TouchableOpacity onPress={props.onBack || onBack}>
          {Platform.OS === 'web' ? (
            // For some reason, svg cant accept styles on web
            <Back />
          ) : (
            <Back style={[styles.navIcon, styles.navIconLeft]} />
          )}
        </TouchableOpacity>
      )}
      {showClose && (
        <TouchableOpacity
          onPress={props.onClose || onBack}
          style={!showBack && { width: '100%' }}
        >
          <Icon
            style={[styles.navIcon, styles.navIconRight]}
            fill={theme['white']}
            name="close-outline"
          />
        </TouchableOpacity>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // marginTop: 16,
    // paddingHorizontal: 16,
  },
  navIcon: {
    width: 30,
    height: 30,
    // marginBottom: 20,
  },
  navIconLeft: {
    alignSelf: 'flex-start',
  },
  navIconRight: {
    alignSelf: 'flex-end',
  },
});

export default TopNav;
