import { useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Image, ImageStyle, StyleProp, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TeamAssessmentItem from 'src/components/Teams/TeamAssessmentItem';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
};

const TeamAssessment: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const team = store.teams.teams.get(`${id}`);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      if (team) {
        store.teams.fetchTeamAssessment(team.id);
      }
    }, [team]),
  );

  const refreshAssessments = useCallback(async () => {
    if (team) {
      setRefreshing(true);
      const result = await store.teams.fetchTeamAssessment(team.id);
      setRefreshing(false);
    }
  }, [team, setRefreshing]);

  const renderAssessment = useCallback(
    ({ item }) => <TeamAssessmentItem assessment={item} />,
    [],
  );

  const allAssessments = useMemo(() => {
    return [
      ...(team?.physicalAssessments || []),
      ...(team?.formAssessments || []),
    ];
  }, [team?.physicalAssessments.values(), team?.formAssessments.values()]);

  return (
    <Layout
      style={[
        styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={styles.navigationContainer}>
        <TopNav showClose={false} />
      </View>
      <View style={styles.content}>
        <View style={styles.header}>
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={
              team?.logoThumbnail
                ? { uri: team.logoThumbnail }
                : require('../../assets/images/icon.png')
            }
          />
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{team?.name}</Text>
            <Text style={styles.subtitle}>
              {team?.members.length}{' '}
              {Humanize.pluralize(team?.members.length, 'member')}
            </Text>
          </View>
        </View>
        <View style={styles.listHeader}>
          <Text style={styles.subtitle}>TEAM ASSESSMENT</Text>
        </View>
        {!!team && (
          <FlatList
            style={styles.activityList}
            data={allAssessments}
            renderItem={renderAssessment}
            keyExtractor={(_item, idx) => `assessment-${idx}`}
            onRefresh={refreshAssessments}
            refreshing={refreshing}
          />
        )}
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  header: {
    marginBottom: 26,
    flexDirection: 'row',
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 1000,
  },
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 24,
    flex: 1,
  },
  subtitle: {
    fontSize: 14,
    color: 'blue-secondary',
    lineHeight: 17,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 29,
  },
  titleContainer: {
    paddingHorizontal: 12,
  },
  seeMore: {
    textAlign: 'right',
    color: 'light-gray',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  activityList: {
    flex: 1,
    marginVertical: 12,
  },
  activityItem: {
    backgroundColor: 'dark-blue',
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  activityImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
    marginRight: 10,
  },
  activityText: {
    fontSize: 14,
    lineHeight: 17,
  },
});

export default TeamAssessment;
