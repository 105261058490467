import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Fuse from 'fuse.js';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import {
  FTEOrganizationModel,
  OSSGroup,
} from 'o2x-store/src/models/FTELibrary';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Animated,
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { getErrors } from '../../../utils/errors';

type Props = {
  org?: FTEOrganizationModel;
  user?: User;
  group?: OSSGroup;
  onNotification: boolean;
};

const ThriveProgramContent: React.FC<Props> = (props) => {
  const { org, user, group, onNotification } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [fteThriveProgramList, setFteThriveProgramList] =
    useState<ThriveProgram[]>();
  const [targetThriveProgramList, setTargetThriveProgramList] =
    useState<ThriveProgram[]>();
  const [searchList, setSearchList] = useState<ThriveProgram[]>();

  const [assignList, setAssignList] = useState<number[]>();
  const [progressList, setProgressList] = useState<number[]>();
  const [initialLoadingA, setInitialLoadingA] = useState(false);
  const [initialLoadingB, setInitialLoadingB] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [nextRequest, setNextRequest] = useState('');
  const [query, setQuery] = useState('');
  const [id, setId] = useState(0);

  const [fuzzy, setFuzzy] = useState('');

  const { register, setValue, handleSubmit } = useForm();

  const [hasError, setHasError] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const fuseOptions = {
    distance: 100,
    includeMatches: true,
    keys: ['name'],
    location: 0,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.6,
  };

  const notifReceiver = org
    ? org.users
        .map((user) => {
          if (user.id !== store.auth.user?.id) return user.id;
        })
        .toString()
        .replace(/,\s*$/, '')
    : user
    ? user?.id.toString()
    : group?.users.toString().replace(/,\s*$/, '');

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingA(true);
        const thrivePrograms = user
          ? await store.fteLibrary.fetchTargetUserThriveProgramList(user.id)
          : org
          ? await store.fteLibrary.fetchTargetOrgThriveProgramList(org.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupThriveProgramList(group.id)
          : null;
        setId(thrivePrograms?.extra?.results[0].id);
        setAssignList(thrivePrograms?.extra?.results[0].thrivePrograms);
        setProgressList(thrivePrograms?.extra?.results[0].thrivePrograms);
        setTargetThriveProgramList(
          thrivePrograms?.extra?.results[0].thriveProgramList,
        );
        setSearchList(thrivePrograms?.extra?.results[0].thriveProgramList);
        setInitialLoadingA(false);
      })();
    }, [org, user, group]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingB(true);
        const fetchData = await store.fteLibrary.fetchFTEThrivePrograms();
        setFteThriveProgramList(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setInitialLoadingB(false);
      })();
    }, []),
  );

  const initialLoading = initialLoadingA || initialLoadingB;

  useFocusEffect(
    useCallback(() => {
      register({ name: 'id' }, { required: false });
      register({ name: 'thrivePrograms' }, { required: true });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('id', id);
    setValue('thrivePrograms', assignList);
  }, [assignList, id]);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (nextRequest && !listLoading) {
      const fetchData = await store.fteLibrary.fetchFTEThrivePrograms(
        nextRequest,
      );
      setFteThriveProgramList([
        ...fteThriveProgramList!,
        ...fetchData.extra?.results,
      ]);
      setNextRequest(fetchData.extra?.next);
    }
    setLoadingMore(false);
  };

  const searchItem = () => {
    const fuse = new Fuse(searchList!, fuseOptions);
    const matches = fuse.search(fuzzy);
    if (matches.length > 0) {
      const results = matches.map((result) => result.item);
      setTargetThriveProgramList(results);
    }
    if (fuzzy.length === 0) {
      setTargetThriveProgramList(searchList);
    }
  };

  const onSearch = async () => {
    setListLoading(true);
    let url = `${
      config.urls.fteLibrary
    }fte-thrive-programs/?search=${query.trim()}&limit=20`;
    const fetchData = await store.fteLibrary.fetchFTEThrivePrograms(url);
    setFteThriveProgramList(fetchData.extra?.results);
    setNextRequest(fetchData.extra?.next);
    setListLoading(false);
  };

  const addToAssign = (program: ThriveProgram) => {
    if (
      assignList?.includes(program.id) ||
      targetThriveProgramList?.includes(program)
    )
      return;
    if (assignList) setAssignList([...assignList!, program.id]);
    else setAssignList([program.id]);
    if (targetThriveProgramList) {
      setTargetThriveProgramList([...targetThriveProgramList!, program]);
      setSearchList([...searchList!, program]);
    } else {
      setTargetThriveProgramList([program]);
      setSearchList([program]);
    }
  };

  const removeFromAssign = (program: ThriveProgram) => {
    const arr = [...assignList!];
    const arr2 = [...searchList!];
    const arr3 = [...targetThriveProgramList!];
    const index = arr.indexOf(program.id);
    const index2 = arr3.indexOf(program);
    arr3.splice(index2, 1);
    arr2.splice(index, 1);
    arr.splice(index, 1);
    setAssignList([...arr]);
    setSearchList([...arr2]);
    setTargetThriveProgramList([...arr3]);
  };

  const onSubmit = useCallback(
    async (data) => {
      setLoadingSave(true);
      const result = org
        ? await store.fteLibrary.updateTargetOrgThriveProgramList(data)
        : user
        ? await store.fteLibrary.updateTargetUserThriveProgramList(data)
        : group
        ? await store.fteLibrary.updateTargetGroupThriveProgramList(data)
        : null;
      if (result?.ok) {
        const updatedThrivePrograms = org
          ? await store.fteLibrary.fetchTargetOrgThriveProgramList(org?.id)
          : user
          ? await store.fteLibrary.fetchTargetUserThriveProgramList(user?.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupThriveProgramList(group?.id)
          : null;
        if (updatedThrivePrograms) {
          setAssignList(
            updatedThrivePrograms?.extra?.results[0].thrivePrograms,
          );
          setProgressList(
            updatedThrivePrograms?.extra?.results[0].thrivePrograms,
          );
          setTargetThriveProgramList(
            updatedThrivePrograms?.extra?.results[0].thriveProgramList,
          );
          setSearchList(
            updatedThrivePrograms?.extra?.results[0].thriveProgramList,
          );
        }
        if (onNotification) {
          await store.fteEvent.sendNotification({
            recipientUsers: notifReceiver,
            title: 'Your On-site Specialist just posted a new THRIVE program',
            body: 'New Content',
            extra: {
              type: 'New Content',
              content: 'thrive',
              fte: {
                id: store.auth.user.id,
                email: store.auth.user?.email,
                fullName: store.auth.user?.fullName,
                firstName: store.auth.user?.firstName,
                lastName: store.auth.user?.lastName,
                o2xId: store.auth.user?.o2XId,
                organizations: store.auth.user?.organization,
                profileImage: store.auth.user?.profileImage,
              },
              solo: user ? true : false,
            },
          });
        }
      } else {
        console.log(getErrors(result?.errors));
      }
      setLoadingSave(false);
      setHasError(!result?.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => animation.setValue(0));
    },
    [org, user, group, onNotification],
  );
  const onSubmitWrapped = handleSubmit(onSubmit);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });
  return (
    <Layout
      style={!!isMobile ? styles.createContainerMobile : styles.createContainer}
    >
      <Layout
        style={!!isMobile ? styles.listContainerMobile : styles.listContainer}
      >
        <Text style={styles.listHeader}>THRIVE PROGRAM LIBRARY</Text>
        <Input
          size="small"
          style={styles.search}
          accessoryLeft={() => (
            <Icon style={styles.icon} name="search-outline" fill="white" />
          )}
          value={query}
          onChangeText={(text) => setQuery(text)}
          placeholder="Search"
          onKeyPress={(key) =>
            key.nativeEvent.key === 'Enter' ? onSearch() : null
          }
        />
        <ScrollView
          style={styles.contentContainer}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              loadMore();
            }
          }}
          scrollEventThrottle={400}
        >
          {initialLoading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            fteThriveProgramList?.map((program, index) => {
              return (
                <Layout key={index} style={styles.dataContainer}>
                  <Text style={styles.dataText}>{program.name}</Text>
                  <TouchableOpacity
                    style={styles.buttonIcon}
                    onPress={() => addToAssign(program)}
                  >
                    <Icon
                      name="plus-outline"
                      fill="white"
                      style={styles.icon}
                    />
                  </TouchableOpacity>
                </Layout>
              );
            })
          )}
          {!initialLoading && loadingMore && (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          )}
        </ScrollView>
      </Layout>
      <Layout style={styles.listContainer}>
        <Text style={styles.listHeader}>SELECTED THRIVE PROGRAM LIBRARY</Text>
        <Input
          size="small"
          style={styles.search}
          accessoryLeft={() => (
            <Icon style={styles.icon} name="search-outline" fill="white" />
          )}
          value={fuzzy}
          onChangeText={(text) => setFuzzy(text)}
          placeholder="Search"
          onKeyPress={(key) =>
            key.nativeEvent.key === 'Enter' ? searchItem() : null
          }
        />
        <ScrollView style={styles.saveContainer}>
          {initialLoading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            targetThriveProgramList?.map((program, index) => {
              return (
                <Layout key={index} style={styles.dataContainer}>
                  <Text style={styles.dataText}>{program.name}</Text>
                  <TouchableOpacity
                    style={styles.buttonIcon}
                    onPress={() => removeFromAssign(program)}
                  >
                    <Icon
                      name="minus-outline"
                      fill="white"
                      style={styles.icon}
                    />
                  </TouchableOpacity>
                </Layout>
              );
            })
          )}
        </ScrollView>
        {!!loadingSave ? (
          <Layout style={styles.loadingSave}>
            <Spinner />
          </Layout>
        ) : (
          <TouchableOpacity style={styles.saveButton} onPress={onSubmitWrapped}>
            <Text style={styles.saveText}>Save</Text>
          </TouchableOpacity>
        )}
      </Layout>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[
            styles.popUpContainer,
            { opacity, transform: [{ translateY }, { scale }] },
          ]}
          pointerEvents="none"
        >
          <Icon
            style={styles.savedIcon}
            name={
              hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'
            }
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>
            {hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}
          </Text>
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  createContainer: {
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  listContainer: {
    width: '45%',
    height: '93%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  createContainerMobile: {
    width: '95%',
    paddingTop: 20,
    marginRight: 0,
    marginTop: 0,
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#1A3248',
  },
  listContainerMobile: {
    width: '47%',
    height: '93%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  listHeader: {
    marginTop: 15,
    marginLeft: 15,
    paddingLeft: 10,
    borderLeft: '2px solid #04B7D6',
    height: 'auto',
    fontSize: 15,
    color: 'white',
    maxWidth: '90%',
  },
  contentContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 20,
    backgroundColor: '#091C2D',
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 12,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  loadMore: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    marginBottom: 15,
    marginTop: 10,
  },
  loadText: {
    color: 'white',
    fontSize: 12,
    margin: 'auto',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  loadingSave: {
    position: 'absolute',
    right: 20,
    bottom: 10,
    padding: 5,
    backgroundColor: 'transparent',
  },
  saveContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 45,
    backgroundColor: '#091C2D',
  },
  saveButton: {
    position: 'absolute',
    backgroundColor: 'olive',
    width: 70,
    height: 30,
    right: 10,
    bottom: 10,
    padding: 5,
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  search: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1A3248',
    marginTop: 15,
    marginBottom: 5,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
});

export default observer(ThriveProgramContent);
