import { useNavigation } from '@react-navigation/core';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useRef } from 'react';
import {
  Animated,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DLBottomBar from 'src/components/Downloaded/DLBottomBar';
import config from 'src/config';
import { DOWNLOAD_TYPE } from 'src/models/DownloadedList';
import EquipmentIcon from '../../assets/images/equipment-icon.svg';
import NavigationBar from '../../components/NavigationBar';
import UserDetail from '../../components/UserDetail';
import { useNativeStore } from '../../stores';
import LevelIcon from '../ProgramDetail/LevelIcon';

type Props = {
  route: any;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const DownloadedProgram: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { downloads } = useNativeStore();

  const { id, type, downloadType } = props.route.params;

  const opacity = useRef(new Animated.Value(0)).current;

  const program: any = downloads.getProgram(downloadType, id);
  const color = getTaskColor(type);

  const onScroll = useCallback((e) => {
    opacity.setValue(e.nativeEvent.contentOffset.y);
  }, []);

  const onOpen = useCallback(() => {
    if (
      downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM ||
      downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM
    ) {
      navigation.navigate('DownloadedPreview', { id, type, downloadType });
    }
  }, []);

  if (!program) return null;

  const titleOpacity = opacity.interpolate({
    inputRange: [0, config.titleDisplayOffsetOnScroll],
    outputRange: [0, 1],
  });

  return (
    <Layout style={styles.container}>
      <AnimatedScrollView style={styles.scroll} onScroll={onScroll}>
        {program.imageBanner ? (
          <Image
            style={styles.cover as StyleProp<ImageStyle>}
            source={{ uri: program.imageBanner }}
          />
        ) : (
          <View style={styles.cover} />
        )}
        <Layout style={styles.content}>
          <Text category="h2">{program.name}</Text>
          <Text style={[styles.subtitle, { color }]} category="c2">
            {program.subtitle}
          </Text>
          {!!program.equipment && (
            <View style={styles.extraSection}>
              <View style={styles.extraSectionLabel}>
                <EquipmentIcon width={20} />
                <Text style={styles.extraSectionLabelText}>EQUIPMENT</Text>
              </View>
              <Text style={styles.extraSectionText}>
                {Humanize.oxford(program.equipment)}
              </Text>
            </View>
          )}
          {!!program.difficulty && (
            <View style={[styles.extraSection]}>
              <View style={styles.extraSectionLabel}>
                <LevelIcon color={color} />
                <Text style={styles.extraSectionLabelText}>LEVEL</Text>
              </View>
              <Text style={styles.extraSectionText}>
                {Humanize.titleCase(program.difficulty)}
              </Text>
            </View>
          )}
          {!!program.author && (
            <UserDetail style={styles.author} author={program.author} />
          )}
          {!!program.description && (
            <View style={styles.description}>
              <Text category="p2">{program.description}</Text>
            </View>
          )}
          {!!program.ingredients && (
            <View>
              <Text style={styles.subheader}>Ingredients</Text>
            </View>
          )}
          {!!program.directions && (
            <View>
              <Text style={styles.subheader}>Directions</Text>
            </View>
          )}
        </Layout>
      </AnimatedScrollView>
      <NavigationBar
        style={[styles.navigation, { top: insets.top }]}
        title={program.name}
        titleOpacity={titleOpacity}
      />
      <DLBottomBar hidden={type === TASK_TYPE.EAT} onOpen={onOpen} />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actions: {
    marginTop: 20,
    marginBottom: 30,
  },
  author: {
    marginTop: 29,
    marginBottom: 6,
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
  },
  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  description: {
    marginTop: 10,
  },
  li: {
    marginLeft: 10,
  },
  bulletContainer: {
    flexDirection: 'row',
    marginTop: -10,
  },
  subheader: {
    fontFamily: 'Lato-Bold',
    marginBottom: 20,
  },
  text: {
    color: 'white',
    fontSize: 16,
  },
});

export default observer(DownloadedProgram);
