import { RouteProp } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React from 'react';
import { Dimensions, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import TopNav from 'src/components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';
export type Props = {
  route: RouteProp<AppStackParamList, 'VideoModal'>;
};

const VideoModal: React.FC<Props> = (props) => {
  const { resourceId } = props.route.params;
  const { resources } = useStore();
  const resourceItem = resources.resources.get(`${resourceId}`);

  const styles = useStyleSheet(themedStyles);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const initialVideoWidth = isMobile
    ? Dimensions.get('window').width * 0.9 // 90% of screen width
    : isDeviceMaxWidth600
    ? 450
    : isDeviceMaxWidth768
    ? 500
    : 600;

  const opts = {
    width: initialVideoWidth,
    height: (initialVideoWidth / 16) * 9, // 16:9 aspect ratio
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} />
            </View>
            {isMobile ? (
              <Layout style={styles.vidMobile}>
                <YouTube videoId={resourceItem?.videoId} opts={opts} />
              </Layout>
            ) : (
              <Layout style={styles.vid}>
                <YouTube videoId={resourceItem?.videoId} opts={opts} />
              </Layout>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

export const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    maxWidth: 620,
  },
  modalMobile: {
    width: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  vid: {
    marginTop: 15,
    alignSelf: 'center',
    backgroundColor: 'transparent',
    paddingBottom: '56.25%',
    height: 0,
  },
  vidMobile: {
    alignSelf: 'center',
    backgroundColor: 'transparent',
    paddingBottom: '56.25%',
    height: 0,
  },
});

export default VideoModal;
