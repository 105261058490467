import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import ThriveProgramDay from 'o2x-store/src/models/ThriveProgramDay';
import React, { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
import MoreVertical from '../../assets/images/more-vertical.svg';
type Props = {
  program?: ThriveProgram;
  day?: ThriveProgramDay;
};

const ThriveStartHeader: React.FC<Props> = (props) => {
  const { program, day } = props;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  if (!program) {
    return null;
  }

  const onOptions = useCallback(() => {
    navigation.navigate('ThriveOptions', {
      programId: program.id,
      dayId: day.id,
    });
  }, [program, day]);

  return (
    <Layout style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title} category="h2">
          {program.title}
        </Text>
        {!!day && (
          <Text style={styles.subtitle} category="c1">
            {day.dayDisplay}
          </Text>
        )}
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity onPress={onOptions}>
          <MoreVertical />
        </TouchableOpacity>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginBottom: 32,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 29,
  },
  subtitle: {
    color: 'orange',
    textAlign: 'center',
  },
  buttonContainer: {
    alignSelf: 'center',
  },
  titleContainer: {
    width: '90%',
  },
});

export default observer(ThriveStartHeader);
