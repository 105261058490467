import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import DownloadedItem from 'src/models/DownloadedItem';
import { useNativeStore } from 'src/stores';
import ChevronDown from '../../assets/images/chevron-down.svg';
import SweatIcon from '../../assets/images/sweat-icon.svg';
import VideoPlayer from '../VideoPlayer';

enum WORKOUT_TYPE {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

type Props = {
  item: DownloadedItem;
  workoutType: WORKOUT_TYPE;
  expandedExercise?: number;
  setExpandedExercise: (id?: number) => void;
  type?: string;
  rest?: number | null;
  work?: number | null;
};

const { width } = Dimensions.get('window');

const DLSweatItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { downloads } = useNativeStore();

  const {
    item,
    workoutType,
    expandedExercise,
    type,
    setExpandedExercise,
    work,
    rest,
  } = props;

  const [thumbnail, setThumbnail] = useState('');

  const videoFile = useMemo(() => downloads.getOrCreateMediaFile(item), []);

  useEffect(() => {
    (async () => {
      if (videoFile) {
        const uri = await videoFile.downloadMedia();
        if (uri) {
          const result = await videoFile.getThumbnail();
          setThumbnail(result);
        }
      }
    })();
  }, [videoFile]);

  const onExpand = useCallback(() => {
    if (expandedExercise === item.id) {
      setExpandedExercise(undefined);
    } else {
      setExpandedExercise(item.id);
    }
  }, [expandedExercise]);

  const parseTime = useCallback((time) => {
    if (time) {
      const secs = time % 60;
      const mins = Math.floor(time / 60);
      return `${mins < 10 ? `0${mins}` : mins}:${
        secs < 10 ? `0${secs}` : secs
      }`;
    }
    return '';
  }, []);

  const timerWork = item.timerWork || work;
  const timerRest = item.timerRest || rest;

  return (
    <View>
      {workoutType !== WORKOUT_TYPE.SWEAT && (!!item.sets || !!item.reps) && (
        <Layout style={styles.headerContainer}>
          <View style={styles.headerContent}>
            {!!item.sets && (
              <Text style={styles.headerText}>{`${
                item.sets
              } ${Humanize.pluralize(parseInt(item.sets), 'set')}`}</Text>
            )}
            {!!item.reps && (
              <Text style={[styles.headerText, styles.cyanText]}>
                {`${item.reps} ${Humanize.pluralize(
                  parseInt(item.reps),
                  'rep',
                )}`}
                {item.isRepsEachSide ? ' each side' : ''}
              </Text>
            )}
          </View>
        </Layout>
      )}
      <Layout style={styles.container}>
        <TouchableOpacity onPress={onExpand}>
          <Layout style={styles.contentContainer}>
            <Layout style={styles.content}>
              <Layout style={styles.contentItem}>
                {!!thumbnail ? (
                  <Layout style={styles.thumbnailWrapper}>
                    <Image
                      style={styles.thumbnail as StyleProp<ImageStyle>}
                      source={{ uri: thumbnail }}
                    />
                  </Layout>
                ) : (
                  <Layout style={styles.iconWrapper}>
                    <SweatIcon height={30} width={45} />
                  </Layout>
                )}
                <Layout style={styles.contentTextItem}>
                  <Text style={styles.title} numberOfLines={1}>
                    {item.name}
                  </Text>
                  <Layout style={styles.subtitleContainer}>
                    {!!item.timeDistanceSubtitle && (
                      <Text style={styles.subtitle}>
                        {item.timeDistanceSubtitle}
                      </Text>
                    )}
                    {!!item.subtitle && !item.timeDistanceSubtitle && (
                      <Text style={styles.subtitle}>{item.subtitle}</Text>
                    )}
                    {(!!timerWork || !!timerRest) && (
                      <Text style={styles.timers}>
                        {timerWork && timerRest
                          ? `${parseTime(timerWork)} | ${parseTime(timerRest)}`
                          : timerWork
                          ? `${parseTime(timerWork)}`
                          : `${parseTime(timerRest)}`}
                      </Text>
                    )}
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
            <View style={styles.expandButton}>
              <ChevronDown
                style={expandedExercise === item.id ? styles.up : {}}
              />
            </View>
          </Layout>
        </TouchableOpacity>
        {expandedExercise === item.id && (
          <Layout style={styles.expandContainer}>
            {!!item.description && (
              <Text style={styles.description} category="c1">
                {item.description}
              </Text>
            )}
            {!!item.instructions && (
              <Text style={styles.description} category="c1">
                {item.instructions}
              </Text>
            )}
            {videoFile && (
              <VideoPlayer videoFile={videoFile} size={width} muted={true} />
            )}
          </Layout>
        )}
      </Layout>
      {type === 'tabata' && (
        <Layout style={styles.container}>
          <Layout style={styles.contentContainer}>
            <Layout style={styles.content}>
              <Layout style={styles.contentItem}>
                <Layout style={styles.iconWrapper}>
                  <SweatIcon height={30} width={45} />
                </Layout>
                <Layout style={styles.contentTextItem}>
                  <Text style={styles.title} numberOfLines={1}>
                    REST
                  </Text>
                  <Layout style={styles.subtitleContainer}>
                    <Text style={styles.subtitle}>00:10</Text>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      )}
    </View>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 2,
    backgroundColor: 'darker-blue',
    paddingRight: 8,
  },
  headerContainer: {
    backgroundColor: 'black',
    height: 40,
    justifyContent: 'center',
  },
  headerContent: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginHorizontal: 3,
  },
  cyanText: {
    color: 'cyan',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    minHeight: 60,
  },
  description: {
    margin: 12,
  },
  expandButton: {
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandContainer: {
    backgroundColor: 'wallpaper',
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'cyan',
    width: 4,
  },
  label: {
    flex: 1,
    textAlign: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    width: 60,
    marginRight: 8,
    backgroundColor: 'black',
  },
  title: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  contentItem: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  contentTextItem: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
    width: 60,
    height: 60,
  },
  thumbnailWrapper: {
    width: 60,
    height: 60,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: 'black',
  },
  subtitleContainer: {
    backgroundColor: 'transparent',
    paddingVertical: 2,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'light-gray',
  },
  timers: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: getTaskColor(TASK_TYPE.SWEAT),
  },
  up: {
    transform: [{ rotateZ: '180deg' }],
  },
});

export default DLSweatItem;
