import { filter, transform, uniq } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { LIST_TYPE } from '../utils/list';
import { getError, getSuccess } from '../utils/models';
import { getTaskColor, TASK_TYPE } from '../utils/tasks';
import { Author, RatingsBreakdown } from './Program';
import SweatWorkout from './SweatWorkout';
import UserSweatProgramProgress from './UserSweatProgramProgress';

@model('o2x-store/SweatProgram')
export default class SweatProgram extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  slug: prop<string>(''),
  description: prop<string>(''),
  author: prop<Author>(),
  coverImage: prop<string>(''),
  coverImageBanner: prop<string>(''),
  coverImageCard: prop<string>(''),
  coverImageThumbnail: prop<string>(''),
  lengthInWeeks: prop<number>(0),
  daysPerWeek: prop<string>(''),
  difficulty: prop<string>(''),
  career: prop<string>(''),
  focus: prop<string>(''),
  rating: prop<number>(0),
  userRating: prop<number>(0),
  userProgress: prop<UserSweatProgramProgress | null>(null),
  durationDisplay: prop<string>(''),
  saveList: prop<Array<number>>(() => new Array<number>()),
  viewUrl: prop<string>(''),
  workouts: prop<Array<SweatWorkout>>(),
  equipment: prop<Array<string>>(() => new Array<string>()),
  fteOwner: prop<number>(),
  ossVisible: prop<boolean>(),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(source: ModelInstanceCreationData<SweatProgram>) {
    const data = { ...source };
    const { userProgress } = data;
    delete data.userProgress;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    if (userProgress) {
      this.userProgress = transform(
        userProgress,
        (result: any, value: any, key: string) => {
          if ((key === 'currentWorkout' || key === 'nextWorkout') && value) {
            result[key] = value.id;
            sweat?.createOrUpdateSweatWorkout(value);
          } else {
            result[key] = value;
          }
        },
        {},
      ) as UserSweatProgramProgress;
    }
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @computed
  get title(): string {
    return this.name;
  }

  @computed
  get subtitle(): string {
    return this.durationDisplay;
  }

  @computed
  get image(): string {
    return this.coverImage;
  }

  @computed
  get imageBanner(): string {
    return this.coverImageBanner || this.coverImage;
  }

  @computed
  get imageCard(): string {
    return this.coverImageCard || this.coverImage;
  }

  @computed
  get imageThumbnail(): string {
    return this.coverImageThumbnail || this.coverImage;
  }

  @computed
  get type(): TASK_TYPE {
    return TASK_TYPE.SWEAT;
  }

  @computed
  get sweat_list_type(): LIST_TYPE {
    return LIST_TYPE.SWEAT_PROGRAM;
  }

  @computed
  get color(): string {
    return getTaskColor(TASK_TYPE.SWEAT);
  }

  @computed
  get isCurrentWorkoutComplete(): boolean {
    const id = this.userProgress?.currentWorkout;
    if (!this.userProgress || !id) {
      return false;
    }
    return !!this.userProgress.sweatWorkoutProgress.find(
      (workoutProgress) =>
        workoutProgress.workout === id && workoutProgress.isComplete,
    );
  }

  @computed
  get ratingsBreakdown(): RatingsBreakdown | undefined {
    const rootStore = getRoot<RootStore>(this);
    if (rootStore.getProgramRatings) {
      return rootStore.getProgramRatings(this.type, this.id);
    }
  }

  @modelFlow
  fetchRatings = _async(function* (this: SweatProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchSweatProgramRatings(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching ratings', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  postRate = _async(function* (this: SweatProgram, rate: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.postSweatProgramRate(rootStore.auth.token, this.id, rate),
      ));
    } catch (error) {
      console.warn('[DEBUG] error post rate', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;
    this.userRating = rate;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  startProgram = _async(function* (this: SweatProgram, params?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<SweatProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.startSweatProgram(rootStore.auth.token, this.id, params),
      ));
    } catch (error) {
      console.warn('[DEBUG] error starting sweat program', error);
      return getError(error);
    }

    this.update(entities);

    analytics.logProgramStart(this);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markComplete = _async(function* (this: SweatProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token || !this.userProgress) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<SweatProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.markCompleteProgram(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error marking complete sweat program', error);
      return getError(error);
    }

    this.update(entities);

    analytics.logProgramComplete(this);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markCompleteCurrentWorkout = _async(function* (
    this: SweatProgram,
    data: {
      totalTime: number;
      stepExerciseTimeBreakdown: { [key: number]: number };
      globalStepExerciseTimeBreakdown: { [key: number]: number };
      circuitBreakdownSets?: { [key: number]: number };
    },
    workoutId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (
      !rootStore.auth?.token ||
      !this.userProgress ||
      !this.userProgress.currentWorkout
    ) {
      return getSuccess();
    }

    this.loading = true;

    const workout = !!workoutId ? workoutId : this.userProgress?.currentWorkout;

    let entities: ModelInstanceCreationData<SweatProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.markCompleteSweatProgramWorkout(
          rootStore.auth.token,
          this.id,
          workout,
          data,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error marking complete sweat program', error);
      return getError(error);
    }

    this.update(entities);

    const workoutObj = rootStore.sweat.sweatWorkouts.get(`${workout}`);
    if (workoutObj) analytics.logSweatWorkoutComplete(workoutObj, this);

    if (!this.userProgress.currentWorkout && !this.userProgress.nextWorkout) {
      const result = yield* _await(this.markComplete());
      this.loading = false;
      return result;
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchProgramWorkouts = _async(function* (this: SweatProgram) {
    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelInstanceCreationData<SweatWorkout>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchSweatProgramWorkouts(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat program workouts', error);
      return getError(error);
    }

    results.forEach((workout) => {
      sweat.createOrUpdateSweatWorkout(workout);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  startWorkout = _async(function* (this: SweatProgram, workoutId?: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    const workout = !!workoutId
      ? workoutId
      : this.isCurrentWorkoutComplete
      ? this.userProgress?.nextWorkout
      : this.userProgress?.currentWorkout;
    if (!workout) {
      return getSuccess();
    }

    let entities: ModelInstanceCreationData<SweatProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.startSweatProgramWorkout(rootStore.auth.token, this.id, workout),
      ));
    } catch (error) {
      console.warn('[DEBUG] error starting next workout', error);
      return getError(error);
    }

    this.update(entities);

    const workoutObj = rootStore.sweat.sweatWorkouts.get(`${workout}`);
    analytics.logSweatWorkoutStart(workoutObj, this);

    this.loading = false;
    return getSuccess({ workout });
  });
}
