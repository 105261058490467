import { Layout, Text, Icon } from '@ui-kitten/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Slide from './Slide';
import SweatWorkout from '../../../o2x-store/src/models/SweatWorkout';

type Props = {
  data: SweatWorkout[];
  onPress: any;
  showNumbers: boolean;
  loadMoreWorkouts: () => void;
  workoutsCount: number;
};

const SwipeableList: React.FC<Props> = (props) => {
  const { data, onPress, showNumbers, loadMoreWorkouts, workoutsCount } = props;

  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;

  const onPrev = () => {
    setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  useEffect(() => {
    if (data.length - index < 5) {
      loadMoreWorkouts();
    }
  }, [index]);

  const onNext = () => {
    setIndex((prevIndex) => Math.min(prevIndex + 1, data.length - 1));
  };

  if (!data) return <></>;
  return (
    <Layout
      style={{
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
      }}
      key={data[index].id.toString()}
    >
      <View style={{ width: '100%', paddingHorizontal: 24 }}>
        <TouchableOpacity
          onPress={() => onPress(data[index].id)}
          style={{ width: '100%' }}
        >
          <View
            style={{
              paddingHorizontal: 12,
              marginTop: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderWidth: 1,
              borderColor: '#fff',
              width: '100%',
            }}
          >
            <TouchableOpacity onPress={onPrev} disabled={index === 0}>
              <Icon
                style={{
                  margin: 10,
                  width: 30,
                  height: 30,
                }}
                fill="white"
                name="chevron-left"
              />
            </TouchableOpacity>
            <Slide data={data[index]} />
            <TouchableOpacity
              onPress={onNext}
              disabled={index === data?.length - 1}
            >
              <Icon
                style={{
                  margin: 10,
                  width: 30,
                  height: 30,
                }}
                fill="white"
                name="chevron-right"
              />
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
      {showNumbers && (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Text style={{ flex: 1 }}>
            {index + 1} of {workoutsCount}
          </Text>
        </View>
      )}
    </Layout>
  );
};

export default SwipeableList;
