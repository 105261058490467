import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import Confetti from '../../assets/images/confetti.svg';
import Logo from '../../assets/images/logo-dark-blue.svg';
import TacticalPerformance from '../../assets/images/tactical-performance.svg';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';
type Props = {
  route: RouteProp<AppStackParamList, 'ThriveActivityComplete'>;
  navigation: StackNavigationProp<AppStackParamList, 'ThriveActivityComplete'>;
};

const ThriveActivityComplete: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {
    route: {
      params: { activity },
    },
    navigation,
  } = props;
  const store = useStore();
  const activityText = useMemo(() => {
    switch (activity) {
      case THRIVE_ACTIVITY.SLEEP:
        return 'sleep';
      case THRIVE_ACTIVITY.BREATHE:
        return 'breathing exercise';
      case THRIVE_ACTIVITY.MEDITATE:
        return 'meditation';
      default:
        return 'thrive activity';
    }
  }, [activity]);

  const styles = useStyleSheet(themedStyles);
  const [isSharing, setIsSharing] = useState<boolean>(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onClose = useCallback(() => {
    navigation.popToTop();
  }, []);

  // const onShare = useCallback(() => {
  //   setIsSharing(true);
  // }, [setIsSharing]);

  const stopSharing = useCallback(() => {
    setIsSharing(false);
  }, [setIsSharing]);

  useEffect(
    useCallback(() => {
      if (isSharing) {
        doShare();
      }
    }, [isSharing]),
  );

  const doShare = useCallback(async () => {
    //Sharing is not supported in Web

    // await captureRef(viewShotRef, {
    //   format: 'jpg',
    //   quality: 0.8,
    //   result: 'base64',
    // }).then(
    //   async (res) => {
    //     let filename = 'share.jpg';
    //     let filepath = `${FileSystem.documentDirectory}/${filename}`;
    //     await FileSystem.writeAsStringAsync(filepath, res, {
    //       encoding: 'base64',
    //     });
    //     await Sharing.shareAsync(filepath, { mimeType: 'image/jpg' });
    //   },
    //   (error) => {
    //     console.log('error', error);
    //   },
    // );

    stopSharing();
  }, [stopSharing]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Image
              style={styles.cover as StyleProp<ImageStyle>}
              source={require('../../assets/images/meditate-thumbnail.png')}
            />
            <LinearGradient
              colors={['transparent', '#10283E']}
              style={styles.gradient}
            />
            <Layout
              style={[styles.navigationContainer, styles.transparentBackground]}
            >
              <TopNav
                showBack={false}
                showClose={true}
                onClose={onClose}
                style={styles.transparentBackground}
              />
            </Layout>

            <ScrollView
              style={styles.scroll}
              contentContainerStyle={styles.contentScroll}
            >
              <View style={styles.screenshotContainer}>
                <Confetti style={styles.icon} width={150} height={150} />
                <Text style={styles.label} category="h1">
                  {'Great work!'}
                </Text>
              </View>

              <View style={[styles.titleContainer]}>
                <Text style={styles.programName}>
                  You've completed your {activityText}.
                </Text>
              </View>

              {/* <View style={styles.actions}>
                {!isSharing && (
            <TouchableOpacity style={styles.actionItem} onPress={onShare}>
              <ShareIcon
                style={styles.actionIcon}
                stroke="#C4C4C4"
                color="#C4C4C4"
              />
              <Text style={styles.actionText}>Share</Text>
            </TouchableOpacity>
          )}
              </View> */}

              <View style={styles.logoContainer}>
                <Logo />
                <TacticalPerformance />
              </View>
            </ScrollView>
            <BottomNav
              showBack={false}
              nextColor={theme['dark-blue']}
              onNext={onClose}
              nextLabel="Finish"
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  contentScroll: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
    paddingVertical: 24,
    paddingTop: 0,
  },
  cover: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  gradient: {
    height: 350,
    position: 'absolute',
    width: '100%',
  },
  icon: {
    marginBottom: 24,
    alignSelf: 'center',
  },
  label: {
    textAlign: 'center',
  },
  programName: {
    marginBottom: 8,
    paddingHorizontal: 60,
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 22,
  },
  scroll: {
    flex: 1,
  },
  actions: {
    flexDirection: 'row',
    marginTop: 40,
  },
  actionIcon: {
    marginBottom: 7,
  },
  actionItem: {
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  actionText: {
    color: 'light-gray',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  titleContainer: {
    alignItems: 'center',
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  screenshotContainer: {
    width: '100%',
    paddingBottom: 30,
    justifyContent: 'center',
  },
  logoContainer: {
    marginTop: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default observer(ThriveActivityComplete);
