import {
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { StyleProp, TextInput, View, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  error: string;
  value: string;
  onChangeText: (text: string) => void;
  unit?: string;
  label?: string;
};

const PASS_FAIL_OPTIONS = [
  { label: 'Pass', value: '100' },
  { label: 'Fail', value: '0' },
];

const AssessmentScoring: React.FC<Props> = (props) => {
  const { style, error, value, onChangeText, label, unit } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const hasError = error.length > 0;

  const getSelectIndex = useCallback(() => {
    if (value) {
      return value === '100' ? new IndexPath(1) : new IndexPath(2);
    }
    return undefined;
  }, [value]);

  const getSelectValue = useCallback(() => {
    if (value) {
      return value === '100' ? 'Pass' : 'Fail';
    }
    return undefined;
  }, [value]);

  const renderPassFail = useCallback(
    () => (
      <View style={styles.select}>
        <Select
          style={styles.input}
          selectedIndex={getSelectIndex()}
          value={getSelectValue()}
          onSelect={(index: IndexPath | IndexPath[]) => {
            onChangeText(`${PASS_FAIL_OPTIONS[Number(index) - 1].value}`);
          }}
        >
          {PASS_FAIL_OPTIONS.map(
            (option: { label: string; value: string }, index: number) => {
              return (
                <SelectItem key={`option-${index}`} title={option.label} />
              );
            },
          )}
        </Select>
        {hasError && <Text style={styles.errorMessage}>{error}</Text>}
      </View>
    ),
    [getSelectIndex, getSelectValue, onChangeText, value, hasError, error],
  );

  const renderTimeInput = useCallback(() => {
    const timeCallback = (text: string) => {
      let result = '';
      let duration = text.replace(/[^0-9]+/g, '');
      if (duration.length % 2 === 1 && duration.length > 1) {
        result = `${duration.slice(0, 1)}:`;
        duration = duration.slice(1);
      }
      result += duration.replace(/..\B/g, '$&:');
      onChangeText(result);
    };
    return (
      <TextInput
        style={[
          styles.input,
          {
            borderColor: hasError ? 'red' : '#4E7B89',
          },
        ]}
        placeholderTextColor={theme['white']}
        keyboardType="number-pad"
        value={value}
        onChangeText={timeCallback}
        placeholder={label ? label : '0'}
      />
    );
  }, [onChangeText, value, hasError, error]);

  const renderInput = useCallback(
    (unit?: string) => {
      const onChangeNumberInput = (text: string) => {
        const result =
          unit === 'reps'
            ? text.replace(/[^0-9]+/g, '')
            : text.replace(/[^0-9.]+/g, '');
        onChangeText(result);
      };

      return (
        <TextInput
          style={[
            styles.input,
            {
              borderColor: hasError ? 'red' : '#4E7B89',
            },
          ]}
          placeholderTextColor={theme['white']}
          keyboardType="numbers-and-punctuation"
          value={value}
          onChangeText={onChangeNumberInput}
          placeholder={label ? label : '0'}
        />
      );
    },
    [onChangeText, value, hasError, error],
  );

  const renderField = useCallback(() => {
    let field;
    switch (unit) {
      case 'pass_fail':
        field = renderPassFail();
        break;
      case 'seconds':
        field = renderTimeInput();
        break;
      default:
        field = renderInput(unit);
        break;
    }
    return field;
  }, [value, unit, hasError, error, onChangeText]);

  return (
    <Layout style={style}>
      {hasError && <Text style={styles.error}>{error}</Text>}
      {renderField()}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  input: {
    width: '100%',
    flex: 1,
    height: 8,
    backgroundColor: 'transparent',
    borderBottomWidth: 1,
    outlineWidth: 0,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    paddingBottom: 6,
  },
  label: {
    marginRight: 8,
    fontFamily: 'Lato-Bold',
    textTransform: 'uppercase',
  },
  error: {
    fontSize: 12,
    color: 'red',
  },
  select: {
    flexDirection: 'column',
    flex: 1,
  },
  errorMessage: {
    flex: 1,
    fontSize: 12,
    color: 'red',
    position: 'relative',
    top: 10,
  },
});

export default AssessmentScoring;
