import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Switch } from 'react-native-switch';
import Coffee from '../../assets/images/coffee.svg';
import DartNo from '../../assets/images/dart-no.svg';
import DartYes from '../../assets/images/dart-yes.svg';
import DrinkingWater from '../../assets/images/drinking-water.svg';
import EmptyCoffee from '../../assets/images/empty-coffee.svg';
import NotDrinkingWater from '../../assets/images/not-drinking-water.svg';
import NotPlanningCaloricIntake from '../../assets/images/not-planning-caloric-intake.svg';
import PlanningCaloricIntake from '../../assets/images/planning-caloric-intake.svg';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  disabled?: boolean;
  fill?: string;
}

const ToggleInput: React.FC<Props> = (props) => {
  const {
    question,
    onChange,
    initial,
    required,
    disabled = false,
    fill,
  } = props;
  const styles = useStyleSheet(themedStyles);
  let initialIndex = 0;
  (question.options || []).forEach(
    (option: Array<number | string>, index: number) => {
      if (option[0] === initial) {
        initialIndex = index;
      }
    },
  );
  const [checked, setChecked] = useState<boolean>(
    !!initialIndex ? Boolean(initialIndex) : false,
  );

  const onCheckedChange = useCallback(
    (isChecked: boolean) => {
      setChecked(isChecked);
      onChange(question.key, isChecked ? 1 : 0);
      onChange(question.key, question.options[isChecked ? 1 : 0][0]);
    },
    [question, onChange, setChecked],
  );

  useEffect(() => {
    onCheckedChange(checked);
  }, [checked, onCheckedChange]);

  const renderIcon = useCallback(
    (YesIcon: any, NoIcon: any) => (checked ? <YesIcon /> : <NoIcon />),
    [checked],
  );

  const renderField = useCallback(() => {
    if (question) {
      switch (question.key) {
        case 'doDrinkWater':
          return renderIcon(DrinkingWater, NotDrinkingWater);
        case 'doDrinkCaffeine':
          return renderIcon(Coffee, EmptyCoffee);
        case 'doPlanCaloricIntake':
          return renderIcon(PlanningCaloricIntake, NotPlanningCaloricIntake);
        case 'goalsAchieved':
          return renderIcon(DartYes, DartNo);
        default:
          return null;
      }
    }
  }, [question, renderIcon]);

  return (
    <View style={styles.toggleContainer}>
      <Switch
        value={checked}
        onValueChange={(val) => onCheckedChange(val)}
        activeText={'YES'}
        inActiveText={'NO'}
        circleSize={80}
        barHeight={80}
        circleBorderWidth={0}
        backgroundActive={fill}
        backgroundInactive={'#C4C4C4'}
        circleActiveColor={'white'}
        circleInActiveColor={'white'}
        renderInsideCircle={() => renderField()}
        innerCircleStyle={styles.circle}
        switchBorderRadius={40}
        activeTextStyle={styles.active}
        inactiveTextStyle={styles.inactive}
        switchLeftPx={8}
        switchRightPx={5}
      />
    </View>
  );
};

const themedStyles = StyleService.create({
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    fontSize: 32,
    fontWeight: '700',
    paddingRight: 10,
    color: '#203950',
  },
  inactive: {
    fontSize: 32,
    fontWeight: '700',
    paddingLeft: 10,
    color: '#31576D',
  },
  toggleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 130,
  },
});

export default ToggleInput;
