import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  fill: string;
  onChange?: (k: string, v: any) => void;
  checked: boolean;
  field?: string;
  label?: string;
  children?: any;
  style?: any;
  optionStyle?: any;
  optionStyleSelected?: any;
  status?: string;
  labelStyle?: any;
  disabled?: boolean;
};

const CheckBox: React.FC<Props> = (props) => {
  const { fill, checked, onChange, label, style, labelStyle, disabled, field } =
    props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  const onPress = useCallback(() => {
    const itemKey = field;
    if (itemKey && onChange) {
      onChange(itemKey, !checked);
    }
    if (!itemKey && !!onChange) {
      onChange();
    }
  }, [field, onChange, checked]);

  if (disabled) {
    return (
      <View style={[styles.container, style]}>
        <Layout
          style={[
            styles.box,
            checked && styles.checked,
            checked && { backgroundColor: fill },
          ]}
        >
          {checked && (
            <Icon
              style={styles.checkmark}
              name="checkmark"
              fill={theme['black']}
            />
          )}
        </Layout>
        {!!label && (
          <Text style={[styles.label, labelStyle, checked && { color: fill }]}>
            {label}
          </Text>
        )}
      </View>
    );
  }

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Layout
        style={[
          styles.box,
          checked && styles.checked,
          checked && { backgroundColor: fill },
        ]}
      >
        {checked && (
          <Icon
            style={styles.checkmark}
            name="checkmark"
            fill={theme['black']}
          />
        )}
      </Layout>
      {!!label && (
        <Text style={[styles.label, labelStyle, checked && { color: fill }]}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  box: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderColor: 'white',
    borderWidth: 2,
  },
  checked: {
    borderWidth: 0,
  },
  checkmark: {
    height: 10,
    width: 10,
  },
  container: {
    alignItems: 'center',
  },
  label: {
    flex: 1,
    paddingHorizontal: 10,
  },
});

export default CheckBox;
