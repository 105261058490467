import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import AskAnExpert from 'src/containers/AskAnExpert';
import ProgramDetail from 'src/containers/ProgramDetail';
import RatingModal from 'src/containers/RatingModal';
import SaveToList from 'src/containers/Saved/SaveToList';
import SweatContinue from 'src/containers/SweatContinue';
import SweatOptions from 'src/containers/SweatOptions';
import SweatOptionsJump from 'src/containers/SweatOptions/SweatOptionsJump';
import SweatOptionsQuit from 'src/containers/SweatOptions/SweatOptionsQuit';
import SweatStart from 'src/containers/SweatStart';
import SweatStartDetail from 'src/containers/SweatStart/SweatStartDetail';
import SweatWorkoutComplete from 'src/containers/SweatWorkoutComplete';
import TeamChallenge from 'src/containers/Teams/TeamChallenge';
import TeamDelete from 'src/containers/Teams/TeamDelete';
import TeamDetailInvite from 'src/containers/Teams/TeamDetailInvite';
import TeamEdit from 'src/containers/Teams/TeamEdit';
import TeamInvite from 'src/containers/Teams/TeamInvite';
import ThriveBreathe from 'src/containers/ThriveBreathe';
import ThriveMeditate from 'src/containers/ThriveMeditate';
import ThriveOptions from 'src/containers/ThriveOption';
import ThriveOptionsJump from 'src/containers/ThriveOption/ThriveOptionsJump';
import ThriveOptionsQuit from 'src/containers/ThriveOption/ThriveOptionsQuit';
import ThriveProgramDayComplete from 'src/containers/ThriveProgramDayComplete';
import ThriveSetTime from 'src/containers/ThriveSetTime';
import ThriveSleep from 'src/containers/ThriveSleep';
import ThriveStart from 'src/containers/ThriveStart';
import Header from '../../Header';
import ProgramPreview from '../../ProgramDetail/Preview';
import Teams from '../../Teams';
import SweatWorkoutSummaryEditModal from '../modals/SweatWorkoutSummaryEditModal';

type Props = {};

const Stack = createStackNavigator();

const TeamStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Teams" component={Teams} />
    <Stack.Screen name="TeamEdit" component={TeamEdit} />
    <Stack.Screen name="TeamDelete" component={TeamDelete} />
    <Stack.Screen name="TeamInvite" component={TeamInvite} />
    <Stack.Screen name="TeamDetailInvite" component={TeamDetailInvite} />

    <Stack.Screen name="ProgramDetail" component={ProgramDetail} />
    <Stack.Screen name="ProgramPreview" component={ProgramPreview} />
    <Stack.Screen name="RatingModal" component={RatingModal} />
    <Stack.Screen name="SweatStart" component={SweatStart} />
    <Stack.Screen name="SweatContinue" component={SweatContinue} />
    <Stack.Screen name="SweatStartDetail" component={SweatStartDetail} />
    <Stack.Screen
      name="SweatWorkoutComplete"
      component={SweatWorkoutComplete}
    />
    <Stack.Screen
      name="SweatWorkoutSummary"
      component={SweatWorkoutSummaryEditModal}
    />
    <Stack.Screen name="TeamChallenge" component={TeamChallenge} />
    <Stack.Screen name="SweatOptions" component={SweatOptions} />
    <Stack.Screen name="SweatOptionsQuit" component={SweatOptionsQuit} />
    <Stack.Screen name="SweatOptionsJump" component={SweatOptionsJump} />
    <Stack.Screen name="ThriveStart" component={ThriveStart} />
    <Stack.Screen name="ThriveOptionsJump" component={ThriveOptionsJump} />
    <Stack.Screen name="ThriveOptionsQuit" component={ThriveOptionsQuit} />
    <Stack.Screen
      name="ThriveProgramDayComplete"
      component={ThriveProgramDayComplete}
    />
    <Stack.Screen name="ThriveSleep" component={ThriveSleep} />
    <Stack.Screen name="ThriveBreathe" component={ThriveBreathe} />
    <Stack.Screen name="ThriveMeditate" component={ThriveMeditate} />
    <Stack.Screen name="ThriveSetTime" component={ThriveSetTime} />
    <Stack.Screen name="ThriveOptions" component={ThriveOptions} />
    <Stack.Screen name="AskAnExpert" component={AskAnExpert} />
    <Stack.Screen name="SaveToList" component={SaveToList} />
  </Stack.Navigator>
);

export default TeamStack;
