import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import Organization from '../models/Organization';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/OrganizationStore')
export default class OrganizationStore extends Model({
  organizations: prop_mapObject(() => new Map<string, Organization>()),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateOrganization(data: ModelInstanceCreationData<Organization>) {
    const id = `${data.id}`;
    if (this.organizations.has(id)) {
      this.organizations.get(id)!.update(data);
    } else {
      const organization = new Organization(data);
      this.organizations.set(id, organization);
      organization.update(data);
    }
  }

  @modelFlow
  fetchOrganizations = _async(function* (this: OrganizationStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Organization>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchOrganizations(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching organizations', error);
      return getError(error);
    }

    results.forEach((data) => {
      this.createOrUpdateOrganization(data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchOrganization = _async(function* (this: OrganizationStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelInstanceCreationData<Organization>;
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchOrganization(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching organizations', error);
      return getError(error);
    }
    console.log(results);
    this.createOrUpdateOrganization(results);

    this.loading = false;
    return getSuccess(results);
  });

  @modelFlow
  joinOrganization = _async(function* (this: OrganizationStore, code: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.joinOrganization(rootStore.auth.token, { inviteCode: code }),
      ));
    } catch (error) {
      console.warn('[DEBUG] error joining organization', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
