import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import {
  Icon,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import { DashboardModel } from 'o2x-store/src/models/Dashboard';
import { useStore } from 'o2x-store/src/stores';
import React, { Fragment, useCallback, useState } from 'react';
import { Dimensions, ScrollView, TouchableOpacity, View } from 'react-native';
import RenderHTML from 'react-native-render-html';
import WebView from 'react-native-webview';
import ReactPlayer from 'react-player';

type Props = {
  content: DashboardModel;
  onDelete: () => void;
  onEdit: () => void;
  isMobile: boolean;
};

const { width, height } = Dimensions.get('window');

const DashboardContent: React.FC<Props> = (props) => {
  const { content, onDelete, onEdit, isMobile } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [isModalVisible, setModalVisible] = useState(false);
  const customHTMLElementModels = {
    iframe: iframeModel,
  };

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onDownloadFile = useCallback(async (id: number) => {
    window.open(
      `${config.urls.api}${config.urls.dashboardFiles}download/${id}`,
    );
  }, []);

  const classStyles = {
    'ql-align-center': { textAlign: 'center' },
    'ql-align-right': { textAlign: 'right' },
    'ql-align-left': { textAlign: 'left' },
    'ql-align-justify': { textAlign: 'justify' },
  };

  return (
    <Layout style={styles.dashboardContainer}>
      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={hideModal}
      >
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <View style={styles.modalContent}>
            <Text style={styles.titleViewContainer}>{content?.title}</Text>
            <Text style={styles.userViewContainer}>
              {content?.userDetails.email}
            </Text>
            <Text style={styles.dateViewContainer}>
              {format(parseISO(content.created), 'yyyy-MM-dd H:mma')}
            </Text>
            <ScrollView style={styles.bodyViewContainer}>
              {/* <HTML
                tagsStyles={{
                  p: styles.bodyStyle,
                  h1: styles.bodyStyle,
                  h2: styles.bodyStyle,
                  blockquote: styles.bodyStyle,
                  li: styles.bodyStyle,
                  img: styles.image,
                  iframe: styles.iframe,
                }}
                source={{ html: content.body }}
                renderers={{ iframe }}
                WebView={WebView}
              /> */}
              <RenderHTML
                renderers={{ iframe: IframeRenderer }}
                tagsStyles={{
                  p: styles.bodyStyle,
                  h1: styles.bodyStyle,
                  h2: styles.bodyStyle,
                  blockquote: styles.bodyStyle,
                  ul: styles.bodyStyle,
                  ol: styles.bodyStyle,
                  li: styles.bodyStyle,
                  img: styles.image,
                  iframe: styles.iframe,
                }}
                WebView={WebView}
                // classesStyles={classStyles}
                customHTMLElementModels={customHTMLElementModels}
                source={{ html: content.body }}
                enableExperimentalBRCollapsing
                enableExperimentalGhostLinesPrevention
                // contentWidth={useWindowDimensions().width}
              />
              {content.files
                .filter(
                  (file) => file.type === 'mp4' || file.type === 'quicktime',
                )
                .map((file, index) => (
                  <Fragment key={index}>
                    <ReactPlayer
                      key={index}
                      url={file.file}
                      style={{
                        flex: 1,
                        marginTop: 10,
                      }}
                      width="50%"
                      playing={false}
                      loop={true}
                      controls
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nofullscreen',
                          },
                        },
                      }}
                    />
                    <Text style={styles.fileName} numberOfLines={1}>
                      {file.name}
                    </Text>
                  </Fragment>
                ))}
            </ScrollView>
          </View>
          {content.files && content.files.length > 0 && (
            <Layout
              style={{
                width: '100%',
                height: 50,
              }}
            >
              <ScrollView
                style={{ width: '100%', backgroundColor: 'transparent' }}
                contentContainerStyle={{
                  width: '100%',
                  backgroundColor: 'transparent',
                }}
                horizontal={true}
              >
                {content.files.map((file, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={styles.attachmentContainer}
                      onPress={() => onDownloadFile(file.id)}
                    >
                      <Text style={styles.fileName} numberOfLines={1}>
                        {file.name}
                      </Text>
                      <Text style={styles.fileSize} numberOfLines={1}>
                        {(file.fileSize / (1024 * 1024)).toFixed(2)} MB
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </Layout>
          )}
        </Layout>
      </Modal>
      <TouchableOpacity
        style={styles.titleContainer}
        onPress={() => showModal()}
      >
        <Text numberOfLines={1} style={styles.title}>
          {content?.title}
        </Text>
        <Text style={styles.detail}>
          {format(parseISO(content.created), 'yyyy-MM-dd H:mma')}
        </Text>
      </TouchableOpacity>
      <Layout style={styles.buttonContainer}>
        <TouchableOpacity onPress={onEdit}>
          <Icon
            style={styles.buttonIcon}
            fill="#4E7B89"
            name="edit-2-outline"
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={onDelete}>
          <Icon style={styles.buttonIcon} fill="#4E7B89" name="trash-outline" />
        </TouchableOpacity>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  dashboardContainer: {
    width: '100%',
    // height: '55px',
    borderBottom: '1px solid black',
    padding: 10,
    flexDirection: 'row',
    flex: 1,
  },
  buttonContainer: {
    flexDirection: 'row',
    position: 'absolute',
    right: 20,
    top: 20,
  },
  titleContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 20,
  },
  buttonIcon: {
    height: 18,
    width: 18,
  },
  title: {
    maxWidth: '80%',
    fontSize: 15,
    color: 'white',
  },
  detail: {
    fontSize: 10,
    color: 'white',
    opacity: 0.6,
    marginTop: 5,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: width / 2.5,
    height: height / 2,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  modalMobile: {
    width: width / 1.1,
    height: height / 1.8,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  modalContent: {
    width: '100%',
    position: 'relative',
    marginTop: 50,
    flex: 1,
  },
  titleViewContainer: {
    fontSize: 20,
    lineHeight: 19,
    fontWeight: 400,
    alignText: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'uppercase',
  },
  userViewContainer: {
    marginTop: 5,
    paddingLeft: 20,
    fontSize: 12,
    fontWeight: 50,
    opacity: 0.5,
    fontStyle: 'italic',
  },
  dateViewContainer: {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 20,
    fontSize: 12,
    fontWeight: 10,
    opacity: 0.5,
    fontStyle: 'italic',
  },
  bodyViewContainer: {
    padding: 20,
    height: 350,
  },
  bodyStyle: {
    marginTop: 0,
    marginBottom: 0,
    color: 'white',
  },
  image: {
    maxWidth: 450,
    maxHeight: 300,
  },
  iframe: {
    maxWidth: 450,
    maxHeight: 300,
  },
  attachmentContainer: {
    height: '80%',
    width: '25%',
    backgroundColor: '#1A3248',
    marginVertical: 'auto',
    marginHorizontal: 5,
  },
  fileName: {
    fontSize: 12,
    marginVertical: 'auto',
    marginLeft: 5,
  },
  fileSize: {
    fontSize: 8,
    marginVertical: 'auto',
    marginLeft: 5,
    color: 'grey',
  },
});

export default observer(DashboardContent);
