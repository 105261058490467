import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  Alert,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { RatingModalParamList } from '../AppContainer/modals/RatingStackModal';

type Props = {
  route: RouteProp<RatingModalParamList, 'RatingModal'>;
};

const RatingModal: React.FC<Props> = (props) => {
  const { type, id } = props.route.params;
  const store = useStore();
  const program = store.getProgram(type, id);
  const [rating, setRating] = useState(program?.userRating || 0);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const stars = Array.from(Array(5), (_, i) => i + 1);

  const onChangeRating = useCallback((newRating) => {
    setRating((rating) => {
      if (rating === newRating) {
        return 0;
      } else {
        return newRating;
      }
    });
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onRate = useCallback(() => {
    (async () => {
      if (rating !== 0) {
        const res = await program?.postRate(rating);
        if (res && !res.ok) {
          Alert.alert(
            'IMPORTANT',
            'It seems that you are offline. Please check your internet connection.',
            [
              {
                text: 'Go Back',
                onPress: () => navigation.goBack(),
                style: 'cancel',
              },
            ],
          );
        }
        // return;
      }
      navigation.goBack();
    })();
  }, [program, rating]);

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.container}>
        <Layout style={styles.modal}>
          <View style={styles.content}>
            <Text
              style={styles.header}
              category="c1"
            >{`Rate ${program?.name}`}</Text>
            <View style={styles.ratingContainer}>
              {stars.map((rate) => (
                <TouchableOpacity
                  style={styles.ratingButton}
                  key={`${rate}`}
                  onPress={() => onChangeRating(rate)}
                >
                  <Icon
                    fill={rating >= rate ? theme['white'] : theme['black']}
                    stroke={theme['gray']}
                    strokeWidth={0.5}
                    width={36}
                    height={36}
                    name="star"
                  />
                </TouchableOpacity>
              ))}
            </View>
          </View>
          <View style={styles.actions}>
            <TouchableOpacity style={styles.action} onPress={goBack}>
              <Text style={styles.actionText} category="c1">
                Cancel
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.action, styles.rateAction]}
              onPress={onRate}
            >
              <Text style={styles.actionText} category="c1">
                Rate
              </Text>
            </TouchableOpacity>
          </View>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  action: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
  },
  actionText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000080',
  },
  content: {
    padding: 24,
  },
  header: {
    marginBottom: 12,
  },
  modal: {
    maxWidth: 268,
    borderRadius: 4,
  },
  rateAction: {
    backgroundColor: 'olive',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingButton: {
    padding: 4,
  },
});

export default RatingModal;
