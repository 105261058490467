import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';
import { Image, ImageStyle, StyleProp, View } from 'react-native';

type Props = {};

const Sweat: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <>
      <Image
        style={styles.image as StyleProp<ImageStyle>}
        source={require('../../assets/images/sweat-onboarding.png')}
      />
      <Text style={styles.header} category="h1">
        Sweat
      </Text>
      <Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>
            Strength and conditioning plans
          </Text>
        </Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>Workout generator</Text>
        </Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>
            Injury risk reduction programs
          </Text>
        </Layout>
      </Layout>
      <Text
        style={[
          styles.text,
          styles.bold,
          styles.paddingTop,
          styles.limitedWidth,
        ]}
      >
        All from the world's leading specialists
      </Text>
    </>
  );
};

const themedStyles = StyleService.create({
  header: {
    paddingBottom: 13,
    textTransform: 'uppercase',
  },
  image: {
    height: 210,
    width: 296,
    resizeMode: 'cover',
    marginBottom: 70,
  },
  paddingTop: {
    paddingTop: 23,
  },
  bold: {
    fontFamily: 'Lato-Bold',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
    marginLeft: 8,
  },
  marker: {
    width: 5,
    height: 5,
    backgroundColor: '#04B7D6',
  },
  outlineText: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '80%',
  },
  limitedWidth: {
    textAlign: 'center',
    paddingHorizontal: '20%',
  },
});

export default Sweat;
