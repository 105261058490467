import { observable } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { ASSESSMENT_TYPE } from './Assessment';
import { AssessmentSubmissionItem } from './AssessmentSubmissionItem';

type SectionData = {
  name: string;
  done: boolean;
  totalAnswered: number;
  totalCount: number;
};

@model('o2x-store/FormAssessmentSubmission')
export default class FormAssessmentSubmission extends Model({
  id: prop<number>(),
  user: prop<number>(),
  assessment: prop<number>(),
  assessmentName: prop<string>(),
  coverImageThumbnail: prop<string | null>(null),
  created: prop<string>(),
  modified: prop<string>(),
  score: prop<number | null>(),
  sectionsData: prop<SectionData[]>(() => []),
  items: prop<Array<AssessmentSubmissionItem>>(),
  eatScore: prop<number | null>(),
  sweatScore: prop<number | null>(),
  thriveScore: prop<number | null>(),
  result: prop<Object | null>(() => ({})),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;
  type = ASSESSMENT_TYPE.FORM;

  @modelAction
  update(data: ModelInstanceCreationData<FormAssessmentSubmission>) {
    Object.assign(this, data);
  }
}
