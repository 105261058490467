import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';
import { Image, ImageStyle, StyleProp, View } from 'react-native';
type Props = {};

const Eat: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <>
      <Image
        style={styles.image as StyleProp<ImageStyle>}
        source={require('../../assets/images/eat-onboarding.png')}
      />
      <Text style={styles.header} category="h1">
        Eat
      </Text>
      <Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>Track your intake</Text>
        </Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>
            Explore healthy recipes
          </Text>
        </Layout>
        <Layout style={styles.outlineText}>
          <View style={styles.marker}></View>
          <Text style={[styles.text, styles.bold]}>
            Create optimal nutrition habits
          </Text>
        </Layout>
      </Layout>
    </>
  );
};

const themedStyles = StyleService.create({
  header: {
    paddingBottom: 13,
    textTransform: 'uppercase',
  },
  image: {
    height: 210,
    width: 283,
    resizeMode: 'cover',
    marginBottom: 70,
  },
  bold: {
    fontFamily: 'Lato-Bold',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
    marginLeft: 8,
  },
  marker: {
    width: 5,
    height: 5,
    backgroundColor: '#91C300',
  },
  outlineText: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '80%',
  },
});

export default Eat;
