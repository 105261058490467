import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import CheckInput from '../../components/Question/CheckInput';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const EventTerms: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const event = store.event.events.get(`${id}`);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { register, setValue, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    register({ name: 'readTerms' }, { required: true });
  }, [register]);

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const result = await store.event.updateCheckin(id, {
        ...data,
        id: event?.checkin ? event?.checkin.id : null,
      });
      setLoading(false);
      if (result.ok) {
        navigation.goBack();
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [event],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  if (!event) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={true} onBack={goToEvent} showClose={false} />
            </View>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isMobile
                    ? styles.contentMobile
                    : isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }
              >
                <Text style={styles.title}>O2X Terms</Text>
                <View style={styles.message}>
                  <Text style={styles.textContent}>
                    Welcome to our website. O2X Human Performance, LLC, together
                    with its affiliates and subsidiaries (collectively “O2X” or
                    “Company”), provides this and other websites (including
                    applications and other online services that are accessible
                    through various desktop, tablet and mobile web browsers from
                    time to time collectively referred to herein as the “Site”)
                    to visitors and users subject to your full and complete
                    acknowledgment and compliance with these Terms of Use.
                  </Text>
                  <Text style={styles.textContent}>
                    PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE
                    SITE. These Terms of Use constitute a legal agreement
                    between O2X and you. We suggest that you review these Terms
                    of Use and print a copy to be maintained in your records.
                  </Text>
                  <Text style={styles.textContent}>
                    By accessing and using the Site, you affirm that you are
                    able and legally competent to agree and comply with these
                    Terms of Use. If you do not agree to these Terms of Use or
                    if you are not legally competent to agree to them, then you
                    should not and may not use the Site.
                  </Text>
                  <Text style={styles.textContent}>
                    Please note that these Terms of Use contain provisions that
                    govern the resolution of disputes or claims between O2X and
                    you. Please review the section entitled “Legal Disputes” for
                    complete details.
                  </Text>
                  <Text style={styles.textContent}>
                    You acknowledge that Company may, from time to time, and in
                    its sole discretion and without notification to You, modify
                    these Terms of Use. The Company will endeavor to notify You
                    of any change to these Terms of Use by updating the “Terms
                    of Use” section on its website homepage.
                  </Text>
                  <Text style={styles.textContent}>
                    Your access and/or use of this Site indicates Your agreement
                    to be governed by these Terms of Use.. It is Your
                    responsibility to apprise Yourself of any Company issued
                    revisions or modifications to these Terms of Service each
                    time you visit or use the Site.{' '}
                  </Text>
                  <Text style={styles.textHeader}>Overview</Text>
                  <Text style={styles.textContent}>
                    O2X provides access to the Site, which may contain
                    customized human performance information as well as
                    performance analysis, information, and data.
                  </Text>
                  <Text style={styles.textHeader}>TERMS AND CONDITIONS</Text>
                  <Text style={styles.termsAndConditionHeader}>
                    SITE INTENDED FOR ADULTS ONLY
                  </Text>
                  <Text style={styles.textContent}>
                    If you are a minor, which for purposes of these Terms of Use
                    is a person under 18 years of age, You must obtain express
                    written permission from Your parent(s) and/or legal guardian
                    (s).
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    AVAILABILITY
                  </Text>
                  <Text style={styles.textContent}>
                    Company makes no guarantees regarding the availability of
                    the Site or the Services (including the availability of any
                    specific Services). Company reserves the right, in its sole
                    discretion, to discontinue the Site or Services and/or
                    modify the contents of the Site or the terms of the Services
                    as Company sees fit, including but not limited to, the price
                    for Services, descriptions, and other e-commerce related
                    information and/or functionality. You agree that Company
                    will not be liable to You for any such discontinuance or
                    modification of the Site or the Services.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    AUTHORIZED USE & PASSWORDS
                  </Text>
                  <Text style={styles.textContent}>
                    Company grants You a limited license to access and make
                    personal use of the Site for purposes and uses that are
                    legal and that are permitted by Terms of Use. Other than
                    page caching and/or podcasts, You are not authorized to
                    download or modify the Site or any portion of it, except
                    with express, prior written consent of Company. This license
                    does not include and specifically prohibits any resale or
                    commercial use of this site or its contents (except for Your
                    purchase of Services); any derivative use of this site or
                    its contents; any downloading or copying of account
                    information for the benefit of another person or merchant;
                    or any use of data mining, robots, or similar data gathering
                    and extraction tools. You may not post or transmit through
                    the Site any material that (i) infringes, misappropriates or
                    otherwise violates the rights of any person or entity,
                    including intellectual property rights; and/or (ii) that is
                    unlawful, threatening, harassing, hateful, abusive,
                    defamatory, invasive of privacy or publicity rights, vulgar,
                    obscene, profane, or embarrassing to another user or any
                    other person or entity, sexually-explicit, or is otherwise
                    objectionable in Company’s sole discretion, which encourages
                    conduct that would constitute a criminal offense, give rise
                    to civil liability or otherwise violates any law, or which
                    contains advertising or any solicitation with respect to
                    products or services. You are also prohibited from using the
                    Site in any way that restricts or inhibits any other Site
                    user from using or enjoying the Site consistent with the
                    letter and intent of these Terms of Service. Any
                    unauthorized use terminates the permission or license
                    granted by Company in relation to the Site immediately. You
                    are responsible for maintaining the confidentiality of Your
                    user account, profile and password and for restricting
                    access to Your computer, and You agree to accept
                    responsibility for all activities that occur under Your
                    account or password. You agree to immediately notify us of
                    any unauthorized use of Your account or any other breach of
                    security of which You become aware. Company reserves the
                    right to refuse service, terminate accounts, remove or edit
                    content, or cancel orders in its sole discretion.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    LINKING (TO & FROM) THE SITE; ADVERTISERS, THIRD PARTY SITE
                    TRANSFER
                  </Text>
                  <Text style={styles.textContent}>
                    Company may, from time to time, provide or incorporate via
                    the Site, active hyperlinks to third party websites. The
                    Company has no control over such third-party sites and;
                    therefore, cannot control or ensure Your security, the
                    content or the quality of the third party site, including
                    but not limited to the goods or services offered.
                  </Text>
                  <Text style={styles.textContent}>
                    You expressly acknowledge and agree that Company shall not
                    be held responsible for, nor does it guarantee or endorse,:
                    (a) the availability of third party site, including those
                    that are linked-to sites or resources on the Site; (b) the
                    accuracy, value, or completeness of any content available at
                    or through the third party sites; or (c) the goods or
                    services offered by third-parties.
                  </Text>
                  <Text style={styles.textContent}>
                    You acknowledge and agree that Company shall not be
                    responsible or liable to You in any manner, directly or
                    indirectly, for any damage or loss, monetary or otherwise,
                    caused or alleged to be caused by or in connection with use
                    of or reliance upon any content of any third-party sites,
                    even if accessed directly through the Site.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    PRIVACY POLICY
                  </Text>
                  <Text style={styles.textContent}>
                    The Company takes your privacy issues seriously. Please
                    review our Privacy Policy which also governs your use of the
                    Services, including information the Company collects and
                    obtains and its use of such information.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    PROHIBITION AGAINST HARMFUL TRANSMISSIONS & APPROPRIATE USE
                    OF SITE
                  </Text>
                  <Text style={styles.textContent}>
                    As a visitor or user of the Site, You expressly acknowledge
                    and agree that You are responsible for all of your
                    communications and postings, including, but not limited to,
                    comments, commentary, suggestions, resumes, job postings and
                    otherwise (“User Content”). You agree that You will not
                    upload, post, e-mail, or otherwise transmit to the Site or
                    to any other User any messages, programs, or other materials
                    (including, but not limited to, Trojan Horses, viruses,
                    worms, and the like) that contain software viruses or any
                    other computer code, files, or programs designed to
                    interrupt, destroy, or limit the functionality of any
                    computer software or telecommunications equipment or collect
                    or mine information about this site or other users. Company
                    does not have an obligation to assess or guarantee the
                    accuracy or appropriateness of the communications or
                    postings, even if Company elects, in its sole discretion, to
                    do so or to attempt to do so from time to time. Company is
                    not responsible for any User Content or actions or omissions
                    in response to User Content.
                  </Text>
                  <Text style={styles.textContent}>
                    You may not, and You agree that You will not, decompile,
                    disassemble or reverse engineer any of the software
                    comprising or in any way making up a part of the Site. You
                    may not violate or attempt to violate the security of the
                    Site, including, without limitation, unauthorized access of
                    data; circumventing authentication mechanisms; or
                    interfering with the Site in any way. You may not use any
                    engine, software, tool, agent or other device or mechanism
                    (including but not limited to, browsers, spiders, avatars or
                    BOTs/intelligent agents) to navigate or search the Site
                    other than as provided through the Site and/or a standard
                    third-party browser. You may not “frame” this Site or
                    display the Site’s content through any other URL or in
                    conjunction with another company name or trademark. You
                    shall not copy the HTML code that the Company creates to
                    generate its webpage.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY; RISK OF
                    LOSS
                  </Text>
                  <Text style={styles.textContent}>
                    Company takes your privacy issues seriously. Please review
                    our Privacy Policy which also governs your use of the
                    Services, including information collection and information
                    uses. In addition to the disclaimers and limitations of
                    liability set forth in our Privacy Policy, the following
                    terms and conditions shall apply:
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    WARRANTIES DISCLAIMED
                  </Text>
                  <Text style={styles.textContent}>
                    NEITHER COMPANY NOR ANY OF ITS SERVICE PROVIDERS,
                    INFORMATION PROVIDERS, LICENSORS, EMPLOYEES, OFFICERS,
                    DIRECTORS, MEMBERS, ASSIGNEES, OR AGENTS WARRANT THAT THE
                    SERVICES OR THE INFORMATION PROVIDED VIA THE SITE WILL BE
                    UNINTERRUPTED OR ERROR FREE. EXCEPT AS IS EXPRESSLY SET
                    FORTH ELSEWHERE HEREIN, THE SERVICES ARE MADE AVAILABLE “AS
                    IS,” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                    IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
                    OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE,
                    NON-INFRINGEMENT, OR OTHERWISE, ALL SUCH WARRANTIES BEING
                    EXPRESSLY DISCLAIMED. TO THE EXTENT ALLOWED BY LAW, NEITHER
                    COMPANY NOR ANY OF ITS SERVICE PROVIDERS, INFORMATION
                    PROVIDERS, LICENSORS, EMPLOYEES, OFFICERS, DIRECTORS,
                    MEMBERS, ASSIGNEES, OR AGENTS SHALL HAVE ANY LIABILITY FOR
                    INCIDENTAL, CONSEQUENTIAL, INDIRECT, EXEMPLARY, PUNITIVE, OR
                    SPECIAL DAMAGES SUFFERED BY YOU OR ANY OTHER PARTY ARISING
                    OUT OF OR RELATED TO (A) YOUR USE OF THE SITE OR SERVICES,
                    (B) THE OPERATION OR MALFUNCTION OF THE SITE OR SERVICES, OR
                    (C) YOUR PURCHASE OF SERVICES (OR ANY OTHER PRODUCTS OR
                    SERVICES) VIA THE SITE, AND, REGARDLESS OF WHETHER OR NOT
                    SUCH PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES AS TO (A) THROUGH (C).
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    LIMITATION OF LIABILITY
                  </Text>
                  <Text style={styles.textContent}>
                    TO THE EXTENT ALLOWED BY LAW, COMPANY SHALL HAVE NO
                    LIABILITY FOR UNAUTHORIZED THIRD-PARTY ACCESS FOR
                    ALTERATION, THEFT OR DESTRUCTION OF ANY INFORMATION
                    CONTAINED AT THE SITE THROUGH ACCIDENT OR THROUGH FRAUDULENT
                    OR WRONGFUL MEANS OR DEVICES. IN ANY EVENT, THE LIABILITY OF
                    COMPANY TO YOU OR ANY OTHER USER FOR ANY REASON AND UPON ANY
                    CAUSE OF ACTION SHALL BE LIMITED TO AND SHALL IN NO EVENT
                    EXCEED THE SUM OF THE GREATER OF ONE-HUNDRED DOLLARS
                    ($100.00) OR, IN THE EVENT THAT YOUR DISPUTE OR CLAIM ARISES
                    FROM YOUR PURCHASE OF SERVICES VIA THE INTERNET, THE COST OF
                    SUCH DISPUTED SERVICES (I.E., THE AMOUNT YOU PAID TO COMPANY
                    FOR SUCH SERVICES). THIS LIMITATION APPLIES TO ALL CAUSES OF
                    ACTION IN THE AGGREGATE, INCLUDING, WITHOUT LIMITATION,
                    BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT
                    LIABILITY, MISREPRESENTATIONS, NON-INFRINGEMENT, AND OTHER
                    CAUSES OF ACTION OR ALLEGATIONS.
                  </Text>
                  <Text style={styles.textContent}>
                    NOTE: SOME JURISDICTIONS LIMIT THE PERMISSIBLE SCOPE OF
                    DISCLAIMERS AND LIMITATIONS SUCH AS THOSE APPEARING IN THE
                    TWO PRECEDING PARAGRAPHS. THEREFORE, SOME OF THE LIMITATIONS
                    AND DISCLAIMERS APPEARING HEREIN MAY NOT APPLY.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    RISK OF LOSS, RETURNS & REFUNDS
                  </Text>
                  <Text style={styles.textContent}>
                    Some Services purchased from the Site are made pursuant to a
                    third party shipment contract between a third party and the
                    Company. You acknowledge and agree that the risk of loss and
                    title for such Services shall be passed to You upon delivery
                    to Company’s carrier. Under no circumstances does the
                    Company take legal or equitable title to any returned items
                    until the item arrives at our fulfillment center. Company
                    may, in its sole discretion and under certain circumstances,
                    issue a refund You without requiring a return of the item(s)
                    purchased.
                  </Text>

                  <Text style={styles.termsAndConditionHeader}>
                    HEALTH DISCLAIMER
                  </Text>
                  <Text style={styles.textContent}>
                    This Site may provide human performance (conditioning,
                    nutrition, sleep hygiene, stress management, and resilience
                    training) and other related information, recommendations,
                    protocols, programs, applications, and content (collectively
                    “Information”).. The Information is intended only to assist,
                    inform and educate users and visitors in their personal
                    efforts. Company is not a medical organization and cannot –
                    and does not – give You medical advice or a medical
                    diagnosis. Nothing contained in this Site should be
                    construed as medical advice or a medical diagnosis. Any
                    Information and reports generated by Company should not be
                    interpreted as a substitute for consultation, evaluation,
                    recommendation, advice, and treatment of your health care
                    provider(s).
                  </Text>
                  <Text style={styles.textContent}>
                    The risk of injury from participation in a human performance
                    program is significant, including the potential for
                    catastrophic injury or death. You should and must consult a
                    medical professional before undertaking any fitness regimen
                    or exercise program, including any exercises or techniques
                    set forth or described on this Site. Any text, pictures, and
                    description set forth in this Site is for educational
                    purposes only (e.g., the information illustrates and
                    explains various fitness-related and exercise techniques)
                    and does NOT advise that You or any other particular Site
                    user undertake or perform any particular technique or
                    exercise. You agree that You will not undertake or perform
                    any exercise or technique described in this Site until and
                    unless You consult with and are cleared by a medical doctor
                    in relation to such participation.
                  </Text>
                  <Text style={styles.textContent}>
                    The Site is not intended for use by minors, pregnant women,
                    or individuals with any type of health condition and/or
                    health problem. As are all Users, such individuals are
                    specifically warned to seek professional medical advice
                    prior to initiating any form of human performance training.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    INDEMNIFICATION
                  </Text>
                  <Text style={styles.textContent}>
                    You agree to indemnify, defend, and hold harmless It, its
                    directors, officers, members, employees, assignees, and
                    agents, and defend any action brought against same with
                    respect to any claim, demand, cause of action, debt or
                    liability, including, without limitation, reasonable
                    attorneys’ fees and costs, to the extent that such action is
                    based upon a claim that: (i) would constitute a breach of
                    any of Your representations, warranties, or agreements
                    hereunder; (ii) arises out of or relates to Your use of the
                    Site; and/or (iii) arises out of Your acts or omissions.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    SEVERABILITY
                  </Text>
                  <Text style={styles.textContent}>
                    In the event that one or more of the words, phrases,
                    sentences, clauses, sections, subdivisions or subparagraphs
                    contained herein shall be held invalid, these Terms of Use
                    shall be construed as if such invalid portion had not been
                    inserted. In the event that any invalid language is severed
                    under this paragraph, the severed language shall be
                    comprised of the smallest unit possible such that the
                    severance of the unit results in a valid provision (e.g., a
                    single item in a list will be severed rather than the entire
                    list, a clause will be severed rather than the entire
                    sentence, etc.).
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    ENTIRE AGREEMENT
                  </Text>
                  <Text style={styles.textContent}>
                    These Terms of Service set forth and contain the entire
                    agreement with regard to the matters set forth herein
                    between You and Company. There are no promises, terms,
                    conditions, or obligations other than those contained
                    herein. These Terms of Use supersede all previous
                    communications, written or verbal, representations, or
                    agreements, either verbal or written, between and among You
                    and the Company with respect to the subject matter hereof.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    CHOICE OF LAW
                  </Text>
                  <Text style={styles.textContent}>
                    These Terms of Use and any disputes arising hereunder shall
                    be construed under and governed by the laws of the
                    Commonwealth of Massachusetts. All contracts resulting from
                    or relating to this Site, including Member Agreements, as
                    well as all information and advice provided herein, shall be
                    deemed to have been formed, entered into, executed, and/or
                    given/communicated in the Commonwealth of Massachusetts.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    VENUE; PERSONAL JURISDICTION; SERVICE OF PROCESS
                  </Text>
                  <Text style={styles.textContent}>
                    Any dispute arising out of, concerning, or relating to these
                    Terms of Use shall exclusively be brought in the appropriate
                    state or federal courts located in the Commonwealth of
                    Massachusetts. By accessing and using the Site, You (and any
                    person or entity on whose behalf you act) hereby agree and
                    consent to the exclusive jurisdiction and venue of any state
                    or federal court located in the Commonwealth of
                    Massachusetts and You hereby waive and release now and
                    forever any defense to that assertion of jurisdiction,
                    including lack of personal jurisdiction and forum
                    non-conveniens.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    HEADINGS FOR CONVENIENCE ONLY
                  </Text>
                  <Text style={styles.textContent}>
                    The headings used herein are for convenience only. The
                    headings do not purport to define, limit, or extend the
                    scope or intent of the language of the sections and the
                    paragraphs to which they pertain.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>WAIVER</Text>
                  <Text style={styles.textContent}>
                    The waiver by one party of a breach of any provision of
                    these Terms of Use by the other party shall not operate or
                    be construed as a waiver of any subsequent breach of the
                    same or any other provision by the other party. The failure
                    of a party at any time to require performance of any
                    provision hereof shall in no manner affect its right at a
                    later time to enforce the same.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    MARKS & MATERIAL
                  </Text>
                  <Text style={styles.textContent}>
                    The Site contains copyrighted material, trademarks and other
                    proprietary information which may include text, software,
                    photos, video, graphics, music and sound. The entire
                    contents of the Site are copyrighted by Company and are
                    subject to United States and international copyright laws.
                    Company owns a copyright in the selection, coordination,
                    arrangement and enhancement of such content, as well as in
                    the content original to it. You may not modify, publish,
                    transmit, participate in the transfer or sale, create
                    derivative works, or in any way exploit, any of the content,
                    in whole or in part. Except as otherwise expressly permitted
                    under copyright law or expressly allowed herein, no copying,
                    redistribution, retransmission, publication or commercial
                    exploitation of downloaded material will be permitted
                    without the express permission of Company and the copyright
                    owner, if other than Company. In the event of any permitted
                    copying, redistribution or publication of copyrighted
                    material, no changes in or deletion of author attribution,
                    trademark legend or copyright notice shall be made. You
                    acknowledge that You do not acquire any such ownership
                    rights by downloading copyrighted material or otherwise
                    using the Site. Without the owner’s prior written
                    permission, You agree not to display or use in any manner
                    (other than as set forth in this T.O.S.), any trademarks or
                    content found on the Site.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>CONTACT</Text>
                  <Text style={styles.textContent}>
                    Please direct all inquiries and other communications
                    relating to the Site and/or the Services to:
                  </Text>
                  <Text style={styles.textContent}>O2X</Text>
                  <Text style={styles.textContact}>28 New Driftway</Text>
                  <Text style={styles.textContact}>Suite 5</Text>
                  <Text style={styles.textContact}>
                    Scituate, Massachusetts
                  </Text>
                  <Text style={styles.textContact}>02066</Text>
                  <Text style={styles.textContact}>info@o2x.com</Text>

                  <Text style={styles.termsAndConditionHeader}>LOCAL LAWS</Text>
                  <Text style={styles.textContent}>
                    You warrant and agree that Your access to and use of the
                    Site is in compliance with the laws governing such access,
                    use, and related conduct in Your geographic region.
                  </Text>
                  <Text style={styles.termsAndConditionHeader}>
                    PROHIBITED CONDUCT
                  </Text>
                  <Text style={styles.textContent}>
                    Use of the Site or the corresponding Services for any use or
                    reason other than the intended and permissible uses
                    described or implied herein is expressly prohibited. Any
                    such misuse may constitute violation of state and federal
                    civil and criminal laws and may result in civil and/or
                    criminal prosecution.
                  </Text>
                  <Text style={styles.textContent}>
                    Furthermore, without limiting other remedies, Company may
                    immediately issue a warning and/or temporarily or
                    indefinitely suspend or terminate, at its sole discretion,
                    Your privilege to use and access the Site if You breach
                    these Terms of Use Company reserves the right to deny access
                    to the Site and/or any elements of the Site to any person at
                    any time and for any reason.
                  </Text>
                  <Text style={styles.textContent}>
                    IF YOU DO NOT AGREE TO THESE TERMS OF USE, YOU ARE NOT
                    AUTHORIZED AND MAY NOT ACCESS OR UTILIZE THE SITE
                  </Text>
                </View>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                <CheckInput
                  style={{ visibility: false }}
                  field="readTerms"
                  initial={false}
                  onChange={(key: string, checked: boolean) =>
                    setValue(key, checked)
                  }
                  label="I agree to the terms above"
                  status="basic-plain"
                  optionStyle={{ backgroundColor: 'transparent' }}
                  labelStyle={styles.label}
                />
              </Layout>
            </ScrollView>
            <BottomNav
              showBack={false}
              onNext={onSubmitWrapped}
              nextLabel="Submit"
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    paddingBottom: 20,
    paddingHorizontal: '10%',
  },
  contentMobile: {
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    paddingBottom: 20,
    paddingHorizontal: '8%',
  },
  scroll: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  message: {
    marginBottom: 20,
  },
  textContent: {
    fontSize: 14,
    marginTop: 20,
  },
  textHeader: {
    fontSize: 14,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginTop: 20,
  },
  termsAndConditionHeader: {
    fontSize: 14,
    marginTop: 20,
  },
  textContact: {
    fontSize: 14,
    marginTop: 3,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
    marginBottom: 36,
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
  },
});

export default observer(EventTerms);
