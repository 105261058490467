import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { startsWith } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SweatCategory, SweatEquipment } from 'o2x-store/src/models/Sweat';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  SectionList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from 'src/components/NavigationBar';
import Filter from '../../assets/images/filter.svg';
import ExploreFilterItem from '../../components/ExploreFilterItem';
import ExploreFilterSectionHeader from '../../components/ExploreFilterSectionHeader';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { ExploreFilterSections } from '../ExploreFilter/constants';

type Props = {
  route: RouteProp<AppStackParamList, 'ExploreFilter'>;
};

type SweatFilters = {
  [key: string]: string[];
};

const fill = getTaskColor(TASK_TYPE.SWEAT);

const WorkoutGeneratorFilter: React.FC<Props> = (props) => {
  const selected = TASK_TYPE.SWEAT;
  const { exploreFilter, workoutFilter } = useNativeStore();
  const store = useStore();
  const [filters, setFilters] = useState({ ...workoutFilter.filters });

  const [currentFilter, setCurrentFilter] = useState<SweatFilters>({});

  const theme = useTheme();
  const [workoutCount, setWorkoutCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingEquipment, setLoadingEquipment] = useState(false);
  const styles = useStyleSheet(themedStyles);
  const filterCount = Object.values(filters).filter((filter) => filter).length;
  const navigation = useNavigation();
  const titleOpacity = useRef(new Animated.Value(0));
  const insets = useSafeAreaInsets();
  const [expandEquipment, setExpandEquipment] = useState(false);

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });
  useFocusEffect(
    useCallback(() => {
      store.sweat.fetchSweatCategories();
    }, []),
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await store.sweat.generateSweatWorkoutCount(currentFilter);
      if (result.ok) {
        setWorkoutCount(result.extra?.count);
      }
      setLoading(false);
    })();
  }, [currentFilter, filters]);

  useFocusEffect(
    useCallback(() => {
      store.sweat.resetSweatEquipments();
      LoadEquipment();
    }, []),
  );

  const LoadEquipment = useCallback(() => {
    (async () => {
      console.log('Load');
      setLoadingEquipment(true);
      await store.sweat.fetchSweatEquipments(true);
      setLoadingEquipment(false);
    })();
  }, []);

  const category = [{ key: 1, label: 'Equipment-Free' }];

  const filterSections = useMemo(() => {
    return {
      ...ExploreFilterSections,
      [TASK_TYPE.SWEAT]: [
        {
          key: 'category',
          data: category.map((cat: any) => ({
            key: `category-${cat.key}`,
            label: cat.label,
          })),
        },
        {
          key: 'equipment',
          data: Array.from(store.sweat.sweatEquipments.values()).map(
            (eq: SweatEquipment) => ({
              key: `equipment-${eq.id}`,
              label: eq.name,
            }),
          ),
        },
        {
          key: 'categories',
          data: Array.from(store.sweat.sweatCategories.values()).map(
            (eq: SweatCategory) => ({
              key: `categories-${eq.id}`,
              label: eq.name,
            }),
          ),
        },
        ...ExploreFilterSections[TASK_TYPE.SWEAT],
      ],
    };
  }, [
    store.sweat.sweatEquipments.values(),
    store.sweat.sweatCategories.values(),
  ]);

  const onSelectFilter = useCallback((filter, key, value, checked) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));

    if (startsWith(key, 'difficulty')) {
      value = value.toLowerCase();
    }

    if (startsWith(key, 'category')) {
      switch (value) {
        case 'Equipment-Free':
          value = 'ef';
          break;
        default:
          value = 'equipment';
          break;
      }
    }
    setCurrentFilter((prevCurrentFilter) => ({
      ...prevCurrentFilter,
      [key]:
        key in prevCurrentFilter
          ? checked
            ? prevCurrentFilter[key].filter((v) => v != value)
            : [...prevCurrentFilter[key], value]
          : [value],
    }));
  }, []);

  const onClearSection = useCallback(
    (sectionKey) => {
      const sectionFilters = filterSections[selected].find(
        (section) => section.key === sectionKey,
      );
      if (!sectionFilters) return;
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...sectionFilters?.data.reduce(
          (obj, filter) => ({ ...obj, [filter.key]: false }),
          {},
        ),
      }));
      setCurrentFilter((prevCurrentFilter) => ({
        ...prevCurrentFilter,
        [sectionKey]: [],
      }));
    },
    [filterSections, selected],
  );

  const onClearAll = useCallback(() => {
    setFilters({});
    setCurrentFilter({});
  }, []);

  const renderSectionFooter = useCallback(
    ({ section }) => {
      const ef_selected = currentFilter['category']
        ? currentFilter['category'].includes('ef')
        : false;
      if (section.key !== 'equipment' || ef_selected || !expandEquipment)
        return null;

      return (
        <>
          {loadingEquipment && expandEquipment && (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          )}
          <TouchableOpacity onPress={LoadEquipment}>
            <Text style={styles.seeMoreText}> see more equipment </Text>
          </TouchableOpacity>
        </>
      );
    },
    [loadingEquipment, expandEquipment, currentFilter],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => {
      const ef_selected = currentFilter['category']
        ? currentFilter['category'].includes('ef')
        : false;
      if (section.key == 'equipment' && ef_selected) return null;
      return section.key !== 'equipment' && !section['data'].length ? null : (
        <>
          {section.key === 'equipment' && (
            <TouchableOpacity
              style={styles.equipmentButton}
              onPress={() => {
                setExpandEquipment(!expandEquipment);
              }}
            >
              <Text>Select Equipment Available</Text>
              <Icon
                fill="white"
                width={24}
                height={24}
                name={expandEquipment ? 'chevron-up' : 'chevron-down'}
              />
            </TouchableOpacity>
          )}
          {(section.key !== 'equipment' || expandEquipment) && (
            <ExploreFilterSectionHeader
              section={section}
              fill={fill}
              onClearSection={onClearSection}
              showHeaderKey={section.key != 'category'}
            />
          )}
        </>
      );
    },
    [fill, expandEquipment, currentFilter],
  );

  const renderItem = useCallback(
    ({ item, section }) => {
      const ef_selected = currentFilter['category']
        ? currentFilter['category'].includes('ef')
        : false;
      if (
        startsWith(item.key, 'equipment') &&
        (ef_selected || !expandEquipment)
      )
        return null;
      return (
        <View style={styles.filterItem}>
          <ExploreFilterItem
            fill={fill}
            item={item}
            checked={filters[item.key]}
            onSelect={onSelectFilter}
            sectionKey={section.key}
          />
        </View>
      );
    },
    [fill, filters, currentFilter, expandEquipment],
  );

  const onNext = useCallback(() => {
    const ef_selected = currentFilter['category']
      ? currentFilter['category'].includes('ef')
      : false;
    let selectedFilters = currentFilter;
    if (ef_selected) {
      if (selectedFilters['equipment']) delete selectedFilters['equipment'];
    } else {
      selectedFilters = { ...selectedFilters, category: ['equipment'] };
    }
    if (!loading) {
      navigation.navigate('WorkoutGeneratorResult', {
        selectedFilters: selectedFilters,
        count: workoutCount,
      });
    }
  }, [loading, workoutCount]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Layout
              style={
                isMobile
                  ? styles.contentMobile
                  : isDeviceMaxWidth600
                  ? styles.contentMaxWidth600
                  : isDeviceMaxWidth900
                  ? styles.contentMaxWidth900
                  : styles.content
              }
            >
              <Text category="h2" style={styles.title}>
                Workout Generator
              </Text>
              <Layout style={styles.filter}>
                <Filter height={20} width={20} stroke={theme['white']} />
                <Text style={styles.filterLabel}>Filters</Text>

                <TouchableOpacity
                  style={styles.filterText}
                  onPress={onClearAll}
                >
                  <Text style={[styles.clearAllLabel]} category="c1">
                    Clear All ({filterCount})
                  </Text>
                </TouchableOpacity>
              </Layout>
              <SectionList
                style={styles.list}
                contentContainerStyle={styles.listContainer}
                sections={filterSections[selected]}
                extraData={filters}
                renderSectionHeader={renderSectionHeader}
                renderSectionFooter={renderSectionFooter}
                renderItem={renderItem}
                // ItemSeparatorComponent={renderSeparator}
                stickySectionHeadersEnabled={false}
              />
              <Layout style={styles.bottomPadding} />
            </Layout>
            {/* <View style={styles.resultsCount}>
              {loading ? (
                <Spinner status="basic" />
              ) : (
                <View style={styles.workoutButton}>
                  <Text style={styles.filterNumMatches}>{workoutCount}</Text>
                  <Text style={styles.filterNumLabel}>Available Workouts</Text>
                </View>
              )}
            </View> */}
            <TouchableOpacity style={styles.bottomNav} onPress={onNext}>
              <View style={styles.workoutButton}>
                <Text style={styles.filterNumLabel}>next</Text>
              </View>
            </TouchableOpacity>
          </Layout>
          <NavigationBar
            style={[styles.navigation, { paddingTop: insets.top }]}
            title="Workout Generator"
            titleOpacity={titleOpacity.current.interpolate({
              inputRange: [0, 60, 80],
              outputRange: [0, 0, 1],
            })}
            showBack={false}
            showClose={true}
            withBackground={false}
          />
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  contentMaxWidth900: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '20%',
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: 'white',
    marginTop: 20,
    textAlign: 'center',
  },
  actionButton: {
    flex: 1,
    backgroundColor: 'darker-blue',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  bottomPadding: {
    height: 10,
  },
  filterText: {
    paddingHorizontal: 10,
  },
  clearAllLabel: {
    color: fill,
    textTransform: 'uppercase',
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    marginHorizontal: 24,
  },
  filterLabel: {
    flex: 1,
    marginHorizontal: 16,
  },
  filterButton: {
    paddingRight: 0,
  },
  filterItem: {
    marginBottom: 14,
  },
  list: {
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 20,
    flex: 1,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'white',
    borderBottomWidth: 1,
    marginHorizontal: 24,
    marginVertical: 8,
    paddingVertical: 4,
  },
  searchIcon: {
    height: 24,
    width: 24,
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
  },
  spacer: {
    height: 8,
  },
  bottomNav: {
    height: 48,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fill,
  },
  workoutButton: {
    flexDirection: 'row',
  },
  filterNumMatches: {
    color: fill,
    marginRight: 5,
  },
  filterNumLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  resultsCount: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
  equipmentButton: {
    backgroundColor: 'dark-blue',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    marginTop: 20,
  },
  seeMoreText: {
    fontSize: 14,
    marginTop: 25,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'cyan',
  },
  loading: {
    marginTop: 8,
    marginLeft: '45%',
  },
});

export default observer(WorkoutGeneratorFilter);
