import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import DailyDebriefDial from 'src/components/DailyDebriefDial';
import TopNav from 'src/components/Question/TopNav';
import TaskIcon from 'src/components/TaskIcon';
import Topics from 'src/components/User/Topics';
import SweatAverage from '../../assets/images/sweat-average.svg';
import SweatExcellent2 from '../../assets/images/sweat-excellent-2.svg';
import SweatExcellent from '../../assets/images/sweat-excellent.svg';
import SweatPoor from '../../assets/images/sweat-poor.svg';
import BottomNav from '../../components/Question/BottomNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'DailyDebriefQuestion'>;
};

const dailyDebriefContent: { [key: string]: any } = {
  content: {
    prepare:
      'In more general terms, this is your warm-up. Prepare readies your body for exercise, improves flexibility and mobility, and reduces the potential for injury.',
    sweat:
      'This is the conditioning portion of your training session and is where muscle adaptation and endurance are activated.',
    recover:
      "This refers to your cool-down or recovery for the day or a training session. Whether you're using a foam roller or static stretching, recovery is a critical component to staying healthy and ready to perform every day.",
  },
  excellent2: SweatExcellent2,
  excellent: SweatExcellent,
  average: SweatAverage,
  poor: SweatPoor,
  color: '#04B7D6',
};

const fields = [
  'doPrepareAndRecover',
  'physicalHealthLevel',
  'doExercise',
  'doChallenge',
  'energyLevel',
  'motivationLevel',
];

const DailyDebriefSweat: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const store = useStore();

  const [value, setValue] = useState(0);

  const { monitorFields = [] } = store.user.debriefProfile ?? {};
  const {
    debriefData = {},
    habits = fields.filter((f) => monitorFields.includes(f)),
  } = props?.route.params ?? {};

  const task = TASK_TYPE.SWEAT;

  const onNext = useCallback(async () => {
    const data = {
      ...debriefData,
      overallSweat: Math.floor(value / 10),
      sweatHabits: habits,
    };
    if (habits.length === 0) {
      navigation.navigate('DailyDebriefThrive', {
        debriefData: data,
      });
    } else {
      navigation.navigate('QuestionSweat', {
        debriefData: data,
        habits,
      });
    }
  }, [habits, debriefData]);

  const addHabit = useCallback(() => {
    navigation.navigate('AddHabitSweat', { habits });
  }, [habits]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Layout style={styles.navigationContainer}>
              <TopNav
                showBack
                showClose
                onClose={() => navigation.navigate('Today')}
                onBack={goBack}
              />
            </Layout>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <View style={styles.header}>
                <View
                  style={[
                    styles.titleContainer,
                    {
                      paddingTop: insets.top,
                    },
                  ]}
                >
                  <TaskIcon
                    style={styles.icon}
                    type={task}
                    width={35}
                    height={35}
                  />
                  <Text
                    category="h1"
                    style={[
                      styles.title,
                      {
                        color: getTaskColor(task),
                      },
                    ]}
                  >
                    {task}
                  </Text>
                </View>
                <Topics task={task} />
              </View>
              <View style={styles.flex}>
                <View style={styles.center}>
                  <DailyDebriefDial
                    value={value}
                    setValue={setValue}
                    debrief={dailyDebriefContent}
                    offsetY={insets.top + 120}
                    isRating={true}
                  />
                </View>
                <View style={styles.flex} />
                <Text category="s1" style={styles.note}>
                  {`Custom tailor your ${task.toUpperCase()} log by selecting the button below.`}
                </Text>
                <TouchableOpacity style={styles.addHabit} onPress={addHabit}>
                  <Icon
                    height={15}
                    width={15}
                    fill="#c4c4c4"
                    name="plus-outline"
                  />
                  <Text category="label" style={styles.add}>
                    ADD HABITS
                  </Text>
                </TouchableOpacity>
                <View style={styles.bottom} />
              </View>
            </View>
            <View style={{ paddingBottom: insets.bottom }}>
              <BottomNav
                onBack={goBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  add: {
    color: '#c4c4c4',
  },
  addHabit: {
    flexDirection: 'row',
    marginVertical: 20,
    height: 40,
    width: 150,
    borderRadius: 2,
    alignSelf: 'center',
    backgroundColor: '#203950',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    paddingHorizontal: '25%',
    overflowY: 'scroll',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    overflowY: 'scroll',
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    overflowY: 'scroll',
  },
  modalOverlay2: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal1: {
    width: '70%',
  },
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 90,
  },
  title: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: '500',
    alignSelf: 'center',
  },
  icon: {
    alignSelf: 'center',
    marginRight: 6,
  },
  note: {
    alignSelf: 'center',
    color: '#c4c4c4',
    textAlign: 'center',
    maxWidth: 224,
  },
  header: {
    height: 130,
  },
  bottom: {
    height: 10,
  },
});

export default observer(DailyDebriefSweat);
