import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import React from 'react';
import { Image, ImageStyle, StyleProp, View } from 'react-native';
import { useNativeStore } from '../../stores';

type Props = {
  step: SweatWorkoutStep;
  newScreen?: boolean;
};

const SweatStartSectionHeader: React.FC<Props> = (props) => {
  const { step, newScreen } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const { sweatStart } = useNativeStore();

  const convertTime = (time: string) => {
    if (time) {
      if (time.includes(':')) return time;
      else {
        var sec_num = parseInt(time, 10);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor(sec_num / 60) % 60;
        var seconds = sec_num % 60;

        return [hours, minutes, seconds]
          .map((v) => (v < 10 ? '0' + v : v))
          .filter((v, i) => v !== '00' || i > 0)
          .join(':');
      }
    }
  };

  const focus =
    !sweatStart.workoutId ||
    sweatStart.step?.id === step.id ||
    step?.exercises.find((e) => !sweatStart.breakdownTime[e.id]);

  let subtitle = `Complete ${step.circuitSets} ${Humanize.pluralize(
    step.circuitSets,
    'time',
  )}`;
  if (newScreen && !step.circuitTimer) {
    subtitle = '';
  } else if (step.circuitSets === 1 && !step.circuitTimer) {
    subtitle = '';
  } else if (step.circuitTimer === 'amrap') {
    if (step.circuitTimerWork)
      subtitle = `Complete as many sets as possible in ${convertTime(
        step.circuitTimerWork?.toString(),
      )}`;
    else subtitle = `Complete as many sets as possible`;
  } else if (step.circuitSets === 0) {
    subtitle = 'Repeat as many times';
    if (step.circuitTime) {
      subtitle += ` in ${step.circuitTime}`;
    }
    if (step.circuitRest) {
      subtitle += `, REST ${step.circuitRest}`;
    }
  }

  return (
    <>
      <Layout style={[styles.container, !focus && styles.blurContainer]}>
        {!newScreen && (step.circuitSets !== 1 || !!step.circuitTimer) && (
          <View style={styles.iconContainer}>
            <Image
              style={styles.icon as StyleProp<ImageStyle>}
              source={require('../../assets/images/repeat.png')}
            />
          </View>
        )}
        <View style={styles.content}>
          <Text style={styles.title}>
            {step.circuitSets === 1 && !step.circuitTimer
              ? 'Perform in order'
              : newScreen && !step.circuitTimer
              ? 'Perform in order'
              : step.circuitTimer !== 'variable'
              ? step.circuitTimer
              : 'Circuit'}
          </Text>
          <View style={styles.subtitleContainer}>
            <Text style={[styles.title, styles.repeat]}>{subtitle}</Text>
          </View>
        </View>
      </Layout>
      {!!step.circuitInstructions && (
        <View style={styles.instructionsContainer}>
          <Text style={styles.instructionsText}>
            {step.circuitInstructions}
          </Text>
        </View>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  blurContainer: {
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    height: 40,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 12,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: 26,
    height: 40,
    backgroundColor: 'black',
  },
  icon: {
    width: 14,
    height: 14,
  },
  subtitleContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  repeat: {
    fontWeight: 'bold',
  },
  instructionsContainer: {
    flex: 1,
  },
  instructionsText: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 40,
    color: 'light-gray',
    paddingBottom: 10,
  },
});

export default observer(SweatStartSectionHeader);
