import {
  Icon,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react-lite';
import { FTEEventModel } from 'o2x-store/src/models/Event';
import User from 'o2x-store/src/models/User';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import Hyperlink from 'react-native-hyperlink';

type Props = {
  event: FTEEventModel;
  onDelete: () => void;
  onEdit: () => void;
  user: User;
};

const CalendarEvent: React.FC<Props> = (props) => {
  const { event, onDelete, onEdit, user } = props;
  const styles = useStyleSheet(themedStyles);
  const [isModalVisible, setModalVisible] = useState(false);
  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onLinkPress = async (url: string) => {
    if (await Linking.canOpenURL(url)) window.open(url, '_blank')?.focus();
  };

  const frequencies: { [key: string]: string } = {
    '': 'Does not repeat',
    DAILY: 'Repeats Every Day',
    WEEKLY: 'Repeats Every Week',
    MONTHLY: 'Repeats Every Month',
    YEARLY: 'Repeats Every Year',
  };

  return (
    <Layout>
      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={hideModal}
      >
        <ScrollView style={styles.modal}>
          <View style={styles.modalContent}>
            <View style={styles.modalDetail}>
              <Icon
                name="checkmark-square-2-outline"
                style={styles.modalIcon}
                fill="#4E7B89"
              />
              <Text style={[styles.modalText, { textTransform: 'uppercase' }]}>
                {event.eventName}
              </Text>
            </View>
            <View style={styles.modalDetail}>
              <Icon
                name="calendar-outline"
                style={styles.modalIcon}
                fill="#4E7B89"
              />
              <Text style={styles.modalText}>
                {format(parseISO(event.date), 'MMMM dd, yyyy')}{' '}
                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                  {event.recurring
                    ? `${frequencies[event.rrule]} until ${format(
                        parseISO(event.endRecurringDate),
                        'MMMM dd, yyyy',
                      )}`
                    : ''}
                </Text>
              </Text>
            </View>
            <View style={styles.modalDetail}>
              <Icon
                name="clock-outline"
                style={styles.modalIcon}
                fill="#4E7B89"
              />
              <Text style={styles.modalText}>
                {format(
                  new Date(new Date().toDateString() + ' ' + event.timeStart),
                  'HH:mm a',
                )}{' '}
                -{' '}
                {format(
                  new Date(new Date().toDateString() + ' ' + event.timeEnd),
                  'HH:mm a',
                )}
              </Text>
            </View>
            {!!event.location && (
              <View style={styles.modalDetail}>
                <Icon
                  name="pin-outline"
                  style={styles.modalIcon}
                  fill="#4E7B89"
                />
                <Text style={styles.modalText}>{event.location}</Text>
              </View>
            )}
            {!!event.link && (
              <View style={styles.modalDetail}>
                <Icon
                  name="link-2-outline"
                  style={styles.modalIcon}
                  fill="#4E7B89"
                />
                <Hyperlink
                  //   linkDefault={true}
                  onPress={(url, text) => onLinkPress(url)}
                  linkStyle={{
                    margin: 0,
                    padding: 0,
                    fontSize: 16,
                    color: '#ADD8E6',
                  }}
                >
                  <Text style={[styles.modalText, { alignSelf: 'flex-start' }]}>
                    {event.link}
                  </Text>
                </Hyperlink>
              </View>
            )}
            <View style={styles.modalDetail}>
              <Icon
                name="people-outline"
                style={styles.modalIcon}
                fill="#4E7B89"
              />
              <Text style={styles.modalText}>Guests</Text>
            </View>

            <ScrollView style={styles.guestView}>
              <View style={styles.guestContainer}>
                <Image
                  style={styles.profile as StyleProp<ImageStyle>}
                  source={
                    user.profileImage
                      ? { uri: user.profileImage }
                      : require('../../../assets/images/user_placeholder.png')
                  }
                />
                <View style={styles.guestDetails}>
                  <Text style={styles.guestText}>
                    {user.fullName ? user.fullName : user.email}
                  </Text>
                  <Text
                    style={[styles.guestText, { fontSize: 10, opacity: 0.6 }]}
                  >
                    Organizer
                  </Text>
                </View>
              </View>
              {event.guestDetails.length > 0 && (
                <Text style={styles.modalText}>Going</Text>
              )}
              {event.guestDetails.map((guest, index) => {
                return (
                  <View style={styles.guestContainer} key={index}>
                    <Image
                      style={styles.profile as StyleProp<ImageStyle>}
                      source={
                        guest.profileImage
                          ? { uri: guest.profileImage }
                          : require('../../../assets/images/user_placeholder.png')
                      }
                    />
                    <View style={styles.guestDetails}>
                      <Text style={styles.guestText}>
                        {guest.fullName ? guest.fullName : guest.email}
                      </Text>
                    </View>
                  </View>
                );
              })}
              {event.pendingDetails.length > 0 && (
                <Text style={styles.modalText}>Pending Invites</Text>
              )}
              {event.pendingDetails.map((guest, index) => {
                return (
                  <View style={styles.guestContainer} key={index}>
                    <Image
                      style={styles.profile as StyleProp<ImageStyle>}
                      source={
                        guest.profileImage
                          ? { uri: guest.profileImage }
                          : require('../../../assets/images/user_placeholder.png')
                      }
                    />
                    <View style={styles.guestDetails}>
                      <Text style={styles.guestText}>
                        {guest.fullName ? guest.fullName : guest.email}
                      </Text>
                    </View>
                  </View>
                );
              })}
              {event.declinedDetails.length > 0 && (
                <Text style={styles.modalText}>Declined</Text>
              )}
              {event.declinedDetails.map((guest, index) => {
                return (
                  <View style={styles.guestContainer} key={index}>
                    <Image
                      style={styles.profile as StyleProp<ImageStyle>}
                      source={
                        guest.profileImage
                          ? { uri: guest.profileImage }
                          : require('../../../assets/images/user_placeholder.png')
                      }
                    />
                    <View style={styles.guestDetails}>
                      <Text style={styles.guestText}>
                        {guest.fullName ? guest.fullName : guest.email}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </ScrollView>
          </View>
        </ScrollView>
      </Modal>
      <TouchableOpacity
        style={styles.eventContainer}
        onPress={() => showModal()}
      >
        <Text style={styles.date}>
          {format(parseISO(event.date), 'MMMM dd, yyyy')}
        </Text>
        <Layout style={styles.circle} />
        <Layout style={styles.detailContainer}>
          <Text style={styles.eventName} numberOfLines={1}>
            {event.eventName}
          </Text>
          <Layout style={styles.subdetailContainer}>
            <Icon style={styles.icon} fill="#4E7B89" name="clock-outline" />
            <Text style={styles.detail}>
              {format(
                new Date(new Date().toDateString() + ' ' + event.timeStart),
                'HH:mm a',
              )}{' '}
              -{' '}
              {format(
                new Date(new Date().toDateString() + ' ' + event.timeEnd),
                'HH:mm a',
              )}
            </Text>
          </Layout>
        </Layout>
        <Layout style={styles.buttonContainer}>
          <TouchableOpacity onPress={onEdit}>
            <Icon
              style={styles.buttonIcon}
              fill="#4E7B89"
              name="edit-2-outline"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={onDelete}>
            <Icon
              style={styles.buttonIcon}
              fill="#4E7B89"
              name="trash-outline"
            />
          </TouchableOpacity>
        </Layout>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  eventContainer: {
    width: '100%',
    borderBottom: '1px solid black',
    padding: 10,
    flexDirection: 'row',
  },
  date: {
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 12,
    color: 'white',
  },
  circle: {
    marginLeft: 5,
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: '#31576D',
  },
  detailContainer: {
    flexDirection: 'column',
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  eventName: {
    fontSize: 15,
    color: 'white',
    maxWidth: 250,
  },
  detail: {
    fontSize: 10,
    color: 'white',
    opacity: 0.6,
    marginTop: 2,
    marginLeft: 2,
  },
  icon: {
    height: 16,
    width: 14,
  },
  subdetailContainer: {
    flexDirection: 'row',
  },
  buttonContainer: {
    flexDirection: 'row',
    position: 'absolute',
    right: 10,
    top: 20,
  },
  buttonIcon: {
    height: 18,
    width: 18,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: 500,
    maxHeight: 500,
    backgroundColor: '#091C2D',
  },
  modalContent: {
    marginTop: 30,
  },
  modalDetail: {
    marginVertical: 5,
    marginHorizontal: 10,
    flexDirection: 'row',
  },
  modalIcon: {
    width: 20,
    height: 20,
    marginHorizontal: 10,
  },
  modalText: {
    width: 400,
    fontSize: 16,
    opacity: 0.6,
    alignSelf: 'center',
  },
  guestView: {
    height: 200,
    marginBottom: 20,
  },
  guestContainer: {
    flexDirection: 'row',
    marginHorizontal: 40,
    marginVertical: 5,
  },
  guestDetails: {
    width: '100%',
    alignSelf: 'center',
  },
  guestText: {
    width: '80%',
    fontSize: 14,
    color: 'white',
  },
  profile: {
    width: 30,
    height: 30,
    borderRadius: 15,
    marginHorizontal: 10,
  },
});

export default observer(CalendarEvent);
