import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';

export type Props = {
  onPress: () => void;
};

const JoinItem: React.FC<Props> = (props) => {
  const { onPress } = props;
  const styles = useStyleSheet(themedStyles);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout style={styles.container} level="2">
      <TouchableOpacity
        style={isMobile ? styles.contentMobile : styles.content}
        onPress={onPress}
      >
        <Text>+ New Team</Text>
      </TouchableOpacity>
    </Layout>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  content: {
    height: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 24,
    marginVertical: 22,
  },
  contentMobile: {
    height: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 15,
    marginVertical: 15,
  },
});

export default JoinItem;
