import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { RootStore } from '../stores';
import SweatGlobalStepExercise from './SweatGlobalStepExercise';

@model('o2x-store/SweatGlobalStep')
export default class SweatGlobalStep extends Model({
  id: prop<number>(),
  name: prop<string>(),
  fteOwner: prop<number>(),
  isActive: prop<boolean>(),
  stepType: prop<string>(''),
  exercises: prop<SweatGlobalStepExercise[]>(),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(source: ModelInstanceCreationData<SweatGlobalStep>) {
    const data = { ...source };
    const { exercises } = data;
    delete data.exercises;
    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    this.exercises = exercises.map((item: any) => {
      const data = { ...item };
      const { exercise } = data;
      delete data.exercise;

      const globalExercise = new SweatGlobalStepExercise(data);
      globalExercise.exercise = exercise.id;

      sweat.createOrUpdateSweatExercise(exercise);

      return globalExercise;
    });
  }
}
