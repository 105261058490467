import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import MusclesStatistics from 'src/components/BodyComposition/MuscleStatistics';
import ProgressBar from 'src/components/BodyComposition/ProgressBar';
import InfoWhite from '../../assets/images/assessment-info-white.svg';
import Info from '../../assets/images/assessment-info.svg';
import RightOutline from '../../assets/images/right-outline.svg';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentHistory'>;
};

const BodyCompositionItem: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const { auth, assessment: assessmentStore, sweat: sweatStore } = useStore();

  const assessment = assessmentStore.bodyComposition.get(`${assessmentId}`);
  const [loading, setLoading] = useState(false);
  const [isOpenMuscleInfo, setIsOpenMuscleInfo] = useState(false);
  const [summary, setSummary] = useState([]);
  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState('');
  const navigation = useNavigation();

  const barGraphValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, '%'];
  const barGraphValuesMobile = [0, 20, 40, 60, 80, 100, '%'];
  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    if (assessment) analytics.logAssessmentOpen(assessment);
  }, [assessment]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const result = await assessmentStore.fetchBodyCompositionSummary();
        if (result.ok) {
          setSummary(result.extra);
        }
        setLoading(false);
      })();
    }, []),
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const goToAssessment = useCallback(() => {
    navigation.navigate('Plan');
  }, []);

  if (!assessment) {
    return null;
  }

  const { testDate, bmr, lbm, bfm, bmi, weight } = assessment;
  const percentagesmm = (assessment.smm * 100) / assessment.weight;
  const percentagebfm = (assessment.bfm * 100) / assessment.weight;

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        setModalOpen('');
      }}
    >
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout
              style={isMobile ? styles.containerMobile : styles.container}
            >
              <Layout style={styles.header}>
                <Layout style={styles.row}>
                  <TouchableOpacity onPress={goToAssessment}>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.assessmentTexMaxWidth900
                          : styles.assessmentText
                      }
                    >
                      Assessments
                    </Text>
                  </TouchableOpacity>
                  {isDeviceMaxWidth900 ? (
                    <RightOutline
                      style={styles.rightOutline}
                      width="6"
                      height="10"
                      viewBox="0 0 8 14"
                    />
                  ) : (
                    <RightOutline />
                  )}
                  <TouchableOpacity onPress={goBack}>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.bodyCompositionTextMaxWidth900
                          : styles.bodyCompositionText
                      }
                    >
                      Body Composition Assessment
                    </Text>
                  </TouchableOpacity>
                  {isDeviceMaxWidth900 ? (
                    <RightOutline
                      style={styles.rightOutline}
                      width="6"
                      height="10"
                      viewBox="0 0 8 14"
                    />
                  ) : (
                    <RightOutline />
                  )}
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameHeaderMaxWidth900
                        : styles.assessmentNameHeader
                    }
                  >
                    {moment(assessment.testDate).format('MMM D, YYYY')}
                  </Text>
                </Layout>
              </Layout>
              <Layout
                style={
                  isMobile ? styles.userContainerMobile : styles.userContainer
                }
              >
                <Layout style={styles.user}>
                  <Image
                    style={styles.profile as StyleProp<ImageStyle>}
                    source={
                      currentUser?.profileImage
                        ? { uri: currentUser?.profileImage }
                        : require('../../assets/images/user_placeholder.png')
                    }
                  />
                  <Layout
                    style={isMobile ? styles.userDataMobile : styles.userData}
                  >
                    <Text
                      style={
                        isMobile
                          ? styles.fullNameMobile
                          : isDeviceMaxWidth900
                          ? styles.fullNameMaxWidth900
                          : styles.fullName
                      }
                    >
                      {currentUser?.fullName}
                    </Text>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.professionMaxWidth900
                          : styles.profession
                      }
                    >
                      {currentUser?.profession}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.assessmentContainer}>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameMaxWidth900
                        : styles.assessmentName
                    }
                  >
                    Body Composition Assessment
                  </Text>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentDateMaxWidth900
                        : styles.assessmentDate
                    }
                  >
                    {testDate === null
                      ? ''
                      : `date taken: ${moment(testDate).format(
                          'MMM DD, YYYY',
                        )}`}
                  </Text>
                </Layout>
              </Layout>
              <Layout style={styles.tableContainer}>
                <Layout
                  style={
                    isMobile
                      ? styles.statsContainerMobile
                      : styles.statsContainer
                  }
                >
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>
                      Basal Metabolic Rate
                    </Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {Math.floor(bmr)} KCAL{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('bmr');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>

                    {modalOpen === 'bmr' && (
                      <Layout style={styles.popUp}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            Basal metabolic rate(BMR) is the amount of energy
                            per unit time that a person needs to keep the body
                            functioning at rest
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Lean Body Mass</Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {lbm} LBS{' '}
                    </Text>

                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('lbm');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>
                    {modalOpen === 'lbm' && (
                      <Layout style={styles.popUp}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            Lean body mass (LBM) is a component of body
                            composition, calculated by subtracting body fat
                            weight from total body weight: total body weight is
                            lean plus fat.
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Body Fat Mass</Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {bfm} LBS{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('bfm');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>
                    {modalOpen === 'bfm' && (
                      <Layout style={styles.popUp}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            Body fat includes essential body fat and storage
                            body fat.
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Body Fat Percentage</Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {percentagebfm.toFixed(2)} %{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('percentagebfm');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>
                    {modalOpen === 'percentagebfm' && (
                      <Layout style={styles.popUp}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            The body fat percentage (BFP) of a human or other
                            living being is the total mass of fat divided by
                            total body mass, multiplied by 100.
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>
                      Body Mass Index (KG/M2)
                    </Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {bmi}{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('bmi');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>
                    {modalOpen === 'bmi' && (
                      <Layout style={[styles.popUp, { bottom: 70 }]}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            Body fat is the amount of fat that one has apart
                            from one's bones, water content, muscles and various
                            organs. Your fitness level depends on two components
                            - body fat and body muscle. Two different people of
                            same height and of same age may weigh the same and
                            have the same BMI but their fat and muscle content
                            may differ
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Weight (Total Mass)</Text>
                    <Text
                      style={
                        isMobile
                          ? styles.categoryValueMobile
                          : styles.categoryValue
                      }
                    >
                      {weight}{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        setModalOpen('weight');
                      }}
                      style={styles.categoryIcon}
                    >
                      <Info />
                    </TouchableOpacity>
                    {modalOpen === 'weight' && (
                      <Layout style={[styles.popUp, { bottom: 3 }]}>
                        <Layout style={styles.popUpContainerTable}>
                          <Text style={styles.musclesInfoPopUpText}>
                            Weight of the body
                          </Text>
                        </Layout>
                      </Layout>
                    )}
                  </Layout>
                </Layout>
                {/* <View style={{ width: '5%' }} /> */}
                {/* <Layout style={styles.statsContainer}>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.recommendationText}>
                      Recommendation
                    </Text>
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Body Fat Mass</Text>
                    <Text style={styles.categoryValue}>
                      {bfmControl ? bfmControl : '00'}{' '}
                    </Text>
                    <View style={styles.categoryIcon}>
                      <Info />
                    </View>
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Text style={styles.categoryName}>Lean Body Mass</Text>
                    <Text style={styles.categoryValue}>
                      {lbmControl ? lbmControl : '00'}{' '}
                    </Text>
                    <View style={styles.categoryIcon}>
                      <Info />
                    </View>
                  </Layout>
                </Layout> */}
              </Layout>
              <Layout style={styles.bcaInfoContainer}>
                <Layout style={styles.bcaInfoContent}>
                  <View style={styles.bcaIcon}>
                    <InfoWhite stroke={theme['white']} />
                  </View>
                  <Text style={styles.bcaInfo}>
                    The analysis below shows the amount of muscle in the body
                    and highlights whether or not its distributed equally. The
                    percentage shows a comparison with the ideal muscle mass
                    based on height, weight, age, and other factors.
                  </Text>
                </Layout>
              </Layout>
              <MusclesStatistics assessment={assessment} />

              <Layout style={styles.musclesFatHeader}>
                <Text style={styles.musclesFatText}>Muscle Fat Comparison</Text>

                <TouchableOpacity
                  style={styles.musclesFatIcon}
                  onPress={() => setIsOpenMuscleInfo(!isOpenMuscleInfo)}
                >
                  <InfoWhite />
                </TouchableOpacity>

                {!!isOpenMuscleInfo && (
                  <Layout>
                    <View
                      style={[styles.popUpContainer, isMobile && { right: 1 }]}
                    >
                      <Text style={styles.musclesInfoPopUpText}>
                        The longer the Skeletal Muscle Mass bar is compared to
                        the Body Fat Mass bar, the stronger the body is.
                      </Text>
                    </View>
                  </Layout>
                )}
              </Layout>
              <Layout style={styles.muscleFatContainer}>
                <Layout style={styles.muscleFatCategoryContainer}>
                  <Text style={styles.muscleFatCategory}>
                    Skeletal Muscle Mass (lbs)
                  </Text>
                  <Layout style={styles.horizontalBarGraph}>
                    {isMobile ? (
                      <>
                        <View style={styles.horizontalLabelContainer}>
                          {barGraphValuesMobile.map((value) => (
                            <Text style={styles.valueText} key={value}>
                              {value}
                            </Text>
                          ))}
                        </View>
                        <View
                          style={[
                            styles.valueBar,
                            { width: `${percentagesmm * (5 / 7)}%` },
                          ]}
                        />
                      </>
                    ) : (
                      <>
                        <View style={styles.horizontalLabelContainer}>
                          {barGraphValues.map((value) => (
                            <Text style={styles.valueText} key={value}>
                              {value}
                            </Text>
                          ))}
                        </View>
                        <View
                          style={[
                            styles.valueBar,
                            { width: `${percentagesmm * (11 / 13)}%` },
                          ]}
                        />
                      </>
                    )}
                  </Layout>
                </Layout>
                <Layout style={styles.muscleFatCategoryContainer}>
                  <Text style={styles.muscleFatCategory}>
                    Body Fat Mass (lbs)
                  </Text>
                  <Layout style={styles.horizontalBarGraph}>
                    {isMobile ? (
                      <>
                        <View style={styles.horizontalLabelContainer}>
                          {barGraphValuesMobile.map((value) => (
                            <Text style={styles.valueText} key={value}>
                              {value}
                            </Text>
                          ))}
                        </View>
                        <View
                          style={[
                            styles.valueBar,
                            { width: `${percentagebfm * (5 / 7)}%` },
                          ]}
                        />
                      </>
                    ) : (
                      <>
                        <View style={styles.horizontalLabelContainer}>
                          {barGraphValues.map((value) => (
                            <Text style={styles.valueText} key={value}>
                              {value}
                            </Text>
                          ))}
                        </View>
                        <View
                          style={[
                            styles.valueBar,
                            { width: `${percentagebfm * (11 / 13)}%` },
                          ]}
                        />
                      </>
                    )}
                  </Layout>
                </Layout>
              </Layout>

              <Layout
                style={
                  isDeviceMaxWidth768
                    ? styles.bcaSummaryContainerMaxWidth768
                    : styles.bcaSummaryContainer
                }
              >
                <Text
                  style={
                    isDeviceMaxWidth768
                      ? styles.bcaScoreTitleMaxWidth768
                      : styles.bcaScoreTitle
                  }
                >
                  Your Progress over time
                </Text>
                {!!summary && <ProgressBar summary={summary} />}
              </Layout>
            </Layout>
          </ScrollView>
        </Layout>
      )}
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  bodyCompositionText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingHorizontal: 10,
    color: '#4E7B89',
  },
  bodyCompositionTextMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingHorizontal: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  categoryContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingVertical: 8,
  },
  categoryContainerMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    paddingVertical: 5,
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  statsContainer: {
    width: '70%',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#697484',
    paddingHorizontal: 10,
    paddingVertical: 17,
    marginTop: 10,
  },
  statsContainerMobile: {
    width: '95%',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#697484',
    paddingHorizontal: 10,
    marginTop: 10,
  },
  categoryName: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 2,
  },
  categoryValue: {
    fontSize: 16,
    lineHeight: 19,
    textTransform: 'uppercase',
    flex: 1,
  },
  categoryValueMobile: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
  },
  categoryIcon: {
    flex: 1,
    alignItems: 'center',
  },
  categoryIconMobile: {
    alignItems: 'center',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  recommendationText: {
    fontSize: 18,
    lineHeight: 22,
    flex: 1,
  },
  tableContainer: {
    flexDirection: 'row',
    paddingVertical: 15,
    justifyContent: 'center',
  },
  bcaInfoContent: {
    backgroundColor: 'transparent',
    width: '92%',
    flexDirection: 'row',
  },
  bcaInfoContainer: {
    width: '95%',
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderLeftWidth: 4,
    borderColor: 'olive',
    marginTop: 20,
    alignSelf: 'center',
  },
  bcaInfo: {
    flex: 1,
    fontSize: 14,
    letterSpacing: 1,
  },
  bcaIcon: {
    justifyContent: 'center',
    paddingRight: 10,
  },
  musclesFatHeader: {
    width: '95%',
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    alignItems: 'center',
    marginTop: 20,
    alignSelf: 'center',
    height: 40,
    backgroundColor: '#31576D',
  },
  musclesFatText: {
    fontSize: 18,
    lineHeight: 22,
    paddingRight: 10,
  },
  musclesFatIcon: {
    marginLeft: 10,
  },
  muscleFatContainer: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    width: '95%',
    alignSelf: 'center',
  },
  muscleFatCategoryContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  muscleFatCategory: {
    flex: 1,
    color: 'blue-secondary',
    fontSize: 14,
    lineHeight: 18,
    paddingRight: 10,
  },
  horizontalBarGraph: {
    flex: 3,
  },
  valueText: {
    flex: 1,
    fontSize: 12,
    lineHeight: 14,
    color: 'blue-secondary',
  },
  valueBar: {
    backgroundColor: 'gray',
    height: 10,
  },
  popUpContainer: {
    backgroundColor: 'black',
    width: 250,
    padding: 8,
    position: 'absolute',
    bottom: 2,
    flexGrow: 1,
  },
  popUpContainerTable: {
    backgroundColor: 'black',
    width: 250,
    padding: 8,
    position: 'absolute',
  },
  popUp: {
    position: 'absolute',
    minHeight: 50,
    bottom: 25,
  },
  musclesInfoPopUpText: {
    fontSize: 12,
    lineHeight: 14,
  },
  bcaSummaryContainer: {
    height: 374,
    marginLeft: 20,
    flexGrow: 1,

    padding: 22,
  },
  bcaSummaryContainerMaxWidth768: {
    padding: 15,
    width: '100%',
    marginTop: 5,
    marginBottom: 20,
  },
  bcaScoreTitle: {
    fontSize: 18,
    lineHeight: 20,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    position: 'absolute',
    left: 22,
    top: 22,
  },
  bcaScoreTitleMaxWidth768: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    position: 'absolute',
    left: 15,
    top: 15,
  },
  horizontalLabelContainer: {
    flexDirection: 'row',
    paddingBottom: 5,
  },
});

export default observer(BodyCompositionItem);
