import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { FTESweatWorkoutStepExercise } from '../../../../o2x-store/src/models/FTELibrary';
import ExerciseDetail from './ExerciseDetail';

type Props = {
  exercises: (
    | {
        exercise: FTESweatWorkoutStepExercise;
        date: string;
        userNotes: string;
        weightInputs: string[];
      }
    | undefined
  )[];
  onClose: () => void;
};

const ExerciseHistory: React.FC<Props> = ({ exercises, onClose }) => {
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  const sortedExercises = exercises.sort((x, y) => {
    if (x?.date !== 'Ongoing' && y?.date === 'Ongoing') return -1;
    else return 0;
  });
  return (
    <Layout style={styles.container}>
      <Layout style={!!isMobile ? styles.mobileBg : styles.bgContainer}>
        <Layout style={styles.header}>
          <Text style={styles.headerText}>
            {exercises[0] ? exercises[0].exercise.exerciseName : ''}
          </Text>

          <TouchableOpacity
            onPress={onClose}
            style={[styles.close, { marginLeft: 'auto' }]}
          >
            <Icon name="close-outline" fill="white" style={styles.icon} />
          </TouchableOpacity>
        </Layout>

        <Layout style={styles.contentContainer}>
          <ScrollView>
            {!!isMobile ? (
              <ScrollView horizontal={true}>
                <Layout style={{ width: 600 }}>
                  {exercises.map(
                    (
                      exercise:
                        | {
                            exercise: FTESweatWorkoutStepExercise;
                            date: string;
                            userNotes: string;
                            weightInputs: string[];
                          }
                        | undefined,
                    ) =>
                      exercise ? (
                        <ExerciseDetail
                          exercise={exercise}
                          key={exercise.exercise.id}
                        />
                      ) : (
                        <></>
                      ),
                  )}
                </Layout>
              </ScrollView>
            ) : (
              <Layout>
                {sortedExercises.map(
                  (
                    exercise:
                      | {
                          exercise: FTESweatWorkoutStepExercise;
                          date: string;
                          userNotes: string;
                          weightInputs: string[];
                        }
                      | undefined,
                    index,
                  ) =>
                    exercise ? (
                      <ExerciseDetail
                        exercise={exercise}
                        key={`${exercise.exercise.id}-${
                          exercise.userNotes
                        }-${index}-${Math.random()}`}
                      />
                    ) : (
                      <></>
                    ),
                )}
              </Layout>
            )}
          </ScrollView>
        </Layout>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '90%',
    height: '90%',
    marginLeft: '4%',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileBg: {
    position: 'absolute',
    width: '87%',
    height: '93%',
    left: '26px',
    right: '26px',
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '93%',
    height: '87%',
    marginLeft: '20px',
    marginBottom: '40px',
  },
  header: {
    width: '92%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
    paddingTop: '15px',
  },
  headerText: {
    color: 'white',
    fontSize: 16,
  },
  close: {
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export default observer(ExerciseHistory);
