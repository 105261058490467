import { useFocusEffect } from '@react-navigation/native';
import {
  Datepicker,
  Icon,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import * as ImagePicker from 'expo-image-picker';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import {
  filter,
  find,
  findIndex,
  get,
  indexOf,
  isUndefined,
  keys,
  omitBy,
  set,
  uniq,
} from 'lodash';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import CameraIcon from '../../assets/images/camera.svg';
import EditIcon from '../../assets/images/edit.svg';
import Week from '../../components/Calendar/Week';
import LabeledInput from '../../components/LabeledInput';
import HeightInput from '../../components/Question/HeightInput';
import { getErrors } from '../../utils/errors';
import { convertPhoto, formatPhoto } from '../../utils/photos';

type Props = {};

enum TAB_TYPE {
  PROFILE = 'profile',
  STATS = 'stats',
}
enum FIELD_TYPE {
  TEXT = 'text',
  DATE = 'date',
  SELECT = 'select',
  HEIGHT = 'height',
}

type FieldOption = {
  label: string;
  value: string;
};

type EditableField = {
  key: string;
  label: string;
  value: string | number | Date;
  type: FIELD_TYPE;
  options?: Array<FieldOption> | undefined;
};

type UserData = {
  user: User;
  values: Partial<User>;
};

const EDITABLE_FIELDS = [
  'firstName',
  'lastName',
  'birthDate',
  'gender',
  'height',
  'weight',
  'profession',
  'email',
  'phone',
  'address',
  'city',
  'stateCode',
  'zipCode',
  'bmi',
  'bmr',
];

const Profile: React.FC<Props> = () => {
  const root = useStore();
  const { auth, user: userStore } = root;
  const styles = useStyleSheet(themedStyles);
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState<UserData>();
  const [selected, setSelected] = useState(TAB_TYPE.PROFILE);
  const [photo, setPhoto] = useState(null);
  const [editingFields, setEditingFields] = useState<Array<string>>(
    new Array<string>(),
  );

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const [datePickerData, setDatePickerData] = useState({
    show: false,
    value: format(new Date(), 'yyyy-MM-dd'),
    field: '',
  });
  const [pickerData, setPickerData] = useState({
    show: false,
    options: [],
    value: '',
    field: '',
  });

  const profileFields: Array<EditableField> = [
    // {
    //   key: 'email',
    //   label: 'E-mail',
    //   value: userData?.values.email || '',
    //   type: FIELD_TYPE.TEXT,
    // },
    {
      key: 'firstName',
      label: 'First Name',
      value: userData?.values.firstName || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      value: userData?.values.lastName || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'birthDate',
      label: 'Date of Birth',
      value: userData?.values.birthDate || '',
      type: FIELD_TYPE.DATE,
    },
    {
      key: 'gender',
      label: 'Gender',
      value: userData?.values.gender || '',
      type: FIELD_TYPE.SELECT,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
    },
    {
      key: 'phone',
      label: 'Number',
      value: userData?.values.phone || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  const supportDataFields: Array<EditableField> = [
    {
      key: 'address',
      label: 'Location',
      value: userData?.values.address || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'city',
      label: 'City',
      value: userData?.values.city || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'stateCode',
      label: 'State',
      value: userData?.values.stateCode || '',
      type: FIELD_TYPE.SELECT,
      options: root.question.states,
    },
    {
      key: 'zipCode',
      label: 'Zip Code',
      value: userData?.values.zipCode || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'height',
      label: 'Height',
      value: userData?.values.height || '',
      type: FIELD_TYPE.HEIGHT,
    },
    {
      key: 'weight',
      label: 'Weight (lbs)',
      value: userData?.values.weight || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'bmi',
      label: 'BMI',
      value: userData?.values.bmi || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'bmr',
      label: 'BMR',
      value: userData?.values.bmr || '',
      type: FIELD_TYPE.TEXT,
    },
    {
      key: 'profession',
      label: 'Profession',
      value: userData?.values.profession || '',
      type: FIELD_TYPE.TEXT,
    },
  ];

  const isDisabled = (key: string) => {
    return indexOf(editingFields, key) === -1;
  };

  useFocusEffect(
    useCallback(() => {
      root.question.fetchStates();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        await userStore.fetchMe();
      })();
    }, [auth.user]),
  );

  useEffect(() => {
    const storedUser = auth.user;
    if (storedUser) {
      const storedUserValues: Partial<User> = {};
      EDITABLE_FIELDS.forEach((f: string) =>
        set(storedUserValues, f, get(storedUser, f)),
      );
      setUserData({
        user: storedUser,
        values: storedUserValues,
      });
    }
  }, [auth.user, userStore.loading]);

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f) => {
      register({ name: f }, { required: false });
    });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      if (data) {
        const result = await userStore.updateMe(omitBy(data, isUndefined));
        if (result.ok) {
          keys(data).forEach((k: string) => {
            setValue(k, data[k]);
            onSetEditing(k, false);
          });
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [userData],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSetEditing = useCallback((key: string, isEditing: boolean) => {
    if (isEditing) {
      setEditingFields(uniq([...editingFields, key]));
    } else {
      const filteredEditingFields = filter(editingFields, (f) => f !== key);
      setEditingFields(filteredEditingFields);
    }
  }, []);

  const onSubmitEdit = useCallback((key: string) => {
    const data = { [key]: getValues(key) };
    return onSubmit(data);
  }, []);

  const getIndex = (arr: Array<FieldOption>, value: number | string) => {
    const idx = findIndex(arr, (o) => o.value === value);
    // if (idx < 0) return 0;
    return idx;
  };

  const getSelectValue = (
    options: Array<FieldOption>,
    value: string | number,
  ) => {
    const valueOption = find(options, (o) => o.value === value);
    if (valueOption) {
      return valueOption.label;
    }
    return '';
  };

  const renderSelectField = useCallback(
    (item: EditableField) => {
      return isDisabled(item.key) ? (
        <LabeledInput
          style={styles.inputContainer}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => setValue(item.key, text)}
          defaultValue={getSelectValue(item.options, item.value)}
          disabled={isDisabled(item.key)}
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
          divider={false}
        />
      ) : (
        <View style={styles.inputContainer}>
          <Select
            placeholder="Select an option"
            value={getSelectValue(
              item.options,
              get(userData?.values, item.key),
            )}
            selectedIndex={
              getIndex(item.options, get(userData?.values, item.key)) > -1
                ? new IndexPath(
                    getIndex(item.options, get(userData?.values, item.key)),
                  )
                : null
            }
            onSelect={(index) => {
              const indexStr = index.toString();
              setUserData({
                user: userData?.user,
                values: {
                  ...userData?.values,
                  [item.key]: item.options[Number(indexStr) - 1].value,
                },
              });
              setValue(item.key, item.options[Number(indexStr) - 1].value);
            }}
          >
            {item.options?.map((o) => (
              <SelectItem title={o.label} key={o.value} />
            ))}
          </Select>
        </View>
      );
    },
    [root.question.states, selected, editingFields, userData],
  );

  const renderDatePickerField = useCallback(
    (item: EditableField) => {
      return isDisabled(item.key) ? (
        <LabeledInput
          style={styles.inputContainer}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => setValue(item.key, text)}
          defaultValue={item.value || ''}
          disabled={isDisabled(item.key)}
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
          divider={false}
        />
      ) : (
        <View style={styles.inputContainer}>
          <Datepicker
            date={
              get(userData?.values, item.key)
                ? parseISO(get(userData?.values, item.key))
                : new Date()
            }
            onSelect={(value: Date) => {
              const newValue = value ? format(value, 'yyyy-MM-dd') : null;
              setUserData({
                ...userData,
                values: { ...userData?.values, [item.key]: newValue },
              });
              setDatePickerData({
                show: false,
                field: '',
                value: format(new Date(), 'yyyy-MM-dd'),
              });
              setValue(item.key, newValue);
            }}
            max={new Date()}
            min={new Date(1900, 0, 1)}
          />
        </View>
      );
    },
    [root.question.states, selected, editingFields, userData],
  );

  const renderHeightField = useCallback(
    (item: EditableField) => {
      return (
        <HeightInput
          question={{
            question: item.label,
            type: item.type,
            key: item.key,
            options: [],
          }}
          style={styles.inputContainer}
          inputStyle={styles.inputFieldHeight}
          textStyle={styles.inputFieldText}
          disabled={isDisabled(item.key)}
          keyboardType="decimal-pad"
          multiline
          onChange={(k, v) => setValue(item.key, v)}
          divider={false}
          initial={item.value}
          required={false}
        />
      );
    },
    [root.question.states, selected, editingFields],
  );

  const renderTextField = useCallback(
    (item: EditableField) => {
      return (
        <LabeledInput
          style={styles.inputContainer}
          inputStyle={styles.inputField}
          textStyle={styles.inputFieldText}
          onChangeText={(text) => setValue(item.key, text)}
          defaultValue={item.value || ''}
          disabled={isDisabled(item.key)}
          keyboardType={
            indexOf(['height', 'weight'], item.key) !== -1
              ? 'decimal-pad'
              : 'default'
          }
          multiline
          divider={false}
        />
      );
    },
    [root.question.states, selected, editingFields],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <>
        {isDeviceMaxWidth768 && (
          <Text style={styles.labelTabletOrMobile}>{item.label}</Text>
        )}
        <View style={styles.itemContainer}>
          {!isDeviceMaxWidth768 && (
            <Text style={styles.label}>{item.label}</Text>
          )}
          <View
            style={
              isDeviceMaxWidth768
                ? styles.itemEditableTabletOrMobile
                : styles.itemEditable
            }
          >
            {item.type === FIELD_TYPE.DATE && renderDatePickerField(item)}
            {item.type === FIELD_TYPE.TEXT && renderTextField(item)}
            {item.type === FIELD_TYPE.SELECT && renderSelectField(item)}
            {item.type === FIELD_TYPE.HEIGHT && renderHeightField(item)}
            <View style={styles.divider} />
          </View>
          <View style={styles.inputAction}>
            {isDisabled(item.key) ? (
              <TouchableOpacity
                onPress={() => {
                  if (item.type === FIELD_TYPE.SELECT) {
                    setPickerData({
                      show: true,
                      options: item.options,
                      value: item.value,
                      field: item.key,
                    });
                    onSetEditing(item.key, true);
                  } else {
                    onSetEditing(item.key, true);
                  }
                }}
                style={styles.editAction}
              >
                <EditIcon style={styles.inputActionIcon} />
              </TouchableOpacity>
            ) : (
              <View style={styles.multiAction}>
                <TouchableOpacity
                  onPress={() => {
                    setUserData({
                      ...userData,
                      values: {
                        ...userData?.values,
                        [item.key]: get(userData?.user, item.key),
                      },
                    });
                    onSetEditing(item.key, false);
                  }}
                >
                  <Icon
                    style={styles.inputActionIcon2}
                    fill="white"
                    name="close-outline"
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={onSubmitWrapped}>
                  <Icon
                    style={styles.inputActionIcon2}
                    fill="white"
                    name="checkmark"
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </>
    ),
    [
      editingFields,
      onSubmitWrapped,
      onSetEditing,
      userData,
      setDatePickerData,
      datePickerData,
      profileFields,
      userData,
    ],
  );

  const onSelectPhoto = useCallback(
    async (imageInfo: ImageInfo) => {
      setPhotoLoading(true);

      const photo = await convertPhoto(formatPhoto(imageInfo));
      setPhoto(photo);

      await userStore.updateMePhoto(photo);
      setPhotoLoading(false);
    },
    [userStore],
  );

  const openLibrary = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.cancelled) {
      onSelectPhoto(result as ImageInfo);
    }
  }, [onSelectPhoto]);

  let uri: string = '';
  if (photo) {
    uri = photo?.photo.thumbnail || photo?.photo.fullSize || photo?.uri;
  } else if (auth.user?.profileImage) {
    uri = auth.user?.profileImage;
  }

  return (
    <>
      {loading ? (
        <Layout style={styles.loader}>
          <Spinner />
        </Layout>
      ) : (
        <ScrollView style={styles.scroll}>
          <Layout style={isMobile ? styles.bodyMobile : styles.body}>
            <Layout style={styles.tabContainer}>
              <TouchableOpacity
                style={[
                  styles.tabBtn,
                  selected === TAB_TYPE.PROFILE && styles.isTabSelected,
                ]}
                onPress={() => setSelected(TAB_TYPE.PROFILE)}
              >
                <Text
                  style={[
                    styles.tabText,
                    selected === TAB_TYPE.PROFILE && styles.isTextSelected,
                  ]}
                >
                  My Profile
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.tabBtn,
                  selected === TAB_TYPE.STATS && styles.isTabSelected,
                ]}
                onPress={() => setSelected(TAB_TYPE.STATS)}
              >
                <Text
                  style={[
                    styles.tabText,
                    selected === TAB_TYPE.STATS && styles.isTextSelected,
                  ]}
                >
                  My Stats
                </Text>
              </TouchableOpacity>
            </Layout>
            <Layout style={styles.tabContent}>
              {selected === TAB_TYPE.PROFILE ? (
                <Layout
                  style={
                    isDeviceMaxWidth768
                      ? styles.profileContainerTabletOrMobile
                      : styles.profileContainer
                  }
                >
                  <Layout
                    style={
                      isDeviceMaxWidth768
                        ? styles.dataContainerTabletOrMobile
                        : styles.personalDataContainer
                    }
                  >
                    <Layout style={styles.profile}>
                      <TouchableOpacity
                        style={styles.changeProfile}
                        onPress={openLibrary}
                      >
                        {uri ? (
                          <Image
                            style={styles.profilePhoto as StyleProp<ImageStyle>}
                            source={{ uri }}
                          />
                        ) : (
                          <Image
                            style={styles.profilePhoto as StyleProp<ImageStyle>}
                            source={require('../../assets/images/user_placeholder.png')}
                          />
                        )}
                        {photoLoading && (
                          <Layout style={styles.photoLoading}>
                            <ActivityIndicator />
                          </Layout>
                        )}
                        <CameraIcon style={styles.icon} />
                      </TouchableOpacity>
                      <Text style={styles.fullName}>
                        {userData?.user.fullName || userData?.user.email}
                      </Text>
                      <Text
                        style={styles.o2xId}
                      >{`O2X ID #${userData?.user.o2XId}`}</Text>
                      {!!errorMessage && (
                        <Text style={styles.error} category="label">
                          {errorMessage}
                        </Text>
                      )}
                    </Layout>
                    <FlatList
                      data={Array.from(profileFields.values())}
                      renderItem={renderItem}
                      keyExtractor={(item) => `${item.key}`}
                      style={styles.editableFields}
                    />
                  </Layout>
                  <Layout
                    style={
                      isDeviceMaxWidth768
                        ? styles.dataContainerTabletOrMobile
                        : styles.otherDataContainer
                    }
                  >
                    <Layout
                      style={
                        isDeviceMaxWidth768
                          ? styles.supportDataContainerTabletOrMobile
                          : styles.supportDataContainer
                      }
                    >
                      <Text
                        style={
                          isDeviceMaxWidth768
                            ? styles.supportDataTabletOrMobile
                            : styles.supportData
                        }
                      >
                        Support Data
                      </Text>

                      <FlatList
                        data={Array.from(supportDataFields.values())}
                        renderItem={renderItem}
                        keyExtractor={(item) => `${item.key}`}
                        style={styles.editableFields}
                      />
                    </Layout>
                  </Layout>
                </Layout>
              ) : (
                <Layout style={styles.statsContainer}>
                  <Week />
                </Layout>
              )}
            </Layout>
          </Layout>
        </ScrollView>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  statsContainer: {
    width: '100%',
    paddingBottom: 20,
    backgroundColor: 'transparent',
  },
  scroll: {
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
    paddingTop: 18,
    paddingHorizontal: 25,
    paddingBottom: 20,
  },
  bodyMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
    paddingTop: 18,
    paddingHorizontal: 15,
    paddingBottom: 15,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  tabContainer: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  tabBtn: {
    paddingVertical: 6,
  },
  tabText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
  },
  isTabSelected: {
    borderBottomColor: 'olive',
    borderBottomWidth: 3,
  },
  isTextSelected: {
    color: 'white',
  },
  tabContent: {
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: 30,
  },
  profileContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  profileContainerTabletOrMobile: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  personalDataContainer: {
    width: '35%',
    paddingHorizontal: 25,
    paddingVertical: 20,
    backgroundColor: '#091C2D',
    alignItems: 'center',
  },
  profile: {
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    marginBottom: 60,
  },
  changeProfile: {
    height: 100,
    width: 110,
    alignItems: 'center',
    marginBottom: 20,
  },
  profilePhoto: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  icon: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    right: 10,
  },
  fullName: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
  },
  o2xId: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginTop: 3,
  },
  otherDataContainer: {
    width: '63%',
    paddingHorizontal: 40,
    backgroundColor: '#091C2D',
    alignItems: 'center',
    paddingVertical: 20,
  },
  dataContainerTabletOrMobile: {
    width: '100%',
    padding: 20,
    backgroundColor: '#091C2D',
    alignItems: 'center',
  },
  supportDataContainer: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  supportDataContainerTabletOrMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
  },
  supportData: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'white',
    width: '15%',
    marginRight: 30,
  },
  supportDataTabletOrMobile: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'white',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  inputContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    width: '25%',
    marginRight: 5,
  },
  labelTabletOrMobile: {
    fontSize: 11,
    lineHeight: 12,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  itemContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemEditable: {
    width: '60%',
    flexDirection: 'column',
  },
  itemEditableTabletOrMobile: {
    width: '80%',
    flexDirection: 'column',
  },
  input: {
    paddingBottom: 9,
    width: 20,
  },
  inputField: {
    width: '100%',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inputFieldHeight: {
    width: '45%',
    outline: 'none',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    backgroundColor: '#4E7B89',
    marginBottom: 30,
    marginTop: 0,
    height: 1,
  },
  inputFieldText: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  inputAction: {
    paddingLeft: 5,
    paddingBottom: 8,
  },
  inputActionIcon: {
    width: 20,
    height: 20,
  },
  inputActionIcon2: {
    width: 28,
    height: 28,
  },
  editAction: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  multiAction: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
  },
  subSelection: {
    flexDirection: 'row',
    paddingTop: 4,
    marginBottom: 24,
  },
  subSelectionContainer: {
    flex: 1,
  },
  subSelectionContainerSelected: {},
  selectionText: {
    color: 'white',
    opacity: 0.5,
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 3,
    alignSelf: 'center',
  },
  selectionTextSelected: {
    opacity: 1,
    borderBottomWidth: 2,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  bottomPadding: {
    paddingVertical: 16,
  },
  photoLoading: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  editableFields: {
    width: '100%',
  },
});

export default observer(Profile);
