import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { ASSESSMENT_TYPE } from './Assessment';
export type BodyCompositionHistory = {
  id: number;
  testDate: string;
  bmi: number | null;
};
export type BodyCompositionSummary = {
  month: number;
  averageSmm: number;
  averageBfm: number;
  averageBmi: number;
  averageWeight: number;
};

@model('o2x-store/BodyComposition')
export default class BodyComposition extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  user: prop<number>(),
  height: prop<string>(''),
  dateOfBirth: prop<string | null>(null),
  gender: prop<string>(''),
  age: prop<number>(),
  mobile: prop<string | null>(null),
  phone: prop<string | null>(null),
  zipCode: prop<string | null>(null),
  email: prop<string | null>(null),
  dateOfRegistration: prop<string | null>(null),
  memo: prop<string | null>(null),
  organization: prop<number | null>(null),
  staff: prop<number | null>(null),
  testDate: prop<string>(''),
  weight: prop<number>(),
  tbw: prop<number>(),
  dlm: prop<number>(),
  bfm: prop<number>(),
  lbm: prop<number>(),
  smm: prop<number>(),
  bmi: prop<number>(),
  pbf: prop<number>(),
  lbmRightArm: prop<number>(),
  percentLbmRightArm: prop<number>(),
  lbmLeftArm: prop<number>(),
  percentLbmLeftArm: prop<number>(),
  lbmTrunk: prop<number>(),
  percentLbmTrunk: prop<number>(),
  lbmRightLeg: prop<number>(),
  percentLbmRightLeg: prop<number>(),
  lbmLeftLeg: prop<number>(),
  percentLbmLeftLeg: prop<number>(),
  bfmControl: prop<number>(),
  lbmControl: prop<number>(),
  bmr: prop<number>(),
  ra_impedance_20_khz: prop<number>(),
  la_impedance_20_khz: prop<number>(),
  tr_impedance_20_khz: prop<number>(),
  rl_impedance_20_khz: prop<number>(),
  ll_impedance_20_khz: prop<number>(),
  ra_impedance_100_khz: prop<number>(),
  la_impedance_100_khz: prop<number>(),
  tr_impedance_100_khz: prop<number>(),
  rl_impedance_100_khz: prop<number>(),
  ll_impedance_100_khz: prop<number>(),
  smi: prop<number>(),
  inbody_type: prop<number>(),
  serial: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  type = ASSESSMENT_TYPE.BODY;

  @modelAction
  update(data: ModelInstanceCreationData<BodyComposition>) {
    Object.assign(this, data);
  }
}
