import { useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, sub } from 'date-fns';
import { get, indexOf, isNull, startCase } from 'lodash';
import moment from 'moment';
import { UserSummary } from 'o2x-store/src/models/Summary';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useNativeStore } from 'src/stores';
import Next from '../../assets/images/next-date.svg';
import Prev from '../../assets/images/prev-date.svg';
import ScoreBadge from '../../components/ScoreBadge';
import { MarkProps } from '../../containers/User';

type Props = {
  data?: Map<string, MarkProps>;
  onExpand?: () => void;
  baseDay?: Date;
  loading?: boolean;
};

const Week: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { user: userStore, task } = useStore();
  const { workoutFilter } = useNativeStore();
  const { navigateWithPayWall } = useIsSubscribed();
  const [today, setToday] = useState(new Date());
  const [isCurrentDay, setCurrentDay] = useState(true);
  const [isCurrentDaySelected, setCurrentDaySelected] = useState(true);
  const [selectedDay, setSelectedDay] = useState(6);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Map<string, MarkProps>>(
    new Map<string, MarkProps>(),
  );

  const [summary, setSummary] = useState<UserSummary>();
  const eatMonitorFields = userStore.eatProfile?.monitorFields || [];
  const thriveMonitorFields = userStore.thriveProfile?.monitorFields || [];

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onPressEat = useCallback(() => {
    navigateWithPayWall('EatHabitQuestion');
  }, [navigateWithPayWall]);

  const onPressSweat = useCallback(() => {
    workoutFilter.reset();
    navigateWithPayWall('WorkoutGeneratorFilter');
  }, []);

  const onPressThrive = useCallback(() => {
    navigateWithPayWall('ThriveMeditate');
  }, [navigateWithPayWall]);

  const [dateSelection, setDateSelection] = useState({
    dateStart: sub(today, { days: 6 }),
    dateEnd: today,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        // Fetch data
        const fetchedData = await userStore.fetchSummary(
          format(today, 'yyyy-MM-dd'),
          format(today, 'yyyy-MM-dd'),
        );
        if (!('errors' in fetchedData)) {
          setSummary(fetchedData);
        }
        setLoading(false);
      })();
    }, []),
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      // Fetch data
      const fetchedData = await userStore.fetchHistory(
        format(dateSelection.dateStart, 'yyyy-MM-dd'),
        format(dateSelection.dateEnd, 'yyyy-MM-dd'),
      );
      if (!('errors' in fetchedData)) {
        setData(fetchedData);
      }
      setLoading(false);
    })();
  }, [dateSelection]);

  const decreaseDate = useCallback(() => {
    const newDate = new Date(today.setDate(today.getDate() - 7));
    setToday(newDate);
    setCurrentDay(false);
    setCurrentDaySelected(false);
    setSelectedDay(6);
    onShowStats(6, newDate);
    setDateSelection({
      dateStart: sub(newDate, { days: 6 }),
      dateEnd: today,
    });
  }, []);

  const increaseDate = useCallback(() => {
    let newDate = new Date(today.setDate(today.getDate() + 7));
    const currentDay = new Date();
    if (newDate >= currentDay) {
      newDate = currentDay;
      setCurrentDay(true);
      setCurrentDaySelected(true);
    }
    setToday(newDate);
    setSelectedDay(6);
    onShowStats(6, newDate);
    setDateSelection({
      dateStart: sub(newDate, { days: 6 }),
      dateEnd: today,
    });
  }, []);

  const onShowStats = useCallback((id: number, day: Date) => {
    (async () => {
      setSelectedDay(id);
      setLoading(true);
      // Fetch data
      const fetchedData = await userStore.fetchSummary(
        format(day, 'yyyy-MM-dd'),
        format(day, 'yyyy-MM-dd'),
      );
      if (!('errors' in fetchedData)) {
        setSummary(fetchedData);
      }
      setLoading(false);
      setCurrentDaySelected(
        isCurrentDay ? moment(day).isSame(new Date(), 'day') : false,
      );
    })();
  }, []);

  const getMark = (d: Date | null) => {
    if (d) {
      const d_formatted = format(d, 'yyyyMMdd');
      return get(data, d_formatted);
    }
    return {};
  };

  const getDays = () => {
    const days = [];
    let i: number;
    for (i = 6; i >= 0; i--) {
      days.push(sub(today, { days: i }));
    }
    return days;
  };

  const getDateString = () => {
    if (today.getDate() - 6 <= 0) {
      const prevDate = new Date(today);
      prevDate.setDate(prevDate.getDate() - 6);

      return `${format(prevDate, 'd')} ${format(prevDate, 'MMMM')} - ${format(
        today,
        'd',
      )}  ${format(today, 'MMMM')} ${format(today, 'yyyy')}`;
    }

    return `${today.getDate() - 6} - ${format(today, 'd')} ${format(
      today,
      'MMMM',
    )} ${format(today, 'yyyy')}`;
  };

  return (
    <Layout style={styles.container}>
      <View style={styles.dateSlider}>
        <TouchableOpacity onPress={decreaseDate}>
          <Prev />
        </TouchableOpacity>
        <Text style={isMobile ? styles.dateRangeMobile : styles.dateRange}>
          {getDateString()}
        </Text>
        <TouchableOpacity onPress={increaseDate}>
          {!isCurrentDay && <Next />}
        </TouchableOpacity>
      </View>

      <View style={styles.content}>
        {getDays().map((d, idx) => {
          const dayMarks = getMark(d);
          return (
            <TouchableOpacity
              key={idx}
              onPress={() => onShowStats(idx, d)}
              style={[styles.day, idx === selectedDay && styles.today]}
            >
              <Text
                style={[
                  isMobile
                    ? styles.labelMobile
                    : isDeviceMaxWidth768
                    ? styles.labelMaxWidth768
                    : isDeviceMaxWidth900
                    ? styles.labelMaxWidth900
                    : styles.label,
                  idx === selectedDay && styles.active,
                ]}
              >
                {isMobile ? format(d, 'E') : format(d, 'EEEE')}
              </Text>
              <Text
                style={
                  isMobile
                    ? styles.dateNumberMobile
                    : isDeviceMaxWidth768
                    ? styles.dateNumberMaxWidth768
                    : isDeviceMaxWidth900
                    ? styles.dateNumberMaxWidth900
                    : styles.dateNumber
                }
              >
                {format(d, 'd')}
              </Text>
              <View style={styles.marks}>
                {(dayMarks?.dailyEatHabit || dayMarks?.dailyDebrief) && (
                  <Layout
                    style={[
                      isMobile
                        ? styles.markIconMobile
                        : isDeviceMaxWidth768
                        ? styles.markIconMaxWidth768
                        : isDeviceMaxWidth900
                        ? styles.markIconMaxWidth900
                        : styles.markIcon,
                      { backgroundColor: getTaskColor(TASK_TYPE.EAT) },
                    ]}
                  />
                )}
                {dayMarks?.sweatWorkoutProgress &&
                  dayMarks?.sweatWorkoutProgress.length > 0 && (
                    <Layout
                      style={[
                        isMobile
                          ? styles.markIconMobile
                          : isDeviceMaxWidth768
                          ? styles.markIconMaxWidth768
                          : isDeviceMaxWidth900
                          ? styles.markIconMaxWidth900
                          : styles.markIcon,
                        { backgroundColor: getTaskColor(TASK_TYPE.SWEAT) },
                      ]}
                    />
                  )}
                {(dayMarks?.dailyThriveHabit ||
                  (dayMarks?.thriveProgramDayProgress &&
                    dayMarks?.thriveProgramDayProgress.length > 0) ||
                  (dayMarks?.thriveActivity &&
                    dayMarks?.thriveActivity.length > 0)) && (
                  <Layout
                    style={[
                      isMobile
                        ? styles.markIconMobile
                        : isDeviceMaxWidth768
                        ? styles.markIconMaxWidth768
                        : isDeviceMaxWidth900
                        ? styles.markIconMaxWidth900
                        : styles.markIcon,
                      { backgroundColor: getTaskColor(TASK_TYPE.THRIVE) },
                    ]}
                  />
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      {loading ? (
        <Layout style={styles.loader}>
          <Spinner />
        </Layout>
      ) : (
        <View style={styles.statsContainer}>
          <View
            style={
              isDeviceMaxWidth900
                ? styles.statsCategoryContainerTabletOrMobile
                : styles.statsCategoryContainer
            }
          >
            <TouchableOpacity
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryBtnMobile
                  : styles.categoryBtn,
                { borderColor: getTaskColor(TASK_TYPE.EAT) },
              ]}
              onPress={onPressEat}
            >
              <Layout
                style={
                  isDeviceMaxWidth900
                    ? styles.thumbnailWrapperTabletMobile
                    : styles.thumbnailWrapper
                }
              >
                <Image
                  style={styles.thumbnail as StyleProp<ImageStyle>}
                  source={require('../../assets/images/eat-habit-thumbnail.png')}
                />
              </Layout>
              <Layout style={styles.buttonInfo}>
                <Text
                  style={
                    isDeviceMaxWidth900
                      ? styles.buttonTitleTabletOrMobile
                      : styles.buttonTitle
                  }
                >
                  Start Tracking your EAT habits
                </Text>
                <Text
                  style={[
                    isDeviceMaxWidth900
                      ? styles.buttonDescriptionTabletMobile
                      : styles.buttonDescription,
                    { color: getTaskColor(TASK_TYPE.EAT) },
                  ]}
                >
                  Daily Log
                </Text>
              </Layout>
            </TouchableOpacity>
            <View
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryDataContainerTabletOrMobile
                  : styles.categoryDataContainer,
                { borderColor: getTaskColor(TASK_TYPE.EAT) },
              ]}
            >
              <View style={styles.statsDataContainer}>
                {!summary?.dailyEatHabit.count ? (
                  <View style={styles.statsInfo}>
                    <View
                      style={[
                        styles.infoIcon,
                        {
                          backgroundColor: getTaskColor(TASK_TYPE.EAT),
                        },
                      ]}
                    >
                      <Text style={styles.info}>!</Text>
                    </View>
                    <Text style={styles.fielMessage}>
                      You are not tracking your EAT habits
                    </Text>
                  </View>
                ) : (
                  <>
                    {indexOf(eatMonitorFields, 'waterOz') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.waterOzAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={summary?.dailyEatHabitScore?.waterOzAvg}
                            size={15}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          Ounces of water a Day
                        </Text>
                      </View>
                    )}
                    {indexOf(eatMonitorFields, 'treatsPerDay') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.treatsPerDayAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={summary?.dailyEatHabitScore?.treatsPerDayAvg}
                            size={15}
                          />
                        </View>
                        <Text style={styles.fieldUnit}>
                          indulgent food items a day
                        </Text>
                      </View>
                    )}
                    {indexOf(eatMonitorFields, 'fruitsPerDay') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.fruitsPerDayAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={summary?.dailyEatHabitScore?.fruitsPerDayAvg}
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>
                          servings of fruits a day
                        </Text>
                      </Layout>
                    )}
                    {indexOf(eatMonitorFields, 'vegetablesPerDay') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.vegetablesPerDayAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={
                              summary?.dailyEatHabitScore?.vegetablesPerDayAvg
                            }
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>
                          servings of vegetables a day
                        </Text>
                      </Layout>
                    )}
                    {indexOf(eatMonitorFields, 'caffeinePerDay') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.caffeinePerDayAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={
                              summary?.dailyEatHabitScore?.caffeinePerDayAvg
                            }
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>
                          caffeinated drinks a day
                        </Text>
                      </Layout>
                    )}
                    {indexOf(eatMonitorFields, 'alcoholPerDay') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyEatHabit?.alcoholPerDayAvg || 0}
                          </Text>
                          <ScoreBadge
                            type={summary?.dailyEatHabitScore?.alcoholPerDayAvg}
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>
                          alcoholic drinks a day
                        </Text>
                      </Layout>
                    )}
                    {indexOf(eatMonitorFields, 'caloriesPerDay') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {isNull(summary?.dailyEatHabit?.caloriesPerDayAvg)
                              ? 'N/A'
                              : summary?.dailyEatHabit?.caloriesPerDayAvg}
                          </Text>
                          <ScoreBadge
                            type={
                              summary?.dailyEatHabitScore?.caloriesPerDayAvg
                            }
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>calories a day</Text>
                      </Layout>
                    )}
                    {indexOf(eatMonitorFields, 'energyLevel') > -1 && (
                      <Layout
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <Layout style={styles.fieldTextContainer}>
                          <Text style={styles.fieldText}>
                            {summary?.dailyEatHabit?.energyLevelAvg
                              ? startCase(
                                  summary?.dailyEatHabit?.energyLevelAvg,
                                )
                              : 'None'}
                          </Text>
                          <ScoreBadge
                            type={summary?.dailyEatHabitScore?.energyLevelAvg}
                            size={15}
                          />
                        </Layout>
                        <Text style={styles.fieldUnit}>energy level a day</Text>
                      </Layout>
                    )}{' '}
                  </>
                )}
              </View>
            </View>
          </View>
          <View
            style={
              isDeviceMaxWidth900
                ? styles.statsCategoryContainerTabletOrMobile
                : styles.statsCategoryContainer
            }
          >
            <TouchableOpacity
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryBtnMobile
                  : styles.categoryBtn,
                { borderColor: getTaskColor(TASK_TYPE.SWEAT) },
              ]}
              onPress={onPressSweat}
            >
              <Layout
                style={
                  isDeviceMaxWidth900
                    ? styles.thumbnailWrapperTabletMobile
                    : styles.thumbnailWrapper
                }
              >
                <Image
                  style={styles.thumbnail as StyleProp<ImageStyle>}
                  source={require('../../assets/images/workout-generator-thumbnail.png')}
                />
              </Layout>
              <Layout style={styles.buttonInfo}>
                <Text
                  style={
                    isDeviceMaxWidth900
                      ? styles.buttonTitleTabletOrMobile
                      : styles.buttonTitle
                  }
                >
                  Do a SWEAT workout
                </Text>
                <Text
                  style={[
                    isDeviceMaxWidth900
                      ? styles.buttonDescriptionTabletMobile
                      : styles.buttonDescription,
                    { color: getTaskColor(TASK_TYPE.SWEAT) },
                  ]}
                >
                  Workout Generator
                </Text>
              </Layout>
            </TouchableOpacity>
            <View
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryDataContainerTabletOrMobile
                  : styles.categoryDataContainer,
                { borderColor: getTaskColor(TASK_TYPE.SWEAT) },
              ]}
            >
              <View style={styles.statsDataContainer}>
                {!summary?.sweatWorkoutProgress.count &&
                !summary?.sweatWorkoutProgress.isCompleteCount ? (
                  <View style={styles.statsInfo}>
                    <View
                      style={[
                        styles.infoIcon,
                        {
                          backgroundColor: getTaskColor(TASK_TYPE.SWEAT),
                        },
                      ]}
                    >
                      <Text style={styles.info}>!</Text>
                    </View>
                    <Text style={styles.fielMessage}>
                      {isCurrentDaySelected
                        ? 'You have not done a SWEAT workout today'
                        : 'You have not done a SWEAT workout on this day'}
                    </Text>
                  </View>
                ) : (
                  <>
                    <View
                      style={
                        isMobile
                          ? styles.statsDataMobile
                          : isDeviceMaxWidth768
                          ? styles.statsDataMaxWidth768
                          : styles.statsData
                      }
                    >
                      <View style={styles.fieldTextContainer}>
                        <Text style={styles.fieldNumber}>
                          {summary?.sweatWorkoutProgress?.timeAvg || 0}
                        </Text>
                      </View>
                      <Text style={styles.fieldUnit}>
                        minutes of workout a day
                      </Text>
                    </View>

                    <View
                      style={
                        isMobile
                          ? styles.statsDataMobile
                          : isDeviceMaxWidth768
                          ? styles.statsDataMaxWidth768
                          : styles.statsData
                      }
                    >
                      <View style={styles.fieldTextContainer}>
                        <Text style={styles.fieldText}>
                          {summary?.sweatWorkoutProgress
                            ?.workoutDifficultyAvg || 'None'}
                        </Text>
                      </View>
                      <Text style={styles.fieldUnit}>
                        average workout difficulty
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>
          <View
            style={
              isDeviceMaxWidth900
                ? styles.statsCategoryContainerTabletOrMobile
                : styles.statsCategoryContainer
            }
          >
            <TouchableOpacity
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryBtnMobile
                  : styles.categoryBtn,
                { borderColor: getTaskColor(TASK_TYPE.THRIVE) },
              ]}
              onPress={onPressThrive}
            >
              <Layout
                style={
                  isDeviceMaxWidth900
                    ? styles.thumbnailWrapperTabletMobile
                    : styles.thumbnailWrapper
                }
              >
                <Image
                  style={styles.thumbnail as StyleProp<ImageStyle>}
                  source={require('../../assets/images/meditate-thumbnail.png')}
                />
              </Layout>
              <Layout style={styles.buttonInfo}>
                <Text
                  style={
                    isDeviceMaxWidth900
                      ? styles.buttonTitleTabletOrMobile
                      : styles.buttonTitle
                  }
                >
                  Meditate
                </Text>
              </Layout>
            </TouchableOpacity>
            <View
              style={[
                isDeviceMaxWidth900
                  ? styles.categoryDataContainerTabletOrMobile
                  : styles.categoryDataContainer,
                { borderColor: getTaskColor(TASK_TYPE.THRIVE) },
              ]}
            >
              <View style={styles.statsDataContainer}>
                {!summary?.dailyThriveHabit.count &&
                !summary?.thriveActivity.count &&
                !summary?.thriveProgramDayProgress.count ? (
                  <View style={styles.statsInfo}>
                    <View
                      style={[
                        styles.infoIcon,
                        {
                          backgroundColor: getTaskColor(TASK_TYPE.THRIVE),
                        },
                      ]}
                    >
                      <Text style={styles.info}>!</Text>
                    </View>
                    <Text style={styles.fielMessage}>
                      {isCurrentDaySelected
                        ? 'You have not done a THRIVE exercise today'
                        : 'You have not done a THRIVE exercise on this day'}
                    </Text>
                  </View>
                ) : (
                  <>
                    <View
                      style={
                        isMobile
                          ? styles.statsDataMobile
                          : isDeviceMaxWidth768
                          ? styles.statsDataMaxWidth768
                          : styles.statsData
                      }
                    >
                      <View style={styles.fieldTextContainer}>
                        <Text style={styles.fieldNumber}>
                          {summary?.thriveProgramDayProgress?.isCompleteCount ||
                            0}
                        </Text>
                      </View>
                      <Text style={styles.fieldUnit}>
                        program exercises completed
                      </Text>
                    </View>
                    <View
                      style={
                        isMobile
                          ? styles.statsDataMobile
                          : isDeviceMaxWidth768
                          ? styles.statsDataMaxWidth768
                          : styles.statsData
                      }
                    >
                      <View style={styles.fieldTextContainer}>
                        <Text style={styles.fieldNumber}>
                          {summary?.thriveActivity?.count || 0}
                        </Text>
                      </View>
                      <Text style={styles.fieldUnit}>
                        thrive activities completed
                      </Text>
                    </View>
                    {indexOf(thriveMonitorFields, 'hoursSleep') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyThriveHabit?.hoursSleepAvg || 0}
                          </Text>
                        </View>
                        <Text style={styles.fieldUnit}>hours of sleep</Text>
                      </View>
                    )}
                    {indexOf(thriveMonitorFields, 'qualitySleep') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyThriveHabit?.qualitySleepAvg || 0}
                          </Text>
                        </View>
                        <Text style={styles.fieldUnit}>quality of sleep</Text>
                      </View>
                    )}
                    {indexOf(thriveMonitorFields, 'stressLevel') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyThriveHabit?.stressLevelAvg || 0}
                          </Text>
                        </View>
                        <Text style={styles.fieldUnit}>stress level</Text>
                      </View>
                    )}
                    {indexOf(thriveMonitorFields, 'goalsAchieved') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyThriveHabit?.goalsAchievedAvg || 0}
                          </Text>
                        </View>
                        <Text style={styles.fieldUnit}>goals achieved</Text>
                      </View>
                    )}
                    {indexOf(thriveMonitorFields, 'purposeLevel') > -1 && (
                      <View
                        style={
                          isMobile
                            ? styles.statsDataMobile
                            : isDeviceMaxWidth768
                            ? styles.statsDataMaxWidth768
                            : styles.statsData
                        }
                      >
                        <View style={styles.fieldTextContainer}>
                          <Text style={styles.fieldNumber}>
                            {summary?.dailyThriveHabit?.purposeLevelAvg || 0}
                          </Text>
                        </View>
                        <Text style={styles.fieldUnit}>purpose level</Text>
                      </View>
                    )}
                  </>
                )}
              </View>
            </View>
          </View>
        </View>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  content: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  day: {
    textAlign: 'center',
    width: '13%',
    backgroundColor: '#1A3248',
    paddingVertical: 5,
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginBottom: 9,
  },
  dateNumber: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    marginBottom: 9,
  },
  markIcon: {
    width: 24,
    height: 8,
    marginHorizontal: 1.5,
  },
  labelMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  dateNumberMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    marginBottom: 5,
  },
  markIconMaxWidth900: {
    width: 15,
    height: 5,
    marginHorizontal: 1.5,
  },
  labelMaxWidth768: {
    fontSize: 10,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginBottom: 5,
  },
  dateNumberMaxWidth768: {
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    marginBottom: 5,
  },
  markIconMaxWidth768: {
    width: 10,
    height: 3,
    marginHorizontal: 1.5,
  },
  labelMobile: {
    fontSize: 10,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  dateNumberMobile: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    marginBottom: 3,
  },
  markIconMobile: {
    width: 7,
    height: 2,
    marginHorizontal: 1,
  },
  active: {
    color: 'white',
  },
  marked: {
    borderBottomWidth: 3,
    borderStyle: 'solid',
  },
  markedText: {
    color: 'white',
  },
  today: {
    backgroundColor: '#31576D',
  },
  marks: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: 30,
  },
  dateSlider: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 15,
  },
  dateRange: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'white',
    marginHorizontal: 20,
    textTransform: 'uppercase',
  },
  dateRangeMobile: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'white',
    textTransform: 'uppercase',
  },
  statsContainer: {
    width: '100%',
    marginTop: 20,
  },
  statsCategoryContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  statsCategoryContainerTabletOrMobile: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30,
  },
  categoryBtn: {
    width: 316,
    height: 125,
    borderLeftWidth: 4,
  },
  categoryBtnMobile: {
    width: '100%',
    height: 140,
    borderLeftWidth: 4,
    marginBottom: 10,
  },
  categoryDataContainer: {
    borderWidth: 1,
    width: '65%',
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  categoryDataContainerTabletOrMobile: {
    borderWidth: 1,
    width: '100%',
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 10,
  },
  thumbnailWrapper: {
    width: '100%',
    height: 75,
  },
  thumbnailWrapperTabletMobile: {
    width: '100%',
    height: 90,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
  },
  buttonInfo: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    backgroundColor: '#1A3248',
    padding: 10,
  },
  buttonTitle: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '600',
    color: 'white',
  },
  buttonDescription: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  buttonTitleTabletOrMobile: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '600',
    color: 'white',
  },
  buttonDescriptionTabletMobile: {
    fontSize: 10,
    letterSpacing: 1,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  statsDataContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statsData: {
    width: '33%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  statsDataMaxWidth768: {
    width: '48%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  statsDataMobile: {
    width: '98%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  fieldTextContainer: {
    width: '42%',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  fieldNumber: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
  },
  fieldUnit: {
    width: '55%',
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    color: '#4E7B89',
    marginLeft: 15,
  },
  fielMessage: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    color: '#4E7B89',
    marginLeft: 20,
  },
  fieldText: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
  },
  statsInfo: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoIcon: {
    width: 16,
    height: 16,
    borderRadius: 50,
    alignItems: 'center',
  },
  info: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: '700',
    color: 'wallpaper',
  },
});

export default Week;
