import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import TopNav from 'src/components/Question/TopNav';
import ChevronDown from '../../assets/images/chevron-down-darker-blue.svg';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatWorkoutSummary'>;
};

const SweatWorkoutSummaryEdit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { summaryId, editingMode },
    },
  } = props;
  const store = useStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const summary = store.user.sweatWorkoutProgress.get(`${summaryId}`);
  const [edit, setEdit] = useState(editingMode || false);
  const [notes, setNotes] = useState<string>('');
  const theme = useTheme();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      store.user.fetchSweatWorkoutProgress(`${summaryId}`);
    }, []),
  );

  useEffect(() => {
    if (summary) {
      setNotes(summary.notes);
    }
  }, [summaryId]);

  const onEdit = useCallback(() => {
    store.user.updateSweatWorkoutProgress({
      id: summary.id,
      notes: notes,
    });
    setEdit(false);
  }, [notes, summary]);

  const duration = useMemo(() => {
    const data = moment.utc(summary?.time * 1000);
    return data.format('HH:mm:ss');
  }, [summaryId, summary?.time]);

  const onOpenDurationModal = useCallback(() => {
    navigation.navigate('DurationPickerModal', { summaryId: summaryId });
  }, [summaryId, edit]);

  return (
    <View style={styles.modalOverlay}>
      <Layout
        style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
      >
        <Layout style={styles.container}>
          <Layout
            style={[styles.navigationContainer, styles.transparentBackground]}
          >
            <TopNav
              showBack={true}
              showClose={false}
              style={styles.transparentBackground}
            />
          </Layout>
          <Layout style={styles.headerContainer}>
            <Text style={styles.header} category="h2">
              Workout Summary
            </Text>
          </Layout>

          <Layout style={styles.content}>
            <TouchableOpacity
              style={styles.detailContainer}
              onPress={() =>
                navigation.navigate('SweatWorkoutDetail', {
                  id: summary?.workout,
                })
              }
            >
              <Text style={styles.detailText}>Details</Text>
            </TouchableOpacity>
            <View style={styles.categoryContainer}>
              <Text style={styles.textCategory}> Workout Duration </Text>
              {edit ? (
                <TouchableOpacity
                  style={styles.durationContainer}
                  onPress={onOpenDurationModal}
                >
                  <Text style={styles.timeText}>{duration}</Text>
                  <ChevronDown stroke={theme['darker-blue']} />
                </TouchableOpacity>
              ) : (
                <View style={styles.durationContainer}>
                  <Text style={styles.timeText}>{duration}</Text>
                </View>
              )}
            </View>
            <View style={styles.notesContainer}>
              <Text style={styles.textCategory}>Notes </Text>
              {edit ? (
                <TextInput
                  value={notes}
                  onChangeText={(text) => setNotes(text)}
                  style={styles.textNotes}
                  multiline
                  autoFocus
                />
              ) : (
                <Text style={styles.textNotes}> {notes}</Text>
              )}
            </View>
          </Layout>
          <BottomNav
            showBack={false}
            nextLabel={edit ? 'Save' : 'Edit'}
            nextColor={edit ? theme['olive'] : theme['darker-blue']}
            onNext={
              edit
                ? onEdit
                : () => {
                    setEdit(!edit);
                  }
            }
          />
        </Layout>
      </Layout>
    </View>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  categoryContainer: {
    paddingVertical: 15,
  },
  notesContainer: {
    paddingVertical: 15,
    flex: 1,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: 30,
  },
  headerContainer: {
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  header: {
    fontSize: 24,
    lineHeight: 28,
    color: 'white',
  },
  textCategory: {
    fontWeight: '700',
    fontSize: 12,
    lineHeight: 16,
    color: 'light-gray',
    textTransform: 'uppercase',
    paddingVertical: 8,
  },
  textNotes: {
    color: 'white',
    fontSize: 15,
    lineHeight: 20,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: 'darker-blue',
    width: '90%',
    padding: 10,
  },
  durationContainer: {
    borderWidth: 1,
    borderColor: 'darker-blue',
    width: '90%',
    paddingHorizontal: 16,
    padding: 10,
    flexDirection: 'row',
  },
  timeText: {
    flex: 1,
    color: 'white',
    fontSize: 15,
    lineHeight: 20,
  },
  pickerItem: {
    fontSize: 15,
    lineHeight: 20,
    color: 'white',
  },

  closeText: {
    color: 'black',
    fontSize: 15,
    lineHeight: 20,
  },
  detailText: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'white',
  },
  detailContainer: {
    flexDirection: 'row',
    paddingVertical: 6,
    backgroundColor: 'blue-secondary',
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 18,
  },
});

export default observer(SweatWorkoutSummaryEdit);
