import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { PREPARE_ID, RECOVER_ID } from 'o2x-store/src/constants/sweat';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Dimensions,
  Platform,
  SectionList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from 'src/components/NavigationBar';
import SweatStartDetailItem from 'src/components/SweatStart/SweatStartDetailItem';
import SweatStartNextMinimized from 'src/components/SweatStart/SweatStartNextMinimized';
import { DEFAULT_REST_TABATA } from 'src/models/SweatStart';
import { getTimeDisplay } from 'src/utils/timeDisplay';
import ChevronLeft from '../../assets/images/chevron-left.svg';
import ChevronRight from '../../assets/images/chevron-right.svg';
import ClockBlue from '../../assets/images/clock-dark-blue.svg';
import ListIcon from '../../assets/images/list-dark-blue.svg';
import PauseIcon from '../../assets/images/pause.svg';
import PlayIcon from '../../assets/images/play.svg';
import ReplayButton from '../../assets/images/replay.svg';
import StopCircle from '../../assets/images/stop-circle.svg';
import TouchIcon from '../../assets/images/touch-and-hold.svg';
import VideoIcon from '../../assets/images/video.svg';
import { useNativeStore } from '../../stores';

type Props = {};

const DEFAULT_SWITCH_DELAY = 1000;

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);
const SweatStartDetail: React.FC<Props> = (props) => {
  const { programId } = props.route.params;
  const navigation = useNavigation();
  const { sweatStart, mediaStorage, notification } = useNativeStore();
  const { sweat, auth } = useStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const sectionListRef = useRef<SectionList>(null);

  const [width, setWidth] = useState(Dimensions.get('window').width);

  const [height, setHeight] = useState(Dimensions.get('window').height);

  const dailyPlanReminders = auth.user?.settings.dailyPlanReminders;
  let program: SweatProgram | undefined;
  if (programId) {
    program = sweat.sweatPrograms.get(`${programId}`);
  }
  const [nextStep, setNextStep] = useState<SweatWorkoutStep | undefined>();
  const [prevStep, setPrevStep] = useState<SweatWorkoutStep | undefined>();
  let workout: SweatWorkout | undefined;
  let step: SweatWorkoutStep | undefined;
  let stepExercise:
    | SweatWorkoutStepExercise
    | SweatGlobalStepExercise
    | undefined;
  let exercise: SweatExercise | undefined;
  let nextStepExercise:
    | SweatWorkoutStepExercise
    | SweatGlobalStepExercise
    | undefined;
  let nextExercise: SweatExercise | undefined;

  ({ workout, step, stepExercise, exercise } = sweatStart);

  const titleOpacity = useRef(new Animated.Value(0));
  const isCircuit = step?.circuitSets !== 1 || !!step?.circuitTimer;
  const isPrepare = step?.id === -1;
  const isRecover = step?.id === -2;
  const isSweat = step?.id !== -1 && step?.id !== -2;
  const exerciseOrder = (step?.exercises || []).findIndex(
    (e) => e.id === stepExercise!.id,
  );
  const maxRound = step?.exercises[step?.exercises.length - 1].round + 1;
  const exerciseVideoFile = useMemo(() => {
    return (
      exercise &&
      exercise.video &&
      mediaStorage.getOrCreateMediaFile(exercise, 'video')
    );
  }, [exercise]);

  let nextIsCircuit = false;
  if (nextStep) {
    nextStepExercise = sweatStart.getNextStepExercise(true);
    nextIsCircuit = nextStep.circuitSets !== 1;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [hintOpacity, setHintOpacity] = useState(new Animated.Value(1));
  const [displayHint, setDisplayHint] = useState<boolean>(true);
  const [isCompleteScreen, setIsCompleteScreen] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showInstruction, setShowInstruction] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });
  useEffect(() => {
    setNextStep(sweatStart.getNextStep);
    setPrevStep(sweatStart.getPrevStep);
  }, [step]);

  useEffect(() => {
    if (exerciseVideoFile) {
      Animated.timing(hintOpacity, {
        toValue: 0,
        duration: 1500,
        useNativeDriver: true,
      }).start();
      setTimeout(() => {
        setDisplayHint(false);
      }, 1500);
    }
  }, [exerciseVideoFile, setDisplayHint]);

  const onPrevStep = useCallback(() => {
    sweatStart.prevStep(sweat);
  }, [sweat, sweatStart, step, prevStep]);

  const onPrevExercise = useCallback(() => {
    console.log('ON PREV EXERCISE');
    if (!exercise || stepExercise?.id === PREPARE_ID) {
      console.log('... RETURN');
      return;
    }
    setShowInstruction(false);
    setLoading(true);
    if (step?.circuitTimer !== 'amrap' && sweatStart.isFirstExerciseInStep) {
      console.log('...FIRST IN STEP, PREV STEP');
      onPrevStep();
    } else {
      console.log('...PREV EXERCISE');
      sweatStart.prevExercise(sweat);
    }
    setLoading(false);
  }, [sweat, sweatStart, stepExercise, exercise, workout]);

  const onNextExercise = useCallback(() => {
    console.log('ON NEXT EXERCISE');
    setLoading(true);
    setShowInstruction(false);
    if (step?.circuitTimer !== 'amrap' && sweatStart.isLastExerciseInStep) {
      console.log('... LAST EXERCISE, NEXT STEP');
      onNextStep();
    } else {
      console.log('... NEXT EXERCISE');
      sweatStart.nextExercise(sweat);
    }
    setLoading(false);
  }, [sweat, sweatStart, step, stepExercise, exercise, workout, exerciseOrder]);

  const onNext = useCallback(() => {
    if (isCompleteScreen) {
      return onNextStep();
    }
    setAlertVisible(false);
    sweatStart.stopTimer();
    sweatStart.showReady = false;
    sweatStart.readyIndex = 0;
    setIsCompleteScreen(true);
  }, [isCompleteScreen]);

  const onNextStep = useCallback(() => {
    if (step?.id === RECOVER_ID) {
      return onEndWorkout();
    }

    if (sweatStart.active) {
      sweatStart.stopTimer();
    }
    setIsCompleteScreen(false);

    setAlertVisible(false);
    setLoading(true);
    sweatStart.nextStep(sweat);
    setLoading(false);

    const next = sweatStart.getNextStep;
    if (
      next?.circuitInstructions ||
      next?.circuitTimer ||
      (!!next?.circuitSets && next?.circuitSets !== 1)
    ) {
      return navigation.navigate('SweatStartDetailInstruction', {
        programId: programId,
      });
    }

    sweatStart.startTimer();
  }, [sweat, sweatStart, step, nextStep]);

  const onEndWorkout = useCallback(async () => {
    let result;
    if (program && !sweatStart.repeat) {
      if (dailyPlanReminders) {
        const currentWorkoutId = get(
          program,
          'userProgress.currentWorkout',
          null,
        );
        const nextWorkoutId = get(program, 'userProgress.nextWorkout', null);

        const currentWorkout =
          currentWorkoutId && sweat.sweatWorkouts.get(`${currentWorkoutId}`);
        const nextWorkout =
          nextWorkoutId && sweat.sweatWorkouts.get(`${nextWorkoutId}`);

        if (nextWorkout && currentWorkout) {
          const numberOfDays =
            (nextWorkout.week - currentWorkout.week) * 7 +
            (nextWorkout.day - currentWorkout.day);
          await notification.setSweatNotification(
            program.name,
            `${program.id}`,
            numberOfDays,
          );
        }
      }

      result = await program.markCompleteCurrentWorkout(
        {
          totalTime: sweatStart.totalTime,
          stepExerciseTimeBreakdown: sweatStart.breakdownTime,
          globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
          circuitBreakdownSets: sweatStart.circuitBreakdownSets,
        },
        workout.id,
      );
    } else if (workout) {
      result = await workout.markComplete({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
        circuitBreakdownSets: sweatStart.circuitBreakdownSets,
      });
    }
    if (!result || !result.ok) {
      console.log('[DEBUG] result', result);
      return;
    }

    sweatStart.endWorkout();
    sweatStart.reset();
    navigation.navigate('SweatWorkoutComplete', {
      programId: program ? program.id : undefined,
      workoutId: workout!.id,
    });
  }, [program, workout, sweatStart, sweatStart.repeat, dailyPlanReminders]);

  const onEndWorkoutAlert = useCallback(() => {
    if (Platform.OS === 'web') {
      const doEnd = confirm('Are you sure you want to end your workout?');
      if (doEnd) {
        onEndWorkout();
      }
    }
  }, [onEndWorkout]);
  const toggleActive = useCallback(() => {
    if (sweatStart.active) {
      sweatStart.stopTimer();
    } else {
      sweatStart.startTimer();
    }
  }, [sweatStart]);

  const renderEmpty = useCallback(() => {
    if (loading || workout?.loading) {
      return (
        <View style={styles.loading}>
          <Spinner />
        </View>
      );
    }
    return null;
  }, [workout, loading]);

  const toggleVideoExercise = useCallback(
    (item) => {
      setShowVideo(true);

      sweatStart.setExercise(sweat, item.id);
    },
    [setShowVideo, sweatStart, sweat],
  );

  const renderItem = useCallback(
    ({ item, section: { title } }) => {
      const exerciseIndex = (step?.exercises || []).findIndex(
        (e) => e.id === item!.id,
      );

      let isFirst = true;
      let isLast = true;
      if (exerciseIndex) {
        isFirst = step?.exercises[exerciseIndex - 1].round !== item.round;
      }

      if (exerciseIndex < step!.exercises.length - 1) {
        isLast = step?.exercises[exerciseIndex + 1].round !== item.round;
      }

      const shouldBlur =
        step?.circuitTimer === 'amrap'
          ? false
          : step?.circuitTimer === 'variable' ||
            (!step?.circuitTimer && !sweatStart.isGlobalStep)
          ? sweatStart.stepExerciseId !== item.id
          : !!title
          ? sweatStart.currentRound !== title
          : sweatStart.stepExercise!.id !== item.id;

      return (
        <View style={shouldBlur && styles.blurContainer}>
          {((step?.circuitTimer === 'emom' && step?.specifyRounds) ||
            ((step?.circuitTimer === 'variable' || !step?.circuitTimer) &&
              step?.circuitSets > 1)) &&
            isFirst && (
              <View style={styles.roundHeader}>
                <Text style={[styles.roundText, styles.cyanText]}>
                  ROUND {title}
                </Text>
              </View>
            )}

          <SweatStartDetailItem
            workout={workout}
            step={step}
            stepExercise={item}
            onPress={() => toggleVideoExercise(item)}
          />

          {((step?.specifyRounds && isLast && step.circuitTimerRest) ||
            step?.circuitTimer === 'tabata') && (
            <View style={styles.restHeader}>
              <Text style={[styles.tabataText]}>Rest </Text>
              <Text style={styles.restTimeText}>
                {step?.circuitTimer === 'tabata'
                  ? getTimeDisplay(DEFAULT_REST_TABATA)
                  : getTimeDisplay(step.circuitTimerRest)}
              </Text>
            </View>
          )}
        </View>
      );
    },
    [
      sweatStart,
      step,
      sweatStart.currentCircuitRound,
      sweatStart.currentRound,
      sweatStart.stepExercise,
    ],
  );

  const nextOnType = useCallback(
    (withRest: boolean = true) => {
      if (sweatStart.isGlobalStep) {
        // GLOBAL
        if (sweatStart.isExerciseSetDone) {
          if (
            sweatStart.stepExerciseRest &&
            !sweatStart.isRestTimer &&
            withRest
          ) {
            setTimeout(() => {
              sweatStart.restPeriod(sweat);
            }, DEFAULT_SWITCH_DELAY);
          } else {
            sweatStart.showReady = false;
            onNextExercise();
          }
        } else {
          setTimeout(() => {
            if (
              !sweatStart.isRestTimer &&
              withRest &&
              sweatStart.stepExerciseRest
            ) {
              sweatStart.restPeriod(sweat);
            } else {
              sweatStart.showReady = false;
              sweatStart.nextExerciseSet(sweat);
            }
          }, DEFAULT_SWITCH_DELAY);
        }
      } else {
        if (step?.circuitTimer === 'amrap') {
          // AMRAP has a circuit complete screen
          onNext();
        } else if (step?.circuitTimer === 'emom') {
          // EMOM
          if (sweatStart.currentRound === step!.circuitSets) {
            sweatStart.countDown = 0;
            onNextStep();
          } else {
            nextCircuitRound();
          }
        } else if (step?.circuitTimer === 'variable' || !step?.circuitTimer) {
          // VARIABLE
          if (
            sweatStart.currentRound >= step?.circuitSets &&
            sweatStart.isLastExerciseInStep &&
            sweatStart.isExerciseSetDone
          ) {
            sweatStart.countDown = 0;
            if (
              sweatStart.stepExerciseRest &&
              !sweatStart.isRestTimer &&
              withRest
            ) {
              sweatStart.restPeriod(sweat);
            } else {
              onNextStep();
            }
          } else if (sweatStart.isExerciseSetDone) {
            if (
              !sweatStart.isRestTimer &&
              withRest &&
              sweatStart.stepExerciseRest
            ) {
              sweatStart.restPeriod(sweat);
            } else {
              sweatStart.showReady = false;
              if (sweatStart.isLastExerciseInStep) {
                nextCircuitRound();
              } else {
                sweatStart.nextExercise(sweat);
              }
            }
          } else {
            setTimeout(() => {
              if (
                !sweatStart.isRestTimer &&
                withRest &&
                sweatStart.stepExerciseRest
              ) {
                console.log(`${sweatStart.stepExerciseRest}`);
                sweatStart.restPeriod(sweat);
              } else {
                sweatStart.showReady = false;
                sweatStart.nextExerciseSet(sweat);
              }
            }, DEFAULT_SWITCH_DELAY);
          }
        } else if (step?.circuitTimer === 'tabata') {
          // TABATA IS 20s WORK, 10s REST PER ROUND
          if (sweatStart.currentRound === step?.circuitSets) {
            if (sweatStart.isRestTimer) {
              sweatStart.countDown = 0;
              onNextStep();
            } else {
              if (withRest) {
                setTimeout(() => {
                  sweatStart.restPeriod(sweat);
                }, DEFAULT_SWITCH_DELAY);
              }
            }
          } else {
            setTimeout(() => {
              if (sweatStart.isRestTimer) {
                sweatStart.nextRound(sweat);
              } else {
                sweatStart.restPeriod(sweat);
              }
            }, DEFAULT_SWITCH_DELAY);
          }
        }
      }
    },
    [
      sweatStart.stepExercise,
      exerciseOrder,
      sweatStart.currentCircuitRound,
      isCompleteScreen,
      step?.circuitSets,
    ],
  );

  useEffect(() => {
    // Sets logic when countdown reaches 0, or timer switches from rest to work and vice versa
    if (sweatStart.countDown === 0) {
      nextOnType();
    }
  }, [sweatStart.countDown, sweatStart.isRestTimer]);

  useEffect(() => {
    if (sweatStart.stepExercise) {
      const sectionIndex =
        step?.circuitTimer === 'amrap'
          ? 0
          : !!step?.specifyRounds
          ? 0
          : sweatStart.currentCircuitRound - 1;
      const itemIndex = exerciseOrder;
      if (exerciseOrder >= 0) {
        setTimeout(() => {
          try {
            sectionListRef.current?.scrollToLocation({
              sectionIndex,
              itemIndex,
            });
          } catch (err) {
            console.log(err);
          }
        }, 100);
      }
    }
  }, [sweatStart.stepExercise, exerciseOrder, sweatStart.currentCircuitRound]);

  const keyExtractor = useCallback((item) => `${item.id}`, []);

  const prevCircuitRound = useCallback(() => {
    if (step?.circuitTimer === 'amrap') {
      return sweatStart.decrementCircuitRounds();
    }
    if (sweatStart.currentRound === 1) {
      return;
    } else {
      sweatStart.prevRound(sweat);
    }
  }, [sweatStart, sweat, sweatStart.currentRound, step]);

  const nextCircuitRound = useCallback(() => {
    if (step?.circuitTimer === 'amrap') {
      return sweatStart.incrementCircuitRounds();
    }
    if (step?.circuitSets !== 1) {
      if (sweatStart.currentRound === step?.circuitSets) {
        return onNext();
      }
      return sweatStart.nextRound(sweat);
    } else {
      if (sweatStart.currentRound === maxRound) {
        sweatStart.isRestTimer = false;
        return onNext();
      }
      return sweatStart.nextRound(sweat);
    }
  }, [sweatStart, sweat, sweatStart.currentRound, step]);

  const repeatCircuit = useCallback(() => {
    setIsCompleteScreen(false);
    sweatStart.repeatCircuit(sweat);
    sweatStart.startTimer();
  }, [sweatStart, sweat]);

  const onBack = useCallback(() => {
    if (sweatStart.active) {
      sweatStart.stopTimer();
    }
    setIsCompleteScreen(false);
    if (isPrepare) {
      return navigation.goBack();
    }
    if (
      !!step?.circuitInstructions ||
      step?.circuitSets !== 1 ||
      !!step?.circuitTimer
    ) {
      sweatStart.currentCircuitRound = 1;
      sweatStart.currentRound = 1;
      navigation.navigate('SweatStartDetailInstruction', {
        programId: programId,
      });
    } else {
      if (
        prevStep?.circuitInstructions ||
        prevStep?.circuitSets !== 1 ||
        !!prevStep.circuitTimer
      ) {
        sweatStart.prevStep(sweat);
        navigation.navigate('SweatStartDetailInstruction', {
          programId: programId,
        });
      } else {
        sweatStart.prevStep(sweat);
      }
    }
  }, [sweatStart.active, step]);

  const renderIcon = useCallback(
    () => (showVideo ? <VideoIcon /> : <ListIcon width={16} height={16} />),
    [showVideo],
  );

  const section = useMemo(() => {
    if (!step?.exercises) {
      return [{ title: '', data: [] }];
    }
    let data = [...step?.exercises];
    let numExercises = step?.exercises.length;

    let maxEmomRound = 0;
    if (step?.specifyRounds) {
      maxEmomRound = data.reduce(
        (total, curr) => (curr.round > total ? curr.round : total),
        0,
      );
    }

    if (
      (step?.circuitSets && step?.circuitSets > 1) ||
      step?.circuitTimer === 'tabata'
    ) {
      let modified_data: any[] = [];
      if (!['emom', 'tabata'].includes(step?.circuitTimer)) {
        modified_data = new Array(step.circuitSets)
          .fill('')
          .map((item, index) => {
            return {
              title: index + 1,
              data,
            };
          });
      } else if (step?.circuitTimer === 'emom') {
        modified_data = new Array(step.circuitSets)
          .fill('')
          .map((item, index) => {
            return {
              title: index + 1,
              data: step?.specifyRounds
                ? data.filter((e) => e.round === index % (maxEmomRound + 1))
                : [step?.exercises[index % numExercises]],
            };
          });
      } else if (step?.circuitTimer === 'tabata') {
        modified_data = new Array(step.circuitSets)
          .fill('')
          .map((item, index) => {
            return {
              title: index + 1,
              data: [step?.exercises[index % numExercises]],
            };
          });
      }
      return modified_data;
    }

    return [{ title: '', data }];
  }, [step]);

  const handleSkipButton = useCallback(() => {
    onNextStep();
  }, [step]);

  const handleNextButton = useCallback(() => {
    nextOnType();
    // if (sweatStart.isGlobalStep) {
    //   // GLOBAL
    //   onNextExercise();
    // } else if (step?.circuitTimer === 'amrap') {
    //   // AMRAP has a circuit complete screen
    //   onNext();
    // } else if (step?.circuitTimer === 'emom') {
    //   // EMOM
    //   if (sweatStart.currentRound === sweatStart.step!.circuitSets) {
    //     onNextStep();
    //   } else {
    //     nextCircuitRound();
    //   }
    // } else if (step?.circuitTimer === 'variable' || !step?.circuitTimer) {
    //   // VARIABLE
    //   if (
    //     sweatStart.currentRound >= step?.circuitSets &&
    //     sweatStart.isLastExerciseInStep
    //   ) {
    //     onNextStep();
    //   } else {
    //     if (
    //       sweatStart.currentRound < step?.circuitSets &&
    //       sweatStart.isLastExerciseInStep
    //     ) {
    //       nextCircuitRound();
    //     } else {
    //       sweatStart.nextExercise(sweat);
    //     }
    //   }
    // } else if (step?.circuitTimer === 'tabata') {
    //   // TABATA IS 20s WORK, 10s REST PER ROUND
    //   if (sweatStart.currentRound === step?.circuitSets) {
    //     onNextStep();
    //   } else {
    //     nextCircuitRound();
    //   }
    // }
  }, [
    // sweatStart.stepExercise,
    // exerciseOrder,
    // sweatStart.currentCircuitRound,
    // isCompleteScreen,
    nextOnType,
  ]);

  const handlePrevButton = useCallback(() => {
    if (isPrepare) {
      // PREPARE
      if (!sweatStart.isFirstExerciseInStep) {
        onPrevExercise();
      }
      onEndWorkoutAlert();
    } else if (step?.circuitTimer === 'amrap') {
      // AMRAP has a circuit complete screen
      onPrevStep();
    } else if (step?.circuitTimer === 'emom') {
      // EMOM
      if (
        sweatStart.currentRound <= sweatStart.step!.circuitSets &&
        sweatStart.currentRound > 1
      ) {
        prevCircuitRound();
      } else {
        onPrevStep();
      }
    } else if (step?.circuitTimer === 'variable' || !step?.circuitTimer) {
      // VARIABLE
      if (sweatStart.isFirstExerciseInStep) {
        if (sweatStart.currentRound === 1) {
          onPrevStep();
        } else {
          prevCircuitRound();
        }
      } else {
        onPrevExercise();
      }
    } else if (step?.circuitTimer === 'tabata') {
      // TABATA IS 20s WORK, 10s REST PER ROUND
      if (
        sweatStart.currentRound < step?.circuitSets &&
        sweatStart.currentRound > 1
      ) {
        prevCircuitRound();
      } else {
        onPrevStep();
      }
    } else {
      if (!sweatStart.isFirstExerciseInStep) {
        onPrevExercise();
      }
      onPrevStep();
    }
  }, [
    sweatStart.stepExercise,
    exerciseOrder,
    sweatStart.currentCircuitRound,
    isCompleteScreen,
    isPrepare,
  ]);

  if (!workout || !step || !stepExercise || !exercise) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.videoDetails}>
              <View style={[styles.videoContainer]}>
                {loading ? (
                  <View style={styles.loading}>
                    <Spinner />
                  </View>
                ) : (
                  <>
                    {!!exerciseVideoFile && (
                      <>
                        <ReactPlayer
                          url={exerciseVideoFile.uri}
                          playing={true}
                          loop={true}
                          style={{
                            flex: 1,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                          }}
                          width={
                            isMobile
                              ? width * 0.9
                              : Math.min((width - 240) * 0.7, 800) / 2
                          }
                          controls
                        />
                        {/* <VideoPlayer
                          style={styles.video}
                          videoLink={exerciseVideoFile.uri}
                          size={
                            isMobile
                              ? width * 0.9
                              : Math.min((width - 240) * 0.7, 800) / 2
                          }
                          pauseInitial={false}
                        /> */}
                        {displayHint && (
                          <Animated.View
                            style={[styles.hintIcon, { opacity: hintOpacity }]}
                          >
                            <TouchIcon width="211" height="110" />
                          </Animated.View>
                        )}
                      </>
                    )}
                    <SweatStartNextMinimized
                      step={nextStep}
                      stepExercise={nextStepExercise}
                    />
                  </>
                )}
              </View>

              <View style={styles.videoDescriptionContainer}>
                <TouchableOpacity onPress={onPrevExercise}>
                  <ChevronLeft />
                </TouchableOpacity>
                <View
                  style={{
                    justifyContent: 'center',
                    flex: 1,
                  }}
                >
                  {!!stepExercise.subtitle && (
                    <Text style={styles.exerciseReps} category="h3">
                      {stepExercise.subtitle}
                    </Text>
                  )}
                  <Text style={styles.exerciseName} category="h3">
                    {exercise.name}
                  </Text>

                  <View
                    style={{
                      alignSelf: 'center',
                      paddingTop: 5,
                      flexDirection: 'row',
                    }}
                  >
                    {step.exercises.map((item, index) => (
                      <View
                        key={index}
                        style={[
                          {
                            borderRadius: 2,
                            width: 4,
                            height: 4,
                            marginHorizontal: 5,
                            backgroundColor: theme['gray'],
                          },
                          index === exerciseOrder && {
                            backgroundColor: theme['white'],
                          },
                        ]}
                      />
                    ))}
                  </View>
                </View>
                <TouchableOpacity onPress={onNextExercise}>
                  <ChevronRight />
                </TouchableOpacity>
              </View>
              <View style={styles.detailContainer}>
                <Layout style={styles.exerciseContainer}>
                  <View style={styles.exerciseLabel}>
                    {isCircuit && (
                      <View style={styles.circuitTitleContainer}>
                        <View style={styles.circuitLabelContainer}>
                          <>
                            {step.circuitTimer === 'amrap' ? (
                              <Text style={styles.circuitValue} category="c2">
                                {sweatStart.currentCircuitRound}
                                {Humanize.pluralize(
                                  sweatStart.currentCircuitRound,
                                  '  Round',
                                )}
                              </Text>
                            ) : (
                              <View
                                style={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Text style={styles.circuitValue} category="c2">
                                  {step.circuitSets === 1
                                    ? `Round ${sweatStart.currentCircuitRound} / ${maxRound}`
                                    : step.circuitTimer === 'tabata'
                                    ? `Exercise ${sweatStart.currentCircuitRound} / ${step.circuitSets}`
                                    : `Round ${sweatStart.currentCircuitRound} / ${step.circuitSets}`}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    )}
                    <Text style={styles.setValue}>
                      {'Set '}
                      {sweatStart.currentStepExerciseSetDisplay}
                      {!!sweatStart.currentStepExerciseSetSideDisplay &&
                        ` - ${sweatStart.currentStepExerciseSetSideDisplay}`}
                    </Text>
                  </View>
                </Layout>

                <Layout style={styles.midContent}>
                  <TouchableOpacity
                    style={styles.midIcon}
                    onPress={onEndWorkoutAlert}
                  >
                    <StopCircle style={styles.midIconIcon} />
                    <Text style={styles.midIconLabel}>Stop</Text>
                  </TouchableOpacity>
                  <Layout
                    style={[styles.timerContainer, { paddingBottom: 20 }]}
                  >
                    <Layout style={styles.timer}>
                      <Text
                        style={[
                          styles.exerciseTime,
                          sweatStart.shouldBlink && styles.blinkText,
                          sweatStart.isRestTimer && styles.restText,
                        ]}
                      >
                        {sweatStart.exerciseTimeDisplay(stepExercise.id)}
                      </Text>
                      <View style={styles.centeredRow}>
                        {isSweat && (
                          <View style={{ paddingRight: 3 }}>
                            <ClockBlue />
                          </View>
                        )}
                        <Text style={styles.totalTime} category="c1">
                          {isSweat
                            ? sweatStart.totalTimeDisplay
                            : isPrepare
                            ? 'PREPARE'
                            : 'RECOVER'}
                        </Text>
                      </View>
                    </Layout>
                  </Layout>
                  <TouchableOpacity
                    style={styles.midIcon}
                    onPress={repeatCircuit}
                  >
                    <ReplayButton style={styles.midIconIcon} />
                    <Text style={styles.midIconLabel}>Restart</Text>
                  </TouchableOpacity>
                </Layout>

                <Layout style={styles.actionContainer}>
                  <TouchableOpacity
                    style={styles.action}
                    onPress={handlePrevButton}
                  >
                    <View style={styles.buttonContainer}>
                      <Text style={styles.buttonText}>Back</Text>
                    </View>
                  </TouchableOpacity>
                  {step.circuitTimer === 'amrap' && (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={sweatStart.decrementCircuitRounds}
                    >
                      <View style={styles.buttonContainer}>
                        <Text style={styles.buttonText}>-</Text>
                        <Text style={styles.buttonText}>Rnd</Text>
                      </View>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity
                    style={styles.action}
                    onPress={toggleActive}
                  >
                    <View
                      style={[
                        styles.buttonContainer,
                        styles.playButtonContainer,
                      ]}
                    >
                      {sweatStart.active ? (
                        <PauseIcon fill="white" />
                      ) : (
                        <PlayIcon fill="white" />
                      )}
                    </View>
                  </TouchableOpacity>
                  {step.circuitTimer === 'amrap' && (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={sweatStart.incrementCircuitRounds}
                    >
                      <View style={styles.buttonContainer}>
                        <Text style={styles.buttonText}>+</Text>
                        <Text style={styles.buttonText}>Rnd</Text>
                      </View>
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity
                    style={styles.action}
                    onPress={handleNextButton}
                  >
                    <View style={styles.buttonContainer}>
                      <Text style={styles.buttonText}>Next</Text>
                    </View>
                  </TouchableOpacity>
                  {(isPrepare || isRecover) && (
                    <TouchableOpacity
                      style={styles.action}
                      onPress={handleSkipButton}
                    >
                      <View style={styles.buttonContainer}>
                        <Text style={styles.buttonText}>Skip</Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Layout>
              </View>
            </View>

            {!isMobile && (
              <View style={styles.programDetails}>
                <Text style={styles.listHeaderText}>
                  {isPrepare
                    ? 'PREPARE'
                    : isRecover
                    ? 'RECOVER'
                    : !step.circuitTimer && step.circuitSets === 1
                    ? 'PERFORM IN ORDER'
                    : !!step.circuitTimer && step.circuitTimer !== 'variable'
                    ? step.circuitTimer
                    : 'CIRCUIT'}
                  {isCompleteScreen && ' Complete'}
                </Text>
                <AnimatedSectionList
                  ref={sectionListRef}
                  sections={section}
                  renderSectionHeader={({ section: { title } }) => {
                    /*
                    if (title) {
                      return (
                        <View
                          style={[
                            styles.roundListHeaderContainer,
                            title &&
                              title !== sweatStart.currentCircuitRound &&
                              styles.blurContainer,
                          ]}
                        >
                          <Text style={[styles.roundText, styles.cyanText]}>
                            {`ROUND ${title}`}
                          </Text>
                        </View>
                      );
                    }
                    return null;
                    */
                  }}
                  renderItem={renderItem}
                  extraData={{ program, workout }}
                  keyExtractor={keyExtractor}
                  ListEmptyComponent={renderEmpty}
                  style={styles.listItem}
                  initialNumToRender={30}
                  scrollEventThrottle={500}
                />
              </View>
            )}
            <NavigationBar
              style={[styles.navigation, { top: insets.top, paddingTop: 0 }]}
              showBack={isCompleteScreen}
              goBack={onEndWorkoutAlert}
              showClose={true}
              withBackground={false}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
    flexDirection: 'row',
  },
  programDetails: {
    flex: 1,
    paddingTop: 60,
  },
  videoDetails: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  videoContainer: {
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  listContainer: {
    flex: 1,
    paddingTop: 60,
  },
  hintIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: [{ translateX: -(211 / 2) }, { translateY: -110 / 2 }],
  },
  video: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  detailContainer: {
    height: 180,
    backgroundColor: 'black',
    justifyContent: 'space-between',
  },
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 24,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: 'black',
  },
  actionText: {},
  circuitContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 12,
  },
  circuitTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circuitLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  circuitIcon: {
    marginRight: 4,
    width: 14,
    height: 14,
  },
  circuitLabel: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginRight: 5,
  },
  circuitValue: {
    paddingTop: 2,
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
  },
  endCircuitButton: {
    backgroundColor: 'dark-blue',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  endCircuitButtonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  exerciseContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    flex: 1,
  },
  exerciseButton: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    width: 48,
    marginHorizontal: -6,
  },
  exerciseLabel: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  exerciseReps: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 22,
    color: 'cyan',
    textTransform: 'uppercase',
  },
  exerciseName: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 22,
  },
  rest: {
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'light-gray',
    paddingTop: 4,
  },
  exerciseTime: {
    textAlign: 'center',
    fontSize: 30,
    lineHeight: 40,
    fontWeight: '700',
  },
  timer: {
    flex: 1,
    backgroundColor: 'black',
  },
  circuitCount: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    backgroundColor: 'black',
  },
  timerAction: {
    justifyContent: 'space-between',
  },
  timerContainer: {
    flex: 1,
    flexGrow: 2,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  totalTime: {
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 15,
    color: 'blue-secondary',
  },
  notesContainer: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 10,
    backgroundColor: 'black',
  },
  circuitInstructions: {
    flex: 1,
    marginHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: 'darker-blue',
    flexDirection: 'row',
  },
  instructions: {},
  notes: {},
  extraInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    backgroundColor: 'black',
    paddingHorizontal: 20,
    paddingVertical: 10,
    paddingTop: 0,
  },
  next: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    color: '#828282',
    textTransform: 'uppercase',
    marginRight: 12,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  readMore: {
    marginTop: 8,
    marginBottom: 20,
    textDecorationLine: 'underline',
    color: 'gray',
  },
  prepareContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 5,
    marginHorizontal: 20,
  },
  sectionContainer: {
    marginRight: 4,
    backgroundColor: 'transparent',
  },
  jumpButton: {
    backgroundColor: 'dark-blue',
    marginLeft: 4,
    paddingVertical: 4,
  },
  jumpText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  sectionText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 12,
    color: 'light-gray',
  },
  hint: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'gray',
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
  iconContainer: {
    paddingRight: 8,
  },
  instructionContainer: {
    flex: 1,
    paddingHorizontal: 15,
  },
  instructionCloseIcon: {
    alignContent: 'center',
    paddingRight: 10,
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'blue-secondary',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 3,
    borderColor: 'blue-secondary',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButtonContainer: {
    borderWidth: 0,
    backgroundColor: 'blue-secondary',
  },
  listItem: {
    flex: 1,
    backgroundColor: 'darker-blue',
  },
  listHeaderContainer: {
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 24,
  },
  roundListHeaderContainer: {
    height: 40,
    backgroundColor: 'black',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 24,
  },
  listHeaderText: {
    fontSize: 18,
    lineHeight: 22,
    color: '#04B7D6',
    padding: 5,
    textTransform: 'uppercase',
  },
  blinkText: {
    color: 'cyan',
  },
  restText: {
    color: 'blue-secondary',
  },
  changeToLeft: {
    transform: [
      {
        rotate: '90deg',
      },
    ],
  },
  changeToRight: {
    transform: [
      {
        rotate: '270deg',
      },
    ],
  },
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    fontSize: 10,
    paddingRight: 0,
    color: '#203950',
  },
  inactive: {
    fontSize: 10,
    paddingLeft: 0,
    color: '#31576D',
  },
  completeScreenText: {
    fontSize: 16,
    lineHeight: 19,
    color: 'white',
  },
  roundText: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: '700',
  },
  tabataText: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  cyanText: {
    color: 'cyan',
  },
  restTimeText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'cyan',
  },
  blurContainer: {
    opacity: 0.5,
  },
  modalHeader: {
    marginTop: 30,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  modalHeaderContainer: {
    flexDirection: 'row',
  },
  modalHeaderText: {
    fontSize: 12,
    lineHeight: 14,
    paddingHorizontal: 5,
  },
  blueText: {
    color: 'blue-secondary',
  },
  modalContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 50,
    backgroundColor: 'transparent',
  },
  modalContentText: {
    fontSize: 16,
    lineHeight: 19,
    color: 'white',
    textAlign: 'center',
  },
  modalbuttonContainer: {
    height: 48,
    width: 180,
    backgroundColor: 'dark-blue',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  modalbuttonText: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
  },
  backButton: {},
  roundHeader: {
    height: 40,
    backgroundColor: 'wallpaper',
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  restHeader: {
    height: 50,
    backgroundColor: 'darker-blue',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  modalBackground: {
    position: 'absolute',
    backgroundColor: 'black',
    opacity: 0.9,
  },
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  centeredRow: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonPadding: {
    padding: 20,
  },
  swipeUp: {
    alignSelf: 'center',
    paddingBottom: 5,
  },
  listTitleContainer: {
    height: 40,
    backgroundColor: 'wallpaper',
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  videoDescriptionContainer: {
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    height: 72,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 24,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  videoIcon: {
    width: 26,
    height: 26,
  },
  midContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },
  midIcon: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  midIconLabel: {
    fontWeight: 'bold',
    color: 'light-gray',
    fontSize: 10,
    lineHeight: 12,
    marginTop: 8,
    textTransform: 'uppercase',
  },
  midIconIcon: {
    width: 20,
    height: 20,
  },
  setValue: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginTop: 2,
    color: 'cyan',
  },
});

export default observer(SweatStartDetail);
