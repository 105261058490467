import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isFinite, isNumber, toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import PhysicalAssessment from 'o2x-store/src/models/PhysicalAssessment';
import PhysicalAssessmentItem from 'o2x-store/src/models/PhysicalAssessmentItem';
import PhysicalAssessmentSubmission from 'o2x-store/src/models/PhysicalAssessmentSubmission';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import * as analytics from 'o2x-store/src/services/analytics';
import SweatStore from 'o2x-store/src/stores/SweatStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, ScrollView, TouchableOpacity, View } from 'react-native';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import Timer from 'src/components/Timer';
import Next from '../../assets/images/next.svg';
import Previous from '../../assets/images/previous.svg';
import UsersIcon from '../../assets/images/users.svg';
import AssessmentScoring from '../../components/AssessmentScoring';
import ProgressBar from '../../components/Question/ProgressBar';
import TimerStart from '../../models/TimerStart';
import { getTimeDisplay } from '../../utils/timeDisplay';

type Props = {
  physicalAssessmentItems: Map<string, PhysicalAssessmentItem>;
  physicalAssessment?: FormAssessment | PhysicalAssessment;
  physicalAssessmentSubmission: PhysicalAssessmentSubmission | undefined;
  onFinishedAssessment: () => void;
  sweatStore: SweatStore;
  timerStart?: TimerStart;
  navigation: Navigator;
  closeModal: () => void;
};

const PhysicalAssessmentSurvey: React.FC<Props> = ({
  physicalAssessmentItems,
  physicalAssessment,
  physicalAssessmentSubmission,
  onFinishedAssessment,
  sweatStore,
  timerStart,
  navigation,
  closeModal,
}) => {
  const styles = useStyleSheet(themedStyles);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState('');
  const [isAssessmentDone, setAssessmentDone] = useState(false);
  const [scoreMapping, setScoreMapping] = useState<{ [key: number]: string }>(
    {},
  );

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const initialVideoWidth = isMobile
    ? Dimensions.get('window').width * 0.9 // 90% of screen width
    : isDeviceMaxWidth600
    ? 220
    : isDeviceMaxWidth768
    ? 250
    : 300;

  const opts = {
    width: initialVideoWidth,
    height: (initialVideoWidth / 4) * 3,
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      // fs: 0,
    },
  };

  const { name, items, previousScoreBadge, currentSubmission } =
    physicalAssessment;

  let physicalAssessmentItem: PhysicalAssessmentItem | undefined;
  let exerciseId: number | undefined;
  let exercise: SweatExercise | undefined;
  let videoLink: string | null;

  if (items && items[index]) {
    physicalAssessmentItem = physicalAssessmentItems.get(`${items[index]}`);
  }

  if (physicalAssessmentItem && physicalAssessmentItem.exercises.length > 0) {
    exerciseId = physicalAssessmentItem.exercises[0];
    exercise = sweatStore.getSweatExercise(exerciseId);
  }

  if (physicalAssessmentItem && exercise) {
    videoLink = exercise.video;
  }

  const numBars = useMemo(() => items?.length || 0, []);

  useEffect(() => {
    if (physicalAssessment && !currentSubmission) {
      (async () => {
        setLoading(true);
        physicalAssessment.start();
        setLoading(false);
      })();
    }
  }, [physicalAssessment, currentSubmission]);

  useEffect(() => {
    if (!exercise && isNumber(exerciseId)) {
      (async () => {
        setLoading(true);
        sweatStore.fetchSweatExercise(exerciseId);
        setLoading(false);
      })();
    }
  }, []);

  const onNext = useCallback(async () => {
    if (!physicalAssessmentItem || !physicalAssessment?.currentSubmission) {
      return;
    }

    const score = getScoreValue(index);
    if (!score || score.length === 0) {
      setError('Required');
      return;
    }
    const result = await physicalAssessmentItem.answer(
      physicalAssessment.currentSubmission,
      score,
    );

    if (result.ok) {
      setError('');
      if (index + 1 === numBars) {
        (async () => {
          setLoading(true);
          await physicalAssessment?.fetch();
          setLoading(false);
        })();
        if (physicalAssessment) {
          analytics.logAssessmentComplete(physicalAssessment);
        }
        setAssessmentDone(true);
      } else {
        setIndex((index) => index + 1);
      }
    } else {
      setError(result.errors.detail || result.errors.value || '');
    }
  }, [
    index,
    scoreMapping,
    physicalAssessment,
    physicalAssessmentItem,
    physicalAssessmentSubmission,
    setError,
  ]);

  const getScoreValue = useCallback(
    (index: number) => {
      const score = scoreMapping[index];
      if (score && physicalAssessmentItem) {
        if (physicalAssessmentItem.unit === 'seconds') {
          const time = toNumber(score);
          if (isFinite(time)) {
            return `${time}`; // Assumed seconds.
          } else {
            return `${moment.duration(score).as('seconds') / 60}`;
          }
        }
      }
      return score;
    },
    [scoreMapping, physicalAssessmentItem],
  );

  const onPrev = useCallback(() => {
    setIndex((index) => index - 1);
  }, [index]);

  const onScoringUpdate = useCallback(
    (score: string) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: score,
      }));
    },
    [index],
  );

  const onStopTimer = useCallback(
    (timeSpent: number) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: `${getTimeDisplay(timeSpent)}`,
      }));
    },
    [index],
  );

  const onAssessmentDone = useCallback(() => {
    setAssessmentDone(false);
    setScoreMapping({});
    setIndex(0);
    setError('');
    onFinishedAssessment();
  }, []);

  const onChallenge = useCallback(() => {
    if (physicalAssessment) {
      navigation.navigate('TeamChallenge', {
        name: physicalAssessment.name,
        physicalAssessmentId: physicalAssessment.id,
      });
      closeModal();
    }
  }, [navigation, physicalAssessment, closeModal]);

  if (!physicalAssessmentItem) {
    return null;
  }

  return (
    <>
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <>
          {isAssessmentDone ? (
            <Layout
              style={
                isMobile
                  ? styles.asessmentDoneModalMobile
                  : styles.asessmentDoneModal
              }
            >
              <Text style={styles.doneText}>You’ve made it!</Text>
              <Text style={styles.doneBadge}>{previousScoreBadge}</Text>
              <View style={styles.actions}>
                <TouchableOpacity
                  style={styles.actionItem}
                  onPress={onChallenge}
                >
                  <UsersIcon
                    style={styles.actionIcon}
                    stroke="#C4C4C4"
                    color="#C4C4C4"
                  />
                  <Text style={styles.actionText}>Challenge</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={
                  isMobile ? styles.finishButtonMobile : styles.finishButton
                }
                onPress={onAssessmentDone}
              >
                <Text style={styles.finish}>Finish</Text>
              </TouchableOpacity>
            </Layout>
          ) : (
            <Layout
              style={isMobile ? styles.surveyModalMobile : styles.surveyModal}
            >
              <Layout
                style={
                  isMobile
                    ? styles.modalSurveyContainerMobile
                    : styles.modalSurveyContainer
                }
              >
                <ScrollView style={styles.scroll}>
                  <Layout style={styles.modalTitleContainer}>
                    <Text style={styles.modalPhysicalAssessment}>{name}</Text>
                  </Layout>
                  {isMobile && (
                    <>
                      {videoLink ? (
                        <Layout style={styles.vidMobile}>
                          {/* <VideoPlayer
                            videoLink={videoLink}
                            size={initialVideoWidth}
                          /> */}
                          <ReactPlayer
                            url={videoLink}
                            playing={true}
                            loop={true}
                            style={{
                              flex: 1,
                              // position: 'absolute',
                              top: 0,
                              left: 0,
                            }}
                            width={initialVideoWidth}
                          />
                        </Layout>
                      ) : (
                        physicalAssessmentItem.videoId && (
                          <Layout style={styles.vidMobile}>
                            <YouTube
                              videoId={physicalAssessmentItem.videoId}
                              opts={opts}
                            />
                          </Layout>
                        )
                      )}
                    </>
                  )}
                  <Layout
                    style={
                      isMobile
                        ? styles.modalContentMobile
                        : isDeviceMaxWidth768
                        ? styles.modalContentMaxWidth768
                        : styles.modalContent
                    }
                  >
                    <Layout
                      style={
                        isMobile ? styles.modalBodyMobile : styles.modalBody
                      }
                    >
                      <TouchableOpacity
                        style={
                          isMobile
                            ? styles.modalButtonMobile
                            : styles.modalButton
                        }
                        onPress={onPrev}
                      >
                        {index > 0 && <Previous />}
                      </TouchableOpacity>
                      <Layout
                        style={
                          isMobile
                            ? styles.surveyContainerMobile
                            : styles.surveyContainer
                        }
                      >
                        {!isMobile && (
                          <>
                            {videoLink ? (
                              <Layout
                                style={
                                  isDeviceMaxWidth768
                                    ? styles.vidMaxWidth768
                                    : styles.vid
                                }
                              >
                                {/* <VideoPlayer
                                  videoLink={videoLink}
                                  size={initialVideoWidth}
                                /> */}
                                <ReactPlayer
                                  url={videoLink}
                                  playing={true}
                                  loop={true}
                                  style={{
                                    flex: 1,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                  }}
                                  width={initialVideoWidth}
                                />
                              </Layout>
                            ) : (
                              physicalAssessmentItem.videoId && (
                                <Layout
                                  style={
                                    isDeviceMaxWidth768
                                      ? styles.vidMaxWidth768
                                      : styles.vid
                                  }
                                >
                                  <YouTube
                                    videoId={physicalAssessmentItem.videoId}
                                    opts={opts}
                                  />
                                </Layout>
                              )
                            )}
                          </>
                        )}

                        <Layout
                          style={
                            isMobile
                              ? styles.surveyMobile
                              : isDeviceMaxWidth600
                              ? styles.surveyMaxWidth600
                              : styles.survey
                          }
                        >
                          <Text style={styles.assessmentTitle}>
                            {physicalAssessmentItem.title}
                          </Text>
                          <AssessmentScoring
                            style={styles.scoring}
                            error={error}
                            value={scoreMapping[index] || ''}
                            onChangeText={onScoringUpdate}
                            unit={physicalAssessmentItem.unit}
                            label={physicalAssessmentItem.unitDisplay}
                          />
                          {!!physicalAssessmentItem.shortDescription && (
                            <Text style={styles.shortDescription}>
                              {physicalAssessmentItem.shortDescription}
                            </Text>
                          )}
                          {!!physicalAssessmentItem.rest && (
                            <Text style={styles.rest}>
                              {`REST ${physicalAssessmentItem.rest}`}
                            </Text>
                          )}
                          {!!physicalAssessmentItem.timer && !!timerStart && (
                            <Timer
                              type={physicalAssessmentItem.timer}
                              timeInSeconds={
                                physicalAssessmentItem.timerLengthInSeconds
                                  ? physicalAssessmentItem.timerLengthInSeconds
                                  : undefined
                              }
                              onStopTimer={onStopTimer}
                              timerStart={timerStart}
                            />
                          )}
                        </Layout>
                      </Layout>
                      <TouchableOpacity
                        style={
                          isMobile
                            ? styles.modalButtonMobile
                            : styles.modalButton
                        }
                        onPress={onNext}
                      >
                        {index < numBars && <Next />}
                      </TouchableOpacity>
                    </Layout>
                  </Layout>
                </ScrollView>
              </Layout>
              <Layout
                style={isMobile ? styles.progressBarMobile : styles.progressBar}
              >
                <ProgressBar numBars={numBars} currentBar={index} />
              </Layout>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  modalSurveyContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalSurveyContainerMobile: {
    width: '100%',
    height: '87%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  scroll: {
    width: '100%',
  },
  asessmentDoneModal: {
    width: '100%',
    height: 466,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  asessmentDoneModalMobile: {
    width: '100%',
    height: '94%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  surveyModal: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  surveyModalMobile: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  modalTitleContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalPhysicalAssessment: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  modalContent: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 44,
    marginBottom: 90,
    paddingHorizontal: 20,
  },
  modalContentMaxWidth768: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 30,
    marginBottom: 70,
    paddingHorizontal: 10,
  },
  modalContentMobile: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  modalBody: {
    width: '100%',
    height: 300,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  modalBodyMobile: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  vid: {
    width: 300,
    height: 300,
    marginRight: 35,
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  vidMaxWidth768: {
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginRight: 10,
  },
  vidMobile: {
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  survey: {
    width: 320,
    backgroundColor: 'transparent',
  },
  surveyMaxWidth600: {
    width: 220,
    backgroundColor: 'transparent',
  },
  surveyMobile: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  assessmentTitle: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '400',
  },
  shortDescription: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginBottom: 10,
    color: '#C4C4C4',
  },
  scoring: {
    marginTop: 20,
    marginBottom: 15,
    backgroundColor: 'transparent',
  },
  rest: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    color: '#C4C4C4',
  },
  modalButton: {
    width: 10,
    marginHorizontal: 10,
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalButtonMobile: {
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  surveyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  surveyContainerMobile: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  progressBar: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  progressBarMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    marginTop: 10,
  },
  doneText: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    marginBottom: 50,
  },
  doneBadge: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#4E7B89',
  },
  actions: {
    marginTop: 30,
    marginVertical: 20,
  },
  actionIcon: {
    alignSelf: 'center',
    marginBottom: 7,
  },
  actionText: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
  finishButton: {
    width: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finishButtonMobile: {
    width: '100%',
    maxWidth: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finish: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 466,
  },
});

export default observer(PhysicalAssessmentSurvey);
