import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Input,
  Layout,
  Modal,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import {
  ASSESSMENT_TYPE,
  FORM_ASSESSMENT_SCORING_TYPE,
} from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import PhysicalAssessmentSubmission from 'o2x-store/src/models/PhysicalAssessmentSubmission';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useIsSubscribed } from 'src/hooks/subscription';
import Close from '../../assets/images/close-modal.svg';
import RightOutline from '../../assets/images/right-outline.svg';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import HumanPerformanceAssessment from '../HumanPerformanceAssessment';
import PhysicalAssessment from '../PhysicalAssessment';
import ReadinessAssessment from '../ReadinessAssessment';
import FormAssessmentSurvey from '../Survey/FormAssessmentSurvey';
import PhysicalAssessmentSurvey from '../Survey/PhysicalAssessmentSurvey';
import PreassessmentSurvey from '../Survey/PreassessmentSurvey';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentHistory'>;
};

const AssessmentHistory: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const {
    auth,
    assessment: assessmentStore,
    sweat: sweatStore,
    user: userStore,
  } = useStore();
  const { navigateWithPayWall, isSubscribed } = useIsSubscribed();
  const navigation = useNavigation();

  const physicalAssessmentItems = assessmentStore.physicalAssessmentItems;
  const formAssessmentItems = assessmentStore.formAssessmentItems;

  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const [preassessmentDone, setPreassessmentDone] = useState<boolean>(
    assessment?.showPreassessmentForm ? false : true,
  );
  const { timerStart } = useNativeStore();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [inputError, setInputError] = useState('');
  const [status, setStatus] = useState('basic');

  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    if (assessment) {
      analytics.logAssessmentOpen(assessment);
      // if (!assessment.currentSubmission && isModalVisible) {
      if (isModalVisible) {
        assessment.start();
      }
    }
  }, [assessment, isModalVisible]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!assessment) {
          setLoading(true);
          if (type === ASSESSMENT_TYPE.PHYSICAL) {
            await assessmentStore.fetchPhysicalAssessmentByIds([
              `${assessmentId}`,
            ]);
          } else if (type === ASSESSMENT_TYPE.FORM) {
            await assessmentStore.fetchFormAssessmentByIds([`${assessmentId}`]);
          }
        }
      })();
    }, [assessmentId]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        await assessment?.fetch();
        if (type === ASSESSMENT_TYPE.PHYSICAL) {
          await assessmentStore.fetchPhysicalAssessmentItems(assessmentId);
        } else if (type === ASSESSMENT_TYPE.FORM) {
          await assessmentStore.fetchFormAssessmentItems(assessmentId);
        }
        setLoading(false);
      })();
    }, [setLoading, assessment]),
  );

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onConfirmNext = useCallback(async () => {
    if (!isSubscribed) {
      navigateWithPayWall('PayWall');
      return;
    }
    if (assessment instanceof FormAssessment && assessment.enableAccessCode) {
      // checks if user has event access to this form assessment
      if (assessment.userAccess) {
        console.log('[ASSESSMENT] ACCESS WITH EVENT');
        openModal();
      } else {
        setShowConfirmPopup(true);
      }
    } else {
      openModal();
    }
  }, [isSubscribed, assessment]);

  const onValidateCode = useCallback(() => {
    if (
      assessment instanceof FormAssessment &&
      inputCode == assessment.accessCode
    ) {
      setInputError('');
      setStatus('');
      openModal();
    } else {
      setInputError('Invalid access code');
      setStatus('danger');
    }
  }, [assessment, inputCode]);

  const openModal = useCallback(async () => {
    if (!isSubscribed) {
      navigateWithPayWall('PayWall');
      return;
    }
    setModalVisible(true);
  }, [isSubscribed]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const onFinishedAssessment = useCallback(() => {
    setModalVisible(false);
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const markPreassessmentDone = useCallback(() => {
    setPreassessmentDone(true);
  }, []);

  if (!assessment) {
    return (
      <View style={styles.loader}>
        <Spinner />
      </View>
    );
  }

  const {
    previousScoreBadge,
    previousResult,
    currentSubmission,
    currentDate,
    items,
    name,
  } = assessment;

  let physicalAssessmentSubmission: PhysicalAssessmentSubmission | undefined;
  let formAssessmentSubmission: FormAssessmentSubmission | undefined;

  if (currentSubmission) {
    if (type === ASSESSMENT_TYPE.PHYSICAL) {
      physicalAssessmentSubmission = assessmentStore.physicalAssessmentSubmissions.get(
        `${currentSubmission}`,
      );
    } else if (type === ASSESSMENT_TYPE.FORM) {
      formAssessmentSubmission = assessmentStore.formAssessmentSubmissions.get(
        `${currentSubmission}`,
      );
    }
  }

  return (
    <>
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout
              style={isMobile ? styles.containerMobile : styles.container}
            >
              <Layout style={styles.header}>
                <Layout style={styles.row}>
                  <TouchableOpacity onPress={goBack}>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.assessmentTexMaxWidth900
                          : styles.assessmentText
                      }
                    >
                      Assessments
                    </Text>
                  </TouchableOpacity>
                  {isDeviceMaxWidth900 ? (
                    <RightOutline
                      style={styles.rightOutline}
                      width="6"
                      height="10"
                      viewBox="0 0 8 14"
                    />
                  ) : (
                    <RightOutline />
                  )}
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameHeaderMaxWidth900
                        : styles.assessmentNameHeader
                    }
                  >
                    {name}
                  </Text>
                </Layout>

                {/* <Layout style={styles.row}>
                  <TouchableOpacity>
                    <Download style={{ paddingRight: 20 }} />
                  </TouchableOpacity>
                  <TouchableOpacity>
                    <Save />
                  </TouchableOpacity>
                </Layout> */}
              </Layout>
              <Layout
                style={
                  isMobile ? styles.userContainerMobile : styles.userContainer
                }
              >
                <Layout style={styles.user}>
                  <Image
                    style={styles.profile as StyleProp<ImageStyle>}
                    source={
                      currentUser?.profileImage
                        ? { uri: currentUser?.profileImage }
                        : require('../../assets/images/user_placeholder.png')
                    }
                  />
                  <Layout
                    style={isMobile ? styles.userDataMobile : styles.userData}
                  >
                    <Text
                      style={
                        isMobile
                          ? styles.fullNameMobile
                          : isDeviceMaxWidth900
                          ? styles.fullNameMaxWidth900
                          : styles.fullName
                      }
                    >
                      {currentUser?.fullName}
                    </Text>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.professionMaxWidth900
                          : styles.profession
                      }
                    >
                      {currentUser?.profession}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.assessmentContainer}>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameMaxWidth900
                        : styles.assessmentName
                    }
                  >
                    {name}
                  </Text>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentDateMaxWidth900
                        : styles.assessmentDate
                    }
                  >
                    {currentDate === null
                      ? ''
                      : `date taken: ${moment(currentDate).format(
                          'MMM DD, YYYY',
                        )}`}
                  </Text>
                </Layout>
                <TouchableOpacity
                  style={
                    isMobile
                      ? styles.takeTestButtonMobile
                      : isDeviceMaxWidth900
                      ? styles.takeTestButtonMaxWidth900
                      : styles.takeTestButton
                  }
                  onPress={onConfirmNext}
                >
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.buttonLabelMaxWidth900
                        : styles.buttonLabel
                    }
                  >
                    Take Assessment
                  </Text>
                </TouchableOpacity>
              </Layout>
              {type === ASSESSMENT_TYPE.FORM ? (
                assessment.scoringType ===
                  FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_READINESS ||
                assessment.scoringType ===
                  FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_BODY_COMP ||
                assessment.scoringType ===
                  FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_HP_SURVEY ||
                assessment.scoringType ===
                  FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_MOBILITY_CAPACITY ? (
                  <ReadinessAssessment assessment={assessment} />
                ) : (
                  <HumanPerformanceAssessment assessment={assessment} />
                )
              ) : (
                <PhysicalAssessment
                  assessment={assessment}
                  items={items}
                  physicalAssessmentItems={physicalAssessmentItems}
                  previousScoreBadge={previousScoreBadge}
                  previousResult={previousResult}
                />
              )}
            </Layout>
          </ScrollView>
          {showConfirmPopup && (
            <View style={styles.popupContent}>
              <View style={styles.center}>
                <View style={styles.popupTextBox}>
                  <Text style={styles.popupText}>
                    Please enter your Readiness Assessment Access Code. The O2X
                    Tactical Athlete Readiness Assessment should be administered
                    by O2X personnel. If you’re not at an event or with an
                    on-site specialist, select cancel and contact us for more
                    information.
                  </Text>
                  <Input
                    style={styles.input}
                    label={() => <Text style={styles.error}>{inputError}</Text>}
                    placeholder="Access Code"
                    status={status}
                    onChangeText={(nextValue) => {
                      setInputCode(nextValue);
                    }}
                  />
                </View>
                <View style={styles.popupActions}>
                  <TouchableOpacity
                    style={[styles.popupButton, styles.popupButtonBlue]}
                    onPress={() => setShowConfirmPopup(false)}
                  >
                    <Text style={styles.popupButtonText}>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.popupButton}
                    onPress={onValidateCode}
                  >
                    <Text style={styles.popupButtonText}>Next</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          )}
        </Layout>
      )}

      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        style={styles.modal}
        onBackdropPress={() => setModalVisible(false)}
      >
        <Layout
          style={
            isMobile
              ? styles.cardMobile
              : isDeviceMaxWidth600
              ? styles.cardMaxWidth600
              : isDeviceMaxWidth768
              ? styles.cardMaxWidth768
              : styles.card
          }
        >
          <TouchableOpacity
            style={[styles.closeModal, isMobile && { padding: 10 }]}
            onPress={() => setModalVisible(false)}
          >
            <Close />
          </TouchableOpacity>
          {assessment.showPreassessmentForm && !preassessmentDone ? (
            <PreassessmentSurvey
              navigation={navigation}
              closeModal={closeModal}
              setDone={markPreassessmentDone}
              currentUser={currentUser}
              userStore={userStore}
            />
          ) : type === ASSESSMENT_TYPE.FORM ? (
            <FormAssessmentSurvey
              formAssessmentItems={formAssessmentItems}
              formAssessment={assessment}
              formAssessmentSubmission={formAssessmentSubmission}
              onFinishedAssessment={onFinishedAssessment}
              navigation={navigation}
              closeModal={closeModal}
            />
          ) : (
            <PhysicalAssessmentSurvey
              physicalAssessmentItems={physicalAssessmentItems}
              physicalAssessment={assessment}
              physicalAssessmentSubmission={physicalAssessmentSubmission}
              onFinishedAssessment={onFinishedAssessment}
              sweatStore={sweatStore}
              timerStart={timerStart}
              navigation={navigation}
              closeModal={closeModal}
            />
          )}
        </Layout>
      </Modal>
    </>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  takeTestButton: {
    marginTop: 20,
    justifyContent: 'center',
    height: 48,
    width: 314,
    backgroundColor: '#31576D',
  },
  takeTestButtonMaxWidth900: {
    marginTop: 20,
    justifyContent: 'center',
    height: 40,
    width: 260,
    backgroundColor: '#31576D',
  },
  takeTestButtonMobile: {
    marginTop: 20,
    justifyContent: 'center',
    height: 40,
    width: '100%',
    backgroundColor: '#31576D',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 14,
    fontWeight: '400',
  },
  buttonLabelMaxWidth900: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 12,
    fontWeight: '400',
  },
  physicalAssessmentContainer: {
    width: '100%',
    marginTop: 25,
    backgroundColor: 'transparent',
  },
  physicalAssessmentScoresContainer: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  modal: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  card: {
    backgroundColor: '#1A3248',
    width: 800,
  },
  cardMaxWidth768: {
    backgroundColor: '#1A3248',
    width: '90%',
  },
  cardMaxWidth600: {
    backgroundColor: '#1A3248',
    width: '95%',
  },
  cardMobile: {
    backgroundColor: '#1A3248',
    width: '90%',
    height: '90%',
  },
  closeModal: {
    marginBottom: 3,
    padding: 20,
    alignSelf: 'flex-end',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  input: {
    marginTop: 10,
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
  error: {
    color: 'danger',
    fontSize: 15,
  },
  popupActions: {
    width: 400,
    flexDirection: 'row',
  },
  popupContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#000000BE',
  },
  popupTextBox: {
    width: 400,
    backgroundColor: 'dark-blue',
    padding: 30,
    marginHorizontal: 30,
    minHeight: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupText: {
    fontSize: 18,
    textAlign: 'center',
  },
  popupButton: {
    marginTop: 5,
    padding: 20,
    backgroundColor: '#767F6A',
    alignItems: 'center',
    width: '50%',
  },
  popupButtonBlue: {
    backgroundColor: '#203950',
  },
  popupButtonText: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default observer(AssessmentHistory);
