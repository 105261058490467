import { filter, transform, uniq } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import { getTaskColor, TASK_TYPE } from '../utils/tasks';
import { Author, RatingsBreakdown } from './Program';
import ThriveProgramDay from './ThriveProgramDay';
import UserThriveProgramProgress from './UserThriveProgramProgress';

@model('o2x-store/ThriveProgram')
export default class ThriveProgram extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  slug: prop<string>(''),
  description: prop<string>(''),
  author: prop<Author>(),
  coverImage: prop<string>(''),
  coverImageBanner: prop<string>(''),
  coverImageCard: prop<string>(''),
  coverImageThumbnail: prop<string>(''),
  lengthInWeeks: prop<number>(0),
  daysPerWeek: prop<string>(''),
  difficulty: prop<string>(''),
  rating: prop<number>(0),
  userRating: prop<number>(0),
  userProgress: prop<UserThriveProgramProgress | null>(null),
  durationDisplay: prop<string>(''),
  saveList: prop<Array<number>>(() => new Array<number>()),
  viewUrl: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(source: ModelInstanceCreationData<ThriveProgram>) {
    const data = { ...source };
    const { userProgress } = data;
    delete data.userProgress;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { thrive } = rootStore;

    if (userProgress) {
      this.userProgress = transform(
        userProgress,
        (result: any, value: any, key: string) => {
          if ((key === 'currentDay' || key === 'nextDay') && value) {
            result[key] = value.id;
            thrive.createOrUpdateThriveProgramDay(value);
          } else {
            result[key] = value;
          }
        },
        {},
      ) as UserThriveProgramProgress;
    }
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @computed
  get title(): string {
    return this.name;
  }

  @computed
  get subtitle(): string {
    return this.durationDisplay;
  }

  @computed
  get image(): string {
    return this.coverImage;
  }

  @computed
  get imageBanner(): string {
    return this.coverImageBanner || this.coverImage;
  }

  @computed
  get imageCard(): string {
    return this.coverImageCard || this.coverImage;
  }

  @computed
  get imageThumbnail(): string {
    return this.coverImageThumbnail || this.coverImage;
  }

  @computed
  get type(): TASK_TYPE {
    return TASK_TYPE.THRIVE;
  }

  @computed
  get color(): string {
    return getTaskColor(TASK_TYPE.THRIVE);
  }

  @computed
  get ratingsBreakdown(): RatingsBreakdown | undefined {
    const rootStore = getRoot<RootStore>(this);
    if (rootStore.getProgramRatings) {
      return rootStore.getProgramRatings(this.type, this.id);
    }
  }

  @modelFlow
  fetch = _async(function* (this: ThriveProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchThriveProgram(rootStore.auth.token, this.id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching ratings', error);
      return getError(error);
    }

    console.log('[DEBUG] entities', entities);
    this.update(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchRatings = _async(function* (this: ThriveProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchThriveProgramRatings(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching ratings', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  postRate = _async(function* (this: ThriveProgram, rate: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: RatingsBreakdown;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.postThriveProgramRate(rootStore.auth.token, this.id, rate),
      ));
    } catch (error) {
      console.warn('[DEBUG] error post rate', error);
      return getError(error);
    }

    rootStore.setProgramRatings(this.type, this.id, entities);
    this.rating = entities.averageRating;
    this.userRating = rate;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  startProgram = _async(function* (this: ThriveProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.startThriveProgram(rootStore.auth.token, this.id)));
    } catch (error) {
      console.warn('[DEBUG] error starting sweat program', error);
      return getError(error);
    }

    this.update(entities);

    analytics.logProgramStart(this);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markComplete = _async(function* (this: ThriveProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token || !this.userProgress) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgram>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.markCompleteThriveProgram(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error marking complete thrive program', error);
      return getError(error);
    }

    this.update(entities);

    analytics.logProgramComplete(this);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchProgramDays = _async(function* (this: ThriveProgram) {
    const rootStore = getRoot<RootStore>(this);
    const { thrive } = rootStore;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelInstanceCreationData<ThriveProgramDay>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchThriveProgramDays(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive program days', error);
      return getError(error);
    }

    console.log('RESULTS', results);

    results.forEach((workout) => {
      thrive.createOrUpdateThriveProgramDay(workout);
    });

    this.loading = false;
    return getSuccess({ results });
  });
}
