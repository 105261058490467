import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  FlatList,
  SectionList,
  TouchableOpacity,
  View,
} from 'react-native';
import { useNativeStore } from '../../stores';
import Loading from '../Loading';
import SweatStartActions from './SweatStartActions';
import SweatStartCurrentMinimized from './SweatStartCurrentMinimized';
import SweatStartHeaderSidebar from './SweatStartHeaderSidebar';
import SweatStartItem from './SweatStartItem';
import SweatStartSectionFooter from './SweatStartSectionFooter';
import SweatStartSectionHeader from './SweatStartSectionHeader';

type Props = {
  loading: boolean;
  program?: SweatProgram;
  workout?: SweatWorkout;
  titleOpacity: Animated.Value;
  renderListHeader?: () => JSX.Element;
  onScrollBegin?: () => void;
  onScrollToTop?: () => void;
  hideMinimize?: boolean;
  isFte?: boolean;
  newScreen?: boolean;
};

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);
enum WORKOUT_SECTION {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

const SweatStartWorkout: React.FC<Props> = (props) => {
  const {
    loading,
    program,
    workout,
    titleOpacity,
    renderListHeader,
    onScrollBegin = () => {},
    onScrollToTop = () => {},
    hideMinimize = false,
  } = props;
  const { sweat } = useStore();
  const { sweatStart } = useNativeStore();
  const navigation = useNavigation();
  const sectionListRef = useRef<SectionList>(null);
  const flatListRef = useRef<FlatList>(null);
  const [selected, setSelected] = useState<WORKOUT_SECTION>(
    WORKOUT_SECTION.SWEAT,
  );
  const styles = useStyleSheet(themedStyles);
  const sections = workout ? workout.sweatStartSections : [];
  const sweatSections = sections.length
    ? sections.slice(1, sections.length - 1)
    : [];
  const prepareSection = sections.length ? sections[0] : null;
  const recoverSection = sections.length ? sections[sections.length - 1] : null;
  const [globalSection, setGlobalSection] = useState<any | null>(
    prepareSection ? prepareSection : null,
  );

  const minHeight = Dimensions.get('window').height * 0.4;
  const keyExtractor = useCallback((item) => `${item.id}`, []);

  useEffect(() => {
    setSelected(WORKOUT_SECTION.SWEAT);
  }, [workout]);

  const renderGlobalItem = useCallback(
    ({ item }) => {
      return (
        <SweatStartItem
          workout={workout}
          step={globalSection}
          stepExercise={item}
        />
      );
    },
    [workout, sweatStart.expandStep, selected, globalSection],
  );

  const renderItem = useCallback(
    ({ item, section }) => {
      return (
        <SweatStartItem workout={workout} step={section} stepExercise={item} />
      );
    },
    [workout, sweatStart.expandStep, selected],
  );

  const renderSectionHeader = useCallback(({ section }) => {
    return <SweatStartSectionHeader step={section} />;
  }, []);

  const renderSectionFooter = useCallback(
    ({ section }) => <SweatStartSectionFooter step={section} />,
    [],
  );

  const renderEmpty = useCallback(() => {
    if (loading || workout?.loading || !globalSection) {
      return (
        <View style={styles.loader}>
          <Spinner />
        </View>
      );
    }
    return null;
  }, [workout, loading]);

  useFocusEffect(
    useCallback(() => {
      if (sections) {
        if (selected === WORKOUT_SECTION.PREPARE) {
          setGlobalSection(prepareSection);
        } else if (selected === WORKOUT_SECTION.RECOVER) {
          setGlobalSection(recoverSection);
        }
      }
    }, [sections, selected]),
  );

  useEffect(() => {
    if (sweatStart.stepId) {
      if (sweatStart.stepId === -1) {
        setGlobalSection(prepareSection);
        setSelected(WORKOUT_SECTION.PREPARE);
      } else if (sweatStart.stepId === -2) {
        setGlobalSection(recoverSection);
        setSelected(WORKOUT_SECTION.RECOVER);
      } else {
        setSelected(WORKOUT_SECTION.SWEAT);
      }
    }
  }, [sweatStart.stepId]);

  useEffect(() => {
    if (workout && (!workout.steps || isNumber(workout.steps[0]))) {
      workout.fetch();
    }
  }, [workout]);

  useEffect(() => {
    if (sweatStart.stepExercise) {
      if (selected === WORKOUT_SECTION.SWEAT) {
        const sectionIndex = sweatSections.findIndex(
          (s) => s.id === sweatStart.step?.id,
        );
        const itemIndex =
          sectionIndex >= 0
            ? sweatSections[sectionIndex].data.findIndex(
                (d) => d.id === sweatStart.stepExercise?.id,
              )
            : -1;

        if (sectionIndex >= 0 && itemIndex >= 0) {
          setTimeout(() => {
            try {
              sectionListRef.current?.scrollToLocation({
                sectionIndex,
                itemIndex,
              });
            } catch (err) {
              console.log(err);
            }
          }, 100);
        }
      } else {
        if (globalSection) {
          const itemIndex = globalSection.data.findIndex(
            (d) => d.id === sweatStart.stepExercise?.id,
          );
          if (itemIndex >= 0) {
            setTimeout(() => {
              try {
                flatListRef.current?.scrollToIndex({ index: itemIndex });
              } catch (err) {
                console.log(err);
              }
            }, 100);
          }
        }
      }
    }
  }, [sweatStart.stepExercise]);

  const onStart = useCallback(() => {
    navigation.navigate('SweatStartDetail', {
      programId: program?.id,
    });
  }, [navigation, program]);

  const onScroll = useCallback(
    async (event) => {
      if (event.nativeEvent.contentOffset.y <= 0 && onScrollToTop) {
        await setTimeout(() => onScrollToTop(), 20);
        flatListRef.current?.scrollToOffset(event.nativeEvent.contentOffset.y);
      } else if (
        event.nativeEvent.contentOffset.y > 20 &&
        event.nativeEvent.contentSize.height > minHeight &&
        onScrollBegin
      ) {
        await setTimeout(() => onScrollBegin(), 20);
        flatListRef.current?.scrollToOffset(event.nativeEvent.contentOffset.y);
      }
    },
    [onScrollToTop, onScrollBegin, flatListRef],
  );

  if (!workout) {
    if (sweat.loading || loading) {
      return <Loading />;
    }
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {renderListHeader ? (
          renderListHeader()
        ) : (
          <SweatStartHeaderSidebar program={program} workout={workout!} />
        )}
      </View>
      <View style={styles.subSelection}>
        <TouchableOpacity
          onPress={() => {
            setGlobalSection(prepareSection);
            setSelected(WORKOUT_SECTION.PREPARE);
          }}
          style={[
            styles.subSelectionContainer,
            selected === WORKOUT_SECTION.PREPARE &&
              styles.subSelectionContainerSelected,
          ]}
        >
          <Text
            style={[
              styles.selectionText,
              selected === WORKOUT_SECTION.PREPARE &&
                styles.selectionTextSelected,
            ]}
          >
            Prepare
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelected(WORKOUT_SECTION.SWEAT);
          }}
          style={[
            styles.subSelectionContainer,
            selected === WORKOUT_SECTION.SWEAT &&
              styles.subSelectionContainerSelected,
          ]}
        >
          <Text
            style={[
              styles.selectionText,
              selected === WORKOUT_SECTION.SWEAT &&
                styles.selectionTextSelected,
            ]}
          >
            Sweat
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setGlobalSection(recoverSection);
            setSelected(WORKOUT_SECTION.RECOVER);
          }}
          style={[
            styles.subSelectionContainer,
            selected === WORKOUT_SECTION.RECOVER &&
              styles.subSelectionContainerSelected,
          ]}
        >
          <Text
            style={[
              styles.selectionText,
              selected === WORKOUT_SECTION.RECOVER &&
                styles.selectionTextSelected,
            ]}
          >
            Recover
          </Text>
        </TouchableOpacity>
      </View>
      {selected !== WORKOUT_SECTION.SWEAT && (
        <View style={styles.listContainer}>
          <FlatList
            ref={flatListRef}
            data={globalSection ? Array.from(globalSection.data.values()) : []}
            renderItem={renderGlobalItem}
            extraData={{ program, workout }}
            keyExtractor={keyExtractor}
            ListEmptyComponent={renderEmpty}
            style={styles.listItem}
            initialNumToRender={30}
            onScrollToIndexFailed={(error) => {
              flatListRef.current?.scrollToOffset({
                offset: error.averageItemLength * error.index,
                animated: true,
              });
              setTimeout(() => {
                if (
                  (globalSection ? Array.from(globalSection.data.values()) : [])
                    .length !== 0 &&
                  flatListRef !== null
                ) {
                  try {
                    flatListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  } catch (err) {
                    console.log(err);
                  }
                }
              }, 100);
            }}
            onScroll={onScroll}
            scrollEventThrottle={500}
          />
        </View>
      )}
      {selected === WORKOUT_SECTION.SWEAT && (
        <AnimatedSectionList
          ref={sectionListRef}
          style={styles.section}
          contentContainerStyle={styles.content}
          sections={sweatSections}
          extraData={{ program, workout }}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ListEmptyComponent={renderEmpty}
          renderSectionHeader={renderSectionHeader}
          renderSectionFooter={renderSectionFooter}
          initialNumToRender={30}
          onScroll={onScroll}
          scrollEventThrottle={500}
        />
      )}
      {!!hideMinimize ? null : sweatStart.workoutId ? (
        <SweatStartCurrentMinimized program={program} />
      ) : (
        <SweatStartActions
          program={program}
          workout={workout}
          onStart={onStart}
        />
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    backgroundColor: 'black',
  },
  section: {
    flex: 1,
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  subSelection: {
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    paddingTop: 4,
    marginTop: 15,
  },
  subSelectionContainer: {
    flex: 1,
  },
  subSelectionContainerSelected: {
    borderBottomWidth: 4,
    borderColor: 'cyan',
    borderStyle: 'solid',
  },
  selectionText: {
    color: 'light-gray',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 9,
    alignSelf: 'center',
  },
  selectionTextSelected: {
    color: 'cyan',
  },
  listItem: {
    flex: 1,
    backgroundColor: 'black',
  },
  listContainer: {
    flex: 1,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
});

export default observer(SweatStartWorkout);
