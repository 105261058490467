import {
  Icon,
  Input,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, TouchableOpacity, View } from 'react-native';

type Props = {
  addStepDetailed: Function;
  sets: number;
  timerWork: string;
  setSets: Function;
  setTimerWork: Function;
  selectedType: string;
  isModalVisible: boolean;
  hideModal: () => void;
};

const AddStep: React.FC<Props> = (props) => {
  const {
    addStepDetailed,
    sets,
    timerWork,
    setSets,
    setTimerWork,
    selectedType,
    isModalVisible,
    hideModal,
  } = props;
  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    if (isModalVisible && (selectedType === '' || selectedType === 'tabata')) {
      hideModal();
      addStepDetailed();
    }
  }, [isModalVisible, selectedType]);

  const onChangeText = useCallback(
    (text: string) => {
      if (text.length > 0 && isNaN(parseInt(text.charAt(text.length - 1))))
        return;
      let result = '';
      let duration = text.replace(/:/g, '');
      if (duration.length % 2 === 1 && duration.length > 1) {
        result = `${duration.slice(0, 1)}:`;
        duration = duration.slice(1);
      }
      result += duration.replace(/..\B/g, '$&:');
      if (result.length < 6) {
        setTimerWork(result);
      }
    },
    [timerWork, setTimerWork],
  );
  const animation = useRef(new Animated.Value(0)).current;

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const onPressOk = useCallback(() => {
    if (!timerWork || !timerWork.length) {
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => {
        animation.setValue(0);
      });
    } else addStepDetailed();
  }, [addStepDetailed, selectedType, timerWork]);

  return (
    <Modal
      visible={isModalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={hideModal}
    >
      <Layout style={styles.modal}>
        {selectedType === 'variable' ? (
          <Layout style={styles.inputContainer}>
            <Text style={styles.text}>Circuit Sets:</Text>
            <Input
              style={styles.input}
              value={sets.toString()}
              onChangeText={(text) =>
                isNaN(parseInt(text)) ? setSets(0) : setSets(parseInt(text))
              }
            />
            <TouchableOpacity
              style={styles.button}
              onPress={() => addStepDetailed()}
            >
              <Text style={styles.okText}>OK</Text>
            </TouchableOpacity>
          </Layout>
        ) : selectedType === 'amrap' ? (
          <Layout style={styles.inputContainer}>
            <Text style={styles.text}>Timer (MM:SS):</Text>
            <Input
              placeholder="MM:SS"
              style={styles.input}
              value={timerWork}
              // onChangeText={(text) =>
              //   isNaN(parseInt(text))
              //     ? setTimerWork(0)
              //     : setTimerWork(parseInt(text))
              // }
              onChangeText={onChangeText}
            />
            <TouchableOpacity style={styles.button} onPress={onPressOk}>
              <Text style={styles.okText}>OK</Text>
            </TouchableOpacity>
          </Layout>
        ) : (
          <Layout style={styles.inputContainer}>
            <Text style={styles.text}>Timer (MM:SS):</Text>
            <Input
              placeholder="MM:SS"
              style={styles.input}
              value={timerWork}
              // onChangeText={(text) =>
              //   isNaN(parseInt(text))
              //     ? setTimerWork(0)
              //     : setTimerWork(parseInt(text))
              // }
              onChangeText={onChangeText}
            />
            <Text style={styles.text}>Rounds:</Text>
            <Input
              style={styles.input}
              value={sets.toString()}
              onChangeText={(text) =>
                isNaN(parseInt(text)) ? setSets(0) : setSets(parseInt(text))
              }
            />
            <TouchableOpacity style={styles.button} onPress={onPressOk}>
              <Text style={styles.okText}>OK</Text>
            </TouchableOpacity>
          </Layout>
        )}
        <View style={styles.center} pointerEvents="none">
          <Animated.View
            style={[
              styles.popUpContainer,
              { opacity, transform: [{ translateY }, { scale }] },
            ]}
            pointerEvents="none"
          >
            <Icon
              style={styles.savedIcon}
              name={'close-circle-outline'}
              fill={'warning'}
            />
            <Text style={styles.savedText}>
              {'Timer is required for AMRAP and EMOM Workouts'}
            </Text>
          </Animated.View>
        </View>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  modal: {
    width: 300,
    height: 100,
    backgroundColor: 'transparent',
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
  },
  text: {
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 15,
  },
  input: {
    borderColor: 'transparent',
    borderRadius: 0,
    borderBottomColor: 'white',
    backgroundColor: 'transparent',
    width: '30%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  button: {
    marginLeft: 10,
    width: 30,
    height: 20,
    borderColor: '#767F6A',
    borderWidth: 1,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  okText: {
    fontSize: 14,
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },

  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
});

export default observer(AddStep);
