import Slider from '@react-native-community/slider';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useMemo } from 'react';
import { View, ViewStyle } from 'react-native';
import CircularSlider from '../../components/DailyDebriefDial/CircularSlider';
import CircularRuler from './CircularRuler';

type Props = {
  value: number;
  setValue: (value: number) => void;
  style?: ViewStyle;
  debrief: { [key: string]: any };
  offsetX?: number;
  offsetY?: number;
  disabled?: boolean;
  isRating?: boolean;
  size?: 'small' | 'large';
};

const DailyDebriefDial: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const {
    value = 0,
    setValue,
    style,
    debrief,
    offsetY = 0,
    size,
    isRating = false,
    disabled,
  } = props;

  const floorValue = Math.floor(value / 10);

  const status = useMemo(() => {
    if (floorValue === 10) {
      return {
        color: debrief['color'],
        details: 'EXCELLENT',
        Icon: debrief['excellent2'],
      };
    }
    if (floorValue > 7) {
      return {
        color: debrief['color'],
        details: 'EXCELLENT',
        Icon: debrief['excellent2'],
      };
    }
    if (floorValue > 3) {
      return {
        color: '#cfac42',
        details: 'AVERAGE',
        Icon: debrief['average'],
      };
    }
    if (floorValue > 0) {
      return {
        color: '#c64141',
        details: 'POOR',
        Icon: debrief['poor'],
      };
    }
    if (isRating) {
      return {
        color: 'white',
        details: '',
        Icon: debrief['excellent2'],
      };
    }
    return {
      color: '#c64141',
      details: 'POOR',
      Icon: debrief['poor'],
    };
  }, [floorValue]);

  const dialStyle = useMemo(() => {
    if (size === 'small') {
      return {
        size: 300,
        btnRadius: 15,
        radius: 98,
        dialRadius: 75,
        large: styles.large,
        small: styles.small,
        value: styles.valueSmall,
      };
    }
    return {
      size: 300,
      btnRadius: 20,
      radius: 130,
      dialRadius: 100,
      large: undefined,
      small: undefined,
      value: styles.valueLarge,
    };
  }, [size]);

  const { color, details, Icon } = status;

  return (
    <>
      <View style={style}>
        {Icon && (
          <View style={styles.icon}>
            <Icon />
          </View>
        )}
        {isRating && <Text style={styles.text}>In the last 24 hours...</Text>}
        <View
          style={[
            styles.container,
            { height: dialStyle.size, width: dialStyle.size },
          ]}
        >
          <CircularRuler
            value={value}
            containerStyle={styles.absolute}
            activeColor={status.color}
            inactiveColor="#31576d"
            radius={dialStyle.radius}
            smallMarkStyle={dialStyle.small}
            largeMarkStyle={dialStyle.large}
          />
          <View style={styles.center}>
            <Text
              style={[
                isRating && floorValue === 0
                  ? styles.valueRateText
                  : dialStyle.value,
                isRating && floorValue === 0
                  ? { color: debrief['color'] }
                  : status.color
                  ? { color }
                  : {},
              ]}
              category="h1"
            >
              {isRating && floorValue === 0
                ? 'Rate your performance'
                : floorValue}
            </Text>
            <Text category="c1">{details}</Text>
          </View>
          <CircularSlider
            initialValue={value * 3.59}
            btnRadius={dialStyle.btnRadius}
            dialRadius={dialStyle.dialRadius}
            fillColor="transparent"
            meterColor={status.color}
            strokeColor="transparent"
            onChangeValue={setValue}
            yCenter={
              Icon
                ? offsetY + dialStyle.size / 2 + 100
                : offsetY + dialStyle.size / 2
            }
            disabled
          />
        </View>
      </View>
      {!disabled && (
        <Slider
          style={{ width: '100%', height: 60 }}
          value={value}
          minimumValue={0}
          maximumValue={100}
          step={10}
          minimumTrackTintColor={color}
          maximumTrackTintColor="#697484"
          thumbTintColor="#FFFFFF"
          onSlidingComplete={setValue}
        />
      )}
    </>
  );
};
const themedStyles = StyleService.create({
  absolute: {
    position: 'absolute',
  },
  center: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginRight: 10,
  },
  large: {
    height: 15,
    width: 4,
    borderRadius: 1,
  },
  small: {
    marginTop: 7,
    width: 2,
    height: 8,
    borderRadius: 1,
  },
  valueRateText: {
    fontSize: 30,
    lineHeight: 32,
    color: '#C64141',
    textAlign: 'center',
  },
  valueSmall: {
    fontSize: 50,
    lineHeight: 52,
    color: '#C64141',
  },
  valueLarge: {
    fontSize: 60,
    color: '#C64141',
  },
});

export default DailyDebriefDial;
