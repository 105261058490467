import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isFinite, isNumber, toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import PhysicalAssessmentItem from 'o2x-store/src/models/PhysicalAssessmentItem';
import PhysicalAssessmentSubmission from 'o2x-store/src/models/PhysicalAssessmentSubmission';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import ReadMore from 'react-native-read-more-text';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import YoutubePlayer from 'react-native-youtube-iframe';
import Timer from 'src/components/Timer';
import config from 'src/config';
import AssessmentScoring from '../../components/AssessmentScoring';
import Loading from '../../components/Loading';
import NavigationBar from '../../components/NavigationBar';
import BottomNav from '../../components/Question/BottomNav';
import ProgressBar from '../../components/Question/ProgressBar';
import VideoPlayer from '../../components/VideoPlayer';
import MediaFile from '../../models/MediaFile';
import { useNativeStore } from '../../stores';
import { getTimeDisplay } from '../../utils/timeDisplay';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentDetail'>;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const PhysicalAssessmentDetail: React.FC<Props> = (props) => {
  const { assessmentId } = props.route.params;
  const { assessment, sweat } = useStore();
  const { mediaStorage } = useNativeStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const [loading, setLoading] = useState(false);
  const [width] = useState(Dimensions.get('window').width);
  const [index, setIndex] = useState(0);
  const [scoreMapping, setScoreMapping] = useState<{ [key: number]: string }>(
    {},
  );
  const [error, setError] = useState('');
  const titleOpacity = useRef(new Animated.Value(0));

  const physicalAssessment = assessment.physicalAssessments.get(
    `${assessmentId}`,
  );

  let physicalAssessmentItem: PhysicalAssessmentItem | undefined;
  let physicalAssessmentSubmission: PhysicalAssessmentSubmission | undefined;
  let exerciseId: number | undefined;
  let exercise: SweatExercise | undefined;
  let exerciseVideo: MediaFile | undefined;

  if (physicalAssessment?.items && physicalAssessment.items[index]) {
    physicalAssessmentItem = assessment.physicalAssessmentItems.get(
      `${physicalAssessment.items[index]}`,
    );
  }

  if (physicalAssessment?.currentSubmission) {
    physicalAssessmentSubmission = assessment.physicalAssessmentSubmissions.get(
      `${physicalAssessment?.currentSubmission}`,
    );
  }

  if (physicalAssessmentItem && physicalAssessmentItem.exercises.length > 0) {
    exerciseId = physicalAssessmentItem.exercises[0];
    exercise = sweat.getSweatExercise(exerciseId);
  }

  if (physicalAssessmentItem && exercise) {
    exerciseVideo = mediaStorage.getOrCreateMediaFile(exercise, 'video');
  }

  const numBars = useMemo(() => physicalAssessment?.items?.length || 0, []);

  const onBack = useCallback(() => {
    if (index === 0) {
      navigation.goBack();
    } else {
      setIndex((index) => index - 1);
    }
  }, [index]);

  const onNext = useCallback(async () => {
    if (!physicalAssessmentItem || !physicalAssessmentSubmission) {
      return;
    }

    const score = getScoreValue(index);
    if (!score || score.length === 0) {
      setError('Required');
      return;
    }
    const result = await physicalAssessmentItem.answer(
      physicalAssessmentSubmission?.id,
      score,
    );

    if (result.ok) {
      setError('');
      if (index + 1 === numBars) {
        if (physicalAssessment) {
          analytics.logAssessmentComplete(physicalAssessment);
        }
        navigation.navigate('AssessmentSummary', {
          assessmentId,
          type: ASSESSMENT_TYPE.PHYSICAL,
        });
      } else {
        setIndex((index) => index + 1);
      }
    } else {
      setError(result.errors.detail || result.errors.value || '');
    }
  }, [
    index,
    scoreMapping,
    assessmentId,
    physicalAssessment,
    physicalAssessmentItem,
    physicalAssessmentSubmission,
    setError,
  ]);

  const getScoreValue = useCallback(
    (index: number) => {
      const score = scoreMapping[index];
      if (score && physicalAssessmentItem) {
        if (physicalAssessmentItem.unit === 'seconds') {
          const time = toNumber(score);
          if (isFinite(time)) {
            return `${time}`; // Assumed seconds.
          } else {
            return `${moment.duration(score).as('seconds') / 60}`;
          }
        }
      }
      return score;
    },
    [scoreMapping, physicalAssessmentItem],
  );

  const onScoringUpdate = useCallback(
    (score: string) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: score,
      }));
    },
    [index],
  );

  const onStopTimer = useCallback(
    (timeSpent: number) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: `${getTimeDisplay(timeSpent)}`,
      }));
    },
    [index],
  );

  const renderTruncatedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="c1">
          Read More
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const renderRevealedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="c1">
          Show Less
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      await assessment.fetchPhysicalAssessmentItems(assessmentId);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (physicalAssessment && !physicalAssessment.currentSubmission) {
      async () => {
        setLoading(true);
        physicalAssessment.start();
        setLoading(false);
      };
    }
  }, [physicalAssessment]);

  useEffect(() => {
    if (!exercise && isNumber(exerciseId)) {
      (async () => {
        setLoading(true);
        await sweat.fetchSweatExercise(exerciseId);
        setLoading(false);
      })();
    }
  }, [physicalAssessmentItem]);

  if (!physicalAssessment || !physicalAssessmentItem) {
    if (loading) {
      return <Loading />;
    }
    return null;
  }

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar
          title={physicalAssessmentItem.title}
          titleOpacity={titleOpacity.current.interpolate({
            inputRange: [0, config.titleDisplayOffsetOnScroll],
            outputRange: [0, 1],
          })}
        />
      </Layout>
      <AnimatedScrollView
        style={styles.content}
        contentContainerStyle={styles.contentContainer}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: titleOpacity.current } } }],
          { useNativeDriver: true },
        )}
      >
        <Text style={styles.title} category="h2">
          {physicalAssessmentItem.title}
        </Text>
        {!!exerciseVideo && (
          <VideoPlayer
            style={styles.videoPlayer}
            videoFile={exerciseVideo}
            size={width}
            muted={true}
          />
        )}
        {!!physicalAssessmentItem.videoId && (
          <YoutubePlayer
            webViewStyle={styles.videoPlayer}
            width={width}
            height={(width / 4) * 3}
            videoId={physicalAssessmentItem.videoId}
            initialPlayerParams={{
              loop: true,
              modestbranding: true,
              rel: false,
            }}
            webViewProps={{ scrollEnabled: false }}
            play
          />
        )}
        {!!physicalAssessmentItem.shortDescription && (
          <Layout style={styles.descriptionContainer}>
            <Text style={styles.description} category="c1">
              {physicalAssessmentItem.shortDescription}
            </Text>
          </Layout>
        )}
        {!!physicalAssessmentItem.longDescription && (
          <Layout style={styles.descriptionContainer}>
            <ReadMore
              numberOfLines={3}
              renderTruncatedFooter={renderTruncatedFooter}
              renderRevealedFooter={renderRevealedFooter}
            >
              <Text style={styles.description} category="c1">
                {physicalAssessmentItem.longDescription}
              </Text>
            </ReadMore>
          </Layout>
        )}
        {!!physicalAssessmentItem.rest && (
          <Layout style={styles.descriptionContainer}>
            <Text style={styles.restLabel} category="c1">
              REST
            </Text>
            <Text style={styles.description} category="c1">
              {physicalAssessmentItem.rest}
            </Text>
          </Layout>
        )}
        <AssessmentScoring
          style={styles.scoring}
          error={error}
          value={scoreMapping[index] || ''}
          onChangeText={onScoringUpdate}
          unit={physicalAssessmentItem.unit}
          label={physicalAssessmentItem?.unitDisplay}
        />
      </AnimatedScrollView>
      {!!physicalAssessmentItem.timer && (
        <Timer
          type={physicalAssessmentItem.timer}
          timeInSeconds={
            physicalAssessmentItem.timerLengthInSeconds
              ? physicalAssessmentItem.timerLengthInSeconds
              : undefined
          }
          onStopTimer={onStopTimer}
        />
      )}
      <Layout>
        <ProgressBar numBars={numBars} currentBar={index} />
        <BottomNav onBack={onBack} onNext={onNext} />
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 16,
  },
  descriptionContainer: {
    marginHorizontal: 18,
    marginVertical: 12,
  },
  description: {
    color: 'lighter-gray',
  },
  scoring: {
    marginHorizontal: 24,
    marginVertical: 12,
  },
  readMore: {
    marginTop: 8,
    textDecorationLine: 'underline',
    color: 'gray',
  },
  title: {
    marginHorizontal: 24,
    marginTop: 16,
    marginBottom: 4,
  },
  videoPlayer: {
    marginVertical: 12,
  },
  restLabel: {
    fontWeight: 'bold',
    marginBottom: 10,
    textTransform: 'uppercase',
  },
});

export default observer(PhysicalAssessmentDetail);
