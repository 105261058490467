import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import SavedList from 'o2x-store/src/models/SavedList';
import ThriveExercise from 'o2x-store/src/models/ThriveExercise';
import { useStore } from 'o2x-store/src/stores';
import { getListByType, getSource, LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useState } from 'react';
import {
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'src/components/Question/TopNav';
import { useIsSubscribed } from 'src/hooks/subscription';
import Edit from '../../assets/images/edit.svg';
import SavedProgramItem from '../../components/Saved/SavedProgramItem';

type Props = {
  route: any;
};

const ListDetail: React.FC<Props> = (props) => {
  const {
    route: {
      params: { listType, listId },
    },
  } = props;

  const root = useStore();
  const { navigateWithPayWall } = useIsSubscribed();
  const { eat, savedList, sweat, thrive } = root;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const [selectedList, setSelectedList] = useState<SavedList>();
  const [items, setItems] = useState<Map<string, Program | ThriveExercise>>(
    new Map<string, Program | ThriveExercise>(),
  );

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      const storeList = getListByType(savedList, listType, listId);
      if (storeList) {
        setSelectedList(storeList);
        const source = getSource(root, listType);
        const newItems = new Map<string, Program | ThriveExercise>();
        storeList?.items.forEach((itemId: number) => {
          const item = source.get(`${itemId}`);
          if (item) {
            newItems.set(`${itemId}`, item);
          }
        });
        setItems(newItems);
      }
      const fetchFromApi = async () => {
        const fetchedList = await savedList.getList(listType, listId);
        if (fetchedList && !('error' in fetchedList)) {
          setSelectedList(fetchedList);
          const source = getSource(root, listType);
          const newItems = new Map<string, Program | ThriveExercise>();
          fetchedList.items.forEach((fetchedItem: Program | ThriveExercise) => {
            const item = source.get(`${fetchedItem.id}`);
            if (item) {
              newItems.set(`${fetchedItem.id}`, item);
            }
          });
          setItems(newItems);
        }
      };
      fetchFromApi();
    }, [savedList, listType, listId]),
  );

  const onPressListItem = useCallback(
    (item) => {
      if (listType === LIST_TYPE.SWEAT_WORKOUT) {
        navigateWithPayWall('SweatContinue', { workoutId: item.id });
      } else if (listType === LIST_TYPE.RECIPE) {
        navigation.navigate('ProgramDetail', { type: item.type, id: item.id });
      } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
        navigateWithPayWall('ThriveYogaDetail', { id: item.id });
      }
    },
    [navigateWithPayWall],
  );
  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity onPress={() => onPressListItem(item)}>
        <SavedProgramItem program={item} showHandle={true} key={item.id} />
      </TouchableOpacity>
    ),
    [],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const editList = useCallback(() => {
    navigation.navigate('ListEdit', { listType: listType, listId: listId });
  }, [listType, selectedList]);

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={isMobile ? styles.containerMobile : styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onClose={goBack} showBack={false} />
            </View>
            <Layout
              style={
                isMobile ? styles.scrollWrapperMobile : styles.scrollWrapper
              }
            >
              <Text category="h2" style={styles.header}>
                {selectedList?.name}
              </Text>
              <View
                style={isMobile ? styles.subHeaderMobile : styles.subHeader}
              >
                <Text style={[styles.subTitle, { color: selectedList?.color }]}>
                  {selectedList?.subtitle}
                </Text>
              </View>
              <TouchableOpacity
                style={isMobile ? styles.editMobile : styles.edit}
                onPress={editList}
              >
                <Edit />
                <View style={styles.editPadding} />
                <Text style={styles.link}>Edit List</Text>
              </TouchableOpacity>
              <View style={styles.listContainer}>
                {items && (
                  <FlatList
                    data={Array.from(items.values())}
                    renderItem={renderItem}
                    keyExtractor={(item) => `${item.id}`}
                    style={!isMobile && styles.listItem}
                  />
                )}
              </View>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  header: {
    marginBottom: 12,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    paddingHorizontal: 24,
  },
  scrollWrapper: {
    flex: 1,
    alignItems: 'center',
  },
  scrollWrapperMobile: {
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 15,
  },
  subHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
    paddingHorizontal: 24,
  },
  subHeaderMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingHorizontal: 24,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  listContainer: {
    flex: 1,
    paddingBottom: 24,
  },
  listItem: {
    paddingHorizontal: 24,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  edit: {
    flexDirection: 'row',
    paddingRight: 330,
    paddingVertical: 10,
  },
  editMobile: {
    flexDirection: 'row',
    paddingVertical: 10,
    alignSelf: 'flex-start',
  },
  editPadding: {
    paddingHorizontal: 5,
  },
});

export default observer(ListDetail);
