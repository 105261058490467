import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components';
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getWeeksInMonth,
  getYear,
  isEqual,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import React, { useMemo } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Props = {
  setSelectedDate: Function;
  eventDates?: string[];
  guestEvents?: string[];
  pending?: string[];
  baseDay: Date;
  onNextMonth: () => void;
  onPrevMonth: () => void;
};

const DAY_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const DAYS_IN_WEEK = 7;

const today = new Date();

const Calendar: React.FC<Props> = (props) => {
  const {
    setSelectedDate,
    eventDates,
    guestEvents,
    pending,
    baseDay,
    onNextMonth,
    onPrevMonth,
  } = props;
  const styles = useStyleSheet(themedStyles);

  const getDays = () => {
    const startDay = startOfMonth(baseDay);
    const startDayOfWeek = getDay(startDay);
    const endDay = endOfMonth(baseDay);
    const endDayOfWeek = getDay(endDay);
    const allDays = eachDayOfInterval({ start: startDay, end: endDay });

    // Group by week
    const currentMonth = getMonth(startDay);
    const currentYear = getYear(startDay);
    const weeksInMonth = getWeeksInMonth(currentMonth);
    const daysInMonth = getDaysInMonth(new Date(currentYear, currentMonth));
    const daysPerWeek = Array<Array<Date | null>>();

    let weeks = weeksInMonth;
    if (
      DAYS_IN_WEEK - startDayOfWeek + (weeks - 1) * DAYS_IN_WEEK <
      daysInMonth
    ) {
      weeks = weeks + 1;
    }

    let i: number;
    let currentIndex: number = 0;
    for (i = 0; i < weeks; i++) {
      let days = new Array<Date | null>();
      if (i === 0) {
        // First iteration
        days = [
          ...Array.from(Array(startDayOfWeek).keys()).map((l) => null),
          ...allDays.slice(0, DAYS_IN_WEEK - startDayOfWeek),
        ];
        currentIndex = DAYS_IN_WEEK - startDayOfWeek;
      } else {
        if (i === weeks - 1) {
          // Last iteration
          days = [
            ...allDays.slice(currentIndex, daysInMonth),
            ...Array.from(
              Array(DAYS_IN_WEEK - (daysInMonth - currentIndex)).keys(),
            ).map((l) => null),
          ];
        } else {
          days = allDays.slice(currentIndex, currentIndex + DAYS_IN_WEEK);
        }
        currentIndex = currentIndex + DAYS_IN_WEEK;
      }
      daysPerWeek.push(days);
    }
    return daysPerWeek;
  };

  const daysPerWeek = useMemo(() => getDays(), [baseDay]);

  return (
    <View style={styles.container}>
      <View style={styles.selector}>
        <TouchableOpacity onPress={onPrevMonth}>
          <Icon
            style={[styles.expandIcon, styles.selectorIconLeft]}
            fill="white"
            name="chevron-left"
          />
        </TouchableOpacity>
        <Text style={styles.selectorText}>{format(baseDay, 'MMMM yyyy')}</Text>
        <TouchableOpacity onPress={onNextMonth}>
          <Icon
            style={[styles.expandIcon, styles.selectorIconRight]}
            fill="white"
            name="chevron-right"
          />
        </TouchableOpacity>
      </View>
      <View style={styles.row}>
        {DAY_LABELS.map((d: string, idx: number) => (
          <Text key={`${d}-${idx}`} style={styles.dayLabel}>
            {d}
          </Text>
        ))}
      </View>
      {daysPerWeek.map((value: (Date | null)[], index: number) => (
        <View key={`week-${index}`} style={styles.row}>
          {value.map((day: Date | null, dayIdx: number) => {
            const hasNotification =
              day && pending?.includes(format(day, 'yyyy-MM-dd'));
            return (
              <TouchableOpacity
                key={`day-${dayIdx}`}
                style={[
                  styles.day,
                  day &&
                    isEqual(startOfDay(day), startOfDay(today)) &&
                    styles.today,
                ]}
                onPress={() => setSelectedDate(day)}
              >
                {hasNotification && <View style={styles.notification} />}
                <Text
                  style={[
                    styles.label,
                    hasNotification && styles.labelHasNotification,
                  ]}
                >
                  {day && getDate(day)}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {day && eventDates?.includes(format(day, 'yyyy-MM-dd')) && (
                    <View style={styles.eventDay} />
                  )}
                  {day && guestEvents?.includes(format(day, 'yyyy-MM-dd')) && (
                    <View style={styles.guestEvent} />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      ))}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    // marginHorizontal: 20,
    // marginVertical: 8,
  },
  titleContainer: {
    flex: 1,
    marginVertical: 8,
    marginHorizontal: 12,
  },
  bodyContainer: {
    marginVertical: 12,
    marginHorizontal: 10,
  },
  title: {
    flex: 1,
    fontSize: 18,
    fontWeight: '700',
  },
  subtitle: {
    flex: 1,
    fontSize: 12,
    marginTop: 8,
    color: 'blue-secondary',
  },
  author: {
    marginVertical: 8,
    fontSize: 14,
    color: '#4E7B89',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 12,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  icon: {
    height: 30,
    width: 30,
  },
  iconContainer: {
    height: 30,
    width: 30,
    marginRight: 12,
  },
  label: {
    textTransform: 'uppercase',
    color: '#C4C4C4',
    fontSize: 16,
    lineHeight: 17,
    textAlign: 'center',
  },
  labelHasNotification: {
    color: 'white',
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    width: '90%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 4,
    alignSelf: 'center',
  },
  day: {
    width: 44,
    textAlign: 'center',
    alignSelf: 'center',
    paddingVertical: 4,
    paddingHorizontal: 11,
  },
  today: {
    backgroundColor: 'rgba(105, 116, 132, 0.25)',
  },
  dayLabel: {
    fontSize: 15,
    color: 'white',
    width: 44,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    paddingHorizontal: 4,
    marginBottom: 8,
  },
  selector: {
    alignSelf: 'center',
    flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  selectorText: {
    fontSize: 18,
    textTransform: 'uppercase',
    color: 'white',
  },
  selectorIconLeft: {
    marginRight: 36,
  },
  selectorIconRight: {
    marginLeft: 36,
  },
  expandIcon: {
    width: 30,
    height: 30,
  },
  eventDay: {
    height: 4,
    width: 22,
    marginTop: 2,
    alignSelf: 'center',
    backgroundColor: '#4E7B89',
  },
  guestEvent: {
    height: 2,
    width: 16,
    alignSelf: 'center',
    backgroundColor: 'red',
  },
  notification: {
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: 'white',
    position: 'absolute',
    top: 5,
    right: 5,
  },
});

export default Calendar;
