import { useFocusEffect } from '@react-navigation/core';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { add, format, parseISO, startOfMonth, sub } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FTEEventModel } from 'o2x-store/src/models/Event';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Calendar from 'src/components/FTE/Calendar';
import EventItem from 'src/components/FTE/EventItem';
import TopNav from 'src/components/Question/TopNav';

type Props = {
  route: any;
};

const today = new Date();
const CalendarFte: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [baseDay, setBaseDay] = useState(new Date());
  const [eventDates, setEventDates] = useState<string[]>();
  const [events, setEvents] = useState<Array<FTEEventModel>>();
  const [dayEvents, setDayEvents] = useState<Array<FTEEventModel>>();
  const [guestEvents, setGuestEvents] = useState<string[]>([]);
  const [pending, setPending] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const store = useStore();

  const { selectedFte, checkPendingInvites } = props.route.params;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const fetchData = await store.fteEvent.fetchFTEEvents(
          selectedFte.id,
          baseDay.getMonth() + 1,
          baseDay.getFullYear(),
        );
        setEvents(fetchData.extra?.results);
        setEventDates(
          fetchData.extra?.results.map((event: FTEEventModel) => event.date),
        );
        setDayEvents(
          fetchData.extra?.results?.filter(
            (event: FTEEventModel) =>
              parseISO(event.date).getDate() === selectedDate.getDate(),
          ),
        );
        setGuestEvents(
          fetchData.extra?.results
            .filter((event: FTEEventModel) =>
              event.guests.includes(store.auth.user?.id!),
            )
            .map((event: FTEEventModel) => event.date),
        );
        setPending(
          fetchData.extra?.results
            .filter((event: FTEEventModel) =>
              event.pending.includes(store.auth.user?.id!),
            )
            .map((event: FTEEventModel) => event.date),
        );
        setLoading(false);
      })();
    }, [selectedDate]),
  );

  const onSelect = useCallback(
    (date: Date) => {
      (() => {
        if (date) {
          setSelectedDate(date);
          setDayEvents(
            events?.filter(
              (event) => parseISO(event.date).getDate() === date.getDate(),
            ),
          );
        }
      })();
    },
    [selectedDate, events],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <EventItem
        item={item}
        selectedFte={selectedFte}
        userId={store.auth.user?.id!}
        checkPendingInvites={checkPendingInvites}
      />
    ),
    [selectedFte, dayEvents, selectedDate],
  );

  const renderHeader = useCallback(
    () => <View style={styles.listHeader} />,
    [],
  );

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const onPrevMonth = useCallback(async () => {
    const startDay = startOfMonth(baseDay);
    const prevMonthDay = sub(startDay, { months: 1 });
    setLoading(true);
    const fetchData = await store.fteEvent.fetchFTEEvents(
      selectedFte.id,
      prevMonthDay.getMonth() + 1,
      prevMonthDay.getFullYear(),
    );
    setEvents(fetchData.extra?.results);
    setEventDates(
      fetchData.extra?.results.map((event: FTEEventModel) => event.date),
    );
    setGuestEvents(
      fetchData.extra?.results
        .filter((event: FTEEventModel) =>
          event.guests.includes(store.auth.user?.id!),
        )
        .map((event: FTEEventModel) => event.date),
    );
    setPending(
      fetchData.extra?.results
        .filter((event: FTEEventModel) =>
          event.pending.includes(store.auth.user?.id!),
        )
        .map((event: FTEEventModel) => event.date),
    );
    setLoading(false);
    setBaseDay(prevMonthDay);
  }, [baseDay]);

  const onNextMonth = useCallback(async () => {
    const startDay = startOfMonth(baseDay);
    const nextMonthDay = add(startDay, { months: 1 });
    setLoading(true);
    const fetchData = await store.fteEvent.fetchFTEEvents(
      selectedFte.id,
      nextMonthDay.getMonth() + 1,
      nextMonthDay.getFullYear(),
    );
    setEvents(fetchData.extra?.results);
    setEventDates(
      fetchData.extra?.results.map((event: FTEEventModel) => event.date),
    );
    setGuestEvents(
      fetchData.extra?.results
        .filter((event: FTEEventModel) =>
          event.guests.includes(store.auth.user?.id!),
        )
        .map((event: FTEEventModel) => event.date),
    );
    setPending(
      fetchData.extra?.results
        .filter((event: FTEEventModel) =>
          event.pending.includes(store.auth.user?.id!),
        )
        .map((event: FTEEventModel) => event.date),
    );
    setLoading(false);
    setBaseDay(nextMonthDay);
  }, [baseDay]);

  return (
    <Layout
      style={[
        styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={styles.header}>
        <Text style={styles.headerText}>Schedule</Text>
      </View>
      <Calendar
        setSelectedDate={onSelect}
        eventDates={eventDates}
        guestEvents={guestEvents}
        pending={pending}
        baseDay={baseDay}
        onNextMonth={onNextMonth}
        onPrevMonth={onPrevMonth}
      />
      <Text style={styles.eventHeader}>
        {format(selectedDate, 'MMMM dd, yyyy')}
      </Text>
      {loading ? (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      ) : dayEvents && dayEvents.length > 0 ? (
        <FlatList
          data={dayEvents}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListHeaderComponent={renderHeader}
        />
      ) : (
        <Text style={styles.eventText}> No Scheduled Events</Text>
      )}

      <View style={styles.navigationContainer}>
        <TopNav showClose={false} style={styles.topNav} />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 22,
    alignSelf: 'center',
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  loading: {
    margin: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  listHeader: {
    height: 16,
  },
  eventHeader: {
    marginLeft: 20,
    marginTop: 20,
    fontSize: 18,
    lineHeight: 18,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  eventText: {
    alignSelf: 'center',
    fontSize: 14,
    color: '#C4C4C4',
    marginTop: 20,
  },
});

export default observer(CalendarFte);
