import {
  Icon,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { cloneDeep } from 'lodash';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
} from 'o2x-store/src/models/FTELibrary';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import DraggableFlatList, {
  RenderItemParams,
} from 'react-native-draggable-flatlist';
import ExerciseItem from './ExerciseItem';
import LoadModalTemplate from './LoadModalTemplate';
import MorePopover from './MorePopover';
import SaveModalTemplate from './SaveModalTemplate';

type Props = {
  stepIndex: number;
  setStepIndex: Function;
  step: FTESweatWorkoutStep | SweatWorkoutStep;
  setExerIndex: Function;
  setExercise: Function;
  setParamModalVisible: Function;
  showExerModal: Function;
  editStepDetails: Function;
  setSelectedType: Function;
  stepJSON: string;
  onRemoveStep: () => void;
  content: string;
  stepList?: (FTESweatWorkoutStep | SweatWorkoutStep)[];
  setStepList?: Function;
  workoutList?: (FTESweatWorkout | SweatWorkout)[];
  setWorkoutList?: Function;
  workout?: FTESweatWorkout | SweatWorkout;
  workoutIndex?: number;
  stepClipboard?: FTESweatWorkoutStep | SweatWorkoutStep;
  setStepClipboard?: Function;
  pasteStep?: (index: number) => void;
  drag: () => void;
};
const stepTypes: { [key: string]: string } = {
  '': 'Perform in Order',
  amrap: 'AMRAP (As Many Rounds as Possible)',
  emom: 'EMOM (Every Minute On the Minute)',
  tabata: 'TABATA',
  variable: 'Standard Circuit',
};

const StepItem: React.FC<Props> = (props) => {
  const {
    stepIndex,
    step,
    setExerIndex,
    setExercise,
    setParamModalVisible,
    showExerModal,
    editStepDetails,
    onRemoveStep,
    content,
    stepList,
    setStepList,
    workoutList,
    setWorkoutList,
    workout,
    workoutIndex,
    setStepIndex,
    setSelectedType,
    stepClipboard,
    setStepClipboard,
    pasteStep,
    drag,
  } = props;
  const styles = useStyleSheet(themedStyles);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number>();
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [loadModalVisible, setLoadModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [stepTemplate, setStepTemplate] = useState<
    FTESweatWorkoutStep | SweatWorkoutStep
  >();
  const [stepCopy, setStepCopy] = useState<
    FTESweatWorkoutStep | SweatWorkoutStep
  >(step);

  const store = useStore();

  useEffect(() => {
    if (stepTemplate) {
      let exerArr = stepTemplate.exercises;
      exerArr?.forEach((exercise) => {
        exercise.id = null;
        exercise.exercise = exercise.exercise.id;
      });
      const stepObject: Partial<FTESweatWorkoutStep> = {
        circuitSets: stepTemplate.circuitSets,
        circuitTimer: stepTemplate.circuitTimer,
        circuitTimerWork: stepTemplate.circuitTimerWork,
        exercises: exerArr,
      };
      if (content === 'workout') {
        let stepArr = [...stepList!];
        stepArr?.splice(stepIndex, 1, stepObject);
        setStepList && setStepList([...stepArr]);
      } else if (content === 'program' && setWorkoutList) {
        const workoutListCopy = [...workoutList!];
        workoutListCopy[workoutIndex].steps.splice(stepIndex, 1, stepObject);
        setWorkoutList([...workoutListCopy]);
      }
      setStepCopy(stepObject);
    }
  }, [stepTemplate]);

  useEffect(() => {
    if (removeIndex! >= 0) {
      if (content === 'workout' && setStepList) {
        const list: any = [...stepList!];
        list[stepIndex]['exercises'].splice(removeIndex, 1);
        setStepList(list);
      } else if (content === 'program' && workout && setWorkoutList) {
        const workoutCopy = workout;
        const workoutListCopy = [...workoutList!];
        workoutCopy.steps?.[stepIndex]['exercises']?.splice(removeIndex!, 1);
        workoutListCopy[workoutIndex!] = workoutCopy;
        setWorkoutList(workoutListCopy);
      }
      setRemoveIndex(undefined);
    }
  }, [removeIndex, stepList, workoutList]);

  const saveTemplate = useCallback(async () => {
    const data = step;
    data.name = templateName;
    data.fte = store.auth.user?.id;
    data.exercises?.map((exercise) => {
      Number.isInteger(exercise.exercise)
        ? null
        : (exercise.exercise = exercise.exercise.id);
    });
    await store.fteLibrary.saveFteWorkoutStepTemplate(data);
    setSaveModalVisible(false);
  }, [templateName]);

  const renderToggleButton = () => (
    <TouchableOpacity
      style={{
        marginLeft: 'auto',
        // marginRight: 10,
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      onPress={() => setIsOptionsVisible(true)}
    >
      <Icon
        name="more-vertical-outline"
        fill="white"
        style={{
          width: 15,
          height: 15,
        }}
      />
    </TouchableOpacity>
  );

  const convertTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    let seconds = `${time % 60}`;
    if (parseInt(seconds) < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  };

  const stepDisplay = useCallback(() => {
    if (stepTemplate) {
      if (stepTemplate.circuitTimer === '')
        return `STEP ${stepIndex + 1} - Perform in Order`;
      else if (stepTemplate.circuitTimer === 'variable')
        return `STEP ${stepIndex + 1} - Standard Circuit - ${
          stepTemplate.circuitSets
        } sets`;
      else if (stepTemplate.circuitTimer === 'tabata')
        return `STEP ${stepIndex + 1} - TABATA`;
      else
        return `STEP ${stepIndex + 1} - ${
          stepTemplate.circuitTimer === 'emom' ? 'EMOM' : 'AMRAP'
        } -  ${convertTime(step.circuitTimerWork!)}`;
    } else {
      if (step.circuitTimer === '')
        return `STEP ${stepIndex + 1} - Perform in Order`;
      else if (step.circuitTimer === 'variable')
        return `STEP ${stepIndex + 1} - Standard Circuit - ${
          step.circuitSets
        } sets`;
      else if (step.circuitTimer === 'tabata')
        return `STEP ${stepIndex + 1} - TABATA`;
      else
        return `STEP ${stepIndex + 1} - ${
          step.circuitTimer === 'emom' ? 'EMOM' : 'AMRAP '
        } - ${convertTime(step.circuitTimerWork!)}`;
    }
  }, [step.circuitTimer, step.circuitSets]);

  const onCopy = () => {
    const stepCopy = cloneDeep(step);
    delete stepCopy.id;
    stepCopy.exercises?.forEach((exercise) => {
      delete exercise.id;
      exercise.exerciseSets.forEach((set) => {
        delete set.id;
        delete set.exercise;
      });
    });
    setStepClipboard(stepCopy);
  };

  const renderItem = useCallback(
    ({
      item,
      index,
      drag,
    }: RenderItemParams<
      SweatWorkoutStepExercise | FTESweatWorkoutStepExercise
    >) => {
      return (
        <ExerciseItem
          key={index}
          index={index}
          exercise={item}
          circuitSets={stepCopy?.circuitSets}
          setExerIndex={setExerIndex}
          setExercise={setExercise}
          setParamModalVisible={setParamModalVisible}
          style={styles.exerciseContainer}
          exerciseData={JSON.stringify(item)}
          onRemoveExercise={() => setRemoveIndex(index)}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          stepList={stepList}
          workoutList={workoutList}
          content={content}
          drag={drag}
        />
      );
    },
    [stepList, stepClipboard, workoutList, stepCopy, stepCopy.exercises],
  );

  return (
    <Layout key={stepIndex} style={{ backgroundColor: 'transparent' }}>
      {!!saveModalVisible && (
        <SaveModalTemplate
          saveModalVisible={saveModalVisible}
          hideSaveModalVisible={() => setSaveModalVisible(false)}
          templateName={templateName}
          setTemplateName={setTemplateName}
          saveTemplate={saveTemplate}
        />
      )}
      {!!loadModalVisible && (
        <LoadModalTemplate
          loadModalVisible={loadModalVisible}
          content="steps"
          hideLoadModalVisible={() => setLoadModalVisible(false)}
          setStepTemplate={setStepTemplate}
          store={store}
        />
      )}
      {!!stepClipboard && (
        <TouchableOpacity
          style={{ marginTop: 10, flexDirection: 'row' }}
          onPress={() => pasteStep(stepIndex)}
        >
          <Icon
            name="copy-outline"
            style={{ width: 16, height: 16, marginRight: 7 }}
            fill="#8F9BB3"
          />
          <Text style={[styles.stepText, { marginTop: 0 }]}>
            Paste step here
          </Text>
        </TouchableOpacity>
      )}
      <Layout style={{ flexDirection: 'row', width: '100%' }}>
        <TouchableOpacity
          style={{ margin: 'auto' }}
          onLongPress={drag}
          delayLongPress={50}
        >
          <Icon
            name="menu-outline"
            fill="white"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
        <Select
          size="medium"
          style={[styles.stepContainer, { marginTop: 5 }]}
          placeholder={() => (
            <Text style={styles.stepTitle} numberOfLines={1}>
              {stepDisplay()}
            </Text>
          )}
          onSelect={(indexPath: IndexPath | IndexPath[]) => {
            setSelectedType(Object.keys(stepTypes)[indexPath.row]);
            editStepDetails(Object.keys(stepTypes)[indexPath.row], stepIndex);
          }}
        >
          {Object.values(stepTypes).map((type, stepIndex) => {
            return <SelectItem key={stepIndex} title={type} />;
          })}
        </Select>
        <MorePopover
          isOptionsVisible={isOptionsVisible}
          renderToggleButton={renderToggleButton}
          setIsOptionsVisible={setIsOptionsVisible}
          removeItem={onRemoveStep}
          saveItem={() => setSaveModalVisible(true)}
          loadItem={() => setLoadModalVisible(true)}
          onCopy={onCopy}
          // onPaste={onPaste}
          content={content === 'program' ? 'step' : ''}
        />
      </Layout>
      <DraggableFlatList
        data={stepCopy.exercises}
        renderItem={renderItem}
        keyExtractor={(item) => `${item.id}`}
        onDragEnd={({ data }) => {
          if (content === 'workout' && setStepList) {
            const list: any = [...stepList!];
            list[stepIndex]['exercises'] = data;
            setStepList(list);
          } else if (content === 'program' && workout && setWorkoutList) {
            const workoutCopy = workout;
            const workoutListCopy = [...workoutList!];
            workoutCopy.steps[stepIndex]['exercises'] = data;
            workoutListCopy[workoutIndex!] = workoutCopy;
            setWorkoutList(workoutListCopy);
          }
        }}
      />
      <TouchableOpacity
        style={[styles.exerciseContainer, { marginLeft: 'auto' }]}
        onPress={() => showExerModal(stepIndex)}
      >
        <Text style={styles.exerciseText}>+ Add Exercise</Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  stepContainer: {
    width: '88%',
    // backgroundColor: '#767F6A',
    height: 30,
    marginTop: 5,
    marginBottom: 10,
  },
  stepTitle: {
    fontSize: 12,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  exerciseContainer: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#767F6A',
    height: 30,
    marginTop: 5,
    marginBottom: 5,
    width: '85%',
  },
  exerciseText: {
    fontSize: 15,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  detailInput: {
    width: '100%',
    marginTop: 10,
  },
  stepText: {
    fontSize: 14,
    color: '#8F9BB3',
    marginTop: 10,
    fontWeight: 'bold',
  },
});

export default React.memo(StepItem, (prevProps, nextProps) => {
  if (
    prevProps.stepJSON !== nextProps.stepJSON ||
    JSON.stringify(prevProps.stepList) !== JSON.stringify(nextProps.stepList) ||
    prevProps.stepList?.length !== nextProps.stepList?.length ||
    (prevProps.workoutList &&
      nextProps.workoutList &&
      prevProps.workoutList.length !== nextProps.workoutList.length) ||
    prevProps.step.circuitSets !== nextProps.step.circuitSets ||
    prevProps.step.circuitTimer !== nextProps.step.circuitTimer ||
    prevProps.stepClipboard !== nextProps.stepClipboard
  ) {
    return false;
  }
  return true;
});
