import { first, isNumber } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import ThriveProgramExercise from './ThriveProgramExercise';

@model('o2x-store/ThriveProgramDay')
export default class ThriveProgramDay extends Model({
  id: prop<number>(),
  week: prop<number>(),
  day: prop<number>(),
  program: prop<number>(),
  exercises: prop<number[]>(() => []),
  programExercises: prop<number[]>(() => []),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @computed
  get dayDisplay(): string {
    return `Week ${this.week} Day ${this.day}`;
  }

  @modelAction
  update(source: ModelInstanceCreationData<ThriveProgramDay>) {
    const data = { ...source };
    const { exercises, programExercises } = data;
    delete data.exercises;
    delete data.programExercises;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { thrive } = rootStore;

    if (exercises) {
      if (isNumber(first(exercises))) {
        this.exercises = exercises;
      } else {
        this.exercises = exercises.map((data: any) => {
          thrive.createOrUpdateThriveExercise(data);
          return data.id;
        });
      }
    }

    if (programExercises) {
      if (isNumber(first(programExercises))) {
        this.programExercises = programExercises;
      } else {
        this.programExercises = programExercises.map((data: any) => {
          thrive.createOrUpdateThriveProgramExercise(data);
          return data.id;
        });
      }
    }
  }

  @modelFlow
  fetch = _async(function* (this: ThriveProgramDay) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgramDay>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchThriveProgramDay(rootStore.auth.token, this.program, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive program day', error);
      return getError(error);
    }

    this.update(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  start = _async(function* (this: ThriveProgramDay) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgramDay>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.startThriveProgramDay(rootStore.auth.token, this.program, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error starting thrive program day', error);
      return getError(error);
    }

    this.update(entities);

    const thriveProgram = rootStore.thrive.thrivePrograms.get(
      `${this.program}`,
    );
    if (thriveProgram) analytics.logThriveProgramDayStart(this, thriveProgram);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markComplete = _async(function* (this: ThriveProgramDay) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<ThriveProgramDay>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.markCompleteThriveProgamDay(
          rootStore.auth.token,
          this.program,
          this.id,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error completing thrive program day', error);
      return getError(error);
    }

    this.update(entities);

    const thriveProgram = rootStore.thrive.thrivePrograms.get(
      `${this.program}`,
    );
    if (thriveProgram)
      analytics.logThriveProgramDayComplete(this, thriveProgram);

    this.loading = false;
    return getSuccess();
  });

  @computed
  get thriveStartSections(): ThriveProgramExercise[] {
    const rootStore = getRoot<RootStore>(this);
    const { thrive } = rootStore;
    const exercises = this.programExercises
      .map((id) => thrive.thriveProgramExercises.get(`${id}`))
      .filter((e) => !!e) as ThriveProgramExercise[];
    return exercises;
  }
}
