import * as Notifications from 'expo-notifications';
import { observable } from 'mobx';
import {
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  prop_dateString,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import moment from 'moment';
import { Platform } from 'react-native';

const DAYS_BEFORE_THE_EVENT = 1;
const NOTIFICATION_DAY_UNIT = 'days';

type EventData = {
  eventName: string;
  createdDate: string;
};
export const NotificationBody = {
  eat: 'Log your EAT habits today',
  sweat: `Do today's SWEAT workout`,
  thrive: `Do today's THRIVE exercise`,
  event: `Event starts in 1 day`,
  contact: 'New Message',
  calendar: 'New Event',
  dashboard: 'New Dashboard Post',
  content: 'New Content',
};

@model('o2x-native/Notification')
export default class Notification extends Model({
  sweatNotification: prop<string>(''),
  sweatName: prop<string>(''),
  sweatId: prop<string>(''),
  sweatCreated: prop_dateString(),
  eatNotification: prop<string>(''),
  eatCreated: prop_dateString(),
  thriveNotification: prop<string>(''),
  thriveName: prop<string>(''),
  thriveId: prop<string>(''),
  thriveCreated: prop_dateString(),
  eventNotification: prop_mapObject(() => new Map<string, string>()),
  eventData: prop_mapObject(() => new Map<string, EventData>()),
  restoreInLogin: prop<boolean>(true),
  eventNotificationUpdateDate: prop_dateString(),
}) {
  @observable loading = false;

  @modelAction
  updateSweatInfo(name: string, id: string, date: Date) {
    this.sweatName = name;
    this.sweatId = id;
    this.sweatCreated = date;
  }

  @modelAction
  updateThriveInfo(name: string, id: string, date: Date) {
    this.thriveName = name;
    this.thriveId = id;
    this.thriveCreated = date;
  }

  @modelAction
  updateEatInfo(date: Date) {
    this.eatCreated = date;
  }

  @modelAction
  isEventReminderUpdate() {
    const currentDate = moment().toDate();
    if (
      this.eventNotificationUpdateDate &&
      moment(this.eventNotificationUpdateDate).isSame(currentDate, 'day')
    ) {
      return false;
    }

    this.eventNotificationUpdateDate = currentDate;
    return true;
  }

  @modelFlow
  setEventNotification = _async(function* (
    this: Notification,
    eventId: string,
    eventName: string,
    eventStart: string,
  ) {
    if (this.eventNotification.get(eventId)) {
      return;
    }

    const eventCreated = moment(eventStart)
      .subtract(DAYS_BEFORE_THE_EVENT, NOTIFICATION_DAY_UNIT)
      .toDate();
    if (moment().isAfter(eventCreated)) {
      return;
    }

    this.eventData.set(eventId, {
      eventName: eventName,
      createdDate: eventCreated.toString(),
    });

    const eventNotification = yield* _await(
      this.setNotification(
        `${eventName}`,
        NotificationBody.event,
        `${eventId}`,
        eventCreated,
      ),
    );

    this.eventNotification.set(eventId, `${eventNotification}`);
  });

  @modelFlow
  setThriveNotification = _async(function* (
    this: Notification,
    programName: string,
    programId: string,
    days: number,
  ) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    this.loading = true;
    if (this.thriveNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.thriveNotification),
      );
    }
    this.thriveName = programName;
    this.thriveCreated = moment().add(days, NOTIFICATION_DAY_UNIT).toDate();
    this.thriveId = programId;
    const thriveNotification = yield* _await(
      this.setNotification(
        `${this.thriveName}`,
        NotificationBody.thrive,
        this.thriveId,
        this.thriveCreated,
      ),
    );

    this.thriveNotification = `${thriveNotification}`;
    this.loading = false;
  });

  @modelFlow
  setSweatNotification = _async(function* (
    this: Notification,
    programName: string,
    programId: string,
    days: number,
  ) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    this.loading = true;
    if (this.sweatNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.sweatNotification),
      );
    }
    this.sweatName = programName;
    this.sweatCreated = moment().add(days, NOTIFICATION_DAY_UNIT).toDate();
    this.sweatId = programId;
    const sweatNotification = yield* _await(
      this.setNotification(
        `${this.sweatName}`,
        NotificationBody.sweat,
        this.sweatId,
        this.sweatCreated,
      ),
    );

    this.sweatNotification = `${sweatNotification}`;
    this.loading = false;
  });

  @modelFlow
  setEatNotification = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    this.loading = true;
    if (this.eatNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.eatNotification),
      );
    }
    this.eatCreated = moment().add(1, NOTIFICATION_DAY_UNIT).toDate();
    const eatNotification = yield* _await(
      this.setNotification(
        'Daily EAT log reminder',
        NotificationBody.eat,
        'eatId', // Not needed for Eat
        this.eatCreated,
      ),
    );

    this.eatNotification = `${eatNotification}`;
    this.loading = false;
  });

  @modelFlow
  setNotification = _async(function* (
    this: Notification,
    title: string,
    body: string,
    id: string,
    time: Date,
  ) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }

    //cancel all notifications to avoid duplicate notifications when app is opened
    _await(Notifications.cancelAllScheduledNotificationsAsync());

    const notification = yield* _await(
      Notifications.scheduleNotificationAsync({
        content: {
          title: title,
          body: body,
          data: { type: body, id: id },
        },
        trigger: time,
      }),
    );

    return notification;
  });

  @modelAction
  setRestoreInLogin(bool: boolean) {
    this.restoreInLogin = bool;
  }

  @modelFlow
  restoreDailyPlanReminders = _async(function* (this: Notification) {
    if (this.eatCreated && moment().isBefore(this.eatCreated)) {
      const eatNotification = yield* _await(
        this.setNotification(
          'Daily EAT log reminder',
          NotificationBody.eat,
          'eatId', // Not needed for Eat
          this.eatCreated,
        ),
      );
      this.eatNotification = `${eatNotification}`;
    }
    if (this.sweatCreated && moment().isBefore(this.sweatCreated)) {
      const sweatNotification = yield* _await(
        this.setNotification(
          `${this.sweatName}`,
          NotificationBody.sweat,
          this.sweatId,
          this.sweatCreated,
        ),
      );
      this.sweatNotification = `${sweatNotification}`;
    }
    if (this.thriveCreated && moment().isBefore(this.thriveCreated)) {
      const thriveNotification = yield* _await(
        this.setNotification(
          `${this.thriveName}`,
          NotificationBody.thrive,
          this.thriveId,
          this.thriveCreated,
        ),
      );
      this.thriveNotification = `${thriveNotification}`;
    }
  });

  @modelFlow
  restoreEventReminders = _async(function* (this: Notification) {
    if (this.eventData.size > 0) {
      for (const [key, value] of this.eventData) {
        if (moment().isBefore(value.createdDate)) {
          const eventNotification = yield* _await(
            this.setNotification(
              `${value.eventName}`,
              NotificationBody.event,
              `${key}`,
              moment(value.createdDate).toDate(),
            ),
          );
          this.eventNotification.set(key, `${eventNotification}`);
        }
      }
    }
  });

  @modelFlow
  cancelEatNotification = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    if (this.eatNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.eatNotification),
      );
    }
    this.eatNotification = '';
  });

  @modelFlow
  cancelSweatNotification = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    if (this.sweatNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.sweatNotification),
      );
    }
    this.sweatNotification = '';
  });

  @modelFlow
  cancelThriveNotification = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    if (this.thriveNotification !== '') {
      yield* _await(
        Notifications.cancelScheduledNotificationAsync(this.thriveNotification),
      );
    }
    this.thriveNotification = '';
  });

  @modelFlow
  cancelDailyPlanReminders = _async(function* (this: Notification) {
    yield* _await(this.cancelEatNotification());
    yield* _await(this.cancelSweatNotification());
    yield* _await(this.cancelThriveNotification());
  });

  @modelFlow
  cancelEventReminders = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    if (this.eventNotification.size > 0) {
      for (const [key, value] of this.eventNotification) {
        yield* _await(Notifications.cancelScheduledNotificationAsync(value));
      }
    }
    this.eventNotification = new Map<string, string>();
  });

  @modelAction
  resetEventData() {
    this.eventData = new Map<string, EventData>();
  }

  @modelFlow
  removeAllNotifications = _async(function* (this: Notification) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] Notifications not supported on web');
      return;
    }
    yield* _await(Notifications.cancelAllScheduledNotificationsAsync());

    this.eatNotification = '';
    this.sweatNotification = '';
    this.thriveNotification = '';
    this.eventNotification = new Map<string, string>();
  });
}
