import { createStackNavigator } from '@react-navigation/stack';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React from 'react';
import AskAnExpert from 'src/containers/AskAnExpert';
import ProgramDetail from 'src/containers/ProgramDetail';
import RatingModal from 'src/containers/RatingModal';
import ListEdit from 'src/containers/Saved/ListEdit';
import SaveToList from 'src/containers/Saved/SaveToList';
import SweatContinue from 'src/containers/SweatContinue';
import SweatOptions from 'src/containers/SweatOptions';
import SweatOptionsJump from 'src/containers/SweatOptions/SweatOptionsJump';
import SweatOptionsQuit from 'src/containers/SweatOptions/SweatOptionsQuit';
import SweatStart from 'src/containers/SweatStart';
import SweatStartDetail from 'src/containers/SweatStart/SweatStartDetail';
import SweatStartDetailInstruction from 'src/containers/SweatStart/SweatStartDetailInstruction';
import SweatWorkoutComplete from 'src/containers/SweatWorkoutComplete';
import TeamChallenge from 'src/containers/Teams/TeamChallenge';
import ThriveActivityComplete from 'src/containers/ThriveActivityComplete';
import ThriveBreathe from 'src/containers/ThriveBreathe';
import ThriveMeditate from 'src/containers/ThriveMeditate';
import ThriveOptions from 'src/containers/ThriveOption';
import ThriveOptionsJump from 'src/containers/ThriveOption/ThriveOptionsJump';
import ThriveOptionsQuit from 'src/containers/ThriveOption/ThriveOptionsQuit';
import ThriveProgramDayComplete from 'src/containers/ThriveProgramDayComplete';
import ThriveSetTime from 'src/containers/ThriveSetTime';
import ThriveSleep from 'src/containers/ThriveSleep';
import ThriveStart from 'src/containers/ThriveStart';
import ThriveYoga from 'src/containers/ThriveYoga';
import ThriveYogaFilter from 'src/containers/ThriveYoga/ThriveYogaFilter';
import ThriveYogaDetail from 'src/containers/ThriveYogaDetail';
import FindSweatPlanQuestion from 'src/containers/Today/FindSweatPlanQuestion';
import FindSweatPlanSummary from 'src/containers/Today/FindSweatPlanSummary';
import FindThrivePlanQuestion from 'src/containers/Today/FindThrivePlanQuestion';
import FindThrivePlanSummary from 'src/containers/Today/FindThrivePlanSummary';
import WorkoutGenerator from 'src/containers/WorkoutGenerator';
import Workout from 'src/containers/WorkoutGenerator/Workout';
import WorkoutGeneratorFilter from 'src/containers/WorkoutGenerator/WorkoutGeneratorFilter';
import WorkoutGeneratorEquipmentsFilter from 'src/containers/WorkoutGenerator/WorkoutGeneratorEquipmentsFilter';
import WorkoutGeneratorResult from 'src/containers/WorkoutGenerator/WorkoutGeneratorResult';
import Header from '../../Header';
import ProgramPreview from '../../ProgramDetail/Preview';
import ExploreStackModal from '../modals/ExploreStackModal';
import SweatWorkoutSummaryEditModal from '../modals/SweatWorkoutSummaryEditModal';
export type RatingModalParamList = {
  RatingModal: { type: TASK_TYPE; id: number };
};

type Props = {};

const Stack = createStackNavigator();

const ExploreStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Explore" component={ExploreStackModal} />
    <Stack.Screen name="ProgramDetail" component={ProgramDetail} />
    <Stack.Screen name="ProgramPreview" component={ProgramPreview} />
    <Stack.Screen name="RatingModal" component={RatingModal} />
    <Stack.Screen name="SweatStart" component={SweatStart} />
    <Stack.Screen name="SweatContinue" component={SweatContinue} />
    <Stack.Screen name="SweatStartDetail" component={SweatStartDetail} />
    <Stack.Screen
      name="SweatStartDetailInstruction"
      component={SweatStartDetailInstruction}
    />
    <Stack.Screen
      name="SweatWorkoutComplete"
      component={SweatWorkoutComplete}
    />
    <Stack.Screen
      name="SweatWorkoutSummary"
      component={SweatWorkoutSummaryEditModal}
    />
    <Stack.Screen name="SweatOptions" component={SweatOptions} />
    <Stack.Screen name="SweatOptionsJump" component={SweatOptionsJump} />
    <Stack.Screen name="SweatOptionsQuit" component={SweatOptionsQuit} />
    <Stack.Screen name="ThriveStart" component={ThriveStart} />
    <Stack.Screen name="ThriveOptionsJump" component={ThriveOptionsJump} />
    <Stack.Screen name="ThriveOptionsQuit" component={ThriveOptionsQuit} />
    <Stack.Screen
      name="ThriveProgramDayComplete"
      component={ThriveProgramDayComplete}
    />
    <Stack.Screen name="ThriveSleep" component={ThriveSleep} />
    <Stack.Screen name="ThriveBreathe" component={ThriveBreathe} />
    <Stack.Screen name="ThriveMeditate" component={ThriveMeditate} />
    <Stack.Screen name="ThriveYoga" component={ThriveYoga} />
    <Stack.Screen name="ThriveYogaDetail" component={ThriveYogaDetail} />
    <Stack.Screen name="ThriveYogaFilter" component={ThriveYogaFilter} />
    <Stack.Screen name="ThriveSetTime" component={ThriveSetTime} />
    <Stack.Screen name="ThriveOptions" component={ThriveOptions} />
    <Stack.Screen
      name="ThriveActivityComplete"
      component={ThriveActivityComplete}
    />
    <Stack.Screen name="WorkoutGenerator" component={WorkoutGenerator} />
    <Stack.Screen
      name="WorkoutGeneratorFilter"
      component={WorkoutGeneratorFilter}
    />
    <Stack.Screen
      name="WorkoutGeneratorEquipmentsFilter"
      component={WorkoutGeneratorEquipmentsFilter}
    />
    <Stack.Screen
      name="WorkoutGeneratorResult"
      component={WorkoutGeneratorResult}
    />
    <Stack.Screen name="Workout" component={Workout} />
    <Stack.Screen
      name="FindSweatPlanQuestion"
      component={FindSweatPlanQuestion}
    />
    <Stack.Screen
      name="FindSweatPlanSummary"
      component={FindSweatPlanSummary}
    />
    <Stack.Screen
      name="FindThrivePlanQuestion"
      component={FindThrivePlanQuestion}
    />
    <Stack.Screen
      name="FindThrivePlanSummary"
      component={FindThrivePlanSummary}
    />
    <Stack.Screen name="AskAnExpert" component={AskAnExpert} />
    <Stack.Screen name="SaveToList" component={SaveToList} />
    <Stack.Screen name="ListEdit" component={ListEdit} />
    <Stack.Screen name="TeamChallenge" component={TeamChallenge} />
  </Stack.Navigator>
);

export default ExploreStack;
