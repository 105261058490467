import {
  CheckBox,
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
} from 'o2x-store/src/models/FTELibrary';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import StepDetails from 'src/components/FTE/StepDetails';
import WorkoutDetails from 'src/components/FTE/WorkoutDetails';
import { formatWorkout } from 'src/utils/sweatstart';

type Props = {
  style?: StyleProp<ViewStyle>;
  onClose?: () => void;
  onSave?: () => void;
  workout?: SweatWorkout | FTESweatWorkout;
  libraryId?: number | undefined;
  workoutLibrary?: Number[];
};

const CreateSweatWorkout: React.FC<Props> = (props) => {
  const { onClose, onSave, libraryId, workoutLibrary, style, workout } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [workoutName, setWorkoutName] = useState(workout ? workout.name : '');
  const [workoutNotes, setWorkoutNotes] = useState(
    workout ? workout.notes : '',
  );
  const [difficulty, setDifficulty] = useState(
    workout ? workout.difficulty : '',
  );
  const [stepList, setStepList] = useState<
    (FTESweatWorkoutStep | SweatWorkoutStep)[]
  >(workout?.steps!);

  const [visible, setVisible] = useState(workout ? workout.ossVisible : false);

  const [category, setCategory] = useState(workout ? workout.category : '');
  const [prepare, setPrepare] = useState<number | null>(null);
  const [recover, setRecover] = useState<number | null>(null);
  const [prepName, setPrepName] = useState<string>(
    workout && workout.ftePrepare.name
      ? workout.ftePrepare.name
      : 'O2X Standard Prepare Step',
  );
  const [recName, setRecName] = useState<string>(
    workout && workout.fteRecover.name
      ? workout.fteRecover.name
      : 'O2X Standard Recover Step',
  );

  const [loadingSave, setLoadingSave] = useState(false);
  const [resultError, setResultError] = useState('');
  const [hasError, setHasError] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const data: FTESweatWorkout = {
    id: null,
    program: null,
    week: 1,
    day: 1,
    name: workoutName,
    notes: workoutNotes,
    difficulty: difficulty,
    category: category,
    fteOwner: store.auth.user?.id,
    steps: stepList,
    ftePrepare: prepare,
    fteRecover: recover,
    ossVisible: visible,
  };

  const onSubmit = useCallback(
    async (data) => {
      if (isNaN(data.ftePrepare)) data.ftePrepare = null;
      if (isNaN(data.fteRecover)) data.fteRecover = null;

      const workout = formatWorkout(data) as FTESweatWorkout;
      setLoadingSave(true);
      const result = await store.fteLibrary.createFteSweatWorkout(workout);
      if (result.ok) {
        const update = {
          id: libraryId,
          workouts: workoutLibrary
            ? [...workoutLibrary!, result.extra?.id]
            : [result.extra?.id],
        };
        const updateLibrary =
          await store.fteLibrary.updateFteSweatWorkoutLibrary(update);
      } else {
        Object.values(result.errors).map((error) =>
          setResultError(JSON.stringify(error)),
        );
      }
      setLoadingSave(false);
      setHasError(!result.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => {
        animation.setValue(0);
        if (result.ok && onSave) {
          onSave();
        }
      });
    },
    [onSave],
  );

  const onSubmitEdit = useCallback(
    async (data: FTESweatWorkout) => {
      if (isNaN(data.ftePrepare)) data.ftePrepare = null;
      if (isNaN(data.fteRecover)) data.fteRecover = null;

      const newWorkout = formatWorkout(data) as FTESweatWorkout;
      setLoadingSave(true);
      const result = await store.fteLibrary.updateFteSweatWorkout(
        workout?.id,
        newWorkout,
      );
      setLoadingSave(false);
      setHasError(!result.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => {
        animation.setValue(0);
        if (result.ok && onSave) {
          onSave();
        }
      });
    },
    [onSave],
  );

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 650,
  });

  return (
    <Layout style={[styles.container, style]}>
      <Layout style={styles.bgContainer}>
        <Layout style={isMobile ? styles.headerMobile : styles.header}>
          <TouchableOpacity onPress={onClose} style={styles.close}>
            <Icon
              name="arrow-back-outline"
              fill="#4E7B89"
              style={styles.icon}
            />
          </TouchableOpacity>
          <Text style={isMobile ? styles.headerTextMobile : styles.headerText}>
            Create Sweat Workout
          </Text>
          <CheckBox
            checked={visible}
            onChange={(status) => setVisible(status)}
            style={isMobile ? styles.shareMobile : styles.shareCheck}
          >
            {() => (
              <Text
                style={isMobile ? styles.shareTextMobile : styles.shareText}
              >
                Share with other OSS
              </Text>
            )}
          </CheckBox>
          {!!loadingSave ? (
            <Layout style={styles.loadingSave}>
              <Spinner />
            </Layout>
          ) : (
            <TouchableOpacity
              style={[
                isMobile ? styles.saveButtonMobile : styles.saveButton,
                workout && workout.fteOwner
                  ? workout?.fteOwner.id !== store.auth.user?.id && {
                      opacity: 0.3,
                    }
                  : {
                      opacity: 0.3,
                    },
              ]}
              onPress={() => onSubmitEdit(data)}
              disabled={
                !!!workout || (workout && workout.fteOwner)
                  ? workout?.fteOwner?.id !== store.auth.user?.id
                  : true
              }
            >
              <Text style={styles.saveText}>Save</Text>
            </TouchableOpacity>
          )}
          {!!loadingSave ? (
            <Layout style={styles.loadingSave}>
              <Spinner />
            </Layout>
          ) : (
            <TouchableOpacity
              style={isMobile ? styles.saveButtonMobile : styles.saveButton}
              onPress={() => onSubmit(data)}
            >
              <Text style={styles.saveText}>Save Workout</Text>
            </TouchableOpacity>
          )}
        </Layout>
        <Layout style={styles.stepContainer}>
          <WorkoutDetails
            workoutName={workoutName}
            workoutNotes={workoutNotes}
            difficulty={difficulty}
            category={category}
            setWorkoutName={setWorkoutName}
            setWorkoutNotes={setWorkoutNotes}
            setDifficulty={setDifficulty}
            setCategory={setCategory}
          />
          <StepDetails
            content="workout"
            stepList={stepList}
            setStepList={setStepList}
            setPrepare={setPrepare}
            setRecover={setRecover}
            setPrepName={setPrepName}
            setRecName={setRecName}
            prepName={prepName}
            recName={recName}
            prepare={prepare}
            recover={recover}
          />
        </Layout>
      </Layout>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[
            styles.popUpContainer,
            { opacity, transform: [{ translateY }, { scale }] },
          ]}
          pointerEvents="none"
        >
          <Icon
            style={styles.savedIcon}
            name={
              hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'
            }
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.savedText}>
            {hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}
          </Text>
          {hasError && <Text style={styles.errorText}>{resultError}</Text>}
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '95%',
    height: '95%',
    marginLeft: '5px',
    marginTop: 10,
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  header: {
    width: '96%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerMobile: {
    width: '96%',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  headerTextMobile: {
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  close: {
    marginLeft: 10,
    marginTop: 10,
  },
  icon: {
    width: 30,
    height: 30,
  },
  saveButton: {
    width: 100,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 15,
  },
  saveButtonMobile: {
    width: 140,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    alignItems: 'center',
  },
  loadingSave: {
    position: 'absolute',
    padding: 8,
    right: 30,
    top: 12,
    backgroundColor: 'transparent',
  },
  saveText: {
    margin: 'auto',
    color: 'white',
    fontSize: 12,
  },
  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  shareCheck: {
    marginLeft: 'auto',
    marginRight: 5,
    alignSelf: 'center',
    marginTop: 10,
  },
  shareMobile: {
    marginTop: 4,
    marginLeft: 10,
  },
  shareText: {
    marginLeft: 5,
    fontSize: 14,
  },
  shareTextMobile: {
    marginLeft: 5,
    fontSize: 12,
  },
  stepContainer: {
    height: '90%',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
});

export default observer(CreateSweatWorkout);
